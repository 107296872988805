let config = {
    TTT_Game_Service:'http://150.95.25.61:7778',
    //TTT_Game_Service:'http://localhost:7778',
    TTT_Game_Notification_Service:'http://150.95.25.61:7775',
    TTT_Game_Service_IMG:'http://150.95.25.61:7778/static/',
    TTT_Game_Permission_Service:'http://150.95.25.61:7779',
    TTT_Game_Socket_Service:'http://150.95.25.61:7776',
    TTT_Game_Ai_Service:'http://150.95.89.38:8000',
    TTT_Game_Socket_Service_Port:'7776',
    API_TTT: '4f781ebba1a655430fb6db734c2c156f',   
    
    TTT_Dashboard_Url:'http://150.95.25.61:7781/federation-dashboard/',
    TTTClient:'http://150.95.25.61:7777',
    API_URL_BOX_SERVICE:"http://150.95.25.61:7770",
    };
    
    export default Object.freeze(Object.assign({}, config));