import React, { useState,useRef, useEffect } from 'react'
import "./SpaceshipConsolCSS.css";
import { Link } from 'react-router-dom'
import SpaceShipLogo from './img/SpaceShip_Logo_2.png';
import SpaceShip from './img/image_output_path.png';
import axios from "axios";
import Configs from "../../config";
import {Row,Col  } from "antd";
import html2canvas from 'html2canvas';
import LogoFigma from './img/Frame 238657.png'
import logo2 from './img/Group 237750.png'
import logo3 from './img/Group 237754.png'
import logo4 from './img/Group 237769.png'
import logo5 from './img/Group 237770.png'
import logo6 from './img/Group 237817.png'
import logo7 from './img/Group 237959.png'
import logo8 from './img/Group 238545.png'
import logo9 from './img/Group 238658.png'
import logo10 from './img/Group 238620.png'
import logoLeft from './img/Vector.png'
import LogoCap from './img/Vector_cap.png'
import logo11 from './img/Frame 239201 (2).png'
import { PlusCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import AddElement from "../SpaceshipConsole/components/addElement/index";

function Spaceshipconsole() {
    const urlParams = new URLSearchParams(window.location.search);
    const spid = urlParams.get('spid');
    const oldPageMode = urlParams.get('mode');
    const fedSpsId = urlParams.get('fedSpsId');
    const spaceship_id = urlParams.get('spaceship_id');

    const [test, setTest] = useState({
        test: ''
    });

    const [isLeader, setIsLeader] = useState(false);

    const [addElementIsOpen, setAddElementIsOpen] = useState(false);
    const toggleAddElementIsOpen = () => { setAddElementIsOpen(!addElementIsOpen) };
    const [planning,setPlanning] = useState([
        {no:''},
        {no:''},
        {no:''},
        {no:''},
        {no:''},
        {no:''},
        {no:''},
    ])
    const [testing,setTesting] = useState([
        {no:''},
        {no:''},
        {no:''},
        {no:''},
        {no:''},
        {no:''},
        {no:''},
    ])
    const [data,setdata] = useState([
        {
            no:'1',
            requirement:logo6,
            Analysis:LogoFigma,
            Installation:'',
            Train:logo3,
            namerequirement:'External Interface',
            Gistrequirement:'ปัจจัยภายนอก เป็นการรายงานปัญหาความต้องการของ User Interface ว่าเมนูใดเสียหายหรือใช้งานไม่ได้',
            nameAnalysis:'Figma Link',
            GistAnalysis:'Element สำหรับใส่ URL เพื่อให้ลิงค์ไปยัง Figma โดยจะลิ๊งค์ไปยังหน้าจอนั้นนั้นได้โดยตรง',
            nameTrain:'Security',
            GistTrain:'การทำงานที่เกี่ยวข้องด้านความปลอดภัยในด้านการทำงาน การควบคุมการเข้าถึง ความสมบูรณ์ของข้อมูล การตรวจสอบสิทธิ์ และความปลอดภัยของรหัสผ่านการเข้าถึง'
        },
        {
            no:'2',
            requirement:logo11,
            Analysis:logo7,
            Installation:'',
            Train:logo4,
            namerequirement:'Proformance',
            Gistrequirement:'ความสามารถของผลิตภัณฑ์หรือระบบในด้านประสิทธิภาพว่าทำงานได้ดีเพียงใด ช่วยเสริมด้านการทำงานของระบบ',
            nameAnalysis:'Screenshot',
            GistAnalysis:'Element ไว้สำหรับการนำภาพหน้าจอเข้าสู่ระบบแล้วสามารถกำหนดเวอร์ชั่นได้',
            nameTrain:'Change / Feed Back',
            GistTrain:'การปรับเปลี่ยนขนาดของเว็บไซต์ให้เหมาะสมกับการแสดงผลบนหน้าจอขนาดต่างๆ และความละเอียดของหน้าจอในอุปกรณ์ที่แตกต่างกัน'
        },
        {
            no:'3',
            requirement:logo9,
            Analysis:'',
            Installation:'',
            Train:logo5,
            namerequirement:'Non - Function',
            Gistrequirement:'สิ่งที่เสริมซอฟต์แวร์ที่สามารถทำงานได้ลื่นไหลขึ้น สิ่งที่เกี่ยวข้องกับความต้องการของผู้ใช้โดยตรง เช่น ความน่าเชื่อถือ ความรวดเร็วในการตอบสนองความสามารถในการจัดเก็บข้อมูล',
            nameTrain:'Report',
            GistTrain:'การรายงาน การส่งข้อมูลให้กับเจ้าของโปรแกรมแก้ไขจุดบกพร่อง บทสรุปของข้อมูลเป็นอย่างไร'
        },
        {
            no:'4',
            requirement:logo2,
            Analysis:'',
            Installation:'',
            Train:'',
            namerequirement:'Function',
            Gistrequirement:'สิ่งที่ระบบควรจะทำ ขึ้นอยู่กับประเภทของซอฟต์แวร์ที่ต้องพัฒนาเพื่อตอบสนองกับความต้องการของผู้ใช้'
        },
        {
            no:'5',
            requirement:'',
            Analysis:'',
            Installation:'',
            Train:''
        },
        {
            no:'6',
            requirement:'',
            Analysis:'',
            Installation:'',
            Train:''
        },
        {
            no:'7',
            requirement:'',
            Analysis:'',
            Installation:'',
            Train:''
        },
        {
            no:'8',
            requirement:'',
            Analysis:'',
            Installation:'',
            Train:''
        },
        {
            no:'9',
            requirement:'',
            Analysis:'',
            Installation:'',
            Train:''
        },
        {
            no:'10',
            requirement:'',
            Analysis:'',
            Installation:'',
            Train:''
        }
    ])


    const [Codeing,setCodeing]= useState([
        {
            no:'1',
            code:logo8,
            extra:logo10,
            nameCode:'Gen Backend',
            GistCode:'ใช้สำหรับ generate Code Backend',
            nameExtra:'Time Sheet',
            GistExtra:''
        },
        {
            no:'2',
            code:'',
            extra:''
        },
        {
            no:'3',
            code:'',
            extra:''
        },
        {
            no:'4',
            code:'',
            extra:''
        },
        {
            no:'5',
            code:'',
            extra:''
        },
        {
            no:'6',
            code:'',
            extra:''
        },
        {
            no:'7',
            code:'',
            extra:''
        },
        {
            no:'8',
            code:'',
            extra:''
        },
    ])
    const [datetime, setDatetime] = useState(new Date());

    const screenshotRef = useRef(null);

    const handleScreenshot = () => {
    const element = screenshotRef.current;
    html2canvas(element, { useCORS: true, scrollY: -window.scrollY }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'screenshot.png';
      link.click();
    });
  };

  const [isMouseInside, setIsMouseInside] = useState(false);
/* 
  const [positionMouse, setPositionMouse] = useState({
    mouseX: '',
    mouseY: ''
    });
     */
    const [positionMouse, setPositionMouse] = useState({ mouseX: 0, mouseY: 0 });
    const [isTracking, setIsTracking] = useState(false);
    const [positionTooltip, setPositionTooltip] = useState({ top: 0, left: 0 });
    const tooltipRef = useRef(null);
    const FunpositionMouse = () => {
        setIsTracking(true);
    };

    const stopFunpositionMouse = () => {
        setIsTracking(false);
    };

    useEffect(() => {
        if (isTracking) {
            const handleMouseMove = (event) => {
                if (tooltipRef.current) {
                    const rect = tooltipRef.current.getBoundingClientRect();
                    const tooltipTop = ((event.clientY - rect.top) / rect.height) * 100;
                    const tooltipLeft = ((event.clientX - rect.left) / rect.width) * 100;
                    console.log('tooltipTop',tooltipTop);
                    console.log('tooltipLeft',tooltipLeft);
                    

                    setPositionTooltip({
                        top: `${tooltipTop}%`,
                        left: `${tooltipLeft}%`
                    });
                    console.log(positionTooltip);
                    
                }
            };

            window.addEventListener('mousemove', handleMouseMove);

            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, [isTracking]);
/*     useEffect(() => {
        const interval = setInterval(() => {
        setDatetime(new Date());
        }, 1000); // อัปเดตทุก ๆ 1 วินาที

        return () => clearInterval(interval); // เคลียร์ interval เมื่อ component ถูก unmount
    }, []); */

    async function checkIsLeader() {
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/spaceship/checkIsLeader/${spid}`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
            }
          })
            .then(async function (response) {
                if (response.data) {
                    setIsLeader(response.data);
                    setTest({...test, test: ''});
                    test.test = '';
                }
            })
            .catch(function (error) {
              console.log(error);
            });
    }

    useEffect(() => {
        checkIsLeader();
    }, []);

    const formattedDate = datetime.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    }).toUpperCase()

  return (
    <div className='Area'  ref={screenshotRef}>
      <div className='BoxTopLeft'>
          <div style={{ height:'100%',width:'54% ',transform: 'rotate(-5deg)',marginTop:'10%',marginLeft:'20%'}}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <div className='TextHearder'>Planning</div>
            </div>
            <div className='EvenTop'>
            {planning.map((e)=>{
                return(
                    <div className='Box' style={{height: '100%',width: '40%'}}>{e.no}</div>
                )
            })}
            </div>
          </div>
      </div>
      <div className='BoxCenterLeft'style={{zIndex:'6'}}>
          <div style={{display:'flex',marginLeft:'5%',marginTop:'2%',height:'100%',width:'100%',transform: 'rotate(-5deg)'}}>
            <div style={{width:'40%',height:'100%',display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                <div className='TextHearder' >Requirement</div>
                <div className='EvenCenter' style={{zIndex: '10'}}> 
                    {data.map((e, index)=>{
                        let name = e.namerequirement
                        let Gist = e.Gistrequirement
                        return e.requirement ? (
                            <div className='Box'
                            key={index}
                            onMouseEnter={FunpositionMouse}
                            onMouseLeave={stopFunpositionMouse}
                                style={{
                                    height: '50px',
                                    width: '50px',
                                    position: 'relative'
                                }}
                                ref={tooltipRef}
                            > 
                                <img
                                style={{ height: '100%', width: '100%' }}
                                src={e.requirement}
                            />
                            <div 
                                 style={{
                                    position: 'absolute',
                                    top: '-20%',
                                    left: '230%',
                                    zIndex: '10',
                                }} className='linerTooltip'></div>
                            <div 
                                style={{
                                    position: 'absolute',
                                    top: '-16%',
                                    left: '210%',
                                    zIndex: '10',
                                }} className='linerTooltip2'></div>
                            <div 
                               style={{
                                position: 'absolute',
                                top: '-35%',
                                left: '310%',
                                zIndex: '10',
                            }} class="tooltip">

                                <div style={{fontWeight: 'bold',fontSize:'20px'}}>{name}</div>
                                <div>{Gist}</div>
                                </div>
                            </div>
                        ) : (
                            <div 
                                key={index}
                                className='Box'
                                onMouseEnter={FunpositionMouse}
                                onMouseLeave={stopFunpositionMouse}
                                style={{
                                    height: '50px',
                                    width: '50px',
                                    position: 'relative'
                                }}
                                ref={tooltipRef}
                            ></div>
                        );
                    })}
                </div>
            </div>
            <div style={{width:'40%',height:'100%',display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                <div className='TextHearder'>Analysis and Design</div>
                <div className='EvenCenter'> 
                    {data.map((e,index)=>{
                        let name = e.nameAnalysis
                        let Gist = e.GistAnalysis
                        return e.Analysis ? (
                            <div className='Box'
                            key={index}
                            onMouseEnter={FunpositionMouse}
                            onMouseLeave={stopFunpositionMouse}
                                style={{
                                    height: '50px',
                                    width: '50px',
                                    position: 'relative'
                                }}
                            ref={tooltipRef}
                            >   
                                <img style={{ height: '100%', width: '100%' }} src={e.Analysis}>
                                </img>
                                <div 
                                style={{
                                    position: 'absolute',
                                    top: '-20%',
                                    left: '230%',
                                    /* transform: 'translateX(-50%)', */
                                    zIndex: '10',}} className='linerTooltip'></div>
                                <div  
                                    style={{
                                    position: 'absolute',
                                    top: '-16%',
                                    left: '210%',
                                    /* transform: 'translateX(-50%)', */
                                    zIndex: '10',}} className='linerTooltip2'></div>
                                <div 
                                    style={{
                                    position: 'absolute',
                                    top: '-35%',
                                    left: '310%',
                                    /* transform: 'translateX(-50%)', */
                                    zIndex: '10',}} class="tooltip" >
                                    <div  style={{fontWeight: 'bold',fontSize:'20px'}}>{name}</div>
                                    <div>{Gist}</div>
                                </div>
                            </div>
                            
                        ) : (
                            <div className='Box'
                                style={{ 
                                    height: '50px',
                                    width: '50px'
                                }}
                            ></div>
                        );
                    })}
                </div>
            </div>
          </div>
      </div>
      <div className='BoxBottomLeft'>
        <div style={{marginLeft:'35%',marginTop:'8%',height:'90%',width:'100%',transform: 'rotate(-5deg)',zIndex:'3'}}>
            <div  style={{width:'100%',height:'100%',display: 'flex',flexDirection: 'column',gap:'5%',zIndex:'3'}}>
                <div className='TextHearder'>Coding and Unit Test</div>
                <div className='EvenBottom'> 
                        {Codeing.map((e)=>{
                            return e.code ? (
                                <div className='Box'
                                onMouseEnter={FunpositionMouse}
                                onMouseLeave={stopFunpositionMouse}
                                    style={{
                                        height: '50px',
                                        width: '50px',
                                        position: 'relative'
                                    }}
                                >
                                    <img
                                    style={{ height: '100%', width: '100%' }}
                                    src={e.code}
                                />
                                <div 
                                style={{
                                    position: 'absolute',
                                    top: '-20%',
                                    left: '230%',
                                    /* transform: 'translateX(-50%)', */
                                    zIndex: '10',}} className='linerTooltip'></div>
                                <div  
                                    style={{
                                    position: 'absolute',
                                    top: '-16%',
                                    left: '210%',
                                    /* transform: 'translateX(-50%)', */
                                    zIndex: '10',}} className='linerTooltip2'></div>
                                <div 
                                    style={{
                                    position: 'absolute',
                                    top: '-35%',
                                    left: '310%',
                                    /* transform: 'translateX(-50%)', */
                                    zIndex: '10',}} class="tooltip" >
                                <div style={{fontWeight: 'bold',fontSize:'20px'}}>{e.nameCode}</div>
                                <div>{e.GistCode}</div>
                                </div>
                                </div>
                                
                            ) : (
                                <div className='Box'
                                    style={{ 
                                        height: '50px',
                                        width: '50px'
                                    }}
                                ></div>
                            );
                        })}
                </div>
            </div>
        </div>
      </div>
      <div className='BoxTopCenter'>
        <div style={{background: '#131313'/* ,background: 'linear-gradient(90deg, rgba(19,19,19,1) 0%, rgba(35,35,35,1) 100%)' */,
            width:'100%',height:'44.5%',display: 'flex',justifyContent:'space-around',borderBottom:' 3px solid #0047FF'}}>
            <img style={{width:'40%',height:'70%',marginTop:'3%'}} src={SpaceShipLogo}></img>
        </div>
        <div style={{width:'100%',height:'55.5%',display: 'flex',justifyContent:'space-around',alignItems: 'center'}}>
            <div style={{width:'50%',height:'75%',border:' 2px solid #002B99',backgroundColor:' rgba(32,32,32)',backgroundColor:' rgba(255,255,255, 0.1)',borderRadius:'10px',display:'flex'}}>
                <Link to={`/StageMonitoring?key=${oldPageMode}${oldPageMode == 'fedSpaceShip' ? `&fedSpsId=${fedSpsId}` : ''}${oldPageMode == 'all' && spaceship_id != '' ? `&spaceship_id=${spaceship_id}` : ''}`} className='evenTopCenter'>
                    <div style={{height:'52%',width: '34%',borderRadius: '5px',border:'1px solid #ff0000',backgroundColor:'#920000',display: 'flex',justifyContent:'space-around',alignItems: 'center',cursor:' pointer'}}>
                        <img style={{width:'65%'}} src={logoLeft}></img>
                    </div>
                    <div>
                        BACK TO SPACE
                    </div>
                </Link>
                {isLeader && <div className='evenTopCenter'>
                    <div style={{height:'52%',width: '34%',border:'2px dashed #fff',borderRadius: '5px',backgroundColor:'rgba(255,255,255,0.1)',display: 'flex',justifyContent:'space-around',alignItems: 'center',cursor:' pointer'}}
                        onClick={() => {
                        toggleAddElementIsOpen();
                      }}>
                        <PlusCircleFilled   style={{fontSize: '25px',color:'fff'}}/>
                    </div>
                    <div>ADD ELEMENT</div>
                </div>}
            </div>
        </div>
      </div>
      <div className='BoxCenter'>
        <div style={{width:'100%',height:'100%',display: 'flex',justifyContent:'space-around',alignItems: 'center'}}>
            <img style={{width:'25%',height:'60%',position: 'absolute',zIndex:'-1',filter:'drop-shadow(1px 1px 20px #000)',animation:'bounce 4s infinite'}} src={SpaceShip}></img>
        </div>
      </div>
      <div className='BoxBottomCenter'>
        <div style={{height:'50%',width:'50%',display:'flex',justifyContent:'space-around',alignItems: 'center'}}>
            <div style={{display:'flex',justifyContent:'space-around',alignItems: 'center',flexDirection: 'column',rowGap:'7px'}}>
                <div className='evenNumBottomCerter' style={{color:'yellow'}} >1</div>
                <div>RANK</div>
            </div>
            <div style={{display:'flex',justifyContent:'space-around',alignItems: 'center',flexDirection: 'column',rowGap:'7px'}}>
                <div className='evenNumBottomCerter' style={{color:'purple'}} >8</div>
                <div>ELEMENT</div>
            </div>
            <div style={{display:'flex',justifyContent:'space-around',alignItems: 'center',flexDirection: 'column',rowGap:'7px'}}>
                <div className='evenNumBottomCerter' style={{color:'red'}} >12</div>
                <div>SEATS</div>
            </div>
        </div>
        <div style={{height:'6%',width:'42%',border: '3px solid #0047FF',background: 'rgb(0, 43, 153)',}}></div>
        <div style={{height:'40%',width:'100%',borderTop: '3px solid #0047FF',display:'flex',alignItems: 'center',flexDirection: 'column',rowGap:'7%' }}>
          <div style={{
            background: 'linear-gradient(180deg, rgba(0, 71, 255, 1) 10%, rgba(0, 43, 153, 1) 80%)',
            borderRadius:' 40px',
            width: '25%',
            display:'flex',
            alignItems: 'center',
            justifyContent:'space-around'
            ,marginTop:'2%',
          }}>SPACESHIP NAME</div>
          <div style={{
                        fontSize:'30px',
                        width:'50%',
                        height:' 30%',
                        display:'flex',
                        alignItems: 'center',
                        justifyContent:'space-around',
                        color:'#fff',
                       }}
            >Raizeros</div>
          <div style={{
            background: 'linear-gradient(180deg, rgba(0, 71, 255, 1) 10%, rgba(0, 43, 153, 1) 80%)',
            width:'50%',
            display:'flex',
            alignItems: 'center',
            justifyContent:'space-around',
            marginTop: '2%',
            border: '2px solid #002B99',
            clipPath:'polygon(0 100%, 10% 0%, 90% 0, 100% 100%)'}}
          >EXPIRE DATE : {formattedDate }</div>
        </div>
      </div>
      <div className='BoxTopRight'>
            <div style={{  height:'100%',width:'54% ',transform: 'rotate(5deg)',marginTop:'10%',marginLeft:'20%'}}>
                <div className='TextHearder'>Testing</div>
                <div className='EvenTop'>
                {testing.map((e)=>{
                    return(
                        <div className='Box' style={{height: '100%',width: '40%'}}>{e.no}</div>
                    )
                })}
                </div>
            </div>
      </div>
      <div className='BoxCenterRight'>
        <div style={{display:'flex',marginLeft:'10%',marginTop: '2%',transform: 'rotate(5deg)',height:'100%',width:'100%'}}>
            <div style={{width:'40%',height:'100%',display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                <div className='TextHearder' >Installation</div>
                <div className='EvenCenter'> 
                    {data.map((e,index)=>{
                         let name = e.nameTrain
                         let Gist = e.GistTrain
                        return e.Installation ? (
                            <div className='Box'
                                key={index}
                                onMouseEnter={FunpositionMouse}
                                onMouseLeave={stopFunpositionMouse}
                                style={{
                                    height: '50px',
                                    width: '50px',
                                    position: 'relative'
                                }}
                                ref={tooltipRef}
                            >
                                <img
                                style={{ height: '100%', width: '100%' }}
                                src={e.Installation}
                            />
                                <div  style={{
                                    position: 'absolute',
                                    top: '-20%',
                                    right: '120%',
                                    zIndex: '10',
                                }} className='linerTooltip2_1'></div>
                                <div style={{
                                    position: 'absolute',
                                    top: '-16%',
                                    right: '100%',
                                    zIndex: '10',
                                }} className='linerTooltip2_2'></div>
                                <div style={{
                                    position: 'absolute',
                                    top: '-30%',
                                    right: '200%',
                                    zIndex: '10',
                                }} className="tooltip2" >
                                    <div style={{fontWeight: 'bold',fontSize:'20px'}}>{name}</div>
                                    <div>{Gist}</div>
                                </div>
                            </div>
                            
                        ) : (
                            <div className='Box'
                                onMouseEnter={FunpositionMouse}
                                onMouseLeave={stopFunpositionMouse}
                                style={{
                                    height: '50px',
                                    width: '50px'
                                }}
                            >
                            </div>
                            
                        );
                    })}
                </div>
            </div>
            <div style={{width:'40%',height:'100%',display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                <div className='TextHearder'>Trainning</div>
                <div className='EvenCenter'> 
                    {data.map((e,index)=>{
                        let name = e.nameTrain
                        let Gist = e.GistTrain
                        return e.Train ? (
                            <div className='Box'
                                key={index}
                                onMouseEnter={FunpositionMouse}
                                onMouseLeave={stopFunpositionMouse}
                                style={{
                                    height: '50px',
                                    width: '50px',
                                    position: 'relative'
                                }}
                                ref={tooltipRef}
                            >
                                <img
                                style={{ height: '100%', width: '100%' }}
                                src={e.Train}
                            />  
                                <div  style={{
                                    position: 'absolute',
                                    top: '-20%',
                                    right: '120%',
                                    zIndex: '10',
                                }} className='linerTooltip2_1'></div>
                                <div style={{
                                    position: 'absolute',
                                    top: '-16%',
                                    right: '100%',
                                    zIndex: '10',
                                }} className='linerTooltip2_2'></div>
                                <div style={{
                                    position: 'absolute',
                                    top: '-30%',
                                    right: '200%',
                                    zIndex: '10',
                                }} className="tooltip2" >
                                    <div style={{fontWeight: 'bold',fontSize:'20px'}}>{name}</div>
                                    <div>{Gist}</div>
                                </div>
                            </div>
                            
                        ) : (
                            <div className='Box'
                                style={{ 
                                    height: '50px',
                                    width: '50px'
                                }}
                            ></div>
                        );
                    })}
                </div>
            </div>
        </div>
      </div>
      <div className='BoxBottomRight'>
      <div style={{marginLeft:'26%',marginTop:'13%',height:'90%',width:'100%',transform: 'rotate(5deg)'}}>
            <div  style={{width:'100%',height:'100%',display: 'flex',flexDirection: 'column',gap:'5%'}}>
                <div className='TextHearder'>Extra</div>
                <div className='EvenBottom'> 
                        {Codeing.map((e, index)=>{
                            return e.extra ? (
                                <div className='Box'
                                    key={index}
                                    onMouseEnter={FunpositionMouse}
                                    onMouseLeave={stopFunpositionMouse}
                                        style={{
                                            height: '50px',
                                            width: '50px',
                                            position: 'relative'
                                        }}
                                    ref={tooltipRef}
                                >
                                    <img
                                    style={{ height: '50px', width: '100%' }}
                                    src={e.extra}
                                />
                                 <div  style={{
                                    position: 'absolute',
                                    top: '-20%',
                                    right: '120%',
                                    zIndex: '10',
                                }} className='linerTooltip2_1'></div>
                                <div style={{
                                    position: 'absolute',
                                    top: '-16%',
                                    right: '100%',
                                    zIndex: '10',
                                }} className='linerTooltip2_2'></div>
                                <div style={{
                                    position: 'absolute',
                                    top: '-30%',
                                    right: '200%',
                                    zIndex: '10',
                                }} className="tooltip2" >
                                <div style={{fontWeight: 'bold',fontSize:'20px'}}>{e.nameExtra}</div>
                                <div>{e.GistExtra}</div>
                                </div>
                                </div>
                                
                            ) : (
                                <div className='Box'
                                    style={{
                                        height: '50px',
                                        width: '50px'
                                    }}
                                ></div>
                            );
                        })}
                </div>
            </div>
        </div>
      </div>
        <div className='Boxcap' onClick={handleScreenshot} >
            <img style={{width:'50%',height:'50%'}} src={LogoCap}></img>
        </div>
        <div className='boxTopLeft'></div>
        <div className='LinerTopLeft'></div>
        <div className='BottomboxTopLeft'></div>
        <div className='leftbuttomboxTopLeft'></div>
        <div className='lefttopboxTopLeft'></div>
        <div className='rightbuttomboxTopLeft'></div>
        <div className='righttopboxTopLeft'></div>
        <div className='boxTopRight'></div>
        <div className='LinerTopRight'></div>
        <div className='BottomboxTopRight'></div>
        <div className='leftbuttomboxTopRight'></div>
        <div className='lefttopboxTopRight'></div>
        <div className='rightbuttomboxTopRight'></div>
        <div className='righttopboxTopRight'></div>
        <div className='LinerCenterBottomLeft'></div>
        <div className='LinerCenterBottomRight'></div>    
        <div className='LinerCenterLeft'></div>
        <div className='LinerCenterRight'></div>
        <div className='LinerBottomLeft'></div>
        <div className='LinerBottomRight'></div>
        <div className='LinerBottomleftBottom'>
            <div className='LinerBottomleftBottom0_1'></div>
            <div className='LinerBottomleftBottom0_2'></div>
            <div className='LinerBottomleftBottom0_3'></div>
            <div className='LinerBottomleftBottom0_4'></div>
            <div className='LinerBottomleftBottom0_5'></div>
            <div className='LinerBottomleftBottom0_6'></div>
            <div className='LinerBottomleftBottom0_7'></div>
        </div>
        <div className='LinerBottomRightBottom'>
            <div className='LinerBottomleftBottom0_1'></div>
            <div className='LinerBottomleftBottom0_2'></div>
            <div className='LinerBottomleftBottom0_3'></div>
            <div className='LinerBottomleftBottom0_4'></div>
            <div className='LinerBottomleftBottom0_5'></div>
            <div className='LinerBottomleftBottom0_6'></div>
            <div className='LinerBottomleftBottom0_7'></div>
        </div>
        <div className='backgLeft'></div>
        <div className='backgRight'></div>
        <div className='bgBottom'></div>
        <div className='bgBottom2'></div>
        <div className='bgCenterLeft'></div>
        <div className='bgCenterRight'></div>
        <div className='bgCenterBottom'></div>
        <AddElement
        isOpen={addElementIsOpen}
        toggleIsOpen={toggleAddElementIsOpen}
      />
    </div>
  ) 
}

export default Spaceshipconsole;