import React, { useState, useEffect, useRef } from 'react'
import './stageElementStyle.css'
import Configs from "../../../config";
import axios from 'axios';
import UserProgress from './userProgress';
import ElementComponent from './elementComponent';
import SrsArea from './srsArea';
import ModelAlertConfrim from "../../../components/AlertMassge/AlertConfrim";
import ElementBackendCodeGen from "./elementBackendCodeGen";
import ElementScreenshot from "./elementScreenshot";
import ElementFigmalink from "./elementFigmalink";
import ElementUserManual from "./elementUserManual";
import ElementTestCase from "./elementTestCase"
import DefaultUserProfile from '../../../assets/img/userlogo/defultUser.png';
import ModalProcessSrs from "./ModalProcesUAT/ModalProcessSrs";
import ModalTimeSheet from "./time_sheet/index";
import TimeSheetIcon from "./time_sheet/img/time_sheet_icon.png";
import { io } from 'socket.io-client';
import TimeSheetModal from './time_sheet/index';
import MomAttendantEdit from './MomAttendant/momAttandantEdit'

const StageElement = ({
    isOpen,
    toggle_modal_status,
    project_id,

    process_id,
    menu_id,
    getProjectDetail,
    setIndexChat,
    setToCardStage
}) => {
    const [isOpenTimeSheet, setIsOpenTimeSheet] = useState(false);
    const toggle_modal_time_sheet = () => { setIsOpenTimeSheet(!isOpenTimeSheet) };
    const [test, setTest] = useState({ test: '' })

    const [elementData, setElementData] = useState({})
    const [elementDataEdit, setElementDataEdit] = useState({})
    const [elementSend, setElementSend] = useState([])
    const [processStage, setProcessStage] = useState({
        process_id: '',
        process_name: '',
        status_id: '',
        status_name: '',
        module_name: '',
        menu_id: '',
        menu_name: '',
        module_id: ''
    })
    console.log('processStage', processStage)
    const [userPercentList, setUserPercentList] = useState([]);
    const [percentTotal, setPercentTotal] = useState([]);
    console.log('percentTotal', percentTotal)
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);

    const [elementDetailCheck, setElementDetailCheck] = useState(false);
    const toggle_modal_elementDetailCheck = () => { setElementDetailCheck(!elementDetailCheck) };

    const [elementBackEndCodeGen, setElementBackEndCodeGen] = useState(false);
    const toggle_modal_elementBackEndCodeGen = () => { setElementBackEndCodeGen(!elementBackEndCodeGen) };

    const [displaySwal, setDisplaySwal] = useState(false);
    const toggle_modal_confrimModal = () => setDisplaySwal(!displaySwal);

    const [editSrsData, setEditSrsData] = useState(false);
    const toggle_modal_editSrsData = () => { setEditSrsData(!editSrsData) };
    const [callbackSrsData, setCallbackSrsData] = useState({});
    console.log('callbackSrsData', callbackSrsData)

    const [updateEditSrsData, setUpdateEditSrsData] = useState({
        no: 0
    });
    const [errorEditSrsData, setErrorEditSrsData] = useState(false);
    const toggle_modal_errorEditSrsData = () => { setErrorEditSrsData(!errorEditSrsData) };
    const [elementScreenshot, setElementScreenshot] = useState(false);
    const toggle_modal_elementScreenshot = () => { setElementScreenshot(!elementScreenshot) };
    const [elementFigmalink, setElementFigmalink] = useState(false);
    const toggle_modal_ElementFigmalink = () => { setElementFigmalink(!elementFigmalink) };
    const [elementUserManualstage, setElementUserManualstage] = useState(false);
    const toggle_modal_ElementUserManualstage = () => { setElementUserManualstage(!elementUserManualstage) };
    const [elementUsertageUATStage, setElementUsertageUATStage] = useState(false);
    const toggle_modal_ElementUsertageUATStage = () => { setElementUsertageUATStage(!elementUsertageUATStage) };

    const [elementTestCase, setElementTestCase] = useState(false);
    const toggle_modal_ElementTestCase = () => { setElementTestCase(!elementTestCase) };

    const [displayMode, setDisplayMode] = useState(false);

    const [imgData, setImgData] = useState([]);

    const [Dropdown, setDropdown] = useState(false);

    const clickDropdown = () => {
        setDropdown(!Dropdown)
    }

    const [momAttendantData, setMomAttendantData] = useState([]);

    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                //path: '/Socket-service/socket.io'
                port: Configs.TTT_Game_Socket_Service_Port
            });
        }
    }, [

    ])

    useEffect(() => {
        if (setIndexChat != null) {
            setTest({ ...test, test: '' });
            test.test = '';
        }

    }, [setIndexChat])

    useEffect(() => {
        if (callbackSrsData && Array.isArray(callbackSrsData.edit_fileList) && Array.isArray(callbackSrsData.edit_imageList)) {
            const hasFilesOrImages = callbackSrsData.edit_fileList.length > 0 || callbackSrsData.edit_imageList.length > 0;
            if (hasFilesOrImages) {
                setDropdown(true);
            }
        }
    }, [callbackSrsData.edit_fileList, callbackSrsData.edit_imageList]);

    async function getElementById() {
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/process/getProcessById/${process_id}`,
            headers: {
                /*     Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            /* data: temp, */
        })
            .then(async function (response) {
                let tempElementSelectCheck = sessionStorage.getItem('elementSelect');

                let tempElementSelectSet = [];
                let tempElementSelectSetChang = [];
                let tempImg = imgData;
                for (let i of response.data.element) {
                    if (i.elm_abbr === "SS") {

                        await axios({
                            method: "post",
                            url: `${Configs.TTT_Game_Service}/api/process/checkelementsscreenshot`,
                            headers: {
                                /*     Authorization: getToken(), */
                                "X-TTT": `${Configs.API_TTT}`,
                                "Content-Type": "application/json",
                            },
                            data: {
                                project_id: project_id,
                                process_id: process_id,
                                menu_id: menu_id,

                            }
                        })
                            .then(async function (response) {

                                i.elm_isSelect = response.data



                            })
                            .catch(function (error) {
                                console.log(error);
                            });


                    }

                    if (i.elm_abbr === "FL") {

                        await axios({
                            method: "post",
                            url: `${Configs.TTT_Game_Service}/api/process/checkelementfigmalink`,
                            headers: {
                                /*     Authorization: getToken(), */
                                "X-TTT": `${Configs.API_TTT}`,
                                "Content-Type": "application/json",
                            },
                            data: {
                                project_id: project_id,
                                process_id: process_id,
                                menu_id: menu_id,

                            }
                        })
                            .then(async function (response) {

                                i.elm_isSelect = response.data



                            })
                            .catch(function (error) {
                                console.log(error);
                            });


                    }

                    if (i.fd_img_id) {
                        let checkIsImg = tempImg.filter((e) => { return e.fd_img_id == i.fd_img_id });
                        if (checkIsImg.length > 0) {
                            i.elm_img_path = checkIsImg[0].elm_img_path;
                        } else {
                            let response2 = await axios.get(`https://api.box.com/2.0/files/${i.fd_img_id}/content`, {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                responseType: 'blob',
                            });

                            let imgUrl = URL.createObjectURL(response2.data);

                            tempImg.push({
                                fd_img_id: i.fd_img_id,
                                elm_img_path: imgUrl
                            });

                            i.elm_img_path = imgUrl;
                        }
                    }

                    setElementData(response.data);
                    setElementDataEdit(response.data);
                    setImgData(tempImg);
                    setTest({ ...test, test: '' })
                    test.test = ''
                }

                if (tempElementSelectCheck == null || tempElementSelectCheck == undefined || tempElementSelectCheck == '' || tempElementSelectCheck.length < 1) {
                    // tempElementSelectSet.push(response.data.element[0].elm_id);
                    sessionStorage.setItem('elementSelect', JSON.stringify(tempElementSelectSet))
                } else {
                    for (let item of JSON.parse(tempElementSelectCheck)) {
                        let tempJSON = response.data.element.filter((e) => { return e.elm_id == item })
                        if (tempJSON.length > 0) {
                            tempElementSelectSetChang.push(item);
                        }
                    }
                }

                if (tempElementSelectSetChang.length > 0) {
                    sessionStorage.setItem('elementSelect', JSON.stringify(tempElementSelectSetChang))
                } else {
                    // tempElementSelectSet.push(response.data.element[0].elm_id);
                    sessionStorage.setItem('elementSelect', JSON.stringify(tempElementSelectSet))
                }

                let tempFirst = [];
                response.data.element.map((e, index) => {
                    let tempElementSelectCheck = JSON.parse(sessionStorage.getItem('elementSelect'));
                    let temp = tempElementSelectCheck.filter((f) => { return e.elm_id == f })
                    if (temp.length > 0) {
                        e.elm_isSelect = true;
                    }
                });

                setElementData(response.data);
                setElementDataEdit(response.data);
                setTest({ ...test, test: '' });
                test.test = '';
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function getDetailProcessProgress() {
        let temp = {
            project_id: project_id,
            menu_id: menu_id,
            process_id: process_id
        }
        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/project/getDetailProcessProgress`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                setProcessStage({
                    ...processStage,
                    module_name: response.data.module_name,
                    menu_name: response.data.menu_name,
                    menu_id: menu_id,
                    status_id: response.data.status_id,
                    status_name: response.data.status_name,
                    process_name: response.data.process_name,
                    process_id: process_id,
                    project_id: project_id,
                    project_name: response.data.project_name,
                    spaceship_id: response.data.spaceship_id,
                    module_id: response.data.module_id,
                })
                if (response.data.userList) {
                    setUserPercentList(response.data.userList);

                    let temp = imgData;
                    for (let item of response.data.userList) {
                        if (item.fd_img_id) {
                            let checkIsImg = temp.filter((e) => { return e.fd_img_id == item.fd_img_id });
                            if (checkIsImg.length > 0) {
                                item.ul_file_path = checkIsImg[0].ul_file_path;
                            } else {
                                let response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                    },
                                    responseType: 'blob',
                                });

                                let imgUrl = URL.createObjectURL(response2.data);

                                temp.push({
                                    fd_img_id: item.fd_img_id,
                                    ul_file_path: imgUrl
                                });

                                item.ul_file_path = imgUrl;
                            }
                        }

                        setImgData(temp);
                        setTest({ ...test, test: '' })
                        test.test = ''
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getMomAttendantData() {
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/srsMenu/getMomAttendantEdit/${callbackSrsData.edit_sm_id}`,
            headers: {
                /*     Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            }
        })
            .then(async function (response) {
                // console.log(response.data);
                // if (response.data.length > 0) {
                //     const resData = response.data;
                //     console.log(resData);
                //     resData.forEach(e => {
                //         e.person.sort((a, b) => {
                //             return new Date(a.mp_created_date) - new Date(b.mp_created_date);
                //         })
                //     });
                //     setmomAttendantData(resData);
                //     setDisplayMomAttendant(true);
                // } else {
                //     setDisplayMomAttendant(false);
                // }
                if (response.data.length > 0) {
                    let check = response.data.filter((fil) => { return fil.mom_id == callbackSrsData.edit_sm_mom_id });
                    if (check.length > 0) {
                        setMomAttendantData(check || []);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if (isOpen) {
            console.log("TEN", percentTotal)
            let getUserPermisstionMode = sessionStorage.getItem('getUserMode')
            /*  console.log('cream',getUserPermisstionMode); */
            setDisplayMode(getUserPermisstionMode)

            getDetailProcessProgress();
            getElementById()

        } else {
            setDisplayMode(false)
        }


    }, [isOpen])


    useEffect(() => {
        getElementById()
    }, [])


    function changeProcessStage(status_id) {
        setProcessStage({ ...processStage, status_id: status_id });
        processStage.status_id = status_id;
    }

    function callbackUserPercent(dataList) {
        setUserPercentList(dataList);
    }

    function callbackSetPercentTotal(dataList) {
        setPercentTotal(dataList);
    }

    function saveProcessStage() {
        let temp = {
            user_progress_list: userPercentList,
            project_id: project_id,
            process_id: process_id,
            menu_id: menu_id,
            status_id: processStage.status_id,
            percent_total: percentTotal
        };

        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/project/saveProcessStage`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                if (response.data) {
                    socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'ProjectMonitoring' });
                    toggle_modal_status();
                    getProjectDetail(project_id);
                }
            })
            .catch(function (error) {
                console.log(error);
                setAlertKick(true);
            });
    }

    function callBackElement() {
        let temp = elementData.element.filter((e) => {
            return e.elm_isSelect == true
        })

        setElementSend(temp)
    }

    function callBacktoScreenshot() {
        if (elementFigmalink) {
            toggle_modal_ElementFigmalink();
        };
        toggle_modal_elementScreenshot()
    }

    function callBacktoFigmalink() {

        toggle_modal_ElementFigmalink()
    }

    function callBacktoUserMun() {
        toggle_modal_ElementUserManualstage()
    }

    function callBacktoUserUAT() {
        toggle_modal_ElementUsertageUATStage()
    }

    function callBacktoTestCase() {
        toggle_modal_ElementTestCase()
    }
    // function resetElementSelect() {
    //     let temp = elementData;
    //     let index = 0;
    //     let temp2 = [];
    //     for (let item of temp.element) {
    //         if (index == 0) {
    //             item.elm_isSelect = true;
    //             temp2.push(item);
    //         } else {
    //             item.elm_isSelect = false;
    //         }
    //         index++;
    //     }

    //     setElementData(temp);
    //     setElementSend(temp2);

    // }

    const [swapStateForSrsArea, setSwapStateForSrsArea] = useState(false)
    const toggle_modal_swapStateForSrsArea = () => { setSwapStateForSrsArea(!swapStateForSrsArea) }

    function swapStateForSrsAreaFunc() {
        toggle_modal_swapStateForSrsArea()
        toggle_modal_confrimModal()
    }

    function resetAllData() {

    }

    const [fileUploadList, setFileUploadList] = useState([]);
    function callbackSrsDataFunc(value) {
        // console.log('value1', value)
        toggle_modal_editSrsData();

        let tempFileList = [];
        let tempImageList = [];
        let tempElement = [];

        if (value.element.length > 0) {

            for (let item of value.element) {
                let temp = [];
                temp = {
                    edit_elm_id: item.elm_id,
                    edit_elm_img_path: item.elm_img_path,
                    edit_elm_name: item.elm_name
                }

                tempElement.push(temp)
            }
        }

        if (value.fileList.length > 0) {
            let checkFile = value.fileList.filter((e) => { return e.fd_keyfile == 'FILE' })
            let checkImage = value.fileList.filter((e) => { return e.fd_keyfile == 'IMAGE' })

            for (let item of checkFile) {
                let temp = [];
                temp = {
                    file_id: item.fd_img_id,
                    edit_ul_file_name: item.fd_origin_name,
                    edit_ul_file_path: item.fd_origin_name,
                    edit_ul_type: item.fd_keyfile
                }
                tempFileList.push(temp);
            }

            for (let item of checkImage) {
                let temp = [];
                temp = {
                    file_id: item.fd_img_id,
                    edit_ul_file_name: item.fd_origin_name,
                    edit_ul_file_path: item.fd_origin_name,
                    edit_ul_type: item.fd_keyfile
                }
                tempImageList.push(temp);
            }
        }

        // console.log('testlog', tempFileList, value.fileList);
        setFileUploadList(value.file_id_list || []);
        setCallbackSrsData({
            project_id: value.sm_project_id,
            file_id_list: value.file_id_list || [],
            edit_sm_comment: value.sm_comment,
            edit_sm_id: value.sm_id,
            edit_sm_menu_id: value.sm_menu_id,
            edit_sm_process_id: value.sm_process_id,
            edit_sm_project_id: value.sm_project_id,
            edit_usr_ttt_id: value.usr_ttt_id,
            edit_fileList: tempFileList,
            edit_imageList: tempImageList,
            edit_element: tempElement,
            edit_sm_index: value.sm_index,
            edit_sm_mom_id: value.sm_mom_id,
            edit_sm_follow_case: value.sm_follow_case,
            edit_sm_follow_name: value.sm_follow_name || null
        });
        callbackSrsData.project_id = value.project_id;
        callbackSrsData.file_id_list = value.file_id_list || [];
        callbackSrsData.edit_sm_comment = value.sm_comment;
        callbackSrsData.edit_sm_id = value.sm_id;
        callbackSrsData.edit_sm_menu_id = value.sm_menu_id;
        callbackSrsData.edit_sm_process_id = value.sm_process_id;
        callbackSrsData.edit_sm_project_id = value.sm_project_id;
        callbackSrsData.edit_usr_ttt_id = value.usr_ttt_id;
        callbackSrsData.edit_fileList = value.tempFileList;
        callbackSrsData.edit_imageList = value.tempImageList;
        callbackSrsData.edit_element = value.tempElement;
        callbackSrsData.edit_sm_index = value.sm_index;
        callbackSrsData.edit_sm_mom_id = value.sm_mom_id;
        callbackSrsData.edit_sm_follow_case = value.sm_follow_case;
        callbackSrsData.edit_sm_follow_name = value.sm_follow_name || null;

        let temp$ = elementDataEdit.element;

        for (let item of temp$) {

            let check = value.element.filter((e) => {
                return e.elm_id == item.elm_id;
            })
            if (check.length > 0) {
                item.isSelect = true;
            } else {
                item.isSelect = false;
            }

        }
    }

    async function CheckAndCreateFolderBox(ref_id, folder_name, callback) {
        let temp = {
            folderLis: ["PROJECT", `PROJECT_${ref_id}`, "STAGE MONITOR CHAT", "PROCESS", folder_name]
        }
        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            }, data: temp,
        })
            .then(async function (response) {
                if (response.data) {
                    let folder_id = response.data.folder_id;
                    if (callback) {
                        let res = {
                            ref_id: ref_id,
                            folder_id: folder_id,
                            folder_name: folder_name,
                        }
                        await callback(res)
                    }

                    /* UploadFiles(folder_id,folder_name,fed_id,file); */
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function UploadFiles(folder_id, key, ref_id, file, callback) {
        let image_id_list = fileUploadList || [];
        let data_ = new FormData();
        data_.append('File', file);
        data_.append('folder_id', folder_id);
        data_.append('key', key);
        data_.append('image_old_list', JSON.stringify(image_id_list));
        data_.append('ref_id', ref_id);

        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "multipart/form-data",
            }, data: data_,
        })
            .then(async function (response) {
                let temp = fileUploadList || [];
                for (let item of response.data.image_id_list) {
                    temp.push(item);
                }
                setFileUploadList(temp);
                setTest({ ...test, test: '' });
                test.test = '';
                if (callback) {

                    await callback(true)
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function sendEditSrsAreaData() {
        // console.log('testlog data',callbackSrsData);
        let temp = callbackSrsData;
        console.log('value', temp)




        let fileList = [];
        let uploadFile = temp.edit_fileList.filter((e) => { return e.isNew == true });
        let uploadImage = temp.edit_imageList.filter((e) => { return e.isNew == true });


        /*         console.log('upload',uploadFile)
                console.log('upload',uploadImage) */

        /*         let uploadFileOld = temp.edit_fileList.filter((e) => { return !e.isNew });
                let uploadImageOld = temp.edit_imageList.filter((e) => { return !e.isNew }); */
        /*    if (uploadFile.length > 0) {
               const dataFile = new FormData();
               for (let i = 0; i < uploadFile.length; i++) {
                   console.log(uploadFile[i].file)
                   dataFile.append('Profile', uploadFile[i].file);
               }
               dataFile.append('typeFile', "FileUploadProcessElement");
               dataFile.append('projectKey', "TTTSPACESHIP");
   
               await axios({
                   method: 'post',
                   url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                   headers: {
                    
                       'X-TTT': `${Configs.API_TTT}`,
                       'Content-Type': 'multipart/form-data',
                   },
                   data: dataFile,
               })
                   .then(async function (response) {
                       if (response.data.status == true) {
                           response.data.data.forEach((e) => {
                               const tmp = {
                                   name: e.orgin_name,
                                   path: e.path,
                                   is_save: true,
                                   ul_is_use: true,
                                   ul_file_size: e.size,
                                   url: e.path,
                                   ul_type: 'FILE',
                                   ul_group_name: "ProcessElement",
                                   ul_file_name: e.orgin_name,
                                   ul_file_path: e.path,
                                   ul_menu_name: "ProcessElementChange",
                                   ul_is_profile: false,
                                   status: 'done'
   
                               }
                               fileList.push(tmp);
                           })
                       }
   
                   })
                   .catch(function (error) {
                       console.log(error);
                   });
           }
   
           if (uploadImage.length > 0) {
               const dataImage = new FormData();
               for (let i = 0; i < uploadImage.length; i++) {
   
                   dataImage.append('Profile', uploadImage[i].file);
               }
               dataImage.append('typeFile', "ImageUploadProcessElement");
               dataImage.append('projectKey', "TTTSPACESHIP");
   
               console.log('dataImage', dataImage);
               await axios({
                   method: 'post',
                   url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                   headers: {
                  
                       'X-TTT': `${Configs.API_TTT}`,
                       'Content-Type': 'multipart/form-data',
                   },
                   data: dataImage,
               })
                   .then(async function (response) {
                       if (response.data.status == true) {
                           response.data.data.forEach((e) => {
                               const tmp = {
                                   name: e.orgin_name,
                                   path: e.path,
                                   is_save: true,
                                   ul_is_use: true,
                                   ul_file_size: e.size,
                                   url: e.path,
                                   ul_type: 'IMAGE',
                                   ul_group_name: "ProcessElement",
                                   ul_file_name: e.orgin_name,
                                   ul_file_path: e.path,
                                   ul_menu_name: "ProcessElementChange",
                                   ul_is_profile: false,
                                   status: 'done'
   
                               }
                               fileList.push(tmp);
                           })
                       }
   
                   })
                   .catch(function (error) {
                       console.log(error);
                   });
           } */


        /*    for (let item of uploadFileOld) {
               let temp = {
   
                   name: item.edit_ul_file_name,
                   path: item.edit_ul_file_path,
                   is_save: false,
                   ul_is_use: true,
                   ul_file_size: null,
                   url: item.edit_ul_file_path,
                   ul_type: 'IMAGE',
                   ul_group_name: 'ProcessElement',
                   ul_file_name: item.edit_ul_file_name,
                   ul_file_path: item.edit_ul_file_path,
                   ul_menu_name: 'ProcessElementChange',
                   ul_is_profile: false,
                   status: 'done'
   
               };
               fileList.push(temp);
           }
   
           for (let item of uploadImageOld) {
               let temp = {
   
                   name: item.edit_ul_file_name,
                   path: item.edit_ul_file_path,
                   is_save: false,
                   ul_is_use: true,
                   ul_file_size: null,
                   url: item.edit_ul_file_path,
                   ul_type: 'IMAGE',
                   ul_group_name: 'ProcessElement',
                   ul_file_name: item.edit_ul_file_name,
                   ul_file_path: item.edit_ul_file_path,
                   ul_menu_name: 'ProcessElementChange',
                   ul_is_profile: false,
                   status: 'done'
   
               };
               fileList.push(temp);
           } */

        // console.log('testlog te,p', temp);
        let checkTag = temp.edit_sm_comment.split('*');
        let tagData = [];
        checkTag.length > 0 && checkTag.map((e, index) => {
            let fil = userPercentList.filter((f) => { return e == f.first_name + ' ' + f.last_name })
            let checkFil = tagData.filter((cf) => { return e == cf.first_name + ' ' + cf.last_name })
            if (fil.length > 0 && checkFil.length == 0) {
                tagData.push(fil[0])
            }

        });

        temp.tagData = tagData;
        temp.fileList = fileList;

        console.log()

        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/srsMenu/editSRSArea`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                if (response.data.id) {
                    if (uploadFile.length > 0) {
                        await CheckAndCreateFolderBox(temp.project_id, "FILE", async (result) => {
                            if (result) {
                                let index = 0;
                                for await (let item of uploadFile) {
                                    await UploadFiles(result.folder_id, result.folder_name, response.data.id, item.file, async (result2) => {
                                        if (result2) {
                                            console.log('LEK_FILE_FILE_PROCESS', index)
                                            if (index + 1 == uploadFile.length) {
                                                /*   setFileUploadList([]); */
                                                /*    Swal.fire({
                                                       icon: "success",
                                                       title: "Save",
                                                       showConfirmButton: false,
                                                       timer: 1500,
                                                     }); */
                                            }

                                            index++;
                                        }
                                    })
                                }
                            }
                        });
                    } else {
                        await CheckAndCreateFolderBox(temp.project_id, "FILE", async (result) => {
                            if (result) {
                                let index = 0;

                                await UploadFiles(result.folder_id, result.folder_name, response.data.id, null, async (result2) => {
                                    if (result2) {
                                        console.log('LEK_FILE_FILE_PROCESS', index)
                                        if (index + 1 == uploadFile.length) {
                                            /*   setFileUploadList([]); */
                                            /*    Swal.fire({
                                                   icon: "success",
                                                   title: "Save",
                                                   showConfirmButton: false,
                                                   timer: 1500,
                                                 }); */
                                        }

                                        index++;
                                    }
                                })

                            }
                        });
                    }

                    if (uploadImage.length > 0) {
                        await CheckAndCreateFolderBox(temp.project_id, "IMAGE", async (result) => {
                            if (result) {
                                let index = 0;
                                for await (let item of uploadImage) {
                                    await UploadFiles(result.folder_id, result.folder_name, response.data.id, item.file, async (result2) => {
                                        if (result2) {
                                            console.log('LEK_FILE_IMAGE_PROCESS', index)
                                            if (index + 1 == uploadImage.length) {
                                                /*  setFileUploadList([]); */
                                                /*    Swal.fire({
                                                       icon: "success",
                                                       title: "Save",
                                                       showConfirmButton: false,
                                                       timer: 1500,
                                                     }); */
                                            }

                                            index++;
                                        }
                                    })
                                }
                            }
                        });
                    } else {
                        await CheckAndCreateFolderBox(temp.project_id, "IMAGE", async (result) => {
                            if (result) {
                                let index = 0;

                                await UploadFiles(result.folder_id, result.folder_name, response.data.id, null, async (result2) => {
                                    if (result2) {
                                        console.log('LEK_FILE_FILE_PROCESS', index)
                                        if (index + 1 == uploadFile.length) {
                                            /*   setFileUploadList([]); */
                                            /*    Swal.fire({
                                                   icon: "success",
                                                   title: "Save",
                                                   showConfirmButton: false,
                                                   timer: 1500,
                                                 }); */
                                        }

                                        index++;
                                    }
                                })

                            }
                        });
                    }
                    console.log('LEK_FILE_SUCCESS')
                    setUpdateEditSrsData({ ...updateEditSrsData, no: updateEditSrsData.no + 1 })
                    updateEditSrsData.no = updateEditSrsData.no + 1;
                    toggle_modal_editSrsData();
                }
                /*    if (response.data) {
                
                   } */
            })
            .catch(function (error) {
                console.log(error);
            });

        if (tagData.length > 0) {
            processStage.index = callbackSrsData.edit_sm_index;
            for (let item of tagData) {
                let tempNoti = {
                    header: "TAG YOU ON " + processStage.project_name + '>' + processStage.process_name + '>' + processStage.module_name + '>' + processStage.menu_name,
                    // header:"คุณได้รับคำเชิญเข้าร่วมยาน",
                    // body:"คุณได้รับคำเชิญจาก " + sessionStorage.getItem('user') + " เพื่อเข้าร่วมยาน " + spaceShipName + " คุณจะยอมรับคำเชิญหรือไม่ ?",
                    noti_menu_name: "tag_project",
                    user_to_id: item.usr_id,
                    user_to_name: item.first_name + ' ' + item.last_name,
                    link_to: JSON.stringify(processStage),
                    space_ship_id: null
                }
                await axios({
                    method: 'POST',
                    url: `${Configs.TTT_Game_Notification_Service}/api/notification/addNotification`,
                    headers: {
                        Authorization: sessionStorage.getItem('access_token'),
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'application/json',
                    },
                    data: tempNoti,
                })
                    .then(async function (response) {
                        // setSendData([])
                        socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + item.usr_id });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }

        if (tagData.length > 0) {
            let tempMail = {
                userby: 'TTT',
                tagData: tagData || [],
                comment: temp.edit_sm_comment,
                userSend: sessionStorage.getItem('user'),
                where: processStage.project_name + ' > ' + processStage.process_name + ' > ' + processStage.module_name + ' > ' + processStage.menu_name
            };
            axios({
                method: 'POST',
                url: `${Configs.TTT_Game_Service}/api/mail/sendMailChatTag`,
                headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: tempMail
            })
                .then(function (response) {

                })
                .catch(function (error) {
                    console.log(error);


                });
        }
    }

    const renderNames = () => {
        let split = (callbackSrsData.edit_sm_comment || '').toString().split(' ');
        let index = 0;
        if (split.length > 0) {
            for (let item of split) {
                if (item.includes('@') && split.length == index + 1) {
                    if (item.split('@')[1].split(' ')[0] == '' && item.split('@')[1].split(' ')[0] != ' ') {
                        const check = userPercentList.filter((e) => { return e.usr_id != sessionStorage.getItem('user_id') });
                        return check.length > 0 && check.map((e) => {
                            return (<>
                                <div className='srsAreaTagUser' onClick={() => {
                                    let parts = (callbackSrsData.edit_sm_comment || '').toString().split(' ');
                                    let indexParts = 0;
                                    let stringParts = '';
                                    for (let item of parts) {
                                        if (item.includes('@') && parts.length == indexParts + 1) {
                                            stringParts += '*' + e.first_name + ' ' + e.last_name + '* ';
                                        } else {
                                            stringParts += item + ' ';
                                        }
                                        indexParts++
                                    }
                                    setCallbackSrsData({ ...callbackSrsData, edit_sm_comment: stringParts });
                                    callbackSrsData.edit_sm_comment = stringParts;
                                }}>
                                    <div className='setTagNameBoxImg' style={{ width: '13%', height: '40px', backgroundImage: `url(${e.ul_file_path != null ? Configs.TTT_Game_Service_IMG + e.ul_file_path : DefaultUserProfile})` }}></div>
                                    <div className='setTagNameBox' style={{ width: '25%' }}>{e.ttt_id}</div>
                                    <div className='setTagNameBox' style={{ width: '62%' }}>{e.first_name + ' ' + e.last_name}</div>
                                </div>
                            </>)
                        });
                    } else if (item.split('@')[1].split(' ')[0] != ' ') {
                        const names = item.split('@')[1].split(' ');
                        const check = userPercentList.filter((e) => { return e.usr_id != sessionStorage.getItem('user_id') && e.first_name.toLowerCase().startsWith(names[0].toLowerCase()) });
                        return <>
                            {check.length > 0 ?
                                <div className='srsAreaTagUser' onClick={() => {
                                    let parts = (callbackSrsData.edit_sm_comment || '').toString().split(' ');
                                    let indexParts = 0;
                                    let stringParts = '';
                                    for (let item of parts) {
                                        if (item.includes('@') && parts.length == indexParts + 1) {
                                            stringParts += '*' + check[0].first_name + ' ' + check[0].last_name + '* ';
                                        } else {
                                            stringParts += item + ' ';
                                        }
                                        indexParts++
                                    }
                                    setCallbackSrsData({ ...callbackSrsData, edit_sm_comment: stringParts });
                                    callbackSrsData.edit_sm_comment = stringParts;
                                }}>
                                    <div className='setTagNameBoxImg' style={{ width: '13%', height: '40px', backgroundImage: `url(${check[0].ul_file_path != null ? Configs.TTT_Game_Service_IMG + check[0].ul_file_path : DefaultUserProfile})` }}></div>
                                    <div className='setTagNameBox' style={{ width: '25%' }}>{check[0].ttt_id}</div>
                                    <div className='setTagNameBox' style={{ width: '62%' }}>{check[0].first_name + ' ' + check[0].last_name}</div>
                                </div>
                                :
                                <div className='srsAreaTagUser' style={{ display: 'none' }}></div>
                            }
                        </>;
                    }
                }
                index++
            }
        }
        return null;
    };


    function callBackScreenshot() {
        getElementById()
    }

    function callBackCodeGen() {
        toggle_modal_elementBackEndCodeGen()
    }



    async function GetAccesstokenToken(callback) {
        await axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                /* Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
                var temp = {
                    tokenBox: response.data.tokenBox
                }
                if (callback) {
                    callback(response.data.tokenBox);
                }
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
                /*  console.log('ViewToken', response.data); */
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function getFileBoxByIdImage(image_id, callback) {
        await axios({
            method: "get",
            url: `https://api.box.com/2.0/files/${image_id}/content`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',

        })
            .then(async function (response) {

                if (callback) {
                    let tmp = {
                        fileImg: URL.createObjectURL(response.data),
                        file_id: image_id,

                    }
                    callback(tmp);
                }

            }).catch((err) => {

                if (callback) {
                    let tmp = {
                        fileImg: null,
                        file_id: null,

                    }
                    callback(tmp);
                }
            })

    }

    useEffect(() => {
        if (editSrsData) {
            getMomAttendantData();
        }
    }, [editSrsData])


    return (
        <>
            <ElementScreenshot
                isOpen={elementScreenshot}
                toggle_modal_status={toggle_modal_elementScreenshot}
                project_id={project_id}
                process_id={process_id}
                menu_id={menu_id}
                getProjectDetail={getProjectDetail}
                processStage={processStage}
                callBackScreenshot={callBackScreenshot}
                displayMode={displayMode}

            />
            <ElementBackendCodeGen
                isOpen={elementBackEndCodeGen}
                toggle_modal_status={toggle_modal_elementBackEndCodeGen}
                project_id={project_id}
                displayMode={displayMode}
            />
            <ElementFigmalink
                isOpen={elementFigmalink}
                toggle_modal_status={toggle_modal_ElementFigmalink}
                project_id={project_id}
                process_id={process_id}
                menu_id={menu_id}
                callBackFigmalink={callBackScreenshot}
                displayMode={displayMode}
            /*    getProjectDetail={getProjectDetail}
               processStage={processStage}
               callBackScreenshot={callBackScreenshot} */
            />

            <ElementUserManual
                isOpen={elementUserManualstage}
                toggle_modal_status={toggle_modal_ElementUserManualstage}
                project_id={project_id}
                process_id={process_id}
                menu_id={menu_id}
                callBackFigmalink={callBackScreenshot}
                displayMode={displayMode}
            /*    getProjectDetail={getProjectDetail}
               processStage={processStage}
               callBackScreenshot={callBackScreenshot} */
            />
            <ModalProcessSrs
                toggle_modal_process_srs={toggle_modal_ElementUsertageUATStage}
                isOpen={elementUsertageUATStage}
                project_id={project_id}
                menu_id={menu_id}
            //  projectDetail={projectDetail}
            //  getProjectDetail={getProjectDetail}
            />
            <ElementTestCase
                toggle_modal_process_srs={toggle_modal_ElementTestCase}
                isOpen={elementTestCase}
                project_id={project_id}
                menu_id={menu_id}
                process_id={process_id}
            //  projectDetail={projectDetail}
            //  getProjectDetail={getProjectDetail}
            />
            {alertKick === true ?
                <div className='alertKick' style={{ zIndex: '9999999' }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox'>
                            <div className='alertKickBoxIn'>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead'>
                                        ! ERROR !
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning'>
                                        WARNING
                                    </div>
                                </div>
                                <div className='row' style={{ height: '12.5%' }}>
                                    <div className='alertKickText'>
                                        Status must be 100% before approved
                                    </div>
                                </div>
                                <div className='row' style={{ height: '25%' }}>
                                    {/*  <div className='alertKickTextSec'>
                                                            YOU CAN NOT GET OFF FROM THIS SPACESHIP
                                                        </div> */}
                                </div>
                                <div className='row alertKickBoxButton'>
                                    <button className='alertKickButton' onClick={() => {
                                        toggle_modal_alertKick()
                                    }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <></>}

            <div className='stageElementBg' style={{ display: `${isOpen ? 'flex' : 'none'}` }}>
                <div className='stageElementBgClose' onClick={() => {
                    setIsOpenTimeSheet(false);
                    resetAllData();
                    toggle_modal_status();
                    if (elementFigmalink) {
                        toggle_modal_ElementFigmalink();
                    };
                    if (elementUserManualstage) {
                        toggle_modal_ElementUserManualstage();
                    };
                    if (elementUsertageUATStage) {
                        toggle_modal_ElementUsertageUATStage();
                    };
                }}></div>
                <div className='stageElementBoxShadow'>
                    <div className='stageElementBox'>
                        <div className='stageElementBoxIn'>

                            <div className='stageElementHeader'>
                                <i class="fa fa-times" aria-hidden="true" style={{ fontSize: "20px", cursor: 'pointer', position: 'absolute', right: '0.5%', top: '0.5%' }} onClick={() => {
                                    socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'ProjectMonitoring' });
                                    setIsOpenTimeSheet(false);
                                    toggle_modal_status();
                                    if (elementDetailCheck) {
                                        toggle_modal_elementDetailCheck();
                                    };
                                    if (elementFigmalink) {
                                        toggle_modal_ElementFigmalink();
                                    };
                                    if (elementUserManualstage) {
                                        toggle_modal_ElementUserManualstage();
                                    };
                                    if (elementUsertageUATStage) {
                                        toggle_modal_ElementUsertageUATStage();
                                    };
                                }} />

                                <div className='stageElementHeaderLeft'>
                                    <div className='stageElementHeaderSrs'>{processStage.process_name}</div>
                                    <div className='stageElementHeaderLine'></div>
                                    <div className='stageElementHeaderMenu'>{processStage.module_name} {'>'} {processStage.menu_name}</div>
                                </div>

                                <div className='stageElementHeaderRight'>
                                    <div className='stageElementHeaderSaveButton' style={{ cursor: `${displayMode == false || displayMode == 'false' ? 'pointer' : 'no-drop'}` }}
                                        onClick={() => {
                                            /* if (displayMode == false || displayMode == 'false') {
                                                saveProcessStage()
                                            } else  */
                                            if (percentTotal < 100 && processStage.status_id == '6e6f2408-6a06-4f93-8f0a-aa385cfc4188') {
                                                setAlertKick(true)
                                                // console.log('status : ',processStage.status_id)
                                                // console.log('percent : ' ,percentTotal)

                                            }
                                            /* else if (percentTotal == 100 && processStage.status_id !== '6e6f2408-6a06-4f93-8f0a-aa385cfc4188' ) {
                                                    setAlertKick(true)
                                                } */
                                            else {
                                                saveProcessStage()
                                                // setPercentTotal(null)
                                                // console.log('status2 : ',processStage.status_id)
                                                // console.log('percent2 : ' ,percentTotal)
                                            }
                                        }
                                        }
                                    >SAVE CHANGE</div>
                                </div>
                            </div>

                            <div className='stageElementBody'>
                                <div className='stageElementLeft'>
                                    <div className='stageElementLeftHeader'>
                                        <div className='stageElementLeftText'>TIMELINE</div>

                                        <ElementComponent
                                            elementData={elementData}
                                            displayMode={displayMode}
                                            callBackElement={callBackElement}
                                            callBacktoScreenshot={callBacktoScreenshot}
                                            callBacktoFigmalink={callBacktoFigmalink}
                                            callBacktoUserMun={callBacktoUserMun}
                                            toggle_modal_elementDetailCheck={toggle_modal_elementDetailCheck}
                                            callBackBackEnd={toggle_modal_elementBackEndCodeGen}
                                            callBacktoUserUAT={callBacktoUserUAT}
                                            callBacktoTestCase={callBacktoTestCase}
                                        />
                                    </div>

                                    <div className='stageElementLeftBody'>
                                        <SrsArea
                                            isOpen={isOpen}
                                            project_id={project_id}
                                            process_id={process_id}
                                            menu_id={menu_id}
                                            // elementSend_={elementSend}
                                            // resetElementSelect={resetElementSelect}
                                            toggle_modal_confrimModal={toggle_modal_confrimModal}
                                            swapStateForSrsArea={swapStateForSrsArea}
                                            callbackSrsDataFunc={callbackSrsDataFunc}
                                            updateEditSrsData={updateEditSrsData}
                                            elementData={elementData}
                                            dataUserPercentList={userPercentList}
                                            processStage={processStage}
                                            setIndexChat={setIndexChat}
                                            setToCardStage={setToCardStage}
                                            imgData={imgData}
                                            setImgData={setImgData}
                                        />
                                    </div>
                                </div>

                                <div className='stageElementRight'>

                                    <div className={isOpenTimeSheet == true ? 'elementTimeSheetActive' : 'elementTimeSheet'} onClick={() => { toggle_modal_time_sheet(); }}>
                                        <img src={TimeSheetIcon} style={{ width: '100%', marginRight: '-3px' }} />
                                    </div>
                                    <UserProgress dataUserPercentList={userPercentList} callbackUserPercent={callbackUserPercent} displayMode={displayMode} percentTotal={callbackSetPercentTotal} />

                                    <div className='colorProcessBox'>
                                        <div className='colorProcessBoxIn'>
                                            <div className='colorProcessBody_'>
                                                <div className={`colorProcessBoxClick1 ${processStage.status_id == '02e2fbdb-1350-48aa-8bc6-e86c07f9035b' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        if (displayMode == false || displayMode == 'false') {
                                                            changeProcessStage('02e2fbdb-1350-48aa-8bc6-e86c07f9035b');
                                                        }
                                                    }}
                                                >

                                                    <div className='colorProcessBoxClick1Box' style={{ border: `1px solid ${processStage.status_id == '02e2fbdb-1350-48aa-8bc6-e86c07f9035b' ? '#676767' : 'white'}` }}>
                                                        <div className='colorProcessBoxClick1BoxInMain'></div>
                                                        <div className='colorProcessBoxClick1BoxInSec'></div>
                                                    </div>
                                                    <svg height="100%" width="100%" style={{ position: 'relative', top: '-100%' }}>
                                                        <line x1="0%" y1="15%" x2="15%" y2="0%" style={{ stroke: `${processStage.status_id == '02e2fbdb-1350-48aa-8bc6-e86c07f9035b' ? '#676767' : 'white'}`, strokeWidth: '1px' }} />
                                                        <line x1="80%" y1="100%" x2="100%" y2="80%" style={{ stroke: `${processStage.status_id == '02e2fbdb-1350-48aa-8bc6-e86c07f9035b' ? '#676767' : 'white'}`, strokeWidth: '1px' }} />
                                                        <line x1="20%" y1="1%" x2="100%" y2="1%" style={{ stroke: `${processStage.status_id == '02e2fbdb-1350-48aa-8bc6-e86c07f9035b' ? '#676767' : 'white'}`, strokeWidth: '7px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)' }} />
                                                    </svg>
                                                </div>

                                                <div className={`colorProcessBoxClick2 ${processStage.status_id == '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        if (displayMode == false || displayMode == 'false') {
                                                            changeProcessStage('4ded34bc-1d60-4512-b0ad-8c8daf16c0d8');
                                                        }

                                                    }}
                                                >
                                                    <div className='colorProcessBoxClick1Box' style={{ border: `1px solid ${processStage.status_id == '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8' ? '#F00' : 'white'}` }}>
                                                        <div className='colorProcessBoxClick1BoxInMain' style={{ backgroundColor: 'rgba(255, 0, 0, 1)' }}></div>
                                                        <div className='colorProcessBoxClick1BoxInSec' style={{ backgroundColor: 'rgba(255, 0, 0, 1)' }}></div>
                                                    </div>
                                                    <svg height="100%" width="100%" style={{ position: 'relative', top: '-100%' }}>
                                                        <line x1="0%" y1="15%" x2="15%" y2="0%" style={{ stroke: `${processStage.status_id == '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8' ? '#F00' : 'white'}`, strokeWidth: '1px' }} />
                                                        <line x1="80%" y1="100%" x2="100%" y2="80%" style={{ stroke: `${processStage.status_id == '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8' ? '#F00' : 'white'}`, strokeWidth: '1px' }} />
                                                        <line x1="20%" y1="1%" x2="100%" y2="1%" style={{ stroke: `${processStage.status_id == '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8' ? '#F00' : 'white'}`, strokeWidth: '7px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)' }} />
                                                    </svg>
                                                </div>

                                                <div className={`colorProcessBoxClick3 ${processStage.status_id == 'f00e63f2-ab27-4828-9d7a-f9316c28703e' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        if (displayMode == false || displayMode == 'false') {
                                                            changeProcessStage('f00e63f2-ab27-4828-9d7a-f9316c28703e');
                                                        }
                                                    }}
                                                >
                                                    <div className='colorProcessBoxClick1Box' style={{ border: `1px solid ${processStage.status_id == 'f00e63f2-ab27-4828-9d7a-f9316c28703e' ? '#FFEB3B' : 'white'}` }}>
                                                        <div className='colorProcessBoxClick1BoxInMain' style={{ backgroundColor: 'rgba(255, 235, 59, 1)' }}></div>
                                                        <div className='colorProcessBoxClick1BoxInSec' style={{ backgroundColor: 'rgba(255, 235, 59, 1)' }}></div>
                                                    </div>
                                                    <svg height="100%" width="100%" style={{ position: 'relative', top: '-100%' }}>
                                                        <line x1="0%" y1="15%" x2="15%" y2="0%" style={{ stroke: `${processStage.status_id == 'f00e63f2-ab27-4828-9d7a-f9316c28703e' ? '#FFEB3B' : 'white'}`, strokeWidth: '1px' }} />
                                                        <line x1="80%" y1="100%" x2="100%" y2="80%" style={{ stroke: `${processStage.status_id == 'f00e63f2-ab27-4828-9d7a-f9316c28703e' ? '#FFEB3B' : 'white'}`, strokeWidth: '1px' }} />
                                                        <line x1="20%" y1="1%" x2="100%" y2="1%" style={{ stroke: `${processStage.status_id == 'f00e63f2-ab27-4828-9d7a-f9316c28703e' ? '#FFEB3B' : 'white'}`, strokeWidth: '7px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)' }} />
                                                    </svg>
                                                </div>

                                                <div className={`colorProcessBoxClick4 ${processStage.status_id == '6e6f2408-6a06-4f93-8f0a-aa385cfc4188' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        if (displayMode == false || displayMode == 'false') {
                                                            changeProcessStage('6e6f2408-6a06-4f93-8f0a-aa385cfc4188');
                                                        }
                                                    }}
                                                >
                                                    <div className='colorProcessBoxClick1Box' style={{ border: `1px solid ${processStage.status_id == '6e6f2408-6a06-4f93-8f0a-aa385cfc4188' ? '#259B24' : 'white'}` }}>
                                                        <div className='colorProcessBoxClick1BoxInMain' style={{ backgroundColor: 'rgba(37, 155, 36, 1)' }}></div>
                                                        <div className='colorProcessBoxClick1BoxInSec' style={{ backgroundColor: 'rgba(37, 155, 36, 1)' }}></div>
                                                    </div>
                                                    <svg height="100%" width="100%" style={{ position: 'relative', top: '-100%' }}>
                                                        <line x1="0%" y1="15%" x2="15%" y2="0%" style={{ stroke: `${processStage.status_id == '6e6f2408-6a06-4f93-8f0a-aa385cfc4188' ? '#259B24' : 'white'}`, strokeWidth: '1px' }} />
                                                        <line x1="80%" y1="100%" x2="100%" y2="80%" style={{ stroke: `${processStage.status_id == '6e6f2408-6a06-4f93-8f0a-aa385cfc4188' ? '#259B24' : 'white'}`, strokeWidth: '1px' }} />
                                                        <line x1="20%" y1="1%" x2="100%" y2="1%" style={{ stroke: `${processStage.status_id == '6e6f2408-6a06-4f93-8f0a-aa385cfc4188' ? '#259B24' : 'white'}`, strokeWidth: '7px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)' }} />
                                                    </svg>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='stageElementElementDetailList' style={{ display: `${elementDetailCheck ? '' : 'none'}` }}>
                                {elementData.element && elementData.element.map((e) => {
                                    return (
                                        <div className='elementDetailBox'>
                                            <div className='elementDetailIconBox'>
                                                <div className='elementDetailIcon' style={{ backgroundImage: `url(${/* Configs.TTT_Game_Service_IMG + */ e.elm_img_path})` }}></div>
                                            </div>
                                            <div className='elementDetailTextBox'>
                                                <div className='elementDetailTextName'>{e.elm_name}</div>
                                                <div className='elementDetailTextDetail'>{e.elm_detail}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ModelAlertConfrim
                isOpen={displaySwal}
                mainFuc={() => swapStateForSrsAreaFunc()}
                toggle_alertKick={() => toggle_modal_confrimModal()}
            />
            <div className='editSrsAreaData' style={{ display: `${editSrsData ? '' : 'none'}` }}>
                <div className='editSrsAreaDataClose' onClick={() => {
                    toggle_modal_editSrsData();
                    setDropdown(false);
                }}></div>

                <div className='editSrsAreaDataBox'>

                    <div className='editSrsAreaDataBody'>
                        <div className='editSrsAreaDataHeader'>ELEMENT</div>
                        <div className='editSrsAreaDataElement'>
                            {elementDataEdit.element && elementDataEdit.element.map((e, index) => {
                                return (
                                    <div className='editSrsAreaDataElementBox' style={{ backgroundImage: `url(${e.elm_img_path})`, border: `1px solid ${e.isSelect ? 'gray' : '#0047FF'}` }} onClick={() => {
                                        let tempElement = elementDataEdit.element;

                                        let temp = tempElement.filter((e) => {
                                            return e.isSelect == true
                                        })

                                        if (temp.length > 0) {
                                            tempElement[index].isSelect = !e.isSelect;
                                        } else {
                                            tempElement[index].isSelect = true;
                                        }

                                        let data = callbackSrsData.edit_element
                                        let check = data.filter((f) => {
                                            return e.elm_id == f.edit_elm_id
                                        })

                                        /* if (temp.length == 1) {
                                            console.log('testlog 1');
                                            if (check.length == 0) {
                                                console.log('testlog 3');
                                                let check2 = data.filter((e2)=>{return e2.edit_elm_id == e.elm_id});
                                                let set = {
                                                    edit_elm_id: e.elm_id,
                                                    edit_elm_img_path: e.elm_img_path,
                                                    edit_elm_name: e.elm_name,
                                                }
                                                if(check2.length ==0){
                                                    data.push(set);
                                                }
                                            
                                            }

                                        } else */ if (temp.length >= 0) {
                                            // console.log('testlog 2');
                                            if (check.length > 0) {
                                                console.log('testlog 4');
                                                let indexMain = data.findIndex((g) => g.edit_elm_id == e.elm_id);
                                                if (indexMain > -1) {
                                                    data.splice(indexMain, 1);
                                                }

                                            } else {
                                                // console.log('testlog 5');
                                                let check2 = data.filter((e2) => { return e2.edit_elm_id == e.elm_id });

                                                let set = {
                                                    edit_elm_id: e.elm_id,
                                                    edit_elm_img_path: e.elm_img_path,
                                                    edit_elm_name: e.elm_name,
                                                }
                                                if (check2.length == 0) {
                                                    data.push(set);
                                                }

                                            }
                                        }
                                        setTest({ ...test, test: '' });
                                        test.test = '';
                                    }}></div>
                                )
                            })}
                        </div>
                        <div className='editSrsAreaDataHeader'>COMMENT <span style={{ color: 'red' }}>{errorEditSrsData ? '***' : ''}</span></div>
                        <textarea className='editSrsAreaDataComment'
                            value={callbackSrsData.edit_sm_comment}
                            onChange={(e) => {
                                setCallbackSrsData({ ...callbackSrsData, edit_sm_comment: e.target.value })
                                callbackSrsData.edit_sm_comment = e.target.value
                            }}
                        />
                        <div style={{ display: 'flex', height: '34%', gap: '15px' }}>
                            <div style={{ height: '100%', width: `${callbackSrsData.edit_sm_mom_id ? '70%' : '100%'}` }}>
                                <div onClick={clickDropdown} className='editSrsAreaDataHeader' style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>OTHER
                                    {Dropdown ? (
                                        <div style={{ fontSize: '150%', transform: 'rotate(-90deg)', cursor: 'pointer' }}>
                                            &#62;
                                        </div>
                                    ) : (
                                        <div style={{ fontSize: '150%', transform: 'rotate(90deg)', cursor: 'pointer' }}>
                                            &#62;
                                        </div>
                                    )}
                                </div>
                                <div style={{ width: '100%', height: '100%', whiteSpace: 'nowrap', display: 'block', overflowX: 'auto' }}>
                                    {Dropdown && (
                                        <div style={{ width: '100%', height: '35%' }}>
                                            <div className='edittext' style={{ borderBottom: '1px solid #0047ff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                FILE
                                                <div>
                                                    <label className='editChatAddFile' style={{ backgroundColor: '#039BE5' }} for="srsAreaUploadAddButtonFileEdit">
                                                        <i className="fa fa-plus" aria-hidden="true"></i> ADD FILE
                                                    </label>
                                                    <input
                                                        id='srsAreaUploadAddButtonFileEdit'
                                                        style={{ display: 'none' }}
                                                        type='file'
                                                        accept={['application/pdf', '.xls', '.xlsx', '.docx',
                                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                            'application/vnd.openxmlformats-officedocument.presentationml.presentation']}
                                                        multiple
                                                        onChange={(e) => {
                                                            if (e.target.files.length > 0) {
                                                                let temp = callbackSrsData.edit_fileList;
                                                                let no = temp.length;
                                                                for (let item of e.target.files) {
                                                                    let file = {
                                                                        no: no + 1,
                                                                        type: 'FILE',
                                                                        file_name: item.name,
                                                                        file: item,
                                                                        path: URL.createObjectURL(item),
                                                                        url: URL.createObjectURL(item),
                                                                        edit_ul_file_name: item.name,
                                                                        edit_ul_file_path: URL.createObjectURL(item),
                                                                        isNew: true
                                                                    }
                                                                    temp.push(file);
                                                                    no++;
                                                                }
                                                                setCallbackSrsData({ ...callbackSrsData, edit_fileList: temp });
                                                                setTest({ ...test, test: '' });
                                                                test.test = '';
                                                                console.log(callbackSrsData);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='editSrsAreaDataFile'>
                                                {callbackSrsData.edit_fileList && callbackSrsData.edit_fileList.map((e, index) => {
                                                    return (
                                                        <>
                                                            <div className='editSrsAreaDataFileBox'>
                                                                <a className='editSrsAreaDataFileName' target="_blank" style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        GetAccesstokenToken((result_) => {
                                                                            getFileBoxByIdImage(e.file_id, (result) => {
                                                                                window.open(result.fileImg, '_blank');
                                                                            });
                                                                        });

                                                                    }}
                                                                >{e.edit_ul_file_name}</a>
                                                                <i class="fa fa-times" aria-hidden="true" style={{ fontSize: "20px", cursor: 'pointer' }} onClick={() => {
                                                                    let temp = callbackSrsData.edit_fileList;
                                                                    console.log('value', callbackSrsData)
                                                                    temp.splice(index, 1);
                                                                    let tempIdList = callbackSrsData.file_id_list;
                                                                    let finnI = tempIdList.findIndex((e2) => e2 == e.file_id)
                                                                    if (finnI > -1) {
                                                                        tempIdList.splice(finnI, 1);
                                                                    }
                                                                    setFileUploadList(tempIdList);
                                                                    setCallbackSrsData({ ...callbackSrsData, edit_fileList: temp, file_id_list: tempIdList });
                                                                    callbackSrsData.edit_fileList = temp;
                                                                    callbackSrsData.file_id_list = tempIdList;

                                                                }} />
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>


                                            <div className='edittext' style={{ borderBottom: '1px solid #0047ff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                IMAGE
                                                <div>
                                                    <label className='editChatAddFile' style={{ backgroundColor: '#039BE5' }} for="srsAreaUploadAddButtonImageEdit">
                                                        <i className="fa fa-plus" aria-hidden="true"></i> ADD IMAGE
                                                    </label>
                                                    <input
                                                        id='srsAreaUploadAddButtonImageEdit'
                                                        style={{ display: 'none' }}
                                                        type='file'
                                                        accept={'image/*'}
                                                        multiple
                                                        onChange={(e) => {

                                                            if (e.target.files.length > 0) {
                                                                let temp = callbackSrsData.edit_imageList;
                                                                let no = temp.length;
                                                                for (let item of e.target.files) {
                                                                    let file = {
                                                                        no: no + 1,
                                                                        type: 'IMAGE',
                                                                        file_name: item.name,
                                                                        file: item,
                                                                        path: URL.createObjectURL(item),
                                                                        url: URL.createObjectURL(item),
                                                                        edit_ul_file_name: item.name,
                                                                        edit_ul_file_path: URL.createObjectURL(item),
                                                                        isNew: true
                                                                    }
                                                                    temp.push(file);
                                                                    no++;
                                                                }

                                                                setCallbackSrsData({ ...callbackSrsData, edit_imageList: temp });
                                                                setTest({ ...test, test: '' });
                                                                test.test = '';
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='editSrsAreaDataFile'>
                                                {callbackSrsData.edit_imageList && callbackSrsData.edit_imageList.map((e, index) => {
                                                    return (
                                                        <>
                                                            <div className='editSrsAreaDataFileBox'>
                                                                <a className='editSrsAreaDataFileName' style={{ cursor: 'pointer' }} target="_blank"
                                                                    onClick={() => {
                                                                        GetAccesstokenToken((result_) => {
                                                                            getFileBoxByIdImage(e.file_id, (result) => {
                                                                                window.open(result.fileImg, '_blank');
                                                                            });
                                                                        });
                                                                        if (e.file_id.length > 0) {
                                                                            setDropdown(true)
                                                                        };
                                                                    }}
                                                                >{e.edit_ul_file_name}</a>
                                                                <i class="fa fa-times" aria-hidden="true" style={{ fontSize: "20px", cursor: 'pointer' }} onClick={() => {
                                                                    let temp = callbackSrsData.edit_imageList
                                                                    temp.splice(index, 1);
                                                                    let tempIdList = callbackSrsData.file_id_list;
                                                                    let finnI = tempIdList.findIndex((e2) => e2 == e.file_id)
                                                                    if (finnI > -1) {
                                                                        tempIdList.splice(finnI, 1);
                                                                    }
                                                                    setFileUploadList(tempIdList);
                                                                    setCallbackSrsData({ ...callbackSrsData, edit_imageList: temp, file_id_list: tempIdList })
                                                                    callbackSrsData.edit_imageList = temp;
                                                                    callbackSrsData.file_id_list = tempIdList;
                                                                }} />
                                                            </div>
                                                        </>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {callbackSrsData.edit_sm_mom_id && <div style={{ width: '30%' }}>
                                <MomAttendantEdit data={momAttendantData} callbackSrsData={callbackSrsData} setCallbackSrsData={setCallbackSrsData}/>
                            </div>}
                        </div>

                        <div style={{ display: 'flex', position: 'absolute', bottom: '10%', right: '7%' }}>
                            <div className='editSrsAreaDataFooterButton' style={{ /* backgroundColor: 'green' */border: '2px solid #007bff', color: '#007bff' }} onClick={() => {
                                if ((callbackSrsData.edit_sm_comment || "").toString().trim() != '') {
                                    sendEditSrsAreaData();
                                    if (errorEditSrsData) {
                                        toggle_modal_errorEditSrsData();
                                    }
                                } else {
                                    if (errorEditSrsData == false) {
                                        toggle_modal_errorEditSrsData();
                                    }
                                };
                                setDropdown(false)
                                console.log("callbackSrsData", callbackSrsData.edit_fileList);

                            }}>SAVE</div>
                            <div className='editSrsAreaDataFooterButton' style={{ /* backgroundColor: 'red' */border: '2px solid #dc3545', color: '#dc3545' }} onClick={() => {
                                toggle_modal_editSrsData();
                                if (errorEditSrsData) {
                                    toggle_modal_errorEditSrsData();
                                }
                                setDropdown(false)
                            }}>CANCEL</div>
                        </div>
                    </div>
                </div>

            </div>
            {callbackSrsData.edit_sm_comment != undefined ?
                renderNames() && editSrsData && <div className='srsAreaTagBoxEdit'>
                    {renderNames()}
                </div>
                : <></>
            }

            {isOpenTimeSheet == true && (
                <ModalTimeSheet
                    toggle_modal_time_sheet={toggle_modal_time_sheet}
                    project_id={project_id}
                    project_name={processStage.project_name}
                    process_id={processStage.process_id}
                    process_name={processStage.process_name}
                    module_name={processStage.module_name}
                    menu_id={processStage.menu_id}
                    menu_name={processStage.menu_name}
                />
            )}



        </>
    )
}

export default StageElement
