import React, { useEffect, useState, useRef } from 'react'
import "./style.css"

import { NumericFormat, PatternFormat } from 'react-number-format';


import Configs from "../../../../../config";
import {
    PlusCircleOutlined
} from '@ant-design/icons';
import axios from "axios";
import { DatePicker, Input, Tooltip, ConfigProvider, Select } from 'antd';
import { DeleteOutlined, DownloadOutlined, EditOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import SelectBoxLLL from './component/SelectBoxLLL';
import ModelAlertConfrim from "../../../../../components/AlertMassge/AlertConfrim";
import ModelAlertConfrimDel from "../../../../Risk/componentAlertRiskDel";
import backIcon from './Image/Icon.png'
import { isFunction } from 'lodash';
import Monthlyworksummaryreport from '../../../../component/Report/Monthlyworksummaryreport';
import { CSSTransition } from 'react-transition-group';
const { RangePicker } = DatePicker;
const { TextArea } = Input;




function FederationFollowUp(params) {

    const [filterFolllowUp, setFilterFollowUp] = useState({
        search: '',
        follow_up_status_id: 'ALL',
        task_status_id: 'ALL',
        category_id: 'ALL'
    });

    const [projectListOption, setPorjectListOption] = useState([]);

    const [showAllDetail, setShowAllDetail] = useState(false);
    const toggleShowAllDetail = () => { setShowAllDetail(!showAllDetail) };


    async function getFederationWorkloadData() {

        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/federation/getProjectListByFed/${params.federation_id}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                let options = [];
                response.data.forEach((item) => {
                    options.push({
                        label: item.project_name,
                        value: item.project_id
                    })
                })
                setPorjectListOption(options);


            })
            .catch(function (error) {
                console.log(error);
            });
    }


    useEffect(() => {
        if (params.federationFollowUpIsOpen) {
            getFederationWorkloadData();
        };

    }, [params.federationFollowUpIsOpen])




    const [timeSheeMonthDetail, setTimeSheetMonthDetail] = useState({
        id: '',
        mode: 'add',
        dear: '',
        month: dayjs()/* moment(new Date()).format('YYYY-MM') */,
    })

    const [documentVersionList, setDocumentVersionList] = useState([]);

    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);
    const [errorMasgerList, setErrorMasgerList] = useState({
        isError: false,
        massger: []
    });

    const [loading, setLoading] = useState({
        status: false
    });


    const [followUpList, setFollowUpList] = useState([]);
    const [followUpAllList, setFollowAllUpList] = useState([]);

    const [followUpStatusOption, setFollowUpStatusOption] = useState([]);
    const [taskStatusOption, setTaskStatusOption] = useState([]);
    const [followUpStatusFilterOption, setFollowUpStatusFilterOption] = useState([]);
    const [taskStatusFilterOption, setTaskStatusFilterOption] = useState([]);

    const [categoryOption, setCategoryOption] = useState([
        {
            label: 'Internal',
            value: 'Internal',
            fontColor: '#0094FF',
            bgColor: '#182732'
        },
        {
            label: 'External',
            value: 'External',
            fontColor: 'rgb(255, 181, 181)',
            bgColor: 'rgb(82, 14, 14)'
        }
    ]);

    const [categoryOptionFilter, setCategoryOptionFilter] = useState([
        {
            label: 'ALL',
            value: 'ALL',
            fontColor: '#0094FF',
            bgColor: '#182732'
        },
        {
            label: 'Internal',
            value: 'Internal',
            fontColor: '#0094FF',
            bgColor: '#182732'
        },
        {
            label: 'External',
            value: 'External',
            fontColor: 'rgb(255, 181, 181)',
            bgColor: 'rgb(82, 14, 14)'
        }
    ]);

    async function getFollowUpStatus() {

        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/federation/getFollowUpStatus`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                let option = [];
                let optionFilter = [];
                optionFilter.push({
                    label: 'ALL',
                    value: 'ALL',
                    fontColor: '',
                    bgColor: ''
                })
                response.data.forEach(element => {
                    let temp = {
                        label: element.fus_name,
                        value: element.fus_id,
                        fontColor: element.fus_font_color,
                        bgColor: element.fus_bg_color
                    }
                    option.push(temp);
                    optionFilter.push(temp);
                });
                setFollowUpStatusOption(option);
                setFollowUpStatusFilterOption(optionFilter);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getTaskStatus() {

        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/federation/getTaskStatus`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                let option = [];
                let optionFilter = [];
                optionFilter.push({
                    label: 'ALL',
                    value: 'ALL',
                    fontColor: '',
                    bgColor: ''
                })
                response.data.forEach(element => {
                    let temp = {
                        label: element.tfs_name,
                        value: element.tfs_id,
                        fontColor: element.tfs_font_color,
                        bgColor: element.tfs_bg_color
                    }
                    option.push(temp);
                    optionFilter.push(temp);
                });
                setTaskStatusOption(option);
                setTaskStatusFilterOption(optionFilter);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getFollowUpByFed() {
        let temp = {
            fed_id: params.federation_id,
            search: filterFolllowUp.search.trim(),
            follow_status: filterFolllowUp.follow_up_status_id,
            task_status: filterFolllowUp.task_status_id
        }
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/federation/getFollowUpByFed`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp

        })
            .then(async function (response) {
                setFollowUpList(response.data);
                setFollowAllUpList(response.data);
                searchFrontFollowUp(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }




    useEffect(() => {
        if (params.federationFollowUpIsOpen == true) {

            setActiveTab('ALL FOLLOW UP')
            getFollowUpStatus();
            getTaskStatus();
            getFollowUpByFed();
        }

    }, [params.federationFollowUpIsOpen]);

    useEffect(() => {
        console.log('openElement  123', sessionStorage.getItem('onElement'))
    }, [sessionStorage.getItem('onElement')])

    const [test, settest] = useState({
        test: ''
    })

    function searchFrontFollowUp(arr_data) {
        let tempData = arr_data || followUpAllList || [];
        for (let item of tempData) {
            item.isEdit = false;
        }
        if (filterFolllowUp.search.trim() != '') {


            let result = tempData.filter(e => {
                return (e.project_name.toLowerCase().includes(filterFolllowUp.search.trim().toLowerCase())
                    || e.sm_comment.toLowerCase().includes(filterFolllowUp.search.trim().toLowerCase())
                    || (e.sm_follow_name || '').toLowerCase().includes(filterFolllowUp.search.trim().toLowerCase())
                    || (e.sm_follow_keeper || '').toLowerCase().includes(filterFolllowUp.search.trim().toLowerCase())
                )
            });

            if (filterFolllowUp.follow_up_status_id != 'ALL') {
                result = result.filter((e) => { return e.sm_follow_status_id == filterFolllowUp.follow_up_status_id || (filterFolllowUp.follow_up_status_id == '1cc898ea-a303-4829-b8f2-dd79a9a56a0c' && (e.sm_follow_status_id == filterFolllowUp.follow_up_status_id || !e.sm_follow_status_id)) });
                /*        console.log(result) */
            }

            if (filterFolllowUp.task_status_id != 'ALL') {
                result = result.filter((e) => { return e.sm_follow_task_status_id == filterFolllowUp.task_status_id || (filterFolllowUp.task_status_id == 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0' && (e.sm_follow_task_status_id == filterFolllowUp.task_status_id || !e.sm_follow_task_status_id)) });
                /*   console.log(result) */
            }

            if (filterFolllowUp.category_id != 'ALL') {
                result = result.filter((e) => { return e.sm_follow_category == filterFolllowUp.category_id || (filterFolllowUp.category_id == 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0' && (e.sm_follow_category == filterFolllowUp.category_id || !e.sm_follow_category)) });
                /*   console.log(result) */
            }

            setFollowUpList(result);
        } else {
            let result = tempData;
            if (filterFolllowUp.follow_up_status_id != 'ALL') {
                result = result.filter((e) => { return e.sm_follow_status_id == filterFolllowUp.follow_up_status_id || (filterFolllowUp.follow_up_status_id == '1cc898ea-a303-4829-b8f2-dd79a9a56a0c' && (e.sm_follow_status_id == filterFolllowUp.follow_up_status_id || !e.sm_follow_status_id)) });
                /*        console.log(result) */
            }

            if (filterFolllowUp.task_status_id != 'ALL') {
                result = result.filter((e) => { return e.sm_follow_task_status_id == filterFolllowUp.task_status_id || (filterFolllowUp.task_status_id == 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0' && (e.sm_follow_task_status_id == filterFolllowUp.task_status_id || !e.sm_follow_task_status_id)) });
                /*   console.log(result) */
            }

            if (filterFolllowUp.category_id != 'ALL') {
                result = result.filter((e) => { return e.sm_follow_category == filterFolllowUp.category_id || (filterFolllowUp.category_id == 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0' && (e.sm_follow_category == filterFolllowUp.category_id || !e.sm_follow_category)) });
                /*   console.log(result) */
            }

            setFollowUpList(result);
        }

        settest({ ...test, test: '' });
        test.test = '';

    }


    const [followUpEditDetail, setFollowUpEditDetail] = useState({
        sm_id: '',
        project_id: '',
        comment: '',
        follow_name: '',
        follow_keeper: '',
        follow_up_status: '',
        task_status: '',
        follow_tracking_date: '',
        follow_renark: '',
        category: '',

    })

    function setEditFollow(index) {
        const allDivs = document.querySelectorAll('div');

        // Convert the NodeList to an Array
        /*      const divArray = Array.from(allDivs);
             
             // Filter divs by specific id
             const filteredDivs = divArray.filter(div => div.id.includes('SelectBoxLLL-'));
             
             console.log(filteredDivs); */
        let tempData = followUpList;
        for (let item of tempData) {
            item.isEdit = false;
        }
        if (index > -1) {
            tempData[index].isEdit = true;
            console.log(tempData[index])
            setFollowUpEditDetail({
                ...followUpEditDetail,
                sm_id: tempData[index].sm_id,
                project_id: tempData[index].sm_project_id,
                comment: tempData[index].sm_comment,
                follow_name: tempData[index].sm_follow_name,
                follow_keeper: tempData[index].sm_follow_keeper,
                follow_up_status: tempData[index].sm_follow_status_id,
                task_status: tempData[index].sm_follow_task_status_id,
                follow_tracking_date: tempData[index].sm_follow_tracking_date,
                follow_renark: tempData[index].sm_follow_remark,
                category: tempData[index].sm_follow_category,


            });
            setFollowUpList(tempData);
        }

    }

    async function updateFollowStatus(sm_id, status_id) {
        let temp = {
            sm_id: sm_id,
            status_id: status_id
        }
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/federation/updateFollowStatus`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp

        })
            .then(async function (response) {
                getFollowUpByFed();
                /*    setFollowUpList(response.data);
                   setFollowAllUpList(response.data); */
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function updateTaskStatus(sm_id, status_id) {
        let temp = {
            sm_id: sm_id,
            status_id: status_id
        }
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/federation/updateTaskStatus`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp

        })
            .then(async function (response) {
                getFollowUpByFed();
                /*    setFollowUpList(response.data);
                   setFollowAllUpList(response.data); */
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function updateCategory(sm_id, cate) {
        let temp = {
            sm_id: sm_id,
            cate: cate
        }
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/federation/updateCategory`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp

        })
            .then(async function (response) {
                getFollowUpByFed();
                /*    setFollowUpList(response.data);
                   setFollowAllUpList(response.data); */
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    async function updateFollow() {
        let temp = {
            sm_id: followUpEditDetail.sm_id,
            project_id: followUpEditDetail.project_id,
            comment: (followUpEditDetail.comment || '').trim(),
            follow_name: (followUpEditDetail.follow_name || '').trim(),
            follow_keeper: (followUpEditDetail.follow_keeper || '').trim(),
            follow_up_status: followUpEditDetail.follow_up_status,
            task_status: followUpEditDetail.task_status,
            follow_tracking_date: followUpEditDetail.follow_tracking_date,
            follow_renark: (followUpEditDetail.follow_renark || '').trim(),
            category: followUpEditDetail.category,
        }
        const error_list = [];
        if (temp.project_id == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [Project]."
            }
            error_list.push(temp_err);
        }

        if ((temp.comment || '').trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [รายละเอียด]."
            }
            error_list.push(temp_err);
        }

        if ((temp.follow_name || '').trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [ผู้ร้องขอ]."
            }
            error_list.push(temp_err);
        }

        if ((temp.follow_keeper || '').trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [ผู้ดูแล]."
            }
            error_list.push(temp_err);
        }
        if (temp.follow_tracking_date == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [วันที่ต้องติดตาม]."
            }
            error_list.push(temp_err);
        }



        if (error_list.length > 0) {
            setLoading({ ...loading, status: false });
            loading.status = false;
            setErrorMasgerList({
                ...errorMasgerList,
                isError: true,
                massger: error_list
            });

            toggle_modal_alertKick()
            /*   var err_message = "";
              for (var e = 0; e < error_list.length; e++) {
                  err_message += "<br/>" + error_list[e].message;
              }
              Swal.fire("Error", err_message, "error"); */
        } else {
            await axios({
                method: "POST",
                url: `${Configs.TTT_Game_Service}/api/federation/updateFollow`,
                headers: {
                    'Authorization': sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp

            })
                .then(async function (response) {
                    setOpenConfirmEdit(false);
                    toggle_modal_alertKick();
                    setErrorMasgerList({
                        ...errorMasgerList,
                        isError: false,
                        massger: []
                    })
                    getFollowUpByFed();

                })
                .catch(function (error) {
                    setOpenConfirmEdit(false);
                    console.log(error);
                });

        }

    }

    const [followUpAddDetail, setFollowUpAddDetail] = useState({
        is_add: false,
        sm_id: '',
        project_id: '',
        comment: '',
        follow_name: '',
        follow_keeper: '',
        follow_up_status: '1cc898ea-a303-4829-b8f2-dd79a9a56a0c',
        task_status: 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0',
        follow_tracking_date: '',
        follow_renark: '',
        category: '',

    })

    async function addFollowUp() {

        let temp = {

            project_id: followUpAddDetail.project_id,
            comment: followUpAddDetail.comment,
            follow_name: followUpAddDetail.follow_name,
            follow_keeper: followUpAddDetail.follow_keeper,
            follow_up_status: followUpAddDetail.follow_up_status,
            task_status: followUpAddDetail.task_status,
            follow_tracking_date: followUpAddDetail.follow_tracking_date,
            follow_renark: followUpAddDetail.follow_renark,
            category: followUpAddDetail.category,
        }

        const error_list = [];
        if (temp.project_id == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [Project]."
            }
            error_list.push(temp_err);
        }

        if (temp.comment.trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [รายละเอียด]."
            }
            error_list.push(temp_err);
        }

        if (temp.follow_name.trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [ผู้ร้องขอ]."
            }
            error_list.push(temp_err);
        }

        if (temp.follow_keeper.trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [ผู้ดูแล]."
            }
            error_list.push(temp_err);
        }
        if (temp.follow_tracking_date == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [วันที่ต้องติดตาม]."
            }
            error_list.push(temp_err);
        }



        if (error_list.length > 0) {
            setLoading({ ...loading, status: false });
            loading.status = false;
            setErrorMasgerList({
                ...errorMasgerList,
                isError: true,
                massger: error_list
            });

            toggle_modal_alertKick()
            /*   var err_message = "";
              for (var e = 0; e < error_list.length; e++) {
                  err_message += "<br/>" + error_list[e].message;
              }
              Swal.fire("Error", err_message, "error"); */
        } else {
            await axios({
                method: "POST",
                url: `${Configs.TTT_Game_Service}/api/federation/addFollow`,
                headers: {
                    'Authorization': sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp

            })
                .then(async function (response) {
                    setOpenConfirmAdd(false);
                    toggle_modal_alertKick();
                    setErrorMasgerList({
                        ...errorMasgerList,
                        isError: false,
                        massger: []
                    })
                    setFollowUpAddDetail({
                        ...followUpAddDetail,
                        is_add: false,
                        sm_id: '',
                        project_id: '',
                        comment: '',
                        follow_name: '',
                        follow_keeper: '',
                        follow_up_status: '1cc898ea-a303-4829-b8f2-dd79a9a56a0c',
                        task_status: 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0',
                        follow_tracking_date: '',
                        follow_renark: '',
                        category: '',
                    });
                    getFollowUpByFed();


                })
                .catch(function (error) {
                    setOpenConfirmAdd(false);
                    console.log(error);
                });

        }
    }

    async function delFollow() {
        let id = delSM.sm_id;
        /*    console.log(id); */
        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/federation/deleteFollow/${id}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },


        })
            .then(async function (response) {
                setOpenConfirmDel(false);
                setDelSM({ ...delSM, sm_id: '' });
                delSM.sm_id = '';
                toggle_modal_alertKick();
                setErrorMasgerList({
                    ...errorMasgerList,
                    isError: false,
                    massger: []
                })

                /*  setFollowUpAddDetail({...followUpAddDetail,
                     is_add:false,
                     sm_id: '',
                     project_id: '',
                     comment: '',
                     follow_name: '',
                     follow_keeper: '',
                     follow_up_status: '1cc898ea-a303-4829-b8f2-dd79a9a56a0c',
                     task_status: 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0',
                     follow_tracking_date: '',
                     follow_renark: '',
                     category: '',
                 }); */
                getFollowUpByFed();


            })
            .catch(function (error) {
                setOpenConfirmDel(false);
                console.log(error);
            });
    }


    const [openConfirmAdd, setOpenConfirmAdd] = useState(false);
    const toggle_modal_confrimModalAdd = () => setOpenConfirmAdd(!openConfirmAdd);


    const [openConfirmEdit, setOpenConfirmEdit] = useState(false);
    const toggle_modal_confrimModalEdit = () => setOpenConfirmEdit(!openConfirmEdit);

    const [openConfirmDel, setOpenConfirmDel] = useState(false);
    const toggle_modal_confrimModalDel = () => setOpenConfirmDel(!openConfirmDel);

    const [delSM, setDelSM] = useState({
        sm_id: ""
    });

    const [activeTab, setActiveTab] = useState('ALL FOLLOW UP');

    const [followUpStatusTableList, setFollowUpStatusTableList] = useState([]);
    const [taskStatusTableList, setTaskStatusTableList] = useState([]);



    async function getFollowUpStatusTableList() {
        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/federation/getFollowUpStatusTableList/${params.federation_id}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },


        })
            .then(async function (response) {

                setFollowUpStatusTableList(response.data);

            })
            .catch(function (error) {

                console.log(error);
            });
    }

    async function getTaskStatusTableList() {
        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/federation/getTaskStatusTableList/${params.federation_id}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },


        })
            .then(async function (response) {

                setTaskStatusTableList(response.data);

            })
            .catch(function (error) {

                console.log(error);
            });
    }

    return (
        <>
            <div className='fedWorkloadBg2' style={{ display: `${params.federationFollowUpIsOpen ? '' : 'none'}` }}>
                <div className='row fedWorkloadBackBox' style={{ margin: 0, padding: 0 }}>
                    <div className='fedWorkloadBackButton' onClick={() => {
                        params.toggleFederationFollowUpIsOpen();
                        params.closeElement();
                        setFollowUpAddDetail({ ...followUpAddDetail, is_add: false });

                        setFilterFollowUp({
                            ...filterFolllowUp,
                            search: '',
                            follow_up_status_id: 'ALL',
                            task_status_id: 'ALL',
                            category_id: 'ALL'
                        });
                        setFollowUpList([]);
                        setFollowAllUpList([]);
                        setFollowUpStatusTableList([]);
                        setTaskStatusTableList([]);

                    }}>
                        <img src={backIcon} style={{ width: '100%', height: '100%' }}></img>
                    </div>
                    <div className='fedWorkloadBackLine'></div>
                    <div className='fedWorkloadBackNameBox'>
                        <div className='fedWorkloadBackNameHead'>Federation Follow Up</div>
                        <div className='fedWorkloadBackNameText'>{params.federation_name}</div>
                    </div>
                </div>

                <div className='fedTabContainner'>
                    <div
                        className={activeTab == 'ALL FOLLOW UP' ? 'fedTabNameActive' : 'fedTabName'}
                        onClick={() => {
                            setActiveTab('ALL FOLLOW UP');
                            getFollowUpByFed();
                        }}
                    >ALL FOLLOW UP</div>

                    <div
                        className={activeTab == 'FOLLOW STATUS' ? 'fedTabNameActive' : 'fedTabName'}
                        onClick={() => {
                            setActiveTab('FOLLOW STATUS');
                            getFollowUpStatusTableList();

                        }}
                    >FOLLOW STATUS</div>
                    <div
                        className={activeTab == 'TASK STATUS' ? 'fedTabNameActive' : 'fedTabName'}
                        onClick={() => {
                            setActiveTab('TASK STATUS');
                            getTaskStatusTableList();
                        }}
                    >TASK STATUS</div>
                </div>


                {activeTab == 'ALL FOLLOW UP' && (<>
                    <div className='followUpFilterContainner' style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <div style={{ width: '300px' }}>
                                    Search
                                    <Input className='inputFilterFollowUp'
                                        value={filterFolllowUp.search}
                                        onChange={(e) => {
                                            setFilterFollowUp({ ...filterFolllowUp, search: e.target.value });
                                            filterFolllowUp.search = e.target.value;

                                            searchFrontFollowUp();
                                        }}
                                    />
                                </div>
                                <div style={{ width: '200px' }}>Follow Up Status
                                    <SelectBoxLLL
                                        id='select-follow-status'
                                        option={followUpStatusFilterOption}
                                        value={filterFolllowUp.follow_up_status_id}
                                        onChange={(e) => {
                                            setFilterFollowUp({ ...filterFolllowUp, follow_up_status_id: e.value });
                                            filterFolllowUp.follow_up_status_id = e.value;
                                            searchFrontFollowUp();
                                        }}
                                    />
                                </div>

                                <div style={{ width: '200px' }}>Task Status
                                    <SelectBoxLLL
                                        id='select-task-status'
                                        value={filterFolllowUp.task_status_id}
                                        option={taskStatusFilterOption}
                                        onChange={(e) => {
                                            setFilterFollowUp({ ...filterFolllowUp, task_status_id: e.value });
                                            filterFolllowUp.task_status_id = e.value;
                                            searchFrontFollowUp();
                                        }}
                                    />
                                </div>

                                <div style={{ width: '200px' }}>Category
                                    <SelectBoxLLL
                                        id='select-task-status'
                                        value={filterFolllowUp.category_id}
                                        option={categoryOptionFilter}
                                        onChange={(e) => {
                                            setFilterFollowUp({ ...filterFolllowUp, category_id: e.value });
                                            filterFolllowUp.category_id = e.value;
                                            searchFrontFollowUp();
                                        }}
                                    />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ visibility: 'hidden' }}>x</div>
                                    <div style={{ display: 'flex', gap: '5px' }}>
                                        <div>{`(${followUpList.length || 0})`}</div>
                                        {showAllDetail ? <CaretUpOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                            toggleShowAllDetail();
                                        }} />
                                            : <CaretDownOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                                toggleShowAllDetail();
                                            }} />}
                                    </div>
                                </div>


                                {/*   <div className='col-1'>
                                    <div className='btnGen' style={{gap:'10px'}}
                                   
                                   ><PlusCircleOutlined />Add Follow Up</div>
                       
                                    </div> */}


                            </div>

                            <div style={{ display: 'flex', alignItems: 'end' }}>

                                <div className='btnGen' style={{ gap: '10px' }}
                                    onClick={() => {
                                        setFollowUpAddDetail({ ...followUpAddDetail, is_add: true });

                                    }}

                                ><PlusCircleOutlined />Add Follow Up</div>
                            </div>
                        </div>


                        <CSSTransition
                            in={showAllDetail}
                            timeout={500}
                            classNames="fade"
                            unmountOnExit
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                                    <table style={{ width: 'fit-content', wordWrap: 'break-word' }}>
                                        {[1].map((e) => {
                                            let sumAll = 0;
                                            return (<>
                                                <tr style={{ background: 'rgba(0,0,0,0.1)' }}>
                                                    {followUpStatusOption.map((e2) => {
                                                        let checkCount = followUpList.filter((fil) => { return (fil.sm_follow_status_id || '1cc898ea-a303-4829-b8f2-dd79a9a56a0c') == e2.value });

                                                        sumAll += checkCount.length
                                                    })}
                                                    <td style={{ width: '200px', textAlign: 'center', padding: '5px', verticalAlign: 'top', fontSize: '13px' }}>Follow Up Status {`(${sumAll})`}</td>
                                                    {followUpStatusOption.map((e2) => {
                                                        let checkCount = followUpList.filter((fil) => { return (fil.sm_follow_status_id || '1cc898ea-a303-4829-b8f2-dd79a9a56a0c') == e2.value });

                                                        return (<>
                                                            <td style={{ width: '175px', textAlign: 'center', padding: '5px', verticalAlign: 'top', fontSize: '13px', background: e2.bgColor, color: e2.fontColor }}>{e2.label} {`(${checkCount.length || 0})`}</td>
                                                        </>)
                                                    })}
                                                </tr>
                                            </>)
                                        })}
                                    </table>
                                    <div style={{ height: '100%', background: 'rgba(0,0,0,0.1)' }}></div>
                                </div>

                                <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                                    <table style={{ width: 'fit-content', wordWrap: 'break-word' }}>
                                        {[1].map((e) => {
                                            let sumAll = 0;
                                            return (<>
                                                <tr style={{ background: 'rgba(0,0,0,0.1)' }}>
                                                    {taskStatusOption.map((e2) => {
                                                        let checkCount = followUpList.filter((fil) => { return (fil.sm_follow_task_status_id || 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0') == e2.value });
                                                        
                                                        sumAll += checkCount.length
                                                    })}
                                                    <td style={{ width: '200px', textAlign: 'center', padding: '5px', verticalAlign: 'top', fontSize: '13px' }}>Task Status {`(${sumAll})`}</td>
                                                    {taskStatusOption.map((e2) => {
                                                        let checkCount = followUpList.filter((fil) => { return (fil.sm_follow_task_status_id || 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0') == e2.value });
                                                        
                                                        return (<>
                                                            <td style={{ width: '175px', textAlign: 'center', padding: '5px', verticalAlign: 'top', fontSize: '13px', background: e2.bgColor, color: e2.fontColor }}>{e2.label} {`(${checkCount.length || 0})`}</td>
                                                        </>)
                                                    })}
                                                </tr>
                                            </>)
                                        })}


                                    </table>
                                    <div style={{ height: '100%', background: 'rgba(0,0,0,0.1)' }}></div>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>

                    <ConfigProvider theme={{
                        token: {

                            colorInfo: 'red',
                            colorBorder: '#0047FF',
                            colorText: '#FFF',

                            colorTextDescription: '#FFFFFF',
                            colorBgContainer: '#001D5F',
                            zIndexPopupBase: 999999,
                            colorPrimaryBg: '#FFFFFF',
                            colorBgBase: '#001D5F',//พื้นหลัง datepicker

                            colorTextQuaternary: 'White',//สีตัวอักษร

                            colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                            colorTextBase: 'White', // สี scroll bar

                            colorPrimaryBg: '#0047FF',// สีตอนเลือก
                            /*       
                                   colorBgLayout:'red',
                                   colorFillQuaternary:'red', */


                        }
                    }} >


                        <div className='fedWorkMonthBody'>

                        </div>

                        <div style={{ display: 'flex', height: '100%', overflowY: 'auto', flexDirection: 'column' }}>
                            <table style={{ width: '100%', wordWrap: 'break-word' }}>
                                <tr className='testtest' style={{ position: 'sticky', top: 0, zIndex: 3, maxHeight: '37px' }}>
                                    <td style={{ textAlign: 'center', width: '5%', fontSize: '15px' }}>ลำดับ</td>
                                    <td style={{ textAlign: 'center', width: '10%', fontSize: '15px' }}>Project</td>
                                    <td style={{ textAlign: 'center', width: '15%', fontSize: '15px', maxWidth: '50px' }}>รายละเอียด</td>
                                    <td style={{ textAlign: 'center', width: '5%', fontSize: '15px' }}>ผู้ร้องขอ</td>
                                    <td style={{ textAlign: 'center', width: '5%', fontSize: '15px' }}>ผู้ดูแล</td>
                                    <td style={{ textAlign: 'center', width: '10%', fontSize: '15px' }}>Follow up Status</td>
                                    <td style={{ textAlign: 'center', width: '10%', fontSize: '15px' }}>Task Status</td>
                                    <td style={{ textAlign: 'center', width: '10%', fontSize: '15px' }}>วันที่ต้องติดตาม</td>
                                    <td style={{ textAlign: 'center', width: '15%', fontSize: '15px', width: '50px' }}>หมายเหตุ</td>
                                    <td style={{ textAlign: 'center', width: '10%', fontSize: '15px' }}>Category</td>
                                    <td style={{ textAlign: 'center', width: '10%', fontSize: '15px' }}>Ref No.</td>
                                    <td style={{ textAlign: 'center', width: '10%', fontSize: '15px' }}>Management</td>

                                </tr>
                                {followUpAddDetail.is_add && (

                                    <>
                                        <tr style={{ height: '37px', background: 'rgba(0,0,0,0.1)' }}>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>-</td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px', minWidth: '150px', maxWidth: '150px' }}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    filterOption={(inputValue, option) =>
                                                        option.label.toLowerCase().includes(inputValue.toLowerCase())
                                                    }
                                                    style={{
                                                        borderRadius: 0,
                                                        width: '100%',
                                                        height: '36px'
                                                    }}
                                                    value={followUpAddDetail.project_id}
                                                    onChange={(e) => {

                                                        setFollowUpAddDetail({ ...followUpAddDetail, project_id: e });

                                                    }}
                                                    /*     value={projectSelect} */

                                                    placeholder="Please select"
                                                    /*     maxTagCount='responsive' */


                                                    options={projectListOption}
                                                />
                                            </td>
                                            <td style={{ textAlign: 'left', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>
                                                <TextArea
                                                    value={followUpAddDetail.comment}
                                                    style={{ minHeight: '36px' }}
                                                    onChange={(value) => {
                                                        setFollowUpAddDetail({ ...followUpAddDetail, comment: value.target.value });
                                                    }}
                                                    autoSize
                                                />
                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>
                                                <Input
                                                    value={followUpAddDetail.follow_name}
                                                    style={{ height: '36px' }}
                                                    onChange={(value) => {
                                                        setFollowUpAddDetail({ ...followUpAddDetail, follow_name: value.target.value });
                                                    }}
                                                />
                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>
                                                <Input
                                                    value={followUpAddDetail.follow_keeper}
                                                    style={{ height: '36px' }}
                                                    onChange={(value) => {
                                                        setFollowUpAddDetail({ ...followUpAddDetail, follow_keeper: value.target.value });
                                                    }}
                                                />
                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', position: 'relative', verticalAlign: 'top' }}>
                                                -
                                                {/*   <SelectBoxLLL
                                                option={followUpStatusOption}
                                                id={`select-follow-status-add`}
                                                
                                                value={'1cc898ea-a303-4829-b8f2-dd79a9a56a0c'}
                                               
                                            /> */}



                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', position: 'relative', verticalAlign: 'top' }}>

                                                {/*    <SelectBoxLLL option={taskStatusOption}
                                                id={`select-task-status-add`}
                                                value={'a2d1457a-36ea-45e6-a79f-5a8551cb20b0'}
                                            
                                            /> */}
                                                -


                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>

                                                <DatePicker
                                                    className='inputMonthFedWorkMonth'
                                                    style={{ fontSize: '10px', height: '36px' }}
                                                    value={followUpAddDetail.follow_tracking_date && dayjs(followUpAddDetail.follow_tracking_date)}
                                                    onChange={(date) => {

                                                        setFollowUpAddDetail({ ...followUpAddDetail, follow_tracking_date: date });
                                                    }}

                                                />

                                            </td>
                                            <td style={{ textAlign: 'left', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>
                                                <TextArea
                                                    value={followUpAddDetail.follow_renark}
                                                    style={{ minHeight: '36px' }}
                                                    onChange={(value) => {
                                                        setFollowUpAddDetail({ ...followUpAddDetail, follow_renark: value.target.value });
                                                    }}
                                                    autoSize
                                                />
                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', position: 'relative', verticalAlign: 'top' }}>

                                                <SelectBoxLLL option={categoryOption}
                                                    id={`select-category-add`}
                                                    value={followUpAddDetail.category || 'Internal'}
                                                    onChange={(value) => {
                                                        setFollowUpAddDetail({ ...followUpAddDetail, category: value.value });
                                                    }}
                                                />


                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>-</td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', display: 'flex', fontSize: '16px', gap: '10px', justifyContent: 'center' }}>

                                                <div className='btn-lek-save'

                                                    onClick={() => { setOpenConfirmAdd(true) }}
                                                >Save</div>
                                                <div className='btn-lek-cancel'
                                                    onClick={() => {

                                                        setFollowUpAddDetail({
                                                            ...followUpAddDetail,
                                                            is_add: false,
                                                            sm_id: '',
                                                            project_id: '',
                                                            comment: '',
                                                            follow_name: '',
                                                            follow_keeper: '',
                                                            follow_up_status: '1cc898ea-a303-4829-b8f2-dd79a9a56a0c',
                                                            task_status: 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0',
                                                            follow_tracking_date: '',
                                                            follow_renark: '',
                                                            category: '',
                                                        })
                                                        followUpAddDetail.is_add = false;
                                                    }}

                                                >Cancel</div>



                                            </td>
                                        </tr>
                                    </>
                                )}
                                {followUpList.map((e, index) => {
                                    return (<>
                                        <tr style={{ height: '37px', background: 'rgba(0,0,0,0.1)' }}>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{index + 1}</td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{e.project_name}</td>
                                            <td style={{ textAlign: 'left', padding: '7px', verticalAlign: 'top', fontSize: '13px', minWidth: '250px', maxWidth: '250px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                                {e.isEdit ? (<><TextArea
                                                    value={followUpEditDetail.comment}
                                                    style={{ minHeight: '36px' }}
                                                    onChange={(value) => {
                                                        setFollowUpEditDetail({ ...followUpEditDetail, comment: value.target.value });
                                                    }}
                                                    autoSize
                                                /></>) : e.sm_comment}
                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>
                                                {e.isEdit ? (<><Input
                                                    value={followUpEditDetail.follow_name}
                                                    style={{ height: '36px' }}
                                                    onChange={(value) => {
                                                        setFollowUpEditDetail({ ...followUpEditDetail, follow_name: value.target.value });
                                                    }}
                                                /></>) : e.sm_follow_name || '-'}
                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>
                                                {e.isEdit ? (<><Input
                                                    value={followUpEditDetail.follow_keeper}
                                                    style={{ height: '36px' }}
                                                    onChange={(value) => {
                                                        setFollowUpEditDetail({ ...followUpEditDetail, follow_keeper: value.target.value });
                                                    }}
                                                /></>) : e.sm_follow_keeper || '-'}
                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', position: 'relative', verticalAlign: 'top', minWidth: '150px', maxWidth: '150px' }}>
                                                {e.isEdit ? (<>
                                                    <SelectBoxLLL
                                                        option={followUpStatusOption}
                                                        id={`select-follow-status-${index}`}
                                                        value={followUpEditDetail.follow_up_status || '1cc898ea-a303-4829-b8f2-dd79a9a56a0c'}
                                                        onChange={(value) => {
                                                            setFollowUpEditDetail({ ...followUpEditDetail, follow_up_status: value.value });
                                                        }}
                                                    />
                                                </>) : (<>
                                                    <SelectBoxLLL
                                                        option={followUpStatusOption}
                                                        id={`select-follow-status-${index}`}
                                                        value={e.sm_follow_status_id || '1cc898ea-a303-4829-b8f2-dd79a9a56a0c'}
                                                        onChange={(value) => {
                                                            updateFollowStatus(e.sm_id, value.value)
                                                        }}
                                                    />

                                                </>)}

                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', position: 'relative', verticalAlign: 'top', minWidth: '150px', maxWidth: '150px' }}>
                                                {e.isEdit ? (<>

                                                    <SelectBoxLLL option={taskStatusOption}
                                                        id={`select-task-status-${index}`}
                                                        value={followUpEditDetail.task_status || 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0'}
                                                        onChange={(value) => {
                                                            setFollowUpEditDetail({ ...followUpEditDetail, task_status: value.value });
                                                        }}
                                                    />


                                                </>) : (<>
                                                    <SelectBoxLLL option={taskStatusOption}
                                                        id={`select-task-status-${index}`}
                                                        value={e.sm_follow_task_status_id || 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0'}
                                                        onChange={(value) => {
                                                            updateTaskStatus(e.sm_id, value.value)
                                                        }}
                                                    />

                                                </>)}

                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>
                                                {e.isEdit ? (<>
                                                    <DatePicker
                                                        className='inputMonthFedWorkMonth'
                                                        style={{ fontSize: '10px', height: '36px' }}
                                                        value={followUpEditDetail.follow_tracking_date && dayjs(followUpEditDetail.follow_tracking_date)}
                                                        onChange={(date) => {

                                                            setFollowUpEditDetail({ ...followUpEditDetail, follow_tracking_date: date });
                                                        }}

                                                    /* onChange={onChange} */ />
                                                </>) : e.sm_follow_tracking_date ? moment(e.sm_follow_tracking_date).format('DD/MM/YYYY') : '-'}
                                                {/*    {e.sm_follow_tracking_date ? moment(e.sm_follow_tracking_date).format('DD/MM/YYYY') : '-'} */}</td>
                                            <td style={{ textAlign: 'left', padding: '7px', verticalAlign: 'top', fontSize: '13px', minWidth: '250px', maxWidth: '250px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                                {e.isEdit ? (<><TextArea
                                                    value={followUpEditDetail.follow_renark}
                                                    style={{ minHeight: '36px', width: '100%' }}
                                                    onChange={(value) => {
                                                        setFollowUpEditDetail({ ...followUpEditDetail, follow_renark: value.target.value });
                                                    }}
                                                    autoSize
                                                /></>) : e.sm_follow_remark || '-'}
                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', position: 'relative', verticalAlign: 'top' }}>
                                                {e.isEdit ? (<>
                                                    <SelectBoxLLL option={categoryOption}
                                                        id={`select-category-${index}`}
                                                        value={followUpEditDetail.category || 'Internal'}
                                                        onChange={(value) => {
                                                            setFollowUpEditDetail({ ...followUpEditDetail, category: value.value });
                                                        }}
                                                    />
                                                </>) : (<>
                                                    <SelectBoxLLL option={categoryOption}
                                                        id={`select-category-${index}`}
                                                        value={e.sm_follow_category || 'Internal'}
                                                        onChange={(value) => {
                                                            updateCategory(e.sm_id, value.value)
                                                            /*    updateTaskStatus(e.sm_id,value.value)
                                                               e.sm_follow_category = value.value; */

                                                        }}
                                                    />
                                                </>)}

                                            </td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{e.mom_code || '-'}</td>
                                            <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', display: 'flex', fontSize: '16px', gap: '10px', justifyContent: 'center' }}>
                                                {e.isEdit ? (<>
                                                    <div className='btn-lek-save'
                                                        onClick={() => { setOpenConfirmEdit(true) }}
                                                    /*       onClick={()=>{
                                                              
                                                              updateFollow();}} */
                                                    >Save</div>
                                                    <div className='btn-lek-cancel'
                                                        onClick={() => {
                                                            let tempData = followUpList;
                                                            tempData[index].isEdit = false;
                                                            setFollowUpList(tempData);
                                                            setFollowUpEditDetail({
                                                                ...followUpEditDetail,
                                                                sm_id: '',
                                                                project_id: '',
                                                                comment: '',
                                                                follow_name: '',
                                                                follow_keeper: '',
                                                                follow_up_status: '',
                                                                task_status: '',
                                                                follow_tracking_date: '',
                                                                follow_renark: '',
                                                                category: '',
                                                            })

                                                        }}

                                                    >Cancel</div>
                                                </>) : (<>
                                                    <div style={{ cursor: 'pointer' }}
                                                        onClick={() => {

                                                            setEditFollow(index);
                                                        }}
                                                    ><EditOutlined /></div>
                                                    <div style={{ cursor: 'pointer', visibility: e.sm_mom_id || (e.sm_follow_status_id != '1cc898ea-a303-4829-b8f2-dd79a9a56a0c' || e.sm_follow_task_status_id != 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0') ? 'hidden' : '' }}
                                                        onClick={() => {
                                                            setDelSM({ ...delSM, sm_id: e.sm_id });
                                                            delSM.sm_id = e.sm_id;
                                                            toggle_modal_confrimModalDel();

                                                        }}

                                                    ><DeleteOutlined /></div>
                                                </>)}


                                            </td>
                                        </tr>
                                    </>)
                                })}

                            </table>
                            <div style={{ height: '100%', background: 'rgba(0,0,0,0.1)' }}></div>
                        </div>

                    </ConfigProvider>
                </>)}

                {activeTab == 'FOLLOW STATUS' && (<>
                    <div style={{ display: 'flex', paddingLeft: '10px', height: '100%', overflowY: 'auto', flexDirection: 'column' }}>
                        <table style={{ width: '100%', wordWrap: 'break-word' }}>
                            <tr /* className='testtest' */ style={{ position: 'sticky', top: 0, zIndex: 3, height: '37px' }}>
                                <td style={{ textAlign: 'center', width: '5%', fontSize: '15px', background: '#141414', color: '#009DFF' }}>PROJECT</td>
                                {followUpStatusOption.map((e) => {
                                    return (<>
                                        <td style={{ textAlign: 'center', width: '5%', fontSize: '15px', background: e.bgColor, color: e.fontColor }}>{e.label}</td>
                                    </>)
                                })}
                                <td style={{ textAlign: 'center', width: '5%', fontSize: '15px', background: '#141414', color: '#009DFF' }}>ผลรวม</td>

                            </tr>

                            {followUpStatusTableList.map((e) => {
                                let sum = 0;
                                return (<>
                                    <tr style={{ height: '37px', background: 'rgba(0,0,0,0.1)' }}>
                                        <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{e.project_name || '-'}</td>
                                        {followUpStatusOption.map((e2) => {
                                            let getCount = e.count_list.filter((ee) => { return ee.sm_follow_status_id == e2.value });
                                            if (e2.value == '1cc898ea-a303-4829-b8f2-dd79a9a56a0c') {
                                                getCount = e.count_list.filter((ee) => { return ee.sm_follow_status_id == e2.value || !ee.sm_follow_status_id });
                                            }

                                            if (getCount.length > 0) {
                                                sum = sum + parseInt(getCount[0].count || 0);
                                            }
                                            /*       console.log('getCount',getCount) */
                                            return (<>

                                                <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{getCount.length > 0 ? getCount[0].count : ''}</td>
                                            </>)
                                        })}
                                        <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{sum}</td>
                                    </tr>
                                </>)
                            })}
                            {[1].map((e) => {
                                let sumAll = 0;
                                return (<>
                                    <tr style={{ height: '37px', background: 'rgba(0,0,0,0.1)' }}>
                                        <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>ผลรวม</td>
                                        {followUpStatusOption.map((e2) => {
                                            let sum = 0;
                                            for (let item of followUpStatusTableList) {
                                                for (let item2 of item.count_list) {
                                                    /*  console.log(item2) */

                                                    if (item2.sm_follow_status_id == e2.value || (e2.value == '1cc898ea-a303-4829-b8f2-dd79a9a56a0c' && !item2.sm_follow_status_id))
                                                        sum = sum + parseInt(item2.count || 0);
                                                }
                                            }
                                            sumAll = sumAll + sum;

                                            return (<>
                                                <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{sum}</td>
                                            </>)
                                        })}
                                        <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{sumAll}</td>
                                    </tr>
                                </>)
                            })}


                        </table>
                        <div style={{ height: '100%', background: 'rgba(0,0,0,0.1)' }}></div>
                    </div>
                </>)}

                {activeTab == 'TASK STATUS' && (<>
                    <div style={{ display: 'flex', paddingLeft: '10px', height: '100%', overflowY: 'auto', flexDirection: 'column' }}>
                        <table style={{ width: '100%', wordWrap: 'break-word' }}>
                            <tr /* className='testtest' */ style={{ position: 'sticky', top: 0, zIndex: 3, height: '37px' }}>
                                <td style={{ textAlign: 'center', width: '5%', fontSize: '15px', background: '#141414', color: '#009DFF' }}>PROJECT</td>
                                {taskStatusOption.map((e) => {
                                    return (<>
                                        <td style={{ textAlign: 'center', width: '5%', fontSize: '15px', background: e.bgColor, color: e.fontColor }}>{e.label}</td>
                                    </>)
                                })}
                                <td style={{ textAlign: 'center', width: '5%', fontSize: '15px', background: '#141414', color: '#009DFF' }}>ผลรวม</td>

                            </tr>

                            {taskStatusTableList.map((e) => {
                                let sum = 0;
                                return (<>
                                    <tr style={{ height: '37px', background: 'rgba(0,0,0,0.1)' }}>
                                        <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{e.project_name || '-'}</td>
                                        {taskStatusOption.map((e2) => {
                                            let getCount = e.count_list.filter((ee) => { return ee.sm_follow_task_status_id == e2.value });
                                            if (e2.value == 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0') {
                                                getCount = e.count_list.filter((ee) => { return ee.sm_follow_task_status_id == e2.value || !ee.sm_follow_task_status_id });
                                            }

                                            if (getCount.length > 0) {
                                                sum = sum + parseInt(getCount[0].count || 0);
                                            }
                                            /*       console.log('getCount',getCount) */
                                            return (<>

                                                <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{getCount.length > 0 ? getCount[0].count : ''}</td>
                                            </>)
                                        })}
                                        <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{sum}</td>
                                    </tr>
                                </>)
                            })}
                            {[1].map((e) => {
                                let sumAll = 0;
                                return (<>
                                    <tr style={{ height: '37px', background: 'rgba(0,0,0,0.1)' }}>
                                        <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>ผลรวม</td>
                                        {taskStatusOption.map((e2) => {
                                            let sum = 0;
                                            for (let item of taskStatusTableList) {
                                                for (let item2 of item.count_list) {
                                                    /*  console.log(item2) */

                                                    if (item2.sm_follow_task_status_id == e2.value || (e2.value == 'a2d1457a-36ea-45e6-a79f-5a8551cb20b0' && !item2.sm_follow_task_status_id))
                                                        sum = sum + parseInt(item2.count || 0);
                                                }
                                            }
                                            sumAll = sumAll + sum;

                                            return (<>
                                                <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{sum}</td>
                                            </>)
                                        })}
                                        <td style={{ textAlign: 'center', padding: '7px', verticalAlign: 'top', fontSize: '13px' }}>{sumAll}</td>
                                    </tr>
                                </>)
                            })}


                        </table>
                        <div style={{ height: '100%', background: 'rgba(0,0,0,0.1)' }}></div>
                    </div>
                </>)}






                {loading.status && (
                    <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                        <div className='alertKickBoxShadow'>
                            <div className='alertKickBox' style={{ height: 'fit-content' }}>
                                <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                    <div className='row' style={{ height: '25%' }}>
                                        <div className='alertKickHead' style={{ color: 'green' }}>
                                            Loading
                                        </div>
                                    </div>
                                    <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                        <div className='alertKickWarning' style={{ border: 'none' }}>
                                            <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}` }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox' style={{ height: 'fit-content', width: '429 !important' }}>
                            <div className='alertKickBoxIn' style={{ height: 'fit-content', width: '490px !important' }}>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: `${errorMasgerList.isError ? 'red' : 'green'}` }}>
                                        {errorMasgerList.isError ? '! ERROR !' : 'SUCCESS'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning'>
                                        {errorMasgerList.isError ? 'WARNING' : 'SUCCESS'}
                                    </div>
                                </div>

                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickTextSec'>
                                        {errorMasgerList.massger.map((e) => {

                                            return <div> {e.message} </div>

                                        })}
                                    </div>
                                </div>
                                <div className='row alertKickBoxButton mt-4'>
                                    <button className='alertKickButton' onClick={() => {

                                        /*    if (displayPassword === true && errorMasgerList.isError !== true) {
                                               sessionStorage.clear(); window.location.href = '/'
                                           } */
                                        if (errorMasgerList.isError === false) {
                                            /*    setPersonalInfoCheck({
                                                   isOpen: false
                                               }) */
                                            /*       getInfomaionUserByID() */
                                            /*    setMenu('PROJECT') */
                                        }

                                        /*      setDisplayPassword(false) */
                                        toggle_modal_alertKick()
                                        setErrorMasgerList({
                                            ...errorMasgerList,
                                            isError: false,
                                            massger: []
                                        })
                                    }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModelAlertConfrim
                    isOpen={openConfirmAdd}
                    mainFuc={() => addFollowUp()}
                    toggle_alertKick={() => toggle_modal_confrimModalAdd()}

                />

                <ModelAlertConfrimDel
                    isOpen={openConfirmDel}
                    mainFuc={() => delFollow()}
                    toggle_alertKick={() => toggle_modal_confrimModalDel()}

                />


                <ModelAlertConfrim
                    isOpen={openConfirmEdit}
                    mainFuc={() => updateFollow()}
                    toggle_alertKick={() => toggle_modal_confrimModalEdit()}

                />
            </div>
        </>
    )
}

export default FederationFollowUp;
