

import jsPDF from "jspdf";
import { Get_font_pdf_th1, Get_font_pdf_th2 } from "../../../assets/fonts/pdf_font";
import moment from "moment";
import Configs from "../../../config";
import iconMember from "../../../assets/img/report/Icon Spaceship Member_V2 (1).png";
import noimg from "../../../assets/img/report/Report_Header_NoImage.png"
import axios from "axios";
import html2canvas from "html2canvas";
import { logDOM } from "@testing-library/react";
import clipPaperIcon from './img/clip paper.png';
/* import QRCode from "qrcode"; */

async function reportallseting(seting, data, id, mode, summaryofchange) {
    const compressImage = (imageUrl,maxWidth = 1920 ,maxHeight = 1080) => {
       /*  const mmToPx = (mm) => (mm * 96) / 25.4; */

        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous'; 
            img.src = imageUrl;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                let wpx = img.width;
                let hpx = img.height;
                const mwpx = /* mmToPx(maxWidth) */maxWidth;
                const mhpx = /* mmToPx(maxHeight) */maxHeight;
               /*  console.log('w',mwpx,wpx);
                console.log('h',mhpx,hpx); */

    if (wpx > hpx) {
        if (wpx > mwpx) {
            hpx = Math.round((hpx * mwpx) / wpx);
            wpx = mwpx;
        }
    } else {
        if (hpx > mhpx) {
            wpx = Math.round((wpx * mhpx) / hpx);
            hpx = mhpx;
        }
    }

    canvas.width = wpx;
    canvas.height = hpx;
    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, wpx, hpx);
    ctx.drawImage(img, 0, 0, wpx, hpx);

    resolve(canvas.toDataURL('image/jpeg', 0.8)); 

               
            };

            img.onerror = () => {
                reject(new Error('Failed to load image.'));
            };
        });
    };

    let listdatabox = []
   /*  const userAgent = window.navigator.userAgent; */
  
    /* console.log(userAgent,operatingSystem); */
    let imghead = ''
    /*  console.log(imghead); */
    const doc = new jsPDF("p", "mm", "a4");

    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "bold");
    doc.setFont("THSarabunNew", 'bold');
    let padding = 20////เว้นขอบข้าง
        , wpage = 210///กว้าง
        , hpage = 297///สูง
        , page = 1
        , r = 194
        , g = 194
        , b = 194
        , endpage = 262,
        his = [],
        img = {}

    let heightpage = 34


   /*  if (data.img.length > 0) {
        img = data.img[0]
    } */
    let colordrop = [
        {
            before:'#676767',
            after:'#DEDEDE'
        },
        {
            before:'#FF00E5',
            after:'#FBDCFF'
        },
        {
            before:'#BA84FF',
            after:'#CCD9FA'
        },
        {
            before:'#00A5FF',
            after:'#CDEEFF'
        },
        {
            before:'#259B24',
            after:'#D3FFCF'
        },
        {
            before:'#FFEB3B',
            after:'#FFFDCF'
        },
        {
            before:'#FF8000',
            after:'#FFE0C1'
        },
        {
            before:'#FF0000',
            after:'#FFD9D9'
        },
     ]
    if (data.project.federation_color !== null) {
        let selectcolor = colordrop.findIndex((e) => e.before == data.project.federation_color || e.after == data.project.federation_color)
        /* console.log(selectcolor); */
        if (selectcolor !== -1) {
            let hex = colordrop[selectcolor].after.replace(/^#/, '');
            let bigint = parseInt(hex, 16);
    
            r = (bigint >> 16) & 255;
            g = (bigint >> 8) & 255;
            b = bigint & 255;
        }
    
    }

    Shot('fs', 29)
    let nametemplate = data.name === 'UAT' ? 'UAT (ACCEPTANCE RECORD)' : data.name
    Shot('t', CutPadding('w', 0, 2), 76, TexttoString(`${nametemplate}`), 'l')
    Shot('t', CutPadding('w', 0, 2), 76, TexttoString(`${nametemplate}`), 'l')
    Shot('lw', 0.2)
    doc.line(CutPadding('w', 0, 2), 76 + 4, CutPadding('w', 100, 2), 76 + 4);
    Shot('t', CutPadding('w', 0, 2), 88, TexttoString(data.project.project_name), 'l')
    Shot('t', CutPadding('w', 0, 2), 88, TexttoString(data.project.project_name), 'l')

    Shot('fs', 18)

    Shot('t', CutPadding('w', 35, 2), 160, TexttoString(`Version`), 'r')
    Shot('t', CutPadding('w', 35, 2), 166, TexttoString(`Author`), 'r')
    Shot('t', CutPadding('w', 35, 2), 172, TexttoString(`Updated Date`), 'r')
    Shot('t', CutPadding('w', 35, 2), 160, TexttoString(`Version`), 'r')
    Shot('t', CutPadding('w', 35, 2), 166, TexttoString(`Author`), 'r')
    Shot('t', CutPadding('w', 35, 2), 172, TexttoString(`Updated Date`), 'r')
    Shot('t', CutPadding('w', 49, 2), 160, TexttoString(`:`), 'r')
    Shot('t', CutPadding('w', 49, 2), 166, TexttoString(`:`), 'r')
    Shot('t', CutPadding('w', 49, 2), 172, TexttoString(`:`), 'r')


    Shot('t', CutPadding('w', 51, 2), 160, TexttoString(`${parseFloat(data.version.version) + 1}.0`), 'l')
    Shot('t', CutPadding('w', 51, 2), 166, TexttoString(`${data.version.name}`), 'l')
    Shot('t', CutPadding('w', 51, 2), 172, TexttoString(`${moment(new Date()).add('year', 543).format('DD/MM/YYYY')}`), 'l')

    let datahis = 0
    if (seting.pageversion === true) {
        doc.insertPage(seting.insertversion + 1)
        page += 1
        /*  Shot("add");
         page += 1 */
        let Historytable = {
            col1: CutPadding('w', 19, -padding),
            col2: CutPadding('w', 16, -padding),
            col3: CutPadding('w', 21, -padding),
            col4: CutPadding('w', 44, -padding),
            name1: 'Revision number',
            name2: 'Revision date',
            name3: 'Author',
            name4: 'Summary of changes',
            loop: 4
        }
        heightpage = 34
        heightpage += 2
        Shot('fs', 18)
        Shot('t', CutPadding('w', 0), heightpage, TexttoString(`I. Revision History`), 'l')
        Shot('t', CutPadding('w', 0), heightpage, TexttoString(`I. Revision History`), 'l')
        his.push(
            {
                page: page,
                name: `I. Revision History`
            }
        )
        heightpage += 4
        Shot('fs', 16)
        ShotTable('ht', Historytable, heightpage, Historytable.loop, 9, '', 'fd')
        heightpage += 14

    if (data.version.versionlist.length > 0) {

        for (let index = 0; index < data.version.versionlist.length; index++) {
            let i = data.version.versionlist[index]
            /* console.log(i); */
            let cutText1 = doc.splitTextToSize(`${i.fname || ''} ${i.lname || ''}`, CutPadding('w', 0, Historytable.col3-23));
            let cutText2 = doc.splitTextToSize(`${i.dv_summary_of_change || ''}`, CutPadding('w', 0, Historytable.col4-23));
            /* console.log(cutText1,cutText2); */
            let iscut = cutText1.length > cutText2.length ? cutText1.length : cutText2.length
        
         

            for (let cc = 0; cc < iscut; cc += 1) {
                if (heightpage > 265) {
                    newpage(265)  
                    datahis+=1
                }
           
                if (cc ===  0) {
                    Shot('t', Colposition('t', Historytable, 1, 'c'), heightpage, TexttoString(`${parseFloat(i.dv_version)}.0`), 'c');
                    Shot('t', Colposition('t', Historytable, 2, 'c'), heightpage, TexttoString(`${moment(i.dv_date).add('year', 543).format('DD/MM/YYYY')}`), 'c');
        
                }
                if (cutText1.length > cc) {
                    Shot('t', Colposition('t', Historytable, 3, 'c'), heightpage, TexttoString(cutText1[cc]), 'c');
                }
                if (cutText2.length > cc) {
                    Shot('t', Colposition('t', Historytable, 4, 'l', 1), heightpage, TexttoString(cutText2[cc]), 'l');
                }
                heightpage += 6
            }

            Shot('r', Colposition('r', Historytable, 1), heightpage - (iscut * 6) - 5, CutPadding('w', 0, Historytable.col1 - padding), (iscut * 6) + 2);
            Shot('r', Colposition('r', Historytable, 2), heightpage - (iscut * 6) - 5, CutPadding('w', 0, Historytable.col2 - padding), (iscut * 6) + 2);
            Shot('r', Colposition('r', Historytable, 3), heightpage - (iscut * 6) - 5, CutPadding('w', 0, Historytable.col3 - padding), (iscut * 6) + 2);
            Shot('r', Colposition('r', Historytable, 4), heightpage - (iscut * 6) - 5, CutPadding('w', 0, Historytable.col4 - padding), (iscut * 6) + 2);
            heightpage += 2
        }

    }

    }

    Shot("add");
    page += 1

    /*   Shot('t', CutPadding('w', 0, 2), 260, TexttoString(`TTT Brother CO., LTD. `), 'l')
      Shot('t', CutPadding('w', 0, 2), 260, TexttoString(`TTT Brother CO., LTD. `), 'l')
      Shot('t', CutPadding('w', 0, 2), 266, TexttoString(`711/1-3, PRACHARAT SAI 1 ROAD, BANGSUE, BANGKOK 10800, THAILAND `), 'l')
      Shot('t', CutPadding('w', 0, 2), 272, TexttoString(`Tel. 085-818-8910 `), 'l')
      Shot('t', CutPadding('w', 0, 2), 278, TexttoString(`Fax. – `), 'l') */


    heightpage = 34
    heightpage += 2
    let sortindex = 0
    let unit1 = data.unit1
    for (let li of unit1) {
        newpage(265)
        loopseletdata(li, 1, sortindex + 1)
    }

    let jsonunit2 = {
        col1: CutPadding('w', 6, -padding),
        col2: CutPadding('w', 52, -padding),
        col3: CutPadding('w', 12, -padding),
        col4: CutPadding('w', 30, -padding),
        name1: ['No'],
        name2: ['Features List'],
        name3: ['Accept'],
        name4: ['Remark'],
        loop: 4,
        height: 1
    }
    let unit2count = 0
    if (seting.mode === 'UAT') {
        sortindex = 0
        for (let li of data.unit2) {
if (sortindex > 0) {
    Shot("add");
    page += 1
    heightpage = 38
}
await loopseletuat(li, sortindex + 1)      
            sortindex += 1

        }

    }






    let beforheightpage = heightpage

    if (seting.pagesarabar === true) {
        /*   let chackrow = his.filter((e) => { return e.name === 'II. Related Documents' }) */
        doc.insertPage(seting.insertsarabar + 1+datahis)
        page += 1
        let numhis = 0, countpagehis = 1
        heightpage = 34
        heightpage += 2
        Shot('fs', 18)
      /*   console.log(heightpage); */
        Shot('t', CutPadding('w', 0), heightpage, TexttoString(`Table of contents`), 'l')
        Shot('t', CutPadding('w', 0), heightpage, TexttoString(`Table of contents`), 'l')
        heightpage += 8
        doc.setLineDash([0.1, 0.1], 0);
        Shot('fs', 16)
        let listhis = his
        for (let h of his) {

            Shot('t', CutPadding('w', 0), heightpage, TexttoString(h.name), 'l')
            Shot('t', CutPadding('w', 100), heightpage, TexttoString(h.page + numhis), 'r')
            /* doc.line(CutPadding('w', 0), heightpage+1 , CutPadding('w', 96), heightpage+1 ); */
            if (heightpage > 265) {
                doc.insertPage(seting.insertsarabar + 1 + countpagehis)
                page += 1
                heightpage = 32
                countpagehis += 1
            }
            heightpage += 6
            if (seting.insertsarabar === h.page) {
                numhis += 1
                Shot('t', CutPadding('w', 0), heightpage, TexttoString('Table of contents'), 'l')
                Shot('t', CutPadding('w', 100), heightpage, TexttoString(h.page + numhis), 'r')
                /* doc.line(CutPadding('w', 0), heightpage+1 , CutPadding('w', 96), heightpage+1 ); */
                heightpage += 6
                let hiscount = heightpage - 12
                for (let hc of listhis) {
                    hiscount += 6
                    if (hiscount > 265) {
                        numhis += 1
                        hiscount = 32

                    }
                }


            }

        }

    }

    /* console.log(beforheightpage); */
    if (beforheightpage > 245) {
       /*  console.log('xxx', beforheightpage); */
        Shot("add");
        page += 1
        heightpage = 38

    }
    const mmToPx = (mm) => (mm * 96) / 25.4;
    if (data.img.length > 0) {
        img = await getboximg(data.img[0].fd_img_id)
        imghead = /* await compressImage(Configs.TTT_Game_Service_IMG + img.ul_file_path ,mmToPx(wpage + 1), mmToPx(27)) */img
    }else{
        imghead = /* await compressImage(noimg,mmToPx(wpage + 1), mmToPx(27)) */noimg

    }
    for (let i = 0; i <= page; i += 1) {
        if (i > 1) {
            doc.setPage(i)
            if (seting.header === true) {
                sethead()
            }


            if (seting.footer === true) {
                setfooter(i, page)
            }
        }

    }

    await savetopdf(mode)




    /*     Shot('save', `SRS_${data.project.project_name} ${moment(new Date()).add('year', 543).format('DD/MM/YY')}.pdf`); */

    async function getboximg(imageid,check = null) {
        try{
        const response2 = await axios.get(`https://api.box.com/2.0/files/${imageid}/content`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
        });
        if (check !== null) {
            listdatabox.push({
                id:imageid,
                path:URL.createObjectURL(response2.data)
            })
        }
     return URL.createObjectURL(response2.data);
    }catch (error) {
        return null 
    }
    }


    function loopseletdata(data, lv, textlv, border = false) {
        let count = 1
        for (let i of data) {

            /*  console.log(textlv); */
            let cutText = [], cutw = 2 * (lv - 1), sort = `${textlv}`

            if (i.mode === 'description') {

                if (i.header !== undefined) {

                    heightpage += 4
                    Shot('fs', 18)
                    cutText = doc.splitTextToSize(`${i.header || ''}`, CutPadding('w', 100 - (cutw), -padding));
                    his.push(
                        {
                            page: page,
                            name: `${sortindex + 1}. ${cutText[0]}`
                        }
                    )
                    let countrow = 0
                    for (let c of cutText) {
                        newpage(endpage)
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        countrow += 1
                        heightpage += 6
                    }
                    heightpage += 2
                    sortindex += 1
                }

                if (i.lable !== undefined) {
                    Shot('fs', 16)

                    cutText = doc.splitTextToSize(lv > 1 ? `${sort}.${count} ${i.lable || ''}` : `     ${i.lable || ''}`, CutPadding('w', 100 - (cutw), -padding));

                    for (let c of cutText) {
                        newpage(endpage)
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(`${c}`), 'l')
                        heightpage += 6
                    }

                }

                if (i.sub !== undefined) {


                    loopseletdata(i.sub, lv + 1, sortindex)



                }





                /*  console.log(cutText); */
            }

            if (i.mode === 'table') {
                if (i.header !== undefined) {

                    heightpage += 4
                    Shot('fs', 18)
                    cutText = doc.splitTextToSize(`${i.header || ''}`, CutPadding('w', 100 - (cutw), -padding));
                    his.push(
                        {
                            page: page,
                            name: `${sortindex + 1}. ${cutText[0]}`
                        }
                    )
                    let countrow = 0
                    for (let c of cutText) {
                        newpage(endpage)
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        countrow += 1
                        heightpage += 6
                    }
                    heightpage += 2

                    sortindex += 1
                }
                let cutTextsub = [], cutlength = 0, befor = 0, rowall = 0
                if (i.subhead !== undefined) {
                    Shot('fs', 16)
                    cutText = doc.splitTextToSize(`${i.subhead.trim() || ''}`, CutPadding('w', 20/*  - (cutw ) */, -(padding + 6)));
                    cutTextsub = doc.splitTextToSize(`${i.sublable.trim() || ''}`, CutPadding('w', 80 - (cutw), -(padding + 4)));
                    cutlength = cutText.length > cutTextsub.length ? cutText.length : cutTextsub.length
                    befor = heightpage - 6
                    rowall = cutlength

                    if (heightpage + (cutlength * 6) > endpage) {

                        heightpage += (cutlength * 6)
                        newpage(endpage)
                    }
                   /*  console.log(i); */
                    if (i.is_border === true || border === true) {
                        Shot('fc', r, g, b);
                        Shot('r', CutPadding('w', 0), befor, CutPadding('w', 100 - (80 + cutw), -padding), ((rowall) * 6) + 2, 'fd');
                        Shot('r', CutPadding('w', 100 - (80 + cutw)), befor, CutPadding('w', 80, -padding), ((rowall) * 6) + 2);
                    }
                    for (let c = 0; c < cutlength; c += 1) {

                        /*  if (heightpage + 6 > endpage) {
                         
                            
                             heightpage += 6
                             newpage(endpage)
                             rowall-= (c+1)
                             befor = heightpage-6
                         } */
                        if (cutText.length > c) {
                            Shot('t', CutPadding('w', 100 - (80 + cutw), -2), heightpage, TexttoString(`${cutText[c]}`), 'r')
                            Shot('t', CutPadding('w', 100 - (80 + cutw), -2), heightpage, TexttoString(`${cutText[c]}`), 'r')
                        }
                        if (cutTextsub.length > c) {
                            Shot('t', CutPadding('w', 100 - (80 + cutw), 2), heightpage, TexttoString(`${cutTextsub[c]}`), 'l')
                        }
                        heightpage += 6
                    }
                    heightpage += 2

                }

                if (i.sub !== undefined) {


                    loopseletdata(i.sub, lv + 1, sortindex, i.is_border === true ? true : false)


                }


            }

            if (i.mode === 'table_column') {
                if (i.header !== undefined) {

                    heightpage += 4
                    Shot('fs', 18)
                    cutText = doc.splitTextToSize(`${i.header || ''}`, CutPadding('w', 100 - (cutw)));
                    his.push(
                        {
                            page: page,
                            name: `${sortindex + 1}. ${cutText[0]}`
                        }
                    )
                    let countrow = 0
                    for (let c of cutText) {
                        newpage(endpage)
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        countrow += 1
                        heightpage += 6
                    }
                    /*   heightpage += 2 */

                    sortindex += 1
                }

                let jsonhead = {}
                if (i.headtable !== undefined) {
                
                    let counth = 0, hht = 0, lh = i.headtable.length
                    for (let ht of i.headtable) {

                        let nameh = doc.splitTextToSize(`${ht.header || ''}`, CutPadding('w', 100 / lh, -padding));
                        jsonhead[`col${counth + 1}`] = CutPadding('w', 100 / lh, -padding)
                        jsonhead[`name${counth + 1}`] = nameh

                        if (hht < nameh.length) {
                            hht = nameh.length
                        }
                        counth += 1
                    }

                    jsonhead.loop = counth
                    jsonhead.height = hht
                    if (heightpage + (jsonhead.height * 7) > endpage) {

                        heightpage += (jsonhead.height * 7)
                        newpage(endpage)
                    }
                    Shot('fs', 16)
                    ShotTable('htc', jsonhead, heightpage, jsonhead.loop, 7, '', 'fd', jsonhead.height)

                }

                if (i.datatable !== undefined) {
                    Shot('fs', 16)
                    for (let c of i.datatable) {
                        let jsonlable = {}
                        let countl = 0, hlt = 0, ll = c.length
                        for (let lt of c) {
                            let namel = doc.splitTextToSize(`${lt || ''}`, CutPadding('w', 100 / ll, -(padding + 3)));

                            jsonlable[`col${countl + 1}`] = CutPadding('w', 100 / ll, -padding)
                            jsonlable[`name${countl + 1}`] = namel

                            if (hlt < namel.length) {
                                hlt = namel.length
                            }
                            countl += 1

                        }
                        jsonlable.loop = countl
                        jsonlable.height = hlt
                        /* if (heightpage + (jsonlable.height * 6) > endpage) {

                            heightpage += (jsonlable.height * 6)
                            newpage(endpage)
                        } */
                        ShotTable('ltc', jsonlable, heightpage, jsonlable.loop, 6, '', '', jsonlable.height)



                    }
                    heightpage += 4
                }

            }
            count += 1
        }

    }





    async function loopseletuat(data, lv) {
        /*   newpage(245) */
        console.log(data);
        
        if (data.sub.length > 0) {
            let cutText = [], sort = `${lv}`, countrow = 0,countcutmax=0
            heightpage += 4
            newpage(235)
            Shot('fs', 18)
            cutText = doc.splitTextToSize(`${sort}. ${data.module_name || ''}`, CutPadding('w', 100, -padding));
            his.push(
                {
                    page: page,
                    name: `${cutText[0]}`
                }
            )
            countrow = 0
            for (let c of cutText) {
                newpage(endpage)
                Shot('t', CutPadding('w', 0), heightpage, TexttoString(countrow === 0 ? `${c}` : `${c}`), 'l')
                Shot('t', CutPadding('w', 0), heightpage, TexttoString(countrow === 0 ? `${c}` : `${c}`), 'l')
                countrow += 1
                if (countrow !== cutText.length - 1) {
                    heightpage += 6
                }
            }
            /*  heightpage += 2 */
            let subsort = 0
            for (let m of data.sub) {
                /*     heightpage += 4 */
                newpage(235)
                Shot('fs', 18)
                cutText = doc.splitTextToSize(`${sort}.${subsort + 1} ${m.menu_name || ''}`, CutPadding('w', 100 - (3), -padding));
                his.push(
                    {
                        page: page,
                        name: `   ${cutText[0]}`
                    }
                )
                countrow = 0
                for (let c of cutText) {
                    newpage(endpage)
                    Shot('t', CutPadding('w', 3), heightpage, TexttoString(`${c}`), 'l')
                    Shot('t', CutPadding('w', 3), heightpage, TexttoString(`${c}`), 'l')

                    if (countrow !== cutText.length - 1) {
                        heightpage += 6
                    }
                    countrow += 1
                }
                heightpage += 2
                newpage(endpage)
                subsort += 1
                if (endpage < heightpage + 20) {
                    heightpage += 20
                newpage(endpage)
                }
                Shot('fs', 16)
                ShotTable('htc', jsonunit2, heightpage, jsonunit2.loop, 7, '', 'fd', jsonunit2.height)

                heightpage += 6
                let subsortelement = 0, befor = 0, countall = 0
                if (m.element_list.length > 0) {

                    for (let p of m.element_list) {
                        cutText = doc.splitTextToSize(`     ${subsortelement + 1} ${p.element_name || ''} ${p.element_description || ''}`, CutPadding('w', 100, -(padding + 5)));
                        befor = heightpage - 6
                        countall = cutText.length * 6
                        if (endpage < heightpage + (cutText.length * 6)) {
                            heightpage += cutText.length * 6
                            newpage(endpage)
                            befor = 34
                            countall += 2
                            heightpage += 2
                            ShotTable('htc', jsonunit2, heightpage, jsonunit2.loop, 7, '', 'fd', jsonunit2.height)
                            heightpage += 8
                            befor+=7+6
                        }

                        countrow = 0
                        for (let c of cutText) {

                            if (countrow === 0) {
                             let chackimg = listdatabox.findIndex((e)=>{return e.id === p.element_image})
                                Shot('i', /* `${Configs.TTT_Game_Service_IMG + p.element_image}` */chackimg !== -1 ? listdatabox[chackimg].pathbox?  listdatabox[chackimg].pathbox : listdatabox[chackimg].path : await getboximg(p.element_image,'check'), CutPadding('w', 0, 3), heightpage - 3.5, 4, 4)
                            }
                            Shot('t', CutPadding('w', 0, 3), heightpage, TexttoString(`${c}`), 'l')
                            Shot('t', CutPadding('w', 0, 3), heightpage, TexttoString(`${c}`), 'l')


                            heightpage += 6

                            countrow += 1
                        }

                        heightpage += 2
                        Shot('r', Colposition('r', jsonunit2, 1), befor, CutPadding('w', 100, - padding), countall + 2);


                        if (p.promt_list.length > 0) {
                            let countel = 0
                            for (let el of p.promt_list) {
                                let isstart = true
                                cutText = doc.splitTextToSize(`${el.sm_comment.trim()}`, CutPadding('w', 0, jsonunit2.col2 - (padding + 5)));
                                befor = heightpage - 6
                                countall = cutText.length * 6
                                if (endpage < heightpage + (2 * 6)) {
                                    heightpage += 2 * 6
                                    newpage(endpage)
                                    befor = 32
                                    ShotTable('htc', jsonunit2, heightpage, jsonunit2.loop, 7, '', 'fd', jsonunit2.height)
                                    heightpage += 6
                                    befor+=7+6
                                }
                                countrow = 0
                                let countend = 0
                                countcutmax = cutText.length
                                for (let c of cutText) {

                                    if (endpage < befor + ((countrow + 1) * 6)) {
                                        Shot('r', Colposition('r', jsonunit2, 1), befor, CutPadding('w', 0, jsonunit2.col1 - padding), ((countrow + 1) * 6) + 2);
                                        Shot('r', Colposition('r', jsonunit2, 2), befor, CutPadding('w', 0, jsonunit2.col2 - padding), ((countrow + 1) * 6) + 2);
                                        Shot('r', Colposition('r', jsonunit2, 3), befor, CutPadding('w', 0, jsonunit2.col3 - padding), ((countrow + 1) * 6) + 2);
                                        Shot('r', Colposition('r', jsonunit2, 4), befor, CutPadding('w', 0, jsonunit2.col4 - padding), ((countrow + 1) * 6) + 2);
                                        heightpage += ((countrow + 1) * 6) + 2
                                        newpage(endpage)
                                  
                                        befor = 32
                                        ShotTable('htc', jsonunit2, heightpage, jsonunit2.loop, 7, '', 'fd', jsonunit2.height)
                                        heightpage += 6
                                        befor+=7+6
                                        countcutmax-= countrow
                                        countall = (countcutmax) * 6
                                        countrow = 0
                                    }
                                    if (countrow === 0 && isstart === true) {
                                        Shot('t', Colposition('t', jsonunit2, 1, 'c'), heightpage, TexttoString(`${subsortelement + 1}.${countel + 1}`), 'c')

                                        doc.roundedRect(Colposition('r', jsonunit2, 3, 6.5), heightpage - 3, 7, 7, 1.5, 1.5);
                                        countel += 1
                                        isstart = false
                                    }
                                    let newtext = c.replace("\t", "  ")
                                    Shot('t', Colposition('t', jsonunit2, 2, 'l', 3), heightpage, TexttoString(newtext), 'l')

                                    heightpage += 6
                                    countrow += 1

                                    countend += 1
                                }
                                /* console.log(2 * 6, countall); */
                                if (2 * 6 > countall) {
                                    countall = 2 * 6
                                    heightpage += 6
                                }
                                heightpage += 2
                                Shot('r', Colposition('r', jsonunit2, 1), befor, CutPadding('w', 0, jsonunit2.col1 - padding), countall + 2);
                                Shot('r', Colposition('r', jsonunit2, 2), befor, CutPadding('w', 0, jsonunit2.col2 - padding), countall + 2);
                                Shot('r', Colposition('r', jsonunit2, 3), befor, CutPadding('w', 0, jsonunit2.col3 - padding), countall + 2);
                                Shot('r', Colposition('r', jsonunit2, 4), befor, CutPadding('w', 0, jsonunit2.col4 - padding), countall + 2);
                            }

                        }






                        subsortelement += 1
                    }
                }

                if (m.screen_shot.length > 0) {


                    let ss = m.screen_shot[0].ehv_value.data, countss = 0
                    for (let s of ss) {
                        let screen = s.data[s.data.length - 1]
                        cutText = doc.splitTextToSize(`${screen.remark}`, CutPadding('w', 80, -padding));
                        if (countss === 0) {
                            if (endpage < heightpage + 80 + 8) {
                                heightpage +=  80 + 8
                                newpage(endpage)

                            }
                            heightpage += 4
                            Shot('fs', 18)
                            Shot('t', CutPadding('w', 0), heightpage, TexttoString(`${'รูปภาพที่เกี่ยวข้อง'}`), 'l')
                            Shot('t', CutPadding('w', 0), heightpage, TexttoString(`${'รูปภาพที่เกี่ยวข้อง'}`), 'l')
                            Shot('fs', 16)
                            heightpage += 4
                        }
                        heightpage += 2

                        if (endpage < heightpage + 80) {
                            heightpage +=  80
                            newpage(endpage)

                        }

                        await Shot(
                            'i',
                            /* `${Configs.TTT_Game_Service_IMG}${screen.path}` */await getboximg(screen.pathbox ? screen.pathbox  : screen.path),
                             CutPadding('w', 20, -padding),
                            heightpage,
                             CutPadding('w', 80, -padding),
                            75,
                            'fit',
                            screen
                        );
                        heightpage += 80
                        countrow = 0
                        for (let c of cutText) {
                            if (endpage < heightpage + ((countrow + 1) * 6)) {
                                heightpage += ((countrow + 1) * 6)
                                newpage(endpage)
                            }
                            Shot('t', CutPadding('w', 20, -padding), heightpage, TexttoString(`${c}`), 'l')
                            heightpage += 6
                        }
                        countss += 1
                    }
                    if (sort < data.length && subsort < data.sub.length - 1) {
                        Shot('add')
                        page += 1
                        heightpage = 38
                    }




                }

                heightpage += 4












            }

        }
    }

    function newpage(count) {
        if (heightpage > count) {
            Shot("add");
            page += 1
            heightpage = 38

        }
    }

    function measureText(text, fontSize) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = `${fontSize}px THSarabunNew`;

        const metrics = context.measureText(text);
        return { width: metrics.width };

    }

    function sethead() {
        Shot('i', /* img.ul_file_path !== undefined ? Configs.TTT_Game_Service_IMG + img.ul_file_path : */ imghead, 0, 0, wpage + 1, 27)
    }
    function setfooter(on, max) {

        if (seting.mode === 'UAT' && on === max) {

            Shot('t', CutPadding('w', 100), 255, TexttoString(`  Approved By:................................................................`), 'r')
            Shot('t', CutPadding('w', 100), 261, TexttoString(`(................................................................)`), 'r')
            Shot('t', CutPadding('w', 100), 267, TexttoString(`  Date................................................................`), 'r')

        }
        Shot('lw', 0.3)
        Shot('fs', 13)
        Shot('t', CutPadding('w', 50, 2), 273 + 2, TexttoString(`Page ${on} of ${max}`), 'c')
        doc.line(CutPadding('w', 0, 2), 273 + 4, CutPadding('w', 100, 2), 273 + 4);
        Shot('t', CutPadding('w', 0, 2), 273 + 8, TexttoString(`Template v2.0 Copyright © TTT Brother CO., LTD. `), 'l')
        Shot('t', CutPadding('w', 95, 2), 273 + 8, TexttoString(`V${parseFloat(data.version.version) + 1}.0`), 'r')

    }
    /* --------------------------------------------------------------------------------------------------------------------------------------------------------------- */


    function setDetethai(mode, date, ps, cut, length) {
        let currentDate = new Date(date);
        let day = currentDate.getDate();
        let monthNamesShort = [
            "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
            "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
        ];
        let monthIndex = currentDate.getMonth();
        let thaiYear = (currentDate.getFullYear() + 543).toString().slice(-2); // แปลงเป็น ปีไทยย่อ
        let dayNameShort = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'][currentDate.getDay()];

        let formattedDate = ''
        if (mode === 'day') {
            formattedDate = `${dayNameShort} ${day}/${monthNamesShort[monthIndex]}/${thaiYear}`;
        }

        /*  Shot('t', Colposition('t', tableschedule, ps, 'r', 15), inpage + (cut - (length * 5) + 2), TexttoString(formattedDate), 'r'); */
    }

    const getImageBase64FromUrl = async (imageUrl) => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    async function Shot(mode, c1, c2, c3, c4, c5, c6, c7) {
        if (mode === 'add') {
            if (c1 !== undefined) {
                return doc.addPage(c1);
            } return doc.addPage()
        }
        if (mode === 'new') {
            window.open(doc.output('bloburl'));
        }
        if (mode === 'save') {

            if (c1 !== undefined) {
                return doc.output('save', c1);
            } return doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY'));

        }
        if (mode === 'newsave') {
            if (c1 !== undefined) {
                return window.open(doc.output('bloburl'), doc.output('save', c1));

            } return window.open(doc.output('bloburl'), doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY')));

        }

        /*  if (mode === 'qr') {
           let imgqr = ''
         QRCode.toDataURL(c1, function (err, url) {
           imgqr = url;
         });
   
         return doc.addImage(imgqr, 'JPEG', c2, c3, c4, c4);
       }
      */
        if (mode === 'fs') {
            doc.setFontSize(c1)
        }
        if (mode === 'dc') {
            doc.setDrawColor(c1, c2, c3);
        }
        if (mode === 'tc') {
            doc.setTextColor(c1, c2, c3);
        }
        if (mode === 'fc') {
            doc.setFillColor(c1, c2, c3);
        }
        if (mode === 'lw') {
            doc.setLineWidth(c1);
        }


        if (mode === 'i' && c1 !== null) {
            if (c5 !== undefined) {
                if (c6 !== undefined) {
/* console.log(c1); */
                    if (c6 === 'fit') {
                     

                       /*  console.log(c2 + x, c3 + y, nw, nh); */
                      
                       let Width = c7.width? c7.width : 1920;
                       let Height = c7.height? c7.height : 1080; 
                    
                      const imgar = Width / Height;
                      const reactar = c4 / c5;

                      let scale = 1;
                      if (imgar > reactar) {
                          scale = c4 / Width;
                      } else {
                          scale = c5 / Height;
                      }
                      const nw = Width * scale;
                      const nh = Height * scale;

                      const x = (c4 - nw) / 2;
                      const y = (c5 - nh) / 2;


                      const compressedImgData = await compressImage(c1);


                       return doc.addImage(compressedImgData, 'JPEG', c2 + x, c3 + y, nw, nh, undefined, 'FAST')
                    }
                } return doc.addImage(c1, 'JPEG', c2, c3, c4, c5);
            } return doc.addImage(c1, 'JPEG', c2, c3, c4, c4);

        }
        if (mode === 'r') {
            if (c5 !== undefined) {
                if (c5 === 'd') {
                    return doc.rect(c1, c2, c3, c4, 'D');
                }
                if (c5 === 'fd') {
                    return doc.rect(c1, c2, c3, c4, 'FD');
                }
                if (c5 === 'f') {
                    return doc.rect(c1, c2, c3, c4, 'F');
                }
            } else return doc.rect(c1, c2, c3, c4);

        }
        if (mode === 't') {

            if (c4 !== undefined) {
                if (c4 === 'c') {
                    return doc.text(c1, c2, c3, 'center');
                }
                if (c4 === 'r') {
                    return doc.text(c1, c2, c3, 'right');
                }
                if (c4 === 'l') {
                    return doc.text(c1, c2, c3, 'left');
                }
            } else return doc.text(c1, c2, c3);
        }

    }




    function CutPadding(mode, percent, num) {

        if (mode === 'h') {
            if (num !== undefined) {
                return (((hpage/*  - (padding * 2) */) * percent) / 100)/*  + padding */ + num;
            } return (((hpage/*  - (padding * 2) */) * percent) / 100) /* + padding */;

        }
        if (mode === 'w') {
            if (num !== undefined) {
                return (((wpage - (padding * 2)) * percent) / 100) + padding + num;
            } return (((wpage - (padding * 2)) * percent) / 100) + padding;


        }

    }

    function TexttoString(text) {
        if (text === null) {
            return ''.toString();
        } return text.toString();

    }
    function NumtoString(num, fix) {
        if (num !== null) {
            if (fix !== undefined) {
                return parseFloat(num).toFixed(fix).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } return parseFloat(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } return parseFloat(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function ShotTable(mode, head, pos, col, coll, loop, color, hig,header) {
        /* console.log(mode, head, pos, col, coll, loop, color); */
        let collengthsum = coll === undefined ? 5 : coll
        let loopsum = loop === undefined ? 10 : loop
        if (mode === 'ht') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum, color);
                if (head[`name${t}`] !== undefined) {
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                }


            }
        }

        if (mode === 'lt') {
            let lengthloop = (loopsum * collengthsum) + collengthsum
            for (let t = 1; t <= col; t += 1) {

                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), lengthloop);


            }

        }

        if (mode === 'st') {
            let lengthloop = collengthsum
            for (let l = 0; l < loopsum; l += 1) {
                for (let t = 1; t <= col; t += 1) {
                    Shot('r', Colposition('r', head, t), pos + lengthloop, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum);

                }
                lengthloop += collengthsum
            }
        }

        if (mode === 'htc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig, color);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += collengthsum * hig
        }
        if (mode === 'ltc') {
            let befor = 0, higbefor = pos, maxhig = 0
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4); */

                if (head[`name${t}`] !== undefined) {


                    for (let d = befor; d < head[`name${t}`].length; d += 1) {
                        let c = head[`name${t}`][d]
                        if (heightpage + ((d - befor) * 6) > 250) {
                            if (t < col) {
                                for (let t2 = t + 1; t2 <= col; t2 += 1) {
                                    let fakecolsum = coll
                                    for (let dx = befor; dx <= d; dx += 1) {
                                        if (head[`name${t2}`].length > dx) {
                                            let cx = head[`name${t2}`][dx]

                                            Shot('t', Colposition('t', head, t2, 'l', 3), higbefor + (fakecolsum), TexttoString(cx), 'l');
                                            fakecolsum += coll
                                        } else {
                                            break
                                        }

                                    }

                                }

                            }
                            for (let al = 1; al <= col; al += 1) {
                                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), collengthsum + 2);
                            }

                            heightpage += ((d - befor) * 6)
                            newpage(250)
                            higbefor = heightpage
                            collengthsum = col
                            befor = d
                            maxhig = col
                            if (header !== undefined) {
                                ShotTable('htc', header, heightpage, header.loop, 7, '', 'fd', header.height)
                                higbefor+=7
                            }
                           
                        }

                        Shot('t', Colposition('t', head, t, 'l', 3), higbefor + (collengthsum), TexttoString(c), 'l');
                        collengthsum += coll

                    }
                    if (collengthsum > maxhig) {
                        maxhig = collengthsum
                    }

                    collengthsum = coll

                    /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), befor * higl + 4); */
                }
                /* befor+=1 */


            }
            for (let al = 1; al <= col; al += 1) {
                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), maxhig);
            }
            heightpage += maxhig
            /*  heightpage += (collengthsum * hig) + 4 */
        }

        if (mode === 'stc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'l'), pos + (collengthsum /* / 1.6 */), TexttoString(c), 'l');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += (collengthsum * hig) + 4
        }



    }
    function Colposition(mode, name, col, pos, pad) {


        if (mode === 'r') {
            const poscol = pos !== undefined ? pos : 0
            const colcount = col - 1
            let colsum = 0

            for (let i = colcount - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }

            if (colcount === 0) {
                return CutPadding('w', 0)
            } return CutPadding('w', 0, name[`col${colcount}`] + poscol) + colsum


        }
        if (mode === 't') {
            let colsum = 0
            const paddingcol = pad !== undefined ? pad : 0
            for (let i = col - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }


            if (col === 0) {
                return CutPadding('w', 0)
            }

            if (pos !== undefined) {
                if (pos === 'c') {
                    return CutPadding('w', 0, (name[`col${col}`] * (50 + paddingcol)) / 100) + colsum
                }
                if (pos === 'l') {
                    return CutPadding('w', 0, (name[`col${col}`] * (1 + paddingcol)) / 100) + colsum
                }
                if (pos === 'r') {
                    return CutPadding('w', 0, (name[`col${col}`] * (99 - paddingcol)) / 100) + colsum
                }
            } return CutPadding('w', 0, name[`col${col}`]) + colsum



        }



    }


    function loadImageAsync(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });
    }

    async function savetopdf(mode) {
        if (mode === 'version') {
            const dataUri = doc.output('datauristring');
            const binaryData = atob(dataUri.split(',')[1]);
            const arrayBuffer = new ArrayBuffer(binaryData.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryData.length; i++) {
                uint8Array[i] = binaryData.charCodeAt(i);
            }

            const blob = new Blob([uint8Array], { type: 'application/pdf' });
            const file = new File([blob], `${data.name}${data.project.project_name} ${moment(new Date()).add('year', 543).format('DD_MM_YY')}.pdf`, { type: 'application/pdf' });
            console.log(file);
            axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/report/saveversiondoc`,
                headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    project_id: id,
                   /*  path_file: response.data.data[0].path, */
                    file_name: file.name,
                    template_type:  data.name,
                    dv_summary_of_change: summaryofchange || null
    
                }
    
            })
                .then(function (response) {
                    if (response.data) {
    
    
                        CheckAndCreateFolderBox(response.data.data,response.data.olddata,file)
    
    
    
    
                   /*      return true */
                    }
    
    
    
                })
                .catch(function (error) {
                    console.log(error);
    
                });


                async function CheckAndCreateFolderBox(data,olddata,file) {
                    let temp = {
                        folderLis: ["PROJECT",`PROJECT_${id}`,"PROJECT DOCUMENTS","UAT"]
                    }
                   /*  temp.folderLis.push(folder_name) */
                    await axios({
                        method: 'post',
                        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
                        headers: {
                          Authorization: sessionStorage.getItem('access_token'),
                          "X-TTT": `${Configs.API_TTT}`,
                          "Content-Type": "application/json",
                        },
                        data: temp
                    })
                        .then(async function (response) {
                            if (response.data) {
                                
                                data.files = file
                                await UploadFiles(data, response.data.folder_id,olddata)
                         
                            }
                        })
                        .catch(function (error) {
                           
                            console.log(error);
                        });
                }
                
                
                
                async function UploadFiles(item, folder_id,olddata) {
                  console.log(item);
                  const dataImg = new FormData();
                  dataImg.append('File', item.files);
                  dataImg.append('folder_id', folder_id);
                  dataImg.append('key', "UAT");
                  dataImg.append('image_old_list', JSON.stringify(olddata));
                  dataImg.append('ref_id', item.id);
                
                  await axios({
                      method: 'post',
                      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
                      headers: {
                        Authorization: sessionStorage.getItem('access_token'),
                        "X-TTT": `${Configs.API_TTT}`,
                        "Content-Type": "multipart/form-data",
                      },
                      data: dataImg
                  })
                      .then(async function (response) {
                          if (response.data) {
                            if (response.data.image_id_list.length > 0) {
                                return true
                            
                            }
                           
                          }
                      })
                      .catch(function (error) {
                        
                          console.log(error);
                      });
                }
           /*  dataImg.append('Profile', file);
            dataImg.append('typeFile', 'document_version');
            dataImg.append('projectKey', "tttspaceship");
            await axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                headers: {
                   
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/octet-stream',
                },
                data: dataImg,
            })
                .then(async function (response) {
                    if (response.data.status == true) {

                        axios({
                            method: 'post',
                            url: `${Configs.TTT_Game_Service}/api/report/saveversiondoc`,
                            headers: {
                                Authorization: sessionStorage.getItem('access_token'),
                                'X-TTT': `${Configs.API_TTT}`,
                                'Content-Type': 'application/json',
                            },
                            data: {
                                project_id: id,
                                path_file: response.data.data[0].path,
                                file_name: response.data.data[0].orgin_name,
                                template_type: data.name,
                                dv_summary_of_change: summaryofchange || null

                            }

                        })
                            .then(function (response) {

                                return true




                            })
                            .catch(function (error) {
                                console.log(error);

                            });

                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
 */







        } else {
        
            Shot('save', `${data.name}${data.project.project_name} ${moment(new Date()).add('year', 543).format('DD/MM/YY')}.pdf`);

            return true


        }

    }
}






export default reportallseting;
