import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { useLocation } from 'react-router-dom';
import './style.css';
import { Row, Col, DatePicker, Input, Table, ConfigProvider } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import axios from 'axios';
import iconback_doorStage from "../../assets/img/icon/IconBack_stage.png";
import Configs from "../../config";
import {
    PushpinOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import * as XLSX from 'xlsx-js-style';
import { set } from 'lodash';

import BarChart from './BarChart';

const { RangePicker } = DatePicker;
function ProjecTimeline() {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
const id = useQuery().get('id');

  const [headerCalendar,setHeaderCalendar] = useState(['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']);
  const [weekCalendar,setWeekCalendar] = useState([]);
  const [openChart,setOpenChart] = useState(false);
  const toggle_open_chart = () =>{setOpenChart(!openChart)};
  const [projectDetail,setProjectDetail] = useState({
    project_id:'',
    project_name:'',
    start_date:'',
    end_date:'',
    start_date_:'',
    end_date_:'',
    create_date:''
  })

  const [isLoad,setIsLoad] = useState(false);

  const [logStage,setLogStage] = useState([]);

  const[allDays,setAllDays] = useState([]);

  function getDatesOfWeek(weekNumber, year,avg_per_day,last_per_plan,start_date,end_date,log) {
    let logStage_ = log || logStage || [];
    // ตั้งค่าปีและเลขสัปดาห์
    const startOfWeek = moment().year(year).week(weekNumber).startOf('week');

    // สร้างอาร์เรย์เก็บวันที่
    const weekDates = [];


 /*    let tempDays = JSON.parse(JSON.stringify(allDays));
    console.log('tempDays',tempDays) */
    // วนลูปเพื่อเพิ่มวันที่ในอาร์เรย์
    let per_plan  = last_per_plan;
    for (let i = 0; i < 7; i++) {
        let checkActual = logStage_.filter((e)=>{return startOfWeek.clone().add(i, 'days').format('YYYYMMDD') == moment(e.lsm_created_date).format('YYYYMMDD')});
        let temp ={
            day_full:startOfWeek.clone().add(i, 'days').format(),
            day:startOfWeek.clone().add(i, 'days').format('DD'),
            percent_plan:null,
            percent:0
        }
        if(checkActual.length > 0){
            temp.percent =   JSON.parse(checkActual[0].lsm_stage_data).project_progress;
        }else{
            let checkLastActual = logStage_.filter((e)=>{return parseInt(startOfWeek.clone().add(i, 'days').format('YYYYMMDD')) >= parseInt(moment(e.lsm_created_date).format('YYYYMMDD'))});
            if(checkLastActual.length > 0){
              
                temp.percent =   JSON.parse(checkLastActual[checkLastActual.length -1].lsm_stage_data).project_progress;
            }
        }

        
        if(parseInt(startOfWeek.clone().add(i, 'days').format('YYYYMMDD')) >= parseInt(moment(start_date).format('YYYYMMDD')) && parseInt(startOfWeek.clone().add(i, 'days').format('YYYYMMDD')) <= parseInt(moment(end_date).format('YYYYMMDD'))){
            
           
            per_plan = parseFloat(parseFloat(per_plan) + parseFloat(avg_per_day));
            temp.percent_plan = per_plan;
        
        }else{
            console.log(parseInt(moment(start_date).format('YYYYMMDD')),parseInt(startOfWeek.clone().add(i, 'days').format('YYYYMMDD')),parseInt(moment(end_date).format('YYYYMMDD')))
        }
     
      
        weekDates.push(temp);
    }
/*     setAllDays(tempDays); */
    return weekDates;
}

function getWeeksInYear(year) {
    // หาวันที่ 28 ธันวาคมของปีนั้น เพื่อหาว่าปีนั้นมี 52 หรือ 53 สัปดาห์
    const lastWeek = moment(`${year}-12-28`).isoWeek();

    return lastWeek;
  }

async function getProjectTimeline(){
    setIsLoad(true);
    await axios({
        method: "GET",
        url: `${Configs.TTT_Game_Service}/api/project/getProjectTimeline/${id}`,
        headers: {
            Authorization: sessionStorage.getItem('access_token'),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
        },/*  data: temp, */
    })
        .then(async function (response) {


            setProjectDetail({...projectDetail,
                project_id:response.data.project_id,
                project_name:response.data.project_name,
                start_date_:response.data.pp_start_date,
                end_date_:response.data.pp_finish_date,
                create_date:response.data.logStage.length > 0 ? response.data.logStage[0].lsm_created_date : null

            });
            projectDetail.create_date = response.data.logStage.length > 0 ? response.data.logStage[0].lsm_created_date : null;
            let logStage_ = response.data.logStage || [];
            console.log(logStage_)
            setLogStage(logStage_);
            
/* 
            
            let start_week = moment(response.data.pp_start_date).week();
            let end_week = moment(response.data.pp_finish_date).week();
            //console.log(start_week,end_week)
            let temp_week = [];
            let start_year = moment(response.data.pp_start_date).year();
            let end_year = moment(response.data.pp_finish_date).year();
            let year_list = [];
            let diff_year = moment(response.data.pp_finish_date).diff(moment(response.data.pp_start_date),'year');

            let diff_day = moment(response.data.pp_finish_date).diff(moment(response.data.pp_start_date),'day');
            let percent_plan_avg_day = parseFloat(100 / (diff_day+1));
            let last_per_plan = 0;
            //console.log(percent_plan_avg_day)
            

            for(let i_ =start_year; i_<=end_year;i_++){ 
                    console.log('i_',i_)
                    let all_week = 52;
                    if(i_ !== start_year){
                        start_week = 1;
                    }
                        if(end_year == i_  && start_year != end_year){
                            all_week = end_week;
                            start_week = 1;
                        }
                        if(start_year == end_year  && moment(response.data.pp_start_date).week() <= moment(response.data.pp_finish_date).week() && end_week != 1){
                            all_week = end_week;
                        }

                  
                        //console.log('ก่อน loop week',start_week, ' <=',all_week) 
                    for(let i = start_week;i <= all_week;i++){ 
                        //console.log('check',i,i_)
                        let temp ={
                            week:i,
                            day_list:getDatesOfWeek(i,i_,percent_plan_avg_day,last_per_plan,response.data.pp_start_date,response.data.pp_finish_date,logStage_),
                            year:i_
                        }
                        last_per_plan = temp.day_list[temp.day_list.length-1].percent_plan;
                       let check = temp_week.filter((e)=>{return e.week == i && e.year == i_});
                       //console.log('check',check)
                        if(check.length == 0){
                            temp_week.push(temp);
                        }
                       

                    }
            }

            if(start_year == end_year && end_week == 1){
                let temp ={
                    week:1,
                    day_list:getDatesOfWeek(1,end_year+1,percent_plan_avg_day,last_per_plan,response.data.pp_start_date,response.data.pp_start_date),
                    year:end_year+1
                }
                last_per_plan = temp.day_list[temp.day_list.length-1].percent_plan;
               let check = temp_week.filter((e)=>{return e.week == 1 && e.year == end_year+1});
               //console.log('check',check)
                if(check.length == 0){
                    temp_week.push(temp);
                }
            } */

            calDay(response.data.pp_start_date,response.data.pp_finish_date,response.data.project_id,response.data.project_name,logStage_);


         
            projectDetail.project_id = response.data.project_id;
            projectDetail.project_name =response.data.project_name;
          
            

     

                     
          /*   console.log(temp_week)
            setWeekCalendar(temp_week) */

            setIsLoad(false);
            calToDay();
        })
        .catch(function (error) {
            setIsLoad(false);
            console.log(error);
        });
}
useEffect(()=>{
    /* console.log(getWeeksInYear(2012)); */
/*     console.log(getWeeksInYear(2022));
    console.log(getWeeksInYear(2023));
    console.log(getWeeksInYear(2024)); */
   getProjectTimeline();
},[])


const [test,settest] = useState({test:''});


async function calDay(start,end,project_id,project_name,stage){
  

    setIsLoad(true);
    setProjectDetail({...projectDetail,
        start_date:start,
        end_date:end,
        start_date_:start,
        end_date_:end,
        project_id:project_id ? project_id : projectDetail.project_id,
        project_name:project_name ? project_name : projectDetail.project_name,

    });
    let start_week = moment(start).week();
    let end_week = moment(end).week();
  
    let temp_week = [];
    let start_year = moment(start).year();
    let end_year = moment(end).year();
    let year_list = [];
    let diff_year = moment(end).diff(moment(start),'year');

    let diff_day = moment(end).diff(moment(start),'day');
    let percent_plan_avg_day = parseFloat(100 / (diff_day+1));
    let last_per_plan = 0;

    if(moment(start).diff(moment(start).startOf('week'), 'day') < 4 && new Date(start).getMonth() === 11){
        start_year+=1
     }else{
      
     }
     if(moment(end).diff(moment(end).startOf('week'), 'day') < 4  && new Date(end).getMonth() === 11){
        end_year+=1
     }else{
         
     }
    let tempDays = [];
   
    for(let i_ =start_year; i_<=end_year;i_++){ 
           // console.log('i_',i_)
            let all_week = 52;
/*             if() */
            if(i_ !== start_year){
                start_week = 1;
            }
                if(end_year == i_  && start_year != end_year){
                    all_week = end_week;
                    start_week = 1;
                }

                if(start_year == end_year  && moment(start).week() <= moment(end).week() && end_week != 1){
               
                   
                    all_week = end_week;
                }

             
           
                for(let in_=0; in_ < 7; in_++){
                    let check_end_week = moment(`${24+in_}/12/${i_}`,'DD/MM/YYYY').week();
                    if(check_end_week == 53){
                        all_week = check_end_week
                    }
                }
              
             
                
          
            console.log('ก่อน loop week',start_week, ' <=',all_week) 
            for(let i = start_week;i <= all_week;i++){ 
                //console.log('check',i,i_)
                let temp ={
                    week:i,
                    day_list:getDatesOfWeek(i,i_,percent_plan_avg_day,last_per_plan,start,end,stage ),
                    year:i_
                }
                for(let item of temp.day_list){
                    if(
                        parseInt(moment(item.day_full).format('YYYYMMDD')) >= parseInt(moment(start).format('YYYYMMDD')) 
                        &&
                        parseInt(moment(item.day_full).format('YYYYMMDD')) <= parseInt(moment(end).format('YYYYMMDD')) 
                    
                    )
                    tempDays.push(item);
                }
                last_per_plan = temp.day_list[temp.day_list.length-1].percent_plan;
               let check = temp_week.filter((e)=>{return e.week == i && e.year == i_});
               //console.log('check',check)
                if(check.length == 0){
                    temp_week.push(temp);
                }
               

            }
    }
    if(start_year == end_year && end_week == 1){
        let temp ={
            week:1,
            day_list:getDatesOfWeek(1,end_year+1,percent_plan_avg_day,last_per_plan,start,end),
            year:end_year+1
        }
        last_per_plan = temp.day_list[temp.day_list.length-1].percent_plan;
       let check = temp_week.filter((e)=>{return e.week == 1 && e.year == end_year+1});
       //console.log('check',check)
        if(check.length == 0){
            temp_week.push(temp);
        }
    }
  


 

   // console.log('diff_day',diff_day,percent_plan_avg_day)
    



   setAllDays(tempDays)     
    //console.log(temp_week)
    setWeekCalendar(temp_week)

    setIsLoad(false);
}

function truncateToTwoDecimals(number) {
    return Math.floor(number * 100) / 100;
}


function calToDay(){
   /*  let temp = weekCalendar;

    let checkIndex =temp.findIndex((w)=>moment(new Date()).week() == w.week && w.year == moment(new Date()).year() ) ;
    if(checkIndex > -1){
        console.log(checkIndex)
        const container = document.getElementById('container');
        container.scrollTo({
          top: 175 * checkIndex,
          behavior: 'smooth'
        }); */
        const targetElement = document.getElementById('weekToday');
        targetElement.scrollIntoView({
          behavior: 'smooth', // 'auto' หรือ 'smooth'
          block: 'center',     // 'start', 'center', 'end', 'nearest'
          inline: 'nearest'   // 'start', 'center', 'end', 'nearest'
        });
   /*  } */

   
}


const exportToExcel = () => {

    let countprocessList = 5;


    const excelData = [
      // Merged cells for Project Information
      [{ v: `Project Timeline : ${projectDetail.project_name} `, t: "s", s: { font: { sz: 36, bold: true }, alignment: { horizontal: 'center' },border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } }],
    ];
    const wscols = [
      { wpx: 200 },
    ];
    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }, // Merged cells for Project Information

    ];


    let zonea2 = [{ v: `Date`, t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "c9daf8" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } }]

   /*  for (let a2 of projectDetail.processList) {
      zonea2.push({ v: a2.process_name, t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center', wrapText: true, vertical: 'center' }, fill: { fgColor: { rgb: "c9daf8" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } }, id: a2.process_id },)
      wscols.push({ wpx: 90 })
    } */
    zonea2.push(
      { v: 'Plan %', t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "c9daf8" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },
      { v: 'Actual %', t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "c9daf8" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },
      { v: '(Actual - Plan) %', t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "c9daf8" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },
      { v: 'Result', t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "c9daf8" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } }
    
    )
    wscols.push({ wpx: 200 }, { wpx: 200 }, { wpx: 200 }, { wpx: 200 });
    excelData.push(zonea2);

    
    let tempData = allDays;

    console.log('tempData',tempData)
    for(let item of tempData){
        if(!item.percent_plan){
            item.percent = 0;
          }
          if(moment(item.day_full).format('YYYYMMDD') == moment(projectDetail.end_date).format('YYYYMMDD')){
            item.percent_plan = 100;
          }
        
          if(item.percent_plan > 100){
            item.percent_plan = 100;
          }
            let color_percent = '#FF0000';
            let color_percent_text = 'rgba(255, 0, 0, 0.25)';
            if(item.percent >= 0 && item.percent <=25){
                color_percent = '#FF0000';
                color_percent_text = 'rgba(255, 0, 0, 0.25)';
            }else if(item.percent > 25 && item.percent <=50){
                color_percent = '#FF7A00';
                color_percent_text = 'rgba(255, 122, 0, 0.25)';
            }else if(item.percent > 50 && item.percent <=75){
                color_percent = '#FFF414';
                color_percent_text = 'rgba(255, 244, 20, 0.25)';
            }else if(item.percent > 75 && item.percent <=100){
                color_percent = '#1DCC37';
                color_percent_text = 'rgba(31, 255, 27, 0.25)';      
            }
            let isOverToday = false;
            if(parseInt(moment(new Date()).format('YYYYMMDD')) < parseInt(moment(item.day_full).format('YYYYMMDD'))){
                isOverToday = true;
            }
            let color_progres = '32cd32';
            if(item.percent_plan > item.percent && !isOverToday){
             
                color_progres= 'ff0000';
            }

            let result = parseFloat(parseFloat(item.percent) - parseFloat(item.percent_plan)).toFixed(2);

        let dataFormatInRow = [];
        dataFormatInRow.push(
            { v: `${moment(item.day_full).format('DD/MM/YYYY')}`, t: "s", s: { font: { sz: 16, bold: false }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "ffffff" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },
            { v: `${parseFloat(item.percent_plan).toFixed(2)}`, t: "s", s: { font: { sz: 16, bold: false }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "ffffff" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },
            { v: `${!isOverToday ? parseFloat(item.percent).toFixed(2): ''}`, t: "s", s: { font: { sz: 16, bold: false }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "ffffff" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },
            { v: `${!isOverToday ? result : ''}`, t: "s", s: { font: { sz: 16, bold: false,color: { rgb: `${color_progres == 'ff0000' ? 'ff0000' :'000000'}` } }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "ffffff" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },
            { v: `${!isOverToday ? result >=0 ? 'Pass' : 'Fail' : ''}`, t: "s", s: { font: { sz: 16, bold: false ,color: { rgb: `000000` } }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: `${color_progres}` } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } }
          
          );
          excelData.push(dataFormatInRow);
    }







   

    //console.log(excelData);


    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();

    ws['!cols'] = wscols;


    // Specify merged cells



    merges.forEach(merge => {
      ws['!merges'] = ws['!merges'] || [];
      ws['!merges'].push(merge);
    });

    XLSX.utils.book_append_sheet(wb, ws, 'Project Timeline');

    XLSX.writeFile(wb, `Project Timeline (${projectDetail.project_name}) ${moment(new Date()).format("DD-MM-YYYY")}.xlsx`);




  };


  function setEmplyAllDay(callback){
    setAllDays([]);
    
    settest({...test,test:''});
    test.test = '';
    if(callback){
        callback(true);
    }
  }



;

const dataChart = {
    labels: weekCalendar.map((e)=>{return  [`Week ${e.week}`,e.year]}),
    datasets: [
      {
        label: 'Plan',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(0,157,255,1)',
        borderColor: 'rgba(0,157,255,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(0,157,255,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(0,157,255,1)',
        pointHoverBorderColor: 'rgba(0,157,255,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data:  weekCalendar.map((e)=>{
            let checkNow = moment(new Date()).week() == e.week && e.year == moment(new Date()).year();    
            //console.log(moment(new Date()).week() , e.week , e.year , moment(new Date()).year())
              let per = e.day_list.map((e2,index2)=>{
                /*    console.log(parseInt(moment(e2.day_full).format('YYYYMMDD'))) */
                
           /*     if(parseInt(moment(e2.day_full).format('YYYYMMDD')) <= parseInt(moment(new Date()).format('YYYYMMDD'))){ */
                 if(parseInt(moment(e2.day_full).format('YYYYMMDD')) > parseInt(moment(projectDetail.end_date).format('YYYYMMDD'))){
                    return null;
                 }
                   if((index2+1 == e.day_list.length && !checkNow ) || 
                   (checkNow && parseInt(moment(e2.day_full).format('YYYYMMDD')) == parseInt(moment(new Date()).format('YYYYMMDD')))
                   || moment(e2.day_full).format('YYYYMMDD') == moment(projectDetail.end_date).format('YYYYMMDD')


                ){
                       //console.log(moment(e2.day_full).format('YYYY/MM/DD'),e2.percent_plan,checkNow)
                       return e2.percent_plan
                   }else{
                    return null;
                   }
                
             /*   } */
            }) 

    
            per = per.filter((p)=>{return p});

            //console.log('per',per)
            return per[0];
        }),
      },
      {
        label: 'Actual',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(29,204,55,1)',
        borderColor: 'rgba(29,204,55,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(29,204,55,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(29,204,55,1)',
        pointHoverBorderColor: 'rgba(29,204,55,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: weekCalendar.map((e)=>{
            let checkNow = moment(new Date()).week() == e.week && e.year == moment(new Date()).year();    
            //console.log(moment(new Date()).week() , e.week , e.year , moment(new Date()).year())
              let per = e.day_list.map((e2,index2)=>{
                /*    console.log(parseInt(moment(e2.day_full).format('YYYYMMDD'))) */
            
               if(parseInt(moment(e2.day_full).format('YYYYMMDD')) <= parseInt(moment(new Date()).format('YYYYMMDD'))){
                 if(parseInt(moment(e2.day_full).format('YYYYMMDD')) > parseInt(moment(projectDetail.end_date).format('YYYYMMDD'))){

                
                    return null;
                 }
                   if((index2+1 == e.day_list.length && !checkNow ) || 
                   (checkNow && parseInt(moment(e2.day_full).format('YYYYMMDD')) == parseInt(moment(new Date()).format('YYYYMMDD')))
                   || moment(e2.day_full).format('YYYYMMDD') == moment(projectDetail.end_date).format('YYYYMMDD')
                ){
                       //console.log(moment(e2.day_full).format('YYYY/MM/DD'),e2.percent,checkNow)
                       return e2.percent
                   }else{
                   
                    return null;
                   }
                
               }
            })     
            per = per.filter((p)=>{return p});
            console.log(projectDetail.create_date)
            console.log(moment(projectDetail.create_date).week() , parseInt(e.week) );

            function fakePosition(value){
                let value_ = value.toString();
                if(value_.length == 1){
                    value_ = '0'+value;
                }else{
                    value_ = value_
                }
                return value_;
            }

            let start_actual = moment(projectDetail.create_date).year() + fakePosition(moment(projectDetail.create_date).week());
            let current = e.year + fakePosition(e.week);
            console.log(start_actual,current)

            if(parseInt(current) < parseInt(start_actual)){
                return per[0] || 0;
            }
        
  
      
            return per[0];
        })
         
          
      },
    ],
  };

  return (
    <>
    <div className='backgoundTimeline'>
    <div className='timelineHeaderContainer' style={{margin:'5px'}}>
        <div className='backBottom' onClick={()=>{
            window.location.href = '/StageMonitoring';
        }}>
            <img src = {iconback_doorStage}/>
        </div>
        
        <div className='towerGradient'></div>
        <div className='textHeaderTimeline'>PROJECT TIMELINE - {projectDetail.project_name}</div>
    </div>
        <div className= '' style={{padding:'5px'}}>
        <div className='filterContainner' >
    <ConfigProvider
          theme={{
            token: {

                colorInfo: '#272727',
                colorBorder: '#272727',
                  colorText: '#FFF',
  
                  colorTextDescription: '#FFFFFF',
                  colorBgContainer: '#272727',
                  zIndexPopupBase: 999999,
                  colorPrimaryBg: '#FFFFFF',
                  colorBgBase: '#272727',//พื้นหลัง datepicker
  
                  colorTextQuaternary: 'White',//สีตัวอักษร
  
                  colorFillTertiary: '#272727',//สีตอนเมาสชี้
  
                  colorTextBase: 'White', // สี scroll bar
  
                  colorPrimaryBg: '#272727',// สีตอนเลือก
                /*       
                       colorBgLayout:'red',
                       colorFillQuaternary:'red', */
  
  
              }
          }}
        >
            <div style={{display:'flex',gap:'15px',alignItems:'center',color:"#fff",fontSize:'20px'}}>
            Planning & Adjust
    <RangePicker id='dateRange' style={{ width: '250px', height: '45px' }} /* className='inputTimeSheet2' */ allowClear={false}
                format={'DD/MM/YYYY'}
                value={[projectDetail.start_date_ ? dayjs(projectDetail.start_date_) : '', projectDetail.end_date_ ? dayjs(projectDetail.end_date_) : '']}
                onChange={async(date) => {
                  if (date && date.length == 2) {
                  /*   setIsLoad(true); */
                    settest({...test,test:''});
                    test.test = '';
                   
                    setProjectDetail({...projectDetail,
                       
                        start_date_:new Date(date[0]),
                        end_date_:new Date(date[1]),
        
                    });
                 //  await calDay(new Date(date[0]),new Date(date[1]));
                  
               /*   meSheetData({ ...timeSheetData, date_start: new Date(date[0]), date_end: new Date(date[1]) }) */
          
               settest({...test,test:''});
               test.test = '';
                  } else {
                    //console.log('date', date)
                   // setTimeSheetData({ ...timeSheetData, date_start: null, date_end: null })
                  }
                }}
              />

              <div className='BottonExport' onClick={async ()=>{
                
        setEmplyAllDay((result) => {
            console.log('result', result);
            if (result == true) {
                calDay(projectDetail.start_date_,projectDetail.end_date_);
            }
        });
            
              
              }} 
              style={{borderRadius:'5px'}}
              >Search</div>

                    <div className='BottonExport'  onClick={()=>{
                calToDay();
              }} 
              style={{borderRadius:'5px',background:'#272727'}}
              >To Day</div>
              </div>
              </ConfigProvider>



                <div style={{display:'flex',gap:'15px'}}>
              <div className='BottonExport' onClick={()=>{
                toggle_open_chart();
              }}  style={{borderRadius:'5px'}}>Chart</div>
    
    <div className='BottonExport' onClick={()=>{
                exportToExcel();
              }}  style={{borderRadius:'5px'}}>Export</div>
    </div>
    </div>
        </div>
 
    <div className='calendarContainer2' style={{padding:'5px'}}>     
     <div className='headerBoxCalendar'>Week</div> 
{headerCalendar.map((e)=>{
    return (<>
    <div className='headerBoxCalendar' /* style={{minWidth:'200px'}} */>{e}</div>
    </>)
})}
 </div> 
     <div className='calendarContainer' id="container">          

{isLoad == true ? (<div style= {{display:'flex',fontSize:'52px',justifyContent:'center',alignItems:'center',height:"500px"}}>


<LoadingOutlined /></div>):(<>
    {weekCalendar.map((w,i)=>{
    let checkNow = moment(new Date()).week() == w.week && w.year == moment(new Date()).year(); 
    return (<>
        <div className='calendarContainer2' id = {checkNow ? "weekToday" : ''} style={{background:checkNow && '#1A394D',padding:'5px',borderRadius:'10px' }}>  
   <div className='weekBoxCalendar' style={{border: checkNow ? '2px solid #D4D4D4' :  '2px solid #33333'}}><div>Week {w.week}</div> <div>{w.year}</div></div>
   {w.day_list.map((d,i2)=>{
   /*  d.percent = Math.floor(Math.random() * 100) + 1; */
  /*   d.percent_plan = Math.floor(Math.random() * 100) + 1; */
  if(!d.percent_plan){
    d.percent = 0;
  }
  if(moment(d.day_full).format('YYYYMMDD') == moment(projectDetail.end_date).format('YYYYMMDD')){
    d.percent_plan = 100;
  }

  if(d.percent_plan > 100){
    d.percent_plan = 100;
  }
    let color_percent = '#FF0000';
    let color_percent_text = 'rgba(255, 0, 0, 0.25)';
    if(d.percent >= 0 && d.percent <=25){
        color_percent = '#FF0000';
        color_percent_text = 'rgba(255, 0, 0, 0.25)';
    }else if(d.percent > 25 && d.percent <=50){
        color_percent = '#FF7A00';
        color_percent_text = 'rgba(255, 122, 0, 0.25)';
    }else if(d.percent > 50 && d.percent <=75){
        color_percent = '#FFF414';
        color_percent_text = 'rgba(255, 244, 20, 0.25)';
    }else if(d.percent > 75 && d.percent <=100){
        color_percent = '#1DCC37';
        color_percent_text = 'rgba(31, 255, 27, 0.25)';      
    }
    let isOverToday = false;
    if(parseInt(moment(new Date()).format('YYYYMMDD')) < parseInt(moment(d.day_full).format('YYYYMMDD'))){
        //console.log(parseInt(moment(new Date()).format('YYYYMMDD')) , '<',parseInt(moment(d.day_full).format('YYYYMMDD')))
        isOverToday = true;
    }
    let color_progres = '';
    if(d.percent_plan > d.percent && !isOverToday){
     
        color_progres= 'red';
    }


    return (<>
    <div className='dayBoxCalendar' style= {{opacity:!d.percent_plan ? 0.5 : 1,border: color_progres !='' ? `2px solid ${color_progres}` : '2px solid #272727',background:d.day == 1 && '#363636'}}>
    
    
     <div className='textMonth' style={{marginLeft:'70px',height:'45px',visibility:d.day != 1 ? 'hidden' : '',color:'#009DFF'}}>{moment(d.day_full).format('MMM')}</div>
        <div className='dayBoxCalendarIn' >
           
          
       
{moment(d.day_full).format('YYYYMMDD') == moment(new Date()).format('YYYYMMDD') ? (<>
    <div className='textDay' style={{marginTop:'-39px',borderRadius:'100px',paddingLeft:'10px',paddingRight:'10px',lineHeight:'30px',background:'#009DFF'}}/* style={{marginTop:`${d.day == 1 ? -25 : 0}px`}} */>
             {d.day}  
            </div>
</>):(<>
    <div className='textDay' style={{marginTop:'-39px',}}/* style={{marginTop:`${d.day == 1 ? -25 : 0}px`}} */>
             {d.day}  
            </div>
</>)

}
           
     
        </div>
        {!isOverToday ? (<>
            <div className='dayBoxCalendarIn2' style= {{visibility:!d.percent_plan ? 'hidden' : '',marginTop:'-15px'}}/* style={{marginTop:'-30px'}} */>
            <div style={{display:'flex',flexDirection:'column',gap:'5px',alignItems:'center'}}>
                <div className='boxTextPercent' style={{background:color_percent_text}}>{d.percent_plan ? d.percent : 0}%</div>
                <div className='boxPercent' style={{overflow:'hidden'}}>
                <div style={{background:color_percent,width:'100%',height:`${d.percent_plan  ? d.percent : 0}%`}}></div>
                </div>
          
            </div>
            <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-end',gap:'5px'}}>
                    <div style={{fontSize:'15px',display:'flex',gap:'5px'}}>Actual :<div style={{color:'#1DCC37'}}>{d.percent == 100 ? 100 : d.percent} %</div></div>
                    <div style={{fontSize:'15px',display:'flex',gap:'5px'}}>Plan :<div style={{color:'#009DFF'}}>{d.percent_plan ? truncateToTwoDecimals(d.percent_plan)+' %' : '-'} </div></div>
                </div>
        </div>
        </>):(<>
            <div style={{height:'80px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                Plan
                <div style={{color:'#009DFF',fontSize:'44px'}}>{d.percent_plan ? truncateToTwoDecimals(d.percent_plan)+'%' : '-'} </div>
            </div>
        </>)}
    


   </div>

    </>)
})}

   </div> 
    </>);
 })}
</>)}


   </div>



{openChart && (<> 
    <div className='modalTimeStampChartBackdrop'>
   
   <div className='modalTimeStampChart'>
    <div className='TimeStampChartHeader'><div>Project Timeline Chart : {projectDetail.project_name}</div> <div style={{fontSize:'35px',fontWeight:'bold',cursor:'pointer',marginTop:'-50px',marginRight:'-10px'}} onClick={()=>{
        toggle_open_chart();
    }}>x</div></div>
    <div className='TimeStampChartBody'>
    <BarChart  shadow data={dataChart}/>
    </div>
   {/*  <div className='TimeStampChartFooter'>
    <div className='BottonCancel'  onClick={()=>{
               toggle_open_chart();
              }}  >Cancel</div>
   
    </div> */}
    
   </div>
</div>
</>)}
 

    </div>


  

   </>
  )
}

export default ProjecTimeline
