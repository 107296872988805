import React, { useEffect, useState } from 'react';
import { DatePicker, Tabs, Tooltip, Input, Table } from 'antd';
import { CalendarOutlined, SearchOutlined, EyeOutlined, EditOutlined, DownloadOutlined, DeleteOutlined, PlusCircleOutlined, UserOutlined, PlusOutlined, CloseOutlined, UserAddOutlined } from '@ant-design/icons';
import axios from "axios";
import Configs from "../../../../config";
import dayjs, { isDayjs } from 'dayjs';
import styled from 'styled-components';
import './tabMOM.css'
import { findIndex, isArray, isFunction, isPlainObject, iteratee, times } from 'lodash';
import AlertConfrimDelete from '../../../../components/AlertMassge/AlertConfrimDelete';
import momreport from '../../Report/momreport'
import Swal from 'sweetalert2';
import ModelAlertConfrim from "../../../../components/AlertMassge/AlertConfrim";
import ModelAlertMassge from "../../../../components/AlertMassge/AlertMassge";
import moment from 'moment';
import momTabUserImg from './momTabUserImg.jpg';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

function TabMomAttendance({ isOpen, toggle, setMomTabAddUserMode, momTabAddUserMode, setMomTabAddUserModeOld, momTabAddUserModeOld, momTabUserSelect, setMomTabUserSelect, test, setTest, mom_tab, mom_tab_edit, momTabCompanyTabSelectId, project_id, momTabMode }) {
    // const [test, setTest] = useState({
    //     test: ''
    // });

    const [momTabUserList, setMomTabUserList] = useState([]);

    const [momTabUserSearch, setMomTabUserSearch] = useState('');

    const [momTabUserAddData, setMomTabUserAddData] = useState({});

    const [alertKickMassge, setAlertKickMassge] = useState(false);
    const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);

    const [messageError, setMessageError] = useState({
        message: 'Username is duplicated in the system.',
        isError: true,
        menuTitle: ''
    })

    async function getMomTabUserListByProjectId() {
        axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/getMomTabUserListByProjectId/${project_id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                setMomTabUserList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function checkAddMomTabUserList() {
        let temp = {
            project_id: project_id,
            mp_company: momTabUserAddData.company?.trim() || null,
            mp_name: momTabUserAddData.name?.trim() || null,
            mp_position: momTabUserAddData.role?.trim() || null,
            mp_email: momTabUserAddData.email?.trim() || null,
            mp_tel: momTabUserAddData.phone?.trim() || null
        }
        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/checkAddMomTabUserList`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(function (response) {
                if (response.data.success) {
                    momTabUserSelect.push(response.data.data[0]);
                    getMomTabUserListByProjectId();
                    setMomTabAddUserMode(momTabAddUserModeOld);
                    setTest({ ...test, test: '' });
                    test.test = '';
                } else {
                    setMessageError({
                        message: 'Username is duplicated in the system.',
                        isError: true,
                        menuTitle: ''
                    })
                    toggle_modal_alertKickMassge();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function checkEditMomTabUserList() {
        let temp = {
            mp_id: momTabUserAddData.mp_id,
            mp_company: momTabUserAddData.company?.trim() || null,
            mp_name: momTabUserAddData.name?.trim() || null,
            mp_position: momTabUserAddData.role?.trim() || null,
            mp_email: momTabUserAddData.email?.trim() || null,
            mp_tel: momTabUserAddData.phone?.trim() || null
        }
        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/checkEditMomTabUserList`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(function (response) {
                if (response.data.success) {
                    // let findIndex = momTabUserSelect.findIndex((find) => find.mp_id == momTabUserAddData.mp_id);
                    // if (findIndex > -1) {
                    //     momTabUserSelect.splice(findIndex, 1);
                    //     momTabUserSelect.push(response.data.data[0] && response.data.data[0]);
                    // };
                    getMomTabUserListByProjectId();
                    setMomTabAddUserMode(momTabAddUserModeOld);
                    if (momTabMode == 'add') {
                        let findIndex = momTabUserSelect.findIndex((filter) => filter.mp_id == momTabUserAddData.mp_id );
                        if (findIndex > -1) {
                            momTabUserSelect[findIndex].mp_company = momTabUserAddData.company?.trim() || null;
                            momTabUserSelect[findIndex].mp_name = momTabUserAddData.name?.trim() || null;
                            momTabUserSelect[findIndex].mp_position = momTabUserAddData.role?.trim() || null;
                            momTabUserSelect[findIndex].mp_email = momTabUserAddData.email?.trim() || null;
                            momTabUserSelect[findIndex].mp_tel = momTabUserAddData.phone?.trim() || null;
                        }
                    }
                    setTest({ ...test, test: '' });
                    test.test = '';
                } else {
                    toggle_modal_alertKickMassge();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (isOpen) {
            getMomTabUserListByProjectId();
        }
    }, [isOpen]);

    return <>
        {isOpen && <div className='momTabAddUserBg'>
            <div style={{ position: 'absolute', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.8)' }} onClick={() => {
                toggle();
            }}></div>
            <div className='momTabAddUserBox' style={{ zIndex: '1' }}>
                <div className='momTabAddUserHeader'>MANAGE ATTENDANCE</div>

                <div style={{ margin: '10px 0', display: 'flex', gap: '10px' }}>
                    <Input
                        className='momTabAddUserSearch'
                        placeholder='Search'
                        prefix={<UserAddOutlined />}
                        onChange={(e) => {
                            setMomTabUserSearch(e.target.value);
                        }}
                        value={momTabUserSearch}
                        allowClear
                    />

                    <button
                        className='momTabAddUserButton'
                        style={{
                            // border: '2px solid #0349FF',
                            // width: '70px',
                            // height: '34px',
                            border: 'none',
                            minWidth: '20%',
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0',
                            backgroundColor: '#0047FF',
                            borderRadius: '0',
                            cursor: 'pointer'
                        }}
                        onClick={/* addValueMultiple */() => {
                            setMomTabUserAddData({});
                            setMomTabAddUserMode('add');
                            setTest({ ...test, test: '' });
                            test.test = '';
                        }}
                    >
                        <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                            {/* <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 6.5V0.5H8V6.5H14V8.5H8V14.5H6V8.5H0V6.5H6Z" fill="white" />
                </svg> */}
                            <PlusCircleOutlined />
                            <span>
                                ADD
                            </span>
                        </div>
                    </button>
                </div>

                {momTabAddUserMode != 'add' && <div className='momTabAddUserTab'>
                    <div className='momTabAddUserTabIn' style={{ background: `${momTabAddUserMode == 'all' ? 'rgba(0, 0, 0, 0.3)' : ''}` }} onClick={() => {
                        setMomTabAddUserMode('all');
                        setMomTabAddUserModeOld('all');
                        setTest({ ...test, test: '' });
                        test.test = '';
                    }}>ALL</div>
                    <div className='momTabAddUserTabIn' style={{ background: `${momTabAddUserMode == 'select' ? 'rgba(0, 0, 0, 0.3)' : ''}` }} onClick={() => {
                        setMomTabAddUserMode('select');
                        setMomTabAddUserModeOld('select');
                        setTest({ ...test, test: '' });
                        test.test = '';
                    }}>{`SELECT(${momTabUserSelect?.length})`}</div>
                </div>}

                <div style={{ border: '2px solid #0047FF', flexGrow: '1', padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px', overflow: 'auto' }}>
                    {momTabAddUserMode == 'all' && <div style={{ display: 'flex', gap: '10px', width: '100%', cursor: 'pointer', flexDirection: 'column' }}>
                        {momTabUserList?.filter((fil) => {
                            return momTabUserSearch != '' ? fil.mp_name.toString().toLocaleLowerCase().includes(momTabUserSearch.toString().toLocaleLowerCase()) || fil.mp_company.toString().toLocaleLowerCase().includes(momTabUserSearch.toString().toLocaleLowerCase()) : fil
                        }).map((e, i) => {
                            let check = momTabUserSelect.filter((fil) => { return fil.mp_id == e.mp_id });
                            if (check.length > 0) {
                                e.have = true;
                            } else {
                                e.have = false;
                            }
                            return <>
                                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '10px', top: '0', marginBottom: '-45px', gap: '5px' }}>
                                    <EditOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                        setMomTabUserAddData({
                                            mp_id: e.mp_id,
                                            company: e.mp_company,
                                            name: e.mp_name,
                                            role: e.mp_position,
                                            email: e.mp_email,
                                            phone: e.mp_tel,
                                            mode: 'all'
                                        });
                                        setMomTabAddUserMode('edit');
                                        setTest({ ...test, test: '' });
                                        test.test = '';
                                    }} />
                                </div>

                                <div style={{ border: `${e.have ? '1px solid #0047FF' : 'none'}`, padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px', borderRadius: '10px', background: `${e.have ? 'rgba(0, 71, 255, 0.05)' : 'rgba(0, 0, 0, 0.2)'}` }} onClick={() => {
                                    let findIndex = momTabUserSelect.findIndex((find) => find.mp_id == e.mp_id);
                                    let filterIndexCompany = momTabUserSelect.filter((filter) => { return filter.mp_company == e.mp_company });

                                    if (filterIndexCompany.length > 0) {
                                        e.mmp_sort_company = filterIndexCompany[0]?.mmp_sort_company;
                                    } else {
                                        const filteredCompanyValues = momTabUserSelect.map(item => item.mmp_sort_company).filter(value => value !== null);
                                        const maxCompanyValue = filteredCompanyValues.length > 0 ? Math.max(...filteredCompanyValues) + 1 : null;
                                        e.mmp_sort_company = maxCompanyValue || 0;
                                    }

                                    if (findIndex > -1) {
                                        let checkLastInCompany = momTabUserSelect.filter((fil) => { return fil.mp_company == momTabUserSelect[findIndex]?.mp_company });
                                        if (checkLastInCompany?.length == 1) {
                                            for (let item of momTabUserSelect) {
                                                if (item.mmp_sort_company > checkLastInCompany[0].mmp_sort_company) {
                                                    item.mmp_sort_company = item.mmp_sort_company - 1;
                                                }
                                            }
                                        }

                                        let checkInPersonal = momTabUserSelect.filter((filter) => { return filter.mp_id == e.mp_id });
                                        if (checkInPersonal.length > 0) {
                                            for (let item of momTabUserSelect) {
                                                if (item.mmp_sort_personal > checkInPersonal[0].mmp_sort_personal) {
                                                    item.mmp_sort_personal = item.mmp_sort_personal - 1;
                                                }
                                            }
                                        }

                                        momTabUserSelect.splice(findIndex, 1);
                                    } else {
                                        e.mmp_sort_personal = momTabUserSelect.length || 0;

                                        momTabUserSelect.push(e);
                                    };

                                    setTest({ ...test, test: '' });
                                    test.test = '';
                                }}>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <div style={{ minWidth: '24%', height: '100%', background: 'rgba(76, 76, 76, 0.1)', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
                                            <div style={{ width: '45px', height: '45px', border: '1px solid #0047FF', borderRadius: '50%', backgroundImage: `url(${momTabUserImg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                                            <div style={{ fontSize: '12px' }}>{e.mom_code || '-'}</div>
                                        </div>
                                        <div>
                                            <div style={{ color: '#0094FF' }}>{e.mp_name || '-'}</div>
                                            <div>{e.mp_company || '-'}</div>
                                            <div>{e.mp_position || '-'}</div>
                                            <div>{e.mp_email || '-'}</div>
                                            <div>{e.mp_tel || '-'}</div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        })}
                    </div>}

                    {momTabAddUserMode == 'select' && <div style={{ display: 'flex', gap: '10px', width: '100%', position: 'relative', flexDirection: 'column' }}>
                        {momTabUserSelect?.filter((fil) => {
                            return momTabUserSearch != '' ? fil.mp_name.toString().toLocaleLowerCase().includes(momTabUserSearch.toString().toLocaleLowerCase()) : fil
                        }).map((e, i) => {
                            return <>
                                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '10px', top: '0', marginBottom: '-45px', gap: '5px' }}>
                                    <EditOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                        setMomTabUserAddData({
                                            mp_id: e.mp_id,
                                            company: e.mp_company,
                                            name: e.mp_name,
                                            role: e.mp_position,
                                            email: e.mp_email,
                                            phone: e.mp_tel
                                        });
                                        setMomTabAddUserMode('edit');
                                        setTest({ ...test, test: '' });
                                        test.test = '';
                                    }} />
                                    <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                        let findIndex = momTabUserSelect.findIndex((find) => find.mp_id == e.mp_id);
                                        let filterIndexCompany = momTabUserSelect.filter((filter) => { return filter.mp_company == e.mp_company });

                                        if (filterIndexCompany.length > 0) {
                                            e.mmp_sort_company = filterIndexCompany[0]?.mmp_sort_company;
                                        } else {
                                            const filteredValues = momTabUserSelect.map(item => item.mmp_sort_company).filter(value => value !== null);
                                            const maxValue = filteredValues.length > 0 ? Math.max(...filteredValues) + 1 : null;
                                            e.mmp_sort_company = maxValue || 0;
                                        }

                                        if (findIndex > -1) {
                                            let checkLastInCompany = momTabUserSelect.filter((fil) => { return fil.mp_company == momTabUserSelect[findIndex]?.mp_company });
                                            if (checkLastInCompany?.length == 1) {
                                                for (let item of momTabUserSelect) {
                                                    if (item.mmp_sort_company > checkLastInCompany[0].mmp_sort_company) {
                                                        item.mmp_sort_company = item.mmp_sort_company - 1;
                                                    }
                                                }
                                            }
    
                                            let checkInPersonal = momTabUserSelect.filter((filter) => { return filter.mp_id == e.mp_id });
                                            if (checkInPersonal.length > 0) {
                                                for (let item of momTabUserSelect) {
                                                    if (item.mmp_sort_personal > checkInPersonal[0].mmp_sort_personal) {
                                                        item.mmp_sort_personal = item.mmp_sort_personal - 1;
                                                    }
                                                }
                                            }
    
                                            momTabUserSelect.splice(findIndex, 1);
                                        } else {
                                            e.mmp_sort_personal = momTabUserSelect.length || 0;
    
                                            momTabUserSelect.push(e);
                                        };
                                        setTest({ ...test, test: '' });
                                        test.test = '';
                                        
                                    }} />
                                </div>
                                <div style={{ border: '1px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px', borderRadius: '10px', background: 'rgba(0, 71, 255, 0.05)' }}>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <div style={{ minWidth: '24%', height: '100%', background: 'rgba(76, 76, 76, 0.1)', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
                                            <div style={{ width: '45px', height: '45px', border: '1px solid #0047FF', borderRadius: '50%', backgroundImage: `url(${momTabUserImg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                                            <div style={{ fontSize: '12px' }}>{e.mom_code || '-'}</div>
                                        </div>
                                        <div>
                                            <div style={{ color: '#0094FF' }}>{e.mp_name || '-'}</div>
                                            <div>{e.mp_company || '-'}</div>
                                            <div>{e.mp_position || '-'}</div>
                                            <div>{e.mp_email || '-'}</div>
                                            <div>{e.mp_tel || '-'}</div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        })}
                    </div>}

                    {momTabAddUserMode == 'add' && <>
                        <div style={{ border: '1px solid #0047FF', padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div><span style={{ color: 'red', display: `${momTabUserAddData.company?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>Company :</div>
                            <TextArea
                                className='textAreaMomTabAddUser'
                                autoSize={{
                                    minRows: 1
                                }}
                                type='text'
                                value={momTabUserAddData.company || null}
                                onChange={(e) => {
                                    setMomTabUserAddData({ ...momTabUserAddData, company: e.target.value });
                                }}
                            />

                            <div><span style={{ color: 'red', display: `${momTabUserAddData.name?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ชื่อ-นามสกุล :</div>
                            <TextArea
                                className='textAreaMomTabAddUser'
                                autoSize={{
                                    minRows: 1
                                }}
                                type='text'
                                value={momTabUserAddData.name || null}
                                onChange={(e) => {
                                    setMomTabUserAddData({ ...momTabUserAddData, name: e.target.value });
                                }}
                            />

                            <div><span style={{ color: 'red', display: `${momTabUserAddData.role?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ตำแหน่ง :</div>
                            <TextArea
                                className='textAreaMomTabAddUser'
                                autoSize={{
                                    minRows: 1
                                }}
                                type='text'
                                value={momTabUserAddData.role || null}
                                onChange={(e) => {
                                    setMomTabUserAddData({ ...momTabUserAddData, role: e.target.value });
                                }}
                            />

                            <div><span style={{ color: 'red', display: `${momTabUserAddData.email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserAddData.email?.trim()) || momTabUserAddData.email == null ? 'none' : ''}` }}>*</span>Email :</div>
                            <TextArea
                                className='textAreaMomTabAddUser'
                                autoSize={{
                                    minRows: 1
                                }}
                                type='text'
                                value={momTabUserAddData.email || null}
                                onChange={(e) => {
                                    setMomTabUserAddData({ ...momTabUserAddData, email: e.target.value });
                                }}
                            />

                            <div><span style={{ color: 'red', display: `${momTabUserAddData.phone?.trim()?.length > 9 || momTabUserAddData.phone?.trim()?.length < 1 || momTabUserAddData.phone == null ? 'none' : ''}` }}>*</span>เบอร์โทร :</div>
                            <TextArea
                                className='textAreaMomTabAddUser'
                                autoSize={{
                                    minRows: 1
                                }}
                                type='text'
                                value={momTabUserAddData.phone || null}
                                onChange={(e) => {
                                    let value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters

                                    if (value.length > 3) {
                                        value = `${value.slice(0, 3)}-${value.slice(3)}`;
                                    }

                                    // if (value.length > 11) {
                                    //     value = value.slice(0, 12); // Ensure no more than 10 digits are entered
                                    // }

                                    setMomTabUserAddData({ ...momTabUserAddData, phone: value });
                                }}
                            />
                        </div>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                if (momTabUserAddData.company?.trim()?.length > 0 &&
                                    momTabUserAddData.name?.trim()?.length > 0 &&
                                    momTabUserAddData.role?.trim()?.length > 0 &&
                                    (momTabUserAddData.email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserAddData.email?.trim()) || momTabUserAddData.email == null) &&
                                    (momTabUserAddData.phone?.trim()?.length > 9 || momTabUserAddData.phone?.trim()?.length < 1 || momTabUserAddData.phone == null)) {
                                    checkAddMomTabUserList();
                                } else {
                                    setMessageError({
                                        message: 'Please fill in all information completely.',
                                        isError: true,
                                        menuTitle: ''
                                    })
                                    toggle_modal_alertKickMassge();
                                }
                            }}>SAVE</div>
                            <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                setMomTabAddUserMode(momTabAddUserModeOld);
                                setTest({ ...test, test: '' });
                                test.test = '';
                            }}>CANCEL</div>
                        </div>
                    </>}

                    {momTabAddUserMode == 'edit' && <>
                        <div style={{ border: '1px solid #0047FF', padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div><span style={{ color: 'red', display: `${momTabUserAddData.company?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>Company :</div>
                            <TextArea
                                className='textAreaMomTabAddUser'
                                autoSize={{
                                    minRows: 1
                                }}
                                type='text'
                                value={momTabUserAddData.company || null}
                                onChange={(e) => {
                                    setMomTabUserAddData({ ...momTabUserAddData, company: e.target.value });
                                }}
                            />

                            <div><span style={{ color: 'red', display: `${momTabUserAddData.name?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ชื่อ-นามสกุล :</div>
                            <TextArea
                                className='textAreaMomTabAddUser'
                                autoSize={{
                                    minRows: 1
                                }}
                                type='text'
                                value={momTabUserAddData.name || null}
                                onChange={(e) => {
                                    setMomTabUserAddData({ ...momTabUserAddData, name: e.target.value });
                                }}
                            />

                            <div><span style={{ color: 'red', display: `${momTabUserAddData.role?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ตำแหน่ง :</div>
                            <TextArea
                                className='textAreaMomTabAddUser'
                                autoSize={{
                                    minRows: 1
                                }}
                                type='text'
                                value={momTabUserAddData.role || null}
                                onChange={(e) => {
                                    setMomTabUserAddData({ ...momTabUserAddData, role: e.target.value });
                                }}
                            />

                            <div><span style={{ color: 'red', display: `${momTabUserAddData.email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserAddData.email?.trim()) || momTabUserAddData.email == null ? 'none' : ''}` }}>*</span>Email :</div>
                            <TextArea
                                className='textAreaMomTabAddUser'
                                autoSize={{
                                    minRows: 1
                                }}
                                type='text'
                                value={momTabUserAddData.email || null}
                                onChange={(e) => {
                                    setMomTabUserAddData({ ...momTabUserAddData, email: e.target.value });
                                }}
                            />

                            <div><span style={{ color: 'red', display: `${momTabUserAddData.phone?.trim()?.length > 9 || momTabUserAddData.phone?.trim()?.length < 1 || momTabUserAddData.phone == null ? 'none' : ''}` }}>*</span>เบอร์โทร :</div>
                            <TextArea
                                className='textAreaMomTabAddUser'
                                autoSize={{
                                    minRows: 1
                                }}
                                type='text'
                                value={momTabUserAddData.phone || null}
                                onChange={(e) => {
                                    let value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters

                                    if (value.length > 3) {
                                        value = `${value.slice(0, 3)}-${value.slice(3)}`;
                                    }

                                    // if (value.length > 11) {
                                    //     value = value.slice(0, 12); // Ensure no more than 10 digits are entered
                                    // }

                                    setMomTabUserAddData({ ...momTabUserAddData, phone: value });
                                }}
                            />
                        </div>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                if (momTabUserAddData.company?.trim()?.length > 0 &&
                                    momTabUserAddData.name?.trim()?.length > 0 &&
                                    momTabUserAddData.role?.trim()?.length > 0 &&
                                    (momTabUserAddData.email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserAddData.email?.trim()) || momTabUserAddData.email == null) &&
                                    (momTabUserAddData.phone?.trim()?.length > 9 || momTabUserAddData.phone?.trim()?.length < 1 || momTabUserAddData.phone == null)) {
                                    checkEditMomTabUserList();
                                } else {
                                    setMessageError({
                                        message: 'Please fill in all information completely.',
                                        isError: true,
                                        menuTitle: ''
                                    })
                                    toggle_modal_alertKickMassge();
                                }
                            }}>SAVE</div>
                            <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                setMomTabAddUserMode(momTabAddUserModeOld);
                                setTest({ ...test, test: '' });
                                test.test = '';
                            }}>CANCEL</div>
                        </div>
                    </>}

                </div>

                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <div className='momTabAddUserCloseButton' onClick={() => {
                        toggle();
                    }}>CLOSE</div>
                </div>
            </div>
        </div>}

        <ModelAlertMassge
            isOpen={alertKickMassge}
            toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
            message={messageError}
        />
    </>
}

export default TabMomAttendance;

