import React, { useEffect, useState } from 'react'
import './style.css';
import axios from "axios";
import Configs from "../../config";
import { Select, DatePicker, Input, ConfigProvider, Tooltip, Switch } from 'antd';
import moment from 'moment';
import timesheetIcon from "./Image/timesheetIcon.png";
import timeicon from "./Image/timeicon.png";
import bumicon from "./Image/bum.png";
import dayjs from 'dayjs';
import iconback_doorStage from "../../assets/img/icon/IconBack_stage.png"
import timesheertreport from '../component/Report/timesheertreport';
import AlertConfrim from "../../components/AlertMassge/AlertConfrim";
import AlertMassge from "../../components/AlertMassge/AlertMassge";
import {
    CalendarOutlined,
    ClockCircleOutlined,
    DownloadOutlined,
    LoadingOutlined
} from '@ant-design/icons';
moment.locale('en');
const { TextArea } = Input;
const { RangePicker } = DatePicker;
function Timesheettimeline() {
    const [genHistory, setGenHistory] = useState({
        start_date: dayjs(),
        end_date: dayjs()
    });
    const [typemodeselect,setTypemodeselect] = useState(false);
    const [timesheetmodeselect, settimesheetmodeselect] = useState({
        mode:'day'
    });
    const [headerCalendar, setHeaderCalendar] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
    const [historyTimeSheetList, setHistoryTimeSheetList] = useState([]);
    const [isOpenHistoryReport, setIsOpenHistoryReport] = useState(false);
    const toggle_history_report = () => { setIsOpenHistoryReport(!isOpenHistoryReport) };
    let [loadinddisplay, setloadinddisplay] = useState(false);
    const [err_message, setErrorMessage] = useState('');
    const [typeProject, setTypeProject] = useState(false);
    const [test, setTest] = useState({
        test: ''
    });
    const [alertMsgEditMsg, setAlertMsgEditMsg] = useState({
        menuTitle: 'ERROR',
        isError: true,
        message: 'Please fill out the information completely.'
    });
    const [alertMsgEdit, setAlertMsgEdit] = useState(false);
    const toggle_alertMsgEdit = () => { setAlertMsgEdit(!alertMsgEdit) }
    const [alertSaveConfirm, setAlertSaveConfirm] = useState(false);
    const toggle_alertSaveConfirm = () => { setAlertSaveConfirm(!alertSaveConfirm) };
    const [timesheetProjectList, setTimesheetProjectList] = useState([]);
    const [timesheetProcessList, setTimesheetProcessList] = useState([]);
    const [timesheetModuleList, setTimesheetModuleList] = useState([]);
    const [timesheetMenuList, setTimesheetMenuList] = useState([]);
    const [alertSavePopup, setAlertSavePopup] = useState(false);
    const toggle_alertSavePopup = () => { setAlertSavePopup(!alertSavePopup) };
    const [timesheetData, setTimesheetData] = useState([]);
    const [timeIsNull, setTimeIsNull] = useState(false);
    const toggle_timeIsNull = () => { setTimeIsNull(!timeIsNull) };
    const [descIsNull, setDescIsNull] = useState(false);
    const toggle_descIsNull = () => { setDescIsNull(!descIsNull) };
    const [filterDataType, setFilterDateType] = useState('today');
    const [timeSheetData, setTimeSheetData] = useState({
        project_id: '',
        project_name: '',
        ts_process_id: '',
        process_name: '',
        module_id: '',
        module_name: '',
        menu_id: '',
        menu_name: '',
        date_start: new Date(),
        date_end: new Date(),
        time_start: '',
        time_end: '',
        ts_project_detail: '',
        count_hour: 0,
        count_minute: 0,
        datestartselect:null,
        dateendselect:null,
    })
    const [oldTimesheetData, setOldTimesheetData] = useState([]);

    const [isLoad, setIsLoad] = useState(false);
    const [timesheetUserList, setTimesheetUserList] = useState([]);
    const [filtertimesheet, setfiltertimesheet] = useState({
        user: sessionStorage.getItem('user_id'),
        start: moment(new Date()).startOf('month').format(''),
        end: moment(new Date()).endOf('month').format(''),
        username: ` ${sessionStorage.getItem('user')}`
    });
    const [todayUse, setTodayUse] = useState({
        day: 0,
        hours: 0,
        minute: 0,
        avg: 0
    });
    useEffect(() => {

        checkdropdown()
        gettimesheetbyuser()
        getTimesheetProjectById()
        getTimesheetModuleById()
        getTimesheetMenuById()
        getTimesheetProcess()
    }, []);




    const [weekCalendar, setWeekCalendar] = useState({
        data: []
    });



    async function getTimesheetProjectById() {

        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getTimesheetProjectById/${filtertimesheet.user}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
        })
            .then(async function (response) {
                let temp = [];
                for (let item of response.data) {
                    temp.push({
                        label: item.project_name,
                        value: item.project_id,
                    });
                };
                setTimesheetProjectList(temp);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    async function getTimesheetProcess() {
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getTimesheetProcess`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
        })
            .then(async function (response) {
                let temp = [];
                for (let item of response.data) {
                    temp.push({
                        label: item.process_name,
                        value: item.process_id,
                    });
                };
                setTimesheetProcessList(temp);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    async function getTimesheetModuleById() {
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getTimesheetModuleById/${filtertimesheet.user}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }
        })
            .then(async function (response) {
                let temp = [];
                for (let item of response.data) {
                    temp.push({
                        label: item.module_name,
                        value: item.module_id,
                        project_id: item.project_id,
                        project_name: item.project_name,
                    });
                };
                setTimesheetModuleList(temp);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    async function getTimesheetMenuById() {
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getTimesheetMenuById/${filtertimesheet.user}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }
        })
            .then(async function (response) {
                let temp = [];
                for (let item of response.data) {
                    temp.push({
                        label: item.menu_name,
                        value: item.menu_id,
                        module_id: item.module_id,
                        module_name: item.module_name,
                        project_id: item.project_id,
                        project_name: item.project_name,
                    });
                };
                setTimesheetMenuList(temp);
            })
            .catch(function (error) {
                console.log(error);
            });
    };






    function checkdropdown() {

        axios({
            method: "get",
            url: `${Configs.TTT_Game_Service}/api/timeSheet/checkdropdown/${sessionStorage.getItem('user_id')}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },

        })
            .then(async function (response) {
                console.log(response.data);
                setTimesheetUserList(response.data)
                /* setTodayUse({ ...todayUse, hours: response.data.hours, minute: response.data.minute }) */
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async function gettimesheetbyuser() {

        setIsLoad(true);
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/gettimesheetbyuser`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: filtertimesheet
        })
            .then(async function (response) {
                let datasumdate = {
                    d: 0,
                    h: 0,
                    m: 0
                }
                for (let item of response.data) {

                   let sumin =  calculateTime(item.ts_hours, item.ts_time, datasumdate)
                   datasumdate = sumin
                    if (item.ts_project_id) {
                        item.typProject = true;
                    } else {
                        item.typProject = false;
                    }
                }
                
                setingweek([filtertimesheet.start, filtertimesheet.end], response.data)
                const date1 = moment(new Date(filtertimesheet.start)).startOf('days');
                const date2 = moment(new Date(filtertimesheet.end)).endOf('days');

                const differenceInDays = date2.diff(date1, 'days');
               /*  console.log('1s',differenceInDays,moment(date1).format('DD/MM/yyyy'),moment(date2).format('DD/MM/yyyy'));
                console.log(((datasumdate.d * 8) + datasumdate.h) ,(differenceInDays+1),differenceInDays); */
                

                let sum = (((datasumdate.d * 8) + datasumdate.h +(datasumdate.m / 60)) / (differenceInDays+1)) /* +(datasumdate.m/100) */
/* console.log(sum); */
let roundedDown = Math.floor(sum * Math.pow(10, 2)) / Math.pow(10, 2) ;

                setTodayUse({ ...todayUse, day: datasumdate.d, hours: datasumdate.h, minute: datasumdate.m, avg: roundedDown.toFixed(2) })
                todayUse.day = datasumdate.d
                todayUse.hours = datasumdate.h
                todayUse.minute = datasumdate.m
                setTimesheetData(JSON.parse(JSON.stringify(response.data)));
                setOldTimesheetData(JSON.parse(JSON.stringify(response.data)));
                setIsLoad(false);
            })
            .catch(function (error) {
                setIsLoad(false);
                console.log(error);
            });
    }

    function calculateTime(ts_hours, ts_time, sum) {
        /* console.log(sum); */
        
    
        const hours = parseInt(ts_hours , 10) +  sum.h;
        const minutes = parseInt(ts_time, 10) +  sum.m;

   
        const totalMinutes = hours * 60 + minutes; 
        const days = Math.floor(totalMinutes / (8 * 60)); 
        const remainingMinutesAfterDays = totalMinutes % (8 * 60);
        const hoursLeft = Math.floor(remainingMinutesAfterDays / 60); 
        const minutesLeft = remainingMinutesAfterDays % 60; 

        return {
            d: days + sum.d,
            h: hoursLeft,
            m: minutesLeft,
        };
    }

    // ตัวอย่างการใช้ฟังก์ชัน
    const time = {
        ts_hours: 4,
        ts_time: 45,
    };

    /* const calculateMonthDetails = (dates) => {
      const startDate = moment(dates[0]);
      const endDate = moment(dates[1]);
    
      const getMonthDetails = (start, end) => {
        const results = [];
        let currentStart = start.clone().startOf('month');
        let weeksOfMonth = {}; 
    
        const addWeekData = (currentDay) => {
          const weekOfYear = currentDay.week();
          const weekYearKey = `${weekOfYear}`; 
          console.log(weekYearKey);
          
          if (!weeksOfMonth[weekYearKey]) {
            weeksOfMonth[weekYearKey] = [];
          }
    
          weeksOfMonth[weekYearKey].push({
            date: currentDay.format('YYYY-MM-DD'),
            dayOfWeek: currentDay.format('dddd')
          });
        };
    
       
        while (currentStart.isBefore(end)) {
          const currentEnd = currentStart.clone().endOf('month');
          if (currentEnd.isAfter(end)) {
            currentEnd.set('date', end.date());
          }
    
       
          const numberOfDays = currentEnd.date();
    
          for (let day = 1; day <= numberOfDays; day++) {
            const currentDay = currentStart.clone().date(day);
            addWeekData(currentDay);
          }
    
          
          results.push({
            month: weeksOfMonth.keys === '1' ? weeksOfMonth.format('MMMM YYYY') : currentStart.format('MMMM YYYY'),
            numberOfDays: numberOfDays,
            weeksOfMonth: { ...weeksOfMonth }
          });
    
         
          currentStart.add(1, 'month').startOf('month');
    
          weeksOfMonth = {};
        }
    
      
       
        return results;
      };
    
      const findDuplicateWeeks = (data) => {
      
        for (let i = 0; i < data.length; i+=1) {
            const previousMonth =  i > 0 ? data[i - 1] : null;
            const currentMonth = data[i];
            if (previousMonth !== null) {
              const previousMonthWeekKeys = Object.keys(previousMonth.weeksOfMonth);
            const currentMonthWeekKeys = Object.keys(currentMonth.weeksOfMonth);
        
    
            for (const weekKey of previousMonthWeekKeys) {
       
                if (currentMonthWeekKeys.includes(weekKey)) {
             
                    const combinedWeeks = [
                        ...previousMonth.weeksOfMonth[weekKey],
                        ...currentMonth.weeksOfMonth[weekKey]
                    ];
        
                 if (weekKey === '1') {
                  currentMonth.weeksOfMonth[weekKey] = combinedWeeks;
                  delete previousMonth.weeksOfMonth[weekKey]
                 }else{
                  previousMonth.weeksOfMonth[weekKey] = combinedWeeks;
                  delete currentMonth.weeksOfMonth[weekKey]
                 }
                    
                }
            }
            }
            if (i === 0) {
              const weekKeys = Object.keys(data[i].weeksOfMonth)
             
              if (data[i].weeksOfMonth[weekKeys[0]].length < 7 && !data[i].month.includes('December')) {
             
               let datalist = data[i].weeksOfMonth[weekKeys[0]]
               let dataseting = [],datecheak =datalist[0].date
             
               for (let c = (7- datalist.length); c > 0; c-=1) {
            
               const originalDate = moment(datecheak, 'YYYY-MM-DD');
               let adjustedDate = originalDate.clone().subtract(c, 'days');
              
         
               
               dataseting.push({
                date: adjustedDate.format('YYYY-MM-DD'),
                dayOfWeek: adjustedDate.format('dddd'),
                display:'none'
              })
               }
          
               
               dataseting.push(...datalist)
               data[i].weeksOfMonth[weekKeys[0]] = dataseting
              }
            }
    
            console.log(i,data.length-1);
            
            if (i === data.length-1) {
    
              const weekKeys = Object.keys(data[i].weeksOfMonth)
               if (data[i].weeksOfMonth[weekKeys[weekKeys.length-1]].length < 7 ) {
             
               let datalist = data[i].weeksOfMonth[weekKeys[weekKeys.length-1]]
               let dataseting = [],datecheak =datalist[datalist.length-1].date
               console.log('s',datalist);
               for (let c =  1; c < (7-(datalist.length-1)); c+=1) {
          
               const originalDate = moment(datecheak, 'YYYY-MM-DD');
               let adjustedDate = originalDate.clone().add(c, 'days');
              
         
               
               dataseting.push({
                date: adjustedDate.format('YYYY-MM-DD'),
                dayOfWeek: adjustedDate.format('dddd'),
                display:'none'
              })
               }
          
               
             datalist.push(...dataseting)
               data[i].weeksOfMonth[weekKeys[weekKeys.length-1]] = datalist
              }
            }
           
        }
        return data
    };
    
    
    
    let data = getMonthDetails(startDate, endDate)
      return findDuplicateWeeks(data);
    }; */

   
      
    function adjustAndSumTimeEntries(timeEntries) {
        let totalHours = 0;
        let totalTime = 0;

        timeEntries.forEach(entry => {
            let hours = parseInt(entry.ts_hours, 10);
            let time = parseInt(entry.ts_time, 10);

            totalHours += hours;
            totalTime += time;
        });

        totalHours += Math.floor(totalTime / 60);
        totalTime = totalTime % 60;

        return {
            ts_hours: totalHours,
            ts_time: totalTime,
            ts_sum: totalHours + (totalTime * 0.01)

        };
    }
    async function setingweek(date, datalist) {

        let start_week = moment(new Date(date[0])).week();
        let end_week = moment(new Date(date[1])).week();
        /*   console.log(start_week,end_week) */
        let temp_week = [];
        let start_year = moment(new Date(date[0])).year();
        let end_year = moment(new Date(date[1])).year();
        let year_list = [];
        let diff_year = moment(new Date(date[1])).diff(moment(new Date(date[0])), 'year');

        let diff_day = moment(new Date(date[1])).diff(moment(new Date(date[0])), 'day');
        let percent_plan_avg_day = parseFloat(100 / (diff_day + 1));
        let last_per_plan = 0;
        /*   console.log('check',percent_plan_avg_day,diff_day) */

        if(moment(new Date(date[0])).diff(moment(new Date(date[0])).startOf('week'), 'day') < 4 && new Date(new Date(date[0])).getMonth() === 11){
            start_year+=1
         }else{
          
         }
if(moment(new Date(date[1])).diff(moment(new Date(date[1])).startOf('week'), 'day') > 3  && new Date(new Date(date[1])).getMonth() === 11){
    end_year+=1
    
}else{

}

        for (let i_ = start_year; i_ <= end_year; i_++) {
         console.log('i_',i_)
            let all_week = 52
            if (i_ !== start_year) {
                start_week = 1;
            }
            if (end_year == i_ && start_year != end_year) {
                all_week = end_week;
                start_week = 1;
            }

            if (start_year == end_year && moment(new Date(date[0])).week() <= moment(new Date(date[1])).week() && end_week != 1) {


                all_week = end_week;
            }

            for(let in_=0; in_ < 7; in_++){
                let check_end_week = moment(`${24+in_}/12/${i_}`,'DD/MM/YYYY').week();
                if(check_end_week == 53){
                    all_week = check_end_week
                }
            }

if (all_week  < start_week) {
    all_week = start_week
}
          console.log('ก่อน loop week',start_week, ' <=',all_week) 
            for (let i = start_week; i <= all_week; i++) {
                //console.log('check',i,i_)
                let temp = {
                    week: i,
                    day_list: getDatesOfWeek(i, i_, percent_plan_avg_day, last_per_plan, new Date(date[0]), new Date(date[1]), datalist),
                    year: i_
                }


                last_per_plan = temp.day_list[temp.day_list.length - 1].percent_plan;
                let check = temp_week.filter((e) => { return e.week == i && e.year == i_ });
                //console.log('check',check)
                if (check.length == 0) {
                    temp_week.push(temp);
                }


            }
        }
        if (start_year == end_year && end_week == 1) {
            let temp = {
                week: 1,
                day_list: getDatesOfWeek(1, end_year + 1, percent_plan_avg_day, last_per_plan, new Date(date[0]), new Date(date[1]), datalist),
                year: end_year + 1
            }

            last_per_plan = temp.day_list[temp.day_list.length - 1].percent_plan;
            let check = temp_week.filter((e) => { return e.week == 1 && e.year == end_year + 1 });
            //console.log('check',check)
            if (check.length == 0) {
                temp_week.push(temp);
            }
        }




        setWeekCalendar({
            ...weekCalendar, data: temp_week
        })
        weekCalendar.data = temp_week

    }

    function getDatesOfWeek(weekNumber, year, avg_per_day, last_per_plan, start_date, end_date, datalist) {

        // ตั้งค่าปีและเลขสัปดาห์
        const startOfWeek = moment().year(year).week(weekNumber).startOf('week');

        // สร้างอาร์เรย์เก็บวันที่
        const weekDates = [];

        // วนลูปเพื่อเพิ่มวันที่ในอาร์เรย์
        let per_plan = last_per_plan;
        for (let i = 0; i < 7; i++) {
            /*  console.log('ds',datalist); */
            let checkActual = datalist.filter((e) => { return startOfWeek.clone().add(i, 'days').format('YYYYMMDD') == moment(e.ts_date).format('YYYYMMDD') });
            /* console.log('ds',checkActual); */
            let datecount = adjustAndSumTimeEntries(checkActual)
            /*   console.log('datecount',datecount); */
            let temp = {
                day_full: startOfWeek.clone().add(i, 'days').format(),
                day: startOfWeek.clone().add(i, 'days').format('DD'),
                task: checkActual.length,
                countday: datecount
            }

            /* if(checkActual.length > 0){
              temp.percent =   checkActual[0].project_progress;
          }else{
              let checkLastActual = logStage_.filter((e)=>{return parseInt(startOfWeek.clone().add(i, 'days').format('YYYYMMDD')) >= parseInt(moment(e.lsm_created_date).format('YYYYMMDD'))});
              if(checkLastActual.length > 0){
                
                  temp.percent =   JSON.parse(checkLastActual[checkLastActual.length -1].lsm_stage_data).project_progress;
              }
          } */


            if (parseInt(startOfWeek.clone().add(i, 'days').format('YYYYMMDD')) >= parseInt(moment(start_date).format('YYYYMMDD')) && parseInt(startOfWeek.clone().add(i, 'days').format('YYYYMMDD')) <= parseInt(moment(end_date).format('YYYYMMDD'))) {
                per_plan = parseFloat(parseFloat(per_plan) + parseFloat(avg_per_day));
                temp.percent_plan = per_plan;

            }


            weekDates.push(temp);
        }

        return weekDates;
    }


    function getGenTimeSheet() {
        setloadinddisplay(true)
        axios({
            method: "get",
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getHistoryTimeSheetbyid/${filtertimesheet.user}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },

        })
            .then(async function (response) {

                for (let i of response.data) {
                    if (i.rtv_path_file !== null && !isNaN(parseInt(i.rtv_path_file))) {
                        let response2 = await axios.get(`https://api.box.com/2.0/files/${i.rtv_path_file}/content`, {
                            headers: {
                                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                            },
                            responseType: 'blob',
                        });
                        i.href = URL.createObjectURL(response2.data);
                    }

                }




                setHistoryTimeSheetList(response.data)
                setloadinddisplay(false)
            })
            .catch(function (error) {

            });
    }

    async function gettimesheertreport() {

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/report/timesheertreportbyid`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: {
                user_id: filtertimesheet.user,
                startdate: genHistory.start_date,
                enddate: genHistory.end_date,

            }
        })
            .then(async function (response) {
                console.log(response.data);
                response.data.s = genHistory.start_date
                response.data.e = genHistory.end_date
                timesheertreport(response.data, null, 'version', (result) => {
                    console.log('result timesheet', result)
                    saveGenTimeSheet(result)

                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    function saveGenTimeSheet(data) {
        let temp = {
            start_date: genHistory.start_date,
            end_date: genHistory.end_date,
            file_path: data.pathimg || '',
            file_name: data.file_name || '',
            ref_id: data.ref_id,
            user_id: filtertimesheet.user
        }
        axios({
            method: "post",
            url: `${Configs.TTT_Game_Service}/api/timeSheet/saveHistoryTimeSheetbyid`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                getGenTimeSheet();
            })
            .catch(function (error) {

            });
    }
    function saveTimeSheet() {
        let temp = {
            project_id: timeSheetData.project_id,
            project_name: timeSheetData.project_name,
            menu_id: timeSheetData.menu_id,
            process_id: timeSheetData.ts_process_id,
            date_start: timeSheetData.date_start,
            date_end: timeSheetData.date_end,
            count_hour: timeSheetData.count_hour,
            count_minute: timeSheetData.count_minute,
            description: timeSheetData.ts_project_detail.trim() || '',
            user_id: filtertimesheet.user,
            mode: timesheetmodeselect.mode,
            date_selectstart: timeSheetData.datestartselect,
            date_selectend: timeSheetData.dateendselect,

        }
        axios({
            method: "post",
            url: `${Configs.TTT_Game_Service}/api/timeSheet/addTimeSheetbyid`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                toggle_alertSaveConfirm();
                toggle_alertSavePopup();
                gettimesheetbyuser();
                setTimeSheetData({
                    ...timeSheetData,
                    project_id: '',
                    project_name: '',
                    ts_process_id: '',
                    process_name: '',
                    module_id: '',
                    module_name: '',
                    menu_id: '',
                    menu_name: '',
                    date_start: new Date(),
                    date_end: new Date(),
                    time_start: '',
                    time_end: '',
                    ts_project_detail: '',
                    count_hour: 0,
                    count_minute: 0,
                    dateendselect: null,
                    datestartselect: null,
                });
                settimesheetmodeselect({
                    ...timesheetmodeselect,
                    mode:'day'
                });
                setTypemodeselect(false)

            })
            .catch(function (error) {
                console.log(error);
                if (!timeIsNull) {
                    toggle_timeIsNull();
                }

                setAlertMsgEditMsg({
                    ...alertMsgEditMsg,
                    menuTitle: 'ERROR',
                    isError: true,
                    message: 'PLEASE FILL OUT THE INFORMATION COMPLETELY.'
                });
                alertMsgEditMsg.menuTitle = 'ERROR';
                alertMsgEditMsg.isError = true;
                alertMsgEditMsg.message = 'PLEASE FILL OUT THE INFORMATION COMPLETELY.';
                toggle_alertMsgEdit();
            });
    }

    function calToDay() {


        const targetElement = document.getElementById('weekToday');
        targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
        });



    }



    
    return (
        <>
            <ConfigProvider
                theme={{
                    token: {

                        /*     colorPrimary: '#85BFFF', */
                        colorInfo: '#85BFFF',
                        colorBorder: '#85BFFF',
                        colorText: '#FFF',

                        colorTextDescription: '#ffff',
                        colorBgContainer: '#000',

                        zIndexPopupBase: 999999,
                        colorPrimaryBg: 'blue',//สีตอน Active ใน Select
                        colorBgBase: '#001D5F',//พื้นหลัง select ตอนกด down ลงมา

                        colorTextQuaternary: '#FFFFFF',// สีตัวหนังสือของ placeholder


                        colorFillTertiary: 'rgba(0,0,0,0.4)',// สีตอน hover ใน Select


                        colorTextBase: 'blue', // ลูกศรใน datepicker & rangepicker




                        colorPrimaryBg: '#85BFFF',
                        /*       
                               colorBgLayout:'red',
                               colorFillQuaternary:'red', */


                    }
                }}
            >
                <div className={`historyReportBackDrop ${isOpenHistoryReport ? 'opened' : 'closed'}`} onClick={() => { toggle_history_report(); }}></div>
                <div className={`historyReport ${isOpenHistoryReport ? 'opened' : 'closed'}`}>
                    <div className='historyReportBody' /* style={{maxHeight:'500px'}} */>
                        <div className='fontHeader'>Report Time Sheet</div>
                        <div style={{ display: 'flex', borderBottom: '1px solid rgba(133, 191, 255, 1)', paddingBottom: '5px', gap: '15px', alignItems: 'flex-end' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>START DATE</div>
                                <DatePicker
                                    value={genHistory.start_date}
                                    disabledDate={(current) => {
                                        return current && current > genHistory.end_date;
                                    }}
                                    placeholder='Start Date'
                                    onChange={(date) => {
                                        if (date) {
                                            setGenHistory({ ...genHistory, start_date: date });
                                        }
                                    }}
                                />
                            </div>


                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>END DATE</div>
                                <DatePicker
                                    disabledDate={(current) => {
                                        return current && current < genHistory.start_date;
                                    }}

                                    placeholder='End Date'
                                    value={genHistory.end_date}
                                    onChange={(date) => {

                                        if (date) {
                                            setGenHistory({ ...genHistory, end_date: date });
                                        }

                                    }} />
                            </div>


                            <div
                                onClick={() => {
                                    gettimesheertreport();
                                }}
                                style={{ background: 'linear-gradient(to right, rgba(0, 40, 156, 1) 0%, rgba(0, 71, 255, 1) 50%, rgba(0, 40, 156, 1) 100%)', height: '34px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '7px', width: '200px', cursor: 'pointer', color: '#fff', fontSize: '20px' }}>Generate</div>


                        </div>




                        {loadinddisplay === true ? <>
                            <div style={{ display: 'flex', height: '340px', overflowY: 'auto', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
                                <div class="loadingspinnerna" style={{ width: '180px', height: '180px' }}></div>

                            </div>
                        </>
                            :
                            <>
                                <div style={{ display: 'flex', height: '340px', overflowY: 'auto' }}>
                                    <table style={{ width: '100%', height: 'fit-content' }}>
                                        <tr style={{ background: 'linear-gradient(to right, rgba(0, 40, 156, 1) 0%, rgba(0, 71, 255, 1) 50%, rgba(0, 40, 156, 1) 100%)', height: '34px', position: 'sticky', top: 0 }}>
                                            <td style={{ textAlign: 'center', fontSize: '18px' }}>No.</td>
                                            <td style={{ textAlign: 'center', fontSize: '18px' }}>Generate Version</td>
                                            <td style={{ textAlign: 'center', fontSize: '18px' }}>Download</td>
                                        </tr>
                                        {
                                            historyTimeSheetList.map((e, index) => {
                                                return (<>
                                                    <tr style={{ height: '34px' }}>
                                                        <td style={{ textAlign: 'center', fontSize: '16px' }}>{index + 1}</td>
                                                        <td style={{ textAlign: 'center', fontSize: '16px', textTransform: 'none' }}>{e.rtv_file_name}</td>
                                                        <td style={{ textAlign: 'center', fontSize: '16px' }}><a href={`${e.href}`} download={e.rtv_file_name} target='_blank'><DownloadOutlined style={{ cursor: 'pointer' }}

                                                        />
                                                        </a>
                                                        </td>
                                                    </tr>
                                                </>)
                                            })
                                        }
                                    </table>
                                </div>

                            </>
                        }




                    </div>

                    <div className='boxAddConfirmFooter'>
                        <button className=' btn-outline-danger' style={{ padding: '5px', fontSize: '16px', height: '40px', cursor: 'pointer' }}
                            onClick={() => { toggle_history_report(); }}
                        > Cancel </button>
                    </div>

                </div>
            </ConfigProvider>
            <ConfigProvider
                theme={{
                    token: {

                        colorInfo: 'red',
                        colorBorder: '#0047FF',
                        colorText: '#FFF',

                        colorTextDescription: '#FFFFFF',
                        colorBgContainer: '#001D5F',
                        zIndexPopupBase: 999999,
                        colorPrimaryBg: '#FFFFFF',
                        colorBgBase: '#001D5F',//พื้นหลัง datepicker

                        colorTextQuaternary: 'White',//สีตัวอักษร

                        colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                        colorTextBase: 'White', // สี scroll bar

                        colorPrimaryBg: '#0047FF',// สีตอนเลือก
                        /*       
                               colorBgLayout:'red',
                               colorFillQuaternary:'red', */


                    }
                }}
            >
                <div className='timesheetAddContainer' style={{ display: `${alertSavePopup ? 'flex' : 'none'}`,fontFamily:  'Oxanium' }}>
                    <div className='timesheetAddBox'>
                        <div className='timesheetAddBoxHeader'>
                            <div>TIME SHEET</div>
                            <div className='timesheetAddBoxClose' onClick={() => {
                                setTimeSheetData({
                                    ...timeSheetData,
                                    project_id: '',
                                    project_name: '',
                                    ts_process_id: '',
                                    process_name: '',
                                    module_id: '',
                                    module_name: '',
                                    menu_id: '',
                                    menu_name: '',
                                    date_start: new Date(),
                                    date_end: new Date(),
                                    time_start: '',
                                    time_end: '',
                                    ts_project_detail: '',
                                    count_hour: 0,
                                    count_minute: 0,
                                    dateendselect:null,
                                    datestartselect:null
                                });
                                settimesheetmodeselect({
                                    ...timesheetmodeselect,
                                    mode:'day'
                                });
                                setTypemodeselect(false)
                                toggle_alertSavePopup();
                                if (timeIsNull) {
                                    toggle_timeIsNull();
                                }
                                if (descIsNull) {
                                    toggle_descIsNull();
                                }
                            }}>X</div>
                        </div>
                        {timesheetmodeselect.mode === 'day' ? 
                        <>
                          <div className='timesheetAddBoxDateRang'>
                            <CalendarOutlined style={{ fontSize: '25px' }} />
                            <RangePicker id='dateRange' style={{ width: '250px' }} className='inputTimeSheet2' allowClear={false}
                                format={'DD/MM/YYYY'}
                                value={[timeSheetData.date_start ? dayjs(timeSheetData.date_start) : '', timeSheetData.date_end ? dayjs(timeSheetData.date_end) : '']}
                                onChange={(date) => {
                                    if (date && date.length == 2) {
                                        setFilterDateType(null);
                                        setTimeSheetData({ ...timeSheetData, date_start: new Date(date[0]), date_end: new Date(date[1]) })
                                        /*      changeMenuValue('menu_start_stop_date', date, menu.menu_id); */

                                    } else {
                                        //console.log('date', date)
                                        setTimeSheetData({ ...timeSheetData, date_start: null, date_end: null })
                                    }
                                }}
                            />
                            <div className={filterDataType == 'today' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
                                onClick={() => {
                                    setFilterDateType('today');
                                    setTimeSheetData({ ...timeSheetData, date_start: new Date(), date_end: new Date() });

                                }}
                            >
                                Today
                            </div>
                            <div className={filterDataType == 'this_week' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
                                onClick={() => {
                                    setFilterDateType('this_week');
                                    let start = moment(new Date()).startOf('weeks').add(1, 'days');
                                    let end = moment(new Date()).endOf('weeks').add(-1, 'days');
                                    setTimeSheetData({ ...timeSheetData, date_start: start, date_end: end });
                                }}
                            >
                                This Week
                            </div>
                            <div className={filterDataType == 'last_week' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
                                onClick={() => {
                                    setFilterDateType('last_week')
                                    let start = moment(new Date()).startOf('weeks').add(1, 'days').add(-1, 'weeks');
                                    let end = moment(new Date()).endOf('weeks').add(-1, 'days').add(-1, 'weeks');
                                    setTimeSheetData({ ...timeSheetData, date_start: start, date_end: end });
                                }}
                            >
                                Last Week
                            </div>
                        </div>
                        
                        </>


                        :
                        <></>
}
                      
                        <ConfigProvider
                            theme={{
                                token: {

                                    colorInfo: 'red',
                                    colorBorder: '#0047FF',
                                    colorText: '#FFF',

                                    colorTextDescription: '#FFFFFF',
                                    colorBgContainer: '#0A2268',
                                    borderRadius: '2px',
                                    colorBorder: '#0047FF !imporstant',
                                    zIndexPopupBase: 999,
                                    colorPrimaryBg: '#FFFFFF',
                                    colorBgBase: '#001D5F',//พื้นหลัง datepicker

                                    colorTextQuaternary: '#D4D4D4',//สีตัวอักษร

                                    colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                                    colorTextBase: 'White', // สี scroll bar

                                    colorPrimaryBg: '#0047FF',// สีตอนเลือก

                                }
                            }}
                        >
                        <div className='timesheetAddBoxDateRang'>

                        Today
<Switch checked={typemodeselect} onChange={(value)=>{
    settimesheetmodeselect({
        ...timesheetmodeselect,mode:value === true ? 'across' : 'day'
    })
    setTimeSheetData({ ...timeSheetData,  
        project_id: '',
        project_name: '',
        ts_process_id: '',
        process_name: '',
        module_id: '',
        module_name: '',
        menu_id: '',
        menu_name: '',  
        date_start: new Date(),
        date_end: new Date(),
        time_start: '',
        time_end: '',
        ts_project_detail: '',
        count_hour: 0,
        count_minute: 0,
        datestartselect:null,
        dateendselect:null,});
    setTypemodeselect(value)
}} />
Across day

 
</div>
</ConfigProvider>

                        <div className='timesheetAddBoxDateRang'>
                        {timesheetmodeselect.mode === 'day' ? 
<>
<ClockCircleOutlined style={{ fontSize: '25px' }} />
                            <RangePicker style={{ width: '220px', height: '32px' }} className='inputTimeSheet2'
                         format={'HH:mm'}
                                value={[timeSheetData.time_start ? dayjs(timeSheetData.time_start) : '', timeSheetData.time_end ? dayjs(timeSheetData.time_end) : '']}
                                onChange={(date) => {
                                    //console.log('date',date)
                                    if (date && date.length == 2) {
 console.log(date);
                                        let minute_all = moment(new Date(date[1])).seconds(0).milliseconds(0).diff(moment(new Date(date[0])).seconds(0).milliseconds(0), 'minutes');
                                        let hours = Math.floor(minute_all / 60);
                                        let minute = minute_all - (Math.floor(minute_all / 60)) * 60;
                                        setTimeSheetData({ ...timeSheetData, count_hour: hours, count_minute: minute, time_start: new Date(date[0]), time_end: new Date(date[1]) });


                                    }
                                }}
                                
                                allowClear={false}
                                picker="time"
                            />

                            OR
                            <Input style={{ width: '35px', padding: 0, height: '32px', textAlign: 'center', border: `1px solid ${timeIsNull ? 'red' : '#0047FF'}` }} className='inputTimeSheet2'
                                value={timeSheetData.count_hour}
                                onChange={(e) => {
                                    if ((!isNaN(e.target.value) && parseInt(e.target.value) <= 23) || e.target.value == '') {
                                        setTimeSheetData({ ...timeSheetData, count_hour: e.target.value.trim(), time_start: null, time_end: null });
                                        setErrorMessage('');
                                        if (timeIsNull) {
                                            toggle_timeIsNull();
                                        }
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key == '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            hrs.
                            <Input style={{ width: '35px', padding: 0, height: '32px', textAlign: 'center', border: `1px solid ${timeIsNull ? 'red' : 'blue'}` }} className='inputTimeSheet2'
                                value={timeSheetData.count_minute}
                                onChange={(e) => {
                                    if ((!isNaN(e.target.value) && parseInt(e.target.value) <= 59) || e.target.value == '') {
                                        setTimeSheetData({ ...timeSheetData, count_minute: e.target.value.trim(), time_start: null, time_end: null });
                                        setErrorMessage('');
                                        if (timeIsNull) {
                                            toggle_timeIsNull();
                                        }
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key == '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            min


</>

:


<>
<ClockCircleOutlined style={{ fontSize: '25px' }} />
<DatePicker
style={{ border: `1px solid ${timeIsNull ? 'red' : 'blue'}` }}
        showTime
        allowClear={false}
        format="DD/MM/YYYY HH:mm"
        placeholder="Select Date"
        value={timeSheetData.datestartselect}
        onChange={(date) => {
console.log(date);

           if (date) {
            let dateselect = timeSheetData.dateendselect === null ? dayjs(date).add(1, 'day') : dayjs(date).add(1, 'day').set('hour', timeSheetData.dateendselect.hour()).set('minute', timeSheetData.dateendselect.minute())
            setTimeSheetData({ ...timeSheetData, datestartselect: dayjs(date), dateendselect: dateselect });
            setErrorMessage('');
            if (timeIsNull) {
                toggle_timeIsNull();
            }
           }
          }}
      />

<DatePicker 
style={{ border: `1px solid ${timeIsNull ? 'red' : 'blue'}` }}
showTime={{ 
    format: 'HH:mm', 
   
  }}
  disabledDate={(current) => {
    return current && current.format('YYYY-MM-DD') !== dayjs(timeSheetData.dateendselect).format('YYYY-MM-DD');
  }}
 /*  disabledTime={() => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  })} */
/* picker="time" */
        allowClear={false}
        format="DD/MM/YYYY HH:mm"
        placeholder="Select Time"
        disabled={timeSheetData.dateendselect? false : true}
        value={timeSheetData.dateendselect}
        onChange={(date) => {
            console.log(date);
            setTimeSheetData({ ...timeSheetData, dateendselect: dayjs(date) });
            setErrorMessage('');
            if (timeIsNull) {
                toggle_timeIsNull();
            }     
                      }}
      />
                        {/*     <RangePicker style={{ width: '70%', height: '32px' }} className='inputTimeSheet2'
                         format="DD/MM/YYYY HH:mm"
                                value={[timeSheetData.datestartselect ? dayjs(timeSheetData.datestartselect) : '', timeSheetData.dateendselect ? dayjs(timeSheetData.dateendselect) : '']}
                                onChange={(date) => {
                                   console.log('date',date)
                                    if (date && date.length == 2) {

let s = date[0].second(0).diff(timeSheetData.datestartselect.second(0), 'day');
let e = date[1].second(0).diff(timeSheetData.dateendselect.second(0), 'day');

let ns =  dayjs(timeSheetData.datestartselect).add(s, 'day')
let ne =  dayjs(timeSheetData.dateendselect).add(e, 'day')
console.log(s,e);
                                

                                    }
                                }}
                               
                                allowClear={false}
                                showTime={{
                                    format: 'HH:mm',
                                
                                   
                                  }}
                            /> */}
</>}
                          
                        </div>
                        <ConfigProvider
                            theme={{
                                token: {

                                    colorInfo: 'red',
                                    colorBorder: '#0047FF',
                                    colorText: '#FFF',

                                    colorTextDescription: '#FFFFFF',
                                    colorBgContainer: '#0A2268',
                                    borderRadius: '2px',
                                    colorBorder: '#0047FF !imporstant',
                                    zIndexPopupBase: 999,
                                    colorPrimaryBg: '#FFFFFF',
                                    colorBgBase: '#001D5F',//พื้นหลัง datepicker

                                    colorTextQuaternary: '#D4D4D4',//สีตัวอักษร

                                    colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                                    colorTextBase: 'White', // สี scroll bar

                                    colorPrimaryBg: '#0047FF',// สีตอนเลือก

                                }
                            }}
                        >
                        
  <div className='timesheetAddBoxDateRang'>
  {/* <div >
    Other
<Switch checked={typeProject} onChange={(value)=>{
    setTimeSheetData({ ...timeSheetData, project_id: '' ,project_name:'',
        process_name:'',
        process_id:'',
        module_id: '',
        module_name: '',
        menu_id: '',
        menu_name: '',
     });
    setTypeProject(value);
    
}} style={{marginLeft:'5px',marginRight:'5px'}}/>
On Spaceship
</div> */}
    <div>
    Other
<Switch checked={typeProject} onChange={(value)=>{
    setTimeSheetData({ ...timeSheetData, project_id: '' ,project_name:'',
        process_name:'',
        process_id:'',
        module_id: '',
        module_name: '',
        menu_id: '',
        menu_name: '',
     });
    setTypeProject(value);
}} style={{marginLeft:'5px',marginRight:'5px'}}/>
On Spaceship
</div>
</div>
                    {typeProject ? (
     <div className='timesheetAddBoxDateRang'>
     <div style={{ width: '15%' }}>PROJECT :</div>
     <Select
         showSearch
         className='timesheetInput'
         style={{ flexGrow: 1, width: 'fit-content'/* , border: `1px solid ${timeSheetData.project_id == '' || timeSheetData.project_id == null || timeSheetData.project_id == undefined ? 'red' : '#0047FF'}` */ }}
         options={timesheetProjectList}
         value={timeSheetData.project_id}
         onChange={(_, f) => {
             setTimeSheetData({ ...timeSheetData, project_id: f.value, project_name: f.label });
             timeSheetData.project_id = f.value;
             timeSheetData.project_name = f.label;
             // let checkModule = timesheetMenuList.filter((ef) => { return ef.project_id == timeSheetData.project_id });
             // if (checkModule.length > 0) {
             //     setTimeSheetData({
             //         ...timeSheetData,
             //         module_id: checkModule[0].module_id,
             //         module_name: checkModule[0].module_name,
             //         menu_id: checkModule[0].value,
             //         menu_name: checkModule[0].label,
             //     });
             //     timeSheetData.module_id = checkModule[0].module_id;
             //     timeSheetData.module_name = checkModule[0].module_name;
             //     timeSheetData.menu_id = checkModule[0].value;
             //     timeSheetData.menu_name = checkModule[0].label;
             // } else {
             setTimeSheetData({
                 ...timeSheetData,
                 module_id: '',
                 module_name: '',
                 menu_id: '',
                 menu_name: '',
             });
             timeSheetData.module_id = '';
             timeSheetData.module_name = '';
             timeSheetData.menu_id = '';
             timeSheetData.menu_name = '';
             // };
             setTest({ ...test, test: '' });
             test.test = '';
         }}
     />
 </div>

                    ):(

<div className='timesheetAddBoxDateRang'>
<div style={{ width: '15%' }}>PROJECT :</div>
<Input
      className='timesheetInput'
      value={timeSheetData.project_name}
      style={{ flexGrow: 1, width: 'fit-content'/* , border: `1px solid ${timeSheetData.ts_process_id == '' || timeSheetData.ts_process_id == null || timeSheetData.ts_process_id == undefined ? 'red' : '#0047FF'}` */ }}
      onChange={(e)=>{
        let check = timesheetProjectList.filter((e2)=>{return e2.label == e.target.value});
        console.log(check)
        
        setTimeSheetData({ ...timeSheetData, project_id: check.length > 0 ? check[0].value : '', project_name: e.target.value });
      }}
/>
</div>
                    )}
                            <div className='timesheetAddBoxDateRang'>
                                <div style={{ width: '15%' }}>PROCESS :</div>
                                <Select
                                    showSearch
                                    className='timesheetInput'
                                    style={{ flexGrow: 1, width: 'fit-content'/* , border: `1px solid ${timeSheetData.ts_process_id == '' || timeSheetData.ts_process_id == null || timeSheetData.ts_process_id == undefined ? 'red' : '#0047FF'}` */ }}
                                    options={timesheetProcessList}
                                    value={timeSheetData.process_name}
                                    onChange={(_, f) => {
                                        setTimeSheetData({
                                            ...timeSheetData,
                                            ts_process_id: f.value,
                                            process_name: f.label,
                                        });
                                        timeSheetData.ts_process_id = f.value;
                                        timeSheetData.process_name = f.label;
                                        setTest({ ...test, test: '' });
                                        test.test = '';
                                    }}
                                />
                            </div>
                            <div className='timesheetAddBoxDateRang'>
                                <div style={{ width: '15%' }}>MODULE :</div>
                                <Select
                                    showSearch
                                    className='timesheetInput'
                                    style={{ flexGrow: 1, width: 'fit-content'/* , border: `1px solid ${timeSheetData.module_id == '' || timeSheetData.module_id == null || timeSheetData.module_id == undefined ? 'red' : '#0047FF'}` */ }}
                                    options={timesheetModuleList.filter((ef) => {
                                        return ef.project_id == timeSheetData.project_id
                                    })}
                                    value={timeSheetData.module_name}
                                    onChange={(_, f) => {
                                        setTimeSheetData({
                                            ...timeSheetData,
                                            module_id: f.value,
                                            module_name: f.label,
                                        });
                                        timeSheetData.module_id = f.value;
                                        timeSheetData.module_name = f.label;
                                        // let checkModule = timesheetMenuList.filter((ef) => { return ef.module_id == timeSheetData.module_id });
                                        // if (checkModule.length > 0) {
                                        //     setTimeSheetData({
                                        //         ...timeSheetData,
                                        //         menu_id: checkModule[0].value,
                                        //         menu_name: checkModule[0].label,
                                        //     });
                                        //     timeSheetData.menu_id = checkModule[0].value;
                                        //     timeSheetData.menu_name = checkModule[0].label;
                                        // } else {
                                        setTimeSheetData({
                                            ...timeSheetData,
                                            menu_id: '',
                                            menu_name: '',
                                        });
                                        timeSheetData.menu_id = '';
                                        timeSheetData.menu_name = '';
                                        // };
                                        setTest({ ...test, test: '' });
                                        test.test = '';
                                    }}
                                />
                            </div>
                            <div className='timesheetAddBoxDateRang'>
                                <div style={{ width: '15%' }}>MENU :</div>
                                <Select
                                    showSearch
                                    className='timesheetInput'
                                    style={{ flexGrow: 1, width: 'fit-content'/* , border: `1px solid ${timeSheetData.menu_id == '' || timeSheetData.menu_id == null || timeSheetData.menu_id == undefined ? 'red' : '#0047FF'}` */ }}
                                    options={timesheetMenuList.filter((ef) => {
                                        return ef.module_id == timeSheetData.module_id && ef.project_id == timeSheetData.project_id
                                    })}
                                    value={timeSheetData.menu_name}
                                    onChange={(_, f) => {
                                        setTimeSheetData({
                                            ...timeSheetData,
                                            menu_id: f.value,
                                            menu_name: f.label,
                                        });
                                        timeSheetData.menu_id = f.value;
                                        timeSheetData.menu_name = f.label;
                                        setTest({ ...test, test: '' });
                                        test.test = '';
                                    }}
                                />
                            </div>
                        </ConfigProvider>

                        <div className='timesheetAddBoxDateRang'>
                            <div>DESCRIPTION :</div>
                            <TextArea
                                className='timesheetInput'
                                value={timeSheetData.ts_project_detail}
                                autoSize={{
                                    minRows: 1
                                }}
                                style={{ textAlign: 'start', border: `1px solid ${/* timeSheetData.ts_project_detail == '' || timeSheetData.ts_project_detail == null || timeSheetData.ts_project_detail == undefined */descIsNull ? 'red' : '#0047FF'}`  ,maxHeight: '250px'}}
                                onChange={(f) => {
                                    setTimeSheetData({ ...timeSheetData, ts_project_detail: f.target.value });
                                    timeSheetData.ts_project_detail = f.target.value;
                                    if (descIsNull) {
                                        toggle_descIsNull();
                                    }
                                    setTest({ ...test, test: '' });
                                    test.test = '';
                                }}
                            />
                        </div>
                        <div className='btnSave' onClick={() => {
                            if (timeSheetData.ts_project_detail == '' || timeSheetData.ts_project_detail == null || timeSheetData.ts_project_detail == undefined || (timesheetmodeselect.mode == 'day'? timeSheetData.count_hour == 0 && timeSheetData.count_minute == 0 : timeSheetData.datestartselect ==null && timeSheetData.dateendselect == null) /* || timeSheetData.project_id == '' || timeSheetData.ts_process_id == '' || timeSheetData.module_id == '' || timeSheetData.menu_id == '' */) {
                                setAlertMsgEditMsg({
                                    ...alertMsgEditMsg,
                                    menuTitle: 'ERROR',
                                    isError: true,
                                    message: 'Please fill out the information completely.'
                                });
                                alertMsgEditMsg.menuTitle = 'ERROR';
                                alertMsgEditMsg.isError = true;
                                alertMsgEditMsg.message = 'Please fill out the information completely.';
                                console.log(timeSheetData,timesheetmodeselect.mode,timesheetmodeselect.mode == 'day'? timeSheetData.count_hour == 0 && timeSheetData.count_minute == 0 : timeSheetData.datestartselect == null && timeSheetData.dateendselect == null);
                                
                                if (timeSheetData.count_hour == 0 && timeSheetData.count_minute == 0) {
                                    if (!timeIsNull) {
                                        toggle_timeIsNull();
                                    }
                                }
                                if (timeSheetData.ts_project_detail == '' || timeSheetData.ts_project_detail == null || timeSheetData.ts_project_detail == undefined) {
                                    if (!descIsNull) {
                                        toggle_descIsNull();
                                    }
                                }
                                toggle_alertMsgEdit();
                            } else {
                                toggle_alertSaveConfirm();
                               /*  getCountTodayTimeSheet(); */
                                setTest({ ...test, test: '' });
                                test.test = '';
                            }
                        }}
                        >SAVE</div>
                    </div>
                </div>

            </ConfigProvider>
            <div className='backgoundTimeline'>
                <div className='timelineHeaderContainer' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                        <div className='backBottom'
                            onClick={() => {
                                window.location.href = '/StageMonitoring';
                            }}>
                            <img src={iconback_doorStage} />
                        </div>

                        <div className='towerGradient'></div>
                        <div style={{position:'relative'}}>
                        <div className='textHeaderTimelinetimebox' >PERSONAL TIMESHEET</div>
                        </div>
                        
                        <div className='textHeaderTimelinetime' >{filtertimesheet.username}</div>
                        <div className='timesheetToDayText'>
                        </div>


                    </div>
                    <div style={{ display: 'flex', whiteSpace: 'nowrap',marginTop:'10px' }}>
                        <div className='textrigth'> Avg./Day : <span style={{ color: '#0047FF', marginLeft: '10px', marginRight: '10px' }}>{`${ todayUse.avg} `}</span> H </div>
                        <div className='boxrigth'>
                            <div className='timeicon' style={{ backgroundImage: `url(${timeicon})` }} /> <div style={{ marginRight: '3px' }}><span style={{ color: '#0047FF' }}>{todayUse.day}</span> DAY : <span style={{ color: '#0047FF' }}>{todayUse.hours}</span> H <span style={{ color: '#0047FF' }}>{todayUse.minute}</span> MIN </div>
                        </div>
                    </div>
                </div>
                {/*  <div className='timesheetHead'>
    

                </div> */}
                <div className='filterContainner'>
                    <ConfigProvider
                        theme={{
                            token: {

                                colorInfo: '#272727',
                                colorBorder: '#272727',
                                colorText: '#FFF',

                                colorTextDescription: '#FFFFFF',
                                colorBgContainer: '#272727',
                                zIndexPopupBase: 999999,
                                colorPrimaryBg: '#FFFFFF',
                                colorBgBase: '#272727',//พื้นหลัง datepicker

                                colorTextQuaternary: 'White',//สีตัวอักษร

                                colorFillTertiary: '#272727',//สีตอนเมาสชี้

                                colorTextBase: 'White', // สี scroll bar

                                colorPrimaryBg: '#272727',// สีตอนเลือก
                                /*       
                                       colorBgLayout:'red',
                                       colorFillQuaternary:'red', */


                            }
                        }}
                    >
                        <div style={{ display: 'flex', gap: '15px', alignItems: 'center', color: "#fff", fontSize: '20px' }}>
                            <RangePicker id='dateRange' style={{ width: '250px', height: '45px' }} /* className='inputTimeSheet2' */ allowClear={false}
                                format={'DD/MM/YYYY'}
                                value={[filtertimesheet.start ? dayjs(filtertimesheet.start) : '', filtertimesheet.end ? dayjs(filtertimesheet.end) : '']}
                                onChange={(date) => {
                                    /*  console.log(date); */
                                    if (date && date.length == 2) {
                                        console.log('x',date);
                                        /*  setingweek(date) */


                                        /*   meSheetData({ ...timeSheetData, date_start: new Date(date[0]), date_end: new Date(date[1]) }) */
                                        setfiltertimesheet({ ...filtertimesheet, start: date[0], end: date[1] })
                                        filtertimesheet.start = date[0]
                                        filtertimesheet.end = date[1]


                                    } else {
                                        //console.log('date', date)
                                        // setTimeSheetData({ ...timeSheetData, date_start: null, date_end: null })
                                    }
                                }}
                            />
                            {timesheetUserList.length > 0 ? <>
                                <Select
                                    dropdownClassName="custom-dropdown"
                                    showSearch
                                    className='timesheetInputuser'
                                    options={timesheetUserList}
                                    value={filtertimesheet.user}
                                    onChange={(e, f) => {
                                        console.log(e);
                                        setfiltertimesheet({ ...filtertimesheet, user: f.value, username: f.name })
                                        filtertimesheet.user = f.value
                                        filtertimesheet.username = f.name
                                        gettimesheetbyuser()
                                        getTimesheetProjectById()
                                        getTimesheetModuleById()
                                        getTimesheetMenuById()

                                    }}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </> : <></>}
                            <div className='BottonExport' onClick={() => {
                                gettimesheetbyuser()
                            }}
                                style={{ borderRadius: '5px' }}
                            >Search</div>

                            <div className='BottonExport' onClick={() => {
                                calToDay();
                            }}
                                style={{ borderRadius: '5px', background: '#272727', whiteSpace: 'nowrap' }}
                            >To Day</div>
                        </div>
                    </ConfigProvider>
                    <div style={{ display: 'flex' }}>

                        <div className='BottonExportrigth'   style={{ borderRadius: '5px' }}onClick={() => {
                            getGenTimeSheet();
                            setGenHistory({ ...genHistory, start_date: dayjs(filtertimesheet.start), end_date: dayjs(filtertimesheet.end) });
                            toggle_history_report();


                        }}>Export</div>
                        <div className='timesheetAddButton'  style={{ borderRadius: '5px' }} onClick={() => {
                            toggle_alertSavePopup();

                        }}><img src={timesheetIcon} style={{ height: '100%' }} /></div>
                    </div>
                </div>

                <div className='calendarContainer ' /* overcol *//* style={{msOverflowY:'auto',overflowX:'hidden'}} */>
                    <div className='calendarContainer2 ' >
                        <div className='headerBoxCalendar'>Week</div>
                        {headerCalendar.map((e) => {
                            return (<>
                                <div className='headerBoxCalendar'>{e}</div>
                            </>)
                        })}
                    </div>
                    <div className='calendarContainer' >
                        {isLoad == true ? (<div style={{ display: 'flex', fontSize: '52px', justifyContent: 'center', alignItems: 'center', height: "500px" }}>


                            <LoadingOutlined /></div>) : (<>
                                {weekCalendar.data.map((w, i) => {
                                    let checkNow = moment(new Date()).week() == w.week && w.year == moment(new Date()).year();


                                    return (<>
                                        <div className='calendarContainer2  ' style={{ background: checkNow && '#1A394D', padding: '5px', borderRadius: '10px' }} id={checkNow && 'weekToday'}>
                                            <div className='weekBoxCalendar' style={{ border: checkNow ? '2px solid #D4D4D4' : '2px solid #33333'/* ,minWidth:'200px' */ }}><div>Week {w.week}</div> <div>{w.year}</div></div>
                                            {w.day_list.map((d, index) => {

console.log(d.countday.ts_sum);

                                                return (<>
                                                    <div className={`dayBoxCalendar ${!d.percent_plan ? 'boxout' :'boxin'}`} style={{  border: `${(d.percent_plan === undefined || d.task > 0) ? 'none' : '1px solid #FF0000'}`, animationDelay: `${index * 0.05}s` }}>
                                                    <div style={{display:'flex',justifyContent:'end',width:'100%',position:'relative'}}>
                                                            {(d.countday.ts_sum > 8.30) ? <>
                                                           
                                                                <div className='bumicon' style={{ backgroundImage: `url(${bumicon})` }} />
                                                                
                                                                 </> : <></>}
                                                                 </div>

                                                        <div className='textMonth' style={{ marginLeft: '70px', height: '45px', visibility: d.day != 1 ? 'hidden' : '' }}>{moment(d.day_full).format('MMM')}</div>
                                                        <div className='dayBoxCalendarIn' >
                                                       
                                                              

                                                            <div className='textDay' style={{ marginTop: '-39px' }}/* style={{marginTop:`${d.day == 1 ? -25 : 0}px`}} */>
                                                                {d.day}
                                                            </div>

                                                        </div>
                                                        <div className='dayBoxCalendarIn2' style={{ visibility: !d.percent_plan ? 'hidden' : '' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '180px' }}>
                                                                <div className='texttask' >TASK <span style={{ color: `${d.task > 0 ? '#009DFF' : '#FF0000'}` }}>{d.task}</span></div>
                                                                <div className={`${d.countday.ts_sum > 8.30 ? 'boxsumtimebum' : 'boxsumtime'}`} style={{ display: `${d.task > 0 ? 'block' : 'none'}` }}>{`${d.countday.ts_hours} H ${d.countday.ts_time} M`}</div>
                                                            </div>

                                                            {/*  <div style={{display:'flex',flexDirection:'column',gap:'5px'}}>
                <div className='boxTextPercent' style={{background:color_percent_text}}>{d.percent_plan ? d.percent : 0} %</div>
                <div className='boxPercent' style={{overflow:'hidden'}}>
                <div style={{background:color_percent,width:'100%',height:`${d.percent_plan  ? d.percent : 0}%`}}></div>
                </div>
          
            </div>
            <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-end',gap:'5px'}}>
                    <div style={{fontSize:'15px',display:'flex',gap:'5px'}}>Actual :<div style={{color:'#1DCC37'}}>{d.percent} %</div></div>
                    <div style={{fontSize:'15px',display:'flex',gap:'5px'}}>Plan :<div style={{color:'#009DFF'}}>{d.percent_plan ? parseFloat(d.percent_plan).toFixed(2)+' %' : '-'} </div></div>
                </div> */}
                                                        </div>
                                                    </div>

                                                </>)
                                            })}

                                        </div>
                                    </>);
                                })}
                            </>)}
                    </div>
                </div>


            </div>
            <AlertConfrim
                isOpen={alertSaveConfirm}
                toggle_alertKick={toggle_alertSaveConfirm}
                mainFuc={saveTimeSheet}
            />
  <AlertMassge
                isOpen={alertMsgEdit}
                toggle_alertKick={toggle_alertMsgEdit}
                message={alertMsgEditMsg}
            // mainFuc={}
            />
        </>
    )
}

export default Timesheettimeline
