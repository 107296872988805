import React, { useEffect, useState, useRef } from 'react';
import "./style.css";

import axios from "axios";
import Configs from "../../../../config";

import { ConfigProvider, Select, Switch, Row, Col, Input, Tooltip } from 'antd';
import { ShoppingCartOutlined, DeleteOutlined } from '@ant-design/icons';

import backIcon from "./img/Icon.png"
import bgTop from "./img/bgTop.png"
import bgBottom from "./img/bgBottom.png"
import unkey from "./img/unkey.png"
import sapphireIcon from "./img/Frame.png"
import roleIcon from "./img/role.png"
import keyIcon from "./img/key.png"
import rocket from "./img/rocket.png"

import AlertConfrim from "../../../../components/AlertMassge/AlertConfrim"
import AlertMassage from "../../../../components/AlertMassge/AlertMassge"
import ElementLogTransaction from "./elementLogTransaction"

function AddElementShop(params) {
    const isOpenRef = useRef(params.isOpen);

    const [test, setTest] = useState({
        test: ''
    });

    const [filter, setFilter] = useState({
        search: '',
        role: '',
        type: '',
        node: ''
    });
    const [optionsSelectRole, setOptionSelectRole] = useState([]);

    const [optionsSelectType, setOptionSelectType] = useState([]);

    const [optionsSelectNode, setOptionSelectNode] = useState([]);

    const [alertConfirmIsOpen, setAlertConfirmIsOpen] = useState(false);
    const toggleAlertConfirmIsOpen = () => { setAlertConfirmIsOpen(!alertConfirmIsOpen) };
    const [alertMassageIsOpen, setAlertMassageIsOpen] = useState(false);
    const toggleAlertMassageIsOpen = () => { setAlertMassageIsOpen(!alertMassageIsOpen) };

    const [alertConfirmMessage, setAlertConfirmMessage] = useState({
        messageMain: 'Are you sure you want to buy?',
    })
    const [messageBox, setMessageBox] = useState({
        message: 'Insufficient balance.',
        isError: true,
        menuTitle: 'BUY ELEMENT FAIL.'
    })

    const [addElementShopData, setAddElementShopData] = useState([]);

    const [elementShopOnCart, setElementShopOnCart] = useState([]);

    const [elementShopCart, setElementShopCart] = useState(false);
    const toggleElementShopCart = () => { setElementShopCart(!elementShopCart) };

    const [elementShopSapphire, setElementShopSapphire] = useState(0);

    const [elementLog, setElementLog] = useState(false);
    const toggleElementLog = () => { setElementLog(!elementLog) };

    function numberWithFormat(value) {
        let set = 0;
        if (value >= 1000000) {
            set = `${(parseFloat((value) / 1000000).toFixed(2))}M`;
        } else if (value < -1000000) {
            set = `${(parseFloat((value) / 1000000).toFixed(2))}M`;
        } else if (value >= 1000) {
            set = `${(parseFloat((value) / 1000).toFixed(2))}K`;
        } else if (value <= -1000) {
            set = `${(parseFloat((value) / 1000).toFixed(2))}K`;
        } else {
            set = parseFloat(value || 0).toFixed(2);
        }
        return set
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    async function GetAccesstokenToken(callback) {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {

                if (callback) {
                    callback(response.data.tokenBox);
                }
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function getElementRoleForShop() {
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/element/getElementRoleForShop`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }

        })
            .then(async function (response) {
                if (response.data) {
                    setOptionSelectRole(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getElementTypeForShop() {
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/element/getElementTypeForShop`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }

        })
            .then(async function (response) {
                if (response.data) {
                    setOptionSelectType(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getElementNodeForShop() {
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/element/getElementNodeForShop`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }

        })
            .then(async function (response) {
                if (response.data) {
                    setOptionSelectNode(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getSapphire() {
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/element/getSapphire`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }

        })
            .then(async function (response) {
                if (response.data) {
                    setElementShopSapphire(response.data?.usr_sapphire);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getElementDataShop() {
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/element/getElementDataShop`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }

        })
            .then(async function (response) {
                if (response.data) {
                    setAddElementShopData(response.data);

                    for (let item of response.data) {
                        for (let item2 of item.elm_list) {
                            if (item2.fd_img_id && isOpenRef.current) {
                                let check = params.cashImage?.filter((fil) => { return fil.fd_img_id == item2.fd_img_id });
                                if (check.length > 0) {
                                    item2.elm_img_path = check[0].fd_img_path;
                                } else {
                                    let response2 = await axios.get(`https://api.box.com/2.0/files/${item2.fd_img_id}/content`, {
                                        headers: {
                                            Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                        },
                                        responseType: 'blob',
                                    });

                                    item2.elm_img_path = URL.createObjectURL(response2.data);
                                    let temp = {
                                        fd_img_id: item2.fd_img_id,
                                        fd_img_path: URL.createObjectURL(response2.data)
                                    };
                                    params.cashImage?.push(temp)
                                    setAddElementShopData(response.data);
                                }

                                setTest({ ...test, test: '' });
                                test.test = '';
                            }
                        }
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function buyElement() {
        let temp = {
            elm_list: elementShopOnCart,
            sapphire: elementShopSapphire
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/element/buyElement`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setElementShopOnCart([]);
                    toggleElementShopCart();
                    getElementRoleForShop();
                    getElementTypeForShop();
                    getElementNodeForShop();
                    getSapphire();
                    GetAccesstokenToken((result) => {
                        if (result) {
                            getElementDataShop();
                        };
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if (params.isOpen) {
            getElementRoleForShop();
            getElementTypeForShop();
            getElementNodeForShop();
            getSapphire();
            GetAccesstokenToken((result) => {
                if (result) {
                    getElementDataShop();
                };
            });
        }
        isOpenRef.current = params.isOpen;
    }, [params.isOpen])

    return (
        <>
            {params.isOpen && <>
                <div className="addElementBg">
                    <div className="addElementBgSet" style={{ backgroundImage: `url(${bgTop})` }}>
                        <div className='addElementHeader' style={{ margin: 0, padding: 0 }}>
                            <div className="addElementHeaderLeft">
                                <div className='addElementBack' onClick={() => {
                                    params.toggle();
                                    if (elementShopCart) {
                                        toggleElementShopCart();
                                    }
                                }}>
                                    <img src={backIcon} style={{ width: '100%', height: '100%' }}></img>
                                </div>
                                <div className="addElementHeadLine"></div>
                                <div className="addElementHeaderName">ELEMENT SHOP</div>
                            </div>
                            <div className='addElementPhaseBox' style={{ border: 'none' }}>
                                <div className='addElementPhaseBoxAccount' style={{ border: 'none', cursor: 'auto' }}>
                                    <div className='addElementPhaseBoxAccountHeader' style={{ cursor: 'pointer' }} onClick={() => {
                                        toggleElementLog();
                                    }}>ประวัติการซื้อ</div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px', visibility: 'hidden' }}>
                                        <img className='addElementPhaseIcon' src={sapphireIcon}></img>
                                        <div className='addElementPhaseBoxAccountMoney'>{numberWithFormat(500000)}</div>
                                    </div>
                                </div>
                                <div className='addElementPhaseBoxAccount' style={{ border: 'none', cursor: 'auto' }}>
                                    <div className='addElementPhaseBoxAccountHeader'>ยอดคงเหลือ</div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }}>
                                        <img className='addElementPhaseIcon' src={sapphireIcon}></img>
                                        <div className='addElementPhaseBoxAccountMoney'>{numberWithFormat(elementShopSapphire || 0)}</div>
                                    </div>
                                </div>
                                <div className='addElementPhaseBoxShop' onClick={() => {
                                    toggleElementShopCart();
                                }}>
                                    <div className='addElementHeaderName' style={{ fontSize: '20px' }}>{elementShopOnCart?.length}</div>
                                    <ShoppingCartOutlined style={{ fontSize: '20px' }} />
                                </div>
                            </div>
                        </div>
                        <div className='addElementFilter'>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: '#0047FF',
                                        colorInfo: '#0047FF',
                                        colorBorder: '#0047FF',
                                        colorText: '#FFF',

                                        colorTextDescription: '#FFFFFF',
                                        colorBgContainer: '#000',

                                        zIndexPopupBase: 999999,
                                        colorPrimaryBg: '#0047FF',//สีตอน Active ใน Select
                                        colorBgBase: '#0047FF',//พื้นหลัง select ตอนกด down ลงมา

                                        colorTextQuaternary: '#B0C4DE',// สีตัวหนังสือของ placeholder
                                        colorBorder: '#0047FF',

                                        colorFillTertiary: '#3354aa',// สีตอน hover ใน Select
                                    },
                                }}
                            >
                                <div className='addElementFilterType'>
                                    <div className='addElementFilterHead'>Search</div>
                                    <Input
                                        // className='addElementSelectCustom'
                                        type='text' placeholder='Search'
                                        allowClear
                                        style={{ height: '40px', width: '275px', marginTop: '-4px' }}
                                        value={filter.search}
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                search: e.target.value
                                            })
                                            filter.search = e.target.value;
                                        }}
                                    />
                                </div>
                                <div className='addElementFilterType'>
                                    <div className='addElementFilterHead'>Role</div>
                                    <Select
                                        showSearch
                                        dropdownClassName='addElementSelectCustom'
                                        value={optionsSelectRole.filter((e) => { return e.value == filter.role })}
                                        onChange={(e) => {
                                            setFilter({ ...filter, role: e });
                                            filter.role = e;
                                        }}
                                        placeholder='Role'
                                        options={optionsSelectRole}
                                        allowClear
                                        filterOption={(input, option) =>
                                            option?.label?.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </div>
                                <div className='addElementFilterType'>
                                    <div className='addElementFilterHead'>Element Type</div>
                                    <Select
                                        showSearch
                                        dropdownClassName='addElementSelectCustom'
                                        value={optionsSelectType.filter((e) => { return e.value == filter.type })}
                                        onChange={(e) => {
                                            setFilter({ ...filter, type: e });
                                            filter.type = e;
                                        }}
                                        placeholder='Element Type'
                                        options={optionsSelectType}
                                        allowClear
                                        filterOption={(input, option) =>
                                            option?.label?.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </div>
                                <div className='addElementFilterType'>
                                    <div className='addElementFilterHead'>Element Node</div>
                                    <Select
                                        showSearch
                                        dropdownClassName='addElementSelectCustom'
                                        value={optionsSelectNode.filter((e) => { return e.value == filter.node })}
                                        onChange={(e) => {
                                            setFilter({ ...filter, node: e });
                                            filter.node = e;
                                        }}
                                        placeholder='Element Node'
                                        options={optionsSelectNode}
                                        allowClear
                                        filterOption={(input, option) =>
                                            option?.label?.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </div>
                            </ConfigProvider>
                        </div>
                        <Row gutter={[16, 16]} className='addElementDataBox'>
                            {addElementShopData?.filter((fil) => {
                                let is_search = true, is_role = true, is_type = true, is_node = true;
                                if (filter.search) {
                                    // is_search = fil.elm_name.toString().toLowerCase().includes(filter.search.toString().toLowerCase());
                                    is_search = fil.elm_list?.some((_some) => { return _some.elm_name.toString().toLowerCase().includes(filter.search.toString().toLowerCase()) });
                                }

                                if (filter.role) {
                                    // is_role = fil.elm_role_list?.some((_some) => { return _some.role_id == filter.role })
                                    is_role = fil.elm_list?.some((_some) => { return _some.elm_role_list?.some((_some2) => { return _some2.role_id == filter.role }) });
                                }

                                if (filter.type) {
                                    // is_type = fil.elm_type_id == filter.type
                                    is_type = fil.elm_list?.some((_some) => { return _some.elm_type_id == filter.type });
                                }

                                if (filter.node) {
                                    // is_node = fil.elm_node_id == filter.node
                                    is_node = fil.elm_list?.some((_some) => { return _some.elm_node_id == filter.node });
                                }

                                return (is_search && is_role && is_type && is_node)
                            })?.map((e, i) => {
                                return <>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <div className='elmShopPhaseHeader'>
                                            <div className='elmShopPhaseHeaderIcon' style={{ backgroundImage: `url(${rocket})` }}></div>
                                            <div>{e.pp_name}</div>
                                        </div>
                                    </Col>
                                    {e.elm_list?.filter((fil) => {
                                        let is_search = true, is_role = true, is_type = true, is_node = true;
                                        if (filter.search) {
                                            is_search = fil.elm_name.toString().toLowerCase().includes(filter.search.toString().toLowerCase());
                                        }

                                        if (filter.role) {
                                            is_role = fil.elm_role_list?.some((_some) => { return _some.role_id == filter.role })
                                        }

                                        if (filter.type) {
                                            is_type = fil.elm_type_id == filter.type
                                        }

                                        if (filter.node) {
                                            is_node = fil.elm_node_id == filter.node
                                        }

                                        return (is_search && is_role && is_type && is_node)
                                    })?.map((ee, ei) => {
                                        return <Col xs={24} sm={12} md={8} lg={6}>
                                            <div className='addElementDataHead'>
                                                <div className='addElementDataHeadSet'>
                                                    <div className='addElementDataHeadText'>{ee.elm_type_name}</div>
                                                    <div className='addElementDataHeadText'>{ee.elm_node_name}</div>
                                                </div>
                                                <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                                                    {elementShopOnCart.findIndex(filter => filter.elm_id == ee.elm_id) > -1 && <ShoppingCartOutlined style={{ fontSize: '18px' }} />}
                                                    <div className='addElementPriceBox' onClick={() => {
                                                        if (!ee.elm_have) {
                                                            let check = elementShopOnCart.findIndex(filter => filter.elm_id == ee.elm_id);
                                                            if (check > -1) {
                                                                elementShopOnCart.splice(check, 1);
                                                            } else {
                                                                elementShopOnCart.push(ee);
                                                            };
                                                            setTest({ ...test, test: '' });
                                                            test.test = '';
                                                        }
                                                    }}>
                                                        <img className='addElementPriceIcon' src={sapphireIcon}></img>
                                                        <div className='addElementPrice'>{ee.elm_have ? 'ALREADY' : numberWithCommas(ee.elm_price || 0)}</div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='addElementDataBody'>
                                                <div className='addElementDataBodyImg'>
                                                    <img className='addElementPriceIcon' src={ee.elm_img_path}></img>
                                                </div>
                                                <div className='addElementDataBodySet'>
                                                    <Tooltip placement="topLeft" title={ee.elm_name} overlayClassName="momTabCustomTooltip">
                                                        <div className='addElementDataName'>{ee.elm_name}</div>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title={ee.elm_detail} overlayClassName="momTabCustomTooltip">
                                                        <div className='addElementDataDesc'>{ee.elm_detail}</div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className='addElementDataFooter'>
                                                <div className='addElementDataRoleIconBox'>
                                                    <img className='addElementPriceIcon' src={roleIcon}></img>
                                                    <Tooltip placement="topLeft" title={
                                                        ee.elm_role_list?.map(map => map.role_name).join(', ') && <span dangerouslySetInnerHTML={{ __html: ee.elm_role_list?.map(map => map.role_name).join('<br />') }} />
                                                    } overlayClassName="momTabCustomTooltip">
                                                        <div className='addElementRoleTextList'>
                                                            {ee.elm_role_list?.map(map => map.role_name).join(', ') || '-'}
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                                <div className='addElementRoleText' style={{ color: '#FFF' }}>Level <span style={{ color: '#E4DA15' }}>{ee.elm_level_name}</span></div>
                                            </div>
                                        </Col>
                                    })}
                                </>
                            })}
                        </Row>

                        {/* <Row gutter={[16, 32]} className='addElementDataBox'>
                            {addElementData && addElementData.filter((fil) => {
                                // return filter.type ? filter.category ? fil.elm_category.toLowerCase() == filter.category.toLowerCase() && fil.elm_type.toLowerCase() == filter.type.toLowerCase() : fil.elm_type.toLowerCase() == filter.type.toLowerCase() : filter.category ? fil.elm_category.toLowerCase() == filter.category.toLowerCase() : fil
                                return fil
                            })?.map((e, i) => {
                                return <>
                                    <Col xs={24} sm={12} md={8} lg={6}>
                                        <div className='addElementDataHead'>
                                            <div className='addElementDataHeadSet'>
                                                <div className='addElementDataHeadText'>{e.elm_type}</div>
                                                <div className='addElementDataHeadText'>{e.elm_category}</div>
                                            </div>
                                            {e.elm_have ?
                                                <Switch defaultChecked={e.elm_is_active} />
                                                :
                                                <div className='addElementPriceBox' onClick={() => {
                                                    toggleAlertConfirmIsOpen();
                                                }}>
                                                    <img className='addElementPriceIcon' src={sapphireIcon}></img>
                                                    <div className='addElementPrice'>{numberWithCommas(e.elm_price)}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className='addElementDataBody'>
                                            <div className='addElementDataBodyImg'>
                                                <img className='addElementPriceIcon' src={e.fd_image_path}></img>
                                            </div>
                                            <div className='addElementDataBodySet'>
                                                <div className='addElementDataName'>{e.elm_name}</div>
                                                <div className='addElementDataDesc'>{e.elm_desc}</div>
                                            </div>
                                        </div>
                                        <div className='addElementDataFooter'>
                                            <div className='addElementDataRoleIconBox'>
                                                <img className='addElementPriceIcon' src={roleIcon}></img>
                                                <div className='addElementRoleText'>{e.elm_role}</div>
                                            </div>
                                            <div className='addElementRoleText' style={{ color: '#FFF' }}>Level <span style={{ color: '#E4DA15' }}>{e.elm_level}</span></div>
                                        </div>
                                    </Col>
                                </>
                            })}
                        </Row> */}
                    </div>
                </div>

            </>}

            {elementShopCart && <>
                <div className='elementShopCartBox'>
                    <div className='elementShopCartBoxHeader'>รายการสั่งซื้อ</div>

                    <div className='elementShopCartBoxBody' style={{ width: '100%' }}>
                        {elementShopOnCart?.map((e, i) => {
                            return <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center', width: '100%' }}>
                                    <div className='elementShopCartNo'>{i + 1}</div>
                                    <div style={{ flexGrow: '1', display: 'flex', gap: '10px' }}>
                                        <div className='addElementDataBodyImg'>
                                            <img className='addElementPriceIcon' src={e.elm_img_path}></img>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                                            <Tooltip placement="topLeft" title={e.elm_name} overlayClassName="momTabCustomTooltip">
                                                <div className='elementShopCartListName'>{e.elm_name}</div>
                                            </Tooltip>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '5px' }}>
                                                <img className='addElementPhaseIcon' src={sapphireIcon}></img>
                                                <div className='addElementPhaseBoxAccountMoney' style={{ fontSize: '15px', fontWeight: 400 }}>{numberWithFormat(e.elm_price || 0)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='elementShopCartDel' onClick={() => {
                                        elementShopOnCart.splice(i, 1);
                                        setTest({ ...test, test: '' });
                                        test.test = '';
                                    }}><DeleteOutlined style={{ fontSize: '20px' }} /></div>
                                </div>
                            </>
                        })}
                    </div>

                    <div className='elementShopCartBoxSum' style={{ color: '#FFF' }}>
                        <div>ยอดใช้จ่าย</div>
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                            <img className='addElementPhaseIcon' src={sapphireIcon}></img>
                            <div className='addElementPhaseBoxAccountMoney'>{numberWithFormat(elementShopOnCart.length > 0 ? elementShopOnCart?.reduce((previousValue, currentValue) => previousValue + (currentValue.elm_price || 0), 0) : 0)}</div>
                        </div>
                    </div>

                    <div className='elementShopCartBoxSum'>
                        <div>ยอดคงเหลือหลังยืนยัน</div>
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <div className='addElementPhaseBoxAccountMoney' style={{ color: '#FF0000' }}>-</div> */}
                            <img className='addElementPhaseIcon' src={sapphireIcon}></img>
                            <div className='addElementPhaseBoxAccountMoney' style={{ color: '#FF0000' }}>{numberWithFormat(elementShopOnCart.length > 0 ? elementShopSapphire - elementShopOnCart?.reduce((previousValue, currentValue) => previousValue + (currentValue.elm_price || 0), 0) : elementShopSapphire)}</div>
                        </div>
                    </div>

                    <div className='elementShopCartBoxButton' onClick={() => {
                        if (parseInt(elementShopOnCart.length > 0 ? elementShopSapphire - elementShopOnCart?.reduce((previousValue, currentValue) => previousValue + (currentValue.elm_price || 0), 0) : elementShopSapphire) > -1 && elementShopOnCart.length > 0) {
                            toggleAlertConfirmIsOpen();
                        } else if (elementShopOnCart.length < 1) {
                            setMessageBox({
                                ...messageBox,
                                message: 'Please select items to add to your cart.',
                                isError: true,
                                menuTitle: 'BUY ELEMENT FAIL.'
                            });
                            messageBox.message = 'Please select items to add to your cart.';
                            messageBox.isError = true;
                            messageBox.isError = 'BUY ELEMENT FAIL.';
                            toggleAlertMassageIsOpen();
                        } else {
                            setMessageBox({
                                ...messageBox,
                                message: 'Insufficient balance.',
                                isError: true,
                                menuTitle: 'BUY ELEMENT FAIL.'
                            });
                            messageBox.message = 'Insufficient balance.';
                            messageBox.isError = true;
                            messageBox.isError = 'BUY ELEMENT FAIL.';
                            toggleAlertMassageIsOpen();
                        }
                    }}>ยืนยัน</div>
                </div>
            </>}

            <ElementLogTransaction
                isOpen={elementLog}
                toggle={toggleElementLog}
            />

            <AlertConfrim
                isOpen={alertConfirmIsOpen}
                toggle_alertKick={toggleAlertConfirmIsOpen}
                mainFuc={buyElement}
                message={alertConfirmMessage}
            />

            <AlertMassage
                isOpen={alertMassageIsOpen}
                toggle_alertKick={toggleAlertMassageIsOpen}
                // mainFuc={buyElement}
                message={messageBox}
            />
        </>
    );
}
export default AddElementShop;
