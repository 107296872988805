import React, { useEffect, useState, useRef, useMemo  } from 'react';
import Configs from "../../config";
import axios from 'axios';
import './risk.css'
import iconback_doorStage from "../../assets/img/icon/IconBack_stage.png";
import iconPlus from "../../assets/img/icon/plus-radius.png";
import iconSaveRisk from "../../assets/img/icon/save-risk_management.png";
import iconVectorRisk from "../../assets/img/icon/Vector-Risk.png";
import iconBin from "../../assets/img/icon/icon-Bin.png";
import { Menu,Dropdown, ConfigProvider, DatePicker, Select, Space,Tooltip, Pagination, Flex, TreeSelect } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css'; 
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { label } from 'yet-another-react-lightbox';
import { v4 as uuidv4 } from 'uuid';
import ModelAlertConfrim from "../../components/AlertMassge/AlertConfrim";
import ModelAlertMassge from "../../components/AlertMassge/AlertMassge";
import ComponentAlertRiskDel from "./componentAlertRiskDel";
import { faL } from '@fortawesome/free-solid-svg-icons';
import ExcelJS from 'exceljs';
import filePath from './Risks.xlsx'
import { saveAs } from 'file-saver';

function RiskManagement({project_id}) {

    const StyledDatePicker = styled(DatePicker)`
    .ant-picker-clear {
        padding-Right: 10px; 
    }
    `;
    ;
    const { TreeNode } = TreeSelect;
    const location = useLocation();
    // const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const getProjectId = queryParams.get('id');
    // const clearQueryParams = () => {
    //   history.push(location.pathname);
    // };


    const [test, setTest] = useState({ test: '' });
    const [RiskManagementList, setRiskManagementList] = useState([]);
    const [DelRiskManagementList, setDelRiskManagementList] = useState({
        rmr_id:"",
        rmr_pri_id:""
    });
    const [optionRiskCategoty, setoptionRiskCategoty] = useState([]);
    const [optionRiskManagementStatus1, setRiskManagementStatus1] = useState([]);
    const [optionRiskManagementStatus2, setRiskManagementStatus2] = useState([]);
    const [idSpaceshipAndFederation, setidSpaceshipAndFederation] = useState({
        spaceship_id:"",
        federation_id:"",
        project_name:""
    });
    const [CountRiskManagement, setCountRiskManagement] = useState({
        AllCountRiskManagement:"",
        CloseCountRiskManagement:"",
        UnCloseCountRiskManagement:"",
        LastUpdateRiskManagement:""
    });
    const [selectedValue, setSelectedValue] = useState(null);
    const [loading, setLoading] = useState(false);
   
    const [alertKickMassge, setAlertKickMassge] = useState(false);
    const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);
    const [messageBox, setMessageBox] = useState({
        message: [],
        isError: false,
        menuTitle: ''
    })
    const [displaySwal, setDisplaySwal] = useState(false);
    const toggle_modal_confrimModal = () => setDisplaySwal(!displaySwal);

    const [alertKickMassgeDel, setAlertKickMassgeDel] = useState(false);
    const toggle_modal_alertKickMassgeDel = () => setAlertKickMassge(!alertKickMassgeDel);
    const [messageBoxDel, setMessageBoxDel] = useState({
        message: [],
        isError: false,
        menuTitle: ''
    })
    const [displaySwalDel, setDisplaySwalDel] = useState(false);
    const toggle_modal_confrimModalDel = () => setDisplaySwalDel(!displaySwalDel);

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const handlePageChange = (page) => {
        console.log("page",page);
        
        setCurrentPage(page);
      };
      const totalPages = Math.ceil(RiskManagementList.length / pageSize);
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const currentItems = RiskManagementList.slice(startIndex, endIndex);

    const handleMenuClick = (e) => {
        const selectedLabel = items.find(item => item.key === e.key)?.label;
        // const handlePageChange = (page) => {
        //     setCurrentPage(page);
        // };
        setSelectedValue(selectedLabel);
        filterRiskManagement(selectedLabel); 
        // console.log('Selected value:', selectedLabel);
      };
      const [isDisabled, setIsDisabled] = useState(false);
      const [expandedKeys, setExpandedKeys] = useState([]);
      const handleTitleClick = (parentId) => {
        setExpandedKeys((prevKeys) => {
            if (prevKeys.includes(parentId)) {
                return prevKeys.filter((key) => key !== parentId); // หากเปิดอยู่ให้ลดลง
            } else {
                return [...prevKeys, parentId]; // หากไม่ได้เปิดอยู่ให้ขยาย
            }
        });
    };
      const [SaveRiskManagement, setSaveRiskManagementList] = useState(
        {
            mode:false,
            mode_status:"",
            rmr_id: uuidv4(),
            rmr_rc_id: '',
            rmr_descriptions: '',
            rmr_probability: '',
            rmr_impact: '',
            rmr_exposure: '',
            rmr_rms_id_1: '',
            rmr_mitigation_plan: '',
            rmr_contingency_plan: '',
            rmr_target_date: null,
            rmr_rms_id_2: '81ba9127-d22a-4bc5-9f77-f51ca1ede2ff',
            rmr_closed_date: null,
            rmr_remark: null,
            rmr_project_id: getProjectId,
            rmr_federation_id: idSpaceshipAndFederation.federation_id,
            rmr_spaceship_id: idSpaceshipAndFederation.spaceship_id,
            rmr_is_use: true,
            rmr_created_date:  moment(new Date()).toISOString(),
            rmr_created_by: sessionStorage.getItem('user_id'),
            rmr_updated_date:  moment(new Date()).toISOString(),
            rmr_updated_by: sessionStorage.getItem('user_id'),
            rmr_pri_id:'',
            pri_created_name:sessionStorage.getItem('user'),
            arrMasterRc:[],
          }
      );
    
    
      console.log("RiskManagementList: ",RiskManagementList);
      
      // รายการของเมนู
      const items = [
        {
          label: 'All',
          key: '1',
        },
        {
          label: 'Weekly',
          key: '2',
        },
        {
          label: 'Monthly',
          key: '3',
        },
      ];
    
      const menu = (
        <Menu onClick={handleMenuClick}>
          {items.map(item => (
            <Menu.Item key={item.key}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      );

      const onChange = (value) => {
        setSaveRiskManagementList({...SaveRiskManagement, rmr_rc_id:value});

      };

    // console.log("SaveRiskManagement: ",SaveRiskManagement);


    const getRiskManagementName = (rmr_impact, rmr_probability, rmr_id) => {
        let numRisk = rmr_impact * rmr_probability;
        let temp_rms_name = "";
        let temp_rms_id = "";
    
        for (let item of optionRiskManagementStatus1) {
            if (numRisk <= 8 ) {
                if(item.rms_id === '77e8ab34-304c-42c0-93ba-d00fdc68c55f'){
                    temp_rms_name = item.rms_name;
                    temp_rms_id = item.rms_id;
                    break;
                }
            } else if(numRisk >= 9 && numRisk <= 15) {
                if(item.rms_id === '04bd9e8e-8146-4bdb-9344-18f1ce822a2a'){
                    temp_rms_name = item.rms_name;
                    temp_rms_id = item.rms_id;
                    break;
                }
            } else if(numRisk >= 16 && numRisk <= 25) {
                if(item.rms_id === '45c5e389-dae9-4e43-9fd8-5865f6f92628'){
                    temp_rms_name = item.rms_name;
                    temp_rms_id = item.rms_id;
                    break;
                }
            }
        }
        
        return temp_rms_name;
    };

    const getRiskClassCardMain = (numRisk) => {
        if (numRisk <= 8) return 'containerCardRiskManagementRisk-low';
        if (numRisk >= 9 && numRisk <= 15) return 'containerCardRiskManagementRisk-medium';
        if (numRisk >= 16 && numRisk <= 25) return 'containerCardRiskManagementRisk-high';
    };
    
    const getRiskClassName = (numRisk) => {
        if (numRisk <= 8) return 'risk-low';
        if (numRisk >= 9 && numRisk <= 15) return 'risk-medium';
        if (numRisk >= 16 && numRisk <= 25) return 'risk-high';
    };

    const getRiskStatus = (status) => {
        if (status === "New") return '#057dd4';
        if (status === "Open") return '#c67007';
        if (status === "Watch") return '#d0c816';
        if (status === "Closed") return '#24c50a';
    };
    
    
    const optionProbability = [
        {
            value:"1",
            label:"1"
        },
        {
            value:"2",
            label:"2"
        },
        {
            value:"3",
            label:"3"
        },
        {
            value:"4",
            label:"4"
        },
        {
            value:"5",
            label:"5"
        }
    ]
      useEffect(() => {
        getIdSpaceshipAndFederation();
        filterRiskManagement();
        selesctRiskCategoty();
        selesctRiskManagementStatus();
      
      }, []);

      useEffect(() => {
        filterRiskManagement();
      
      }, [currentPage,pageSize]);

      const onClickNewRisk = () => {

        setSaveRiskManagementList({...SaveRiskManagement, 
            mode:true,
            mode_status:"add",
            // rmr_id: uuidv4(),
            rmr_rc_id: '',
            rmr_descriptions: '',
            rmr_probability: '',
            rmr_impact: '',
            rmr_exposure: '',
            rmr_rms_id_1: '',
            rmr_mitigation_plan: '',
            rmr_contingency_plan: '',
            rmr_target_date: null,
            rmr_rms_id_2: '81ba9127-d22a-4bc5-9f77-f51ca1ede2ff',
            rmr_closed_date: null,
            rmr_remark: null,
            rmr_project_id: getProjectId,
            rmr_federation_id: idSpaceshipAndFederation.federation_id,
            rmr_spaceship_id: idSpaceshipAndFederation.spaceship_id,
            rmr_is_use: true,
            rmr_created_date:  moment(new Date()).toISOString(),
            rmr_created_by: sessionStorage.getItem('user_id'),
            rmr_updated_date:  moment(new Date()).toISOString(),
            rmr_updated_by: sessionStorage.getItem('user_id'),
        });

    
      }

      const onClickBackAddRisk = () => {
        setSaveRiskManagementList({...SaveRiskManagement, mode:false});
      }

    //   console.log("RiskManagementList: ", RiskManagementList);

      async function filterRiskManagement(selectedData) {
        // console.log('testlog',editData.pri_id, editData.edit_text);
        setLoading(true);
        let temp = {
            page:currentPage || 1,
            size: 10,
            project_id:getProjectId,
            selectedValue:selectedData
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/filterRiskManagement`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                // console.log(response.data);
                
                setRiskManagementList(response.data.data);
                setCountRiskManagement({...CountRiskManagement, 
                AllCountRiskManagement:response.data.AllCountRiskManagement,
                CloseCountRiskManagement:response.data.CloseCountRiskManagement,
                UnCloseCountRiskManagement:response.data.UnCloseCountRiskManagement,
                LastUpdateRiskManagement:response.data.LastUpdateRiskManagement
            });
             
                setTest({ ...test, test: '' });
                test.test = '';
                setLoading(false);
                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getIdSpaceshipAndFederation() {

        let temp = {
            project_id:getProjectId
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/getIdSpaceshipAndFederation`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                // console.log(response.data);  
                
                setidSpaceshipAndFederation({...idSpaceshipAndFederation,
                    spaceship_id:response.data.spaceship_id,
                    federation_id:response.data.federation_id,
                    project_name:response.data.project_name
                 });
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getByIdRecordRiskmanagement(rmr_id,mode_status) {

        let temp = {
            rmr_id:rmr_id
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/getByIdRecordRiskmanagement`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                setSaveRiskManagementList({...SaveRiskManagement,
                    mode:true,
                    mode_status:mode_status,
                    rmr_id: response.data[0].rmr_id,
                    rmr_rc_id: response.data[0].rmr_rc_id,
                    rmr_descriptions: response.data[0].rmr_descriptions,
                    rmr_probability: response.data[0].rmr_probability,
                    rmr_impact: response.data[0].rmr_impact,
                    rmr_exposure: response.data[0].rmr_exposure,
                    rmr_rms_id_1: response.data[0].rmr_rms_id_1,
                    rmr_mitigation_plan: response.data[0].rmr_mitigation_plan,
                    rmr_contingency_plan: response.data[0].rmr_contingency_plan,
                    rmr_target_date: response.data[0].rmr_target_date,
                    rmr_rms_id_2: response.data[0].rmr_rms_id_2,
                    rmr_closed_date: response.data[0].rmr_closed_date,
                    rmr_remark: response.data[0].rmr_remark,
                    rmr_project_id: response.data[0].rmr_project_id,
                    rmr_federation_id: response.data[0].rmr_federation_id,
                    rmr_spaceship_id: response.data[0].rmr_spaceship_id,
                    rmr_is_use: response.data[0].rmr_is_use,
                    rmr_created_date:  response.data[0].rmr_created_date,
                    rmr_created_by: response.data[0].rmr_created_by,
                    rmr_updated_date:  response.data[0].rmr_updated_date,
                    rmr_updated_by: response.data[0].rmr_updated_by,
                    rmr_pri_id:response.data[0].rmr_pri_id,
                    pri_pri_updated_by: response.data[0].pri_pri_updated_by,
                    pri_updated_name:sessionStorage.getItem('user'),


                });
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }



    //   console.log("DelRiskManagementList: ",DelRiskManagementList);
      
      async function DelRecordRiskmanagement() {
        setDisplaySwal(false)
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/DelRecordRiskmanagement`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: DelRiskManagementList
        })
            .then(async function (response) {   
                setDelRiskManagementList({...DelRiskManagementList, rmr_id:""});
                setMessageBoxDel({
                    ...messageBox,
                    message: 'success',
                    isError: false,
                    menuTitle: 'Delete'
                })

                toggle_modal_alertKickMassgeDel();
                
             })
            .catch(function (error) {
                console.log(error);
            });
    }
    
    
    async function saveRecordRiskmanagement() {
  
        const error_list = [];
        // for(let checkData of RiskManagementList){
         
        if (SaveRiskManagement.rmr_descriptions == '' || SaveRiskManagement.rmr_descriptions == null) {
            let temp_err = {
                message: "You must enter Risk Descriptions."
            }
            error_list.push(temp_err);
        }

        if (SaveRiskManagement.rmr_target_date == '' || SaveRiskManagement.rmr_target_date == null) {
            let temp_err = {
                message: "You must select Target Date."
            }
            error_list.push(temp_err);
        }
 
        if (SaveRiskManagement.rmr_rms_id_2 == '177ee013-076d-4d0c-96e8-fdcaceacdc2c' && SaveRiskManagement.rmr_closed_date == null) {
            let temp_err = {
                message: "You must select the Closed date."
            }
            error_list.push(temp_err);
        }
  
    // }
    // console.log("error_list: ",error_list);
    
        if (error_list.length > 0) {

            var err_message = [];
            for (var e = 0; e < error_list.length; e++) {
                err_message.push(error_list[e].message);
            }
            setMessageBox({
                ...messageBox,
                message: err_message || [],
                isError: null,
                menuTitle: 'WARNING'
            })
            setDisplaySwal(false)
            setAlertKickMassge(true)
        } else {
           let temp = {
            SaveRiskManagement:SaveRiskManagement,
            // DelRiskManagementList:DelRiskManagementList
           }
           if(SaveRiskManagement.mode_status === "add"){
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/saveRecordRiskmanagement`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                // console.log(response.data);
                
          
                setTest({ ...test, test: '' });
                test.test = '';

                // filterRiskManagement();
                setSaveRiskManagementList({});
                setMessageBox({
                    ...messageBox,
                    message: 'success',
                    isError: false,
                    menuTitle: 'Save'
                })

                toggle_modal_alertKickMassge();
                

            })
            .catch(function (error) {
                console.log(error);
            });
        }else if(SaveRiskManagement.mode_status === "edit"){
            // console.log("เข้า edit");

            await axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/riskManagement/editRecordRiskmanagement`,
                headers: {
                    'Authorization': sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp
            })
                .then(async function (response) {
                    // console.log(response.data);
                    
              
                    setTest({ ...test, test: '' });
                    test.test = '';
    
                    // filterRiskManagement();
                    setSaveRiskManagementList({});
                    setMessageBox({
                        ...messageBox,
                        message: 'success',
                        isError: false,
                        menuTitle: 'Save'
                    })
                    // setDisplaySwal(false)
    
                    toggle_modal_alertKickMassge();
                    
    
                })
                .catch(function (error) {
                    console.log(error);
                });
            
        }
    }
}
useEffect(() => {
    const isDisabledFun = () => {
        if (SaveRiskManagement.mode_status === "view") {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    };

    isDisabledFun();
}, [SaveRiskManagement.mode_status]);

        
    async function selesctRiskCategoty() {

        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/selesctRiskCategoty`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            // data: temp
        })
            .then(async function (response) {
                // console.log(response.data);
                setoptionRiskCategoty(response.data);
                
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // console.log("optionRiskManagementStatus2: ",optionRiskManagementStatus2);
    
    async function selesctRiskManagementStatus() {

        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/selesctRiskManagementStatus`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            // data: temp
        })
            .then(async function (response) {
                // console.log(response.data.risk_status_1);
                setRiskManagementStatus1(response.data.risk_status_1);
                setRiskManagementStatus2(response.data.risk_status_2);
         
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function setcolor(name) {
        if (name === 'Closed') {
            return "61CBF3";
        } else if (name === 'Open') {
            return "FFC000";
        } else if (name === 'Watch') {
            return "FFFF00";
        } else {
            return "00B0F0";
        }
    }
    
    async function excell(selectedData) {
        setLoading(true);
        let temp = {
            project_id:getProjectId,
            selectedValue:selectedData
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/Riskexcell`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                setLoading(false);
               let value = response.data
                const arrayBuffer = await fetchExcelFile(filePath);

                const workbook = new ExcelJS.Workbook();
                await workbook.xlsx.load(arrayBuffer);
            
                const worksheet = workbook.worksheets[0];
            
                const newWorkbook = new ExcelJS.Workbook();
                const newWorksheet = newWorkbook.addWorksheet('Risk Management');
      let count = 0,strat = 7
                worksheet.eachRow((row, rowNumber) => {
           console.log(row,rowNumber);
               
                  row.eachCell((cell, colNumber) => {
                      console.log(row,colNumber,cell.value);
                    const newCell = newWorksheet.getCell(rowNumber+count, colNumber);
                    newCell.value = cell.value;
                    newCell.style = cell.style;
                    newCell.border = {
                      top: { style: 'thin', color: { argb: 'FF000000' } },
                      left: { style: 'thin', color: { argb: 'FF000000' } },
                      bottom: { style: 'thin', color: { argb: 'FF000000' } },
                      right: { style: 'thin', color: { argb: 'FF000000' } }
                    };
                  });
                  if (rowNumber === 6) {
                    console.log(value);
                     value.dataTable.forEach((item, rowIndex) => {
                                value.headTable.forEach((header, colIndex) => {
                                  const cell = newWorksheet.getCell(rowIndex + rowNumber+1, colIndex + 1);
                               
                                  let values = item[header.field];
                                  if (header.field === 'rmr_exposure') {
                                    console.log(rowIndex + rowNumber+1);
                                    
                                    cell.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: values }, 
                                    };
                                    values = { formula: `D${rowIndex + rowNumber+1}*E${rowIndex + rowNumber+1}` }
        
                                    
                                  }
        
                                  if (header.field === 'rmr_mode') {
                                    console.log(rowIndex + rowNumber+1);
                                    
                                    cell.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: values }, 
                                    };
                                    values = { formula: `IF(F${rowIndex + rowNumber+1}<1,"",IF(F${rowIndex + rowNumber+1}<9,"Accept",IF(F${rowIndex + rowNumber-1}<16,"Watch","Mitigate")))` }
        
                                    
                                  }

                                  if (header.field === 'rms_name') {
                                    console.log(rowIndex + rowNumber+1);
                                    
                                    cell.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: setcolor(values) }, 
                                    };
                                 
                                    
                                  }




                        
                                  cell.value = values;
                                  if (header.field === 'rc_no'|| header.field === 'rmr_probability' || header.field === 'rmr_impact' || header.field === 'rmr_exposure' || header.field === 'rmr_mode' || header.field =='rms_name') {
                                
                                    cell.alignment = { vertical: 'top', horizontal: 'center' };
                                } else if (typeof cell.value === 'string') {
                              
                                    cell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
                                }
                                  cell.border = {
                                    top: { style: 'thin', color: { argb: 'FF000000' } },
                                    left: { style: 'thin', color: { argb: 'FF000000' } },
                                    bottom: { style: 'thin', color: { argb: 'FF000000' } },
                                    right: { style: 'thin', color: { argb: 'FF000000' } }
                                  };
                        
               /*                    const currentRow = newWorksheet.getRow(rowIndex + rowNumber + 1);
        const desiredHeight = 30; 
        console.log(currentRow.height);
        currentRow.height = desiredHeight;
        if (currentRow.height < desiredHeight) {
            currentRow.height = desiredHeight;
        }else{
            currentRow.height +=10
        }
 */
                                });
                                count+=1
                              });
                   }
                      
                });
                worksheet.columns.forEach((column, index) => {
                  const colNumber = index + 1;
                  const colWidth = column.width;
                  if (colWidth) {
                    newWorksheet.getColumn(colNumber).width = colWidth;
                  }
                });
      
                Object.keys(worksheet._merges).forEach((key) => {
                  const merge = worksheet._merges[key];
                  const model = merge.model;
                  
                  const topLeft = newWorksheet.getCell(model.top, model.left).address;
                  const bottomRight = newWorksheet.getCell(model.bottom, model.right).address;
            
                  try {
                    console.log(`${topLeft}:${bottomRight}`);
                    
                    newWorksheet.mergeCells(`${topLeft}:${bottomRight}`);
                  } catch (error) {
                    console.error(`Error merging cells ${topLeft}:${bottomRight}:`, error);
                  }
                });
                let cellsum  = newWorksheet.getCell(strat+count +1, 2);


                newWorksheet.mergeCells(`B${strat+count +7}:C${strat+count +7}`);
                newWorksheet.mergeCells(`E${strat+count +7}:G${strat+count +7}`);
                newWorksheet.mergeCells(`E${strat+count +8}:G${strat+count +8}`);
                newWorksheet.mergeCells(`E${strat+count +9}:G${strat+count +9}`);
                newWorksheet.mergeCells(`E${strat+count +10}:G${strat+count +10}`);



                cellsum.value = 'ความถี่ในการติดตามความเสี่ยง: ';
                cellsum.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '8ED973' }, 
                };
                cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                cellsum.border = {
                    top: { style: 'thin', color: { argb: 'FF000000' } },
                    left: { style: 'thin', color: { argb: 'FF000000' } },
                    bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    right: { style: 'thin', color: { argb: 'FF000000' } }
                  };
                let select = selectedData? selectedData : 'All'
                cellsum  = newWorksheet.getCell(strat+count +1, 3);
                cellsum.value = `${select}`;
                cellsum.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '8ED973' }, 
                };
                cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                cellsum.border = {
                    top: { style: 'thin', color: { argb: 'FF000000' } },
                    left: { style: 'thin', color: { argb: 'FF000000' } },
                    bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    right: { style: 'thin', color: { argb: 'FF000000' } }
                  };
                cellsum  = newWorksheet.getCell(strat+count +3, 2);

                cellsum.value = 'จำนวนความเสี่ยงทั้งหมด';
                cellsum.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'DAF2D0' }, 
                };
                cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                cellsum.border = {
                    top: { style: 'thin', color: { argb: 'FF000000' } },
                    left: { style: 'thin', color: { argb: 'FF000000' } },
                    bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    right: { style: 'thin', color: { argb: 'FF000000' } }
                  };

                cellsum  = newWorksheet.getCell(strat+count +3, 3);
               
                cellsum.value = { formula: `COUNTIF(K${strat}:K${strat+count},">=""")` };
                cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                cellsum.border = {
                    top: { style: 'thin', color: { argb: 'FF000000' } },
                    left: { style: 'thin', color: { argb: 'FF000000' } },
                    bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    right: { style: 'thin', color: { argb: 'FF000000' } }
                  };
                cellsum  = newWorksheet.getCell(strat+count +4, 2);

                cellsum.value = 'จำนวนความเสี่ยงที่เปิดอยู่';
                cellsum.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'DAF2D0' }, 
                };
                cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                cellsum.border = {
                    top: { style: 'thin', color: { argb: 'FF000000' } },
                    left: { style: 'thin', color: { argb: 'FF000000' } },
                    bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    right: { style: 'thin', color: { argb: 'FF000000' } }
                  };

                  cellsum  = newWorksheet.getCell(strat+count +4, 3);
                 
                  cellsum.value =  { formula: `COUNTIF(K${strat}:K${strat+count},"=OPEN")+COUNTIF(K${strat}:K${strat+count},"=NEW")+COUNTIF(K${strat}:K${strat+count},"=WATCH")` };
                  cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                  cellsum.border = {
                      top: { style: 'thin', color: { argb: 'FF000000' } },
                      left: { style: 'thin', color: { argb: 'FF000000' } },
                      bottom: { style: 'thin', color: { argb: 'FF000000' } },
                      right: { style: 'thin', color: { argb: 'FF000000' } }
                    };
                cellsum  = newWorksheet.getCell(strat+count +5, 2);

                cellsum.value = 'จำนวนความเสี่ยงที่ปิดแล้ว';
                cellsum.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'DAF2D0' }, 
                };
                cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                cellsum.border = {
                    top: { style: 'thin', color: { argb: 'FF000000' } },
                    left: { style: 'thin', color: { argb: 'FF000000' } },
                    bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    right: { style: 'thin', color: { argb: 'FF000000' } }
                  };

                  cellsum  = newWorksheet.getCell(strat+count +5, 3);
                 
                  cellsum.value =  { formula: `COUNTIF(K${strat}:K${strat+count},"=CLOSED")` };
                  cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                  cellsum.border = {
                      top: { style: 'thin', color: { argb: 'FF000000' } },
                      left: { style: 'thin', color: { argb: 'FF000000' } },
                      bottom: { style: 'thin', color: { argb: 'FF000000' } },
                      right: { style: 'thin', color: { argb: 'FF000000' } }
                    };

                    cellsum  = newWorksheet.getCell(strat+count +7, 2);
                    cellsum.value =  'จำนวน ระดับความเสี่ยง';
                    cellsum.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'DAF2D0' }, 
                    };
                    cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                    cellsum.border = {
                        top: { style: 'thin', color: { argb: 'FF000000' } },
                        left: { style: 'thin', color: { argb: 'FF000000' } },
                        bottom: { style: 'thin', color: { argb: 'FF000000' } },
                        right: { style: 'thin', color: { argb: 'FF000000' } }
                      };

                      cellsum  = newWorksheet.getCell(strat+count +8, 2);
                      cellsum.value =  'จำนวนระหว่าง 1 ถึง 8';
                      cellsum.fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '3DEB41' }, 
                      };
                      cellsum.alignment = { vertical: 'top', horizontal: 'left' };
                      cellsum.border = {
                          top: { style: 'thin', color: { argb: 'FF000000' } },
                          left: { style: 'thin', color: { argb: 'FF000000' } },
                          bottom: { style: 'thin', color: { argb: 'FF000000' } },
                          right: { style: 'thin', color: { argb: 'FF000000' } }
                        };

                        cellsum  = newWorksheet.getCell(strat+count +8, 3);
                      cellsum.value =  { formula: `COUNTIF(F${strat}:F${strat+count},">=1")-COUNTIF(F${strat}:F${strat+count},">=9")` };
                      cellsum.fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '3DEB41' }, 
                      };
                      cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                      cellsum.border = {
                          top: { style: 'thin', color: { argb: 'FF000000' } },
                          left: { style: 'thin', color: { argb: 'FF000000' } },
                          bottom: { style: 'thin', color: { argb: 'FF000000' } },
                          right: { style: 'thin', color: { argb: 'FF000000' } }
                        };

                        cellsum  = newWorksheet.getCell(strat+count +9, 2);
                        cellsum.value =  'จำนวนระหว่าง 9 ถึง 15';
                        cellsum.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFF00' }, 
                        };
                        cellsum.alignment = { vertical: 'top', horizontal: 'left' };
                        cellsum.border = {
                            top: { style: 'thin', color: { argb: 'FF000000' } },
                            left: { style: 'thin', color: { argb: 'FF000000' } },
                            bottom: { style: 'thin', color: { argb: 'FF000000' } },
                            right: { style: 'thin', color: { argb: 'FF000000' } }
                          };
  
                          cellsum  = newWorksheet.getCell(strat+count +9, 3);
                        cellsum.value =  { formula: `COUNTIF(F${strat}:F${strat+count},">=9")-COUNTIF(F${strat}:F${strat+count},">=16")` };
                        cellsum.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFF00' }, 
                        };
                        cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                        cellsum.border = {
                            top: { style: 'thin', color: { argb: 'FF000000' } },
                            left: { style: 'thin', color: { argb: 'FF000000' } },
                            bottom: { style: 'thin', color: { argb: 'FF000000' } },
                            right: { style: 'thin', color: { argb: 'FF000000' } }
                          };



                          cellsum  = newWorksheet.getCell(strat+count +10, 2);
                          cellsum.value =  'จำนวนระหว่าง 16 ถึง 25';
                          cellsum.fill = {
                              type: 'pattern',
                              pattern: 'solid',
                              fgColor: { argb: 'FF0000' }, 
                          };
                          cellsum.alignment = { vertical: 'top', horizontal: 'left' };
                          cellsum.border = {
                              top: { style: 'thin', color: { argb: 'FF000000' } },
                              left: { style: 'thin', color: { argb: 'FF000000' } },
                              bottom: { style: 'thin', color: { argb: 'FF000000' } },
                              right: { style: 'thin', color: { argb: 'FF000000' } }
                            };
    
                            cellsum  = newWorksheet.getCell(strat+count +10, 3);
                          cellsum.value =  { formula: `COUNTIF(F${strat}:F${strat+count},">=16")-COUNTIF(F${strat}:F${strat+count},">=26")` };
                          cellsum.fill = {
                              type: 'pattern',
                              pattern: 'solid',
                              fgColor: { argb: 'FF0000' }, 
                          };
                          cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                          cellsum.border = {
                              top: { style: 'thin', color: { argb: 'FF000000' } },
                              left: { style: 'thin', color: { argb: 'FF000000' } },
                              bottom: { style: 'thin', color: { argb: 'FF000000' } },
                              right: { style: 'thin', color: { argb: 'FF000000' } }
                            };




                            cellsum  = newWorksheet.getCell(strat+count +7, 5);
                            cellsum.value =  'คำอธิบายสี';
                            cellsum.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FBE2D5' }, 
                            };
                            cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                            cellsum.border = {
                                top: { style: 'thin', color: { argb: 'FF000000' } },
                                left: { style: 'thin', color: { argb: 'FF000000' } },
                                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                                right: { style: 'thin', color: { argb: 'FF000000' } }
                              };

                            cellsum  = newWorksheet.getCell(strat+count +8, 5);
                            cellsum.value =  'ต่ำ -  ให้ความใส่ใจ';
                            cellsum.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: '3DEB41' }, 
                            };
                            cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                            cellsum.border = {
                                top: { style: 'thin', color: { argb: 'FF000000' } },
                                left: { style: 'thin', color: { argb: 'FF000000' } },
                                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                                right: { style: 'thin', color: { argb: 'FF000000' } }
                              };
      
                              cellsum  = newWorksheet.getCell(strat+count +9, 5);
                            cellsum.value =  'กลาง - เฝ้าระวัง และควบคุม';
                            cellsum.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFF00' }, 
                            };
                            cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                            cellsum.border = {
                                top: { style: 'thin', color: { argb: 'FF000000' } },
                                left: { style: 'thin', color: { argb: 'FF000000' } },
                                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                                right: { style: 'thin', color: { argb: 'FF000000' } }
                              };
                              cellsum  = newWorksheet.getCell(strat+count +10, 5);
                              cellsum.value =  'สูง - อันตราย';
                              cellsum.fill = {
                                  type: 'pattern',
                                  pattern: 'solid',
                                  fgColor: { argb: 'FF0000' }, 
                              };
                              cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                              cellsum.border = {
                                  top: { style: 'thin', color: { argb: 'FF000000' } },
                                  left: { style: 'thin', color: { argb: 'FF000000' } },
                                  bottom: { style: 'thin', color: { argb: 'FF000000' } },
                                  right: { style: 'thin', color: { argb: 'FF000000' } }
                                };
    








                const buffer = await newWorkbook.xlsx.writeBuffer();
            
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, `Risk Management${moment(new Date()).format('DD/MM/yyyy')}.xlsx`);
            })
            .catch(function (error) {
                console.log(error);
            });
       
      }
    const fetchExcelFile = async (url) => {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        return arrayBuffer;
      };
return(<>

<ConfigProvider
    // theme={{
    //     token: {
    //         colorInfo: '#85BFFF',
    //         colorBorder: 'transparent', // เปลี่ยนสีขอบเป็นโปร่งใส
    //         colorText: '#FFF',
    //         colorTextQuaternary: '#FFFFFF', // สีตัวอักษร
    //         colorTextDescription: '#FFFFFF',
    //         colorBgContainer: '#16285C', // เปลี่ยนสีพื้นหลังเป็นโปร่งใส
    //         zIndexPopupBase: 999999,
    //         colorPrimaryBg: '#0047FF', // เปลี่ยนสีพื้นหลังหลักเป็นโปร่งใส
    //         colorBgBase: 'transparent', // เปลี่ยนสีพื้นฐานเป็นโปร่งใส
    //         colorFillTertiary: 'blue', // สีเติมที่สามเป็นโปร่งใส
    //         boxShadow: 'none', // กำจัดเงา
    //     }
    // }}
                theme={{
                    token: {
                        colorInfo: '#85BFFF',
                        colorBorder: '#85BFFF',
                        colorText: '#FFF',
                        colorTextQuaternary: 'White',//สีตัวอักษร
                        colorTextDescription: 'White',
                        colorBgContainer: '#16285C',
                        zIndexPopupBase: 999999,
                        colorPrimaryBg: '#0047FF',
                        colorBgBase: '#051F6C',
                        colorTextQuaternary: '#FFFFFF',
                        colorFillTertiary: 'blue',
                        colorTextBase: 'White',
                        colorPrimaryBg: '#85BFFF',

                    }
                }}
            >
    <div className='bgRiskManagement'>
        <div className='containerRiskManagement'>
            <div className='containerRiskManagement1'>
                <div>
              
            <img 

                onClick={() => {
                    window.location.href = `/StageMonitoring`
                }}
                style={{ 
                    cursor: 'pointer', 
                    // position: 'absolute', 
                    width: "70px", 
                    height: "70px", 
                    // marginTop: "8px",
                    border: "2px solid #fff",
                    borderRadius: "4px",
                    padding: "10px",
                    boxSizing: "border-box"
                }} 
                src={iconback_doorStage}
                />
                <div 
                style={{ 
                    position: 'absolute',       
                    top: '-1px',              
                    left: '90px',             
                    width: "10px",      
                    height: "72px",            
                    background: "linear-gradient(#0047FF, #002B99)" 
                }}
                ></div>
                <span 
                style={{ 
                    position: 'absolute',       
                    top: '8px',     
                    left: '110px',          
                    color: '#fff',           
                    fontSize: '40px',          
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    fontFamily: 'Oxanium'          
                }}
                >
                Risk Management - {idSpaceshipAndFederation.project_name}
                </span>
                </div>
                <div>
                <div className='boxNewRisk' style={{ width:"150px", justifyContent:"space-evenly"}}
                                onClick={()=>{
                                    excell(selectedValue)
                                }}
                                >
                                    <i className="fa fa-file-alt" style={{paddingTop:"3px"}}></i>
                                    <span style={{display:"flex", fontSize:"16px", fontWeight:500}}>REPORT RISK</span>
                                </div>
                </div>
            </div>
          
                <div style={{display:"flex", marginTop:"25px", justifyContent:"space-between"}}>
                <div style={{display:"flex"}}>
                    <div style={{boxShadow: "0 0 10px #227016", border: "2px solid #227016",backgroundColor:"#1e2a1e", width:"60px", height:"30px", borderRadius:"5px", marginTop:"-5px"}}>
                        <span style={{display:"flex" ,fontSize:"16px", fontWeight:500, color:"#fff", textAlign:"center", paddingTop:"2px",paddingLeft:"15px"}}>{"< 8"}</span>
                    </div>
                    <span style={{marginLeft:"5px"}}>ให้ความใส่ใจ</span>
                    <div style={{boxShadow: "0 0 10px #726c0d", border: "2px solid #726c0d",marginLeft:"5px",backgroundColor:"#363300", width:"60px", height:"30px", borderRadius:"5px", marginTop:"-5px"}}>
                        <span style={{fontFamily:"Oxanium", display:"flex" ,fontSize:"16px", fontWeight:500, color:"#fff", textAlign:"center", paddingTop:"2px",paddingLeft:"12px"}}>{"9-15"}</span>
                    </div>
                    <span style={{marginLeft:"5px"}}>เฝ้าระวัง และควบคุม</span>
                    <div style={{boxShadow: "0 0 10px #7b0505", border: "2px solid #7b0505", marginLeft:"5px",backgroundColor:"#3a0101", width:"60px", height:"30px", borderRadius:"5px", marginTop:"-5px"}}>
                        <span style={{display:"flex" ,fontSize:"16px", fontWeight:500, color:"#fff", textAlign:"center", paddingTop:"2px",paddingLeft:"8px"}}>{"16-25"}</span>
                    </div>
                    <span style={{marginLeft:"5px"}}>อันตราย</span>
                </div>
                    <div style={{paddingTop:"8px", backgroundColor:"#272727",width:"300px", height:"40px", textAlign:"center"}}>
                        <span style={{fontSize:"16px", fontWeight:500}}>ความถี่ในการติดตามความเสี่ยง:  </span>
                        <Dropdown overlay={menu}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                            {selectedValue ? ` ${selectedValue}` : 'All'}
                            <DownOutlined />
                            </Space>
                        </a>
                        </Dropdown>
                        </div>
                </div>

                <div style={{display:"flex", marginTop:"20px", justifyContent:"space-between", height:"130px", textAlign:"center"}}>
                    <div style={{display:"flex", width:"100%", height:"100%"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between",backgroundColor:"#1b1b1b",borderRadius:"5px",boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)", width:"100%"}}>
                        <div style={{paddingTop:"20px", fontSize:"36px"}}>{CountRiskManagement.AllCountRiskManagement}</div>    
                        <div style={{backgroundColor:"#1f2429",borderRadius:"5px", height:"30px", paddingTop:"5px"}}><span style={{color:"#0173ef",fontSize:"16px", fontWeight:700}}>จำนวนความเสี่ยงทั้งหมด</span></div> 
                        </div>
      
                    </div>
                    <div style={{display:"flex",width:"100%", height:"100%"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between",backgroundColor:"#1b1b1b",width:"100%",borderRadius:"5px",boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)", marginLeft:"20px"}}>
                        <div style={{paddingTop:"20px", fontSize:"36px"}}>{CountRiskManagement.UnCloseCountRiskManagement}</div>    
                        <div style={{backgroundColor:"#29291f",borderRadius:"5px", height:"30px", paddingTop:"5px"}}><span style={{color:"#c59f08",fontSize:"16px", fontWeight:700}}>จำนวนความเสี่ยงที่เปิดอยู่</span></div> 
                        </div>
                    </div>
                    <div style={{display:"flex",width:"100%", height:"100%"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", borderRadius:"5px", backgroundColor:"#1b1b1b",width:"100%",boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)", marginLeft:"20px"}}>
                        <div style={{paddingTop:"20px", fontSize:"36px"}}>{CountRiskManagement.CloseCountRiskManagement}</div>    
                        <div style={{backgroundColor:"#222229", borderRadius:"5px", height:"30px", paddingTop:"5px"}}><span style={{color:"rgb(88,86,214)",fontSize:"16px", fontWeight:700}}>จำนวนความเสี่ยงที่ปิดแล้ว</span></div> 
                        </div>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:"flex-end"}}>
                    {SaveRiskManagement.mode ? 
                            (<>
                                <div className='boxNewRisk' 
                                style={{ width: "80px", pointerEvents: isDisabled ? 'none' : 'auto', opacity: isDisabled ? 0.5 : 1 }}
                                onClick={()=>{
                                    if (!isDisabled) {
                                        toggle_modal_confrimModal();
                                    }
                                }}
                                >
                                    <img src={iconSaveRisk} style={{width:"20px", height:"20px"}}/>
                                    <span style={{display:"flex", fontSize:"16px", fontWeight:500, paddingLeft:"5px"}}>SAVE</span>
                                </div>

                                <div className='boxNewRisk' style={{ marginLeft:"15px", width:"80px", paddingLeft:"20px"}}
                                onClick={()=>{
                                    setSaveRiskManagementList({});
                                    onClickBackAddRisk();

                                }}
                                >
                                    <span style={{display:"flex", fontSize:"16px", fontWeight:500}}>BACK</span>
                                </div>
                                </>)
                            :
                            (<div className='boxNewRisk'style={{width: "120px"}} 
                                onClick={()=>{
                                onClickNewRisk();
                            }}
                            >
                            <img src={iconPlus} style={{width:"20px", height:"20px"}}/>
                            <span style={{display:"flex", fontSize:"16px", fontWeight:500, paddingLeft:"5px"}}>NEW RISK</span>
                            </div>  )  
                    }
                </div>
     
                {/* <div className='row mt-2' style={{
                    height:"100%", 
                    display:"flex", 
                    gap:"20px",
                    // overflow:"auto"
                    }}> */}
                {SaveRiskManagement.mode ? 
                
                <div  
                className='row mt-2'
                style={{
                    height:"100%", 
                    display:"flex", 
                    gap:"20px",
                    overflow:"auto"
                    }}>
                    <div className='container-add-risk'>
                    {/* <div style={{
                        // border: "1px solid red", 
                        gap:"15px",
                        width: "100%", 
                        height: "100%", 
                        display: "flex", 
                        flexDirection: "column",
                        boxSizing: "border-box", // ป้องกันการขยายเกินขอบ
                    }}> */}
                        <div style={{ padding: "1%" }}>
                            <span>Risk Management Plan</span>
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <div style={{ border: "1px solid #0047FF", width: "100%" }}></div>    
                            </div>
                        </div>

                        <div style={{ 
    paddingLeft: "1%", 
    width: "100%", 
    display: "flex", 
    flexWrap: "wrap", /* เพิ่ม flexWrap เพื่อให้สามารถซ้อนกันได้เมื่อหน้าจอแคบ */
    gap: "10px" /* เพิ่มช่องว่างระหว่างคอลัมน์ */
}}>
    <div style={{ 
        width: "100%", 
        maxWidth: "200px", 
        display: "flex", 
        flexDirection: "column", 
        flex: "1 1 200px", /* กำหนด flex เพื่อให้ปรับขนาดตามความต้องการ */
        minWidth: "150px" /* กำหนดขนาดขั้นต่ำเพื่อป้องกันขนาดที่เล็กเกินไป */
    }}>
        <span>Risk Category</span>
        <ConfigProvider
            theme={{
                token: {
                    colorInfo: '#85BFFF',
                    colorBorder: 'transparent',
                    colorText: '#FFF',
                    colorTextQuaternary: '#FFFFFF',
                    colorTextDescription: '#FFFFFF',
                    colorBgContainer: '#16285C',
                    zIndexPopupBase: 999999,
                    colorPrimaryBg: '#0047FF',
                    colorBgBase: 'transparent',
                    colorFillTertiary: 'blue',
                    boxShadow: 'none',
                }
            }}>
            <TreeSelect
                className='testSelect-01-Risk'
                value={SaveRiskManagement.rmr_rc_id}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto', minWidth: '200px' }}
                allowClear
                onChange={onChange}
                disabled={SaveRiskManagement.mode_status === "view" ? true : false}
                style={{ width: '100%' }} /* ใช้ width 100% เพื่อให้ขยายตาม container */
                treeExpandedKeys={expandedKeys}
                onExpand={(keys) => setExpandedKeys(keys)}
            >
                {optionRiskCategoty.map((parent) => (
                    <TreeNode
                        value={parent.rc_id}
                        title={<span onClick={() => handleTitleClick(parent.rc_id)}>{parent.rc_name}</span>}
                        key={parent.rc_id}
                        selectable={false}
                    >
                        {parent.arr_level2.map((child) => (
                            <TreeNode
                                value={child.rc_id}
                                title={child.rc_name}
                                key={child.rc_id}
                            />
                        ))}
                    </TreeNode>
                ))}
            </TreeSelect>
        </ConfigProvider>
    </div>

    <div style={{ 
        width: "100%", 
        maxWidth: "150px", 
        display: "flex", 
        flexDirection: "column", 
        paddingLeft: "1%", 
        flex: "1 1 150px", 
        minWidth: "120px" 
    }}>
        <span>โอกาสเกิด</span>
        <Select
            className='testSelect-01'
            options={optionProbability}
            value={SaveRiskManagement.rmr_probability}
            disabled={SaveRiskManagement.mode_status === "view" ? true : false}
            onChange={(e) => {
                setSaveRiskManagementList((prevState) => ({
                    ...prevState,
                    rmr_probability: e || 0,
                }));
            }}
        />
    </div>

    <div style={{ 
        width: "100%", 
        maxWidth: "150px", 
        display: "flex", 
        flexDirection: "column", 
        paddingLeft: "1%", 
        flex: "1 1 150px", 
        minWidth: "120px" 
    }}>
        <span>ระดับผลกระทบ</span>
        <Select
            className='testSelect-01'
            options={optionProbability}
            value={SaveRiskManagement.rmr_impact}
            disabled={SaveRiskManagement.mode_status === "view" ? true : false}
            onChange={(e) => {
                setSaveRiskManagementList((prevState) => ({
                    ...prevState,
                    rmr_impact: e || 0,
                }));
            }}
        />
    </div>

    <div style={{ 
        display: "flex", 
        alignContent: "center", 
        marginLeft: "20px", 
        marginTop: "30px" 
    }}>
        <span>=</span>
    </div>

    <div style={{ 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center", 
        width: "100%", 
        maxWidth: "150px", 
        flex: "1 1 100px", 
        minWidth: "100px" 
    }}>
        <span style={{ fontSize: "14px", fontWeight: 500 }}>ระดับความเสี่ยง</span>
        <div 
            className={getRiskClassName(SaveRiskManagement.rmr_probability * SaveRiskManagement.rmr_impact)}
            style={{
                width: "35px", 
                height: "35px",
                color: '#ffffff',
                borderRadius: "50px",
                textAlign: "center",
                paddingTop: "6px"
            }}>
            {SaveRiskManagement.rmr_probability * SaveRiskManagement.rmr_impact || 0}
        </div>
    </div>

    <div style={{ 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center", 
        width: "100%", 
        maxWidth: "200px", 
        flex: "1 1 200px", 
        minWidth: "80px" 
    }}>
        <span style={{ fontSize: "14px", fontWeight: 500 }}>การจัดการความเสี่ยง</span>
        <div
            className={getRiskClassName(SaveRiskManagement.rmr_probability * SaveRiskManagement.rmr_impact)} 
            style={{
                width: "80%", 
                height: "25px",
                color: '#ffffff',
                borderRadius: "10px",
                textAlign: "center",
                marginTop: "7px"
            }}>
            <span style={{ fontSize: "14px", fontWeight: 500 }}>
                {getRiskManagementName(SaveRiskManagement.rmr_probability, SaveRiskManagement.rmr_impact)}
            </span>
        </div>
    </div>
</div>


                        <div style={{ padding: "1%", flexGrow: 1 }}>
                            <div><span style={{ fontSize: "14px", fontWeight: 500 }}>Risk Descriptions</span></div> 
                            <textarea
                             disabled={SaveRiskManagement.mode_status === "view" ? true :false}
                                style={{ width: "99%", height: "100%", boxSizing: "border-box" }}
                                className="areaTEst rezieznone"
                                type="text"
                                value={SaveRiskManagement.rmr_descriptions}
                                onChange={(e) => {
                                    setSaveRiskManagementList({ ...SaveRiskManagement, rmr_descriptions: e.target.value });
                                }}
                            />
                        </div>

                        <div style={{ padding: "1%", flexGrow: 1 }}>
                            <div><span style={{ fontSize: "14x", fontWeight: 500 }}>แผนจัดการความเสี่ยง</span></div> 
                            <textarea
                             disabled={SaveRiskManagement.mode_status === "view" ? true :false}
                                style={{ width: "99%", height: "90%", boxSizing: "border-box" }}
                                className="areaTEst rezieznone"
                                type="text"
                                value={SaveRiskManagement.rmr_mitigation_plan}
                                onChange={(e) => {
                                    setSaveRiskManagementList({ ...SaveRiskManagement, rmr_mitigation_plan: e.target.value });
                                }}
                            />
                        </div>

                        <div style={{ padding: "1%", flexGrow: 1,/*  marginTop:"-1px" */}}>
                            <div><span style={{ fontSize: "14px", fontWeight: 500 }}>แผนฉุกเฉิน</span></div> 
                            <textarea
                             disabled={SaveRiskManagement.mode_status === "view" ? true :false}
                                style={{ width: "99%", height: "90%", boxSizing: "border-box" }}
                                className="areaTEst rezieznone"
                                type="text"
                                value={SaveRiskManagement.rmr_contingency_plan}
                                onChange={(e) => {
                                    setSaveRiskManagementList({ ...SaveRiskManagement, rmr_contingency_plan: e.target.value });
                                }}
                            />
                        </div>
                    {/* </div> */}

                        {/* <div style={{
                            // border: "1px solid red",
                            width: "100%",
                            display: "flex",
                            gap: "10px",
                            height: "40%",
                            flexDirection: "column",
                            boxSizing: "border-box",
                            overflow: "hidden",  
                             }}> */}
                            <div>
                            <span style={{paddingLeft:"1%"}}>Risk Tracking </span>
                            <div style={{display:"flex", justifyContent:"space-around"}}>
                                <div style={{border: "1px solid #0047FF", width:"98%"}}></div>    
                            </div>
                      
                            </div>

                            <div style={{
                                display:"flex", 
                                width:"50%", 
                                paddingLeft:"1%", 
                                gap:"20px",
                                // maxWidth: "100%",
                                // flexWrap: "wrap",
                                }}>

                            <div style={{width:"30%"}}>
                            <div style={{display:"flex", flexDirection:"column", flexGrow:1}}>
                                      <span style={{fontSize:"14px", fontWeight:500}}>Target Date</span>
                                      <StyledDatePicker
                                          className="dateText3"
                                          value={SaveRiskManagement.rmr_target_date ? dayjs(SaveRiskManagement.rmr_target_date) : null}
                                          placeholder="วว/ดด/ปปปป"
                                          format={"DD/MM/YYYY"}
                                          disabled={SaveRiskManagement.mode_status === "view" ? true :false}
                                          onChange={(date, dateString) => {

                                            setSaveRiskManagementList((prevState) => ({
                                                ...prevState,
                                                rmr_target_date: date ? date.$d : null,
                                            }));
                                        }}
                                          
                                      />
                                  </div>
                                  </div>

                                  <div style={{width:"30%"}}>
                                  <div style={{display:"flex", flexDirection:"column", flexGrow:1}}>
                                      <span style={{fontSize:"14px", fontWeight:500}}>สถานะ</span>
                                      <Select

                                                  className='testSelect-01'
                                                  showSearch
                                                  options={optionRiskManagementStatus2}
                                                  disabled={SaveRiskManagement.mode_status === "view" ? true :false}
                                                 value={optionRiskManagementStatus2.filter((e) => { return e.value === SaveRiskManagement.rmr_rms_id_2 })}
                                                 onChange={(e) => {
                                                    setSaveRiskManagementList((prevState) => ({
                                                        ...prevState,
                                                        rmr_rms_id_2: e,
                                                    }));
                                                }}
                                              />
                       
                                  </div>
                                  </div>

                                  <div style={{width:"30%"}}>
                                  <div style={{display:"flex", flexDirection:"column", flexGrow:1}}>
                                      <span style={{fontSize:"14px", fontWeight:500}}>วันที่ปิดความเสี่ยง</span>
                                      <StyledDatePicker
                                          className="dateText3"
                                          value={SaveRiskManagement.rmr_closed_date ? dayjs(SaveRiskManagement.rmr_closed_date) : null}
                                          placeholder="วว/ดด/ปปปป"
                                          format={"DD/MM/YYYY"}
                                          disabled={SaveRiskManagement.mode_status === "view" ? true :false}
                                          onChange={(date, dateString) => {

                                            setSaveRiskManagementList((prevState) => ({
                                                ...prevState,
                                                rmr_closed_date: date ? date.$d : null,
                                            }));
                                        }}
                                      />
                                  </div>
                            </div>
                            </div>
                            <div style={{ 
                                // marginTop:"-20px", 
                                padding: "1%", 
                                flexGrow: 1,
                                // maxWidth: "100%",
                                // overflow: "hidden",
                                }}>
                            <div><span style={{ fontSize: "14px", fontWeight: 500 }}>Remark</span></div> 
                            <textarea
                                style={{ width: "99%", height: "90%", boxSizing: "border-box" }}
                                className="areaTEst rezieznone"
                                type="text"
                                disabled={SaveRiskManagement.mode_status === "view" ? true :false}
                                value={SaveRiskManagement.rmr_remark}
                                onChange={(e) => {
                                    setSaveRiskManagementList({ ...SaveRiskManagement, rmr_remark: e.target.value });
                                }}
                            />
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                    </div> 
                    </div> 
                    :
                    <>
                <div className='row mt-2' style={{
                    height:"100%", 
                    display:"flex", 
                    gap:"20px",
                    // overflow:"auto"
                    }}>
                <div className="risk-table-container">
                <table className="risk-table">
                <thead>
                <tr>
                    <th rowSpan="2"> <span style={{fontSize:"14px", fontWeight:500}}>No.</span></th>
                    <th rowSpan="2"><span style={{fontSize:"14px", fontWeight:500}}>Risk Category</span></th>
                    <th rowSpan="2"> <span style={{fontSize:"14px", fontWeight:500}}>Risk Descriptions</span></th>
                    <th rowSpan="2"> <span style={{fontSize:"14px", fontWeight:500}}>การจัดการความเสี่ยง</span></th>
                    <th rowSpan="2"> <span style={{fontSize:"14px", fontWeight:500}}>แผนจัดการความเสี่ยง</span></th>
                    <th colSpan="3"><span style={{fontSize:"14px", fontWeight:500}}>การติดตามความเสี่ยง</span></th>
                    <th rowSpan="2"><span style={{fontSize:"14px", fontWeight:500}}>Management</span></th>
                </tr>
                <tr>
                    <th><span style={{fontSize:"14px", fontWeight:500}}>Target Date</span></th>
                    <th><span style={{fontSize:"14px", fontWeight:500}}>สถานะ</span></th>
                    <th><span style={{fontSize:"14px", fontWeight:500}}>วันที่ปิดความเสี่ยง</span></th>
                </tr>
                </thead>
               {RiskManagementList.map((item, index)=> (  
        <tbody key={item.rmr_id}>
       <tr>
    <td style={{ textAlign: "center", verticalAlign: "top" }}>
        <div><span style={{ fontSize: "14px", fontWeight: 500 }}>{index + 1}</span></div>
    </td>
    <td style={{ verticalAlign: "top" }}>
        <span style={{ fontSize: "14px", fontWeight: 500 }}>{item.rc_name}</span>
    </td>
    <td style={{ verticalAlign: "top" }}>
        <span style={{ fontSize: "14px", fontWeight: 500 }}>{item.rmr_descriptions}</span>
    </td>
    <td style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", verticalAlign: "top" }}>
        <div
            className={getRiskClassName(item.rmr_impact * item.rmr_probability)}
            style={{
                width: "100%",
                height: "25px",
                color: '#ffffff',
                borderRadius: "10px",
                textAlign: "center",
            }}>
            <span style={{ fontSize: "14px", fontWeight: 500 }}>
                {getRiskManagementName(item.rmr_impact, item.rmr_probability)}
            </span>
        </div>
    </td>
    <td style={{ verticalAlign: "top" }}>
        <span style={{ fontSize: "14px", fontWeight: 500 }}>{item.rmr_mitigation_plan}</span>
    </td>
    <td style={{ textAlign: "center", verticalAlign: "top" }}>
        <span style={{ fontSize: "14px", fontWeight: 500 }}>{item.rmr_target_date == null ? "" : moment(item.rmr_target_date).format('DD/MM/YYYY')}</span>
    </td>
    <td style={{ textAlign: "center", verticalAlign: "top" }}>
        <span style={{ color: getRiskStatus(item.rms_name), fontSize: "14px", fontWeight: 500 }} className="status closed">{item.rms_name}</span>
    </td>
    <td style={{ textAlign: "center", verticalAlign: "top" }}>
        <span style={{ fontSize: "14px", fontWeight: 500 }}>{item.rmr_closed_date == null ? "" : moment(item.rmr_closed_date).format('DD/MM/YYYY')}</span>
    </td>
    <td style={{ textAlign: "center", verticalAlign: "top" }}>
        <div style={{ display: "flex", gap: "10px", justifyContent: "space-evenly" }}>
            <span className="icon-container-risk"
                onClick={() => {
                    getByIdRecordRiskmanagement(item.rmr_id, "view");
                }}
            ><i style={{ fontSize: "14px" }} className="fa fa-eye"></i></span>
            <span className="icon-container-risk"
                onClick={() => {
                    getByIdRecordRiskmanagement(item.rmr_id, "edit");
                }}
            ><i style={{ fontSize: "14px" }} className="fa fa-pen"></i></span>
            <span className="icon-container-risk"
                onClick={() => {
                    setDelRiskManagementList({
                        ...DelRecordRiskmanagement,
                        rmr_id: item.rmr_id,
                        rmr_pri_id: item.rmr_pri_id
                    });
                    toggle_modal_confrimModalDel();
                }}
            ><i style={{ fontSize: "14px" }} className="fas fa-trash"></i></span>
        </div>
    </td>
</tr>

        </tbody>
        ))}
                </table>
           
                </div>
                <div style={{display: "flex", width:"100%", justifyContent:"flex-end",/*  marginTop:"20px" */}}>
                       <Pagination 
                       defaultCurrent={currentPage} 
                       total={CountRiskManagement.AllCountRiskManagement}
                       pageSize={pageSize} 
                       onChange={handlePageChange} 
                       />
                </div>
                </div>
                 
            </>
                }
               
     
            {/* </div> */}

        </div>
       
    </div>
    <ComponentAlertRiskDel
                isOpen={displaySwalDel}
                mainFuc={() => DelRecordRiskmanagement()}
                toggle_alertKick={() => toggle_modal_confrimModalDel()}
                
            />
    <ModelAlertMassge
                isOpen={alertKickMassgeDel}
                toggle_alertKick={() => { toggle_modal_alertKickMassgeDel() }}
                message={messageBoxDel}
                mainFuc={() => { filterRiskManagement(); }}
            />
    <ModelAlertConfrim
                isOpen={displaySwal}
                mainFuc={() => saveRecordRiskmanagement()}
                toggle_alertKick={() => toggle_modal_confrimModal()}
                
            />
    <ModelAlertMassge
                isOpen={alertKickMassge}
                toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
                message={messageBox}
                mainFuc={() => { filterRiskManagement(); }}
            />


    {loading && (
        <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox' style={{ height: 'fit-content' }}>
              <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: 'green' }}>
                    Loading
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning' style={{ border: 'none' }}>
                    <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ConfigProvider>
</>)
}
export default RiskManagement;