import React, { useState, useEffect, useRef } from 'react'
import Getimg from './getimgshow';

function ElementScreenshotPopup({
    isopen,
    data_element_screenshot_version ,
    closePopup
}) {

    const [dataVersion,setDataVersion] = useState([]);
    const [openPopup,setOpenPopup] = useState(false);
    
    console.log("dataVersion",dataVersion);
    
    useEffect(() => {
      console.log('isopen',isopen);
      console.log('data_element_screenshot_version',data_element_screenshot_version);
      if (isopen == true) {
        let item = []
        item.push(data_element_screenshot_version)
        setDataVersion(item);
        console.log('dataVersion',dataVersion);
        
      /* setDataVersion(data_element_screenshot_version) */
      /*   let temp_sort = [];
        for (let item of data_element_screenshot_version) {
          console.log(item);
          let temp = {
            version_view: item.version,
            remark_view: item.remark,
            path_view: item.path,
            name_view: item.name,
            box_path: item?.box_path,
            pathbox: item?.pathbox,
          }
          temp_sort.push(temp);
      }
      let data_sort = temp_sort.sort((a, b) => b.version_view - a.version_view);
      setDataVersion(data_sort); */
    } else {
      setDataVersion([]);
    }
    }, [isopen && data_element_screenshot_version])

  return (
    <>
      <div style={{zIndex:'99999',position: 'absolute',color:'#fff',width:'fit-content',height:'fit-content',top:'-21%',padding:'5px 5px',backgroundColor:'rgb(255,0,181)'}}>VIEW VERSION.</div>
      <div style={{width:'100%',height:'135%',zIndex:'99999',position: 'relative',top:'-16%',background: 'rgb(255,0,181)',background:'linear-gradient(180deg, rgba(255,0,181,1) 0%, rgba(0,71,255,1) 100%)',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <div style={{width:'99.7%',height:'99.5%',border:'1px solid  #0047FF',backgroundColor:'#000',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <div style={{width:'95%',height:'80%',border:'1px solid  #0047FF'}}>
            {dataVersion.map((allitem, index) => {
              
              let item = allitem.data[allitem.version - 1]
              return (
                <div style={{width:'100%',height:'90%',border:'1px solid  #0047FF'}}>
                  <Getimg
                    img={item.box_path? item.box_path : item.pathbox? item.pathbox : item.path}
                    isnew={(item.byid !== undefined && item.byid === true) && !item.box_path? false : true}
                    style={{ height: '100%', width: '100%', cursor: 'pointer', objectFit: 'contain' }}
                    callback={(result) => {
                      allitem.data[allitem.version - 1].box_path = result
                      allitem.loading = true     
                  }}
                  />
                  <div style={{width:'100%',height:'10%',border:'1px solid  #0047FF',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div style={{width:'88%'}} className="imgMockupCardDescription2">{item.remark || 'description.....'}</div>
                    <div className="TagVersion2" >VER.{allitem.version}</div>
                  </div>
                </div>
              )
            }
            )}
             
          
          
        </div>
        <div onClick={closePopup} style={{cursor: 'pointer', color:'red',border:'1px solid red',height:'fit-content',width:'5%',position:'absolute',bottom:'3%',right:'2.8%',textAlign:'center',padding:'5px 10px'}}>
          Cancel
        </div>
        </div>
        
        {/* <botton style={{width:'100%',height:'135%',border:'1px solid  #0047FF',zIndex:'99999',position: 'relative',top:'-16%'}} onClick={closePopup}>
        X
        </botton> */}
      </div>
    </>
    
  )
}

export default ElementScreenshotPopup