
import React, { useEffect, useState, useRef } from 'react';

import {
    CheckOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import icon from './iconSpace.png';
import axios from "axios";
import Configs from "../../../config";
import "./yourskill.css"

function CompleteYourSkill({ isOpen, toggle_openModalCompleteSkill_modal, isRefsSkill, setIsRefsSkill, personalInfoCheck }) {
    const [openSpace, setOpenSpace] = useState(false);
    const [countComplete, setCountComplete] = useState(0);
    const [countNotComplete, setCountNotComplete] = useState(0);
    const [countSusccComplete, setCountSusccComplete] = useState(0);
    const [totalPercentage, setTotalPercentage] = useState(0);
    const [dataCompleteSkill, setDataCompleteSkill] = useState([]);


    function GetCompleteSkill(id) {
        var temp = {
            user_id: sessionStorage.getItem('user_id')
        }
        axios({
            method: "post",
            url: `${Configs.TTT_Game_Service}/api/completeSkill/getCompleteSkill`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                let temp = response.data.completeData
                setDataCompleteSkill(response.data.completeData || []);
                setCountComplete(response.data.countList || 0);
                setCountNotComplete(response.data.countListNotSuscce || 0);
                setTotalPercentage(response.data.totalPercentage || 0);
                setCountSusccComplete(response.data.countListSuscce || 0);
                let Checked = temp.map(item => item.guide_is_check);
                let nulls = Checked.filter(item => item === null);
                let nonNulls = Checked.filter(item => item !== null);
                let result = [...nulls, ...nonNulls];
                console.log('resultresultresult',result);
                
                let allChecked = result.every(e => e == true);

                let getIsSkill = sessionStorage.getItem('is_skill')
                console.log('testttt',getIsSkill);
        
                if(allChecked == false && getIsSkill == 'true'){
                    setOpenSpace(true);
                    sessionStorage.setItem('is_skill', false);
                    console.log( "ไม่ check ทุกช่อง");
                    
                }else if(allChecked == true && getIsSkill == 'true'){
                    setOpenSpace(false);
                    sessionStorage.setItem('is_skill', false);
                    console.log( "check ทุกช่อง");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function checkTrue(){
        
       
    }


    useEffect(() => {
        
        GetCompleteSkill();
        

        
        /* console.log('testtttCheckedChecked',Checked);
        console.log('testtttresult',result); */
        /* console.log('testttt',allChecked); */

       

        

  
       /*  if(getIsSkill == 'true' || getIsSkill == true && openSpace == false){
            setOpenSpace(true)
            
        } */
      
        setIsRefsSkill(false)
    }, [isRefsSkill, personalInfoCheck])



    return (<>
        <div className={`modal-open-skill-complete${!openSpace ? '-none' : ''}`} >
            <div className={`dive${openSpace ? '-h' : '-n'}`} >
                <div className={`test${openSpace ? '-h' : '-n'}`}>
                    <div className={`bg-min${openSpace ? '-1' : '-2'}`} onClick={() => { setOpenSpace(!openSpace) }}>
                        <img src={icon} className='icon-space' />
                        <span className='textCount'> {countNotComplete} </span>

                    </div>
                    {!openSpace ? ' ' : <div className='' style={{ position: 'absolute', top: '-10px', right: '0', height: '100%', width: '40px', display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{ fontSize: '30px', width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-end' }}
                            onClick={() => { setOpenSpace(!openSpace) }}
                        > - </div>
                    </div>}
                    {!openSpace ? ' ' : <span style={{ fontWeight: '700', color: '#FFF' }}> COMPLETE YOUR SKILL </span>}
                </div>
                <div className={`test${openSpace ? '-bh' : '-bn'}`}>
                    <div className='row'>
                        <div className='control-area' style={{ display: `${openSpace ? '' : 'none'}`, paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden' }}>
                                {dataCompleteSkill ? dataCompleteSkill.map((item, index) => {
                                /* console.log('temp-------------',item); */
                                return (<>
                                    <div className='row' style={{ padding: '5px', gap: '10px', whiteSpace: 'nowrap', fontSize: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden' }}>
                                        <div style={{ width: '20px' }} className={`box-check${item.guide_is_check ? '-check' : ''}`}> {item.guide_is_check ? <CheckOutlined /> : ''} </div>
                                        <div style={{ width: '250px', color: '#FFFF' }} className='text-body-data'> {item.guide_name} </div>
                                    </div>
                                </>)
                            }) : ''}
                        </div>
                        <div className='control-footter' style={{ display: `${openSpace ? '' : 'none'}`, paddingLeft: '20px', paddingRight: '20px', paddingTop: '8px', paddingBottom: '10px' }}>
                            <div className='row mt-1'>
                                <div className='text-persion'>
                                    {`${countSusccComplete} / ${countComplete}`}
                                </div>
                            </div>
                            <div className='row mt-1' style={{ padding: '0', margin: '0' }}>
                                <div className='box-feck-persin'></div>
                                <div className='box-realpersin' style={{ visibility:`${parseInt(totalPercentage || 0) > 0 ? '' : 'hidden'}`, width: `${totalPercentage}%` }}></div>
                            </div>
                            <div className='row'>
                                <div className='btn-go-to-acardame' style={{whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'}}>
                                    <div className='row' style={{gap:'5px'}}>
                                        <div className='' style={{width:'30px',height:'26px',backgroundColor:'rgb(1, 28, 86)' ,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'8px'}}>
                                            <LogoutOutlined style={{ transform: 'rotate(180deg)', fontSize: '15px' }} />
                                        </div>
                                       <div className='' style={{display:'flex',justifyContent:'center',alignItems:'center',justifyItems:'center',padding:'2px'}}>
                                       TTT SPACESHIP ACADEMY
                                       </div>
                                      
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

        </div>
    </>)
}


export default CompleteYourSkill;