import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Configs from "../../../../config";
import bgTop from "./img/bgTopNew.png"
import backIcon from "./img/Icon.png";
import IconElementTest from "./img/Frame 238657.png"
import '../addElement/elementLogTransaction.css';
import sapphireIcon from "./img/Frame.png"
import CalendaElementLogTransaction from "./img/Calenda.png"
import moment from "moment";
import { Tooltip } from 'antd';

function ElementLogTransaction({ isOpen, toggle }) {

    const [ItemElementLogTransaction, setItemElementLogTransaction] = useState([]);
    const [test, setTest] = useState({
        test: ''
    });


    useEffect(() => {
        if (isOpen) {
            GetAccesstokenToken((result) => {
                if (result) {
                    getItemElementLogTransaction(1, 9999);
                };
            });
        }
    }, [isOpen])

    function numberWithFormat(value) {
        let set = 0;
        if (value >= 1000000) {
            set = `${(parseFloat((value) / 1000000).toFixed(2))}M`;
        } else if (value >= 1000) {
            set = `${(parseFloat((value) / 1000).toFixed(2))}K`;
        } else {
            set = parseFloat(value || 0).toFixed(2);
        }
        return set
    }
    async function GetAccesstokenToken(callback) {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {

                if (callback) {
                    callback(response.data.tokenBox);
                }
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    // console.log("ItemElementLogTransaction: ",ItemElementLogTransaction);


    function getItemElementLogTransaction(page, enties) {
        let temp = {
            usr_id: sessionStorage.getItem('user_id')
        }
        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/element/getElementLogTransaction`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                setItemElementLogTransaction(response.data);
                for (let item of response.data) {
                    if (item.fd_img_id) {
                        let response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                            headers: {
                                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                            },
                            responseType: 'blob',
                        });

                        item.elm_img_path = URL.createObjectURL(response2.data);

                        setItemElementLogTransaction(response.data);
                        setTest({ ...test, test: '' });
                        test.test = '';
                    }
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <>{ isOpen &&
            <div className="ElementLogTransactionBg">
                <div className="ElementLogTransactionBgSet" style={{ backgroundImage: `url(${bgTop})` }}>
                    <div className='ElementLogTransactionHeader' style={{ margin: 0, padding: 0 }}>
                        <div className="ElementLogTransactionHeaderLeft">
                            <div className='ElementLogTransactionBack' onClick={() => {
                                //   window.location.href = `/`
                                toggle();
                            }}>
                                <img src={backIcon} style={{ width: '100%', height: '100%' }}></img>
                            </div>
                            <div className="ElementLogTransactionHeadLine"></div>
                            <div className="ElementLogTransactionHeaderName">ประวัติการสั่งซื้อ</div>
                        </div>


                    </div>
                    <div className='ElementLogTransactionContainer'>
                        {ItemElementLogTransaction.map((card, index) => (
                            <div key={index} className='ElementLogTransactionCardBg'>
                                <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "space-between", padding: "1% 2.5%" }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "60%" }}>
                                        <div>
                                            <img style={{ width: "23px", height: "23px", marginTop: "-10px" }} src={CalendaElementLogTransaction} />
                                            <span style={{ fontSize: "16px", fontWeight: 500, paddingLeft: "5px" }}>{moment(card.elt_created_date).format('DD/MM/yyyy')}</span>
                                            <span style={{ fontSize: "20px", fontWeight: 500, color: "red", paddingLeft: "20px" }}>Buy Element</span>
                                        </div>

                                        <div style={{ display: "flex", gap: "15px", height: "80%" }}>
                                            <div className='ElementLogTransactionDataBodyImg'>
                                                {card.elm_img_path ? <img className='ElementLogTransactionPriceIcon1'  src={card.elm_img_path}></img> :
                                                <><div style={{width:'48px'}}> </div> </>}
                                                
                                            </div>
                                            <div style={{ 
                                                display: "flex", 
                                                flexDirection: "column",  
                                                }} >
                                                <span style={{ fontSize: "24px", fontWeight: 700 }}>
                                                    {card.elm_name}
                                                </span>
                                                <Tooltip placement="topLeft" title={card.elm_detail} overlayClassName="momTabCustomTooltip">
                                                <span style={{
                                                fontSize: '16px',
                                                fontWeight: 500,
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'normal',
                                                maxHeight: '3em'
                                                }}>
                                                {card.elm_detail}
                                                </span>
                                                </Tooltip>
                                           
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "10px", width: "18%" }}>
                                        <div style={{ backgroundColor: "#181818", height: "40px", paddingTop: "8px", textAlign: "center", display: "flex", justifyContent: "space-around", borderRadius: "8px" }}>
                                            <div style={{flexGrow:1, width:"40%"}}>  <span style={{ fontSize: "16px", fontWeight: 700,}}>ยอดปัจจุบัน</span> </div>
                                            <div style={{flexGrow:1, width:"20%"}}>  <img style={{ width: "15px", height: "20px",}} src={sapphireIcon} /></div>
                                            <div style={{flexGrow:1, width:"30%"}}>
                                            <Tooltip placement="topLeft" title={numberWithCommas(Number(card.elt_current_sapphire))} overlayClassName="momTabCustomTooltip">
                                            <span style={{ fontSize: "16px", fontWeight: 700,}}>{numberWithFormat(Number(card.elt_current_sapphire))}</span>
                                            </Tooltip>
                                              
                                                </div>
                                          
                                          
                                            
                                        </div>
                                        <div style={{ backgroundColor: "#341b1b", height: "40px", paddingTop: "8px", textAlign: "center", display: "flex", justifyContent: "space-around", borderRadius: "8px" }}>
                                            <div style={{flexGrow:1, width:"40%"}}> <span style={{ fontSize: "16px", fontWeight: 700 }}>ยอดใช้จ่าย</span></div>
                                            <div style={{flexGrow:1, width:"20%"}}><img style={{ width: "15px", height: "20px", }} src={sapphireIcon} /></div>
                                            <div style={{flexGrow:1, width:"30%"}}>
                                            <Tooltip placement="topLeft" title={numberWithCommas(Number(card.elt_price))} overlayClassName="momTabCustomTooltip">
                                            <span style={{ fontSize: "16px", fontWeight: 700 }}>{numberWithFormat(Number(card.elt_price))}</span>
                                            </Tooltip>
                                               
                                                </div>
                                           
                                            
                                            
                                        </div>
                                        <div style={{ backgroundColor: "#1b2234", height: "40px", paddingTop: "8px", textAlign: "center", display: "flex", justifyContent: "space-around", borderRadius: "8px" }}>
                                        <div style={{flexGrow:1, width:"40%"}}><span style={{ fontSize: "16px", fontWeight: 700 }}>ยอดคงเหลือ</span></div>
                                        <div style={{flexGrow:1, width:"20%"}}><img style={{ width: "15px", height: "20px", }} src={sapphireIcon} /></div>
                                        <div style={{flexGrow:1, width:"30%"}}>
                                        <Tooltip placement="topLeft" title={numberWithCommas(Number(card.elt_balance_sapphire))} overlayClassName="momTabCustomTooltip">
                                        <span style={{ fontSize: "16px", fontWeight: 700 }}>{numberWithFormat(Number(card.elt_balance_sapphire))}</span>
                                        </Tooltip>
                                           
                                            </div>
                                         
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        }</>

    );
}

export default ElementLogTransaction;