import React, { useEffect, useState, useRef } from 'react'
import './packagestyle.css'
import axios from "axios";
import Configs from "../../config";
import moment from "moment";
import { func } from 'prop-types';
import Title from "../../components/customElement/headertitle"
import checkicon from "../../assets/img/icon/check-square.png"
import poin from '../../assets/img/iconSpaceShip/plus.png';
import Switch from '../../components/customElement/Switch ';
import iconedit from '../../assets/img/icon/edit-3.png';
import iconunlock from '../../assets/img/icon/unlock.png'
import iconlock from '../../assets/img/icon/lock.png'
import iconviewpass from '../../assets/img/icon/eye.png'
import spceicon from '../../assets/img/icon/Group 91.png'
import iconfcn from '../../assets/img/icon/Layer_2.png'
import Construction from '../../assets/img/bg/Construction Page.png'
import icondoc from '../../assets/img/icon/document.png'
import iconexcel from '../../assets/img/icon/excel.png'
import PDF_file_icon from '../../assets/img/icon/PDF_file_icon.svg.png'
import icondelete from '../../assets/img/icon/delete2.png'
import wait_icon from './img/wait_icon.png';
import onspace_icon from './img/onspace_icon.png';
import finish_icon from './img/finish_icon.png';
import ModelAlertConfrim from "../../components/AlertMassge/AlertConfrim";
import Set from './SpaceShipview'
import { NumericFormat, PatternFormat } from 'react-number-format';
import JSZip from 'jszip';
import dayjs from "dayjs";
import Searchicon from '../../assets/img/icon/Icon Search.png';
import { Input, Space, Tag, theme, Tooltip, ConfigProvider, DatePicker, Modal, Cascader, Select, DeleteOutlined, Row, Carousel, Menu } from 'antd';
import Reportlrit from '../listtemplate/'
import FileViewer from 'react-file-viewer';
import DocViewer from 'react-doc-viewer';
import Selectcat from './selectcat/';
import Viewspaceship from '../viewspaceship/'
import { io } from 'socket.io-client';
import { pdfjs } from 'react-pdf';
/* import PublicProjeclrit from '../ViewPublicProjec/' */
import Swal from "sweetalert2";
import ProjectStatePreview from '../projectStatePreview';
import ModelAlertMassge from '../../components/AlertMassge/AlertMassge';
import styled from 'styled-components';
const { RangePicker } = DatePicker;




const Package = ({
  isOpen,
  setRiskIssue,
  onMenu,
  getData,
  checkMenu,
  getRiskIssueData,
  getProjectId
}) => {


  const [projectList, setProjectList] = useState([]);
  const [projectDisplayList, setProjectDisplayList] = useState([]);
  const [projectState, setProjectState] = useState({
    isOpen: false
  })
  const [memuheader, setmemuheader] = useState({
    menuon: 'basic',
    menu1: true,
    onset: 'l',
    num: 0,
    idtemplate: 'baed5cf7-533e-486c-9515-c0b7e4de96a1',
    nametemplate: 'basic',
    returnon: 'basic',

  });
  const [federationlist, setfederationlist] = useState([]);
  const [successMassge, setSuccessMassge] = useState(false);
  const toggle_modal_successMassge = () => setSuccessMassge(!successMassge);
  const [pitchingReviewData, setPitchingReviewData] = useState({});
  const [alertKickMassge, setAlertKickMassge] = useState(false);
  const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);

  const [messageBox, setMessageBox] = useState({
    message: [],
    isError: false,
    menuTitle: ''
  })

  const [editNameProject, setEditNameProject] = useState(false);
  const [errorMasgerList, setErrorMasgerList] = useState({
    isError: false,
    massger: []
  })
  const [confrimContron, setConfrimContron] = useState({
    is_mode: false,
    massger: ''
  })
  const [filterProjectData, setFilterProjectData] = useState({
    onDue: true,
    onSpace: true,
    onFinish: true,
    filname: '',
    isload: false

  });
  const [selectcat, setselectcat] = useState(false);
  const toggle_modal_setselectcat = () => setselectcat(!selectcat);

  const [confrimModal, setConfrimModal] = useState(false);
  const toggle_modal_confrimModal = () => setConfrimModal(!confrimModal);

  const [onremove, setonremove] = useState({
    remove: '',
    count: 0,

  });
  const [tempValue, setTempValue] = useState({
    del_id: ''
  });

  const [dataonprojcet, setdataonprojcett] = useState({
    id: '',
    name: '',
    stage_id: '',
    key: '',
    project_code : '',
    private: false,
    is_universal: false,
    idroom: 'xyz001',
    islock: false,
    passroom: '',
    passroomdis: '',
    isshowpass: false,
    setpass: false,
    doc: '',
    budget: '',
    timlinestart: '',
    timlineend: '',
    cat: '',
    no: false,
    spaceship_interres: [],
    spaceship_view: [],
    cover_name: '',
    cover_path: '',
    imgpdf: null,
    catall: [],
    federation_id: '',
    add: 0
  });

  const [filtercat, setFiltercat] = useState({
    cat: '',
    all: []
  });


  const [isprivate, setIsprivate] = useState(false);
  const [faceProject, setFaceProject] = useState([])
  const [dragging, setDragging] = useState(null);
  const [dataprojcet, setdataprojcet] = useState([]);

  const [projectNewList, setProjectNewList] = useState([]);

  const [roleOption, setRoleOption] = useState([/* {
    value:'1',
    label:'1'
  } */])

  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedFile2, setSelectedFile2] = useState(null);
  const [pathicon, setpathicon] = useState(null);
  const [pathicon2, setpathicon2] = useState(null);
  const [fileType, setFileType] = useState(null);

  const [alertKick, setAlertKick] = useState(false);
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);
  const [selectedValue, setSelectedValue] = useState([]);

  const [oldFileList, setOldFileList] = useState([]);
  const [otherFileList, setOtherFileList] = useState([]);
  const [checkFileList, setCheckFileList] = useState({
    TOR: false,
    COVER: false
  });
  const [onProjectIndex, setOnProjectIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getfederationlis();
    getCategoryOption();
    getProjectByUser(1, 9999);
    GetAccesstokenToken();
    // const interval = setInterval(() => {
    //   document.getElementById('packageTabList').scrollLeft += -999999999999999
    //   clearInterval(interval);
    // }, 1000);
  }, [])

  // useEffect(() => {
  //   if (dataonprojcet.imgpdf !== null) {
  //     setpathicon(PDF_file_icon)
  //   }
  //   else{
  //     setpathicon(null)
  //   }


  // }, [pathicon])
  async function GetAccesstokenToken() {
    axios({
        method: "post",
        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
        headers: {
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
        },
    })
        .then(async function (response) {

            // if (callback) {
            //     callback(response.data.tokenBox);
            // }
            sessionStorage.setItem('tokenBox', response.data.tokenBox)
        })
        .catch(function (error) {
            console.log(error);
        });

}

  const [sendDetailApply, setSendDetailApply] = useState({
    text: ''
  });


  async function getPitchingProjectDetailById(spaceShipId) {
    var temp = {
      spaceShipId: spaceShipId,
      projectId: dataonprojcet.id
    }
    //  console.log(detailprojcet.imgpath);
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/getPitchingProjectDetailById`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

      data: temp
    })
      .then(async function (response) {
        if (response.data.length > 0) {


          setPitchingReviewData({
            ...pitchingReviewData,
            pi_vdo_path: response.data[0].pi_vdo_path,
            pi_vdo_name: response.data[0].pi_vdo_name,
            pi_description: response.data[0].pi_description,
            video_pitching_id: response.data[0].video_pitching_id
          });
          pitchingReviewData.pi_vdo_path = response.data[0].pi_vdo_path;
          pitchingReviewData.pi_vdo_name = response.data[0].video_pitching_name;
          pitchingReviewData.pi_description = response.data[0].pi_description;
          pitchingReviewData.video_pitching_id = response.data[0].video_pitching_id;


          let video_file = null;
          /*a wait GetAccesstokenToken(async(result_)=>{
            await getFileBoxByIdImage(response.data[0].video_pitching_id,async (result)=>{
               if(result){
                 console.log('result_pitching',result)
                 video_file = result.fileImg;
                 let fileUploadImg = [];
                 if (response.data[0].video_pitching_id !== null) {
                         var temps = {
                             uid: fileDoc.length || 1,
                             video_pitching:video_file,
                             video_id : response.data[0].video_pitching_id,
                             ul_file_path: response.data[0].pi_vdo_path,
                             ul_file_name: response.data[0].pi_vdo_name,
                             name: response.data[0].video_pitching_name,
                             path: response.data[0].pi_vdo_path,
                             url: `${Configs.TTT_Game_Service_IMG}/${response.data[0].pi_vdo_path}`,
                             is_save: false,
                             ul_is_use: true,
                             ul_file_size: null,
                             ul_type: null,
                             ul_group_name: "projectPitching",
                             ul_menu_name: "video",
                             ul_is_profile: false,
                             status: 'done',
                             byid: true
                         }
                         fileUploadImg.push(temps);
                         
                 }
                 setFileDoc(fileUploadImg);
               }
             })
           }) */





          settest({ ...test, test: '' });
          test.test = '';
        }
      })
      .catch(function (error) {
        console.log(error);

      });
  }


  const handleDragStart = (event, element) => {

    setDragging(element);
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/plain', JSON.stringify(element));
  };

  const handleDragOver = (event) => {

    event.preventDefault();
  };

  const handleDrop = (event, targetElement) => {
    event.preventDefault();
    if (dragging) {
      console.log(targetElement, dragging.id);
      const updateddataprojcet = dataprojcet.map((el) => {
        if (el.id === dragging.id) return targetElement;
        if (el.id === targetElement.id) return dragging;
        return el;
      });
      for (let i of updateddataprojcet) {
        if (dragging.id === i.id) {
          i.on = true
        } else {
          i.on = false
        }

      }
      setdataprojcet(updateddataprojcet);
      setDragging(null);
    }
  };
  const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  // ลบ project
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmControl, setConfirmControl] = useState({ is_mode: false, massger: '' });
  const [dataonproject, setDataonproject] = useState({ id: '' });
  const handleClick = () => {
    setConfirmModal(true);
  };
  const toggleModalConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const delMyProject = (pro_id) => {
    let ID = pro_id;
    console.log('ID:', ID);
    let temp = {
      project_is_use: false,
    };
    // toggleModalConfirmModal()
    axios({
      method: "get",
      url: `${Configs.TTT_Game_Service}/api/project/deleteProjectById/${ID}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp,
    })
      .then((response) => {
        setConfirmModal(false);
        getProjectByUser(1, 9999);
      })
    // .then((response) => {
    //   console.log('API response:', response.data);
    //   if (response.data) {
    //     Swal.fire({
    //       icon: "success",
    //       title: "ลบสำเร็จ",
    //       showConfirmButton: false,
    //       timer: 1500,
    //     }).then(() => {
    //       setConfirmModal(false);
    //       // FilterReportScheduler(searchData.page, searchData.size, searchData.repeat);
    //     });
    //   }
    // })
  };

  const handleItemDoubleClick = (id) => {

    setdataprojcet((prevItems) => {
      return prevItems.map((item) => {
        if (item.id === id) {
          return { ...item, isEditing: true, mode: 'edit' };
        } else {
          if (item.id === dataonprojcet.key) {
            return { ...item, isEditing: false, data: dataonprojcet };
          } else {
            return { ...item, isEditing: false };
          }

        }
      });
    });

    let datasete = dataprojcet.filter((dp) => dp.id === id);
    if (datasete.length > 0) {
      setdataonprojcett({
        ...dataonprojcet,
        id: datasete[0].id,
        name: datasete[0].name,
        stage_id: datasete[0].stage_id,
        key: datasete[0].id,
        private: datasete[0].data.private,
        project_code:  datasete[0].project_code,
        is_universal: datasete[0].data.is_universal,
        idroom: datasete[0].data.idroom,
        islock: datasete[0].data.islock,
        passroom: datasete[0].data.passroom,
        passroomdis: datasete[0].data.passroomdis,
        isshowpass: datasete[0].data.isshowpass,
        setpass: datasete[0].data.setpass,
        doc: datasete[0].data.doc,
        budget: datasete[0].data.budget || 0,
        timlinestart: datasete[0].data.timlinestart,
        timlineend: datasete[0].data.timlineend,
        /*   cat: datasete[0].data.cat, */
        spaceship_interres: datasete[0].data.spaceship_interres || [],
        spaceship_view: datasete[0].data.spaceship_view || [],
        cover_name: datasete[0].data.cover_name,
        cover_path: datasete[0].data.cover_path,
        imgpdf: datasete[0].data.imgpdf,
        catall: datasete[0].data.catall,
        federation_id: datasete[0].data.federation_id
      })

      setFiltercat({
        ...filtercat, cat: datasete[0].data.cat,
        all: datasete[0].data.catall !== undefined ? datasete[0].data.catall : []
      })


    }



  };

  const [categoryOption, setCategoryOption] = useState([]);
  const [categorAll, setCategorAll] = useState({
    data: []
  });
  async function getCategoryOption() {
    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/category/getallcattegoryindropdown`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

    })
      .then(function (response) {
        setCategoryOption(response.data.data);
        setCategorAll({ ...categorAll, data: response.data.row })
        categorAll.data = response.data.row
      })
      .catch(function (error) {
        console.log(error);

      });
  }

  function setValuesByParentId(data, targetParentId, values) {
    for (const category of data) {
      if (category.value === targetParentId) {
        // Set values for the target parent category
        values.push(category.value);

        // Check if there are children and recursively set values
        if (category.pc_parent_id !== null) {
          setValuesByParentId(data, category.pc_parent_id, values);
        }
      }
    }
  }


  const [test, settest] = useState({ test: '' });
  async function getProjectByUser(page, enties, mode) {
    // setLoading(true);
    setFilterProjectData({ ...filterProjectData, isload: true });
    filterProjectData.isload = true;
    if (mode = 'edit') {
      setSelectedFile(null);
      setpathicon(null);
      setSelectedFile2(null);
      setpathicon2(null);
    }

    let getOnProjectV1 = dataprojcet.filter((e) => e.on === true);

    try {
      const response = await axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/project/getProjectAllByUser`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
        data: {
          page: page || 1,
          enties: enties || 999,
          onDue: filterProjectData.onDue,
          onSpace: filterProjectData.onSpace,
          onFinish: filterProjectData.onFinish,
          filname: filterProjectData.filname || ''
        }
      });

      let project_list = [];
      let TempFack = [];
      let index = 0;
      setProjectList(response.data);
      setProjectDisplayList(response.data);
      setFaceProject(response.data);
      for (let item of response.data) {
        let keySTR = item.project_id.split("-")[1];
        let fileimgdoc = item.imgdoc;
        let charPass = '';
        for (let i = 0; i < (item.project_password || '').toString().length; i++) {
          charPass += '*';
        }
        let datacat = [];
        if (item.project_pc_id !== null) {
          const selectedValues = [];
          setValuesByParentId(categorAll.data, item.project_pc_id, selectedValues);
          datacat = selectedValues.reverse();
        }
        let temp = {
          id: item.project_id,
          mode: 'edit',
          text: item.project_name,
          stage_id: item.project_pros_id,
          textold: item.project_name,
          on: getOnProjectV1.length > 0 ? false : index === response.data.length - 1,
          isEditing: false,
          data: {
            id: item.project_id,
            name: item.project_name,
            key: keySTR,
            private: !item.project_is_public,
            project_code : item.project_code,
            is_universal: item.project_is_universal,
            idroom: keySTR,
            islock: '',
            passroom: item.project_password,
            passroomdis: charPass,
            isshowpass: false,
            setpass: '',
            doc: fileimgdoc,
            budget: item.project_budget,
            timlinestart: item.pp_start_date,
            timlineend: item.pp_finish_date,
            cat: item.project_pc_id,
            spaceship_interres: item.space_interesting_list || [],
            spaceship_view: item.space_view_list || [],
            cover_name: item.project_cover_name,
            cover_path: item.project_cover_path,
            imgpdf: item.project_display_doc_path,
            catall: datacat || [],
            federation_id: item.project_federation_id
          }
        };
        project_list.push(temp);
        TempFack.push(temp);
        if (index == onProjectIndex) {
          temp.on = true;
          setdataonprojcett({
            ...dataonprojcet,
            mode: 'edit',
            id: temp.id,
            name: temp.text,
            stage_id: temp.stage_id,
            key: temp.data.key,
            project_code : temp.data.project_code,
            private: temp.data.private,
            is_universal: temp.data.is_universal,
            idroom: temp.data.idroom,
            islock: temp.data.islock,
            passroom: temp.data.passroom,
            passroomdis: temp.data.passroomdis,
            isshowpass: temp.data.isshowpass,
            setpass: temp.data.setpass,
            doc: temp.data.doc,
            budget: temp.data.budget,
            timlinestart: temp.data.timlinestart,
            timlineend: temp.data.timlineend,
            spaceship_interres: temp.data.spaceship_interres || [],
            spaceship_view: temp.data.spaceship_view || [],
            cover_name: temp.data.cover_name,
            cover_path: temp.data.cover_path,
            imgpdf: temp.data.imgpdf,
            catall: temp.data.catall,
            federation_id: temp.data.federation_id
          });
          setFiltercat({
            ...filtercat,
            cat: temp.data.cat,
            all: temp.data.catall !== undefined ? temp.data.catall : []
          });
          setImgFucn(item);
        };
        index++;
      }
      let getOnProject = dataprojcet.filter((e) => e.on === true);
      if (getOnProject.length > 0) {
        let getIndwex = project_list.findIndex((i) => i.id === getOnProject[0].id);
        if (getIndwex > -1) {
          project_list[getIndwex].on = true;
        }
      }
      setFilterProjectData({ ...filterProjectData, isload: false });
      filterProjectData.isload = false;
      setdataprojcet(project_list);
      settest({ ...test, test: '' });
      test.test = '';
      // if (response.data.length > 0) {
      //   let indexA = 0;
      //   let oldFile = [];
      //   for (let item of response.data) {
      //     if (item.space_interesting_list.length > 0) {
      //       for (let item2 of item.space_interesting_list) {
      //         if (item2.spaceship_profile) {
      //           const response2 = await axios.get(`https://api.box.com/2.0/files/${item2.spaceship_profile}/content`, {
      //             headers: {
      //               Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      //             },
      //             responseType: 'blob',
      //           });

      //           item2.ul_file_path = URL.createObjectURL(response2.data);
      //         } else if (item2.spaceship_model) {
      //           const response2 = await axios.get(`https://api.box.com/2.0/files/${item2.spaceship_model}/content`, {
      //             headers: {
      //               Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      //             },
      //             responseType: 'blob',
      //           });

      //           item2.ul_file_path = URL.createObjectURL(response2.data);
      //         }
      //       }
      //     }
      //     if (item.space_view_list.length > 0) {
      //       for (let item2 of item.space_view_list) {
      //         if (item2.spaceship_profile) {
      //           const response2 = await axios.get(`https://api.box.com/2.0/files/${item2.spaceship_profile}/content`, {
      //             headers: {
      //               Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      //             },
      //             responseType: 'blob',
      //           });

      //           item2.ul_file_path = URL.createObjectURL(response2.data);
      //         } else if (item2.spaceship_model) {
      //           const response2 = await axios.get(`https://api.box.com/2.0/files/${item2.spaceship_model}/content`, {
      //             headers: {
      //               Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      //             },
      //             responseType: 'blob',
      //           });

      //           item2.ul_file_path = URL.createObjectURL(response2.data);
      //         }
      //       }
      //     }

      //     // if (item.image_list && item.image_list.length > 0) {
      //     //   for (let item2 of item.image_list) {
      //     //     if (item2.fd_keyfile == "PROJECT TOR") {
      //     //       console.log('testlog tor', onProjectIndex,item2.fd_img_id);
      //     //       if (onProjectIndex != null){
      //     //         oldFile.push(item2.fd_img_id);
      //     //       }
      //     //       if (item2.fd_img_id) {
      //     //         const response2 = await axios.get(`https://api.box.com/2.0/files/${item2.fd_img_id}/content`, {
      //     //           headers: {
      //     //             Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      //     //           },
      //     //           responseType: 'blob',
      //     //         });

      //     //         item.TOR_file_path = URL.createObjectURL(response2.data);
      //     //         item.TOR_image_id = item2.fd_img_id;
      //     //         if (onProjectIndex == indexA) {
      //     //           setSelectedFile([{
      //     //             url: `${URL.createObjectURL(response2.data)}`,
      //     //           }]);
      //     //           let checkType = item2.fd_origin_name.split('.');
      //     //           if (checkType.length > 0) {
      //     //             let getCheck = checkType[checkType.length - 1];
      //     //             if (getCheck == 'pdf') {
      //     //               setpathicon(PDF_file_icon);
      //     //             } else if (getCheck == 'doc' || getCheck == 'docx') {
      //     //               setpathicon(icondoc);
      //     //             } else if (getCheck == 'xls' || getCheck == 'xlsx') {
      //     //               setpathicon(iconexcel);
      //     //             };
      //     //             setFileType(getCheck);
      //     //           };
      //     //         }
      //     //         settest({ ...test, test: '' });
      //     //         test.test = '';
      //     //       }
      //     //     } else if (item2.fd_keyfile == "PROJECT COVER") {
      //     //       console.log('testlog COVER', onProjectIndex,item2.fd_img_id);
      //     //       if (onProjectIndex != null){
      //     //         oldFile.push(item2.fd_img_id);
      //     //       }
      //     //       if (item2.fd_img_id) {
      //     //         const response2 = await axios.get(`https://api.box.com/2.0/files/${item2.fd_img_id}/content`, {
      //     //           headers: {
      //     //             Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      //     //           },
      //     //           responseType: 'blob',
      //     //         });

      //     //         item.COVER_file_path = URL.createObjectURL(response2.data);
      //     //         item.COVER_image_id = item2.fd_img_id;
      //     //         if (onProjectIndex == indexA) {
      //     //           setSelectedFile([{
      //     //             url: `${URL.createObjectURL(response2.data)}`,
      //     //           }]);

      //     //           setpathicon2(URL.createObjectURL(response2.data));

      //     //           // setFileType(filedoc.ul_type);
      //     //         }
      //     //       }
      //     //     };
      //     //     setOldFileList(oldFile);
      //     //     setProjectList(response.data);
      //     //     setProjectDisplayList(response.data);
      //     //     setFaceProject(response.data);
      //     //     settest({ ...test, test: '' });
      //     //     test.test = '';

      //     //   }
      //     // }

      //     // if (item.image_old && item.image_old.length > 0) {
      //     //   let fdImgIds = item.image_old.map(item => item.fd_img_id);
      //     //   setOtherFileList(fdImgIds);
      //     // }
      //     indexA++
      //   }
      //   // setLoading(false);
      // }
    } catch (error) {
      console.log(error);
    }
  }

  function addProject(data) {
    toggle_modal_confrimModal()
    let temp = {
      project_name: (dataonprojcet.name || '').toString().trim(),
      project_code : (dataonprojcet.project_code || '').toString().trim(),
      project_description: "",
      spaceship_id: "",
      federation_id: "",
      project_budget: "",
      category_id: "",
      is_public: true
    }


    setSelectedFile(null)
    setpathicon(null)
    setSelectedFile2(null)
    setpathicon2(null)

    setFileType(null)

    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/addProjectByUser`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp

    })
      .then(function (response) {
        if (response.data.project_id) {
          setProjectNewList([]);
          updateProject(response.data.project_id);
        }
        /*  if (response.data) {
           handleItemBlur(data.id)

           // setFaceProject([]) 
           setdataprojcet([])
           getProjectByUser();
           settest({ ...test, test: '' })
           test.test = '';
           setErrorMessage({ ...errorMessage, message: 'Success', status: false });
           toggle_modal_alertKick();
         } */



      })
      .catch(function (error) {
        console.log('error', error);
        
        if (error.response && error.response.status === 400) {
          setMessageBox({
            ...messageBox,
            message: error.response.data.error[0].errorDis /* || 'Project Name is duplicated in the system' */,
            isError: true,
            menuTitle: 'WARNING'
          });
        } else {
          console.log('error', error);
        }
        toggle_modal_alertKickMassge();
      });
  }

  const [errorMessage, setErrorMessage] = useState({ status: '', message: '' });
  async function updateProject(project_id) {
    setLoading(true);
    setFilterProjectData({ ...filterProjectData, filname: '' });
    filterProjectData.filname = '';
    toggle_modal_confrimModal();
    setEditNameProject(false);
    let ole_file = [];

    let filelist = []
    // console.log(filelist, selectedFile, selectedFile2);
    if (selectedFile !== null) {
      filelist.push(selectedFile[0])
    }
    if (selectedFile2 !== null) {
      filelist.push(selectedFile2[0])
    }

    // if (filelist && filelist.length > 0) {
    //   let newProfile = filelist.filter((e) => e.is_img_upload !== undefined && e.is_img_upload == true)
    //   if (newProfile.length > 0) {
    //     const dataImg = new FormData();
    //     for (let i = 0; i < newProfile.length; i++) {
    //       dataImg.append('Profile', newProfile[i].image_file);
    //     }
    //     dataImg.append('typeFile', 'projectdoc');
    //     dataImg.append('projectKey', "myproject");
    //     await axios({
    //       method: 'post',
    //       url: `${Configs.TTT_Game_Service}/api/upload/document2`,
    //       headers: {
    //         /*  Authorization: getToken(), */
    //         'X-TTT': `${Configs.API_TTT}`,
    //         'Content-Type': 'application/octet-stream',
    //       },
    //       data: dataImg,
    //     })
    //       .then(async function (response) {
    //         if (response.data.status == true) {
    //           response.data.data.forEach((e) => {
    //             const tmp = {
    //               name: e.orgin_name,
    //               path: e.path,
    //               is_save: true,
    //               ul_is_use: true,
    //               ul_file_size: e.size,
    //               url: e.path,
    //               ul_type: newProfile[0].ul_type,
    //               ul_group_name: "myproject",
    //               ul_file_name: e.orgin_name,
    //               ul_file_path: e.path,
    //               ul_menu_name: "myproject",
    //               ul_is_profile: true,
    //               status: 'done'

    //             }
    //             ole_file.push(tmp);
    //           })
    //         }

    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //   } else {
    //     let checkIMG = filelist.filter((e) => e.byid !== undefined && e.byid == true)
    //     // console.log('TTTTT_checkIMG', checkIMG);
    //     if (checkIMG.length > 0) {
    //       ole_file.push(checkIMG[0])
    //     }
    //   }
    // }

    let fileList = selectedFile !== null ? ole_file.filter((e) => { return e.name === selectedFile[0].name }).length > 0 ? ole_file.filter((e) => { return e.name === selectedFile[0].name }) : selectedFile : [];
    let temp_ = dataonprojcet
    let nameProject = dataprojcet.filter((e) => { return e.id === temp_.id });
    let filecover = selectedFile2 !== null ? ole_file.filter((e) => { return e.name === selectedFile2[0].name }).length > 0 ? ole_file.filter((e) => { return e.name === selectedFile2[0].name }) : selectedFile2 : []
    //console.log(temp_)
    let temp = {
      project_id: project_id ? project_id : temp_.id,
      project_name: (temp_.name || '').toString().trim(),
      project_code: (temp_.project_code || '').toString().trim(),
      project_budget: (temp_.budget || '').toString().replaceAll(',', ''),
      project_password: temp_.passroom,
      /*   project_path: */
      category_id: filtercat.cat || null,
      is_public: temp_.private == true ? false : true,
      is_universal: temp_.is_universal || false,
      start_date: temp_.timlinestart,
      end_date: temp_.timlineend,
      filelist: fileList,
      imgpdf: fileType === 'pdf' ? pathicon : null,
      cover_name: filecover.length > 0 ? filecover[0].name : null,
      cover_path: filecover.length > 0 ? filecover[0].ul_file_path : null,
      federation_id: temp_.federation_id || null

    }
    let error = 0;
    if (temp_.private == true && (temp_.passroom || '').toString().trim() == '') {
      setErrorMessage({ ...errorMessage, message: 'Required Password !!!', status: true });
      toggle_modal_alertKick();
      error++;
    }

    if (error == 0) {


      axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/project/updateProjectByUser`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
        data: temp

      })
        .then(async function (response) {
          if (response.data) {

            /* Swal.fire({
            
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500
            }); */

            for (let item of dataonprojcet.spaceship_interres) {
              notiChangeProject(item.pi_created_by, item.pi_created_name, item.spaceship_id, item.pi_project_id);
            }

            if (checkFileList.TOR || checkFileList.COVER) {
              if (checkFileList.TOR) {
                await CheckAndCreateFolderBox(project_id, 'PROJECT TOR');
              } else if (checkFileList.COVER) {
                await CheckAndCreateFolderBox(project_id, 'PROJECT COVER');
              };
            } else {
              getProjectByUser(1, 9999, 'edit');
              setLoading(false);
            };

            // dataprojcet.on
            // getProjectByUser(1, 9999);
            // setErrorMessage({ ...errorMessage, message: 'Success', status: false });
            // toggle_modal_alertKick();
          }



        })
        .catch(function (error) {
          setLoading(false);
          if (error.response && error.response.status === 400) {
            setMessageBox({
              ...messageBox,
              message: error.response.data.error[0].errorDis /* || 'Project Name is duplicated in the system' */,
              isError: true,
              menuTitle: 'WARNING'
            });
          } else {
            console.log('error', error);
          }
          toggle_modal_alertKickMassge();
        });

    }
  }

  async function CheckAndCreateFolderBox(id, typeList) {
    let temp = {
      folderLis: ["PROJECT", "PROJECT_" + id, 'PROJECT INFORMATION', typeList]
    }
    await axios({
      method: 'post',
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
      },
      data: temp
    })
      .then(async function (response) {
        if (response.data) {
          UploadFiles(id, response.data.folder_id, typeList);
        }
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.error[0].errorDis, "error");
        console.log(error);
      });
  }

  async function UploadFiles(id, folder_id, typeList) {
    let oldFileList_ = oldFileList.filter((e) => { return e != null });
    let setOldFile = [...oldFileList_, ...otherFileList];
    const dataImg = new FormData();
    if (typeList == 'PROJECT TOR') {
      dataImg.append('File', selectedFile[0].image_file);
    } else if (typeList == 'PROJECT COVER') {
      dataImg.append('File', selectedFile2[0].image_file);
    };
    dataImg.append('folder_id', folder_id);
    dataImg.append('key', typeList);
    dataImg.append('image_old_list', JSON.stringify(setOldFile));
    dataImg.append('ref_id', id);

    await axios({
      method: 'post',
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'multipart/form-data'
      },
      data: dataImg
    })
      .then(async function (response) {
        if (response.data) {
          // Swal.fire({
          //     icon: "success",
          //     title: "Save",
          //     showConfirmButton: false,
          //     timer: 1500,
          // }).then((result) => {
          //     window.location.href = "/M-20240220226"
          // });
          if (typeList == 'PROJECT TOR' && checkFileList.COVER) {
            let temp = [];
            oldFileList[0] = response.data.image_id_list[0];
            await CheckAndCreateFolderBox(id, 'PROJECT COVER');
          } else {
            setErrorMessage({ ...errorMessage, message: 'Success', status: false });
            toggle_modal_alertKick();
            getProjectByUser(1, 9999, 'edit');
            setLoading(false);
          }
        }
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.error[0].errorDis, "error");
        console.log(error);
      });
  }

  const handleItemBlur = (id) => {

    /*  addProject */
    /*  let dopvalue = dataprojcet.filter((dp) => dp.text.trim() !== '');
 
     setdataprojcet(dopvalue) */


    let chack = dataprojcet[dataprojcet.length - 1].text.trim()
    console.log('ddddd', id, chack);
    if (chack !== '' || dataprojcet.length > 2) {
      setdataprojcet((prevItems) => {
        /* console.log('prevItems',prevItems); */

        return prevItems.map((item) => {
          if (item.id === id) {

            //console.log('item',item)


            setdataonprojcett({
              ...dataonprojcet,
              id: item.id,
              name: item.data.name,
              stage_id: item.stage_id,
              key: item.id,
              project_code: item.data.project_code,
              private: item.data.private,
              is_universal: item.data.is_universal,
              idroom: item.data.idroom || '000' + item.id,
              islock: item.data.islock,
              passroom: item.data.passroom,
              passroomdis: item.data.passroomdis,
              isshowpass: item.data.isshowpass,
              setpass: item.data.setpass,
              doc: item.data.doc,
              budget: item.data.budget || 0,
              timline: item.data.timline,
              /*  cat: item.data.cat, */
              no: item.text.trim() === '' && item.textold.trim() === '' ? true : false,
              spaceship_interres: item.data.spaceship_interres || [],
              spaceship_view: item.data.spaceship_view || [],
              cover_name: item.data.cover_name,
              cover_path: item.data.cover_path,
              imgpdf: item.data.imgpdf,
              catall: item.data.catall,
              federation_id: item.data.federation_id
            })
            setFiltercat({
              ...filtercat, cat: item.data.cat,
              all: item.data.catall !== undefined ? item.data.catall : []
            })

            if (item.data.cover_name !== null) {
              setSelectedFile2(
                [{
                  uid: item.project_id,
                  ul_file_path: item.data.cover_path,
                  ul_file_name: item.data.cover_path,
                  name: item.data.cover_path,
                  path: item.data.cover_path,
                  url: `${Configs.TTT_Game_Service_IMG}${item.data.cover_path}`,
                  is_save: true,
                  ul_is_use: true,
                  ul_file_size: null,
                  ul_type: 'image',
                  ul_group_name: 'myproject',
                  ul_menu_name: 'myproject',
                  ul_is_profile: true,
                  status: 'done',
                  byid: true
                }]
              )
              setpathicon2(`${item.data.cover_path/* Configs.TTT_Game_Service_IMG + item.data.cover_path */}`)
            } else {
              setSelectedFile2(null)
              setpathicon2(null)
            }

            let filedoc = item.data.doc
            if (filedoc !== null) {
              setSelectedFile(null);
              setpathicon(null)
              setTimeout(function () {
                setSelectedFile(
                  [{
                    uid: item.id,
                    ul_file_path: filedoc.ul_file_path,
                    ul_file_name: filedoc.ul_file_name,
                    name: filedoc.ul_file_name,
                    path: filedoc.ul_file_path,
                    url: `${Configs.TTT_Game_Service_IMG}${filedoc.ul_file_path}`,
                    is_save: true,
                    ul_is_use: true,
                    ul_file_size: filedoc.ul_file_size,
                    ul_type: filedoc.ul_type,
                    ul_group_name: filedoc.ul_group_name,
                    ul_menu_name: filedoc.ul_menu_name,
                    ul_is_profile: true,
                    status: 'done',
                    byid: true
                  }]
                )
                if (filedoc.ul_type === 'pdf') {
                  setpathicon(PDF_file_icon)
                  /* setpathicon(item.data.imgpdf) */
                  settest({ ...test, test: '' })
                }
                if (filedoc.ul_type === 'doc') {
                  setpathicon(icondoc)
                }
                if (filedoc.ul_type === 'xls') {
                  setpathicon(iconexcel)
                }
                if (filedoc.ul_type === 'image') {
                  setpathicon(`${Configs.TTT_Game_Service_IMG}${filedoc.ul_file_path}`)
                }
                setFileType(filedoc.ul_type)
              }, 1);
            } else {
              setSelectedFile(null)
              setFileType(null)
              setpathicon(null)
            }
            if (item.mode == 'add') {
              console.log('aaaaadddddd')
              addProject(item);
              item.mode = 'edit';
            }

            console.log('ดูหน่อยไหมครับ', item);
            if (item.text !== '') {

              return { ...item, isEditing: false, textold: item.text };
            } else {

              return { ...item, isEditing: false, text: item.textold };
            }


          }

          return item;

        });
      });

    } else {

      setdataprojcet([])
      onremove.count = dataprojcet.length - 1
    }

    //console.log(dataprojcet);
  };

  const handleItemChange = (id, newText) => {
    /*  if (newText.length > 0) { */
    console.log('newText', newText)
    setdataprojcet((prevItems) => {
      return prevItems.map((item) => {
        if (item.id === id) {
          if (newText.length > 0) {
            return { ...item, text: newText };
          } return { ...item, text: newText };
        }
        return item;
      });
    });




    console.log('sss', dataprojcet)

    /* }else{
        let dopvalue = dataprojcet.filter((dp) => dp.id !== id);
    
        setdataprojcet(dopvalue)
    } */
  };




  const [stream, setStream] = useState(null);
  const [recording, setRecording] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null);
  const videoRef = useRef();
  const mediaRecorderRef = useRef(null);
  const recordTimerRef = useRef(null);
  const recordedChunksRef = useRef([]);


  const startRecording = async () => {

    try {
      let mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 1280 },
          height: { ideal: 600 },
        },
        audio: true,
      }, { mimeType: 'video/mp4' });
      videoRef.current.srcObject = mediaStream;
      /*  videoRef.current.autoPlay = true; */
      console.log(mediaStream);
      mediaRecorderRef.current = new MediaRecorder(mediaStream);
      let chunks = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {

        const recordedBlob = new Blob(chunks, { type: 'video/mp4' });
        setVideoBlob(recordedBlob);
        recordedChunksRef.current = [];
        videoRef.current.srcObject = null;
        setRecording(false);
        videoRef.current.src = URL.createObjectURL(recordedBlob);

      };

      mediaRecorderRef.current.start();
      console.log('xb', mediaRecorderRef);
      setRecording(true);

      recordTimerRef.current = setTimeout(() => {
        stopRecording();
      }, 60000);
    } catch (error) {
      console.error('Error accessing the camera and microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {

      console.log('b', mediaRecorderRef.current);
      mediaRecorderRef.current.stream.stop();



      clearTimeout(recordTimerRef.current);
    }

  };
  const saveVideo = () => {
    if (videoBlob) {
      const url = URL.createObjectURL(videoBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'recorded-video-with-sound.mp4';
      a.click();

      mediaRecorderRef = null
      recordTimerRef = null
      videoBlob = null

    }
  };


  function confrimSave() {
    setConfrimContron({
      ...confrimContron,
      is_mode: false,
      massger: 'Do you want to delete data?'
    })

    toggle_modal_confrimModal()
  }

  const deleteItem = (id) => {


    /*    Swal.fire({
         title: "Do you want to delete data?",
         text: "You won't be able to revert this!",
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Yes,delete it!",
   
       }).then(async (result) => {
         if (result.isConfirmed) { */
    let dopvalue = dataprojcet.filter((dp) => dp.id !== id);
    let getIStrue = dopvalue.filter((s) => { return s.on === true })

    let array = [];
    if (getIStrue.length == 0) {
      let index = 1;
      for (let item of dopvalue) {
        if (index === dopvalue.length) {
          item.on = true
          setdataonprojcett({
            ...dataonprojcet,
            id: item.id,
            name: item.text,
            stage_id: item.stage_id,
            key: item.data.key,
            private: item.data.private,
            project_code: item.data.project_code,
            is_universal: item.data.is_universal,
            idroom: item.data.idroom,
            islock: item.data.islock,
            passroom: item.data.passroom,
            passroomdis: item.data.passroomdis,
            isshowpass: item.data.isshowpass,
            setpass: item.data.setpass,
            doc: item.data.doc,
            budget: item.data.budget,
            timlinestart: item.data.timlinestart,
            timlineend: item.data.timlineend,
            /*  cat: item.data.cat, */
            spaceship_interres: item.data.spaceship_interres || [],
            spaceship_view: item.data.spaceship_view || [],
            cover_name: item.data.cover_name,
            cover_path: item.data.cover_path,
            imgpdf: item.data.imgpdf,
            catall: item.data.catall,
            federation_id: item.data.federation_id
          })
          setFiltercat({
            ...filtercat, cat: item.data.cat,
            all: item.data.catall !== undefined ? item.data.catall : []
          })

        } else {
          item.on = false
        }
        array.push(item);
        index++
      }
    }
    /*  let delFace = faceProject.filter((dp) => dp.id !== id); */
    toggle_modal_confrimModal()
    setdataprojcet(array)
    /*  setFaceProject(delFace); */
    /*   }
    }) */

  };


  function callbacktemplate(template) {

    setmemuheader({
      ...memuheader,
      menuon: template.returnon
    });

    memuheader.menuon = template.returnon


  }

  function onClickTabArrow(value) {
    if (value === 'left') {
      document.getElementById('packageTabList').scrollLeft += -199
    } else {
      document.getElementById('packageTabList').scrollLeft += 199
    }

  }




  const handleFileChange = async (event) => {
    // if (projectToShow.image_list && projectToShow.image_list.length > 0) {
    //   let checkIdImage = projectToShow.image_list.filter((e)=> {return e.fd_keyfile == 'PROJECT TOR'});
    //   let checkIndex = oldFileList.findIndex((e)=> e == checkIdImage[0].fd_img_id);
    //   if (checkIndex > -1) {
    //     oldFileList[checkIndex] = null;
    //   }
    // }
    oldFileList[0] = null;
    setSelectedFile(null);
    setpathicon(null)
    setTimeout(function () {
      const file = event.target.files;
      let typefile = ''
      const fileName = file[0].name.toLowerCase();
      if (fileName.endsWith('.pdf')) {
        setFileType('pdf');
        typefile = 'pdf'


        if (file) {

          const reader = new FileReader();
          reader.onload = async () => {
            const pdfData = new Uint8Array(reader.result);
            const pdf = await pdfjs.getDocument({ data: pdfData }).promise;


            const page = await pdf.getPage(1);


            const viewport = page.getViewport({ scale: 2 });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            await page.render({ canvasContext: context, viewport }).promise;


            const imageDataURL = canvas.toDataURL('image/png');

            dataonprojcet.imgpdf = imageDataURL
            setpathicon(imageDataURL)
          };

          reader.readAsArrayBuffer(file[0]);


        }
        setpathicon(PDF_file_icon)

      } else if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
        setFileType('doc');
        typefile = 'doc'
        dataonprojcet.imgpdf = null
        setpathicon(icondoc)
      } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
        setFileType('xls');
        typefile = 'xls'
        dataonprojcet.imgpdf = null
        setpathicon(iconexcel)
      } else if (fileName.endsWith('.png') || fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')) {
        setFileType('image');
        dataonprojcet.imgpdf = null
        typefile = 'image'
        setpathicon(URL.createObjectURL(file[0]));
      }
      var list = [{
        ul_file_path: URL.createObjectURL(file[0]),
        ul_file_name: file[0].name,
        name: file[0].name,
        path: URL.createObjectURL(file[0]),
        url: URL.createObjectURL(file[0]),
        is_save: true,
        ul_is_use: true,
        ul_file_size: 0,
        ul_type: typefile,
        ul_group_name: "myproject",
        ul_menu_name: "myproject",
        ul_is_profile: true,
        status: 'done',
        image_file: file[0],
        is_img_upload: true
      }]

      setSelectedFile(list)
      setCheckFileList({ ...checkFileList, TOR: true });
      settest({ ...test, test: '' });
      test.test = '';

    }, 1);


  };



  const handleFileChange2 = async (event) => {
    oldFileList[1] = null;
    setSelectedFile2(null);
    setpathicon2(null)
    setTimeout(function () {
      const file = event.target.files;
      let typefile = ''
      const fileName = file[0].name.toLowerCase();
      if (fileName.endsWith('.png') || fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')) {
        typefile = 'image'
        setpathicon2(URL.createObjectURL(file[0]));
      }
      var list = [{
        ul_file_path: URL.createObjectURL(file[0]),
        ul_file_name: file[0].name,
        name: file[0].name,
        path: URL.createObjectURL(file[0]),
        url: URL.createObjectURL(file[0]),
        is_save: true,
        ul_is_use: true,
        ul_file_size: 0,
        ul_type: typefile,
        ul_group_name: "myproject",
        ul_menu_name: "myproject",
        ul_is_profile: true,
        status: 'done',
        image_file: file[0],
        is_img_upload: true
      }]

      setSelectedFile2(list)
      setCheckFileList({ ...checkFileList, COVER: true });
      settest({ ...test, test: '' });
      test.test = '';

    }, 1);


  };
  const downloadFile = (file) => {
    const fileUrl = file ? file[0].url : '';
    const link = document.createElement('a');
    link.href = fileUrl;
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  function callBackcat(id) {

    if (id !== undefined) {
      setdataonprojcett({ ...dataonprojcet, cat: id })
      dataonprojcet.cat = id
    }

    toggle_modal_setselectcat()

  }

  const socketRef = useRef(null);
  useEffect(() => {

    if (socketRef.current == null) {
      socketRef.current = io(Configs.TTT_Game_Socket_Service, {
        //path: '/Socket-service/socket.io'
        port: Configs.TTT_Game_Socket_Service_Port
      });
    }
  }, [

  ])

  async function notiChangeProject(user, user_name, space_id, project_id) {
    let tempNoti = {
      header: "PROJECT DETAIL BE CHANGE",
      // header:"คุณได้รับคำเชิญเข้าร่วมยาน",
      // body:"คุณได้รับคำเชิญจาก " + sessionStorage.getItem('user') + " เพื่อเข้าร่วมยาน " + spaceShipName + " คุณจะยอมรับคำเชิญหรือไม่ ?",
      noti_menu_name: "message",
      user_to_id: user,
      user_to_name: user_name,
      link_to: "",
      space_ship_id: space_id,
      project_id: project_id
    }
    await axios({
      method: 'POST',
      url: `${Configs.TTT_Game_Notification_Service}/api/notification/addNotification`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: tempNoti,
    })
      .then(async function (response) {
        socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + user });
        // setSendData([])
        /*      fncAlert('SEND OFFER SUCCESS.',false); */
      })
      .catch(function (error) {
        console.log(error);
      });
  }






  const [modalDetail, setModalDetail] = useState({
    isOpen: false,
    space_id: '',
    project_id: ''
  })

  function callBackspace(id, img) {
    console.log(id);
    getPitchingProjectDetailById(id);
    setModalDetail({
      ...modalDetail,
      isOpen: true,
      space_id: id,
      space_profile: img,
      project_id: dataonprojcet.id
    })
  }
  function callBackspaceview() {

    setModalDetail({
      ...modalDetail,
      isOpen: false,
      space_id: '',
      project_id: '',
      space_profile: ''
    })
  }




  const [spaceshipeViewModal, setSpaceshipViewModal] = useState(false)
  const toggle_modal_spaceshipViewModal = () => setSpaceshipViewModal(!spaceshipeViewModal)



  /*  console.log(
     'เช็คหน่อยสิ', categoryOption.filter((e) => { return e.value === dataonprojcet.cat }).length
   ); */



  /* const loadFileFromServer = async (filePath) => {
    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);



      console.log('ssss',objectURL);
    } catch (error) {
      console.error('Error loading file:', error);
    }
  };
 */


  const handleCascaderClick = (value, selectedOptions) => {
    console.log('Clicked value:', value);
    /*  setSelectedValue(value); */
    setFiltercat({
      ...filtercat, cat: value[value.length - 1],
      all: value
    })
    filtercat.cat = value[value.length - 1]
    filtercat.all = value
  };



  const handleDisplayRender = (labels, selectedOptions) => {
    /*     console.log(labels); */
    return `${labels[labels.length - 1]}`;
  };


  async function getfederationlis() {
    await axios({
      method: 'GET',
      url: `${Configs.TTT_Game_Service}/api/project/getfederationlist`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
    })
      .then(async function (response) {
        if (response.data) {
          let temp = [];
          for (let item of response.data) {
            temp.push({
              value: item.federation_id,
              label: item.federation_name
            });
          }
          setfederationlist(temp);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const federationChange = (value) => {
    console.log(`ค่าที่ถูกเลือก: ${value}`);
  };



  function searchRealTime(keySearch) {
    let temp = projectList;
    let result = temp.filter((e) => { return (e.project_name || '').toString().toLowerCase().includes(keySearch.toLowerCase()) });
    setProjectDisplayList(result);
  }

  async function setImgFucn(e) {
    if (e.image_list && e.image_list.length > 0) {
      let oldFile = [null, null];
      for (let item of e.image_list) {
        const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
          },
          responseType: 'blob',
        });
        if (item.fd_keyfile == 'PROJECT TOR'){
          oldFile[0] = item.fd_img_id;
          setSelectedFile([{
            url: `${URL.createObjectURL(response2.data)}`,
          }]);
          let checkType = item.fd_origin_name.split('.');
          if (checkType.length > 0) {
            let getCheck = checkType[checkType.length - 1];
            if (getCheck == 'pdf') {
              setpathicon(PDF_file_icon);
            } else if (getCheck == 'doc' || getCheck == 'docx') {
              setpathicon(icondoc);
            } else if (getCheck == 'xls' || getCheck == 'xlsx') {
              setpathicon(iconexcel);
            };
            setFileType(getCheck);
          };
          // setpathicon(URL.createObjectURL(response2.data));
        } else if (item.fd_keyfile == 'PROJECT COVER') {
          oldFile[1] = item.fd_img_id;
          setpathicon2(URL.createObjectURL(response2.data));
        }
        settest({...test,test: ''});
        test.test = '';
      }
      setOldFileList(oldFile);
    }

    if (e.space_interesting_list && e.space_interesting_list.length > 0) {
      for (let item of e.space_interesting_list) {
        if (item.spaceship_profile) {
          const response2 = await axios.get(`https://api.box.com/2.0/files/${item.spaceship_profile}/content`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
          });

          item.ul_file_path = URL.createObjectURL(response2.data);
        } else if (item.spaceship_model) {
          const response2 = await axios.get(`https://api.box.com/2.0/files/${item.spaceship_model}/content`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
          });

          item.ul_file_path = URL.createObjectURL(response2.data);
        }
        settest({...test,test: ''});
        test.test = '';
      }
    }

    if (e.space_view_list && e.space_view_list.length > 0) {
      for (let item of e.space_view_list) {
        if (item.spaceship_profile) {
          const response2 = await axios.get(`https://api.box.com/2.0/files/${item.spaceship_profile}/content`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
          });

          item.ul_file_path = URL.createObjectURL(response2.data);
        } else if (item.spaceship_model) {
          const response2 = await axios.get(`https://api.box.com/2.0/files/${item.spaceship_model}/content`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
          });

          item.ul_file_path = URL.createObjectURL(response2.data);
        }
        settest({...test,test: ''});
        test.test = '';
      }
    }
  }

  return (
    <>
      <ConfigProvider
        theme={{
          token: {

            /*     colorPrimary: '#85BFFF', */
            colorInfo: '#85BFFF',
            colorBorder: '#0047FF',
            colorText: '#FFF',

            colorTextDescription: '#000',
            colorBgContainer: '#000',

            zIndexPopupBase: 999999,
            colorPrimaryBg: '#0047FF',//สีตอน Active ใน Select
            colorBgBase: '#778899',//พื้นหลัง select ตอนกด down ลงมา

            colorTextQuaternary: '#FFFFFF',// สีตัวหนังสือของ placeholder


            colorFillTertiary: '#000',// สีตอน hover ใน Select


            colorTextBase: 'blue', // ลูกศรใน datepicker & rangepicker




            colorPrimaryBg: '#85BFFF',
            /*        
                   colorBgLayout:'red',
                   colorFillQuaternary:'red', */


          }
        }}
      >
        <Selectcat
          isopen={selectcat}
          pcid={dataonprojcet.cat}
          callback={callBackcat}
        />

        <Viewspaceship
          isOpen={modalDetail.isOpen}
          projectid={modalDetail.project_id}
          spaceid={modalDetail.space_id}
          spaceship_profile={modalDetail.space_profile}

          pitchingReviewData={pitchingReviewData}
          mode={'1'}
          callBack={callBackspaceview}
        />
        {memuheader.menuon === 'report' ?
          (<>



            <Reportlrit
              template={memuheader}
              callback={callbacktemplate}

            />
            {/* <PublicProjeclrit
              template={memuheader}
              callback={callbacktemplate}
            /> */}



          </>)
          :
          (<>
            <div className='headerpackage'  >
              <Title name='PACKAGE' no={1} fix={300} imglong={96} top={7.5} imgbox={70} imgcorner={12} />
              <div style={{ width: '700px', marginTop: '-35px', marginLeft: '230px', fontSize: '46px' }}>
                <div className='row ' >
                  <div className='fontheader ' style={{ width: '80px' }}
                    onClick={() => {
                      if (memuheader.menuon !== 'basic') {
                        setmemuheader({
                          ...memuheader,
                          /*  menuon: 'ALL', */
                          onset: memuheader.num > 0 ? 'r' : 'l',
                          menu1: false,
                          idtemplate: 'baed5cf7-533e-486c-9515-c0b7e4de96a1',
                          nametemplate: 'BASIC',
                          returnon: 'basic'
                        });
                        console.log(memuheader.num, memuheader.onset);
                        memuheader.onset = memuheader.num > 0 ? 'r' : 'l'
                        memuheader.num = 0
                        memuheader.menuon = 'basic'
                        memuheader.idtemplate = 'baed5cf7-533e-486c-9515-c0b7e4de96a1'
                        memuheader.nametemplate = 'BASIC'
                        memuheader.returnon = 'basic'
                        setTimeout(function () {
                          setmemuheader({
                            ...memuheader,
                            menu1: true,
                          });
                        }, 420);
                      }
                      /*    const interval = setInterval(() => {
                           document.getElementById('packageTabList').scrollLeft += -999999999999999
                           clearInterval(interval);
                         }, 1000); */
                    }}>
                    <font className={memuheader.menuon === 'basic' ? 'fontborderfocus' : 'fontborder'} >
                      BASIC
                    </font>
                  </div>
                  <div className='fontheader' style={{ width: '110px' }}
                    onClick={() => {
                      if (memuheader.menuon !== 'is') {
                        setmemuheader({
                          ...memuheader,
                          /*  menuon: 'ACTIVITY', */
                          onset: memuheader.num >= 1 ? 'r' : 'l',
                          menu1: false,
                          idtemplate: '659f03b6-9f8c-423b-bc7a-8cc731161dcc',
                          nametemplate: 'ISO29110',
                          returnon: 'basic'


                        });
                        console.log(memuheader.num, memuheader.onset);
                        memuheader.onset = memuheader.num > 1 ? 'r' : 'l'
                        memuheader.num = 1
                        memuheader.menuon = 'is'
                        memuheader.idtemplate = '659f03b6-9f8c-423b-bc7a-8cc731161dcc'
                        memuheader.nametemplate = 'ISO29110'
                        memuheader.returnon = 'basic'
                        setTimeout(function () {
                          setmemuheader({
                            ...memuheader,
                            menu1: true,
                          });
                        }, 420);
                      }
                    }}>
                    <font className={memuheader.menuon === 'is' ? 'fontborderfocus' : 'fontborder'} >
                      ISO29110
                    </font>
                  </div>
                  <div className='fontheader' style={{ width: '85px' }}
                    onClick={() => {
                      if (memuheader.menuon !== 'cmmi') {

                        setmemuheader({
                          ...memuheader,
                          /*   menuon: 'PROMOTION', */
                          onset: memuheader.num > 2 ? 'r' : 'l',
                          menu1: false,
                          idtemplate: '06470309-8968-481d-b00d-4fdf8471a90e',
                          nametemplate: 'CMMI',
                          returnon: 'basic'

                        });
                        console.log(memuheader.num, memuheader.onset);
                        memuheader.onset = memuheader.num > 2 ? 'r' : 'l'
                        memuheader.num = 2
                        memuheader.menuon = 'cmmi'
                        memuheader.idtemplate = '06470309-8968-481d-b00d-4fdf8471a90e'
                        memuheader.nametemplate = 'CMMI'
                        memuheader.returnon = 'basic'
                        setTimeout(function () {
                          setmemuheader({
                            ...memuheader,
                            menu1: true,
                          });
                        }, 420);
                      }
                    }}>
                    <font className={memuheader.menuon === 'cmmi' ? 'fontborderfocus' : 'fontborder'} >
                      CMMI
                    </font>
                  </div>
                  <div className='fontheader' style={{ width: '270px' }}
                    onClick={() => {
                      if (memuheader.menuon !== 'cus') {
                        setmemuheader({
                          ...memuheader,
                          /*  menuon: 'SPACESHIP', */
                          onset: memuheader.num > 3 ? 'r' : 'l',
                          menu1: false,
                          idtemplate: 'baed5cf7-533e-486c-9515-c0b7e4de96a1',
                          nametemplate: 'CUSTOMIZE',
                          returnon: 'basic'

                        });
                        console.log(memuheader.num, memuheader.onset);
                        memuheader.onset = memuheader.num > 3 ? 'r' : 'l'
                        memuheader.num = 3
                        memuheader.menuon = 'cus'
                        memuheader.idtemplate = 'baed5cf7-533e-486c-9515-c0b7e4de96a1'
                        memuheader.nametemplate = 'CUSTOMIZE'
                        memuheader.returnon = 'basic'
                        setTimeout(function () {
                          setmemuheader({
                            ...memuheader,
                            menu1: true,
                          });
                        }, 420);
                      }
                    }}>
                    <font className={memuheader.menuon === 'cus' ? 'fontborderfocus' : 'fontborder'} >
                      CUSTOMIZE
                    </font>
                  </div>
                </div>
              </div>
            </div>

            <div className='packageContainner'>
              <div className='cardPackageList'>
                <div className='headerProjectCard'>
                  PROJECT LIST
                </div>

                <div className='containnerBtnHeaderProjectCard'>
                  <div className='containnerFilterIcon'>
                    <div className={`IconFilter${filterProjectData.onDue == true ? '-select' : ''}`} onClick={() => {
                      setFilterProjectData({ ...filterProjectData, onDue: !filterProjectData.onDue });
                      filterProjectData.onDue = !filterProjectData.onDue;
                      getProjectByUser(1, 9999);
                    }}>
                      <img src={wait_icon} />
                    </div>
                    <div className={`IconFilter${filterProjectData.onSpace == true ? '-select' : ''}`} onClick={() => {
                      setFilterProjectData({ ...filterProjectData, onSpace: !filterProjectData.onSpace });
                      filterProjectData.onSpace = !filterProjectData.onSpace;
                      getProjectByUser(1, 9999);
                    }}>
                      <img src={onspace_icon} />
                    </div>
                    <div className={`IconFilter${filterProjectData.onFinish == true ? '-select' : ''}`} onClick={() => {
                      setFilterProjectData({ ...filterProjectData, onFinish: !filterProjectData.onFinish });
                      filterProjectData.onFinish = !filterProjectData.onFinish;
                      getProjectByUser(1, 9999);
                    }}>
                      <img src={finish_icon} />
                    </div>
                  </div>

                  <div className='addProjectBtn' onClick={() => {
                    // let tempNewProject = {
                    //   project_id: "",
                    //     project_name: "NEW PROJECT",
                    //     project_description: "",
                    //     project_budget: "0",
                    //     project_pros_id: "",
                    //     project_is_public: true,
                    //     project_password: "",
                    //     project_is_universal: false,
                    //     project_display_doc_path: null,
                    //     project_cover_name: null,
                    //     project_cover_path: null,
                    //     pp_start_date: null,
                    //     pp_finish_date: null,
                    //     pros_id: "",
                    //     pros_name: "NEW PROJECT",
                    //     pros_color: "#676767",
                    //     space_interesting_list: [],
                    //     space_view_list: []
                    // };
                    // setProjectNewList(tempNewProject);

                    let tempProjectNewList = projectNewList;
                    if (tempProjectNewList.length == 0) {


                      tempProjectNewList.push({
                        project_id: "",
                        project_name: "NEW PROJECT",
                        project_description: "",
                        project_code: '',
                        project_budget: "0",
                        project_pros_id: "",
                        project_is_public: true,
                        project_password: "",
                        project_is_universal: false,
                        project_display_doc_path: null,
                        project_cover_name: null,
                        project_cover_path: null,
                        pp_start_date: null,
                        pp_finish_date: null,
                        pros_id: "",
                        pros_name: "NEW PROJECT",
                        pros_color: "#676767",
                        space_interesting_list: [],
                        space_view_list: []
                      });
                      setProjectNewList(tempProjectNewList);
                      setdataonprojcett({
                        ...dataonprojcet,
                        mode: 'add',
                        id: '',
                        name: 'NEW PROJECT',
                        stage_id: '',
                        key: '',
                        project_code :'',
                        private: '',
                        is_universal: '',
                        idroom: '',
                        islock: '',
                        passroom: '',
                        passroomdis: '',
                        isshowpass: '',
                        setpass: '',
                        doc: '',
                        budget: '',
                        timlinestart: '',
                        timlineend: '',
                        spaceship_interres: [],
                        spaceship_view: [],
                        cover_name: '',
                        cover_path: '',
                        imgpdf: '',
                        catall: '',
                        federation_id: '',
                        add: 1
                      });

                      setSelectedFile2(null);
                      setpathicon2(null);
                      setSelectedFile(null);
                      setpathicon(null);

                      setFileType(null);

                      setFiltercat({
                        ...filtercat, cat: '',
                        all: []
                      });
                      settest({ ...test, test: '' });
                      test.test = '';
                    }
                  }}>
                    +
                  </div>

                </div>




                <div style={{ padding: '5px' }}>
                  <input
                    autoComplete="off"
                    placeholder='Search'
                    className='searchInput'
                    type="text"


                    value={filterProjectData.filname}

                    onChange={(e) => {
                      searchRealTime(e.target.value);
                      setFilterProjectData({ ...filterProjectData, filname: e.target.value });
                      filterProjectData.onFinish = e.target.value;
                    }}



                  />
                </div>

                <div style={{
                  padding: '5px', overflowY: 'auto',
                  overflowX: 'hidden', display: 'flex', gap: '4%', flexDirection: 'column', height: '80%'
                }}>
                  {/*      <div className='projectCard-select'>
                    <div className='projectCardShape-select'>
                    </div>
                    <div className='textProjectContainner'>

                      <div className='projectNameCardText'>
                        PROJECT : NEW PROJECT
                      </div>
                      <div className='projectStatusCardText'>
                        STATUS : <font color='#676767'>NEW PROJECT</font>
                      </div>
                    </div>

                  </div> */}
                  {projectNewList.map((e, index) => {
                    return (<>
                      <Tooltip title={e.project_name} placement="right" >
                        <div className='projectCard-select' onClick={() => {
                          // console.log('testlog projectNewList', e);
                          let keySTR = e.project_id.split("-")[1];
                          let fileimgdoc = e.imgdoc
                          let charPass = '';


                          for (let i = 0; i < (e.project_password || '').toString().length; i++) {
                            charPass += '*';

                          }


                          let datacat = [];
                          if (e.project_pc_id !== null) {
                            console.log(categorAll.data, e.project_pc_id);

                            const selectedValues = [];
                            setValuesByParentId(categorAll.data, e.project_pc_id, selectedValues)
                            datacat = selectedValues.reverse();
                          }
                          let temp = {
                            /*    id: (onremove.count + 1).toString(), */
                            id: e.project_id,
                            mode: 'edit',
                            text: e.project_name,
                            stage_id: e.project_pros_id,
                            textold: e.project_name,
                            // on: getOnProjectV1.length > 0 ? false : index == response.data.length - 1 ? true : false,
                            isEditing: true,
                            data: {
                              id: e.project_id,
                              name: e.project_name,
                              key: keySTR,
                              private: e.project_is_public == true ? false : true,
                              project_code: e.project_code,
                              is_universal: e.project_is_universal,
                              idroom: keySTR,
                              islock: '',
                              passroom: e.project_password,
                              passroomdis: charPass,
                              isshowpass: false,
                              setpass: '',
                              doc: fileimgdoc,
                              budget: e.project_budget,
                              timlinestart: e.pp_start_date,
                              timlineend: e.pp_finish_date,
                              cat: e.project_pc_id,
                              spaceship_interres: e.space_interesting_list || [],
                              spaceship_view: e.space_view_list || [],
                              cover_name: e.project_cover_name,
                              cover_path: e.project_cover_path,
                              imgpdf: e.project_display_doc_path,
                              catall: datacat || [],
                              federation_id: e.project_federation_id
                            }
                          };

                          setdataonprojcett({
                            ...dataonprojcet,
                            mode: 'edit',
                            id: temp.id,
                            name: temp.text,
                            stage_id: temp.stage_id,
                            key: temp.data.key,
                            project_code : temp.data.project_code,
                            private: temp.data.private,
                            is_universal: temp.data.is_universal,
                            idroom: temp.data.idroom,
                            islock: temp.data.islock,
                            passroom: temp.data.passroom,
                            passroomdis: temp.data.passroomdis,
                            isshowpass: temp.data.isshowpass,
                            setpass: temp.data.setpass,
                            doc: temp.data.doc,
                            budget: temp.data.budget,
                            timlinestart: temp.data.timlinestart,
                            timlineend: temp.data.timlineend,
                            /*  cat: temp.data.cat, */
                            spaceship_interres: temp.data.spaceship_interres || [],
                            spaceship_view: temp.data.spaceship_view || [],
                            cover_name: temp.data.cover_name,
                            cover_path: temp.data.cover_path,
                            imgpdf: temp.data.imgpdf,
                            catall: temp.data.catall,
                            federation_id: temp.data.federation_id

                          });

                          setFiltercat({
                            ...filtercat, cat: temp.data.cat,
                            all: temp.data.catall !== undefined ? temp.data.catall : []
                          })


                          if (temp.data.cover_name !== null) {
                            setSelectedFile2(
                              [{
                                uid: temp.project_id,
                                ul_file_path: temp.data.cover_path,
                                ul_file_name: temp.data.cover_path,
                                name: temp.data.cover_path,
                                path: temp.data.cover_path,
                                url: `${Configs.TTT_Game_Service_IMG}${temp.data.cover_path}`,
                                is_save: true,
                                ul_is_use: true,
                                ul_file_size: null,
                                ul_type: 'image',
                                ul_group_name: 'myproject',
                                ul_menu_name: 'myproject',
                                ul_is_profile: true,
                                status: 'done',
                                byid: true
                              }]
                            )
                            setpathicon2(`${temp.data.cover_path/* Configs.TTT_Game_Service_IMG+temp.data.cover_path */}`)
                          } else {
                            setSelectedFile2(null)
                            setpathicon2(null)
                            setSelectedFile(null)
                            setpathicon(null)
                          }


                          let filedoc = temp.data.doc

                          if (filedoc !== null) {
                            console.log(temp.data.doc);
                            /*   loadFileFromServer(`${Configs.TTT_Game_Service_IMG}${filedoc.ul_file_path}`) */







                            setTimeout(function () {
                              setSelectedFile(
                                [{
                                  uid: temp.project_id,
                                  ul_file_path: filedoc.ul_file_path,
                                  ul_file_name: filedoc.ul_file_name,
                                  name: filedoc.ul_file_name,
                                  path: filedoc.ul_file_path,
                                  url: `${Configs.TTT_Game_Service_IMG}${filedoc.ul_file_path}`,
                                  is_save: true,
                                  ul_is_use: true,
                                  ul_file_size: filedoc.ul_file_size,
                                  ul_type: filedoc.ul_type,
                                  ul_group_name: filedoc.ul_group_name,
                                  ul_menu_name: filedoc.ul_menu_name,
                                  ul_is_profile: true,
                                  status: 'done',
                                  byid: true
                                }]
                              )
                              /*   console.log('filedoc', filedoc, `${Configs.TTT_Game_Service_IMG}${filedoc.ul_file_path}`); */
                              if (filedoc.ul_type === 'pdf') {

                                /*     setpathicon(temp.data.imgpdf) */
                                setpathicon(PDF_file_icon);
                                settest({ ...test, test: '' });
                                console.log(pathicon);
                              }
                              if (filedoc.ul_type === 'doc') {
                                setpathicon(icondoc);
                              }
                              if (filedoc.ul_type === 'xls') {
                                setpathicon(iconexcel);
                              }
                              if (filedoc.ul_type === 'image') {
                                setpathicon(`${Configs.TTT_Game_Service_IMG}${filedoc.ul_file_path}`)
                              }
                              setFileType(filedoc.ul_type);
                            }, 100);
                          } else {
                            setSelectedFile(null)
                            setpathicon(null)
                            // setFileType(null)
                          }

                        }}>
                          <div className='projectCardShape-select'>
                          </div>
                          <div className='textProjectContainner'>

                            <div className='projectNameCardText'>
                              PROJECT : {e.project_name}
                            </div>
                            <div>
                              <div className='projectStatusCardText'>
                                STATUS : <font color={e.pros_color}>{e.pros_name}</font>
                              </div>
                              {/* <img src={icondelete} /> */}
                            </div>
                          </div>

                        </div>
                      </Tooltip>

                    </>)
                  })}
                  {projectDisplayList.map((e, index) => {
                    return (<>
                      <Tooltip title={e.project_name} placement="right" >
                        <div className='projectCard' onClick={async () => {
                          setpathicon(null);
                          setpathicon2(null);
                          setOnProjectIndex(index);
                          let keySTR = e.project_id.split("-")[1];
                          let fileimgdoc = e.imgdoc
                          let charPass = '';

                          for (let i = 0; i < (e.project_password || '').toString().length; i++) {
                            charPass += '*';

                          }

                          let datacat = [];
                          if (e.project_pc_id !== null) {
                            const selectedValues = [];
                            setValuesByParentId(categorAll.data, e.project_pc_id, selectedValues)
                            datacat = selectedValues.reverse();
                          }
                          let temp = {
                            /*    id: (onremove.count + 1).toString(), */
                            id: e.project_id,
                            mode: 'edit',
                            text: e.project_name,
                            stage_id: e.project_pros_id,
                            textold: e.project_name,
                            // on: getOnProjectV1.length > 0 ? false : index == response.data.length - 1 ? true : false,
                            isEditing: true,
                            data: {
                              id: e.project_id,
                              name: e.project_name,
                              key: keySTR,
                              private: e.project_is_public == true ? false : true,
                              project_code: e.project_code,
                              is_universal: e.project_is_universal,
                              idroom: keySTR,
                              islock: '',
                              passroom: e.project_password,
                              passroomdis: charPass,
                              isshowpass: false,
                              setpass: '',
                              doc: fileimgdoc,
                              budget: e.project_budget,
                              timlinestart: e.pp_start_date,
                              timlineend: e.pp_finish_date,
                              cat: e.project_pc_id,
                              spaceship_interres: e.space_interesting_list || [],
                              spaceship_view: e.space_view_list || [],
                              cover_name: e.project_cover_name,
                              cover_path: e.project_cover_path,
                              imgpdf: e.project_display_doc_path,
                              catall: datacat || [],
                              federation_id: e.project_federation_id,
                              add: 1
                            }
                          };

                          setdataonprojcett({
                            ...dataonprojcet,
                            id: temp.id,
                            name: temp.text,
                            stage_id: temp.stage_id,
                            key: temp.data.key,
                            project_code: temp.data.project_code,
                            private: temp.data.private,
                            is_universal: temp.data.is_universal,
                            idroom: temp.data.idroom,
                            islock: temp.data.islock,
                            passroom: temp.data.passroom,
                            passroomdis: temp.data.passroomdis,
                            isshowpass: temp.data.isshowpass,
                            setpass: temp.data.setpass,
                            doc: temp.data.doc,
                            budget: temp.data.budget,
                            timlinestart: temp.data.timlinestart,
                            timlineend: temp.data.timlineend,
                            /*  cat: temp.data.cat, */
                            spaceship_interres: temp.data.spaceship_interres || [],
                            spaceship_view: temp.data.spaceship_view || [],
                            cover_name: temp.data.cover_name,
                            cover_path: temp.data.cover_path,
                            imgpdf: temp.data.imgpdf,
                            catall: temp.data.catall,
                            federation_id: temp.data.federation_id,
                            add: temp.data.add
                          });

                          setFiltercat({
                            ...filtercat, cat: temp.data.cat,
                            all: temp.data.catall !== undefined ? temp.data.catall : []
                          })

                          setImgFucn(e);
                        }}>
                          <div className='projectCardShape'>
                          </div>
                          <div className='textProjectContainner'>

                            <div className='projectNameCardText'>
                              PROJECT : {e.project_name}
                            </div>

                            <div className='projectStatusCardText'>
                              STATUS : <font color={e.pros_color}>{e.pros_name}</font>
                              {(e.pros_id === '7acfb2d4-eb27-406d-a0ff-f4dff01c7460') && (
                                <img
                                  src={icondelete}
                                  alt="description"
                                  className="icon-hover"
                                  onClick={handleClick}
                                />
                              )}
                            </div>
                          </div>

                        </div>
                      </Tooltip>

                    </>)
                  })}
                </div>
              </div>
              <div className='cardpackage'  >

                {memuheader.menuon === 'basic' ?
                  (
                    <>

                      {dataonprojcet.add === 1 ?
                        (<>
                          <br />
                          <div style={{ marginRight: '80%', transform: 'rotate(180deg)' }}>
                            <Title name='' no={1} fix={300} imglong={200} top={7.5} imgbox={70} imgcorner={12} />
                          </div>


                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                            <div style={{ color: '#ffffff', fontSize: '36px' }}>PROJECT : {editNameProject ? (<input
                              className='searchInput'
                              style={{ width: 'fit-content' }}
                              value={dataonprojcet.name}
                              onChange={(e) => {
                                setdataonprojcett({ ...dataonprojcet, name: e.target.value });
                              }}
                              type='text' />) : dataonprojcet.name} {(dataonprojcet.stage_id === '7acfb2d4-eb27-406d-a0ff-f4dff01c7460' || dataonprojcet.stage_id === "") ? <img src={iconedit} style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                onClick={() => {
                                  setEditNameProject(!editNameProject);
                                }}
                              /> : <></>}
                            </div>


                            <div style={{ display: 'flex', gap: '5px' }}>
                              {dataonprojcet.id != '' ? (<>
                                <button className=' btn-outline-primary' style={{ padding: '5px', width: '100%', height: '36px', fontWeight: 'bolder' }}
                                  disabled={dataonprojcet.stage_id === '798520e8-c54c-4e92-b4bf-012d08d2debb' ? true : false}
                                  onClick={() => {
                                    setConfrimContron({
                                      ...confrimContron,
                                      is_mode: true,
                                      massger: 'Do you want to save data?'
                                    })
                                    toggle_modal_confrimModal()

                                  }}>Save Change</button>
                              </>) : (<>
                                <button className=' btn-outline-primary' style={{ padding: '5px', width: '100%', height: '36px', cursor: 'pointer', fontWeight: 'bolder' }}
                                  disabled={dataonprojcet.stage_id === '798520e8-c54c-4e92-b4bf-012d08d2debb' ? true : false}
                                  onClick={() => {
                                    setConfrimContron({
                                      ...confrimContron,
                                      is_mode: true,
                                      massger: 'Do you want to save data?'
                                    })
                                    toggle_modal_confrimModal()

                                  }}> Save</button>

                                <button className=' btn-outline-danger' style={{ padding: '5px', width: '100%', height: '36px', cursor: 'pointer', fontWeight: 'bolder' }}
                                  disabled={dataonprojcet.stage_id === '798520e8-c54c-4e92-b4bf-012d08d2debb' ? true : false}
                                  onClick={() => {
                                    if (dataonprojcet.id == '') {
                                      //new project
                                      setProjectNewList([]);
                                      let tempProjectList = projectDisplayList;
                                      if (tempProjectList.length > 0) {
                                        let keySTR = tempProjectList[0].project_id.split("-")[1];
                                        let fileimgdoc = tempProjectList[0].imgdoc
                                        let charPass = '';
                                        for (let i = 0; i < (tempProjectList[0].project_password || '').toString().length; i++) {
                                          charPass += '*';
                                        }
                                        let datacat = [];
                                        if (tempProjectList[0].project_pc_id !== null) {
                                          const selectedValues = [];
                                          setValuesByParentId(categorAll.data, tempProjectList[0].project_pc_id, selectedValues)
                                          datacat = selectedValues.reverse();
                                        }
                                        let temp = {
                                          id: tempProjectList[0].project_id,
                                          mode: 'edit',
                                          text: tempProjectList[0].project_name,
                                          stage_id: tempProjectList[0].project_pros_id,
                                          textold: tempProjectList[0].project_name,
                                          on: true,
                                          isEditing: true,
                                          data: {
                                            id: tempProjectList[0].project_id,
                                            name: tempProjectList[0].project_name,
                                            key: keySTR,
                                            project_code:tempProjectList[0].project_code,
                                            private: tempProjectList[0].project_is_public == true ? false : true,
                                            is_universal: tempProjectList[0].project_is_universal,
                                            idroom: keySTR,
                                            islock: '',
                                            passroom: tempProjectList[0].project_password,
                                            passroomdis: charPass,
                                            isshowpass: false,
                                            setpass: '',
                                            doc: fileimgdoc,
                                            budget: tempProjectList[0].project_budget,
                                            timlinestart: tempProjectList[0].pp_start_date,
                                            timlineend: tempProjectList[0].pp_finish_date,
                                            cat: tempProjectList[0].project_pc_id,
                                            spaceship_interres: tempProjectList[0].space_interesting_list || [],
                                            spaceship_view: tempProjectList[0].space_view_list || [],
                                            cover_name: tempProjectList[0].project_cover_name,
                                            cover_path: tempProjectList[0].project_cover_path,
                                            imgpdf: tempProjectList[0].project_display_doc_path,
                                            catall: datacat || [],
                                            federation_id: tempProjectList[0].project_federation_id
                                          }
                                        }
                                        setdataonprojcett({
                                          ...dataonprojcet,
                                          id: temp.id,
                                          name: temp.text,
                                          stage_id: temp.stage_id,
                                          key: temp.data.key,
                                          project_code: temp.data.project_code,
                                          private: temp.data.private,
                                          is_universal: temp.data.is_universal,
                                          idroom: temp.data.idroom,
                                          islock: temp.data.islock,
                                          passroom: temp.data.passroom,
                                          passroomdis: temp.data.passroomdis,
                                          isshowpass: temp.data.isshowpass,
                                          setpass: temp.data.setpass,
                                          doc: temp.data.doc,
                                          budget: temp.data.budget,
                                          timlinestart: temp.data.timlinestart,
                                          timlineend: temp.data.timlineend,
                                          spaceship_interres: temp.data.spaceship_interres || [],
                                          spaceship_view: temp.data.spaceship_view || [],
                                          cover_name: temp.data.cover_name,
                                          cover_path: temp.data.cover_path,
                                          imgpdf: temp.data.imgpdf,
                                          catall: temp.data.catall,
                                          federation_id: temp.data.federation_id
                                        });
                                        setFiltercat({
                                          ...filtercat, cat: temp.data.cat,
                                          all: temp.data.catall !== undefined ? temp.data.catall : []
                                        })
                                        if (temp.data.cover_name !== null) {
                                          setSelectedFile2(
                                            [{
                                              uid: temp.project_id,
                                              ul_file_path: temp.data.cover_path,
                                              ul_file_name: temp.data.cover_path,
                                              name: temp.data.cover_path,
                                              path: temp.data.cover_path,
                                              url: `${Configs.TTT_Game_Service_IMG}${temp.data.cover_path}`,
                                              is_save: true,
                                              ul_is_use: true,
                                              ul_file_size: null,
                                              ul_type: 'image',
                                              ul_group_name: 'myproject',
                                              ul_menu_name: 'myproject',
                                              ul_is_profile: true,
                                              status: 'done',
                                              byid: true
                                            }]
                                          )
                                          setpathicon2(`${temp.data.cover_path/* Configs.TTT_Game_Service_IMG+temp.data.cover_path */}`)
                                        } else {
                                          setSelectedFile2(null)
                                          setpathicon2(null)
                                        }
                                        let filedoc = temp.data.doc
                                        if (filedoc !== null) {
                                          /*   loadFileFromServer(`${Configs.TTT_Game_Service_IMG}${filedoc.ul_file_path}`) */
                                          setTimeout(function () {
                                            setSelectedFile(
                                              [{
                                                uid: temp.project_id,
                                                ul_file_path: filedoc.ul_file_path,
                                                ul_file_name: filedoc.ul_file_name,
                                                name: filedoc.ul_file_name,
                                                path: filedoc.ul_file_path,
                                                url: `${Configs.TTT_Game_Service_IMG}${filedoc.ul_file_path}`,
                                                is_save: true,
                                                ul_is_use: true,
                                                ul_file_size: filedoc.ul_file_size,
                                                ul_type: filedoc.ul_type,
                                                ul_group_name: filedoc.ul_group_name,
                                                ul_menu_name: filedoc.ul_menu_name,
                                                ul_is_profile: true,
                                                status: 'done',
                                                byid: true
                                              }]
                                            )
                                            /*   console.log('filedoc', filedoc, `${Configs.TTT_Game_Service_IMG}${filedoc.ul_file_path}`); */
                                            if (filedoc.ul_type === 'pdf') {
                                              /*     setpathicon(temp.data.imgpdf) */
                                              setpathicon(PDF_file_icon);
                                              settest({ ...test, test: '' });
                                              console.log(pathicon);
                                            }
                                            if (filedoc.ul_type === 'doc') {
                                              setpathicon(icondoc);
                                            }
                                            if (filedoc.ul_type === 'xls') {
                                              setpathicon(iconexcel);
                                            }
                                            if (filedoc.ul_type === 'image') {
                                              setpathicon(`${Configs.TTT_Game_Service_IMG}${filedoc.ul_file_path}`)
                                            }
                                            setFileType(filedoc.ul_type);
                                          }, 100);
                                        } else {
                                          setSelectedFile(null)
                                          setpathicon(null)
                                          setFileType(null)
                                        }
                                      }


                                    }
                                  }}>Cancel</button>
                              </>)}

                            </div>
                          </div>


                          <Title name='Benefit' no={2} fix={260} imglong={60} top={7.5} imgbox={70} imgcorner={12} />
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '300px', marginTop: '5px', fontSize: '12px' }}>

                              <img src={checkicon} style={{ width: "25px", height: "25px", marginTop: "10px" }} />
                              <div className='row' style={{ marginTop: '-19px', marginLeft: '30px', fontWeight: '100' }}><div> Basic tracking </div><div style={{ marginLeft: '10px', color: '#0047FF', cursor: 'pointer' }} onClick={() => { /* window.location.href = '/ProjectStatePreview' */
                                setProjectState({ isOpen: true })
                                projectState.isOpen = true
                              }}><span style={{ textShadow: '-1px 0 #fff, 0 1px #fff, 0px 0 #fff, 0 -1px #fff', filter: 'drop-shadow(0px 0px 1px #fff)' }}>More detail </span> </div></div>
                              <img src={checkicon} style={{ width: "25px", height: "25px", marginTop: "10px" }} />
                              <div className='row' style={{ marginTop: '-19px', marginLeft: '30px', fontWeight: '100' }}><div>Stage monitoring</div></div>
                              <img src={checkicon} style={{ width: "25px", height: "25px", marginTop: "10px" }} />
                              <div className='row' style={{ marginTop: '-19px', marginLeft: '30px', fontWeight: '100' }}><div>Basic Report x5</div><div style={{ marginLeft: '10px', color: '#0047FF', cursor: 'pointer' }}
                                onClick={() => {

                                  setmemuheader({
                                    ...memuheader,
                                    menuon: 'report',
                                  });
                                  memuheader.menuon = 'report'

                                }}><span style={{ textShadow: '-1px 0 #fff, 0 1px #fff, 0px 0 #fff, 0 -1px #fff', filter: 'drop-shadow(0px 0px 1px #fff)' }}>More detail </span></div></div>
                              <img src={checkicon} style={{ width: "25px", height: "25px", marginTop: "10px" }} />
                              <div className='row' style={{ marginTop: '-19px', marginLeft: '30px', fontWeight: '100' }}><div>Software universal</div><div style={{ marginLeft: '10px', color: '#0047FF', cursor: 'pointer' }} onClick={() => { window.location.href = '/landingPageGame' }}><span style={{ textShadow: '-1px 0 #fff, 0 1px #fff, 0px 0 #fff, 0 -1px #fff', filter: 'drop-shadow(0px 0px 1px #fff)' }}>More detail </span> </div></div>





                            </div>

                            <div style={{ top: '20%' }}>
                              <div className='row' style={{ marginRight: '0px' }} >
                                <img src={iconfcn} style={{ width: "25px", height: "30px", marginTop: "14px" }} />
                                <div className='row' style={{ marginTop: '7px', marginLeft: '30px', fontWeight: '600' }} onClick={() => { toggle_modal_setselectcat() }}>
                                  Spaceship
                                </div>
                                <div className='row' style={{ fontSize: '13px', fontWeight: '100', marginTop: '28px', marginLeft: '-90px' }}>
                                  <div>Credit</div><div style={{ marginLeft: '70px', color: '#0047FF', fontSize: '13px' }}>xxx</div>
                                </div>

                              </div>

                              <div className='row' >
                                <img src={spceicon} style={{ width: "25px", height: "30px", marginTop: "14px" }} />
                                <div className='row' style={{ marginTop: '7px', marginLeft: '30px', fontWeight: '600' }}>
                                  Federation
                                </div>
                                <div className='row' style={{ fontSize: '13px', fontWeight: '100', marginTop: '28px', marginLeft: '-90px' }}>
                                  <div>Credit</div><div style={{ marginLeft: '70px', color: '#0047FF', fontSize: '13px' }}>xxx</div>
                                </div>
                              </div>

                            </div>
                          </div>


                          <div className='containnerProjectMgt'>
                            <div className='cardpackagedetil' style={{ display: 'flex'/* , gap: '2%'  */ }}>
                              <div className='projectDetailPreview' style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '15px', gap: '2%' }}>
                                <div className='cardpackageimg' style={{ width: '229px', minHeight: '280px', cursor: 'pointer' }} onClick={() => {
                                  if (selectedFile) {
                                    downloadFile(selectedFile)
                                  }

                                }}>

                                  {pathicon !== null ?
                                    <img src={pathicon} style={{ width: '225px', height: '278px', objectFit: 'contain', border: '0px' }} />

                                    :
                                    <>
                                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                        <div>
                                          <br />
                                          No File
                                        </div>
                                      </div>

                                    </>}
                                  {/*     <div style={{  fontSize: '11px', width: '100%', height: '30px', zIndex: '3', background: 'rgb(18, 18, 21, 0.3)', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingTop: '5px' }}>{selectedFile ? selectedFile[0].name : ''}</div> */}



                                </div>


                                <div className='cardpackageimg' style={{ width: '229px', minHeight: '280px', cursor: 'pointer' }} onClick={() => {
                                  if (selectedFile2) {
                                    downloadFile(selectedFile2)
                                  }

                                }}>
                                  {pathicon2 !== null ?
                                    <img src={pathicon2} style={{ width: '227px', height: '278px', objectFit: 'contain', border: '0px', zIndex: '4' }} />
                                    :
                                    <>
                                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                        <div>
                                          <br />
                                          No Image
                                        </div>
                                      </div>
                                    </>}
                                  {/*     <div style={{  fontSize: '11px', width: '223px', height: '30px', zIndex: '3', background: 'rgb(18, 18, 21, 0.3)', marginTop: '285px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingTop: '5px' }}>
                               
                                  {selectedFile2 ? selectedFile2[0].name : ''}</div> */}




                                </div>


                              </div>




                              <div className='posion'>
                                <div className='fontheader row' style={{ fontSize: '18px', margin: 0, padding: 0 }}>
                                  <div style={{ marginTop: '0px', marginRight: '15px' }}> PRIVATE</div><Switch

                                    isChecked={dataonprojcet.private} onChange={(e) => {
                                      if (dataonprojcet.stage_id !== '798520e8-c54c-4e92-b4bf-012d08d2debb') {
                                        setdataonprojcett({ ...dataonprojcet, private: !dataonprojcet.private });
                                      }
                                    }
                                    }


                                  />
                                </div>
                                {dataonprojcet.private === true ?
                                  (<>
                                    <div className='row' style={{ justifyContent: 'space-between', margin: '0 0 5px 0', padding: 0 }}>
                                      <div> ID : {dataonprojcet.idroom}</div>
                                      <div style={{ cursor: 'pointer', marginRight: '5px' }} onClick={(e) => {

                                        if ((dataonprojcet.passroom || '').toString().trim() === '') {
                                          setdataonprojcett({ ...dataonprojcet, islock: !dataonprojcet.islock, setpass: true })
                                        } else {
                                          setdataonprojcett({ ...dataonprojcet, islock: !dataonprojcet.islock })
                                        }

                                      }}> <img src={dataonprojcet.islock === true ? iconlock : iconunlock} style={{ height: '20px', width: '20px', marginTop: '-7px' }} /></div>
                                    </div>

                                    {dataonprojcet.islock === true ?
                                      (<>
                                        <div className='row packageInputOnLock' style={{ border: `${dataonprojcet.setpass === true ? '1px solid #0047FF' : '1px solid transparent'}` }}>
                                          {dataonprojcet.setpass === true ? (
                                            <form autoComplete="off">
                                              <input
                                                className='packageInputOnLockText'
                                                autoComplete="new-password"
                                                type={dataonprojcet.isshowpass === false ? "password" : "text"}
                                                key={dataonprojcet.idroom}
                                                value={dataonprojcet.passroom}
                                                disabled={dataonprojcet.stage_id === '798520e8-c54c-4e92-b4bf-012d08d2debb' ? true : false}

                                                onBlur={() => {
                                                  if (dataonprojcet.passroom.length === 0) {
                                                    setdataonprojcett({ ...dataonprojcet, islock: false, isshowpass: false, setpass: false })
                                                  } else {
                                                    setdataonprojcett({ ...dataonprojcet, setpass: false })
                                                  }
                                                }
                                                }

                                                onChange={(e) => {
                                                  let psssdis = ''
                                                  if (e.target.value.trim().length > 0) {
                                                    for (let p of e.target.value) {
                                                      psssdis += '*'
                                                    }
                                                  }
                                                  setdataonprojcett({ ...dataonprojcet, passroom: e.target.value.trim(), passroomdis: psssdis })

                                                }}
                                                autoFocus
                                                style={{ background: 'none', color: '#0047FF', width: '200px' }}
                                              // onKeyDown={(e) => {
                                              //   if (e.keyCode === 13) {
                                              //     console.log('dsdds', e);


                                              //   }
                                              // }}
                                              />
                                            </form>
                                          ) : (
                                            <span className='packageInputOnLockPassword' onDoubleClick={(e) => { setdataonprojcett({ ...dataonprojcet, setpass: true }) }}>
                                              {dataonprojcet.isshowpass === true ? <>{dataonprojcet.passroom}</> : <>{dataonprojcet.passroomdis}</>}
                                            </span>
                                          )}
                                          <div className='packageInputOnLockSee' onClick={(e) => { setdataonprojcett({ ...dataonprojcet, isshowpass: !dataonprojcet.isshowpass }); }}> <img src={iconviewpass} style={{ height: '20px', width: '20px', marginTop: '-7px' }} /></div>
                                        </div>
                                      </>)
                                      :
                                      (<></>)}




                                  </>)
                                  :
                                  (<></>)}


                                <div className='row' style={{ margin: 0, padding: 0 }}>

                                  <label htmlFor="fileUpload" class="buttonuploadpackage" style={{ textAlign: 'center', paddingTop: '8px' }}>UPLOAD TOR / SPEC</label>
                                  <input type="file" id="fileUpload" disabled={(dataonprojcet.stage_id === '7acfb2d4-eb27-406d-a0ff-f4dff01c7460' || dataonprojcet.stage_id == "") ? false : true} onChange={handleFileChange} accept=".pdf, .doc, .docx, .xls, .xlsx" style={{ display: 'none' }} />
                                </div>


                                <div className='row' style={{ margin: 0, padding: 0 }}>
                                  <div style={{fontSize:'10px',color:'red'}}>&#42; PROJECT KEY REQUIREMENT 1-4 DIGIT</div>
                                  <div className="input-with-image">
                                    <input
                                      type="text"
                                      placeholder="PROJECT KEY"
                                      value={dataonprojcet.project_code ? dataonprojcet.project_code : ''}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                      disabled={(dataonprojcet.stage_id === '7acfb2d4-eb27-406d-a0ff-f4dff01c7460' || dataonprojcet.stage_id === "") ? false : true}
                                      onChange={(e) => {
                                        let value = e.target.value
                                        if(value.length < 5){
                                          setdataonprojcett({ ...dataonprojcet, project_code: value })
                                        }
                                      }}
                                      style={{ background: 'none', color: '#ffffff' }}
                                    />
                                    {/*         {dataonprojcet.stage_id !== '798520e8-c54c-4e92-b4bf-012d08d2debb' ? <><img src={iconedit} className="image" /></> : <></>} */}
                                  </div>
                                </div>
                                <div className='row' style={{ margin: 0, padding: 0 }}>
                                  <div className="input-with-image">
                                    <NumericFormat
                                      type="text"
                                      placeholder="PROJECT BUDGET"
                                      value={dataonprojcet.budget !== null ? dataonprojcet.budget : 0}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                      disabled={(dataonprojcet.stage_id === '7acfb2d4-eb27-406d-a0ff-f4dff01c7460' || dataonprojcet.stage_id === "") ? false : true}
                                      onChange={(e) => setdataonprojcett({ ...dataonprojcet, budget: e.target.value })}
                                      style={{ background: 'none', color: '#ffffff' }}
                                    />
                                    {/*         {dataonprojcet.stage_id !== '798520e8-c54c-4e92-b4bf-012d08d2debb' ? <><img src={iconedit} className="image" /></> : <></>} */}
                                  </div>
                                </div>

                                <div className='row' style={{ margin: 0, padding: 0 }}>
                                  <ConfigProvider
                                    theme={{
                                      token: {
                                        colorBgBase: '#001D5F',//พื้นหลัง datepicker

                                        colorTextQuaternary: 'White',//สีตัวอักษร

                                        colorFillTertiary: '#0047FF',//สีตอนเมาสชี้
                                        colorBgContainer: '',

                                        colorTextBase: 'White', // สี scroll bar
                                        fontSize: '13px',
                                        // colorPrimaryBg: '#0047FF',// สีตอนเลือก'
                                        colorTextDescription: 'White',
                                        /*       
                                        
                                               colorBgLayout:'red',
                                               colorFillQuaternary:'red', */


                                      }
                                    }}
                                  >
                                    <RangePicker style={{ borderRadius: '0px', paddingLeft: '10px', paddingRight: '7px' }} format="DD-MM-YYYY" className='input-with-image'
                                      allowClear={false}
                                      value={[dataonprojcet.timlinestart ? dayjs(dataonprojcet.timlinestart) : '', dataonprojcet.timlineend ? dayjs(dataonprojcet.timlineend) : '']}
                                      disabled={(dataonprojcet.stage_id === '7acfb2d4-eb27-406d-a0ff-f4dff01c7460' || dataonprojcet.stage_id == "") ? false : true}
                                      onChange={(date) => {


                                        if (date.length == 2) {
                                          setdataonprojcett({ ...dataonprojcet, timlinestart: new Date(date[0]), timlineend: new Date(date[1]) })
                                        }

                                      }}
                                    />
                                  </ConfigProvider>
                                </div>

                                <div className='row' style={{ margin: 0, padding: 0, marginBottom: '15px' }}>
                                  <ConfigProvider
                                    theme={{
                                      token: {
                                        borderRadius: 0,
                                        colorBgContainer: 'transparent', //สี content ใน Main select
                                        // colorTextQuaternary: 'red',//สีตัวอักษร ใน Main select
                                        // colorBorder:'#0047ff',

                                        colorTextDescription: '#FFFFFF', //สี icon ลูกศร
                                        colorBgBase: '#001D5F', //สี content ใน sub select
                                        // colorText:'#E88D67', //สี Text ใน sub select
                                        colorFillTertiary: '#0047ff',//สีตอนเมาสชี้
                                        colorPrimaryBg: '#0047FF',// สีตอนเลือก'
                                        borderRadiusSM: 3,  //border ตัวที่เลือก
                                        borderRadiusLG: 3, //กรอบใหญ่ ใน sub select
                                        // colorSplit:'red',
                                        // borderRadiusOuter
                                        // border

                                        // controlItemWidth: 10,
                                        // controlWidth: 500,
                                        // dropdownHeight: 50,
                                        // menuPadding: 50,
                                        // optionPadding: 50,
                                        // optionSelectedFontWeight: 50,
                                      },
                                    }}
                                  >
                                    {/* <Selectstyle
                                    
                                        borderColor: '#0047ff',  // Corrected spelling and set color to blue
                                        background: 'none'
                                      },
                                    }}
                                  > */}
                                    <Cascader
                                      // className='Cascader2'
                                      options={categoryOption}
                                      value={filtercat.all}
                                      displayRender={handleDisplayRender}
                                      allowClear={false}
                                      onChange={handleCascaderClick}
                                      placeholder="Project category"
                                      showSearch={{
                                        filter: (inputValue, path) =>
                                          path.some(option => option.label.toLowerCase().includes(inputValue.toLowerCase())),
                                      }}
                                      style={{ width: '100%', height: '40px' }}  // Set borderColor here as well
                                      className="custom-cascader01"
                                      changeOnSelect
                                      disabled={(dataonprojcet.stage_id === '7acfb2d4-eb27-406d-a0ff-f4dff01c7460' || dataonprojcet.stage_id == "") ? false : true}
                                    />
                                  </ConfigProvider>
                                </div>


                                <div className='fontheader row' style={{ fontSize: '18px', margin: 0, padding: 0 }}>
                                  <div style={{ marginTop: '0px', marginRight: '15px' }}>UNIVERSAL</div><Switch isChecked={dataonprojcet.is_universal}
                                    onChange={(e) => {
                                      if (dataonprojcet.stage_id !== '798520e8-c54c-4e92-b4bf-012d08d2debb') {
                                        setdataonprojcett({ ...dataonprojcet, is_universal: !dataonprojcet.is_universal });
                                      }

                                    }}
                                  />
                                </div>
                                <div className='row' style={{ margin: 0, padding: 0 }}>

                                  <label htmlFor="fileUpload2" class="buttonuploadpackage" style={{ textAlign: 'center', paddingTop: '8px', marginTop: '50px' }}>UPLOAD COVERING</label>
                                  <input type="file" id="fileUpload2" disabled={(dataonprojcet.stage_id === '7acfb2d4-eb27-406d-a0ff-f4dff01c7460' || dataonprojcet.stage_id == "") ? false : true} onChange={handleFileChange2} accept=".jpg, .jpeg, .png" style={{ display: 'none' }} />
                                </div>
                                {/*        <div className='row' style={{ margin: 0, padding: 0 }}>
                                  <button className=' btn-outline-primary' style={{ padding: '5px', width: '100%' }}
                                    disabled={dataonprojcet.stage_id === '798520e8-c54c-4e92-b4bf-012d08d2debb' ? true : false}
                                    onClick={() => {
                                      setConfrimContron({
                                        ...confrimContron,
                                        is_mode: true,
                                        massger: 'Do you want to save data?'
                                      })
                                      toggle_modal_confrimModal()

                                    }}> SAVE CHANGES </button>


                                </div> */}

                              </div>
                            </div>

                            <div className='cardpackageship fontheader' style={{ marginRight: '78px', cursor: 'context-menu' }}>
                              <div className='row' style={{ fontSize: '18px', fontWeight: '600' }}>Spaceship</div>
                              <div className='row' style={{ fontSize: '16px', fontWeight: '100', marginBottom: '15px' }}> Wait for operation</div>
                              <Set
                                fncAlert={(mesasge, status) => {
                                  setErrorMessage({ ...errorMessage, message: mesasge, status: status });
                                  toggle_modal_alertKick();
                                }}
                                callback={callBackspace}
                                data={dataonprojcet.spaceship_interres}

                                mode="offer" project_id={dataonprojcet.id} project_name={dataonprojcet.name} project_stage_id={dataonprojcet.stage_id}
                              />
                            </div>
                            <div className='cardpackageship fontheader' style={{ cursor: 'context-menu' }}>
                              <div className='row' style={{ fontSize: '18px', fontWeight: '600' }}>Spaceship</div>
                              <div className='row' style={{ fontSize: '16px', fontWeight: '100', marginBottom: '15px' }}> Viewer</div>
                              <Set
                                fncAlert={(mesasge, status) => {
                                  setErrorMessage({ ...errorMessage, message: mesasge, status: status });
                                  toggle_modal_alertKick();
                                }}
                                callback={callBackspace}
                                data={dataonprojcet.spaceship_view} mode="view" project_id={dataonprojcet.id} project_name={dataonprojcet.name} project_stage_id={dataonprojcet.stage_id} />
                            </div>
                          </div>
                        </>)
                        :
                        (<>
                        </>)}





                      {/* <div className='containnerProjectMgt'> */}
                      {/* {dataprojcet.length > 0 && dataonprojcet.no === false ?
                          (<>
                          </>)
                          :
                          (<>
                          </>)} */}
                      {/* </div> */}

                      {/* 
                      <div className='cardpackagein row' style={{ clipPath: `polygon(0 0, 3px 0, ${dataprojcet.length > 0 ? `3px 4px, ${dataprojcet.length * 198.4 >= 1606 ? 1606 : dataprojcet.length * 198.8}px 4px, ${dataprojcet.length * 198.4 >= 1606 ? 1606 : dataprojcet.length * 198.8}px 0` : '0 0, 0 0, 0 0'}, 100% 0, 100% 100%, 0 100%` }}>
                        {dataprojcet.length > 0 && dataonprojcet.no === false ?
                          (<>






                            <div className='cardpackagedetil'>


                              <div className='cardpackageimg' style={{ width: '229px', height: '320px', position: 'relative', cursor: 'pointer' }} onClick={() => {
                                if (selectedFile) {
                                  downloadFile(selectedFile)
                                }

                              }}>
                                <div style={{ position: 'absolute', fontSize: '11px', width: '223px', height: '30px', zIndex: '3', background: 'rgb(18, 18, 21, 0.3)', marginTop: '285px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingTop: '5px' }}>{selectedFile ? selectedFile[0].name : ''}</div>
                              

                                {pathicon !== null ?
                                  <img src={pathicon} style={{ width: '224px', height: '312px', objectFit: 'contain', border: '0px' }} />

                                  :
                                  <></>}
                             

                              </div>




                              <div className='cardpackageimg' style={{ width: '229px', height: '320px', marginTop: '35px', cursor: 'pointer' }} onClick={() => {
                                if (selectedFile2) {
                                  downloadFile(selectedFile2)
                                }

                              }}>
                                <div style={{ position: 'absolute', fontSize: '11px', width: '223px', height: '30px', zIndex: '3', background: 'rgb(18, 18, 21, 0.3)', marginTop: '285px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingTop: '5px' }}>{selectedFile2 ? selectedFile2[0].name : ''}</div>
                               
                                {pathicon2 !== null ?
                                  <img src={pathicon2} style={{ width: '224px', height: '312px', objectFit: 'contain', border: '0px' }} />
                                  :
                                  <></>}
                             

                              </div>



                              <div className='posion'>
                                <div className='fontheader row' style={{ fontSize: '18px', margin: 0, padding: 0 }}>
                                  <div style={{ marginTop: '0px', marginRight: '15px' }}> PRIVATE</div><Switch

                                    isChecked={dataonprojcet.private} onChange={(e) => {
                                      if (dataonprojcet.stage_id !== '798520e8-c54c-4e92-b4bf-012d08d2debb') {
                                        setdataonprojcett({ ...dataonprojcet, private: !dataonprojcet.private });
                                      }
                                    }
                                    }


                                  />
                                </div>
                                {dataonprojcet.private === true ?
                                  (<>
                                    <div className='row' style={{ justifyContent: 'space-between', margin: '0 0 5px 0', padding: 0 }}>
                                      <div> ID : {dataonprojcet.idroom}</div>
                                      <div style={{ cursor: 'pointer', marginRight: '5px' }} onClick={(e) => {

                                        if ((dataonprojcet.passroom || '').toString().trim() === '') {
                                          setdataonprojcett({ ...dataonprojcet, islock: !dataonprojcet.islock, setpass: true })
                                        } else {
                                          setdataonprojcett({ ...dataonprojcet, islock: !dataonprojcet.islock })
                                        }

                                      }}> <img src={dataonprojcet.islock === true ? iconlock : iconunlock} style={{ height: '20px', width: '20px', marginTop: '-7px' }} /></div>
                                    </div>

                                    {dataonprojcet.islock === true ?
                                      (<>
                                        <div className='row packageInputOnLock' style={{ border: `${dataonprojcet.setpass === true ? '1px solid #0047FF' : '1px solid transparent'}` }}>
                                          {dataonprojcet.setpass === true ? (
                                            <form autoComplete="off">
                                              <input
                                                className='packageInputOnLockText'
                                                autoComplete="new-password"
                                                type={dataonprojcet.isshowpass === false ? "password" : "text"}
                                                key={dataonprojcet.idroom}
                                                value={dataonprojcet.passroom}
                                                disabled={dataonprojcet.stage_id === '798520e8-c54c-4e92-b4bf-012d08d2debb' ? true : false}

                                                onBlur={() => {
                                                  if (dataonprojcet.passroom.length === 0) {
                                                    setdataonprojcett({ ...dataonprojcet, islock: false, isshowpass: false, setpass: false })
                                                  } else {
                                                    setdataonprojcett({ ...dataonprojcet, setpass: false })
                                                  }
                                                }
                                                }

                                                onChange={(e) => {
                                                  let psssdis = ''
                                                  if (e.target.value.trim().length > 0) {
                                                    for (let p of e.target.value) {
                                                      psssdis += '*'
                                                    }
                                                  }
                                                  setdataonprojcett({ ...dataonprojcet, passroom: e.target.value.trim(), passroomdis: psssdis })

                                                }}
                                                autoFocus
                                                style={{ background: 'none', color: '#0047FF', width: '200px' }}
                                              // onKeyDown={(e) => {
                                              //   if (e.keyCode === 13) {
                                              //     console.log('dsdds', e);


                                              //   }
                                              // }}
                                              />
                                            </form>
                                          ) : (
                                            <span className='packageInputOnLockPassword' onDoubleClick={(e) => { setdataonprojcett({ ...dataonprojcet, setpass: true }) }}>
                                              {dataonprojcet.isshowpass === true ? <>{dataonprojcet.passroom}</> : <>{dataonprojcet.passroomdis}</>}
                                            </span>
                                          )}
                                          <div className='packageInputOnLockSee' onClick={(e) => { setdataonprojcett({ ...dataonprojcet, isshowpass: !dataonprojcet.isshowpass }); }}> <img src={iconviewpass} style={{ height: '20px', width: '20px', marginTop: '-7px' }} /></div>
                                        </div>
                                      </>)
                                      :
                                      (<></>)}




                                  </>)
                                  :
                                  (<></>)}


                                <div className='row' style={{ margin: 0, padding: 0 }}>

                                  <label htmlFor="fileUpload" class="buttonuploadpackage" style={{ textAlign: 'center', paddingTop: '8px' }}>UPLOAD TOR / SPEC</label>
                                  <input type="file" id="fileUpload" disabled={dataonprojcet.stage_id === '798520e8-c54c-4e92-b4bf-012d08d2debb' ? true : false} onChange={handleFileChange} accept=".pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png" style={{ display: 'none' }} />
                                </div>


                                <div className='row' style={{ margin: 0, padding: 0 }}>
                                  


                                </div>
                                <div className='row' style={{ margin: 0, padding: 0 }}>
                                  <div className="input-with-image">
                                    <NumericFormat type="text" placeholder="PROJECT BUDGET" value={dataonprojcet.budget}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                      disabled={dataonprojcet.stage_id === '798520e8-c54c-4e92-b4bf-012d08d2debb' ? true : false}
                                      onChange={(e) => setdataonprojcett({ ...dataonprojcet, budget: e.target.value })} style={{ background: 'none', color: '#ffffff' }} />
                                    {dataonprojcet.stage_id !== '798520e8-c54c-4e92-b4bf-012d08d2debb' ? <><img src={iconedit} className="image" /></> : <></>}
                                  </div>
                                </div>

                                <div className='row' style={{ margin: 0, padding: 0 }}>
                                  <RangePicker format="DD-MM-YYYY" className='input-with-image' value={[dataonprojcet.timlinestart ? dayjs(dataonprojcet.timlinestart) : '', dataonprojcet.timlineend ? dayjs(dataonprojcet.timlineend) : '']}
                                    disabled={dataonprojcet.stage_id === '798520e8-c54c-4e92-b4bf-012d08d2debb' ? true : false}
                                    onChange={(date) => {
                                      if (date.length == 2) {
                                        setdataonprojcett({ ...dataonprojcet, timlinestart: new Date(date[0]), timlineend: new Date(date[1]) })

                                      


                                      }
                                    }}
                                  />

                                </div>

                                <div className='row' style={{ margin: 0, padding: 0, marginBottom: '15px' }}>

                                  <Cascader
                                    options={categoryOption}
                                    value={filtercat.all}
                                    displayRender={handleDisplayRender}
                                    onChange={handleCascaderClick}
                                    placeholder="Project category"
                                    showSearch={{ filter: (inputValue, path) => path.some(option => option.label.toLowerCase().includes(inputValue.toLowerCase())) }}
                                    style={{ width: '100%' }}
                                    changeOnSelect
                                    disabled={dataonprojcet.stage_id !== '798520e8-c54c-4e92-b4bf-012d08d2debb' ? false : true}


                                  />






                       



                                </div>
                                <div className='fontheader row' style={{ fontSize: '18px', margin: 0, padding: 0 }}>
                                  <div style={{ marginTop: '0px', marginRight: '15px' }}>UNIVERSAL</div><Switch isChecked={dataonprojcet.is_universal}
                                    onChange={(e) => {
                                      if (dataonprojcet.stage_id !== '798520e8-c54c-4e92-b4bf-012d08d2debb') {
                                        setdataonprojcett({ ...dataonprojcet, is_universal: !dataonprojcet.is_universal });
                                      }

                                    }}
                                  />
                                </div>
                                <div className='row' style={{ margin: 0, padding: 0 }}>

                                  <label htmlFor="fileUpload2" class="buttonuploadpackage" style={{ textAlign: 'center', paddingTop: '8px', marginTop: '50px' }}>UPLOAD COVERIMG</label>
                                  <input type="file" id="fileUpload2" disabled={dataonprojcet.stage_id === '798520e8-c54c-4e92-b4bf-012d08d2debb' ? true : false} onChange={handleFileChange2} accept=".jpg, .jpeg, .png" style={{ display: 'none' }} />
                                </div>
                                <div className='row' style={{ margin: 0, padding: 0 }}>
                                  <button className=' btn-outline-primary' style={{ padding: '5px', width: '100%' }}
                                    disabled={dataonprojcet.stage_id === '798520e8-c54c-4e92-b4bf-012d08d2debb' ? true : false}
                                    onClick={() => {
                                      setConfrimContron({
                                        ...confrimContron,
                                        is_mode: true,
                                        massger: 'Do you want to save data?'
                                      })
                                      toggle_modal_confrimModal()
                                    
                                    }}> SAVE CHANGES </button>
                             

                                </div>

                              </div>
                            </div>

                            <div className='cardpackageship fontheader' style={{ marginRight: '35px', cursor: 'context-menu' }}>
                              <div className='row' style={{ fontSize: '18px', fontWeight: '600' }}>Spaceship</div>
                              <div className='row' style={{ fontSize: '16px', fontWeight: '100', marginBottom: '15px' }}> Wait for operation</div>
                              <Set
                                fncAlert={(mesasge, status) => {
                                  setErrorMessage({ ...errorMessage, message: mesasge, status: status });
                                  toggle_modal_alertKick();
                                }}
                                callback={callBackspace}
                                data={dataonprojcet.spaceship_interres} mode="offer" project_id={dataonprojcet.id} project_name={dataonprojcet.name} project_stage_id={dataonprojcet.stage_id} />



                            </div>
                            <div className='cardpackageship fontheader' style={{ cursor: 'context-menu' }}>
                              <div className='row' style={{ fontSize: '18px', fontWeight: '600' }}>Spaceship</div>
                              <div className='row' style={{ fontSize: '16px', fontWeight: '100', marginBottom: '15px' }}> Viewer</div>
                              <Set
                                fncAlert={(mesasge, status) => {
                                  setErrorMessage({ ...errorMessage, message: mesasge, status: status });
                                  toggle_modal_alertKick();
                                }}
                                callback={callBackspace}
                                data={dataonprojcet.spaceship_view} mode="view" project_id={dataonprojcet.id} project_name={dataonprojcet.name} project_stage_id={dataonprojcet.stage_id} />



                            </div>




                          </>)
                          :
                          (<>
                          </>)}

                      </div> */}



                    </>)
                  :
                  (<>

                    <img src={Construction} style={{ width: "100%", height: "100%", marginTop: "10px" }} />



                  </>)

                }




              </div>
            </div>
          </>)
        }

        <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
          <div className='alertKickBoxShadow' >
            <div className='alertKickBox' style={{ height: 'fit-content' }}>
              <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: `${errorMessage.status ? 'red' : 'green'}` }}>
                    {errorMessage.status ? '! ERROR !' : 'SUCCESS'}
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning'>
                    {errorMessage.status ? 'WARNING' : 'SUCCESS'}
                  </div>
                </div>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    {errorMessage.message}

                  </div>
                </div>


                <div className='row alertKickBoxButton'>
                  <button className='alertKickButton' onClick={() => {
                    toggle_modal_alertKick()
                  }}>OK</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </ConfigProvider>


      <div className='alertKick' style={{ display: `${confrimModal ? '' : 'none'}` }}>
        <div className='alertKickBoxShadow'>
          <div className='alertKickBox'>
            <div className='alertKickBoxIn'>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: 'orange' }}>
                  ! warning !
                </div>
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning'>
                  WARNING
                </div>
              </div>
              <div className='row' style={{ height: '12.5%' }}>
                <div className='alertKickText'>
                  {confrimContron.massger}
                  {/*  Do you want to delete data? */}
                </div>
              </div>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickTextSec'>
                  You won't be able to revert this!
                </div>
              </div>
              <div className='row alertKickBoxButton' >
                <button className='mr-2 alertKickConfirm' onClick={() => {
                  if (confrimContron.is_mode === false) {
                    deleteItem(tempValue.del_id)
                  } else {
                    if (dataonprojcet.id == '') {
                      addProject();
                    } else {
                      updateProject(dataonprojcet.id);
                      setCheckFileList({
                        TOR: false,
                        COVER: false
                      });
                    }

                  }

                }}>YES</button>
                <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                  toggle_modal_confrimModal()
                }}>NO</button>


              </div>

            </div>
          </div>
        </div>
      </div>

      {/* <div className='alertKick' style={{ display: `${alertKickMassge ? '' : 'none'}` }}>
        <div className='alertKickBoxShadow'>
          <div className='alertKickBox' style={{ height: 'fit-content', width: '429 !important' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content', width: '490px !important' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `#ffcc00` }}>
                  {'! WARNING !'}
                </div>
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning'>
                  {'WARNING'}
                </div>
              </div>

              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickTextSec'>
                  {'It cannot be deleted because the ship is already on in space.'}
                </div>
              </div>
              <div className='mt-4 row alertKickBoxButton'>
                <button className='alertKickButton' onClick={() => {

                  toggle_modal_alertKickMassge()
                  setErrorMasgerList({
                    ...errorMasgerList,
                    isError: false,
                    massger: []
                  })
                }}>OK</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      <div style={{ display: `${spaceshipeViewModal ? '' : 'none'}`, backgroundColor: 'red', width: '100%', height: '100%', position: 'absolute', zIndex: 999 }}></div>

      <ModelAlertMassge
        isOpen={alertKickMassge}
        toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
        message={messageBox}
        mainFuc={() => { }}
      />

      <ModelAlertMassge
        isOpen={successMassge}
        toggle_alertKick={() => { toggle_modal_successMassge() }}
        message={messageBox}
        mainFuc={() => {  }}
      />

      <ProjectStatePreview
        isOpen={projectState.isOpen}
        setProjectState={setProjectState}
      />

      {confirmModal && (
        <div className='alertKick' style={{ display: confirmModal ? '' : 'none' }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox'>
              <div className='alertKickBoxIn'>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: 'orange' }}>
                    ! warning !
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning'>
                    WARNING
                  </div>
                </div>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    Do you want to delete
                  </div>
                </div>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickTextSec'>
                    You won't be able to revert this!
                  </div>
                </div>
                <div className='row alertKickBoxButton'>
                  <button
                    className='mr-2 alertKickConfirm'
                    onClick={(e) => {
                      setMessageBox({
                        message: 'success',
                        isError: false,
                        menuTitle: messageBox.menuTitle
                      });
                      setSuccessMassge(true);
                      delMyProject(dataonprojcet.id);
                    }}
                  >
                    YES
                  </button>

                  <button
                    className='alertKickConcel'
                    style={{ border: '1px solid red !important' }}
                    onClick={toggleModalConfirmModal}
                  >
                    NO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox' style={{ height: 'fit-content' }}>
              <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: 'green' }}>
                    Loading
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning' style={{ border: 'none' }}>
                    <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Package;
