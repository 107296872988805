import React, { useEffect, useState, useRef } from 'react'
import "./style.css"
import {
    CaretDownOutlined,
    CheckOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
function SelectBoxLLL({ option, value, label, onChange, id }) {
    const generateId = () => {
        return 'box-' + Math.random().toString(36).substr(2, 9);
    };
    const [code, setId] = useState(generateId());
    const [optionList, setOptionList] = useState([


    ]);
    const [valueSelect, setValueSelect] = useState({
        label: '',
        value: '',
        fontColor: '#0094FF',
        bgColor: '#182732'
    });

    const [openDrillSelect, setOpenDrillSelect] = useState(false);
    const toggle_drill_select = () => setOpenDrillSelect(!openDrillSelect);
    const [positionDrillDown, setPositionDrillDown] = useState({
        x: '',
        y: ''
    })


    useEffect(() => {

        
        setOptionList(option);
        let getvalue = option.filter((e) => {
            return e.value == value
        });
        if (getvalue.length > 0) {
            setValueSelect({
                ...valueSelect,
                label: getvalue[0].label,
                value: getvalue[0].value,
                fontColor: getvalue[0].fontColor,
                bgColor: getvalue[0].bgColor

            });
        } else {
            setValueSelect({
                ...valueSelect,
                label: '',
                value: '',
                fontColor: '#0094FF',
                bgColor: '#182732'

            });
        }
    }, [option, value])


    useEffect(() => {

        document.addEventListener('click', (event) => {
            const clickedElement = event.target;
            /*  console.log(clickedElement.id); */
            if (clickedElement.id === `SelectBoxvalue-${code}` || clickedElement.id === `SelectBoxLLL-${code}`) {
                const elements = document.getElementById(`boxdop-${code}`);
                console.log(elements.style.display);

                if (elements.style.display == 'none') {
                    elements.style.display = 'block';
                } else {
                    elements.style.display = 'none';
                }
            } else {
                const elements = document.getElementById(`boxdop-${code}`);
                if (elements) {
                    elements.style.display = 'none';
                }
            }

        });
    }, []);

    return (
        <>

            <div className='SelectBoxLLL' id={`SelectBoxLLL-${code}`} style={{ background: valueSelect.bgColor || '#182732', color: valueSelect.fontColor || '#0094FF', zIndex: '999' }}
                onClick={(event) => {
                    const rect = event.currentTarget.getBoundingClientRect();
                    const x = rect.left;
                    const y = rect.top;

                    setPositionDrillDown({ ...positionDrillDown, x: x, y: y });

                }}
            >
                <div className='SelectBoxLLLValue' id={`SelectBoxvalue-${code}`}>
                    {valueSelect.label != '' ? valueSelect.label : 'Select ...'}
                </div>
                <div className='SelectBoxLLLArrow' id={`SelectBoxvalue-${code}`}>
                    <CaretDownOutlined />
                </div>
            </div>
            <div className='SelectBoxLLLDrillDown' id={`boxdop-${code}`}
                style={{
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                    display: `${openDrillSelect ? 'block' : 'none'}`,

                    fontSize: '20px'
                }}
            >
                {
                    optionList.map((item) => {
                        return (<>
                            <div className='SelectBoxLLLDrillDownList'
                                style={{
                                    background: item.bgColor || '#182732', color: item.fontColor || '#0094FF',
                                    width: `${document.getElementById(`SelectBoxLLL-${code}`).offsetWidth}px`,
                                    height: '36px',
                                    gap: '5px',

                                }}
                                onClick={() => {
                                    onChange(item);
                                    setValueSelect({
                                        ...valueSelect,
                                        label: item.label,
                                        value: item.value,
                                        fontColor: item.fontColor,
                                        bgColor: item.bgColor

                                    });
                                }}
                            >
                                {item.label} {item.value == valueSelect.value && (<>{" "}<CheckCircleOutlined /></>)}
                            </div>

                        </>)
                    })
                }
            </div>

            {/*   {openDrillSelect && (<>
      
        </>)} */}

        </>

    )
}

export default SelectBoxLLL;
