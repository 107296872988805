import React, { useState, useEffect, useRef } from 'react'
import './stageElementStyle.css'
import Configs from "../../../config";
import axios from 'axios';
import moment from 'moment';
import spaceShipImage from '../../../assets/img/iconSpaceShip/Final-Logo.png'
import { send } from 'q';
import clipPaperIcon from '../../../assets/img/icon/clippaper.png';
import picIcon from '../../../assets/img/icon/photo.png';
import { io } from 'socket.io-client';
import Select from 'react-select'
import imgMi from './img/Vector.png'
import DefaultUserProfile from '../../../assets/img/userlogo/defultUser.png';
import MOMAttendant from './MomAttendant/momAttendant';
import Swal from 'sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
import tagMom from './img/tagMom.png'
import { Tooltip } from 'antd';
const SrsArea = ({
    isOpen,
    project_id,
    process_id,
    menu_id,
    // elementSend_,
    // resetElementSelect,
    toggle_modal_confrimModal,
    swapStateForSrsArea,
    callbackSrsDataFunc,
    updateEditSrsData,
    elementData,
    dataUserPercentList,
    processStage,
    setIndexChat,
    setToCardStage,
    imgData,
    setImgData
}) => {
    const [test, setTest] = useState({
        test: ''
    });
    const [sendFile, setSendFile] = useState([]);
    const [sendFileView, setSendFileView] = useState([]);
    const [sendImg, setSendImg] = useState([]);
    const [sendData, setSendData] = useState({
        text: '',
    });
    const [sendSearch, setSendSearch] = useState({
        text: ''
    });
    const [srsAreaData, setSraAreaData] = useState([]);

    const [uploadCheck, setUploadCheck] = useState(false);
    const toggle_modal_uploadCheck = () => { setUploadCheck(!uploadCheck) };
    const [uploadMode, setUploadMode] = useState({
        mode: ''
    })

    const [loadCheck, setLoadCheck] = useState(false);
    const toggle_modal_loadCheck = () => { setLoadCheck(!loadCheck) };
    const [loadMode, setLoadMode] = useState({
        mode: ''
    })
    const [loadData, setLoadData] = useState([]);

    const [srsAreaSelectId, setSrsAreaSelectId] = useState({
        id: ''
    });

    const [mandaysPopup, setMandaysPopup] = useState(false);
    const toggle_modal_mandaysPopup = () => {
        setMandaysSend({ text: null, description: null });
        mandaysSend.text = null;
        mandaysSend.description = null;
        setMandaysPopup(!mandaysPopup)
    };
    const [mandaysSend, setMandaysSend] = useState({
        text: null,
        description: null,
    });

    const [moveMenuPopup, setMoveMenuPopup] = useState(false);
    const toggle_modal_moveMenuPopup = () => { setMoveMenuPopup(!moveMenuPopup);  };
    const [menuData, setMenuData] = useState([]);
    const [moveMenuSend, setMoveMenuSend] = useState({
        menu_id: '',
        sm_id: ''
    });
    const [displayMode, setDisplayMode] = useState(false)

    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                //path: '/Socket-service/socket.io'
                port: Configs.TTT_Game_Socket_Service_Port
            });
        }
    }, [

    ])

  

    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const [speech2Text, setSpeech2Text] = useState({
        test: ''
    })


    const pageSize = 10;
      const [page, setPage] = useState(1);
      const containerRef = useRef(null);
    const [hasMoreData, setHasMoreData] = useState(true); 
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [isLoadingChat, setIsLoadingChat] = useState(false);
    const [checkStatusScroll, setCheckStatusScroll] = useState(true); 


    const [loading, setLoading] = useState(false);
    // console.log("containerRef",containerRef);
    


    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
                audioChunksRef.current.push(event.data);
            }
        };

        mediaRecorderRef.current.onstop = async () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
            const dataAuido = new FormData();

            dataAuido.append('Profile', audioBlob, 'recording.mp3');

            const audioURL = URL.createObjectURL(audioBlob);
            dataAuido.append('typeFile', "radio");
            dataAuido.append('projectKey', "radio");

            try {
                await axios({
                    method: 'post',
                    url: `${Configs.TTT_Game_Ai_Service}/api/NewS2T_V2`,
                    headers: {
                        /*  Authorization: getToken(), */
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    data: dataAuido,
                }).then(async function (response) {
                    console.log('file', response.data);
                    let last_text = sendData.text || '';
                    setSendData({ ...sendData, text: last_text + response.data.text })

                })
                console.log('Upload successful');
            } catch (error) {
                console.error('Upload failed', error);
            }

            setAudioURL(audioURL);
            audioChunksRef.current = [];
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
        console.log('start');
        console.log(audioURL);
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
        console.log('stop');
        console.log(audioURL);
    };

    const S2TAPI = async () => {
        try {
            const dataFile = new FormData();
            for (let i = 0; i < sendFile.length; i++) {
                console.log('kooo')
                console.log(sendFile[i].file)
                dataFile.append('AudioFile', sendFile[i].file);
            }
            axios({
                method: "POST",
                url: "http://192.168.0.152:8000/api/NewS2T_V2/",
                //     headers: {
                //         /*     Authorization: getToken(), */
                //         "X-TTT": `${Configs.API_TTT}`,
                //   'Content-Type': 'multipart/form-data',
                //     },
                data: dataFile,
            }).then((res) => {


            }).catch((err) => {

            })
            //   const response = await axios.get('http://150.95.25.61:8000/api/STT');\
            /* const response = await axios.post('http://192.168.0.201:8000/api/TEST',{test:'hellow'});
              setSpeech2Text(response.data); */
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const fetchAPI = async () => {
        try {

            //  let temp = {
            //     "firstName": "Jane",
            //     "lastName": "Doe",
            //     "hobbies": ["running", "sky diving", "singing"],
            //     "age": 35,
            //     "children": [
            //         {
            //             "firstName": "Alice",
            //             "age": 6
            //         },
            //         {
            //             "firstName": "Bob",
            //             "age": 8
            //         }
            //     ]
            // }
            const dataFile = new FormData();
            for (let i = 0; i < sendFile.length; i++) {
                console.log(sendFile[i].file)
                dataFile.append('Profile', sendFile[i].file);
            }
            axios({
                method: "POST",
                url: "http://192.168.0.152:8000/api/TEST",
                headers: {
                    /*     Authorization: getToken(), */
                    "X-TTT": `${Configs.API_TTT}`,
                    'Content-Type': 'multipart/form-data',
                },
                data: dataFile,
            }).then((res) => {


            }).catch((err) => {

            })
            //   const response = await axios.get('http://150.95.25.61:8000/api/STT');\
            /* const response = await axios.post('http://192.168.0.201:8000/api/TEST',{test:'hellow'});
              setSpeech2Text(response.data); */
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

 
    
    useEffect(() => {

        if (isOpen) {

            let getUserPermisstionMode = sessionStorage.getItem('getUserMode')
            /*  console.log('cream',getUserPermisstionMode); */
            setDisplayMode(getUserPermisstionMode)

            getMOMData();
            setHasMoreMessages(true);
            setPage(1);
            getSrsAreaData();
        } else {
            // console.log('testlog toggle_modal_loadCheck();', loadCheck);
            setDisplayMode(false)
            if (moveMenuPopup) {
                toggle_modal_moveMenuPopup();
            };
            if (uploadCheck) {
                toggle_modal_uploadCheck();
            };
            if (mandaysPopup) {
                toggle_modal_mandaysPopup();
            };
            setSraAreaData([]);
            setSendData({ ...sendData, text: '' });
        }
        // console.log('testlog', onMenu);
        if (isOpen === true) {

            socketRef.current.emit('leaveOne', "PROCESSELEMENTROOM:" + menu_id + process_id)
            socketRef.current.emit('join', "PROCESSELEMENTROOM:" + menu_id + process_id)


        } else {
            socketRef.current.emit('leaveOne', "PROCESSELEMENTROOM:" + menu_id + process_id)

        }
    }, [isOpen]);

    useEffect(() => {


        delSrsAreaData();
    }, [swapStateForSrsArea]);




    useEffect(() => {


        const { current: socket } = socketRef;
        const socFnc = (data) => {
            // console.log('onRoom', data)
            getSrsAreaData(page,pageSize, data.project_id, data.process_id, data.menu_id);

        }
        try {
            socket.open();



            socket.on('chat', socFnc)




        } catch (error) {
            console.log(error);
        }
        // Return a callback to be run before unmount-ing.

        return () => {

            socket.off("chat", socFnc);

            socket.close();
            /*     setActiveRoom({ ...activeRoom, room: '' });
                activeRoom.room = ''; */
        };
        /* } */



    }, []);

    useEffect(() => {
        if (setIndexChat != null) {
            setTest({ ...test, test: '' });
            test.test = '';
        }
     
    }, [setIndexChat])

    
    useEffect(() => {
        // console.log('testlog', updateEditSrsData.no);
        
        if (updateEditSrsData.no > 0) {
            socketRef.current.emit('chat', { message: 'processElement', room: "PROCESSELEMENTROOM:" + menu_id + process_id, menu_id: menu_id, process_id: process_id, project_id: project_id });
        }
        // const sortedNewData = srsAreaData.sort((a, b) => new Date(a.sm_created_date) - new Date(b.sm_created_date));
            
        // setSraAreaData((prevData) => [
        //     ...sortedNewData,
        //     ...prevData
        // ]);
    }, [updateEditSrsData]);


    const [fileUploadList, setFileUploadList] = useState([]);


    const handleScroll = async (e) => {
        const outerDiv = e.target;
        const { scrollTop } = outerDiv;
    
        // เช็คว่าเลื่อนถึงบนสุดหรือไม่
        if (scrollTop === 0 && hasMoreMessages && !isLoadingChat) {
            const prevScrollHeight = outerDiv.scrollHeight; // บันทึกความสูงก่อนโหลดข้อมูลใหม่
            
            setPage((prevPage) => prevPage + 1); 
            await getSrsAreaData(page + 1); 
    
            const newScrollHeight = outerDiv.scrollHeight; // ความสูงใหม่หลังข้อมูลถูกโหลด
            outerDiv.scrollTop = newScrollHeight - prevScrollHeight; // ปรับ scroll ให้คงตำแหน่งเดิม
        }
    };
    
 
    function scrollToBottom() {
        const outerDiv = document.getElementById('srsAreaAutoScroll');
        if (outerDiv) {
            outerDiv.scrollTop = outerDiv.scrollHeight; // เลื่อน Scroll ไปที่ล่างสุด
        }
    }

   async function getSrsAreaData(page, enties, project, process, menu) {
   
        setIsLoadingChat(true);
        // setCheckStatusScroll(false)
        // let outerDiv = document.getElementById('srsAreaAutoScroll');
        // const prevScrollHeight = outerDiv.scrollHeight;
        let outerDiv = document.getElementById('srsAreaAutoScroll');
        const prevScrollHeight = outerDiv.scrollHeight; // ความสูงก่อนโหลดข้อมูลใหม่
        const prevScrollTop = outerDiv.scrollTop;

        let temp = {
            page: page || 1,
            enties: pageSize || 10,
            project_id: project_id ? project_id : project,
            process_id: process_id ? process_id : process,
            menu_id: menu_id ? menu_id : menu,
            search: sendSearch.text.trim()
        }
        
        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/srsMenu/getSRSArea`,
            headers: {
                /*     Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                const newData = response.data;
       
                if (response.data.length === 0) {
                    setHasMoreMessages(false);
                }else{
                    // const sortedNewData = newData.sort((a, b) => new Date(a.sm_created_date) - new Date(b.sm_created_date));
            
                    // setSraAreaData(newData);
                    let newSetDate = [...srsAreaData , ...newData]
                    if(newSetDate.length > 0){
                    let spoet =  newSetDate.sort((a,b) => {
                            return a.sm_created_date.localeCompare(b.sm_created_date)
                        })

                        setSraAreaData(spoet);
                    }
              
                }
         
             
           
                setTest({ ...test, test: '' })
                test.test = ''


                let temp = imgData;
                for (let item of response.data) {
                    if (item.fd_img_id) {
                        let checkIsImg = temp.filter((e) => { return e.fd_img_id == item.fd_img_id });
                        if (checkIsImg.length > 0) {
                            item.ul_file_path = checkIsImg[0].ul_file_path;
                        } else {
                            let response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                responseType: 'blob',
                            });

                            let imgUrl = URL.createObjectURL(response2.data);

                            temp.push({
                                fd_img_id: item.fd_img_id,
                                ul_file_path: imgUrl
                            });

                            item.ul_file_path = imgUrl;
                        }
                    }

                    if (item.element && item.element.length > 0) {
                        for (let item2 of item.element) {
                            if (item2.fd_img_id) {
                                let check = imgData.filter((e) => { return e.fd_img_id == item2.fd_img_id });
                                if (check.length > 0) {
                                    item2.elm_img_path = check[0].elm_img_path;
                                };

                            };
                        };
                    };
                    setImgData(temp);
                    setTest({ ...test, test: '' })
                    test.test = ''
                };

                // const newScrollHeight = outerDiv.scrollHeight;
                // outerDiv.scrollTop = newScrollHeight - prevScrollHeight;
                const newScrollHeight = outerDiv.scrollHeight;
                if (page > 1) {
                    // ถ้าเป็นการโหลดข้อความเก่า จะคงตำแหน่งเดิมของ scroll
                    outerDiv.scrollTop = newScrollHeight - prevScrollHeight + prevScrollTop;
                } else {
                    // ถ้าเป็นการโหลดครั้งแรก จะเลื่อน scroll ไปที่ล่างสุด
                    outerDiv.scrollTop = outerDiv.scrollHeight;
                }
                setIsLoadingChat(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function setScrollAuto() {
        const outerDiv = document.getElementById('srsAreaAutoScroll');
        if (outerDiv) {
            outerDiv.scrollTop = outerDiv.scrollHeight; // เลื่อนลงสุด
        }
    }
    // function setScrollAuto() {
    //     const interval = setInterval(() => {
    //         let outerDiv = document.getElementById('srsAreaAutoScroll');
        
           
    //         if (setIndexChat) {
    //           let innerDiv = document.getElementById('chatAreaIndex' + (setIndexChat.indexKey - 2));
    //           if (innerDiv) {
    //             outerDiv.scrollTop = 0; 
    //             innerDiv.style.animation = '';
        
            
    //             let outerDivRect = outerDiv.getBoundingClientRect();
    //             let innerDivRect = innerDiv.getBoundingClientRect();
        
               
    //             let offsetY = innerDivRect.top - outerDivRect.top;
    //             outerDiv.scrollTop = offsetY - 200; 
        
    //             innerDiv.style.animation = "hl 1s"; 
    //           }
    //         } 
          
    //         else {
    //           outerDiv.scrollTop = outerDiv.scrollHeight; 
    //         }
        
    //         setToCardStage(null); 
    //         clearInterval(interval); 
    //       }, 150);

    //     // const interval = setInterval(() => {
    //     //     if (setIndexChat) {
    //     //         let outerDiv = document.getElementById('srsAreaAutoScroll');
    //     //         let innerDiv = document.getElementById('chatAreaIndex' + (setIndexChat.indexKey - 2));
    //     //         if (innerDiv) {
    //     //             outerDiv.scrollTop = 0
    //     //             innerDiv.style.animation = '';

    //     //             let outerDivRect = outerDiv.getBoundingClientRect();
    //     //             let innerDivRect = innerDiv.getBoundingClientRect();

    //     //             let offsetX = innerDivRect.left - outerDivRect.left;
    //     //             let offsetY = innerDivRect.top - outerDivRect.top;

    //     //             outerDiv.scrollTop = (offsetY - 200)
    //     //             innerDiv.style.animation = "hl 1s";
    //     //         }
    //     //     } else {
    //     //         document.getElementById('srsAreaAutoScroll').scrollTop += 9999999;
    //     //     }
    //     //     setToCardStage(null);
    //     //     clearInterval(interval);
    //     // }, 150);
    // }

    async function GetAccesstokenToken(callback) {
        await axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                /* Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
                var temp = {
                    tokenBox: response.data.tokenBox
                }
                if (callback) {
                    callback(response.data.tokenBox);
                }
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
                /*  console.log('ViewToken', response.data); */
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function CheckAndCreateFolderBox(ref_id, folder_name, callback) {
        let temp = {
            folderLis: ["PROJECT", `PROJECT_${ref_id}`, "STAGE MONITOR CHAT", "PROCESS", folder_name]
        }
        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            }, data: temp,
        })
            .then(async function (response) {
                if (response.data) {
                    let folder_id = response.data.folder_id;
                    if (callback) {
                        let res = {
                            ref_id: ref_id,
                            folder_id: folder_id,
                            folder_name: folder_name,
                        }
                        await callback(res)
                    }

                    /* UploadFiles(folder_id,folder_name,fed_id,file); */
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function UploadFiles(folder_id, key, ref_id, file, callback) {
       
        let image_id_list = fileUploadList || [];
        let data_ = new FormData();
        data_.append('File', file);
        data_.append('folder_id', folder_id);
        data_.append('key', key);
        data_.append('image_old_list', JSON.stringify(image_id_list));
        data_.append('ref_id', ref_id);

        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "multipart/form-data",
            }, data: data_,
        })
            .then(async function (response) {
                let temp = fileUploadList || [];
                for (let item of response.data.image_id_list) {
                    temp.push(item);
                }
                setFileUploadList(temp);
                setTest({ ...test, test: '' });
                test.test = '';
                if (callback) {

                    await callback(true)
                }
             

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function UploadFilesRemove(folder_id, key, ref_id, file, callback) {
        let image_id_list = [];
        let data_ = new FormData();
        data_.append('File', file);
        data_.append('folder_id', folder_id);
        data_.append('key', key);
        data_.append('image_old_list', JSON.stringify(image_id_list));
        data_.append('ref_id', ref_id);

        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "multipart/form-data",
            }, data: data_,
        })
            .then(async function (response) {
                /*    let  temp = fileUploadList || [];
                   for(let item of response.data.image_id_list){
                     temp.push(item);
                   }
                   setFileUploadList(temp); */
                setTest({ ...test, test: '' });
                test.test = '';
                if (callback) {

                    await callback(true)
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function sendSrsAreaData() {

        setCheckStatusScroll(true);
        let checkElement = [];
        let getElementSession = sessionStorage.getItem('elementSelect');
        if (getElementSession != undefined || getElementSession != null || getElementSession != '') {
            if (getElementSession.length > 0) {
                for (let item of elementData.element) {
                    let checkJSON = JSON.parse(getElementSession).filter((e) => { return item.elm_id == e });
                    if (checkJSON.length > 0) {
                        checkElement.push(item);
                    }
                }
            }
        }

        let temp = {
            project_id: project_id,
            process_id: process_id,
            menu_id: menu_id,
            comment: sendData.text,
            element: checkElement,
            mandays: mandaysSend.text ? mandaysSend.text.trim() : null,
            remark: mandaysSend.description ? mandaysSend.description.trim() : null,
            fileList: [],
            sm_mom_id: displayMomAttendant && localStorage.getItem('fsession') ? localStorage.getItem('fsession') : null,
            sm_follow_name: displayMomAttendant && localStorage.getItem('fperson') ? localStorage.getItem('fperson') : null,
            sm_follow_case: displayMomAttendant && localStorage.getItem('ffollow') ? localStorage.getItem('ffollow') : null,
        };
        let fileList = sendFile;
        let uploadFile = sendFile.filter((e) => { return e.type == 'FILE' });
        let uploadImage = sendFile.filter((e) => { return e.type == 'IMAGE' });


        console.log('LEK_FILE_FILE', uploadFile)

        console.log('LEK_FILE_IMAGE', uploadImage)

        let checkTag = sendData.text.split('*');
        let tagData = [];
        checkTag.length > 0 && checkTag.map((e, index) => {
            let fil = dataUserPercentList.filter((f) => { return e == f.first_name + ' ' + f.last_name })
            let checkFil = tagData.filter((cf) => { return e == cf.first_name + ' ' + cf.last_name })
            if (fil.length > 0 && checkFil.length == 0) {
                tagData.push(fil[0])
            }

        });
        /*         temp.fileList = fileList; */
        temp.tagData = tagData;
        temp.index = srsAreaData.length + 2
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/srsMenu/sendSRSArea`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
           
                if (response.data.id) {





                    if(uploadFile.length > 0 && uploadImage.length > 0){
                        setLoading(true);
                        await CheckAndCreateFolderBox(temp.project_id, "FILE", async (result) => {
                            if (result) {
                                let index = 0;
                                for await (let item of uploadFile) {
                                    await UploadFiles(result.folder_id, result.folder_name, response.data.id, item.file, async (result2) => {
                                        if (result2) {
                                            console.log('LEK_FILE_FILE_PROCESS', index)
                                            if (index + 1 == uploadFile.length) {
                                                /*   setFileUploadList([]); */
                                                /*    Swal.fire({
                                                       icon: "success",
                                                       title: "Save",
                                                       showConfirmButton: false,
                                                       timer: 1500,
                                                     }); */
                                            }

                                            index++;
                                        }
                                    })
                                }
                            }
                        });

                        await CheckAndCreateFolderBox(temp.project_id, "IMAGE", async (result) => {
                            if (result) {
                                let index = 0;
                                for await (let item of uploadImage) {
                                    await UploadFiles(result.folder_id, result.folder_name, response.data.id, item.file, async (result2) => {
                                        if (result2) {
                                            console.log('LEK_FILE_IMAGE_PROCESS', index)
                                            if (index + 1 == uploadImage.length) {
                                                /*  setFileUploadList([]); */
                                                /*    Swal.fire({
                                                       icon: "success",
                                                       title: "Save",
                                                       showConfirmButton: false,
                                                       timer: 1500,
                                                     }); */
                                            }

                                            index++;
                                        }
                                    })
                                }
                            }
                        });
                        setLoading(false);
                    }
                    else if (uploadFile.length > 0) {
                        setLoading(true);
                        await CheckAndCreateFolderBox(temp.project_id, "FILE", async (result) => {
                            if (result) {
                                let index = 0;
                                for await (let item of uploadFile) {
                                    await UploadFiles(result.folder_id, result.folder_name, response.data.id, item.file, async (result2) => {
                                        if (result2) {
                                            console.log('LEK_FILE_FILE_PROCESS', index)
                                            if (index + 1 == uploadFile.length) {
                                                /*   setFileUploadList([]); */
                                                /*    Swal.fire({
                                                       icon: "success",
                                                       title: "Save",
                                                       showConfirmButton: false,
                                                       timer: 1500,
                                                     }); */
                                            }

                                            index++;
                                        }
                                    })
                                }
                            }
                        });
                        setLoading(false);
                    }
                    else if (uploadImage.length > 0) {
                        setLoading(true);
                        await CheckAndCreateFolderBox(temp.project_id, "IMAGE", async (result) => {
                            if (result) {
                                let index = 0;
                                for await (let item of uploadImage) {
                                    await UploadFiles(result.folder_id, result.folder_name, response.data.id, item.file, async (result2) => {
                                        if (result2) {
                                            console.log('LEK_FILE_IMAGE_PROCESS', index)
                                            if (index + 1 == uploadImage.length) {
                                                /*  setFileUploadList([]); */
                                                /*    Swal.fire({
                                                       icon: "success",
                                                       title: "Save",
                                                       showConfirmButton: false,
                                                       timer: 1500,
                                                     }); */
                                            }

                                            index++;
                                        }
                                    })
                                }
                            }
                        });
                        setLoading(false);
                    }
                    console.log('LEK_FILE_SUCCESS')
                    /*  Swal.fire({
                         icon: "success",
                         title: "Save",
                         showConfirmButton: false,
                         timer: 1500,
                       }); */

                    /*        await CheckAndCreateFolderBox(response.data.id,"IMAGE",async (result)=>{
                             if(result){
                               await UploadFiles(result.folder_id,result.folder_name,result.ref_id,file,async (result2)=>{
                                 if(result2 == true){
                                   await CheckAndCreateFolderBox(response.data.id,"FEDERATION IMAGE COVER",async (result3)=>{
                                     if(result3){
                                       await UploadFiles(result3.folder_id,result3.folder_name,result3.ref_id,file2,async (result4)=>{
                                         if(result4 == true){
                                           for(let item of getFileSpaceHeadImgSave){
                                             
                                             await CheckAndCreateFolderBoxSpaceship(item.spaceship_id,"SPACESHIP COVER",async (result5)=>{
                                               if(result5){
                                                 await UploadFilesSpaceship(result5.folder_id,result5.folder_name,result5.ref_id,item.head_file,item.file_id_list || [],async (result6)=>{
                                                   if(result6 == true){
                                               
                                                   }
                                                 })
                                               }
                                             })
                                           }   
                                           Swal.fire({
                                             icon: "success",
                                             title: "Save",
                                             showConfirmButton: false,
                                             timer: 1500,
                                           }).then((result_) => {
                                             window.location.href =
                                               "/M-20240220109";
                                           });
                                         }
                                       })
                                     }
                                   });
                                 }
                               })
                             }
                           
                           }
                           ); */

                    /*    getCompanyData((result) => {
                         if (result == true) { */
                    /*   await CheckAndCreateFolderBox(response.data.id,"FEDERATION IMAGE COVER",file2); */














                    
                    // resetElementSelect();
                    setSendFile([]);
                    setSendFileView([]);
                    setUploadCheck(false);
                    setSendSearch({ text: '' });
                    sendSearch.text = '';
                    setSendData({ text: '' });
                    sendData.text = '';
                   
          
                    setHasMoreMessages(true);
                    setPage(1);
                    // setSraAreaData([]);
                    // getSrsAreaData();

                    await socketRef.current.emit('chat', { message: 'processElement', room: "PROCESSELEMENTROOM:" + menu_id + process_id, menu_id: menu_id, process_id: process_id, project_id: project_id });
                    if (mandaysPopup) {
                        mandaysSend.text = null;
                        mandaysSend.description = null;
                        setMandaysSend({ text: null, description: null });
                        toggle_modal_mandaysPopup();
                    }
          
                }
                // setTimeout(() => {
                    // scrollToBottom(); 
                // }, 100);
            })
            .catch(function (error) {
                console.log(error);
            });

        /*   if (uploadFile.length > 0) {
             const dataFile = new FormData();
             for (let i = 0; i < uploadFile.length; i++) {
                 console.log(uploadFile[i].file)
                 dataFile.append('Profile', uploadFile[i].file);
             }
             dataFile.append('typeFile', "FileUploadProcessElement");
             dataFile.append('projectKey', "TTTSPACESHIP");
 
             console.log('dataFile', dataFile);
            await axios({
                 method: 'post',
                 url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                 headers: {
          
                     'X-TTT': `${Configs.API_TTT}`,
                     'Content-Type': 'multipart/form-data',
                 },
                 data: dataFile,
             })
                 .then(async function (response) {
                     if (response.data.status == true) {
                         response.data.data.forEach((e) => {
                             const tmp = {
                                 name: e.orgin_name,
                                 path: e.path,
                                 is_save: true,
                                 ul_is_use: true,
                                 ul_file_size: e.size,
                                 url: e.path,
                                 ul_type: 'FILE',
                                 ul_group_name: "ProcessElement",
                                 ul_file_name: e.orgin_name,
                                 ul_file_path: e.path,
                                 ul_menu_name: "ProcessElementChange",
                                 ul_is_profile: false,
                                 status: 'done'
 
                             }
                             fileList.push(tmp);
                         })
                     }
 
                 })
                 .catch(function (error) {
                     console.log(error);
                 });
         }
 
         if (uploadImage.length > 0) {
             const dataImage = new FormData();
             for (let i = 0; i < uploadImage.length; i++) {
 
                 dataImage.append('Profile', uploadImage[i].file);
             }
             dataImage.append('typeFile', "ImageUploadProcessElement");
             dataImage.append('projectKey', "TTTSPACESHIP");
 
             console.log('dataImage', dataImage);
             await axios({
                 method: 'post',
                 url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                 headers: {
                    
                     'X-TTT': `${Configs.API_TTT}`,
                     'Content-Type': 'multipart/form-data',
                 },
                 data: dataImage,
             })
                 .then(async function (response) {
                     if (response.data.status == true) {
                         response.data.data.forEach((e) => {
                             const tmp = {
                                 name: e.orgin_name,
                                 path: e.path,
                                 is_save: true,
                                 ul_is_use: true,
                                 ul_file_size: e.size,
                                 url: e.path,
                                 ul_type: 'IMAGE',
                                 ul_group_name: "ProcessElement",
                                 ul_file_name: e.orgin_name,
                                 ul_file_path: e.path,
                                 ul_menu_name: "ProcessElementChange",
                                 ul_is_profile: false,
                                 status: 'done'
 
                             }
                             fileList.push(tmp);
                         })
                     }
 
                 })
                 .catch(function (error) {
                     console.log(error);
                 });
         }
 
         let checkTag = sendData.text.split('*');
         let tagData = [];
         checkTag.length > 0 && checkTag.map((e, index) => {
             let fil = dataUserPercentList.filter((f) => { return e == f.first_name + ' ' + f.last_name })
             let checkFil = tagData.filter((cf) => { return e == cf.first_name + ' ' + cf.last_name })
             if (fil.length > 0 && checkFil.length == 0) {
                 tagData.push(fil[0])
             }
 
         });
 
         // console.log('testlog send', tagData);
         // setTagSendData(tagData);
 
         temp.fileList = fileList;
         temp.tagData = tagData;
         temp.index = srsAreaData.length + 2
         axios({
             method: "POST",
             url: `${Configs.TTT_Game_Service}/api/srsMenu/sendSRSArea`,
             headers: {
                 Authorization: sessionStorage.getItem('access_token'),
                 "X-TTT": `${Configs.API_TTT}`,
                 "Content-Type": "application/json",
             },
             data: temp,
         })
             .then(async function (response) {
                 // setSraAreaData(response.data)
                 if (response.data) {
                     // resetElementSelect();
                     setSendFile([]);
                     setSendFileView([]);
                     setUploadCheck(false);
                     setSendSearch({ text: '' });
                     sendSearch.text = '';
                     setSendData({ text: '' });
                     sendData.text = '';
                     //getSrsAreaData();
                     socketRef.current.emit('chat', { message: 'processElement', room: "PROCESSELEMENTROOM:" + menu_id + process_id, menu_id: menu_id, process_id: process_id, project_id: project_id });
                     if (mandaysPopup) {
                         mandaysSend.text = null;
                         mandaysSend.description = null;
                         setMandaysSend({ text: null, description: null });
                         toggle_modal_mandaysPopup();
                     }
                 }
             })
             .catch(function (error) {
                 console.log(error);
             });
 
         if (tagData.length > 0) {
             processStage.index = srsAreaData.length + 2;
             for (let item of tagData) {
                 let tempNoti = {
                     header: "TAG YOU ON " + processStage.project_name + '>' + processStage.process_name + '>' + processStage.module_name + '>' + processStage.menu_name,
                     // header:"คุณได้รับคำเชิญเข้าร่วมยาน",
                     // body:"คุณได้รับคำเชิญจาก " + sessionStorage.getItem('user') + " เพื่อเข้าร่วมยาน " + spaceShipName + " คุณจะยอมรับคำเชิญหรือไม่ ?",
                     noti_menu_name: "tag_project",
                     user_to_id: item.usr_id,
                     user_to_name: item.first_name + ' ' + item.last_name,
                     link_to: JSON.stringify(processStage),
                     space_ship_id: null
                 }
                 await axios({
                     method: 'POST',
                     url: `${Configs.TTT_Game_Notification_Service}/api/notification/addNotification`,
                     headers: {
                         Authorization: sessionStorage.getItem('access_token'),
                         'X-TTT': `${Configs.API_TTT}`,
                         'Content-Type': 'application/json',
                     },
                     data: tempNoti,
                 })
                     .then(async function (response) {
                         // setSendData([])
                         socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + item.usr_id });
                     })
                     .catch(function (error) {
                         console.log(error);
                     });
             }
         }
 
         if (tagData.length > 0) {
             let tempMail = {
                 userby: 'TTT',
                 tagData: tagData || [],
                 comment: sendData.text,
                 userSend: sessionStorage.getItem('user'),
                 where: processStage.project_name + ' > ' + processStage.process_name + ' > ' + processStage.module_name + ' > ' + processStage.menu_name
             };
             axios({
                 method: 'POST',
                 url: `${Configs.TTT_Game_Service}/api/mail/sendMailChatTag`,
                 headers: {
                     Authorization: sessionStorage.getItem('access_token'),
                     'X-TTT': `${Configs.API_TTT}`,
                     'Content-Type': 'application/json',
                 },
                 data: tempMail
             })
                 .then(function (response) {
 
                 })
                 .catch(function (error) {
                     console.log(error);
 
 
                 });
         } */


    }

    async function getFileBoxByIdImage(image_id, callback) {
        await axios({
            method: "get",
            url: `https://api.box.com/2.0/files/${image_id}/content`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',

        })
            .then(async function (response) {

                if (callback) {
                    let tmp = {
                        fileImg: URL.createObjectURL(response.data),
                        file_id: image_id,

                    }
                    callback(tmp);
                }

            }).catch((err) => {

                if (callback) {
                    let tmp = {
                        fileImg: null,
                        file_id: null,

                    }
                    callback(tmp);
                }
            })

    }


    function delUploadFile(indexView, no) {
        let tempView = sendFileView;
        let temp = sendFile;
        if (indexView > -1) {
            tempView.splice(indexView, 1);
            let indexMain = temp.findIndex((e) => e.no == no);
            if (indexMain > -1) {
                temp.splice(indexMain, 1);

                setSendFile(temp);
            }

            setSendFileView(tempView);
        }
        setTest({ ...test, test: '' });
        test.test = '';
    }

    async function delSrsAreaData() {
        let temp = {
            srsAreaId: srsAreaSelectId.id,
            projectId: project_id
        };


        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/srsMenu/delSRSArea`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                if (response.data) {
                    socketRef.current.emit('chat', { message: 'processElement', room: "PROCESSELEMENTROOM:" + menu_id + process_id, menu_id: menu_id, process_id: process_id, project_id: project_id });
                    // getSrsAreaData()

                    await CheckAndCreateFolderBox(project_id, "FILE", async (result) => {
                        if (result) {
                            let index = 0;
            
                            await UploadFilesRemove(result.folder_id, result.folder_name, temp.srsAreaId, null, async (result2) => {
                                if (result2) {
            
                                }
                            })
            
                        }
                    });
                    await CheckAndCreateFolderBox(project_id, "FILE", async (result) => {
                        if (result) {
                            let index = 0;
            
                            await UploadFilesRemove(result.folder_id, result.folder_name, temp.srsAreaId, null, async (result2) => {
                                if (result2) {
            
                                }
                            })
            
                        }
                    });

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getMenuData() {
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/srsMenu/getMenuData/${project_id}`,
            headers: {
                // Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            // data: temp,
        })
            .then(async function (response) {
                if (response.data) {
                    let temp = response.data.filter((e) => { return e.menu_id != menu_id });
                    let set = [];

                    for (let item of temp) {
                        let setFormat = {
                            label: item.menu_name,
                            value: item.menu_id
                        }
                        set.push(setFormat)
                    }
                    setMenuData(set);
                    // socketRef.current.emit('chat', { message: 'processElement', room: "PROCESSELEMENTROOM:" + menu_id + process_id, menu_id: menu_id, process_id: process_id, project_id: project_id });
                    // getSrsAreaData()
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function sendMoveMenu() {
        let temp = {
            menu_id_old: menu_id,
            menu_id_new: moveMenuSend.menu_id,
            sm_id: moveMenuSend.sm_id
        }
        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/srsMenu/updateMenuData`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                if (response.data) {
                    // console.log('testlog response', response.data);
                    socketRef.current.emit('chat', { message: 'processElement', room: "PROCESSELEMENTROOM:" + menu_id + process_id, menu_id: menu_id, process_id: process_id, project_id: project_id });
                    toggle_modal_moveMenuPopup();
                    // getSrsAreaData()
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [checkTagPopup, setCheckTagPopup] = useState(false);

    const renderNames = () => {
        let split = (sendData.text || '').toString().split(' ');
        let index = 0;
        if (split.length > 0) {
            for (let item of split) {
                if (item.includes('@') && split.length == index + 1) {
                    if (item.split('@')[1].split(' ')[0] == '' && item.split('@')[1].split(' ')[0] != ' ') {
                        const check = dataUserPercentList.filter((e) => { return e.usr_id != sessionStorage.getItem('user_id') });
                        return check.length > 0 && check.map((e) => {
                            return (<>
                                <div className='srsAreaTagUser' onClick={() => {
                                    let parts = (sendData.text || '').toString().split(' ');
                                    let indexParts = 0;
                                    let stringParts = '';
                                    for (let item of parts) {
                                        if (item.includes('@') && parts.length == indexParts + 1) {
                                            stringParts += '*' + e.first_name + ' ' + e.last_name + '* ';
                                        } else {
                                            stringParts += item + ' ';
                                        }
                                        indexParts++
                                    }
                                    setSendData({ ...sendData, text: stringParts });
                                    sendData.text = stringParts;
                                }}>
                                    <div className='setTagNameBoxImg' style={{ width: '13%', height: '40px', backgroundImage: `url(${e.ul_file_path != null ? Configs.TTT_Game_Service_IMG + e.ul_file_path : DefaultUserProfile})` }}></div>
                                    <div className='setTagNameBox' style={{ width: '25%' }}>{e.ttt_id}</div>
                                    <div className='setTagNameBox' style={{ width: '62%' }}>{e.first_name + ' ' + e.last_name}</div>
                                </div>
                            </>)
                        });
                    } else if (item.split('@')[1].split(' ')[0] != ' ') {
                        const names = item.split('@')[1].split(' ');
                        const check = dataUserPercentList.filter((e) => { return e.usr_id != sessionStorage.getItem('user_id') && e.first_name.toLowerCase().startsWith(names[0].toLowerCase()) });
                        return <>
                            {check.length > 0 ?
                                <div className='srsAreaTagUser' onClick={() => {
                                    let parts = (sendData.text || '').toString().split(' ');
                                    let indexParts = 0;
                                    let stringParts = '';
                                    for (let item of parts) {
                                        if (item.includes('@') && parts.length == indexParts + 1) {
                                            stringParts += '*' + check[0].first_name + ' ' + check[0].last_name + '* ';
                                        } else {
                                            stringParts += item + ' ';
                                        }
                                        indexParts++
                                    }
                                    setSendData({ ...sendData, text: stringParts });
                                    sendData.text = stringParts;
                                }}>
                                    <div className='setTagNameBoxImg' style={{ width: '13%', height: '40px', backgroundImage: `url(${check[0].ul_file_path != null ? Configs.TTT_Game_Service_IMG + check[0].ul_file_path : DefaultUserProfile})` }}></div>
                                    <div className='setTagNameBox' style={{ width: '25%' }}>{check[0].ttt_id}</div>
                                    <div className='setTagNameBox' style={{ width: '62%' }}>{check[0].first_name + ' ' + check[0].last_name}</div>
                                </div>
                                :
                                <div className='srsAreaTagUser' style={{ display: 'none' }}></div>
                            }
                        </>;
                    }
                }
                index++
            }
        }
        return null;
    };

    const [displayMomAttendant, setDisplayMomAttendant] = useState(false);
    const [momAttendantData, setmomAttendantData] = useState([]);
    const closeMomAttendant = () => {
        setDisplayMomAttendant(false);
        localStorage.removeItem('fperson');
        localStorage.removeItem('fsession');
        localStorage.removeItem('ffollow');

    };

    const getMOMData = async () => {
        let temp = {
            project_id: project_id,
            current_date: new Date().toISOString(),
        }
        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/srsMenu/getMomAttendant`,
            headers: {
                /*     Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                // console.log(response.data);
                if (response.data.length > 0) {
                    const resData = response.data;
                    console.log(resData);
                    resData.forEach(e => {
                        e.person.sort((a, b) => {
                            return new Date(a.mp_created_date) - new Date(b.mp_created_date);
                        })
                    });
                    setmomAttendantData(resData);
                    setDisplayMomAttendant(true);
                } else {
                    setDisplayMomAttendant(false);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <div className='stageElementChatBox'>
                <div className='stageElementChatBoxIn'>

                    <div className='stageElementChatHeader'>
                        <div className='stageElementChatSearch'>
                            <div className='stageElementChatSearchIn'>
                                <input className='stageElementChatSearchInput'
                                    placeholder='Search....'
                                    type={'text'}
                                    value={sendSearch.text}
                                    onChange={(e) => {
                                        setSendSearch({ ...sendSearch, text: e.target.value })
                                        sendSearch.text = e.target.value
                                    }}
                                    onKeyDown={(e) => {
                                        // console.log("onSearch.show",onSearch);
                                        
                                        if (e.keyCode == 13) {
                                            // setSraAreaData([]);
                                            setHasMoreMessages(true);
                                            setPage(1);
                                            // setSraAreaData([]); 
                                            // getSrsAreaData()
                                        }
                                    }}
                                ></input>
                                <div className='stageElementChatSearchIcon'>
                                    <i class="fa fa-search stageElementChatSearchIconSet" aria-hidden="true" onClick={(e) => {
                                        setHasMoreMessages(true);
                                        setPage(1);
                                        // setSraAreaData([]); 
                                        // getSrsAreaData();
                                        }}></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='stageElementChatBody' 
                    id='srsAreaAutoScroll' 
                    ref={containerRef}
                    style={{ position: 'relative' }}
                    onScroll={ handleScroll}
                    >
                              {isLoadingChat && hasMoreData && <div>Loading more data...</div>}
                              {/* {!hasMoreData && <div>No more data to load.</div>} */}
                        <div className='stageElementChatBodyIn'>
                            {srsAreaData && srsAreaData.map((e, index) => {
                                const commentLines = e.sm_comment.split('\n');
                                let isFile = [];
                                let isImage = [];
                                if (e.fileList.length > 0) {
                                    isFile = e.fileList.filter((item) => { return item.fd_keyfile == 'FILE' });
                                    isImage = e.fileList.filter((item) => { return item.fd_keyfile == 'IMAGE' });
                                }
                                return (
                                    <div className='stageElementChat' id={'chatAreaIndex' + index}>
                                        <div className='stageElementChatImg' style={{ backgroundImage: `url(${e.ul_file_path != null || e.ul_file_path != undefined || e.ul_file_path != '' ? /* Configs.TTT_Game_Service_IMG +  */e.ul_file_path : ''})` }}></div>
                                        <div className='stageElementChatDetailBox'>
                                            <div className='stageElementChatDetailHeader'>
                                                <div className='stageElementChatDetailHeaderText'>
                                                    <div className='stageElementChatDetailName'>{e.usr_ttt_id} : {e.sm_created_name}</div>
                                                    <div className='stageElementChatDetailDate'>{moment(e.sm_created_date).format('DD/MM/YYYY เวลา HH:mm')}</div>
                                                    <i class="fa fa-edit stageElementChatDetailEdit" aria-hidden="true" style={{ display: `${e.sm_created_by == sessionStorage.getItem('user_id') ? '' : 'none'}`, cursor: `${displayMode == true || displayMode == 'true' ? 'no-drop' : 'pointer'} ` }} onClick={() => {
                                                        if (displayMode == false || displayMode == 'false') {
                                                            callbackSrsDataFunc(e);
                                                        }

                                                    }}></i>
                                                    <i class="fa fa-trash stageElementChatDetailDel" aria-hidden="true" style={{ display: `${e.sm_created_by == sessionStorage.getItem('user_id') ? '' : 'none'}`, cursor: `${displayMode == true || displayMode == 'true' ? 'no-drop' : 'pointer'} ` }} onClick={() => {
                                                        if (displayMode == false || displayMode == 'false') {
                                                            setSrsAreaSelectId({ ...srsAreaSelectId, id: e.sm_id })
                                                            srsAreaSelectId.id = e.sm_id
                                                            toggle_modal_confrimModal()
                                                        }

                                                    }}></i>
                                                    <i class="fa fa-reply stageElementChatDetailDel" aria-hidden="true" style={{ display: `${e.sm_created_by == sessionStorage.getItem('user_id') ? '' : 'none'}`, cursor: `${displayMode == true || displayMode == 'true' ? 'no-drop' : 'pointer'} ` }} onClick={() => {
                                                        if (displayMode == false || displayMode == 'false') {
                                                            getMenuData();
                                                            toggle_modal_moveMenuPopup();
                                                            setMoveMenuSend({ ...moveMenuSend, sm_id: e.sm_id });
                                                        }

                                                        // moveMenuSend.sm_id = e.sm_id;
                                                    }}></i>
                                                    {/* {e.sm_mom_id && <Tooltip placement="top" title={e.sm_follow_name && <><div>{e.sm_follow_name}</div></>} overlayClassName="momTabCustomTooltip">
                                                        <span style={{color: '#FFFFFF80'}}>MOM</span>
                                                    </Tooltip>} */}
                                                </div>
                                                <div className='stageElementChatDetailHeaderElement'>
                                                    {e.element.map((e) => {
                                                        return (
                                                            <div className='stageElementChatDetailElement' style={{ backgroundImage: `url(${e.elm_img_path != null || e.elm_img_path != undefined || e.elm_img_path != '' ? e.elm_img_path : ''})` }}></div>
                                                        )
                                                    })}
                                                    {e.sm_mom_id && <Tooltip placement="top" title={e.sm_follow_name && <><div>{e.sm_follow_name}</div></>} overlayClassName="momTabCustomTooltip">
                                                        {/* <span style={{color: '#FFFFFF80'}}>MOM</span> */}
                                                        {/* <div style={{ backgroundImage: `url(${tagMom})` }}></div> */}
                                                        <img src={tagMom} style={{height: '25px'}} />
                                                    </Tooltip>}
                                                </div>
                                            </div>
                                            <div className='stageElementChatDetailCommentBox'>
                                                <div className='stageElementChatDetailComment'>
                                                    {/* {e.sm_comment} */}
                                                    {commentLines.map((line, i) => {

                                                        let temp = line.split('*')
                                                        let data = [];
                                                        if (line.includes('*')) {
                                                            temp.length > 0 && temp.map((e, index) => {
                                                                let check = dataUserPercentList.filter((f) => { return e == f.first_name + ' ' + f.last_name })
                                                                if (check.length > 0) {
                                                                    data.push('@' + check[0].first_name + ' ' + check[0].last_name);
                                                                } else {
                                                                    data.push(' ' + e + ' ')
                                                                }
                                                            })
                                                        } else {
                                                            data.push(' ' + line + ' ')
                                                        }

                                                        return (<>
                                                            <span key={i}>
                                                                {data.map((word, j) => {

                                                                    return (<>
                                                                        <span key={j} style={{ color: word.trim() === data[j] ? '#67EDFF' : 'white', textDecoration: word.trim() === data[j] ? 'underline' : 'none' }}>
                                                                            {word}
                                                                            {j < data.length - 1 && ' '}
                                                                        </span>
                                                                    </>)
                                                                })}
                                                                {i < commentLines.length - 1 && <br />}
                                                            </span>
                                                        </>)
                                                    })}
                                                </div>
                                                <div className='stageElementChatDetailIcon' style={{ display: `${isFile.length > 0 ? '' : 'none'}` }} onClick={() => {
                                                    setLoadData(isFile);
                                                    if (loadCheck) {
                                                        if (loadMode.mode != 'FILE') {
                                                            setLoadMode({ ...loadMode, mode: 'FILE' });
                                                            loadMode.mode = 'FILE';
                                                        } else {
                                                            toggle_modal_loadCheck();
                                                        }
                                                    } else {
                                                        setLoadMode({ ...loadMode, mode: 'FILE' });
                                                        loadMode.mode = 'FILE';
                                                        toggle_modal_loadCheck();
                                                    }

                                                }}>
                                                    <img src={clipPaperIcon} style={{ width: '20px', height: '20px' }}></img>
                                                </div>
                                                <div className='stageElementChatDetailIcon' style={{ display: `${isImage.length > 0 ? '' : 'none'}` }} onClick={() => {
                                                    setLoadData(isImage);
                                                    if (loadCheck) {
                                                        if (loadMode.mode != 'IMAGE') {
                                                            setLoadMode({ ...loadMode, mode: 'IMAGE' });
                                                            loadMode.mode = 'IMAGE';
                                                        } else {
                                                            toggle_modal_loadCheck();
                                                        }
                                                    } else {
                                                        setLoadMode({ ...loadMode, mode: 'IMAGE' });
                                                        loadMode.mode = 'IMAGE';
                                                        toggle_modal_loadCheck();
                                                    }
                                                }}>
                                                    <img src={picIcon} style={{ width: '14px', height: '14px' }}></img>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div>
                            {
                                displayMomAttendant ?
                                    (<MOMAttendant data={momAttendantData} closeMomAttendant={closeMomAttendant} />) : ""
                            }
                        </div>
                    
                    </div>

                </div>
            </div>

            <div className='stageElementSendBox'>
                <div className='stageElementSendFile' style={{/* backgroundImage: `url(${clipPaperIcon})`,  */background: `${uploadMode.mode == 'FILE' && uploadCheck ? 'linear-gradient(103deg, #7BD7FF 0%, #0047FF 92.42%)' : 'transparent'}`, cursor: `${displayMode == true || displayMode == 'true' ? 'no-drop' : 'pointer'} ` }} onClick={() => {

                    let temp = sendFile.filter((e) => { return e.type == 'FILE' });
                    setSendFileView(temp);

                    if (displayMode == false || displayMode == 'false') {


                        if (uploadCheck) {
                            if (uploadMode.mode != 'FILE') {
                                setUploadMode({ ...uploadMode, mode: 'FILE' });
                                uploadMode.mode = 'FILE';
                            } else {
                                toggle_modal_uploadCheck();
                            }
                        } else {
                            setUploadMode({ ...uploadMode, mode: 'FILE' });
                            uploadMode.mode = 'FILE';
                            toggle_modal_uploadCheck();
                        }
                    }


                }}>
                    <img className='stageElementSendSetIcon' src={clipPaperIcon} />
                </div>
                <div className='stageElementSendPic' style={{ background: `${uploadMode.mode == 'IMAGE' && uploadCheck ? 'linear-gradient(103deg, #7BD7FF 0%, #0047FF 92.42%)' : 'transparent'}`, cursor: `${displayMode == true || displayMode == 'true' ? 'no-drop' : 'pointer'} ` }} onClick={() => {
                    let temp = sendFile.filter((e) => { return e.type == 'IMAGE' });
                    setSendFileView(temp);

                    if (displayMode == false || displayMode == 'false') {

                        if (uploadCheck) {
                            if (uploadMode.mode != 'IMAGE') {
                                setUploadMode({ ...uploadMode, mode: 'IMAGE' });
                                uploadMode.mode = 'IMAGE';
                            } else {
                                toggle_modal_uploadCheck();
                            }
                        } else {
                            setUploadMode({ ...uploadMode, mode: 'IMAGE' });
                            uploadMode.mode = 'IMAGE';
                            toggle_modal_uploadCheck();
                        }
                    }


                }}>
                    <img style={{ width: '60%', height: '60%' }} src={picIcon} />
                </div>

                <div className='stageElementSendText' style={{ padding: '0 5px', display: 'flex', alignItems: 'center' }}>
                    <textarea
                        className='stageElementSendText'
                        style={{ border: 'none', width: '50%' }}
                        placeholder='Please input your text....'
                        type='text'
                        value={sendData.text}
                        /*     value = {speech2Text.test} */
                        disabled={displayMode == true || displayMode == 'true'}
                        onChange={(e) => {
                            /*   speech2Text() */
                            setSendData({ ...sendData, text: e.target.value });
                            sendData.text = e.target.value;
                        }}
                        onKeyDown={(e) => {
                      
                            if (e.shiftKey && e.keyCode === 13) {
                       
                            } else if (e.keyCode == 13) {
                                if (sendData.text.trim() != '') {
                                    let temp = JSON.parse(sessionStorage.getItem('elementSelect')).filter((f) => { return f == 'c7ac0102-694b-4f73-bd88-b3654e1f5db3' });
                                    if (temp.length > 0) {
                                
                                        toggle_modal_mandaysPopup();
                                        setMandaysSend({ ...mandaysSend, text: null });
                                        mandaysSend.text = null;
                                    } else {
                                        // setSraAreaData([]);
                                        sendSrsAreaData();
                                  
                                    }
                                }
                            }
                        }}
                    />
                    <div className='S2T' style={{ backgroundImage: `url(${imgMi})` }} onMouseDown={startRecording} onMouseUp={stopRecording} disabled={isRecording}>

                    </div>
                    {/* <img src={imgMi} className='S2T' style={{height:'70%',cursor: 'pointer'}}  onMouseDown={startRecording} onMouseUp={stopRecording}  disabled={isRecording}></img> */}
                </div>
                {displayMode == true || displayMode == 'true' ? <>
                    <div className='stageElementSendPrompt' style={{ cursor: 'no-drop' }} onClick={() => {
                        
                    // setSraAreaData([]);
                    sendSrsAreaData();
            
    
                    }}><div>TTT PROMPT</div></div>
                </> : <>
                    <div className='stageElementSendPrompt' onClick={() => {
                        if (sendData.text.trim() != '') {
                            let temp = JSON.parse(sessionStorage.getItem('elementSelect')).filter((f) => { return f == 'c7ac0102-694b-4f73-bd88-b3654e1f5db3' });
                            if (temp.length > 0) {
                                toggle_modal_mandaysPopup();
                                setMandaysSend({ text: null, description: null });
                                mandaysSend.text = null;
                                mandaysSend.description = null;
                            } else {
                                // setSraAreaData([]);
                                sendSrsAreaData();
                  
                                
                            }
                        }
                    }}><div>TTT PROMPT</div></div>
                </>}

            </div>

            <div className='srsAreaUpload' style={{ display: `${uploadCheck ? '' : 'none'}` }}>
                <div className='srsAreaUploadIn'>
                    <div className='srsAreaUploadHeader'>{uploadMode.mode === 'FILE' ? 'FILE' : 'IMAGE'} LIST ({sendFileView.length})
                        <i class="fa fa-times" aria-hidden="true" style={{ fontSize: "20px", cursor: 'pointer', position: 'absolute', right: '5%' }} onClick={() => {
                            toggle_modal_uploadCheck();
                        }} />
                    </div>
                    <div className='srsAreaUploadBody'>
                        {sendFileView.map((e, index) => {
                            return (
                                <>
                                    <div className='srsAreaUploadFileBox'>
                                        <div className='srsAreaUploadFileName'><a className='srsAreaUploadFileNameText' target="_blank" href={e.path} >{e.file_name}</a></div>
                                        <div className='srsAreaUploadFileDel' onClick={() => {
                                            if (displayMode == false || displayMode == 'false') {
                                                delUploadFile(index, e.no)
                                            }

                                        }}><i class="fa fa-times" aria-hidden="true" style={{ fontSize: "16px", cursor: 'pointer' }} /></div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    <div className='srsAreaUploadFooter'>
                        <label className='srsAreaUploadAddButton' onClick={() => {

                        }} for="srsAreaUploadAddButtonFile" ><i class="fa fa-plus" aria-hidden="true"></i> ADD {uploadMode.mode === 'FILE' ? 'FILE' : 'IMAGE'}</label>
                        <input
                            id='srsAreaUploadAddButtonFile'
                            style={{ display: 'none' }}
                            type='file'
                            disabled={displayMode == true || displayMode == 'true'}
                            accept={
                                uploadMode.mode === 'FILE' ?
                                    ['application/pdf',
                                        '.xls', '.xlsx', '.docx',
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                        'application/vnd.openxmlformats-officedocument.presentationml.presentation',] :
                                    'image/*'
                            }
                            multiple
                            onChange={(e) => {

                                if (e.target.files.length > 0) {
                                    let temp = sendFile;
                                    let no = temp.length;
                                    for (let item of e.target.files) {
                                        let file = {
                                            no: no + 1,
                                            type: uploadMode.mode === 'FILE' ? 'FILE' : 'IMAGE',
                                            file_name: item.name,
                                            file: item,
                                            path: URL.createObjectURL(item),
                                            url: URL.createObjectURL(item),
                                        }
                                        temp.push(file);
                                        no++;
                                    }
                                    let temp2 = temp.filter((e2) => { return e2.type == uploadMode.mode });
                                    setSendFileView(temp2);
                                    setSendFile(temp);
                                    setTest({ ...test, test: '' });
                                    test.test = '';
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className='srsAreaLoad' style={{ display: `${loadCheck ? '' : 'none'}` }}>
                <div className='srsAreaLoadIn'>
                    <div className='srsAreaUploadHeader'>{loadMode.mode === 'FILE' ? 'FILE' : 'IMAGE'} LIST ({loadData.length})
                        <i class="fa fa-times" aria-hidden="true" style={{ fontSize: "20px", cursor: 'pointer', position: 'absolute', right: '5%' }} onClick={() => {
                            toggle_modal_loadCheck();
                        }} />
                    </div>
                    <div className='srsAreaLoadBody'>
                        {loadData.map((e, index) => {
                            return (
                                <>
                                    <div className='srsAreaUploadFileBox'>
                                        <div className='srsAreaUploadFileName' style={{ width: '100%' }}><a className='srsAreaUploadFileNameText' style={{ cursor: 'pointer' }} target="_blank" /* href={Configs.TTT_Game_Service_IMG + e.ul_file_path}  */

                                            onClick={() => {
                                                GetAccesstokenToken((result_) => {
                                                    getFileBoxByIdImage(e.fd_img_id, (result) => {
                                                        if (result) {
                                                            window.open(result.fileImg, '_blank');
                                                        }
                                                    });
                                                });
                                            }}
                                        >{e.fd_origin_name}</a></div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    <div className='srsAreaLoadFooter'>

                    </div>
                </div>
            </div>
            {mandaysPopup ? <>
                <div className='srsAreaMandaysBg' style={{ display: `${mandaysPopup ? '' : 'none'}` }}>
                    <div className='srsAreaMandaysBox'>
                        <div className='srsAreaMandaysHeader'>MANDAYS</div>
                        <input className='srsAreaMandaysBody'
                            placeholder='Please input your Manday (number only)'
                            type="number"
                            value={mandaysSend.text}
                            disabled={displayMode == true || displayMode == 'true'}
                            onChange={(e) => {
                                if (/^\d*$/.test(e.target.value)) {
                                    setMandaysSend({ ...mandaysSend, text: e.target.value });
                                    mandaysSend.text = e.target.value;
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.shiftKey && e.keyCode === 13) {

                                }
                            }}
                        ></input>

                        <textarea
                            type='text'
                            id='edittext'
                            className='srsAreaMandaysTextareaBody mt-3'
                            placeholder='Please input your Description'
                            value={mandaysSend.description}
                            onChange={(e) => {
                                setMandaysSend({ ...mandaysSend, description: e.target.value });
                                mandaysSend.description = e.target.value;
                            }}
                        />
                        <div className='srsAreaMandaysFooter'>
                            <div className='srsAreaMandaysSend' onClick={() => {
                                //         setSraAreaData([]);
                                sendSrsAreaData();
                  
                            }}>SAVE</div>
                        </div>
                    </div>
                </div>
            </> : null}
            <div className='srsAreaMoveMenuBg' style={{ display: `${moveMenuPopup ? '' : 'none'}` }}>
                <div className='srsAreaMoveMenuBox'>
                    <div className='srsAreaMandaysHeader'>Move to menu</div>

                    <Select
                        className='srsAreaMoveMenuBody'
                        placeholder="Select menu..."
                        isSearchable={true}

                        isDisabled={displayMode == true || displayMode == 'true'}
                        styles={{
                            minHeight: '46px !important',
                            backgroundColor: '#0047FF',
                            control: (baseStyles, state) => ({
                                ...baseStyles,

                                "&:hover": {
                                    borderColor: "#0047FF",
                                    color: 'red'
                                },
                                fontSize: '16px',
                            }),
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                ...styles,
                                color: isSelected ? '#fff' : 'black', // ตั้งค่าสีตัวหนังสือที่เลือกและไม่เลือก
                                backgroundColor: isSelected ? '#0047FF' : '#fff',
                                textAlign: 'start',
                                border: 'none !important', // ลบเส้นขีดขั้น
                                "&:hover": {
                                    border: 'none !important', // ลบเส้นขีดขั้น
                                    backgroundColor: isFocused ? "#0047FF" : "#0047FF",
                                    color: "#fff"
                                },
                            }),
                        }}

                        options={menuData}

                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary: '#0047FF',
                            },
                        })}


                        value={menuData.filter((e) => { return e.value === moveMenuSend.menu_id })}
                        onChange={(e) => {
                            setMoveMenuSend({ ...moveMenuSend, menu_id: e.value });
                            moveMenuSend.menu_id = e.value;
                        }}
                    />

                    <div className='srsAreaMoveMenuFooter mt-2'>
                        <div className='srsAreaMandaysSend' onClick={() => {
                            sendSrsAreaData();
                    
                            if (displayMode == false || displayMode == 'false') {
                                sendMoveMenu();
                            }

                        }}>SAVE</div>
                        <div className='srsAreaMandaysSend' style={{ background: 'red' }} onClick={() => {
                            toggle_modal_moveMenuPopup();
                        }}>CANCEL</div>
                    </div>
                </div>
            </div>
            {renderNames() && isOpen && <div className='srsAreaTagBox'>
                {renderNames()}
            </div>}

            {loading && (
        <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox' style={{ height: 'fit-content' }}>
              <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: 'orange' }}>
                    Loading
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning' style={{ border: 'none' }}>
                    <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        </>
        
    )
}

export default SrsArea
