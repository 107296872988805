import React, { useEffect, useRef, useState } from 'react'
import Calendar from '../../../../assets/img/icon/Time-Calendar.svg'
import './momAttendant.css';
import styled from 'styled-components';
import FollowCheck from '../../../../assets/img/icon/Follow-check.svg';
import FollowNoCheck from '../../../../assets/img/icon/Follow-non-check.svg'
import pesronIcon from '../../../../assets/img/icon/personMOM.svg'

const MyP = styled.p`
  font-family: Oxanium;
  margin: 0;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
`;

const MyPnLowWeight = styled.p`
  font-family: Oxanium;
  margin: 0;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 100;
`;


function MOMAttendant({ data, closeMomAttendant }) {
  const [reload, setReload] = useState(0)
  const [bodyClose, setbodyClose] = useState(false);
  const [activeTab, setActiveTab] = useState([]);
  const [followUp, setfollowUp] = useState([
    {
      value: 'N/a',
      use: true,
    }, {
      value: 'Me',
      use: false,
    }, {
      value: 'Customer',
      use: false,
    }
  ]);
  const [selectPerson, setSelectPerson] = useState('');
  const [thisFollowUp, setThisFollowUp] = useState('N/a');
  // console.log(thisFollowUp);
  // console.log(selectPerson);

  useEffect(() => {
    
    setActiveSession();
     /* console.log(data); */
  }, [data]);

  useEffect(()=> {
    sendThisSession();
  },[activeTab, thisFollowUp, selectPerson, reload]);

  // console.log(activeTab);

  const setActiveSession = async () => {
    const array = [];
    if(data.length > 0){
      await data.map((item, index) => {
        const obj = {
          mom_id: item.mom_id,
          mom_header: item.mom_header,
          is_open: index == 0 ? true : false,
        }
        array.push(obj);
      });
      setActiveTab(array);
      // setReload(reload + 1);
    }
  }

  const selectFollowUp = (value) => {
    followUp.forEach(i => {
      if (i.value == value) {
        i.use = true;
        setThisFollowUp(i.value);
      } else {
        i.use = false;
      }
    });
    setReload(reload + 1);
  }

  const selectFollowPerson = (mom_id, person_id) => {
    
    console.log(mom_id, person_id)
    data.map((item, index) => {
      if(item.mom_id == mom_id){
        item.person.map((i) => {
          if(i.mmp_mp_id == person_id){
            if(selectPerson == i.mmp_personal_name){
              setSelectPerson('');
            }else{
              setSelectPerson(i.mmp_personal_name)
            }

          }
        })
      }
    })
  }

  console.log('selectPerson',selectPerson)
  // console.log(followUp);
  const changeActiveSession = (id) => {
    const changeActive = activeTab;
    changeActive.map((t, i) => {
      if (id == t.mom_id) {
        t.is_open = true;
      } else {
        t.is_open = false;
      }
    });
    setActiveTab(changeActive);
    setReload(reload + 1);
  }

  const closeSession = (id) => {
    const sessionIndex = activeTab.findIndex((i) => i.mom_id === id);
    const changeActive = activeTab.filter((i) => i.mom_id !== id);
  
    if (changeActive.length > 0) {
      if (sessionIndex > 0) {
        changeActive[sessionIndex - 1].is_open = true;
      } else {
        changeActive[0].is_open = true;
      }
    }else{
      closeMomAttendant();
    }
  
    // Update the state
    // console.log(changeActive);
    setActiveTab(changeActive);
    setReload(reload + 1);
  }

  const sendThisSession = ()=> {
    localStorage.setItem('fperson', selectPerson);
    localStorage.setItem('ffollow', thisFollowUp);
    const s = activeTab.find(i => i?.is_open == true);
    // console.log(s);

    const id = localStorage.getItem('fsession');
    // console.log(id);

    if(s?.mom_id != id && id){
      localStorage.setItem('fperson', '');
      setSelectPerson('');
    }

    localStorage.setItem('fsession', s?.mom_id);
  }

  const divRef = useRef(null);
  

  return (
    <>
      <div className='momAttendantBox' style={{ height: bodyClose ? '' : '100%' }}>
        <div>
          <div className='topBox'>

            {
              data.map((momItem, index) => {
                const session = activeTab.find(i => i.is_open == true);
                // console.log(session);
                if (momItem.mom_id == session?.mom_id) {
                  return (
                    <>
                      <div style={{ width: '80%', display: 'flex', alignItems: 'center', paddingLeft: '7px' }}>
                        <p style={{ width: '100%', fontFamily: 'Oxanium', color: '#fff', fontSize: '16px', margin: '0', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          {session.mom_header}
                        </p>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-around', width: '20%', alignItems: 'center' }}>
                        <div style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => setbodyClose(!bodyClose)}>
                          -
                        </div>
                        <div style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => closeSession(momItem.mom_id)}>
                          X
                        </div>
                      </div>

                    </>
                  )
                }
              })
            }

          </div>
          {
            bodyClose ? "" : (

              <div style={{ display: 'flex', height: '242px' }}>
                <div>
                  <div className='sectionBox'>
                    {
                      activeTab.map((item, index) => {

                        return (
                          <div className='section' style={{ backgroundColor: item.is_open ? '#002887' : '#001D5F', cursor: 'pointer' }} onClick={() => { changeActiveSession(item.mom_id) }}>
                            <div>
                              <img src={Calendar} alt="Calendar" style={{ width: '17px', height: '17px' }} />
                            </div>
                            <div>
                              <p style={{ fontFamily: 'Oxanium', margin: '0', color: '#fff', fontSize: '14px', lineHeight: '20px' }}>
                                Sec. {index + 1}
                              </p>
                            </div>
                          </div>
                        )
                      })
                    }

                  </div>
                </div>

                <div style={{ backgroundColor: '#002887', width: '184px', height: '100%', paddingLeft: '7px', paddingTop: '5px' }}>
                  
                  <div>
                  
                    <MyP>
                      MOM Attendant
                    </MyP>

                    {
                      data.map((item, index) => {
                        const session = activeTab.find(i => i.is_open == true);
                        if (item.mom_id == session?.mom_id) {
                          const date = new Date(item.mom_start_date);
                          const endDate = new Date(item.mom_end_date);

                          const formattedDate = date.toLocaleDateString('en-GB');
                          const formattedEndDate = endDate.toLocaleDateString('en-GB');
                          // const formattedEndDate = formattedDate;

                          const hours = date.getHours();
                          const minutes = date.getMinutes();
                          const formattedTime = `${hours}.${minutes < 10 ? '0' + minutes : minutes}`;

                          const endHours = endDate.getHours();
                          const endMinutes = endDate.getMinutes();
                          const endFormattedTime = `${endHours}.${endMinutes < 10 ? '0' + endMinutes : endMinutes}`;

                          return (
                            <React.Fragment key={index}>
                              <MyPnLowWeight ref={divRef}>
                                Date: {formattedDate == formattedEndDate ? formattedDate : (<><br />{formattedDate} - {formattedEndDate}</>)}
                              </MyPnLowWeight>
                              <MyPnLowWeight>
                                Time: {formattedTime} - {endFormattedTime} น.
                              </MyPnLowWeight>
                            </React.Fragment>
                          );
                        }
                      })
                    }
                    <div>
                      <MyP>
                        Follow up case :
                      </MyP>
                      <div style={{ display: 'flex' }}>
                        {
                          followUp.map((f, i) => {
                            return (
                              <div key={i} style={{ marginLeft: i == 0 ? ' ' : '9px', }}>
                                <img src={f.use ? FollowCheck : FollowNoCheck} alt="FollowUpcaseCheck" style={{ cursor: 'pointer' }} onClick={() => selectFollowUp(f.value)} />
                                <span style={{ fontFamily: 'Oxanium', fontSize: '12px', paddingTop: '5px', color: f.use ? '#00FFFF' : '#fff' }}>{' '}{f.value}</span>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                  {

                    data.map((t, i)=> {
                      const session = activeTab.find(i => i.is_open == true);
                      if(t.mom_id == session?.mom_id){
                        const date = new Date(t.mom_start_date);
                        const endDate = new Date(t.mom_end_date);

                        const formattedDate = date.toLocaleDateString('en-GB');
                        const formattedEndDate = endDate.toLocaleDateString('en-GB');

                        return(
                          <div className='personBox' style={{ height: formattedDate == formattedEndDate? '54%': '45%' }}>
                            {
                              data.map((item) => {
                                const session = activeTab.find(i => i.is_open === true);
                                if (session?.mom_id === item.mom_id && item.person.length > 0) {
                                  return (
                                    <>
                                      {
                                        item.person.map((p, personIndex) => (
                                          <div className='personSelect' onClick={() => selectFollowPerson(p.mmp_mom_id, p.mmp_mp_id)} style={{ backgroundColor: selectPerson == p.mmp_personal_name? '#0048ff8b':'' }}>
                                            <img src={pesronIcon} alt="pesronIcon" style={{ width: '20px', height: '20px' }} />
                                            <p style={{ margin: '0', color: '#fff', fontFamily: 'Oxanium', fontSize: '14px', paddingLeft: '5px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '86%' }} >
                                              {personIndex + 1}. {p.mmp_personal_name}
                                            </p>
                                          </div>
                                        ))
                                      }
                                    </>
                                  );
                                }
                                return null;
                              })
                            }
                          </div>
                        )
                      }
                    })
                  }


                </div>
              </div>

            )
          }
        </div>

      </div>
    </>
  )
}

export default MOMAttendant