import React, { useEffect, useState, useRef } from 'react'
import {
  Input,
  Space,
  Tag,
  theme,
  Tooltip,
  ConfigProvider,
  DatePicker,
  Modal,
} from 'antd'

import ModalUploadReport from './ModalUploadReport/ModalUploadReport.jsx'
import axios from 'axios'
import moment from 'moment'
import dayjs from 'dayjs'
import { ExclamationCircleFilled } from '@ant-design/icons'
import Configs from '../../../config'
import IconReport from '../../../assets/img/IconStage4/reportSandMail.svg'
import UploadReportMockup from '../../../assets/img/IconStage4/uploadMockup.png'
import './processMockup.css'
import reportmock from '../../component/Report/reportmock';
import { generateUUID } from 'three/src/math/MathUtils.js'
import Getimg from '../../component/stageElement/getimgshow.jsx'
const { RangePicker } = DatePicker
const { confirm } = Modal

function ModalProcessMockup({
  isOpen,
  toggle_modal_process_mockup,
  toggle_modal_process_mockup_view_version,
  open_descpription_image,
  close_descpription_image,
  project_id,
  getProjectDetail,
  ModuleDetails,
  projectDetail,
  status,
  CalPosition,
  setID,
  toggle_modal_status,
  actual_progress,
  callbackDataMockupVersion,
  callbackDataMockupDescription,
  switModeShowDisplay,
  persenComplete
}) {
  
  const [stageMonitoringView, setStageMonitoringView] = useState([])
  const [stageSelectMonitoringView, setStageSelectMonitoringView] = useState([])
  console.log('set:>>>>', stageSelectMonitoringView);
  const [stageMonitoringDataReport, setStageMonitoringDataReport] = useState([])
  const [openModalSendMail, setOpenModalSendMail] = useState(false)
  const togle_modal_send_mail = () => setOpenModalSendMail(!openModalSendMail)

  const [openModalViewListReport, setOpenModalViewListReport] = useState(false)
  const togle_modal_ViewListReport = () =>
    setOpenModalViewListReport(!openModalViewListReport)

  const [valueSave, setValueSave] = useState({
    lsent_project_id: '',
    lsent_spaceship_id: '',
  })

  const [processStage, setProcessStage] = useState([
    "f00e63f2-ab27-4828-9d7a-f9316c28703e",
    "4ded34bc-1d60-4512-b0ad-8c8daf16c0d8",
    "6e6f2408-6a06-4f93-8f0a-aa385cfc4188"
]);
  const [progress, setProgress] = useState(0);
  const [statusName, setStatusName] = useState('')
  const [statusID, setStatusID] = useState('')
  const [displayProgress, setDisplayProgress] = useState({
    actual: 0,
    plan: 0,
  })
  const [Loading,setLoading] = useState(0)

  function callbackLoading (value){
    let persen = value
    setLoading(persen)
  }
  const [errorMessage, setErrorMessage] = useState({ status: '', message: '' });
  const [alertKick, setAlertKick] = useState(false);
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);
  async function GetAccesstokenToken(callback) {
    await axios({
        method: "post",
        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
        headers: {
            /* Authorization: getToken(), */
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
        },
    })
        .then(async function (response) {
            var temp = {
                tokenBox: response.data.tokenBox
            }
            if (callback) {
                callback(response.data.tokenBox);
            }
            sessionStorage.setItem('tokenBox', response.data.tokenBox)
            /*  console.log('ViewToken', response.data); */
        })
        .catch(function (error) {
            console.log(error);
        });

}
  useEffect(() => {
    GetAccesstokenToken(async (result_)  => {
    /*  setErrorMessage(''); */
    /*    setModuleDetail({...ModuleDetail,module_name:''});
       ModuleDetail.module_name= ''; */
    /*    console.log('ModuleDetails',ModuleDetails)
       const temp =ModuleDetails || {}
       setModuleDetail(temp); */
    if (isOpen == true) {
      
      console.log('projectDetail :>>', projectDetail)
      let status_id = '6e6f2408-6a06-4f93-8f0a-aa385cfc4188'
      setStatusID(status_id)
      setStatusName('DONE')
      let dataTemp = []
      for (let item of projectDetail.moduleList) {
        for (let item2 of item.module_menu_list) {
          let check = item2.process_list.filter((e) => {
            return e.process_id == "e9ae4d57-077f-414f-9eb2-22f4c4a109c7" && e.ps_id == "f00e63f2-ab27-4828-9d7a-f9316c28703e" || 
            e.ps_id =="4ded34bc-1d60-4512-b0ad-8c8daf16c0d8" ||
            e.ps_id =="6e6f2408-6a06-4f93-8f0a-aa385cfc4188" 
          })
          if (check.length > 0) {
            dataTemp.push({
              /*        project_id: item.project_id,
                       project_spaceship_id: item.project_spaceship_id, */
              module_name: item.module_name,
              module_id: item.module_id,
              menu_name: item2.menu_name,
              menu_id: item2.menu_id,
              process_list: item2.process_list || [],
              mockup_color:check[0].ps_color
            })
          }
        }
      }
      console.log('projectDetail YIP :>>', dataTemp)
      getDataElementMockup(dataTemp);
     /*  setStageMonitoringView(dataTemp) */
      setDisplayProgress({
        ...displayProgress,
        actual: projectDetail.project_progress,
        plan: parseFloat(
          ((projectDetail.done_count + projectDetail.doing_count) /
            projectDetail.all_todo_count) *
            100,
        ).toFixed(2),
      })
      //console.log('plan', parseFloat((projectDetail.done_count + projectDetail.doing_count) / projectDetail.all_todo_count * 100).toFixed(2))
      document.addEventListener('mousemove', onMouseUpdate, false);
    }else {
      document.removeEventListener('mousemove', onMouseUpdate,false);
      close_descpription_image();
    }
  });
  }, [isOpen && projectDetail])
  console.log("ADB", projectDetail) 
  const [test,settest] = useState({test:''});

  function changeProcessStage(status_id){
    console.log(status_id)
    let temp = processStage || [];
    let check = temp.filter((e)=>{return e == status_id});
    if(check.length > 0){
      let index = temp.findIndex((e)=> e == status_id);
      if(index > -1){
        temp.splice(index,1);
      }
    }else if(check.length == 0){
      temp.push(status_id);
    }


    let dataTemp = []
    let checkYellow = temp.filter((e)=>{return e =='f00e63f2-ab27-4828-9d7a-f9316c28703e'});
    let checkRed = temp.filter((e)=>{return e =='4ded34bc-1d60-4512-b0ad-8c8daf16c0d8'});
    let checkGreen = temp.filter((e)=>{return e =='6e6f2408-6a06-4f93-8f0a-aa385cfc4188'});
    for (let item of projectDetail.moduleList) {
      for (let item2 of item.module_menu_list) {
        let check = item2.process_list.filter((e) => {
          return e.process_id == "e9ae4d57-077f-414f-9eb2-22f4c4a109c7" && (checkYellow.length > 0 && e.ps_id == "f00e63f2-ab27-4828-9d7a-f9316c28703e" || 
          checkRed.length > 0 && e.ps_id =="4ded34bc-1d60-4512-b0ad-8c8daf16c0d8" ||
          checkGreen.length > 0 && e.ps_id =="6e6f2408-6a06-4f93-8f0a-aa385cfc4188" )
        })
        if (check.length > 0) {
          dataTemp.push({
            /*        project_id: item.project_id,
                     project_spaceship_id: item.project_spaceship_id, */
            module_name: item.module_name,
            module_id: item.module_id,
            menu_name: item2.menu_name,
            menu_id: item2.menu_id,
            process_list: item2.process_list || [],
            mockup_color:check[0].ps_color
          })
        }
      }
    }
    console.log('projectDetail', dataTemp)
    getDataElementMockup(dataTemp);
    //setStageMonitoringView(dataTemp)

    console.log(temp)
    setProcessStage(temp);
    settest({...test,test:''});
    test.test = '';
    
  }

  function getDataElementMockup(dataMenu){
    let temp = {
      project_id:projectDetail.project_id,
      menu_list:dataMenu
    }
    console.log('item:>', temp);
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/element/getDataElementMockup`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp

    })
      .then(function (response) {
        if (response.data) {
          // console.log(response.data);
          for(let item of dataMenu){
            let check = response.data.filter((e)=>{return item.menu_id == e.ehv_menu_id});
            item.mockup_data = check.length > 0 ? check[0].ehv_value.data : [];
          }
          // console.log('dataMenu',dataMenu);
          setStageMonitoringView(dataMenu);
          
          settest({...test,test:''});
          test.test = '';
         /*  setErrorMessage('');
          setModuleDetail({});

          toggle_modal_config_module();
          getProjectDetail(project_id) */
        }



      })
      .catch(function (error) {
        console.log(error);
       /*  setErrorMessage(error.response.data.error[0].errorDis) */

      });
  }

  async function getDataElementSelectMockup(dataMenu) {
    let temp = {
      project_id: projectDetail.project_id,
      menu_list: dataMenu,
    };
    console.log('item:>>>', temp);
    try {
      const response = await axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/element/getDataElementSelectMockup`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
        data: temp,
      });
  
      if (response.data) {
        for (let item of dataMenu) {
          let check = response.data.filter((e) => {
            return item.menu_id == e.ehv_menu_id;
          });
          item.mockup_data = check.length > 0 ? check[0].ehv_value.data : [];
        }
        console.log('dataMenu', dataMenu);
        setStageSelectMonitoringView(dataMenu);
        console.log('dataMenu:>>', stageSelectMonitoringView);
        settest({ ...test, test: '' });
        test.test = '';
        return dataMenu; // Return updated dataMenu
      }
    } catch (error) {
      console.log(error);
    }
  }


  async function reportSelectMockup(updatedDataMenu) {
  setLoading(0)
  let temp = {
    project_id: projectDetail.project_id,
  };

  await axios({
    method: 'post',
    url: `${Configs.TTT_Game_Service}/api/report/reportSelectMockup`,
    headers: {
      Authorization: sessionStorage.getItem('access_token'),
      'X-TTT': `${Configs.API_TTT}`,
      'Content-Type': 'application/json',
    },
    data: temp,
  })
    .then(async function (response) {
      if (response.data) {

        console.log('Response data:', response.data);
        console.log('updatedDataMenu:', updatedDataMenu);
        let callback = await reportmock(updatedDataMenu, response.data ,callbackLoading);
        console.log('updatedDataMenu',updatedDataMenu);
        toggle_modal_alertKick();
        setAlertKick(false);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

  const [position,setPosition] = useState({
    x:null,
    y:null
  })
/* var x = null;
var y = null; */

  function onMouseUpdate(e) {
    setPosition({...position,x:e.pageX,y:e.pageY});

    position.x = e.pageX;
    position.y = e.pageY;
    CalPosition(e.pageX+10, e.pageY+10);
    //console.log(x, y);

  }

  async function reportMockup(){
    setLoading(0)
    let temp = {
      project_id:projectDetail.project_id,
    }
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/reportMockup`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp,
    })
      .then(async function (response) {
        let data = response.data
        
        if (response.data) {
          console.log('stageMonitoringView',stageMonitoringView);
          let callback = await reportmock(stageMonitoringView,response.data,callbackLoading)
          toggle_modal_alertKick()
          setAlertKick(false) 
        }

      })
      .catch(function (error) {
        console.log(error);
       /*  setErrorMessage(error.response.data.error[0].errorDis) */

      });
  }
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/*     {isOpen && (
    <> */}
      <ConfigProvider
        theme={{
          token: {
            /*     colorPrimary: '#85BFFF', */
            colorInfo: '#85BFFF',
            colorBorder: '#85BFFF',
            colorText: '#FFF',

            colorTextDescription: '#000',
            colorBgContainer: '#000',

            zIndexPopupBase: 999999,
            colorPrimaryBg: 'blue', //สีตอน Active ใน Select
            colorBgBase: '#778899', //พื้นหลัง select ตอนกด down ลงมา

            colorTextQuaternary: '#FFFFFF', // สีตัวหนังสือของ placeholder

            colorFillTertiary: '#000', // สีตอน hover ใน Select

            colorTextBase: 'blue', // ลูกศรใน datepicker & rangepicker

            colorPrimaryBg: '#85BFFF',
            /*       
                   colorBgLayout:'red',
                   colorFillQuaternary:'red', */
          },
        }}
      >
        <div
          className={`backdropModuleMenu ${isOpen ? 'opened' : 'closed'}`}
          onClick={() => {
            if (openModalSendMail !== true) {
              toggle_modal_process_mockup();
              closeModal();
            }
          }}
        >
          {' '}
        </div>
        <div
          className={`detailBoxModuleMenu ${isOpen ? 'opened' : 'closed'}`}
          style={{
            /* top:`10%`, */ 
            left: `${switModeShowDisplay === true ? '0%' : '50%'}`,
            position: `${switModeShowDisplay === true ? 'absolute':'fixed'}`, 
            transform: `${switModeShowDisplay === true ? '':'translate(-50%, -14%)'}`,
            width: `${switModeShowDisplay === true ? '100%':'80%'}` , 
            height: `${switModeShowDisplay === true ? '83vh':'80%'}`,
            top: `${switModeShowDisplay === true ? '4%':''}`,
           
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              margin: 0,
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              className="row"
              style={{ width: '100%', margin: 0, padding: 0 }}
            >
              <div style={{ width: '6px', background: '#0047FF' }}></div>
              <div className='col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3' style={{ padding: 0, textAlign: 'center', paddingTop:'6px', background: 'linear-gradient(-90deg, rgba(0,0,0,1) 0%, rgba(0,43,153,1) 100%)' }}>
                <h4>STAGE MONITORING {`"MOCKUP"`}</h4>{' '}
              </div>

              <div className="col-1" style={{ padding: 0 }}>

                    <div className="colorProcessBody_" style= {{height:'40px',marginTop:'-5%', width: '120px'}}>
            
                      <div className={`colorProcessBoxClick2 ${
                          processStage.filter((e)=>{return e == '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8'}).length > 0
                       
                            ? 'active'
                            : ''
                        }`}
                         onClick={() => { changeProcessStage('4ded34bc-1d60-4512-b0ad-8c8daf16c0d8'); }}
                         style={{width: '33%'}}
                      >
                        <div
                          className="colorProcessBoxClick1Box"
                          style={{
                            border: `1px solid ${
                              processStage.status_id ==
                              '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8'
                                ? '#F00'
                                : 'white'
                            }`,
                          }}
                        >
                          <div
                            className="colorProcessBoxClick1BoxInMain"
                            style={{ backgroundColor: 'rgba(255, 0, 0, 1)' }}
                          ></div>
                          <div
                            className="colorProcessBoxClick1BoxInSec"
                            style={{ backgroundColor: 'rgba(255, 0, 0, 1)' }}
                          ></div>
                        </div>
                        <svg
                          height="100%"
                          width="100%"
                          style={{ position: 'relative', top: '-100%' }}
                        >
                          <line
                            x1="0%"
                            y1="15%"
                            x2="15%"
                            y2="0%"
                            style={{
                              stroke: `${
                                processStage.status_id ==
                                '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8'
                                  ? '#F00'
                                  : 'white'
                              }`,
                              strokeWidth: '1px',
                            }}
                          />
                          <line
                            x1="80%"
                            y1="100%"
                            x2="100%"
                            y2="80%"
                            style={{
                              stroke: `${
                                processStage.status_id ==
                                '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8'
                                  ? '#F00'
                                  : 'white'
                              }`,
                              strokeWidth: '1px',
                            }}
                          />
                          <line
                            x1="20%"
                            y1="1%"
                            x2="100%"
                            y2="1%"
                            style={{
                              stroke: `${
                                processStage.status_id ==
                                '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8'
                                  ? '#F00'
                                  : 'white'
                              }`,
                              strokeWidth: '7px',
                              clipPath:
                                'polygon(0 0, 100% 0, 100% 100%, 30% 100%)',
                            }}
                          />
                        </svg>
                      </div>

                      <div
                        className={`colorProcessBoxClick3 ${
                          processStage.filter((e)=>{return e == 'f00e63f2-ab27-4828-9d7a-f9316c28703e'}).length > 0
                       
                            ? 'active'
                            : ''
                         
                        }`}
                        onClick={() => { changeProcessStage('f00e63f2-ab27-4828-9d7a-f9316c28703e'); }}
                        style={{width: '33%'}}
                      >
                        <div
                          className="colorProcessBoxClick1Box"
                          style={{
                            border: `1px solid ${
                              processStage.status_id ==
                              'f00e63f2-ab27-4828-9d7a-f9316c28703e'
                                ? '#FFEB3B'
                                : 'white'
                            }`,
                          }}
                        >
                          <div
                            className="colorProcessBoxClick1BoxInMain"
                            style={{ backgroundColor: 'rgba(255, 235, 59, 1)' }}
                          ></div>
                          <div
                            className="colorProcessBoxClick1BoxInSec"
                            style={{ backgroundColor: 'rgba(255, 235, 59, 1)' }}
                          ></div>
                        </div>
                        <svg
                          height="100%"
                          width="100%"
                          style={{ position: 'relative', top: '-100%' }}
                        >
                          <line
                            x1="0%"
                            y1="15%"
                            x2="15%"
                            y2="0%"
                            style={{
                              stroke: `${
                                processStage.status_id ==
                                'f00e63f2-ab27-4828-9d7a-f9316c28703e'
                                  ? '#FFEB3B'
                                  : 'white'
                              }`,
                              strokeWidth: '1px',
                            }}
                          />
                          <line
                            x1="80%"
                            y1="100%"
                            x2="100%"
                            y2="80%"
                            style={{
                              stroke: `${
                                processStage.status_id ==
                                'f00e63f2-ab27-4828-9d7a-f9316c28703e'
                                  ? '#FFEB3B'
                                  : 'white'
                              }`,
                              strokeWidth: '1px',
                            }}
                          />
                          <line
                            x1="20%"
                            y1="1%"
                            x2="100%"
                            y2="1%"
                            style={{
                              stroke: `${
                                processStage.status_id ==
                                'f00e63f2-ab27-4828-9d7a-f9316c28703e'
                                  ? '#FFEB3B'
                                  : 'white'
                              }`,
                              strokeWidth: '7px',
                              clipPath:
                                'polygon(0 0, 100% 0, 100% 100%, 30% 100%)',
                            }}
                          />
                        </svg>
                      </div>

                      <div
                        className={`colorProcessBoxClick4 ${
                          processStage.filter((e)=>{return e == '6e6f2408-6a06-4f93-8f0a-aa385cfc4188'}).length > 0
                       
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => { changeProcessStage('6e6f2408-6a06-4f93-8f0a-aa385cfc4188'); }}
                        style={{width: '33%'}}
                      >
                        <div
                          className="colorProcessBoxClick1Box"
                          style={{
                            border: `1px solid ${
                              processStage.status_id ==
                              '6e6f2408-6a06-4f93-8f0a-aa385cfc4188'
                                ? '#259B24'
                                : 'white'
                            }`,
                          }}
                        >
                          <div
                            className="colorProcessBoxClick1BoxInMain"
                            style={{ backgroundColor: 'rgba(37, 155, 36, 1)' }}
                          ></div>
                          <div
                            className="colorProcessBoxClick1BoxInSec"
                            style={{ backgroundColor: 'rgba(37, 155, 36, 1)' }}
                          ></div>
                        </div>
                        <svg
                          height="100%"
                          width="100%"
                          style={{ position: 'relative', top: '-100%' }}
                        >
                          <line
                            x1="0%"
                            y1="15%"
                            x2="15%"
                            y2="0%"
                            style={{
                              stroke: `${
                                processStage.status_id ==
                                '6e6f2408-6a06-4f93-8f0a-aa385cfc4188'
                                  ? '#259B24'
                                  : 'white'
                              }`,
                              strokeWidth: '1px',
                            }}
                          />
                          <line
                            x1="80%"
                            y1="100%"
                            x2="100%"
                            y2="80%"
                            style={{
                              stroke: `${
                                processStage.status_id ==
                                '6e6f2408-6a06-4f93-8f0a-aa385cfc4188'
                                  ? '#259B24'
                                  : 'white'
                              }`,
                              strokeWidth: '1px',
                            }}
                          />
                          <line
                            x1="20%"
                            y1="1%"
                            x2="100%"
                            y2="1%"
                            style={{
                              stroke: `${
                                processStage.status_id ==
                                '6e6f2408-6a06-4f93-8f0a-aa385cfc4188'
                                  ? '#259B24'
                                  : 'white'
                              }`,
                              strokeWidth: '7px',
                              clipPath:
                                'polygon(0 0, 100% 0, 100% 100%, 30% 100%)',
                            }}
                          />
                        </svg>
                      </div>
                    
                     
                    </div>
          
              </div>
              <div 
              // className="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2"  
              style={{  paddingTop:'6px', paddingLeft: '15px', paddingRight: '15px' }}>
                <h4 style={{ textAlign: 'center', fontSize: '21px' }}><span style={{ color: 'rgba(0, 71, 255, 1)' }}>ACTUAL :</span> {displayProgress.actual}%</h4>
              </div>
              <div
                // className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3"
                style={{ paddingTop:'6px' }}
              >
                <h4 style={{ textAlign: 'center', fontSize: '21px' }}>
                <span style={{ color: 'rgba(0, 71, 255, 1)' }}>TARGET :</span> {displayProgress.plan}%
                  {` (+${parseFloat(
                    displayProgress.plan - displayProgress.actual,
                  ).toFixed(2)}%)`}
                </h4>
              </div>
              <div  className="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2" style={{ textAlign: 'center', padding: '0'}} 
              onClick={() => {}}>
                <button style={{ marginTop:'-6px',width:'80%',marginBottom:'10px' }} type="button" className='btn-report-mockup' ><img src={UploadReportMockup} style={{ width: '10%' }} alt='' /><b> Business Flow</b></button>
              </div>
              <div className="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2" style={{ textAlign: 'center', padding: '0' }} 
                  // onClick={() => {
                  //    setErrorMessage({ ...errorMessage, message: '', status: true });
                  //    toggle_modal_alertKick();
                  //    reportMockup();  
                  // }}
                  onClick={openModal}
                  >
                <button style={{ marginTop: '-6px', width: '80%', marginBottom: '10px' }} type="button" className='btn-report-mockup'>
                  <img src={UploadReportMockup} style={{ width: '10%' }} alt='Upload Report Mockup' />
                  <b> Report Mockup</b>
                </button>
              </div>
              <ModalUploadReport 
                isOpen={isModalOpen}
                onClose={closeModal}
                projectDetail={stageMonitoringView}
                reportMockup={reportMockup}
                reportSelectMockup={reportSelectMockup}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                alertKick={alertKick}
                toggle_modal_alertKick={toggle_modal_alertKick}
                getDataElementSelectMockup={getDataElementSelectMockup}
              />
              {/* <ModalUploadReport 
                toggle_modal_upload_report={toggle_modal_upload_report}
                isOpen={openModalUploadReport}
                projectDetail={projectDetail}
              /> */}
              {/* <div
                className="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2"
                style={{ width: '100%', border: '1px solid red' }}
              >
                <h4 style={{ width: '100%', textAlign: 'end', border: '1px solid red' }}>
                  ACTUAL : {displayProgress.actual}%
                </h4>
              </div>
              <div
                className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3"
                style={{ width: '100%', border: '1px solid red' }}
              >
                <h4 style={{ width: '100%', border: '1px solid red' }}>
                  TARGET : {displayProgress.plan}%
                  {` (+${parseFloat(
                    displayProgress.plan - displayProgress.actual,
                  ).toFixed(2)}%)`}
                </h4>
              </div>
              <div  className="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2" style={{ textAlign: 'center', border: '1px solid red' }} 
              onClick={() => {}}>
                <button style={{ marginTop:'-6px',width:'100%',marginBottom:'10px' }} type="button" className='btn-report-mockup' ><img src={UploadReportMockup} style={{ width: '10%' }} alt='' /><b> Business Flow</b></button>
              </div>
              <div  className="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2" style={{ textAlign: 'center', border: '1px solid red' }} 
              onClick={() => {
                 setErrorMessage({ ...errorMessage, message: '', status: true });
                 toggle_modal_alertKick();
                 reportMockup()}}>
                <button style={{ marginTop:'-6px',width:'100%',marginBottom:'10px' }} type="button" className='btn-report-mockup' ><img src={UploadReportMockup} style={{ width: '10%' }} alt='' /><b> Report Mockup</b></button>
              </div> */}
            </div>

            <div style={{ height: '15px', borderTop: '1px solid #FFF' }}></div>

            <div
              className="table-responsive"
              style={{/*  height: '35px' */}}
            >
              <div className="col-12 table-responsive">
                <table>
                  <tr>
                    <td
                      style={{
                        minWidth: '255px',
                        width: '20%',
                        textAlign: 'left',
                      }}
                    >{`[MODULE] >> [MENU]`}</td>
                    <td
                      style={{
                        minWidth: '255px',
                        width: '80%',
                        textAlign: 'left',
                      }}
                    >{`MOCKUP`}</td>
                  </tr>
                  {stageMonitoringView.length > 0 &&
                    stageMonitoringView.map((e2) => {
                      //console.log(e2.menu_name)

                      return (
                        <>
                          <tr
                            style={{
                              height: '250px',
                              borderBottom: '1px solid #fff',
                            }}
                          >
                            <td
                              style={{ whiteSpace: 'nowrap',verticalAlign:'top',paddingTop:'1%' }}
                            >
                              {' '}
                              <div className="row mb-2">
                                {/* <span> */}
                                <Tooltip title={e2.menu_name}>
                                  <p
                                    onClick={() => {}}
                                    style={{
                                      maxWidth: '290px',
                                      fontSize: '14px',
                                      color: '#fff',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      margin: '0 0 0',
                                    }}
                                  >
                                    {' '}
                                    {e2.module_name}
                                    {' >> '}
                                    {e2.menu_name}
                                  </p>
                                </Tooltip>
                                <p style={{ margin: '0 0 0' }}> </p>
                                {/* </span> */}
                              </div>
                            </td>

                            <td
                              style={{
                                verticalAlign: 'top',
                                
                            /*     border:'2px solid red', */
                              /*   width:'50%' */
                                /* width: '100%',
                                height: '220px ',
                                textAlign: 'left',
                                display:'flex',
                                
                                gap:'2%' */
                              }}
                            >
                              <div style= {{display:'flex',gap:'1%'}}>
                              <div className='processColor' style ={{verticalAlign:'top',paddingTop:'2%'}}>
                              <button
                                style={{
                                  clipPath:
                                    'polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)',
                                  width: '50px',
                                  height: '10px',
                                  background: e2.mockup_color,
                                  border: '0px',
                                }}
                              >
                                <button
                                  onClick={(ee) => {}}
                                  style={{
                                    clipPath:
                                      'polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)',
                                    width: '50px',
                                    height: '10px',
                                    background: e2.mockup_color,
                                    border: '0px',
                                    cursor: 'pointer',
                                  }}
                                ></button>
                              </button>
                              </div>
                           
                              
                              <div className="imgMockupContainer" > 
                                  {e2.mockup_data && e2.mockup_data.map((e,index)=>{
                                    return (<>
                                      <div className="imgMockupCard" id = {`imgMockupCard_${index}`}>
                                  <div className="imgMockupTagVersion">VER.<div className ="numberVersionCircle"><div className ="numberVersion">{e.data[e.data.length-1].version}</div></div></div>
                                  <div className="imgMockupCardBody" onClick={()=>{
                                    callbackDataMockupVersion(e.data);
                                    toggle_modal_process_mockup_view_version();
                                  }}
                                  onMouseEnter={() => {
                                  /*   let xx = ee.clientX;
                                    let yy = ee.clientY;
                                    console.log(xx,yy) */
                            /*         const element = document.getElementById(`decriptionViewCard`);
                                    console.log('element.offsetWidth',element.offsetWidth) */

                                    console.log('window.screen.availWidth',window.screen.availWidth);
                                    
                                   
                                    close_descpription_image();
                                    callbackDataMockupDescription(e.data[e.data.length-1].remark);
                                    open_descpription_image();
                                    console.log('Hover On')
                                    var timeleft = 1;
  

/* var downloadTimer = setInterval(function(){
  if(timeleft <= 0){
    clearInterval(downloadTimer);
    if(isOpen == true){
      callbackDataMockupDescription(e.data[e.data.length-1].remark);

           }
    open_descpription_image();
    console.log('Hover On')
  }
 
  timeleft -= 1;
}, 1000); */
                        
                          }}
                                    
                                  onMouseLeave={() => {
                                    close_descpription_image();
                                    console.log('Hover Off')}}
                                  >
                                    
                                    <Getimg
                                      img={e.data[e.data.length-1].box_path? e.data[e.data.length-1].box_path : e.data[e.data.length-1].pathbox? e.data[e.data.length-1].pathbox : e.data[e.data.length-1].path}
                                      isnew={!e.data[e.data.length-1].box_path? false : true}
                                      style={{width:'100%',height:'100%',objectFit:'contain'}}
                                      callback={(result) => {
                                        e.data[e.data.length-1].box_path = result
                                      
                                      }}
                                      />
                                 {/*  <img src = {`${Configs.TTT_Game_Service_IMG}${e.data[e.data.length-1].path}`}  
                                    style= {{width:'100%',height:'100%',objectFit:'contain'}}
                                  />  */}
                                   </div>
                                  </div>
                                    </>);
                                  })}
                                  </div>
                                  </div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                </table>
              </div>
            </div>

            <div style={{ flexGrow: 1, overflowY: 'auto' }}>
              <div
                className="table-responsive"
                style={{ height: '97%', marginTop: '1%' }}
              >
                <div className="col-12 table-responsive">
                  <table>
                    {/* 
                  {stageMonitoringView.length > 0 && stageMonitoringView.map((e2) => {
                    console.log(e2.menu_name)
                      
                        return (<>
                          <tr>
                            <td style={{ height: '30px', whiteSpace: 'nowrap',width:'30%' }}>  <div className="row mb-2">
                              <Tooltip title={e2.menu_name}>
                                <p
                                  onClick={() => {

                                  }}
                                  style={{ maxWidth: '290px', fontSize: '14px', color: '#fff', overflow: 'hidden', textOverflow: 'ellipsis', margin: '0 0 0' }}> {e2.module_name}{' >> '}{e2.menu_name}</p>
                              </Tooltip>
                              <p style={{ margin: '0 0 0' }}>
                                {" "}

                              </p>
                              </div></td>
                        
                                  <td style={{width:'30%', height: '30px', textAlign: 'left' }}>
                                    <button style={{ clipPath: "polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)", width: '50px', height: '10px', background: 'green', border: '0px' }}>
                                      <button onClick={(ee) => {

                                   



                                      }} style={{ clipPath: "polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)", width: '50px', height: '10px', background: 'green', border: '0px', cursor: 'pointer' }}></button>
                                    </button>
                                  </td>
                           


                          </tr>
                        </>)
                      
                    })} */}
                  </table>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                className=" btn-outline-danger"
                style={{ padding: '5px' }}
                onClick={() => {
                  toggle_modal_process_mockup();
                }}
              >
                {' '}
                Cancel{' '}
              </button>
            </div>
          </div>
          <br />
          <br />

          {/*     </>)} */}

          {/*  <ModalSendMail
            isOpen={openModalSendMail}
            stageMonitoringData={stageMonitoringDataReport}
            togle_modal_send_mailFun={() => { togle_modal_send_mail() }}
          />

          <ModalViewsList
            isOpen={openModalViewListReport}
            stageMonitoringData={stageMonitoringDataReport}
            togle_modal_send_mailFun={() => { togle_modal_ViewListReport() }}
          /> */}
        </div>
        <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
        <div className='alertKickBoxShadow' >
          <div className='alertKickBox' style={{ height: 'fit-content' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `${errorMessage.status ? '#fff' : 'green'}` }}>
                  {errorMessage.status ? 'Loading' : 'SUCCESS'}
                  
                </div>
                
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning' style={{ border: 'none' }}>
                  {errorMessage.status ? <><div class="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div></> : <></>}
                </div>
              </div>
              {errorMessage.status ? <>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {Loading.toFixed(2)}%
              </div>
              </> : <>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    {errorMessage.message}
                  </div>
                </div>
                <div className='row alertKickBoxButton'>
                  <button className='alertKickButton' onClick={() => {
                    toggle_modal_alertKick()
                  }}>OK</button>

                </div>
              </>}


            </div>
          </div>
        </div>
      </div>
      </ConfigProvider>

      {/*     </>
    
  )} */}
    </>
  )
}
export default ModalProcessMockup
