import React, { useEffect, useState, useRef } from 'react'
import "./style.css"

import { NumericFormat, PatternFormat } from 'react-number-format';


import Configs from "../../../../../config";

import axios from "axios";
import { DatePicker, Input, Tooltip, ConfigProvider, Select } from 'antd';
import { DeleteOutlined,
     DownloadOutlined,
      EditOutlined,
      ArrowUpOutlined,
      ArrowDownOutlined
    
    } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';

import backIcon from './Image/Icon.png'
import { isFunction } from 'lodash';
import Monthlyworksummaryreport from '../../../../component/Report/Monthlyworksummaryreport';
import geteffortreport from '../../../../component/Report/effortreport';
const { RangePicker } = DatePicker;
const { TextArea } = Input;

function FederationSummaryEffort(params) {

    




    const options = [];
    for (let i = 10; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }


    useEffect(() => {
        if (params.federationSummaryEffortIsOpen) {
            getDocList();
            setPersonalList([]);
            setSummaryEffort({...summaryEffort,month: dayjs()})
        
            /*     getFederationWorkloadData(); */
        };

    }, [params.federationSummaryEffortIsOpen])



    const [test, settest] = useState({ test: '' });
    const [timeSheeMonthDetail, setTimeSheetMonthDetail] = useState({
        id: '',
        mode: 'add',
        dear: '',
        month: dayjs()/* moment(new Date()).format('YYYY-MM') */,
    })


    const [summaryEffort, setSummaryEffort] = useState({
        month: dayjs()/* moment(new Date()).format('YYYY-MM') */,
    })

    const [documentVersionList, setDocumentVersionList] = useState([]);

    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);
    const [errorMasgerList, setErrorMasgerList] = useState({
        isError: false,
        massger: []
    });

    const [loading, setLoading] = useState({
        status: false
    });

     const [personalList,setPersonalList] = useState([])

    async function getPersonalByMonth(){
        let  temp = {
            month:summaryEffort.month,
            fed_id:params.federation_id
        }
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/federation/getPersonalByMonth`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                setPersonalList(response.data);


            })
            .catch(function (error) {
                /*  setLoading(false); */
               
                console.log(error);
                
            });
    }

    

    

    async function generateDoc() {
        setLoading({ ...loading, status: true });
        loading.status = true;

        let temp = {
            fed_id: params.federation_id,
           
            month: summaryEffort.month,
            user_list: personalList

        }

        const error_list = [];

      



        if (error_list.length > 0) {
            setLoading({ ...loading, status: false });
            loading.status = false;
            setErrorMasgerList({
                ...errorMasgerList,
                isError: true,
                massger: error_list
            });

            toggle_modal_alertKick()
            /*   var err_message = "";
              for (var e = 0; e < error_list.length; e++) {
                  err_message += "<br/>" + error_list[e].message;
              }
              Swal.fire("Error", err_message, "error"); */
        } else {


            await axios({
                method: "POST",
                url: `${Configs.TTT_Game_Service}/api/federation/saveDocversionSummaryEffort`,
                headers: {
                    'Authorization': sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp
            })
                .then(async function (response) {
/* console.log(response.data,temp); */
effortreport(temp,response.data)
                   
                })
                .catch(function (error) {
                    /*  setLoading(false); */
                    setLoading({ ...loading, status: false });
                    loading.status = false;
                    console.log(error);
                    setErrorMasgerList({
                        ...errorMasgerList,
                        isError: true,
                        massger: error.response.data.error[0].errorDis
                    })
                    toggle_modal_alertKick();

                  
                });

        }
    }

    async function getDocList(){

        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/federation/getSummaryEffortList/${params.federation_id}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
         
        })
            .then(async function (response) {
                setDocumentVersionList(response.data);

            })
            .catch(function (error) {
                /*  setLoading(false); */
               
                console.log(error);
                
            });
    }

   

    function sortUpPersonal(index){
        let temp = personalList;
        let tmp = temp[index];
        temp[index] = temp[index-1];
        temp[index-1] = tmp;
        setPersonalList(temp);
        settest({...test,test:''});
        test.test = '';


    }

    function sortDownPersonal(index){
        let temp = personalList;
        let tmp = temp[index];
        temp[index] = temp[index+1];
        temp[index+1] = tmp;
        setPersonalList(temp);
        settest({...test,test:''});
        test.test = '';


    }
   
    async function effortreport(temp,data){
       /*  let  temp = {
            month:summaryEffort.month,
            fed_id:params.federation_id
        } */
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/report/effortreport`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                geteffortreport(response.data,data, (result) => {
                    setLoading({ ...loading, status: false });
                loading.status = false;
                temp.data = response.data
                setPersonalList([]);
                let trop = []
                let temp_succ = {
                    message: "success"
                }
                trop.push(temp_succ);
                setErrorMasgerList({
                    ...errorMasgerList,
                    isError: false,
                    massger: trop
                })
                toggle_modal_alertKick()
                getDocList();

                })
               
              

            })
            .catch(function (error) {
                /*  setLoading(false); */
               
                console.log(error);
                
            });
    }

    return (
        <>
            <div className='fedWorkloadBg' style={{ display: `${params.federationSummaryEffortIsOpen ? '' : 'none'}` }}>
                <div className='row fedWorkloadBackBox' style={{ margin: 0, padding: 0 }}>
                    <div className='fedWorkloadBackButton' onClick={() => {
                        params.toggleFederationSummaryEffortIsOpen();
                        params.closeElement();
                    }}>
                        <img src={backIcon} style={{ width: '100%', height: '100%' }}></img>
                    </div>
                    <div className='fedWorkloadBackLine'></div>
                    <div className='fedWorkloadBackNameBox'>
                        <div className='fedWorkloadBackNameHead'>Federation Summary Effort Report</div>
                        <div className='fedWorkloadBackNameText'>{params.federation_name}</div>
                    </div>
                </div>

                <ConfigProvider theme={{
                    token: {

                        colorInfo: 'red',
                        colorBorder: '#0047FF',
                        colorText: '#FFF',

                        colorTextDescription: '#FFFFFF',
                        colorBgContainer: '#001D5F',
                        zIndexPopupBase: 999999,
                        colorPrimaryBg: '#FFFFFF',
                        colorBgBase: '#001D5F',//พื้นหลัง datepicker

                        colorTextQuaternary: 'White',//สีตัวอักษร

                        colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                        colorTextBase: 'White', // สี scroll bar

                        colorPrimaryBg: '#0047FF',// สีตอนเลือก
                        /*       
                               colorBgLayout:'red',
                               colorFillQuaternary:'red', */


                    }
                }} >


                    <div className='fedWorkMonthBody'>
                        <div className='fedWorkMonthFilter'>
                            <div className='row' style={{ width: '50%' }}>
                                
                                <div className='col-4'>Month
                                    <DatePicker className='inputMonthFedWorkMonth' picker="month"
                                        /*    value={timeSheeMonthDetail.month} */

                                    /*     disabled={timeSheeMonthDetail.mode == 'edit'} */
                                        value={summaryEffort.month}
                                        allowClear={false}
                                        onChange={(e, e2) => {
                                            //console.log(moment(e).format('DD/MM/YYYY'))
                                            setPersonalList([]);
                                            setSummaryEffort({ ...summaryEffort, month: dayjs(e2) });
                                            //console.log(moment(e2).format('DD/MM/YYYY'))
                                        }}
                                    />
                                    
                                </div>

                                <div className='col-4'>{/* Month */}
                                    <br/>
                                <div className='btnGen'
                                    onClick={async () => {
                                       getPersonalByMonth();
                                        
                                    }}
                                >Get Personal</div>
                                    
                                </div>
                          

                            </div>
                            <div style={{ display: 'flex', gap: '15px' }}>
                               
                                    {personalList.length > 0 && (
                                         <div className='btnGen'
                                    
                                         onClick={async () => {
                                            generateDoc();
     
                                         }}
                                     >Generate</div>
                                    )}
                               
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', paddingLeft: '10px', height: '40%', overflowY: 'auto', flexDirection: 'column' }}>
                        <table style={{ width: '100%' }}>
                            <tr className='testtest' style={{ position: 'sticky', top: 0, zIndex: 3, maxHeight: '37px' }}>
                                <td style={{ textAlign: 'center', width: '10%' }}>No</td>
                                <td style={{ textAlign: 'center', width: '30%' }}>Position</td>
                                <td style={{ textAlign: 'center', width: '50%' }}>Name</td>
                                <td style={{ textAlign: 'center', width: '20%' }}>Sort</td>
                            </tr>

                                    {personalList.map((e,index)=>{
                                        return (<>
                                            <tr style={{ height: '37px', background: 'rgba(0,0,0,0.1)',textTransform:'none' }}>
                                            <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px',textTransform:'none' }}>{index+1}</td>
                                        <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px',textTransform:'none' }}>{e.role_name}</td>
                                        <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px',textTransform:'none' }}>{e.name}{" "}{e.last_name}</td>
                                        <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px',display:'flex',gap:'5px',justifyContent:'center' }}>
                                        <div style={{cursor:'pointer',visibility:index == 0 ? 'hidden':''}}
                                        onClick={()=>{sortUpPersonal(index)}}
                                        >
                                        <ArrowUpOutlined />
                                        </div>
                                        <div style={{cursor:'pointer',visibility:index == personalList.length-1 ? 'hidden':''}}
                                        onClick={()=>{sortDownPersonal(index)}}
                                        >
                                        <ArrowDownOutlined />
                                        </div>
                                            {/* <TextArea
                                                placeholder=""
                                                autoSize={{
                                                    minRows: 2,
                                                    maxRows: 6,
                                                }}
                                                value={e.remark}
                                                onChange={(e2) => {
                                                    setRemarkProject(index, e2.target.value)
                                                }}
                                            /> */}
                                        </td>
                                        
                                    </tr>
                                        </>)
                                    })}
                                    
                              
                        </table>
                        <div style={{ height: '100%', background: 'rgba(0,0,0,0.1)' }}></div>
                    </div>
                    <div style={{ borderBottom: '1px solid #fff', marginLeft: '10px' }}></div>
                    <div style={{ display: 'flex', paddingLeft: '10px', height: '40%', overflowY: 'auto', flexDirection: 'column' }}>
                        <table style={{ width: '100%' }}>
                            <tr className='testtest' style={{ position: 'sticky', top: 0, zIndex: 3, maxHeight: '37px' }}>
                                <td style={{ textAlign: 'center', width: '10%' }}>No.</td>
                                <td style={{ textAlign: 'center', width: '10%' }}>Date</td>
                                <td style={{ textAlign: 'center', width: '70%' }}>Generate Version</td>
                                <td style={{ textAlign: 'center', width: '10%' }}>MANAGEMENT</td>
                            </tr>
                            {documentVersionList.map((e, index2) => {
                                let bg = 'rgba(0,0,0,0.1)';
                                if (e.ftm_id == timeSheeMonthDetail.id) {
                                    bg = 'rgba(0, 71, 255, 0.15)';
                                }
                                return (<>
                                    <tr style={{ height: '37px', background: bg }}>
                                        <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px' }}>{documentVersionList.length - index2}</td>
                                        <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px' }}>{moment(e.vse_created_date).format('DD/MM/YYYY HH:mm')}</td>
                                        <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px' }}>
                                            {`${e.vse_filename}.xlsx`}
                                        </td>
                                        <td style={{ textAlign: 'center', fontSize: '24px', color: '#2894DC', display: 'flex', gap: '15px', justifyContent: 'center' }}>


                                            <a /* href = {`${e.href}`} download={`${e.ftm_filename}.pdf`} */ target='_blank'
                                                onClick={async () => {
                                                    console.log(e);
                                                    
                                                    if (e.fd_img_id !== null && !isNaN(parseInt(e.fd_img_id))) {
                                                        let response2 = await axios.get(`https://api.box.com/2.0/files/${e.fd_img_id}/content`, {
                                                            headers: {
                                                                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                                            },
                                                            responseType: 'blob',
                                                        });

                                                        // Create a temporary anchor element
                                                        const a = document.createElement('a');
                                                        a.href = URL.createObjectURL(response2.data);
                                                        /* console.log(e); */
                                                        a.download = `${e.vse_filename}.xlsx`; // Set the desired filename here

                                                        // Append the anchor to the body
                                                        document.body.appendChild(a);

                                                        // Trigger a click on the anchor to open the file in a new window/tab
                                                        a.click();

                                                        // Clean up: remove the anchor and revoke the object URL
                                                        document.body.removeChild(a);
                                                        URL.revokeObjectURL(URL.createObjectURL(response2.data));
                                                        /*    console.log(response2)
                                                           window.open(
                                                            URL.createObjectURL(response2.data),
                                                            '_blank', // <- This is what makes it open in a new window.
                                                    
                                                            ); */


                                                    }


                                                }}
                                            >
                                                <DownloadOutlined style={{ cursor: 'pointer' }} /* onClick={(e)=>{

                                        }} *//>
                                            </a>

                                        </td>
                                    </tr>
                                </>)
                            })}
                        </table>
                        <div style={{ height: '100%', background: 'rgba(0,0,0,0.1)' }}></div>
                    </div>
                </ConfigProvider>




                {loading.status && (
                    <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                        <div className='alertKickBoxShadow'>
                            <div className='alertKickBox' style={{ height: 'fit-content' }}>
                                <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                    <div className='row' style={{ height: '25%' }}>
                                        <div className='alertKickHead' style={{ color: 'green' }}>
                                            Loading
                                        </div>
                                    </div>
                                    <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                        <div className='alertKickWarning' style={{ border: 'none' }}>
                                            <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}` }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox' style={{ height: 'fit-content', width: '429 !important' }}>
                            <div className='alertKickBoxIn' style={{ height: 'fit-content', width: '490px !important' }}>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: `${errorMasgerList.isError ? 'red' : 'green'}` }}>
                                        {errorMasgerList.isError ? '! ERROR !' : 'SUCCESS'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning'>
                                        {errorMasgerList.isError ? 'WARNING' : 'SUCCESS'}
                                    </div>
                                </div>

                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickTextSec'>
                                        {errorMasgerList.massger.map((e) => {

                                            return <div> {e.message} </div>

                                        })}
                                    </div>
                                </div>
                                <div className='row alertKickBoxButton mt-4'>
                                    <button className='alertKickButton' onClick={() => {

                                        /*    if (displayPassword === true && errorMasgerList.isError !== true) {
                                               sessionStorage.clear(); window.location.href = '/'
                                           } */
                                        if (errorMasgerList.isError === false) {
                                            /*    setPersonalInfoCheck({
                                                   isOpen: false
                                               }) */
                                            /*       getInfomaionUserByID() */
                                            /*    setMenu('PROJECT') */
                                        }

                                        /*      setDisplayPassword(false) */
                                        toggle_modal_alertKick()
                                        setErrorMasgerList({
                                            ...errorMasgerList,
                                            isError: false,
                                            massger: []
                                        })
                                    }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FederationSummaryEffort;
