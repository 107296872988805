import React, { useState, useEffect, useRef } from 'react'
import './stageElementStyle.css'
import Configs from "../../../config";
import axios from 'axios';
import docicon from '../../../assets/img/icon/doc icon.png';
import uploadicon from '../../../assets/img/icon/upload.png';
import { Input, Space, Tag, theme, Tooltip, ConfigProvider, DatePicker, Modal, Upload } from 'antd';
import moment from 'moment';
import { data } from 'jquery';
import { findIndex } from 'lodash';
import Getimg from './getimgshow';
import Popup from './elementScreenshotPopup';

const { Dragger } = Upload;
const Screenshot = ({
  isOpen,
  toggle_modal_status,
  project_id,
  process_id,
  menu_id,
  callbackDataMockupVersion,
  toggle_modal_process_mockup_view_version,
  getProjectDetail,
  processStage,
  callBackScreenshot,
  displayMode
}) => {

  const [openPopup,setOpenPopup] = useState(false);
  const [dataPopup,setDataPopup] = useState([]);
  const closePopup = () => setOpenPopup(!openPopup)

  const fileInputRef = useRef(null);
  const fileInputRefall = useRef(null);

  const [errorEditSrsData, setErrorEditSrsData] = useState(false);
  const toggle_modal_errorEditSrsData = () => { setErrorEditSrsData(!errorEditSrsData) };
  const [uploadfileScreenshot, setuploadfileScreenshot] = useState({
    row: [],
    on: 0,
    size: 0,
    remove:[]
  })
  const [errorMessage, setErrorMessage] = useState({ status: '', message: '' });
  const [alertKick, setAlertKick] = useState(false);
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);
  
  const [miniversion, setminiversion] = useState(false);
  const toggle_modal_miniversion = () => { setminiversion(!miniversion) };
  const props = {

    maxCount: 1,
    showUploadList: false,
    name: 'file',
    accept: ".png, .jpg, .jpeg",
    multiple: true,
    method: 'get',
    headers: {
      'X-TTT': `${Configs.API_TTT}`,
      'Content-Type': 'application/json',
    },

    action: Configs.TTT_Game_Service + '/api/upload/fackFile',
    onChange(info) {
      const { status } = info.file;
      info.status = 'done';
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        /*  setFileImport(info.file); */

        /* message.success(`${info.file.name} file uploaded successfully.`); */
      } else if (status === 'error') {
        /* message.error(`${info.file.name} file upload failed.`); */
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };


  const [dragging, setDragging] = useState(false);


  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
    setDragging(true);
  };

  const handleDragOver = (e) => {
    if(displayMode == false || displayMode == 'false' ){
      e.preventDefault();
    }
    
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    const draggedIndex = e.dataTransfer.getData('text/plain');
    const newItems = [...uploadfileScreenshot.row];
    /*  console.log(newItems); */
    const draggedItem = newItems[draggedIndex];

    newItems.splice(draggedIndex, 1);
    newItems.splice(targetIndex, 0, draggedItem);

    let num = 1
for (let i of newItems) {
  newItems.no = num
  num+=1
  
}
    setuploadfileScreenshot({ ...uploadfileScreenshot, row: newItems })
    
    /*         setItems(newItems); */
    setDragging(false);
  };

  const handleTextareaChange = (e, index) => {
    const newItems = [...uploadfileScreenshot.row];
    let listdata = newItems[index].data[newItems[index].version - 1]
    listdata.remark = e.target.value;
    newItems[index].data[newItems[index].version - 1] = listdata
    setuploadfileScreenshot({ ...uploadfileScreenshot, row: newItems })
  };

  const handleDelete = (index) => {
    const newItems = [...uploadfileScreenshot.row];
    let isremove = uploadfileScreenshot.remove
    if (newItems[index].id !== undefined) {
      isremove.push(newItems[index])
    }
    newItems.splice(index, 1);
    console.log(uploadfileScreenshot);
    setuploadfileScreenshot({ ...uploadfileScreenshot, row: newItems,remove: isremove })
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [/* uploadfileScreenshot.row */]);

  useEffect(() => {
    if (isOpen === true) {
      setErrorMessage({ ...errorMessage, message: '', status: true });
      toggle_modal_alertKick();
      GetAccesstokenToken((result) => {
        if (result) {
          getScreenshot()
        }
      })
    }
   
    handleWindowResize()

  }, [isOpen]);
  
  async function GetAccesstokenToken(callback) {
    await axios({
        method: "post",
        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
        headers: {
            /* Authorization: getToken(), */
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
        },
    })
        .then(async function (response) {
            var temp = {
                tokenBox: response.data.tokenBox
            }
            if (callback) {
                callback(response.data.tokenBox);
            }
            sessionStorage.setItem('tokenBox', response.data.tokenBox)
            /*  console.log('ViewToken', response.data); */
        })
        .catch(function (error) {
            console.log(error);
        });

}
  
  const handleWindowResize = () => {

    if (uploadfileScreenshot.row.length > 0) {
      let windowy = document.getElementById("scrollableindiv").offsetWidth
      let cutwindowy = windowy * 0.7
      let cut = Math.floor(cutwindowy / 480)

      setuploadfileScreenshot({ ...uploadfileScreenshot, size: (cutwindowy - (cut * 480)) / 2 })
    } else {
      setuploadfileScreenshot({ ...uploadfileScreenshot, size: 0 })
    }


  };

  function getImageDimensions(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function(e) {
        var img = new Image();
        img.src = e.target.result;
        img.onload = function() {
          // สร้างอ็อบเจกต์ที่มีค่าความกว้างและความสูงของภาพ
          var dimensions = {
            width: this.naturalWidth,
            height: this.naturalHeight
          };
          // คืนค่าอ็อบเจกต์ dimensions กลับไป
          resolve(dimensions);
        };
      };
      // อ่านไฟล์ภาพ
      reader.readAsDataURL(file);
    });
  }

  async function saveupload() {
    setErrorMessage({ ...errorMessage, message: '', status: true });
    toggle_modal_alertKick();

    let datafile = []

    if (uploadfileScreenshot.row.length > 0) {
      let num = 1
      console.log(uploadfileScreenshot.row);
      for (let i of uploadfileScreenshot.row) {
        i.no = num
        num+=1
        
      }
      let datalistsort = uploadfileScreenshot.row.sort((a, b) => parseFloat(a.no) - parseFloat(b.no))
      /*  let num = 1 */
     /*  console.log(datalistsort); */

/* let countstep1 =0,maxstep1 = 30 */
      for (let il of datalistsort) {

        let datainversion = il.data, subno = 0
        for (let i of datainversion) {
          console.log(i);
          if (i.byid !== undefined && i.byid === true) {
let as = {
  name: i.name,
  path: i.path,
  remark: i.remark,
  version: i.version,
  byid: true,
  id: i.id,
  no: il.no,
  subno: subno,

}
if (i.pathbox !== undefined) {
  as.pathbox = i.pathbox
}
if (i.width !== undefined && i.height !== undefined) {
  as.width = i.width
  as.height = i.height
}

            datafile.push(as)
          } else {
        let chcekimg = await getImageDimensions(i.file)
       
        let as =  {
          name: i.name,
          path: URL.createObjectURL(i.file),
          files: i.file,
          remark: i.remark,
          version: i.version,
          no: il.no,
          subno: subno,
          width:chcekimg.width,
          height:chcekimg.height


        }
        console.log(as);
            datafile.push(
              as
            )

          }
        /*   countstep1 += maxstep1 */
          subno += 1
          /*   num+=1 */
        }
      }

    }

    let ole_file = [],newFile = []
    if (datafile.length > 0) {
      ole_file = datafile.filter((e) => e.byid !== undefined && e.byid == true)
       newFile = datafile.filter((e) => e.byid == undefined || e.byid == false)

      /* if (newFile.length > 0) {
        const dataImg = new FormData();
        for (let i = 0; i < newFile.length; i++) {

          dataImg.append('Profile', newFile[i].file);

        }

        dataImg.append('typeFile', "Screenshot");
        dataImg.append('projectKey', "TTTSPACESHIP");

        await axios({
          method: 'post',
          url: `${Configs.TTT_Game_Service}/api/upload/document3`,
          headers: {

            'X-TTT': `${Configs.API_TTT}`,
            'Content-Type': 'application/octet-stream',
          },
          data: dataImg,
        })
          .then(async function (response) {
            if (response.data.status == true) {
              response.data.data.forEach((e) => {

                newFile[e.on].name = e.name
                newFile[e.on].path = e.path
                if ( newFile[e.on].version  === undefined) {
                  newFile[e.on].version = 1
                }   
             

                 const tmp = {
                   name: e.name,
                   path:e.path,
                   no: e.no,
                   remark:e.remark,
                   version:1
 
                 }
                ole_file.push(newFile[e.on]);
              })
            }

          })
          .catch(function (error) {
            console.log(error);
          });
      } */
    }
    console.log(datafile,newFile,ole_file);
    ole_file.push(...newFile)
    ole_file.sort((a, b) => parseFloat(a.no) - parseFloat(b.no))
    
    let setfileSS = [], count = 1
    for (let i of ole_file) {
      let check = []
      if (setfileSS.length > 0) {
        check = setfileSS.filter((e) => e.no === i.no)
        console.log('s',setfileSS,check,i);
      }
      if (check.length === 0) {
        let cut = ole_file.filter((e) => e.no === i.no)
        for (let c of cut) {
          delete c.subno;
          delete c.file;

        }
        setfileSS.push({
          no: count,
          data: cut,
          version: cut.length

        })
        count += 1
      }



    }
    console.log(setfileSS);

    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/process/saveuploadfileScreenshot`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      }, data: {
        project_id: project_id,
        process_id: process_id,
        menu_id: menu_id,
        file: { data: setfileSS },
        remove:uploadfileScreenshot.remove,
        Configs: Configs.TTT_Game_Service_IMG

      }

    })
      .then(async function (response) {

         if (response.data) {
          console.log(response.data,ole_file.filter((e) => e.byid !== undefined && e.byid == true));
          let datamap = response.data.data.map((ex)=> {
            let i = newFile.findIndex((e) => ex.nosub == e.no)
            console.log(i,newFile);
            if (i !== -1) {
              ex.files =  newFile[i].files
              return ex
            }
            })
          CheckAndCreateFolderBox(datamap,ole_file.filter((e) => e.byid !== undefined && e.byid == true),response.data.remove)
          /* setuploadfileScreenshot({ ...uploadfileScreenshot, row: [], size: 0,remove:[]})
          callBackScreenshot()
          toggle_modal_status(); */
         }
      })
      .catch(function (error) {

        console.log(error);


      });


  }

  async function CheckAndCreateFolderBox(data,olddata,remove) {
    if (remove.length > 0) {
      for(let i of remove){
        await DelFiles(i)
        }
    }
   
    let temp = {
        folderLis: ["PROJECT",`PROJECT_${project_id}`,"MOCK UP","MODULE",`MODULE_${processStage.module_id}`,'MENU',`MENU_${processStage.menu_id}`]
    }
   /*  temp.folderLis.push(folder_name) */
    await axios({
        method: 'post',
        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          "X-TTT": `${Configs.API_TTT}`,
          "Content-Type": "application/json",
        },
        data: temp
    })
        .then(async function (response) {
            if (response.data) {
              let old = olddata.map(item => `'${item.id}'`).join(',');
              let count = 1
              console.log(data);
              for(let i of data){
               await UploadFiles(i, response.data.folder_id,old,(result) => {
               i.path = result
               delete i.files
            });
               old+=`'${i.id}'${data.length === count ? '': ','}`
               count+=1
              }
              console.log(data);
              updatasceenshot(data)

         
            }
        })
        .catch(function (error) {
           
            console.log(error);
        });
}



async function UploadFiles(item, folder_id,olddata,callBack) {
  console.log(item);
  const dataImg = new FormData();
  dataImg.append('File', item.files);
  dataImg.append('folder_id', folder_id);
  dataImg.append('key', "SCREENSHOT");
  dataImg.append('image_old_list', JSON.stringify(olddata));
  dataImg.append('ref_id', item.id);

  await axios({
      method: 'post',
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "multipart/form-data",
      },
      data: dataImg
  })
      .then(async function (response) {
          if (response.data) {
            if (response.data.image_id_list.length > 0) {
              callBack(response.data.image_id_list[0])
            }
           
          }
      })
      .catch(function (error) {
        
          console.log(error);
      });
}
async function DelFiles(item) {
  console.log(item);
  await axios({
      method: 'post',
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/DelFiles`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "multipart/form-data",
      },
      data: item
  })
      .then(async function (response) {
          if (response.data) {
           
           
          }
      })
      .catch(function (error) {
        
          console.log(error);
      });
}

  function getScreenshot() {
  
    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/process/getScreenshot`,
      headers: {
        /*     Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        project_id: project_id,
        process_id: process_id,
        menu_id: menu_id,
      },
    })
      .then(async function (response) {
/* for (let index of response.data.data) {

    const response2 = await axios.get(`https://api.box.com/2.0/files/${index.data[index.data.length-1].path}/content`, {
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      },
      responseType: 'blob',
  });
  index.data[index.data.length-1].box_path = URL.createObjectURL(response2.data)
  


} */
        
        setAlertKick(false)
        setuploadfileScreenshot({ ...uploadfileScreenshot, row: response.data.data })
       

      })
      .catch(function (error) {
        console.log(error);
      });
  }

 async function updatasceenshot(data) {
 
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/process/updatasceenshot`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      }, data: {
        project_id: project_id,
        process_id: process_id,
        menu_id: menu_id,
        dataupdate:data
      }
    })
      .then(async function (response) {
      if (response.data) {
        setAlertKick(false)
        setErrorMessage({ ...errorMessage, message: 'Success', status: false });
        setuploadfileScreenshot({ ...uploadfileScreenshot, row: [], size: 0,remove:[]})
        callBackScreenshot()
        toggle_modal_status();
      }
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  return (
    <>
      <ConfigProvider
        theme={{
          token: {

            colorInfo: '#85BFFF',
            colorBorder: '#85BFFF',
            colorText: '#FFF',

            colorTextDescription: '#000',
            colorBgContainer: '#000',

            zIndexPopupBase: 999999,
            colorPrimaryBg: 'blue',
            colorBgBase: '#778899',

            colorTextQuaternary: '#FFFFFF',


            colorFillTertiary: '#000',


            colorTextBase: 'blue',




            colorPrimaryBg: '#85BFFF',
            /*       
                   colorBgLayout:'red',
                   colorFillQuaternary:'red', */


          }
        }}
      >
        <div className='stageElementBg' style={{ display: `${isOpen ? 'flex' : 'none'}`, zIndex: '10000' }}>
          <div className='stageElementBgClose' onClick={() => {
         setuploadfileScreenshot({ ...uploadfileScreenshot, row: [], size: 0,remove:[]})
         uploadfileScreenshot.row = []
         uploadfileScreenshot.size = 0
         uploadfileScreenshot.remove = []
            toggle_modal_status();
          }}></div>
          <div className='stageElementBoxShadow' >
            <div className='stageElementBox'>
              <div className='stageElementBoxIn'>

                <div className='stageElementHeader'>
                <input
                    ref={fileInputRefall}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    multiple
                    onChange={(event) => {
                      let files = event.target.files;
                for (let file of files) {
                  let listall = uploadfileScreenshot.row
                          let count = 0
                          if (uploadfileScreenshot.row.length > 0) {
                            let num = 1
                            for (let i of uploadfileScreenshot.row) {
                              i.no = num
                              num+=1
                              
                            }
                            let cut = uploadfileScreenshot.row.sort((b, a) => parseFloat(a.no) - parseFloat(b.no))
                           /*  console.log(cut); */
                            count = cut[0].no + 1
                          } else {
                            count = 1
                          }


                          listall.push({
                            no: count,
                            data: [
                              {

                                file: file,
                                remark: '',
                                name: file.name,
                                path: `${URL.createObjectURL(file)}`,
                                version: 1


                              }
                            ],
                            version: 1
                          })

                          uploadfileScreenshot.row.sort((a, b) => parseFloat(a.no) - parseFloat(b.no))

                          setuploadfileScreenshot({ ...uploadfileScreenshot, row: listall })
                  
                }
                    }}
                  />
                  <div style={{ position: 'absolute', top: '36px', right: '25px' }}>
                  <button  disabled={displayMode == true || displayMode == 'true'} className=' btn-outline-success mr-2' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px' }} onClick={() => {fileInputRefall.current.click()}}> Add </button>
                    <button disabled = {displayMode == true || displayMode == 'true'} className=' btn-outline-primary mr-2' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px' }} onClick={() => {saveupload();}}> Save </button>
                    <button className=' btn-outline-danger' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px' }} onClick={() => {

                      setuploadfileScreenshot({ ...uploadfileScreenshot, row: [], size: 0,remove:[]})
                      uploadfileScreenshot.row = []
                      uploadfileScreenshot.size = 0
                      uploadfileScreenshot.remove = []
                      toggle_modal_status();
                    }}> Cancel </button>
                  </div>


                  <div className='stageElementHeaderLeft' style={{ marginTop: '10px' }}  /* scrollLeft={scrollPosition} */>
                    <div className='stageElementHeaderSrs'>Screenshot</div>
                    <div className='stageElementHeaderLine' style={{ marginTop: '16px', width: '110%' }}></div>
                    <div className='stageElementHeaderMenu' style={{ marginTop: '-32px' }}>{processStage.module_name} {'>'} {processStage.menu_name}</div>
                  </div>

                </div>
                <div className='row' id="scrollableindiv" style={{ height: '89%', display: 'flex', justifyContent: 'center', overflowX: 'hidden', overflowY: 'auto', marginTop: '20px', marginLeft: `${uploadfileScreenshot.size}px` }} >
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    disabled = {displayMode == true || displayMode == 'true'}
                    onChange={(event) => {
                      let file = event.target.files[0];
                      let listall = [...uploadfileScreenshot.row];
               
                      let listdata = listall[uploadfileScreenshot.on].data[listall[uploadfileScreenshot.on].version - 1]
                      console.log(listdata);
                      if (listdata.byid !== undefined && listdata.byid == true) {
                   
                        let countversion = listall[uploadfileScreenshot.on].version + 1
                   
                        listall[uploadfileScreenshot.on].data.push(
                          {
                            file: file,
                            remark: listdata.remark,
                            name: file.name,
                            path: `${URL.createObjectURL(file)}`,
                            version: countversion
                          }
                        )
                        listall[uploadfileScreenshot.on].version = countversion
                      }else{
                     
                            listdata.name = file.name
                            listdata.file = file
                            listdata.path = `${URL.createObjectURL(file)}`
                            listall[uploadfileScreenshot.on].data[listall[uploadfileScreenshot.on].version - 1] = listdata

                      }

                /*       console.log(listall); */
                      setuploadfileScreenshot({ ...uploadfileScreenshot, row: listall })

                    }}
                  />

                  {uploadfileScreenshot.row.length > 0 ? <>
                    <div style={{ height: '100%', width: '70%'/* ,marginTop:'2%' */, display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start'/* ,marginLeft:'10%' */ }}
                    >
                      {uploadfileScreenshot.row.map((allitem, index) => {



                        let item = allitem.data[allitem.version - 1]
                        console.log('item',item);

                        return (<>
                          <div
                            /*   className='col-6' */
                            key={allitem.no}
                            draggable
                            onDragStart={(e) => { 
                              if(displayMode == false || displayMode == 'false' ){
                                handleDragStart(e, index)  
                              }}}
                            onDragOver={(e) => { 
                              if(displayMode == false || displayMode == 'false' ){
                                handleDragOver(e)  
                              }}}
                            onDrop={(e) => {
                              if(displayMode == false || displayMode == 'false'){
                                handleDrop(e, index) 
                              }}}
                            style={{
                              padding: '15px',
                              width: '480px',
                              height: '330px',
                              /*   border: '1px solid #0047FF', */
                              cursor: 'move',
                              background: dragging ? '#0023' : '#0000',

                              position: 'relative'
                            }}
                          >
                            <div style={{

                              border: '1px solid #0047FF',
                              height: '100%'
                            }}
                            >
                         {/*    <div class="animated-background"  style={{ height: '70%', width: '100%', cursor: 'pointer', objectFit: 'contain' }}>
    <div class="background-masker btn-divide-left"></div>
  </div> */}              <div 
                            onClick={()=>{
                              setOpenPopup(true);
                              setDataPopup(allitem);
                            }}
                             style={{ height: '70%', width: '100%', border: '1px solid #0047FF' }}>
                          <Getimg
                          img={item.box_path? item.box_path : item.pathbox? item.pathbox : item.path}
                          isnew={(item.byid !== undefined && item.byid === true) && !item.box_path? false : true}
                          style={{ height: '100%', width: '100%', cursor: 'pointer', objectFit: 'contain' }}
                          callback={(result) => {
                            allitem.data[allitem.version - 1].box_path = result
                            allitem.loading = true
                          }} 
                          />
                          </div>
                             
                              <div style={{ background: '#000000', height: '30%', width: '100%', border: '1px solid #0047FF' }}>
                                <div style={{ background: '#0047FF', height: '75%', width: '15%', /* border: '1px solid #0047FF', */margin: '10px', textAlign: 'center', fontSize: '30px' }}>
                                  {index + 1}
                                  <div style={{ background: '#00289F', height: '7%', width: '11%', border: '1px solid #ffffff', textAlign: 'center', fontSize: '14px', position: 'absolute', margin: '6px', marginTop: '-8px', borderRadius: '8px' }}>
                                    {`VER.${allitem.version}`}
                                  </div>
                                </div>
                                <textarea
                                  value={item.remark}
                                  disabled={displayMode == true || displayMode == 'true'}
                                  onChange={(e) => handleTextareaChange(e, index)}
                                  style={{ width: '73%', height: '19%',color:'#000', marginLeft: '18%', position: 'absolute', marginTop: '-15.5%', borderRadius: '5px' }}
                                />


                              </div>
                            {/*   {item.id !== undefined ? <>
                                <div style={{ cursor: 'pointer', position: 'absolute', height: '30px', width: '30px', background: 'blue', textAlign: 'center', borderRadius: '5px', marginLeft: '78.5%', top: '20px' }}
                                  onClick={(e) => {

                                    setuploadfileScreenshot({ ...uploadfileScreenshot, on: index })
                                    fileInputRef.current.click()
                                  }}>
                                  <i class='fas fa-sync-alt ' style={{ marginLeft: '0px', marginTop: '7px' }}  ></i>
                                </div>

                              </> :

                                <>
                                  <div style={{ cursor: 'pointer', position: 'absolute', height: '30px', width: '30px', background: 'blue', textAlign: 'center', borderRadius: '5px', marginLeft: '78.5%', top: '20px' }}
                                    onClick={(e) => {

                                      setuploadfileScreenshot({ ...uploadfileScreenshot, on: index })
                                      fileInputRef.current.click()
                                    }}>
                                    <i class='fas fa-sync-alt ' style={{ marginLeft: '0px', marginTop: '7px' }}  ></i>
                                  </div>

                                </>} */}
                                   <div style={{ cursor: `${displayMode == false || displayMode == 'false' ? 'pointer' : 'no-drop'}`, position: 'absolute', height: '30px', width: '30px', background: 'blue', textAlign: 'center', borderRadius: '5px', marginLeft: '78.5%', top: '20px' }}
                                  onClick={(e) => {
                                    if(displayMode == false || displayMode == 'false'){
                                      setuploadfileScreenshot({ ...uploadfileScreenshot, on: index })
                                      fileInputRef.current.click()
                                    }
                                    
                                  }}>
                                  <i class='fas fa-sync-alt ' style={{ marginLeft: '0px', marginTop: '7px' }}  ></i>
                                </div>

                              <div style={{ cursor: `${displayMode == false || displayMode == 'false' ? 'pointer' : 'no-drop'}`, position: 'absolute', height: '30px', width: '30px', background: 'linear-gradient(to bottom right, rgba(255,0,0, 1), rgba(178,0,0, 1))', textAlign: 'center', borderRadius: '5px', marginLeft: '86%', top: '22px', }}
                                onClick={(e) => { 
                                  if(displayMode == false || displayMode == 'false'){
                                    handleDelete(index)
                                  }
                                   }}>
                                <i class='fa fa-trash' style={{ marginLeft: '-2px', marginTop: '5px' }}  ></i>
                              </div>
                            </div>
                          </div>

                          </>)
                      }
                      )
                      }
                      <div
                        /* className='col-6' */

                        style={{
                          padding: '15px',
                          width: '480px',
                          height: '330px',
                          position: 'relative'
                        }}
                      >
                        <Dragger  {...props} beforeUpload={(file, fileList) => {

                          let listall = uploadfileScreenshot.row
                          let count = 0
                          if (uploadfileScreenshot.row.length > 0) {
                            let num = 1
                            for (let i of uploadfileScreenshot.row) {
                              i.no = num
                              num+=1
                              
                            }
                            let cut = uploadfileScreenshot.row.sort((b, a) => parseFloat(a.no) - parseFloat(b.no))
                           /*  console.log(cut); */
                            count = cut[0].no + 1
                          } else {
                            count = 1
                          }


                          listall.push({
                            no: count,
                            data: [
                              {

                                file: file,
                                remark: '',
                                name: file.name,
                                path: `${URL.createObjectURL(file)}`,
                                version: 1


                              }
                            ],
                            version: 1
                          })

                          uploadfileScreenshot.row.sort((a, b) => parseFloat(a.no) - parseFloat(b.no))

                          setuploadfileScreenshot({ ...uploadfileScreenshot, row: listall })
                          return false;
                        }}>
                          <p className="ant-upload-drag-icon">

                            <img src={uploadicon} style={{ width: '10%' }} />
                          </p>
                          <p className="ant-upload-text" style={{
                            color: '#FFF',
                            fontFamily: 'Oxanium',
                            fontSize: '30px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: 'normal',
                          }} >Upload Screenshot</p>
                          {/*  <p className="ant-upload-text" style={{
  color: '#FFF',
  fontFamily: 'Oxanium',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
}} >OR</p>
<p className="ant-upload-text" style={{
  color: '#FFF',
  fontFamily: 'Oxanium',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
  fill: 'linear-gradient(90deg, #051F6C 0%, #16285C 78.79%, #222E51 162.66%)',
  strokeWidth: '2px',
  stroke: '#7BD7FF',
  filter: 'drop-shadow(0px 0px 20px #7BD7FF)',

}} >BROWSE FILES</p> */}


                        </Dragger>

                      </div>
                    </div>
                  </>
                    :
                    <>
                      <div className='col-5' style={{ height: '50%', marginTop: '10%' }}>
                        <Dragger {...props} beforeUpload={(file, fileList) => {


                          let listall = uploadfileScreenshot.row
                          let count = 0
                          if (uploadfileScreenshot.row.length > 0) {
                            let num = 1
                            for (let i of uploadfileScreenshot.row) {
                              i.no = num
                              num+=1
                              
                            }
                            let cut = uploadfileScreenshot.row.sort((b, a) => parseFloat(a.no) - parseFloat(b.no))
                            count = cut[0].no + 1
                          } else {
                            count = 1
                          }

                          listall.push({
                            no: count,
                            data: [
                              {

                                file: file,
                                remark: '',
                                name: file.name,
                                path: `${URL.createObjectURL(file)}`,
                                version: 1

                              }
                            ],
                            version: 1
                          })


                          uploadfileScreenshot.row.sort((a, b) => parseFloat(a.no) - parseFloat(b.no))
                          setuploadfileScreenshot({ ...uploadfileScreenshot, row: listall })
                          return false;
                        }}>
                          <p className="ant-upload-drag-icon">

                            <img src={uploadicon} style={{ width: '10%' }} />
                          </p>
                          <p className="ant-upload-text" style={{
                            color: '#FFF',
                            fontFamily: 'Oxanium',
                            fontSize: '30px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: 'normal',
                          }} >Upload Screenshot</p>
                          {/*  <p className="ant-upload-text" style={{
                      color: '#FFF',
                      fontFamily: 'Oxanium',
                      fontSize: '30px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: 'normal',
                    }} >OR</p>
                    <p className="ant-upload-text" style={{
                      color: '#FFF',
                      fontFamily: 'Oxanium',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: 'normal',
                      fill: 'linear-gradient(90deg, #051F6C 0%, #16285C 78.79%, #222E51 162.66%)',
                      strokeWidth: '2px',
                      stroke: '#7BD7FF',
                      filter: 'drop-shadow(0px 0px 20px #7BD7FF)',

                    }} >BROWSE FILES</p> */}


                        </Dragger>



                      </div></>}

                  {/*  <div className='stageElementHeaderRight'>
                                    <div className ='stageElementHeaderSaveButton' onClick ={saveProcessStage}>SAVE CHANGE</div>
                                </div> */}


                </div>





              </div>
            </div>
          </div>
        </div>

        <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
        <div className='alertKickBoxShadow' >
          <div className='alertKickBox' style={{ height: 'fit-content' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `${errorMessage.status ? 'ffffff' : 'green'}` }}>
                  {errorMessage.status ? 'Loading' : 'SUCCESS'}
                </div>
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning' style={{ border: 'none' }}>
                  {errorMessage.status ? <><div class="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div></> : <></>}
                </div>
              </div>
              {errorMessage.status ? <></> : <>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    {errorMessage.message}

                  </div>
                </div>


                <div className='row alertKickBoxButton'>
                  <button className='alertKickButton' onClick={() => {
                    toggle_modal_alertKick()
                  }}>OK</button>

                </div>
              </>}


            </div>
          </div>
        </div>
      </div>
      {openPopup ? <Popup  data_element_screenshot_version={dataPopup} closePopup={closePopup} isopen={openPopup} /> : null}
      </ConfigProvider>
    </>
  )
}

export default Screenshot
