import React, { useState, useEffect } from 'react'
import './index.css';
import Configs from '../../../../config';
import axios from 'axios';
import {
  CloseOutlined,
  CalendarOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import { Row, Col, DatePicker, Input, Table, ConfigProvider,Switch } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { TextArea } = Input;






const TimeSheetModal = ({ toggle_modal_time_sheet,
  project_id,
  project_name,
  process_id,
  module_id,
  process_name,
  module_name,
  menu_id,
  menu_name }) => {
    const [typemodeselect,setTypemodeselect] = useState(false);
    const [timesheetmodeselect, settimesheetmodeselect] = useState({
        mode:'day'
    });
  const [filterDataType, setFilterDateType] = useState('today');
  const [timeSheetData, setTimeSheetData] = useState({
    project_id: '',
    project_name: '',
    module_id: '',
    module_name: '',
    menu_id: '',
    menu_name: '',
    date_start: new Date(),
    date_end: new Date(),
    time_start: '',
    time_end: '',
    description: '',
    count_hour: 0,
    count_minute: 0,
    dateendselect: null,
    datestartselect: null,
  })
  const [todayUse, setTodayUse] = useState({
    hours: 0,
    minute: 0
  });


  const [err_message, setErrorMessage] = useState('');
  const [err_message2, setErrorMessage2] = useState('');



  useEffect(() => {
    getCountTodayTimeSheet();
    /*    setTimeSheetData({...timeSheetData,
        project_id:project_id

       }) */
  }, [])


  function saveTimeSheet() {
    let temp = {
      project_id: project_id,
      project_name: project_name,
      menu_id: menu_id,
      process_id: process_id,
      date_start: timeSheetData.date_start,
      date_end: timeSheetData.date_end,
      count_hour: timeSheetData.count_hour,
      count_minute: timeSheetData.count_minute,
      description: timeSheetData.description.trim() || '',
      mode: timesheetmodeselect.mode,
      date_selectstart: timeSheetData.datestartselect,
      date_selectend: timeSheetData.dateendselect,

    }
    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/timeSheet/addTimeSheet`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        setTimeSheetData({
          ...timeSheetData,
          project_id: '',
          project_name: '',
          ts_process_id: '',
          process_name: '',
          module_id: '',
          module_name: '',
          menu_id: '',
          menu_name: '',
          date_start: new Date(),
          date_end: new Date(),
          time_start: '',
          time_end: '',
          ts_project_detail: '',
          count_hour: 0,
          count_minute: 0,
          dateendselect: null,
          datestartselect: null,
      });
      settimesheetmodeselect({
          ...timesheetmodeselect,
          mode:'day'
      });
      setTypemodeselect(false)
        toggle_modal_time_sheet();
      })
      .catch(function (error) {
        console.log(error);

        setErrorMessage(error.response.data.error[0].errorDis)
      });
  }

  function getCountTodayTimeSheet() {

    axios({
      method: "get",
      url: `${Configs.TTT_Game_Service}/api/timeSheet/getCountTodayTimeSheet/${sessionStorage.getItem('user_id')}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },

    })
      .then(async function (response) {
        setTodayUse({ ...todayUse, hours: response.data.hours, minute: response.data.minute })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <div className='ModalTimesheet' /* style={{ display: 'block' }} */>
        <ConfigProvider
          theme={{
            token: {

              colorInfo: 'red',
              colorBorder: '#0047FF',
              colorText: '#FFF',

              colorTextDescription: '#FFFFFF',
              colorBgContainer: '#001D5F',
              zIndexPopupBase: 999999,
              colorPrimaryBg: '#FFFFFF',
              colorBgBase: '#001D5F',//พื้นหลัง datepicker

              colorTextQuaternary: 'White',//สีตัวอักษร

              colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

              colorTextBase: 'White', // สี scroll bar

              colorPrimaryBg: '#0047FF',// สีตอนเลือก
              /*       
                     colorBgLayout:'red',
                     colorFillQuaternary:'red', */


            }
          }}
        >

          <div className='headerTimeSheet'>
            <div className='headerTimeSheetBg'></div>
            <div className='headerTimeSheetText' style={{ fontFamily:  'Oxanium' }} >
              Time sheet
            </div>
            {timesheetmodeselect.mode === 'day' ? <>
              <div className='headerTimeSheetTimeCal'>
              Today : <font color="#0047FF">{todayUse.hours}</font> hr <font color="#0047FF">{todayUse.minute}</font> min  {"     "}  <CloseOutlined onClick={() => { 
                 setTimeSheetData({
                  ...timeSheetData,
                  project_id: '',
                  project_name: '',
                  ts_process_id: '',
                  process_name: '',
                  module_id: '',
                  module_name: '',
                  menu_id: '',
                  menu_name: '',
                  date_start: new Date(),
                  date_end: new Date(),
                  time_start: '',
                  time_end: '',
                  ts_project_detail: '',
                  count_hour: 0,
                  count_minute: 0,
                  dateendselect: null,
                  datestartselect: null,
              });
              settimesheetmodeselect({
                  ...timesheetmodeselect,
                  mode:'day'
              });
              setTypemodeselect(false)
              toggle_modal_time_sheet(); }} style={{ cursor: 'pointer', marginLeft: '5px' }} />
            </div></> : <>
            <CloseOutlined onClick={() => { toggle_modal_time_sheet(); }} style={{ cursor: 'pointer', marginLeft: '5px' }} /></> }
           
          </div>
          <div className='bodyTimeSheet'>
            <Row style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            {timesheetmodeselect.mode === 'day' ? 
                        <>
                          <CalendarOutlined style={{ fontSize: '20px' }}

/>
<RangePicker id='dateRange' style={{ width: '220px', height: '32px' }} className='inputTimeSheet2' allowClear={false}
  format={'DD/MM/YYYY'}
  value={[timeSheetData.date_start ? dayjs(timeSheetData.date_start) : '', timeSheetData.date_end ? dayjs(timeSheetData.date_end) : '']}
  onChange={(date) => {
    if (date && date.length == 2) {
      setFilterDateType(null);
      setTimeSheetData({ ...timeSheetData, date_start: new Date(date[0]), date_end: new Date(date[1]) })
      /*      changeMenuValue('menu_start_stop_date', date, menu.menu_id); */

    } else {
      //console.log('date', date)
      setTimeSheetData({ ...timeSheetData, date_start: null, date_end: null })
    }
  }}
/>
<div className={filterDataType == 'today' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
  onClick={() => {
    setFilterDateType('today');
    setTimeSheetData({ ...timeSheetData, date_start: new Date(), date_end: new Date() });

  }}
>
  Today
</div>
<div className={filterDataType == 'this_week' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
  onClick={() => {
    setFilterDateType('this_week');
    let start = moment(new Date()).startOf('weeks').add(1, 'days');
    let end = moment(new Date()).endOf('weeks').add(-1, 'days');
    setTimeSheetData({ ...timeSheetData, date_start: start, date_end: end });
  }}
>
  This Week
</div>
<div className={filterDataType == 'last_week' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
  onClick={() => {
    setFilterDateType('last_week')
    let start = moment(new Date()).startOf('weeks').add(1, 'days').add(-1, 'weeks');
    let end = moment(new Date()).endOf('weeks').add(-1, 'days').add(-1, 'weeks');
    setTimeSheetData({ ...timeSheetData, date_start: start, date_end: end });
  }}
>
  Last Week
</div>
                        
                        </>


                        :
                        <></>
}
             
            </Row>
            <ConfigProvider
                            theme={{
                                token: {

                                    colorInfo: 'red',
                                    colorBorder: '#0047FF',
                                    colorText: '#FFF',

                                    colorTextDescription: '#FFFFFF',
                                    colorBgContainer: '#0A2268',
                                    borderRadius: '2px',
                                    colorBorder: '#0047FF !imporstant',
                                    zIndexPopupBase: 999,
                                    colorPrimaryBg: '#FFFFFF',
                                    colorBgBase: '#001D5F',//พื้นหลัง datepicker

                                    colorTextQuaternary: '#D4D4D4',//สีตัวอักษร

                                    colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                                    colorTextBase: 'White', // สี scroll bar

                                    colorPrimaryBg: '#0047FF',// สีตอนเลือก

                                }
                            }}
                        >
                       <Row style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>

                        Today
<Switch checked={typemodeselect} onChange={(value)=>{
    settimesheetmodeselect({
        ...timesheetmodeselect,mode:value === true ? 'across' : 'day'
    })
    setTimeSheetData({ ...timeSheetData,  
        project_id: '',
        project_name: '',
        ts_process_id: '',
        process_name: '',
        module_id: '',
        module_name: '',
        menu_id: '',
        menu_name: '',  
        date_start: new Date(),
        date_end: new Date(),
        time_start: '',
        time_end: '',
        ts_project_detail: '',
        count_hour: 0,
        count_minute: 0,
        datestartselect:null,
        dateendselect:null,});

    setTypemodeselect(value)
}} />
Across day

 
</Row>



</ConfigProvider>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>

            {timesheetmodeselect.mode === 'day' ? 
<> <ClockCircleOutlined style={{ fontSize: '20px' }} />
              <RangePicker style={{ width: '220px', height: '32px' }} className='inputTimeSheet2'
                format={'HH:mm'}
                value={[timeSheetData.time_start ? dayjs(timeSheetData.time_start) : '', timeSheetData.time_end ? dayjs(timeSheetData.time_end) : '']}
                onChange={(date) => {
                  //console.log('date',date)
                  if (date && date.length == 2) {

                    let minute_all = moment(new Date(date[1])).diff(moment(new Date(date[0])), 'minutes');
                    let hours = Math.floor(minute_all / 60);
                    let minute = minute_all - (Math.floor(minute_all / 60)) * 60;
                    //console.log(hours,minute)
                    setTimeSheetData({ ...timeSheetData, count_hour: hours, count_minute: minute, time_start: new Date(date[0]), time_end: new Date(date[1]) });


                  }
                }}
                allowClear={false}
                picker="time"
              />

              OR
              <Input style={{ width: '35px', padding: 0, height: '32px', textAlign: 'center' }} className='inputTimeSheet2'
                value={timeSheetData.count_hour}
                onChange={(e) => {

                  if ((!isNaN(e.target.value) && parseInt(e.target.value) <= 23) || e.target.value == '') {
                    setTimeSheetData({ ...timeSheetData, count_hour: e.target.value.trim(), time_start: null, time_end: null });
                    setErrorMessage('');

                  }
                }}
              />
              hrs.
              <Input style={{ width: '35px', padding: 0, height: '32px', textAlign: 'center' }} className='inputTimeSheet2'
                value={timeSheetData.count_minute}
                onChange={(e) => {
                  if ((!isNaN(e.target.value) && parseInt(e.target.value) <= 59) || e.target.value == '') {
                    setTimeSheetData({ ...timeSheetData, count_minute: e.target.value.trim(), time_start: null, time_end: null });
                    setErrorMessage('');
                  }
                }}
              />
              min


</>

:


<>
<ClockCircleOutlined style={{ fontSize: '25px' }} />
<DatePicker
style={{ border: `1px solid ${ 'blue'}` }}
        showTime
        allowClear={false}
        format="DD/MM/YYYY HH:mm"
        placeholder="Select Date"
        value={timeSheetData.datestartselect}
        onChange={(date) => {
console.log(date);

           if (date) {
            let dateselect = timeSheetData.dateendselect === null ? dayjs(date).add(1, 'day') : dayjs(date).add(1, 'day').set('hour', timeSheetData.dateendselect.hour()).set('minute', timeSheetData.dateendselect.minute())
            console.log(dateselect,timesheetmodeselect.mode);
            
            setTimeSheetData({ ...timeSheetData, datestartselect: dayjs(date), dateendselect: dayjs(dateselect) });
          
           }
          }}
      />

<DatePicker 
style={{ border: `1px solid ${ 'blue'}` }}
showTime={{ 
    format: 'HH:mm', 
   
  }}
  disabledDate={(current) => {
    return current && current.format('YYYY-MM-DD') !== dayjs(timeSheetData.dateendselect).format('YYYY-MM-DD');
  }}
 /*  disabledTime={() => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  })} */
/* picker="time" */
        allowClear={false}
        format="DD/MM/YYYY HH:mm"
        placeholder="Select Time"
        disabled={timeSheetData.dateendselect? false : true}
        value={timeSheetData.dateendselect}
        onChange={(date) => {
            console.log(date);
            setTimeSheetData({ ...timeSheetData, dateendselect: dayjs(date) });
            setErrorMessage('');
            
                      }}
      />
                        {/*     <RangePicker style={{ width: '70%', height: '32px' }} className='inputTimeSheet2'
                         format="DD/MM/YYYY HH:mm"
                                value={[timeSheetData.datestartselect ? dayjs(timeSheetData.datestartselect) : '', timeSheetData.dateendselect ? dayjs(timeSheetData.dateendselect) : '']}
                                onChange={(date) => {
                                   console.log('date',date)
                                    if (date && date.length == 2) {

let s = date[0].second(0).diff(timeSheetData.datestartselect.second(0), 'day');
let e = date[1].second(0).diff(timeSheetData.dateendselect.second(0), 'day');

let ns =  dayjs(timeSheetData.datestartselect).add(s, 'day')
let ne =  dayjs(timeSheetData.dateendselect).add(e, 'day')
console.log(s,e);
                                

                                    }
                                }}
                               
                                allowClear={false}
                                showTime={{
                                    format: 'HH:mm',
                                
                                   
                                  }}
                            /> */}
</>}
             
            </Row>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Project : </div>
              <div className='value'>{project_name}</div>
            </Row>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Process : </div>
              <div className='value'>{process_name}</div>
            </Row>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Menu : </div>
              <div className='value'>{module_name} {`>`} {menu_name}</div>
            </Row>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Date : </div>
              <div className='value'>{timesheetmodeselect.mode === 'day' ? <>{dayjs(timeSheetData.date_start).format('DD/MM/YYYY')} - {dayjs(timeSheetData.date_end).format('DD/MM/YYYY')}</> : <>{timeSheetData.datestartselect ? <>{ dayjs(timeSheetData.datestartselect).format('DD/MM/YYYY')} - {dayjs(timeSheetData.dateendselect).format('DD/MM/YYYY')}</> : <></> }</>}</div>
            </Row>
            {timesheetmodeselect.mode === 'day' ? <>
              <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Time : </div>
              <div className='value'><font color="#0047FF">{timeSheetData.count_hour || 0}</font> hr <font color="#0047FF">{timeSheetData.count_minute || 0}</font> min</div>
            </Row></> : <></> }
          

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Description : </div>
              <div className='value'>

              </div>
            </Row>
            <Row>
              <TextArea className='inputTimeSheet' autoSize={{
                minRows: 2,

              }}
              style={{ maxHeight:'230px'}}
                value={timeSheetData.description}
                onChange={(e) => {
                  setErrorMessage2('');
                  setTimeSheetData({ ...timeSheetData, description: e.target.value });
                }}
              />
            </Row>

          </div>

          <div className='footerTimeSheet'>
            <div className='btnSave'
              onClick={() => {
                let error = 0;
if (timesheetmodeselect.mode === 'day') {
  if (timeSheetData.count_hour == 0 && timeSheetData.count_minute == 0) {
    setErrorMessage("Please enter effort in hours or minute!");
    error++;
  }
}else{
  if (timeSheetData.datestartselect == null && timeSheetData.dateendselect == null) {
    setErrorMessage("Please enter effort select Date!");
    error++;
  }
}
              
                if (timeSheetData.description.trim() == '') {
                  setErrorMessage2("Please enter description!");
                  error++;
                }
                if (error == 0) {
                  saveTimeSheet();
                }

                //toggle_modal_time_sheet();
              }}
            >Save</div>
            <div className='errorSave' style={{display: `${err_message != '' || err_message2 != '' ? 'flex': 'none'}`}}>
            <font color="red" style={{display: `${err_message != '' ? 'block': 'none'}`}}>{err_message}</font>
            <font color="red" style={{display: `${err_message2 != '' ? 'block': 'none'}`}}>{err_message2}</font>
            </div>
          </div>


        </ConfigProvider>
      </div>
    </>
  )
}

export default TimeSheetModal
