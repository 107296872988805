import React, { useEffect, useState, useRef } from 'react'
import './detail.css'
import axios from "axios";
import Configs from "../../../config";
import moment from 'moment';
import { io } from 'socket.io-client';
import ImgProfile from './Rectangle154.png'
import memberListIcon from '../../../assets/img/icon/Group11.png'
import rocket from '../../../assets/img/icon/Layer_2.png'

import SpaceshipWorkload from '../../../components/spaceshipWorkload';

const Detail = ({
    isOpen,
    mode,
    reload,
    toggle_modal_addMemberReload,
    spaceShipId,
    toggle_modal_addMember,
    callBack,
    setMemberSize,
    callBackspace,
    callbackHistory
}) => {
    const [test, setTest] = useState({ test: '' })
    const [detailData, setDetailData] = useState({
        status: false,
        statusName: '',
        desc: '',
        name: 'RAIZEROS',
        mvp: 449,
        rating: 89,
        success: 89,
        level: 210,
        // proCode: 'NX0001',
        projectName: 'PROJECT MONITORING',
        owner: 'sawaddee hahaha',
        budget: 10000000,
        detail: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis architecto eaque ratione? Ipsam dignissimos incidunt, excepturi consectetur animi delectus voluptates, magnam, amet cum nostrum maxime accusantium illo recusandae laborum earum!',
        periodStart: new Date(),
        periodEnd: new Date(),
        expire: new Date(),
        progress: 57,
        state: 'coding',
        member: 5,
        maxMember: 8,
        projectHistory: [],
        memberList: [
            /*             {
                            memberName: 'ahaha ahaha',
                            memberPosition: 'owner',
                            memberStatus: 'INVITED'
                        },
                        {
                            memberName: 'ahaha ahaha',
                            memberPosition: 'ux/ui design',
                            memberStatus: 'ACCEPT'
                        },
                        {
                            memberName: 'ahaha ahaha',
                            memberPosition: 'Developer manager',
                            memberStatus: 'REJECT'
                        },
                        {
                            memberName: 'ahaha ahaha',
                            memberPosition: 'Developer'
                        },
                        {
                            memberName: 'ahaha ahaha',
                            memberPosition: 'Developer'
                        },
                        {
                            memberName: 'ahaha ahaha',
                            memberPosition: 'Developer'
                        },
                        {
                            memberName: 'ahaha ahaha',
                            memberPosition: 'TESTER manager'
                        },
                        {
                            memberName: 'ahaha ahaha',
                            memberPosition: 'TESTER'
                        },
                        {
                            memberName: 'ahaha ahaha',
                            memberPosition: 'TESTER'
                        },
                        {
                            memberName: 'ahaha ahaha',
                            memberPosition: 'TESTER'
                        }, */
        ]
    })

    const [confirmBox, setConfirmBox] = useState(false);
    const toggle_modal_confirmBox = () => setConfirmBox(!confirmBox);
    const [memberDrop, setMemberDrop] = useState({
        member: ''
    })
    const [spaceshipTab, setSpaceshipTab] = useState([])
    const [spaceshipIsId, setSpaceshipIsId] = useState({
        id: ''
    })
    const [historyBox, setHistoryBox] = useState(false);
    const toggle_modal_historyBox = () => setHistoryBox(!historyBox);
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);

    const [spaceshipWorkloadIsOpen, setSpaceshipWorkloadIsOpen] = useState(false);
    const toggle_spaceshipWorkloadIsOpen = () => setSpaceshipWorkloadIsOpen(!spaceshipWorkloadIsOpen);

    async function GetAccesstokenToken(callback) {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                /* Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
              var temp = {
                tokenBox: response.data.tokenBox
              }
              if(callback){
                callback(response.data.tokenBox);
              }
              sessionStorage.setItem('tokenBox',response.data.tokenBox)
               /*  console.log('ViewToken', response.data); */
            })
            .catch(function (error) {
                console.log(error);
            });
    
    }
      
      async function getFileBoxByIdImage(image_id,callback){
        axios({
          method: "get",
          url: `https://api.box.com/2.0/files/${image_id}/content`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
        },
        responseType: 'blob',
    
        })
          .then(async function (response) {
    
            if(callback){
              let tmp ={
                fileImg: URL.createObjectURL(response.data),
                file_id:image_id,
        
              }
              callback(tmp);
            }
    
          }).catch((err)=>{
            if(callback){
                let tmp ={
                  fileImg:null,
                  file_id:null,
          
                }
                callback(tmp);
              }
          })
    
      }

      const [spaceshipProfile,setSpaceshipProfile] = useState({profile_img:null});

    async function getDataSpaceShip() {
        setSpaceshipProfile({...spaceshipProfile,profile_img:null});
        spaceshipProfile.profile_img = null;
        await axios({
            method: 'GET',
            url: `${Configs.TTT_Game_Service}/api/spaceship/getSpaceShipDetail/${spaceshipIsId.id}`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
        })
            .then(async function (response) {
                // console.log('testlog res',response.data);
                let temp = [];
                for (let item of response.data.member) {
                    temp.push({
                        memberId: item.su_id,
                        user_id: item.su_usr_id,
                        memberName: item.usr_name + ' ' + item.usr_lastname,
                        memberPosition: item.usr_role_name,
                        memberStatus: item.su_is_accept,
                        memberLeader: item.su_is_leader
                    })
                }
                let history = [];
                // console.log('testlog', response.data.history);
                for (let item of response.data.history) {
                    history.push({
                        projectId: item.project_id,
                        projectName: item.project_name,
                        projectBudget: item.project_budget,
                        projectProgress: item.project_progress,
                        projectStatus: item.pros_name,
                        projectStart: item.pp_start_date,
                        projectEnd: item.pp_finish_date
                    })
                }
                GetAccesstokenToken(async (result_)=>{
                if(response.data.sps_profile_id || response.data.sps_model_id){
                    let ref_id = response.data.sps_profile_id ? response.data.sps_profile_id : response.data.sps_model_id;

                     getFileBoxByIdImage(ref_id,(result)=>{
                        if(result){
                          
                    
                            setSpaceshipProfile({...spaceshipProfile,profile_img:result.fileImg});
                            spaceshipProfile.profile_img = result.fileImg;
                            
                                
                      
                              } 
                    
                         
              /*             setAllFileDataDetail({
                            ...allFileDataDetail,image_profile:result.fileImg,image_profile_id:result.file_id});
                          allFileDataDetail.image_profile = result.fileImg;
                          allFileDataDetail.image_profile_id = result.file_id; */
                        
                      })
                }
            });

                setDetailData({
                    ...detailData,
                    space_id: response.data.spaceship_id,
                    name: response.data.spaceship_name,
                    member: response.data.member.length,
                    maxMember: response.data.spaceship_size,
                    level: response.data.spaceship_level || 0,
                    status: response.data.spaceship_ss_id == '29d1c602-1858-40f9-8289-8ef8a9ab234d' || response.data.spaceship_ss_id == '6d93d367-508b-4d98-955a-10f1ee44ae52' || response.data.spaceship_ss_id == 'e4c72b30-3380-4d28-9d2f-7e4a2c7346a2' /* || response.data.spaceship_ss_id == 'c5bc1e42-e7fb-4f4e-92fe-fd37306950da' || response.data.spaceship_ss_id == '297268cd-d02f-438f-a50a-3b4c8a419571' */ ? false : true,
                    statusName: response.data.ss_name,
                    desc: response.data.spaceship_description || '',
                    memberList: temp,
                    budget: response.data.project_budget,
                    detail: response.data.roject_description || '',
                    periodStart: response.data.pp_start_date,
                    periodEnd: response.data.pp_finish_date,
                    progress: response.data.project_progress,
                    state: response.data.pros_name,
                    projectName: response.data.project_name,
                    projectHistory: history,
                    owner: response.data.frist_name + ' ' + response.data.last_name,
                    ul_file_path: response.data.ul_file_path,
                    expire:response.data.spaceship_expire_date
                })
                setMemberSize({ max: response.data.spaceship_size })
                callBack(response.data.member)
                callbackHistory(history)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getTabSpaceShip() {
        await axios({
            method: 'GET',
            url: `${Configs.TTT_Game_Service}/api/spaceship/getSpaceShipTab/${sessionStorage.getItem('user_id')}`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
        })
            .then(async function (response) {
                let temp = [];
                for (let item of response.data) {
                    temp.push(
                        {
                            value: item.spaceship_id,
                            label: item.spaceship_name
                        }
                    )
                }
                setSpaceshipTab(temp)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function nextArc() {
        console.log('testlog onclick icon next archievement', detailData);
    }

    function addMember() {
        toggle_modal_addMember()
    }

    function seeMore() {
        console.log('testlog onclick see more');
    }

    async function remove(value) {
        let temp = {
            id: value.memberId
        }
        await axios({
            method: 'POST',
            url: `${Configs.TTT_Game_Service}/api/spaceship/dropSpaceShipDetail`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp,
        })
            .then(async function (response) {
                addNotification(value.user_id, value.memberName)
                getDataSpaceShip()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function checkOwner() {
        if (detailData.memberList.length > 0) {
            const temp = detailData.memberList.filter((e) => {
                return e.memberLeader === true
            })
            if (temp[0].user_id === sessionStorage.getItem('user_id')) {
                return false
            } else {
                return true
            }
        }    
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        if (isOpen === true) {
            setSpaceshipIsId({
                id: spaceShipId
            })
            spaceshipIsId.id = spaceShipId
            getDataSpaceShip()
            getTabSpaceShip()

        }



    }, [isOpen, reload])



    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                //path: '/Socket-service/socket.io'
                port:Configs.TTT_Game_Socket_Service_Port
              });
        }
    }, [

    ])


    useEffect(() => {
        if (isOpen === true) {

            socketRef.current.emit('leaveOne', "SPACESHIP:" + spaceShipId)
            socketRef.current.emit('join', "SPACESHIP:" + spaceShipId)


        } else {
            socketRef.current.emit('leaveOne', 'SPACESHIP:' + spaceShipId)
            socketRef.current.emit('leaveOne', 'SPACESHIP:' + spaceShipId)
        }
    }, [isOpen])
    useEffect(() => {


        /*     if(getProjectId !== '' ){ */



        const { current: socket } = socketRef;
        const socFnc = (data) => {
            // console.log('onRoom', data)
            getDataSpaceShip();
            //getChatData(data.project_id);
            /*     socket.emit('leave',data.room) */
        }
        const socFnc2 = (data) => {
        }
        try {
            socket.open();
            //console.log('onMenu', 'เข้าEffectแล้ว', activeRoom.room, 'RISK93bf93f2-eb48-4bf7-b1bc-1305cdc13fad')
            /* socket.emit('join','RISK93bf93f2-eb48-4bf7-b1bc-1305cdc13fad') */


            socket.on('fecthMemberSpaceship', socFnc)
            socket.on('locationElement', socFnc2)
            /*     socket.on('online_update', socFnc) */



            /*  setActiveRoom({...activeRoom,room:'93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'});
                activeRoom.room = '93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'; */
        } catch (error) {
            console.log(error);
        }
        // Return a callback to be run before unmount-ing.

        return () => {
            /*   console.log('onRoom','out') */
            socket.off("chat", socFnc);

            socket.close();
            /*     setActiveRoom({ ...activeRoom, room: '' });
                activeRoom.room = ''; */
        };
        /* } */



    }, []);

    async function addNotification(user_id, user_name) {

        let tempNoti = {
            header: 'REMOVE YOU FROM',
            // body: `คุณถูกเชิญอออกจากยาน ${detailData.name}`,
            noti_menu_name: "",
            user_to_id: user_id,
            user_to_name: user_name,
            link_to: "",
            space_ship_id: detailData.space_id
        }
        await axios({
            method: 'POST',
            url: `${Configs.TTT_Game_Notification_Service}/api/notification/addNotification`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: tempNoti,
        })
            .then(async function (response) {
                socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + user_id });
                // setSendData([])
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async function kickMemberOnSpaceship(space_id, user_id) {

        let temp = {
            space_id: space_id,
            user_id: user_id
        }
        await axios({
            method: 'POST',
            url: `${Configs.TTT_Game_Service}/api/spaceship/kickMemberOnSpaceship`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {

                if (response.data) {
                    getDataSpaceShip();
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <div className='detalTabBox'>
                {spaceshipTab.map((e) => {
                    return (
                        <div className={e.value === spaceshipIsId.id ? 'detalTabSelect' : 'detalTab'} onClick={() => {
                            setSpaceshipIsId({
                                id: e.value
                            })
                            spaceshipIsId.id = e.value
                            getDataSpaceShip()
                            getTabSpaceShip()
                            callBackspace(e.value, true, e.label)
                        }}>
                            <div className='iconRocket' style={{ backgroundImage: `url(${rocket})` }}></div>
                            <span className='detalTabText'>{e.label}</span>
                        </div>
                    )
                })}
                <div className='detalTabAdd'>+</div>
            </div>

            <div className='row setRowPos' style={{ width: `${mode === '1' ? '1400px' : '100%'}` }}>
                <div className='col-6 setColPos'>
                    <div className='boxTab'>
                        <div className='iconEdit'>
                            <i class="fa fa-pen" aria-hidden="true"></i>
                        </div>

                        <div className='iconDesc'>
                            <i class="fa fa-info" aria-hidden="true"></i>
                            <span class="tooltip-text">Description : {detailData.desc}</span>
                        </div>


                    </div>
                </div>
                <div className='row col-6 setColPosRight' style={{marginTop: '-40px'}}>
                    <div className='detailState' style={{ border: '2px solid #5E9BE1', width: 'fit-content', padding: '0 15px', cursor: 'pointer', zIndex: 1 }} onClick={()=>{
                        toggle_spaceshipWorkloadIsOpen();
                    }}>Spaceship Workload</div>
                    <div className='detailState'>STATUS : {detailData.statusName}</div>
                    <div className='detailExpire'>EXPIRE DATE: {moment(detailData.expire).format('D MMM yyyy')}</div>
                </div>
            </div>

            <div className='box'>

                <div className='row' style={{ padding: 0, margin: '0 0 15px 0', height: '45%' }}>
                    <div className='col-3' style={{ padding: 0, margin: 0 }}>
                        {/* <div className='frameTop1Img' style={{ backgroundImage: `url(${ImgProfile})` }}></div> */}
                        <div className='frameTop1Img'>
                            <img /* className='frameTop1Img' */
                                src={`${
                                    spaceshipProfile.profile_img ? spaceshipProfile.profile_img : ImgProfile
                                   /*  detailData.ul_file_path !== null ? `${Configs.TTT_Game_Service_IMG}${detailData.ul_file_path}` : ImgProfile */}`}
                                style={{ objectFit: 'contain', width: '295px', height: '260px' }}
                            ></img>
                        </div>
                        {/* <img src={ImgProfile} style={{width: '90%', height: '100%'}}></img> */}
                    </div>
                    <div className='row col-3' style={{ padding: 0, margin: 0, height: '100%' }}>
                        <div className='col-12 frameTop2Head' style={{ padding: 0, margin: 0 }}>SPACESHIP DETAIL</div>
                        <div className='col-12 frameTop2Name' style={{ padding: 0, margin: 0 }}>
                            <div className='detailSetName'>{detailData.name}</div>
                        </div>
                        <div className='col-6' style={{ padding: 0, margin: 0, height: 'fit-content' }}>
                            <div className='frameTop2Mvp'>MVP: {detailData.mvp}</div>
                        </div>
                        <div className='col-6 frameTop2flex' style={{ padding: 0, margin: 0, height: 'fit-content' }}>
                            <div className='frameTop2Rat'>RATING: {detailData.rating}%</div>
                        </div>
                        <div className='col-6 ' style={{ padding: 0, margin: 0, height: '20px' }}>
                            <div className='frameTop2Line'></div>
                        </div>
                        <div className='col-6 frameTop2flex' style={{ padding: 0, margin: 0, height: '20px' }}>
                            <div className='frameTop2Line'></div>
                        </div>
                        <div className='col-12 frameTop2Ach' style={{ padding: 0, margin: '-15px 0 0 0' }}>ACHIEVEMENT</div>
                        <div className='col-10' style={{ padding: 0, margin: '-15px 0 0 0' }}>
                            <div className='frameTop2AchIcon'></div>
                        </div>
                        <div className='col-2 frameTop2flex detailIcon' style={{ padding: 0, margin: '-15px 0 0 0' }} onClick={() => {
                            nextArc()
                        }}>
                            <div className='frameTop2AchNext'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <path d="M13.5818 26.7044L22.2504 18.0358L13.5818 9.36719" stroke="white" stroke-width="2.39133" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='row col-2' style={{ padding: '60px 0 0 0', margin: 0, height: '100%' }}>
                        <div className='col-12 frameTop3Head' style={{ padding: 0, margin: '20px 0 0 0' }}>PROJECT CATEGORY</div>
                        <svg style={{ margin: '-10px 0 0 0' }} xmlns="http://www.w3.org/2000/svg" width="220" height="139" viewBox="0 0 220 139" fill="none">
                            <mask id="path-1-inside-1_412_11356" fill="white">
                                <path d="M173.585 72.9738C173.585 59.9427 169.217 47.2877 161.18 37.0309C153.142 26.7741 141.898 19.5076 129.244 16.3925C116.591 13.2775 103.259 14.4936 91.3779 19.8466C79.497 25.1996 69.7533 34.3806 63.7038 45.9223C57.6543 57.4641 55.6481 70.7006 58.0058 83.5166C60.3635 96.3327 66.949 107.989 76.71 116.622C86.471 125.255 98.8442 130.367 111.852 131.142C124.86 131.916 137.753 128.308 148.469 120.893L115.315 72.9738H173.585Z" />
                            </mask>
                            <path d="M173.585 72.9738C173.585 59.9427 169.217 47.2877 161.18 37.0309C153.142 26.7741 141.898 19.5076 129.244 16.3925C116.591 13.2775 103.259 14.4936 91.3779 19.8466C79.497 25.1996 69.7533 34.3806 63.7038 45.9223C57.6543 57.4641 55.6481 70.7006 58.0058 83.5166C60.3635 96.3327 66.949 107.989 76.71 116.622C86.471 125.255 98.8442 130.367 111.852 131.142C124.86 131.916 137.753 128.308 148.469 120.893L115.315 72.9738H173.585Z" stroke="white" stroke-width="3.19291" mask="url(#path-1-inside-1_412_11356)" />
                            <path d="M166.236 100.186C163.886 104.584 162.145 107.501 159.959 109.988C157.852 112.387 155.313 114.406 151.369 116.954L121.174 75.9197L173.06 76.6942C173.168 86.214 170.841 91.57 166.236 100.186Z" stroke="white" stroke-width="1.06919" />
                        </svg>
                    </div>
                    <div className='row col-2' style={{ padding: '100px 0 0 0', margin: 0, height: '100%' }}>
                        <div className='col-12 frameTop4Head' style={{ padding: 0, margin: 0 }}>SUCCESSFUL</div>
                        <div className='col-12 frameTop4Body' style={{ padding: 0, margin: 0 }}>{detailData.success}%</div>
                    </div>
                    <div className='row col-2' style={{ padding: '100px 0 0 0', margin: 0, height: '100%' }}>
                        <div className='col-12 frameTop4Head' style={{ padding: 0, margin: 0 }}>LEVEL</div>
                        <div className='col-12 frameTop4Body' style={{ padding: 0, margin: 0 }}>{detailData.level}</div>
                    </div>
                </div>
                <div className='row' style={{ padding: 0, margin: 0, height: '55%' }}>
                    <div className='row col-3' style={{ padding: '0 15px 0 0', margin: 0, height: '100%' }}>
                        {historyBox ? (<>
                            <div className='detailHistoryListBox'>
                                {detailData.projectHistory.map((e) => {
                                    return (
                                        <>
                                            <div className='row detailHistoryList'>
                                                <div className='col-6 detailHistoryListName'>{e.projectName}</div>
                                                <div className='col-6 detailHistoryListStatus'>PROGRESS : <span className='projectHistoryListProgress'>{e.projectProgress}%</span><br /> STATUS : <span className='projectHistoryListProgress'>-</span></div>
                                                <div className='col-6 detailHistoryListPeriod'>PERIOD : {moment(e.projectStart).format("DD MMM yyyy")} - {moment(e.projectEnd).format("DD MMM yyyy")}</div>
                                                <div className='col-6 detailHistoryListBudget'>BUDGET : {numberWithCommas(parseFloat(e.projectBudget || 0).toFixed(2))} BAHT</div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </>) : (<>
                            {detailData.projectHistory.length > 0 ? (<>
                                <div className='col-12 frameBottom1Head' style={{ padding: 0, margin: 0 }}>CURRENT PROJECT</div>
                                <div className='col-12 frameBottom1Name' style={{ padding: 0, margin: '-20px 0 0 0' }}>{detailData.projectName}</div>
                                {/* <div className='col-12 frameBottom1Detail' style={{ padding: 0, margin: '-5px 0 0 0' }}>PROJECT CODE : {detailData.proCode}</div> */}
                                <div className='col-12 frameBottom1Detail' style={{ padding: 0, margin: '-10px 0 0 0' }}>OWNER : {detailData.owner}</div>
                                <div className='col-12 frameBottom1Detail' style={{ padding: 0, margin: '-10px 0 0 0' }}>BUDGET : {numberWithCommas(parseFloat(detailData.budget || 0).toFixed(2))} BAHT</div>
                                <div className='col-12 frameBottom1Detail' style={{ padding: 0, margin: '-10px 0 0 0' }}>DETAIL :</div>
                                <div className='col-12 frameBottom1DetailMore' style={{ padding: 0, margin: '-20px 0 0 0' }}>{detailData.detail}</div>
                                <div className='col-12 frameBottom1Detail' style={{ padding: 0, margin: '-10px 0 0 0' }}>PERIOD : {moment(detailData.periodStart).format('D MMM yyyy')} - {moment(detailData.periodEnd).format('D MMM yyyy')}</div>
                                <div className='col-6 frameBottom1Detail' style={{ padding: 0, margin: '-10px 0 0 0' }}>PROGRESS : {detailData.progress}%</div>
                                <div className='col-6 frameBottom1Detail' style={{ padding: 0, margin: '-10px 0 0 0' }}>STATE : {detailData.state}</div>
                            </>) : (<>
                                <div className='detailHistoryListBox'></div>
                            </>)}
                        </>)}
                    </div>
                    <div className='row col-9' style={{ padding: 0, margin: 0, height: '100%' }}>
                        <div className='col-12 frameBottom2Head' style={{ padding: 0, margin: 0 }}>MANAGEMENT</div>
                        <div className='col-6 frameBottom2Mem' style={{ padding: 0, margin: '-12.5px 0 0 0' }}>MEMBER IN SPACESHIP {detailData.member}/{detailData.maxMember}</div>
                        <div className='col-6 frameBottom2Add' style={{ padding: 0, margin: '-5px 0 0 0', display: `${detailData.status || detailData.member >= detailData.maxMember || checkOwner() ? 'none' : ''}` }} onClick={() => {
                            addMember()
                        }}>
                            <i class="fa fa-plus-square" aria-hidden="true" style={{ marginRight: '5px' }} /* onClick={() => {
                                addMember()
                            }} */></i>
                            ADD MEMBER
                        </div>
                        <div className='col-12 frameBottom2Table' style={{ padding: 0, margin: 0 }}>
                            {detailData.memberList.map((e) => {
                                return (
                                    <div className='row detailTable' style={{ padding: 0, margin: '0 0 5px 0' }}>
                                        <div className='col-6' style={{ padding: 0, margin: 0 }}>
                                            <img src={memberListIcon} style={{ width: '10px', margin: '-4px 10px 0 10px' }}></img>
                                            {e.memberName}
                                        </div>
                                        <div className='col-2 detailList' style={{ padding: 0, margin: 0 }}>
                                            <div className='memberStatus' style={{ margin: '0 10px', border: `1px solid ${e.memberStatus === true ? 'GREEN' : 'BLUE'}` }}>{e.memberStatus === true ? 'ACCEPT' : 'INVITED'}</div>
                                        </div>
                                        <div className='col-4 detailList' style={{ padding: 0, margin: 0 }}>
                                            <div style={{ margin: '0 10px' }}>{e.memberPosition}</div>
                                            <div className='detailListLast' style={{ margin: '0 10px' }} onClick={() => {
                                                seeMore()
                                            }}>see more</div>
                                            <i class="fa fa-trash detailIcon" aria-hidden="true" style={{ margin: '2px 10px 0 10px', opacity: `${detailData.member <= 1 || e.memberLeader || checkOwner() ? '0.6' : '1.0'}`, cursor: `${e.memberLeader || checkOwner() ? 'not-allowed' : 'pointer'}` }} onClick={() => {
                                                if (e.memberLeader != true && detailData.status != true && detailData.member > 1 && checkOwner() != true/* && e.memberId != sessionStorage.getItem('user_id') */) {
                                                    // remove(e)
                                                    /*       kickMemberOnSpaceship(spaceShipId,e.memberId) */

                                                    setMemberDrop({
                                                        member: e
                                                    })
                                                    memberDrop.member = e
                                                    toggle_modal_confirmBox()
                                                } else if (detailData.status == true) {
                                                    toggle_modal_alertKick()
                                                }
                                            }}></i>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>




            <div className='detailHistory' style={{ visibility: `${mode === '1' ? 'visible' : 'hidden'}` }} onClick={() => {
                toggle_modal_historyBox()
            }}>HISTORY</div>

            <div className='confirmBg' style={{ display: `${confirmBox ? '' : 'none'}` }}>
                <div className='confirmBox'>
                    <div className='row confirmBoxHead'>CONFIRM?</div>
                    <div className='row confirmBoxText'>You sure to kick off member!</div>
                    <div className='row confirmBoxButton'>
                        <button className='confirmButton' onClick={() => {
                            remove(memberDrop.member)

                            toggle_modal_confirmBox()
                        }}>CONFIRM</button>
                        <button className='cancleButton' onClick={() => {
                            toggle_modal_confirmBox()
                        }}>CANCLE</button>
                    </div>
                </div>
            </div>

            <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}` }}>
                <div className='alertKickBoxShadow'>
                    <div className='alertKickBox'>
                        <div className='alertKickBoxIn'>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead'>
                                    ! ERROR !
                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning'>
                                    WARNING
                                </div>
                            </div>
                            <div className='row' style={{ height: '12.5%' }}>
                                <div className='alertKickText'>
                                    THIS SPACESHIP HAS BEEN KICK-OFF PROJECT
                                </div>
                            </div>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickTextSec'>
                                    YOU CAN NOT GET OFF FROM THIS SPACESHIP
                                </div>
                            </div>
                            <div className='row alertKickBoxButton'>
                                <button className='alertKickButton' onClick={() => {
                                    toggle_modal_alertKick()
                                }}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SpaceshipWorkload 
                isOpen={spaceshipWorkloadIsOpen}
                toggleIsOpen={toggle_spaceshipWorkloadIsOpen}
                id={spaceshipIsId.id}
            />
        </>
    )
}

export default Detail;
