

import jsPDF from "jspdf";
import { Get_font_pdf_th1, Get_font_pdf_th2 } from "../../../assets/fonts/pdf_font";
import moment from "moment";
import 'moment/locale/th';
import Configs from "../../../config";
import iconMember from "../../../assets/img/report/Icon Spaceship Member_V2 (1).png";
import noimg from "../../../assets/img/report/Report_Header_NoImage.png"
import axios from "axios";
import html2canvas from "html2canvas";
import { logDOM } from "@testing-library/react";
import clipPaperIcon from './img/clip paper.png';
import { after, before } from "lodash";
import { v4 as uuidv4 } from 'uuid';
/* import QRCode from "qrcode"; */

async function timesheertpostionreport (data, id=null, mode, callback) {
    moment.locale('th');
    const compressImage = (imageUrl,maxWidth = 1920 ,maxHeight = 1080) => {
        /*  const mmToPx = (mm) => (mm * 96) / 25.4; */
 
         return new Promise((resolve, reject) => {
             const img = new Image();
             img.crossOrigin = 'Anonymous'; 
             img.src = imageUrl;
             img.onload = () => {
                 const canvas = document.createElement('canvas');
                 const ctx = canvas.getContext('2d');
 
                 let wpx = img.width;
                 let hpx = img.height;
                 const mwpx = /* mmToPx(maxWidth) */maxWidth;
                 const mhpx = /* mmToPx(maxHeight) */maxHeight;
                /*  console.log('w',mwpx,wpx);
                 console.log('h',mhpx,hpx); */
 
     if (wpx > hpx) {
         if (wpx > mwpx) {
             hpx = Math.round((hpx * mwpx) / wpx);
             wpx = mwpx;
         }
     } else {
         if (hpx > mhpx) {
             wpx = Math.round((wpx * mhpx) / hpx);
             hpx = mhpx;
         }
     }
 
     canvas.width = wpx;
     canvas.height = hpx;
     ctx.fillStyle = '#FFFFFF';
     ctx.fillRect(0, 0, wpx, hpx);
     ctx.drawImage(img, 0, 0, wpx, hpx);
 
     resolve(canvas.toDataURL('image/jpeg', 0.8)); 
 
                
             };
 
             img.onerror = () => {
                 reject(new Error('Failed to load image.'));
             };
         });
     };
     let listdatabox = []
    let imghead = noimg
 
    const doc = new jsPDF("p", "mm", "a4");

    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "bold");
    doc.setFont("THSarabunNew", 'bold');
    
    let padding = 15
        , wpage = 210
        , hpage = 297
        , page = 1
        , r = 222
        , g = 222
        , b = 222
        , endpage = 260,
        his = [],
        img = {}
    let heightpage = 34

    if (data.img.length > 0) {
        img = data.img[0]
    }
  
     let colordrop = [
        {
            before:'#676767',
            after:'#DEDEDE'
        },
        {
            before:'#FF00E5',
            after:'#FBDCFF'
        },
        {
            before:'#BA84FF',
            after:'#CCD9FA'
        },
        {
            before:'#00A5FF',
            after:'#CDEEFF'
        },
        {
            before:'#259B24',
            after:'#D3FFCF'
        },
        {
            before:'#FFEB3B',
            after:'#FFFDCF'
        },
        {
            before:'#FF8000',
            after:'#FFE0C1'
        },
        {
            before:'#FF0000',
            after:'#FFD9D9'
        },
     ]
     console.log(data);
    if (data.fed[0].federation_color !== null) {
        let selectcolor = colordrop.findIndex((e) => e.before == data.fed[0].federation_color || e.after == data.fed[0].federation_color)
        console.log(selectcolor);
        if (selectcolor !== -1) {
            let hex = colordrop[selectcolor].after.replace(/^#/, '');
            let bigint = parseInt(hex, 16);
    
            r = (bigint >> 16) & 255;
            g = (bigint >> 8) & 255;
            b = bigint & 255;
        }
    
    }
  console.log(data);
  

 let autosetdata = [],maxauto = 1
    heightseting(7)

    Shot('fs', 20) 
    Shot('t', CutPadding('w', 50), heightpage, TexttoString(`รายงานสรุป Timesheet ประจำเดือน ${moment(data.date).format('MMMM yyyy')}`), 'c')
    Shot('t', CutPadding('w', 50), heightpage, TexttoString(`รายงานสรุป Timesheet ประจำเดือน ${moment(data.date).format('MMMM yyyy')}`), 'c')
    heightseting(7)

    let table = {
        col1: CutPadding('w', 15, -padding),
        col2: CutPadding('w', 18, -padding),
        col3: CutPadding('w', 67, -padding),
        name1: 'Date',
        name2: 'Work Load',
        name3: 'Task',
        loop: 3
    }

let numuser = 1
for (let i of data.dataTable) {
    autosetbox(0,50,`ชื่อ-นามสกุล`,i.name)
    autosetbox(50,50,`ตำแหน่ง`,i.role)
    autosetbox(0,50,`ช่วงวันที่`,`${moment(i.dates).format('DD MMMM yyyy')} - ${moment(i.datee).format('DD MMMM yyyy')}`)
    autosetbox(50,50,`Mandays`,i.day)
    ShotTable('ht', table, heightpage, table.loop, 7, '', 'fd')
    heightpage+=7
    let befor = heightpage
    for (let index of i.data) {
        if (heightpage > endpage) {


            newpage(endpage)
           
            autosetbox(0,50,`ชื่อ-นามสกุล`,i.name)
            autosetbox(50,50,`ตำแหน่ง`,i.role)
            autosetbox(0,50,`ช่วงวันที่`,`${moment(i.dates).format('DD MMMM yyyy')} - ${moment(i.datee).format('DD MMMM yyyy')}`)
            autosetbox(50,50,`Mandays`,i.day)
            ShotTable('ht', table, heightpage, table.loop, 7, '', 'fd')
            heightpage+=7
            befor = heightpage
        }
        let beforin = heightpage
        
       let item = 0
     /*   console.log(heightpage,index.list,endpage,index.date); */
       if (index.list.length > 0) {
        
     
        for (let li of index.list) {
            if (heightpage > endpage) {
                Shot('r', Colposition('r', table, 1), befor, CutPadding('w', 0, table.col1 - padding), heightpage -befor);
                /* Shot('r', Colposition('r', table, 2), befor, CutPadding('w', 0, table.col2 - padding), heightpage -befor); */
                
                newpage(endpage)
                befor = heightpage
                beforin = heightpage
                autosetbox(0,50,`ชื่อ-นามสกุล`,i.name)
    autosetbox(50,50,`ตำแหน่ง`,i.role)
    autosetbox(0,50,`ช่วงวันที่`,`${moment(i.dates).format('DD MMMM yyyy')} - ${moment(i.datee).format('DD MMMM yyyy')}`)
    autosetbox(50,50,`Mandays`,i.day)
    ShotTable('ht', table, heightpage, table.loop, 7, '', 'fd')
    heightpage+=7
    befor = heightpage
    beforin = heightpage
            }
            let cutText = doc.splitTextToSize(`${li.project_name}${li.module_name? `${li.module_name} `:''} ${li.menu_name? `${li.menu_name} `:''} ${li.process_name? `${li.process_name} `:''} ${li.ts_project_detail}`, CutPadding('w', 0, table.col3 - (padding + 4)));
            /* console.log(cutText,'s'); */
            let num = 0
            for (let c of cutText) {
                if (heightpage > endpage) {
                    Shot('r', Colposition('r', table, 1), befor, CutPadding('w', 0, table.col1 - padding), heightpage -befor);
                    Shot('r', Colposition('r', table, 2), beforin, CutPadding('w', 0, table.col2 - padding), heightpage -beforin);
                    Shot('r', Colposition('r', table, 3), beforin, CutPadding('w', 0, table.col3 - padding), heightpage - beforin);
             
                    newpage(endpage)
                
                    autosetbox(0,50,`ชื่อ-นามสกุล`,i.name)
                    autosetbox(50,50,`ตำแหน่ง`,i.role)
                    autosetbox(0,50,`ช่วงวันที่`,`${moment(i.dates).format('DD MMMM yyyy')} - ${moment(i.datee).format('DD MMMM yyyy')}`)
                    autosetbox(50,50,`Mandays`,i.day)
                    ShotTable('ht', table, heightpage, table.loop, 7, '', 'fd')
                    befor =heightpage
                    beforin = heightpage
                    heightpage+=7
                }
        if (num === 0 && item == 0) {
            Shot('t', Colposition('t', table, 1, 'c'), heightpage+5, TexttoString(index.date), 'c');
          
        }
        if (num === 0 /* && item == 0 */) {
         
            Shot('t', Colposition('t', table, 2, 'c'), heightpage+5, TexttoString(`${li.ts_hours} ชั่วโมง ${li.ts_time} นาที`), 'c');
        }
                Shot('t',  Colposition('t', table, 3, 'l',1),heightpage+5, TexttoString(c), 'l');
                num+=1
                item+=1
                heightpage+=7
            }
            Shot('r', Colposition('r', table, 2), beforin, CutPadding('w', 0, table.col2 - padding), heightpage -beforin);
            Shot('r', Colposition('r', table, 3), beforin, CutPadding('w', 0, table.col3 - padding), heightpage -beforin);
        
      
        }
    }else{
        Shot('t', Colposition('t', table, 1, 'c'), heightpage+5, TexttoString(index.date), 'c');
        Shot('t', Colposition('t', table, 2, 'c'), heightpage+5, TexttoString(index.list.length > 0 ? '1' : ''), 'c');
      
        heightpage+=7
        Shot('r', Colposition('r', table, 2), beforin, CutPadding('w', 0, table.col2 - padding), heightpage -beforin);
        Shot('r', Colposition('r', table, 3), beforin, CutPadding('w', 0, table.col3 - padding), heightpage -beforin);
        
    }
       

       Shot('r', Colposition('r', table, 1), befor, CutPadding('w', 0, table.col1 - padding), heightpage -befor);
       /* Shot('r', Colposition('r', table, 2), befor, CutPadding('w', 0, table.col2 - padding), heightpage -befor); */
            
     
    }
    console.log(numuser,data.dataTable.length);
    
if (numuser < data.dataTable.length) {
    Shot("add");
    page += 1
    heightpage = 38
    befor= heightpage
}
numuser+=1
}
    
    
    if (data.img.length > 0) {
        img = await getboximg(data.img[0].fd_img_id)
        imghead = /* await compressImage(Configs.TTT_Game_Service_IMG + img.ul_file_path ,mmToPx(wpage + 1), mmToPx(27)) */img
    }else{
        imghead = /* await compressImage(noimg,mmToPx(wpage + 1), mmToPx(27)) */noimg

    }
    Shot('fs', 12)
    for (let i = 1; i <= page; i += 1) {
        
            doc.setPage(i)
            sethead()
            setfooter(i, page)
        

    }

    moment.locale('th');
    if (mode === 'version') {
        const dataUri = doc.output('datauristring');
        const binaryData = atob(dataUri.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const file = new File([blob], `${data.file.file_name}.pdf`, { type: 'application/pdf' });
        console.log(file);
        const ref_id = data.file.ref_id;
        CheckAndCreateFolderBox({id:ref_id},'',file)

       /*  const dataImg = new FormData();
        dataImg.append('Profile', file);    
        dataImg.append('typeFile', 'TimeSheetReport');
        dataImg.append('projectKey', "tttspaceship");
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/upload/document2`,
            headers: {
                
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/octet-stream',
            },
            data: dataImg,
        })
            .then(async function (response) {
             
           
                   if(callback){
                    callback(response.data)
                   }
                

            })
            .catch(function (error) {
                console.log(error);
            }); */








    } else {
        Shot('new'/* , `TimeSheet_${moment(data.date.s).diff(moment(data.date.e), 'days') !== 0 ? `${moment(data.date.s).add('years', 543).format('YYMMDD')}_${moment(data.date.e).add('years', 543).format('YYMMDD')}` : `${moment(data.date.s).add('years', 543).format('YYMMDD')}`}${data.version > 0 ? `_V${data.version+1}`: `` }.pdf` */);

        return 'test'
    }

    async function heightseting(num = 0) {
         heightpage+=num 
        if (heightpage > endpage-7) {
            newpage(endpage-7)
        }
        
    }
    function autosetbox(s,w,h,t,font =16) {
        let cutText = doc.splitTextToSize(`${t}`, CutPadding('w', w-12, -(padding + 4)));
                      console.log(cutText);
                      Shot('tc',0, 0, 0);
                      Shot('fs', 14)
                      let count = 1
                      for (let c of cutText) {
                          Shot('t', CutPadding('w', s+12, 2), heightpage+((5* count)), TexttoString(c), 'l')
                          count+=1
                      }
    
   
      
                      if (maxauto < count-1) {
                          maxauto = count-1
                      }
  
      autosetdata.push({
          s:s,
          w:w,
          h:h,
          t:t,
      }
  
      )
  if (s+w === 100) {
    
      for (let i of autosetdata) {
      Shot('fc', r, g, b);
      Shot('dc', 0, 0, 0);
      Shot('tc',0, 0, 0);
      Shot('r', CutPadding('w', i.s), heightpage, CutPadding('w', 12, -padding), 5*maxauto+2, 'fd')
      Shot('fs', font)
      Shot('t', CutPadding('w', i.s, 2), /* heightpage+((5* maxauto)/2)+2 */heightpage+((5* 1)), TexttoString(i.h), 'l')
      Shot('t', CutPadding('w', i.s, 2), /* heightpage+((5* maxauto)/2)+2 */heightpage+((5* 1)), TexttoString(i.h), 'l')
      Shot('r', CutPadding('w', i.s+12), heightpage, CutPadding('w', i.w-12, -padding), 5*maxauto+2)
          
      }
      autosetdata = []
      heightpage += (5*maxauto)+2
      maxauto = 1
   
  }
      }


    async function getboximg(imageid,check = null) {
        try{
        const response2 = await axios.get(`https://api.box.com/2.0/files/${imageid}/content`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
        });
        if (check !== null) {
            listdatabox.push({
                id:imageid,
                path:URL.createObjectURL(response2.data)
            })
        }
     return URL.createObjectURL(response2.data);
    }catch (error) {
        return null 
    }
    }


    async function CheckAndCreateFolderBox(datalist,olddata,file) {
        let temp = {
            folderLis: ["FEDERATION",`FEDERATION_${data.fed_id}`,"FEDERATION SUMMARY TIMESHEET MONTH REPORT"]
        }
       /*  temp.folderLis.push(folder_name) */
        await axios({
            method: 'post',
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    datalist.files = file
                    datalist.file_name =file.name
                    await UploadFiles(datalist, response.data.folder_id,olddata)
             
                }
            })
            .catch(function (error) {
               
                console.log(error);
            });
    }
    
    
    
    async function UploadFiles(item, folder_id,olddata) {
      console.log(item);
      const dataImg = new FormData();
      dataImg.append('File', item.files);
      dataImg.append('folder_id', folder_id);
      dataImg.append('key', "FEDERATION SUMMARY TIMESHEET MONTH REPORT");
      dataImg.append('image_old_list', JSON.stringify(olddata));
      dataImg.append('ref_id', item.id);
    
      await axios({
          method: 'post',
          url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFileschecking`,
          headers: {
            Authorization: sessionStorage.getItem('access_token'),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "multipart/form-data",
          },
          data: dataImg
      })
          .then(async function (response) {
              if (response.data) {
                if (response.data.image_id_list.length > 0) {
                    let as = {
                        ref_id: item.id,
                        pathimg: response.data.image_id_list[0],
                        file_name: item.file_name

                    }
                    callback(as)
                
                }
               
              }
          })
          .catch(function (error) {
            
              console.log(error);
          });
    }
    function newpage(count) {
        if (heightpage > count) {
            Shot("add");
            page += 1
            heightpage = 38

        }
    }


    function sethead() {
        Shot('i', /* img.ul_file_path !== undefined ? Configs.TTT_Game_Service_IMG + img.ul_file_path : */ imghead, 0, 0, wpage + 1, 27)

    }
    function setfooter(on, max) {

        Shot('t', CutPadding('w', 100,), 283, TexttoString(`All Rights Reserved, Copyright ${'TTT Brother Co., Ltd.'} | หน้า ${on}`), 'r')
        Shot('t', CutPadding('w', 100,), 288, TexttoString(`Template Version 1.0 - TTT Spaceship`), 'r')

    }
    /* --------------------------------------------------------------------------------------------------------------------------------------------------------------- */

    async function Shot(mode, c1, c2, c3, c4, c5, c6, c7) {
        if (mode === 'add') {
            if (c1 !== undefined) {
                return doc.addPage(c1);
            } return doc.addPage()
        }
        if (mode === 'new') {
            window.open(doc.output('bloburl'));
        }
        if (mode === 'save') {

            if (c1 !== undefined) {
                return doc.output('save', c1);
            } return doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY'));

        }
        if (mode === 'newsave') {
            if (c1 !== undefined) {
                return window.open(doc.output('bloburl'), doc.output('save', c1));

            } return window.open(doc.output('bloburl'), doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY')));

        }

        /*  if (mode === 'qr') {
           let imgqr = ''
         QRCode.toDataURL(c1, function (err, url) {
           imgqr = url;
         });
   
         return doc.addImage(imgqr, 'JPEG', c2, c3, c4, c4);
       }
      */
        if (mode === 'fs') {
            doc.setFontSize(c1)
        }
        if (mode === 'dc') {
            doc.setDrawColor(c1, c2, c3);
        }
        if (mode === 'tc') {
            doc.setTextColor(c1, c2, c3);
        }
        if (mode === 'fc') {
            doc.setFillColor(c1, c2, c3);
        }
        if (mode === 'lw') {
            doc.setLineWidth(c1);
        }


        if (mode === 'i' && c1 !== null) {
            if (c5 !== undefined) {
                if (c6 !== undefined) {

                    if (c6 === 'fit') {
                     

                       /*  console.log(c2 + x, c3 + y, nw, nh); */
                      
                       let Width = c7.width? c7.width : 1920;
                       let Height = c7.height? c7.height : 1080; 
                    
                      const imgar = Width / Height;
                      const reactar = c4 / c5;

                      let scale = 1;
                      if (imgar > reactar) {
                          scale = c4 / Width;
                      } else {
                          scale = c5 / Height;
                      }
                      const nw = Width * scale;
                      const nh = Height * scale;

                      const x = (c4 - nw) / 2;
                      const y = (c5 - nh) / 2;


                      const compressedImgData = await compressImage(c1);


                       return doc.addImage(compressedImgData, 'JPEG', c2 + x, c3 + y, nw, nh, undefined, 'FAST')
                    }
                } return doc.addImage(c1, 'JPEG', c2, c3, c4, c5);
            } return doc.addImage(c1, 'JPEG', c2, c3, c4, c4);

        }
        if (mode === 'r') {
            if (c5 !== undefined) {
                if (c5 === 'd') {
                    return doc.rect(c1, c2, c3, c4, 'D');
                }
                if (c5 === 'fd') {
                    return doc.rect(c1, c2, c3, c4, 'FD');
                }
                if (c5 === 'f') {
                    return doc.rect(c1, c2, c3, c4, 'F');
                }
            } else return doc.rect(c1, c2, c3, c4);

        }
        if (mode === 't') {

            if (c4 !== undefined) {
                if (c4 === 'c') {
                    return doc.text(c1, c2, c3, 'center');
                }
                if (c4 === 'r') {
                    return doc.text(c1, c2, c3, 'right');
                }
                if (c4 === 'l') {
                    return doc.text(c1, c2, c3, 'left');
                }
            } else return doc.text(c1, c2, c3);
        }

    }


    function CutPadding(mode, percent, num) {

        if (mode === 'h') {
            if (num !== undefined) {
                return (((hpage/*  - (padding * 2) */) * percent) / 100)/*  + padding */ + num;
            } return (((hpage/*  - (padding * 2) */) * percent) / 100) /* + padding */;

        }
        if (mode === 'w') {
            if (num !== undefined) {
                return (((wpage - (padding * 2)) * percent) / 100) + padding + num;
            } return (((wpage - (padding * 2)) * percent) / 100) + padding;


        }

    }

    function TexttoString(text) {
        if (text === null) {
            return ''.toString();
        } return text.toString();

    }
    function NumtoString(num, fix) {
        if (num !== null) {
            if (fix !== undefined) {
                return parseFloat(num).toFixed(fix).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } return parseFloat(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } return parseFloat(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function ShotTable(mode, head, pos, col, coll, loop, color, hig, link = [], header) {
        /* console.log(mode, head, pos, col, coll, loop, color); */
        let collengthsum = coll === undefined ? 5 : coll
        let loopsum = loop === undefined ? 10 : loop
        if (mode === 'ht') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum, color);
                if (head[`name${t}`] !== undefined) {
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                }


            }
        }

        if (mode === 'htissue') {
            for (let t = 1; t <= col; t += 1) {
                if (t === 1) {
                    Shot('fc', 255, 255, 0);
                }else{
                    Shot('fc', 255, 0, 0);
                    /* Shot('tc',255,255,255) */
                }
               
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum, color);
                if (head[`name${t}`] !== undefined) {
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                }


            }
            Shot('tc',0,0,0)
        }

        if (mode === 'lt') {
            let lengthloop = (loopsum * collengthsum) + collengthsum
            for (let t = 1; t <= col; t += 1) {

                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), lengthloop);


            }

        }

        if (mode === 'st') {
            let lengthloop = collengthsum
            for (let l = 0; l < loopsum; l += 1) {
                for (let t = 1; t <= col; t += 1) {
                    Shot('r', Colposition('r', head, t), pos + lengthloop, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum);

                }
                lengthloop += collengthsum
            }
        }

        if (mode === 'htc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig, color);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += collengthsum * hig
        }
        if (mode === 'ltc') {
            let befor = 0, higbefor = pos, maxhig = 0
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4); */

                if (head[`name${t}`] !== undefined) {
                    for (let d = befor; d < head[`name${t}`].length; d += 1) {
                        let c = head[`name${t}`][d]
                        if (heightpage + ((d - befor) * 6) > 250) {
                            if (t < col) {
                                for (let t2 = t + 1; t2 <= col; t2 += 1) {
                                    let fakecolsum = coll
                                    for (let dx = befor; dx <= d; dx += 1) {
                                        if (head[`name${t2}`].length > dx) {
                                            let cx = head[`name${t2}`][dx]

                                            let chackrow = link.filter((e) => { return e.n === cx })
                                            console.log(chackrow, cx, 'in');
                                            if (chackrow.length > 0) {
                                                /*   doc.textWithLink(chackrow[0].n,  Colposition('t', head, t2, 'l',3),  higbefor + (fakecolsum), {url: chackrow[0].l , target: '_blank'});
                                               */
                                                let y = higbefor + (fakecolsum), x = Colposition('t', head, t2, 'l', 3)
                                                let width = doc.getTextWidth(chackrow[0].n);
                                                let height = doc.internal.getLineHeight() / doc.internal.scaleFactor;

                                                y += height * 0.2;
                                                doc.link(x, y - height, width, height, { url: chackrow[0].l, target: '_blank' });
                                            } /* else { */
                                            Shot('t', Colposition('t', head, t2, t2 === 1 ? 'c' : 'l', 3), higbefor + (fakecolsum), TexttoString(cx), t2 === 1 ? 'c' : 'l')
                                            /* } */
                                            fakecolsum += coll
                                        } else {
                                            break
                                        }

                                    }

                                }

                            }
                            for (let al = 1; al <= col; al += 1) {
                                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), collengthsum + 2);
                            }

                            heightpage += ((d - befor) * 6)
                            newpage(250)
                            higbefor = heightpage
                            collengthsum = col
                            befor = d
                            maxhig = col
                            ShotTable('htc', header, heightpage, header.loop, 7, '', 'fd', header.height)
                            higbefor += 7


                        }


                        let chackrow = link.filter((e) => { return e.n === c })
                        if (chackrow.length > 0) {
                            /*   doc.textWithLink(chackrow[0].n,  Colposition('t', head, t2, 'l',3),  higbefor + (fakecolsum), {url: chackrow[0].l , target: '_blank'});
                           */
                            let y = higbefor + (collengthsum), x = Colposition('t', head, t, 'l', 3)
                            let width = doc.getTextWidth(chackrow[0].n);
                            let height = doc.internal.getLineHeight() / doc.internal.scaleFactor;

                            y += height * .2;
                            doc.link(x, y - height, width, height, { url: chackrow[0].l, target: '_blank' });
                        }
                        Shot('t', Colposition('t', head, t, t === 1 ? 'c' : 'l', 3), higbefor + (collengthsum), TexttoString(c), t === 1 ? 'c' : 'l');
                        collengthsum += coll

                    }
                    if (collengthsum > maxhig) {
                        maxhig = collengthsum
                    }

                    collengthsum = coll

                    /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), befor * higl + 4); */
                }
                /* befor+=1 */


            }
            for (let al = 1; al <= col; al += 1) {
                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), maxhig);
            }
            heightpage += maxhig
            /*  heightpage += (collengthsum * hig) + 4 */
        }

        if (mode === 'stc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'l'), pos + (collengthsum /* / 1.6 */), TexttoString(c), 'l');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += (collengthsum * hig) + 4
        }



    }
    function Colposition(mode, name, col, pos, pad) {


        if (mode === 'r') {
            const poscol = pos !== undefined ? pos : 0
            const colcount = col - 1
            let colsum = 0

            for (let i = colcount - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }

            if (colcount === 0) {
                return CutPadding('w', 0)
            } return CutPadding('w', 0, name[`col${colcount}`] + poscol) + colsum


        }
        if (mode === 't') {
            let colsum = 0
            const paddingcol = pad !== undefined ? pad : 0
            for (let i = col - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }


            if (col === 0) {
                return CutPadding('w', 0)
            }

            if (pos !== undefined) {
                if (pos === 'c') {
                    return CutPadding('w', 0, (name[`col${col}`] * (50 + paddingcol)) / 100) + colsum
                }
                if (pos === 'l') {
                    return CutPadding('w', 0, (name[`col${col}`] * (1 + paddingcol)) / 100) + colsum
                }
                if (pos === 'r') {
                    return CutPadding('w', 0, (name[`col${col}`] * (99 - paddingcol)) / 100) + colsum
                }
            } return CutPadding('w', 0, name[`col${col}`]) + colsum



        }



    }



}





export default timesheertpostionreport;
