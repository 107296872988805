import React, { useEffect, useRef } from 'react'
/* import HeaderTTT from "../components/header/HeaderTTT"; */
import '../../assets/css/landingPageGame.css'
import { useState } from 'react'
import DefaultUser from '../../assets/img/iconSpaceShip/Default_V1.png';
import logo from '../../assets/img/iconSpaceShip/Logo TTT 2.png';
import icon_plus from '../../assets/img/iconSpaceShip/Frame_plus.png';
import icon_plus2 from '../../assets/img/iconSpaceShip/plus.png';
import icon_rocket from '../../assets/img/iconSpaceShip/rocket.png';
import icon_mail from '../../assets/img/iconSpaceShip/mail.png';
import icon_fresh from '../../assets/img/iconSpaceShip/Frame_fresh.png';
import icon_pen from '../../assets/img/iconSpaceShip/edit_pen.png';
import icon_sapphire from '../../assets/img/iconSpaceShip/Frame.png';
import logo_ss from '../../assets/img/iconSpaceShip/Final-Logo.png';
import logo_user from '../../assets/img/iconSpaceShip/user.png';
import logo_twitch from '../../assets/img/iconSpaceShip/twitch.png';
import logo_pointer from '../../assets/img/iconSpaceShip/mouse-pointer.png';
import img_banner from '../../assets/img/slider/banner_magazine_thumb01.jpg';
import img_sm from '../../assets/img/icon/IconMonitoring.png';
import Icon_Universal from '../../assets/img/icon/Icon_Universal.gif';
import axios from "axios";
import Configs from "../../config";
import moment from "moment";
import $, { data } from "jquery";
import * as THREE from 'three';
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import Stats from 'three/examples/jsm/libs/stats.module';
import { KTX2Loader } from 'three/addons/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/addons/libs/meshopt_decoder.module.js';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Cardstage from './cardstage'
import towerJSON from '../3dModalTEST/towerData.json';
import Carddefcon from './carddefcon'
/* import towerJSON from '../3dModalTEST/tower500Data.json'; */
import SelectProject from '../component/SelectProject/selectProject';
import PersonalInfo from '../personalInfo/personalInfo.js';
import ProjectTimelinereport from '../component/Report/ProjectTimelinereport';
import testreport from '../component/Report/srsreport';
import ModalProject from '../component/EditProject/modalEditProject';
import RiskIssue from './risk&Issue/riskIssue.jsx';
import DashBordFederation from './dashBordFederation/index.jsx';
import ProjectState from './ProJectState/projectstate.jsx';
import ImgEditModal from '../component/EditProject/IconEdit.png'
import Swal from 'sweetalert2';
import reportallsetingfunc from '../component/Report/reportallseting';
import ModalAddModule from './Module&Menu/ModalAddModule.jsx'
import ModalAddMenu from './Module&Menu/ModalAddMenu.jsx'
import ModalImportStage from './Module&Menu/ModalImport.jsx'
import ModalPackgage from '../Package/index'
import ModalSRS from '../Package/Srsmodal/modalsrs.jsx';
import ModalDetail from './Detail/detail.jsx'
import AddMember from '../component/AddMember/addMember.jsx';
import iconName from '../../assets/img/icon/Group11.png'
import userProfileDefualt from '../../assets/img/userlogo/defultUser.png'
import iconviewproject from '../../assets/img/icon/iconviewproject.png'
import unlockImg from './ProJectState/Image/security.png'

import Chating from './chating/chating.jsx';
import CommentReport from '../../components/commentReport/ModelComment.jsx';
import PublicProjeclrit from '../ViewPublicProjec/'
import { io } from 'socket.io-client';
import ModelAlertMassge from "../../components/AlertMassge/AlertMassge";
import TopUpModal from '../component/Topup/Topup.jsx';
import config from '../../config';

import defaultUserLogo from '../../assets/img/userlogo/defultUser.png'
import gostLogo from '../../assets/img/userlogo/gostImg.png'
import * as XLSX from 'xlsx-js-style';
import ViewUserProfile from '../component/ViewUserProfile'

import FederationMgt from './federationMgt/index.js';
import CompleteYourSkill from './CompleteYourSkill/index.js';
import SlotLocationCompon from './SlotLocation/index.js';
/* 
import ModalConfigModule from './Module&Menu/ModalConfigModule.jsx' */
import CardFedSpaceShip from './cardFedSpaceShip.jsx';
import CardFedSpaceShipList from './cardFedSpaceShipList.jsx';
import iconArrow from './img/arrow.png';

import {
  StopOutlined
} from '@ant-design/icons';
import { Tooltip } from 'antd';


function LandingPagestage(param) {
  function allStageMonitoring(param) {
    console.log('param', param);
    setAllMonitoring(param)
  }

  const [allMonitoring, setAllMonitoring] = useState(false);
  const key = new URLSearchParams(document.location.search).get('key');
  const fedSpsId = new URLSearchParams(document.location.search).get('fedSpsId');
  const spaceship_id = new URLSearchParams(document.location.search).get('spaceship_id');
  const projectKey = new URLSearchParams(document.location.search).get('projectKey');
  const menuKey = new URLSearchParams(document.location.search).get('menuKey');
  const processKey = new URLSearchParams(document.location.search).get('processKey');
  const indexKey = new URLSearchParams(document.location.search).get('indexKey');
  const [modalMessageOpen, setModalMessageOpen] = useState(false);
  const toggle_message_modal = () => { setModalMessageOpen(!modalMessageOpen) };

  const [dashFedModal, setdashFedModal] = useState(false);
  const toggle_dashFedModal_modal = () => { setdashFedModal(!dashFedModal) };

  const [openModalCompleteSkill, setOpenModalCompleteSkill] = useState(false);
  const toggle_openModalCompleteSkill_modal = () => { setOpenModalCompleteSkill(!openModalCompleteSkill) }; /* CompleteYourSkill */

  const [fedMgtConfig, setFedMgtConfig] = useState(false);
  const toggle_modal_fedMgtConfig = () => { setFedMgtConfig(!fedMgtConfig) };

  const [clearClasname, setClearClassname] = useState(false);
  const toggle_clear_classname = () => { setClearClassname(!clearClasname) };

  const [modalSrs, setModalSrs] = useState({
    isOpen: false,
    onMenu: '',
    nameMenu: ''
  })

  const [fedSpaceShipId, setFedSpaceShipId] = useState({
    id: ''
  });
  const [textModalComfrim, setTextModalComfrim] = useState({
    text_1: '',
    text_2: '',
    is_ver: 1,
    ban_user_id: ''
  });


  const [filterTower, setFilterTower] = useState(
    {
      mybuild: false,
      inprocess: false,
      lorem: false
    }
  );
  const [isSaves, setisSaves] = useState(false)
  const [isRefsSkill, setIsRefsSkill] = useState(false)
  const [openModal, setOpenModal] = useState('close')
  const [openModalSelectProject, setOpenModalSelectProject] = useState(false);
  const [projectActive, setProjectActive] = useState({
    pf_project_id: '',
    project_name: '',
    project_progress: '',
    spaceship_id: '',
    spaceship_size: '',
    spaceship_name: '',
    pp_start_date: '',
    pp_finish_date: '',
    federation_name: '',
    pp_team_member: '',
    ul_file_name: '',
    ul_file_path: '',
    isLeader: '',
  })
  const [confrimModal, setConfrimModal] = useState(false);
  const toggle_modal_confrimModal = () => setConfrimModal(!confrimModal);
  const [projectActiveTag, setProjectActiveTag] = useState(null);

  const [jsonArea, setJsonArea] = useState(towerJSON);

  const [checkCategory, setCheckCategory] = useState(
    {
      all: true,
      red: true,
      blue: true,
      yellow: true,
      green: true,
      white: true,
    }
  );

  const [colordefcon, setcolordefcon] = useState({ color: '#0DB6FF', isuse: false })

  const [onCategory, setOnCategory] = useState(false)

  const [riskIssue, setRiskIssue] = useState({
    onMenu: '',
    isOpen: false
  })

  const [projectState, setProjectState] = useState({
    isOpen: false
  })

  const [test, settest] = useState({ test: '' })
  const [stateRender, setStateRender] = useState(false)

  const [personalInfoCheck, setPersonalInfoCheck] = useState({
    isOpen: false,
    submode: null
  });

  const [personalInfoViewCheck, setPersonalInViewfoCheck] = useState({
    isOpen: false,
    submode: null
  });

  const [modeldefcon, setmodeldefcon] = useState({
    isOpen: false
  });


  const [modalAddModule, setModalAddModule] = useState(false);
  const toggle_modal_add_module = () => setModalAddModule(!modalAddModule);
  const [modalAddMenu, setModalAddMenu] = useState(false);
  const toggle_modal_add_menu = () => setModalAddMenu(!modalAddMenu);
  const [modalImportStage, setModalImportStage] = useState(false);
  const toggle_modal_import = () => setModalImportStage(!modalImportStage);
  const [modalPackage, setModalPackage] = useState(false);

  const toggle_modal_Package = () => setModalPackage(!modalPackage);
  const [modalDetail, setModalDetail] = useState(false);
  const toggle_modal_Detail = () => setModalDetail(!modalDetail);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const toggle_modal_addMember = () => setAddMemberModal(!addMemberModal);
  const [addMemberReload, setAddMemberReload] = useState(false);
  const toggle_modal_addMemberReload = () => setAddMemberReload(!addMemberReload);
  const [moduleAndMenuKey, setModuleAndMenu] = useState({
    project_id: '',
    module_id: '',
  });


  const [projectDetail, setProjectDetail] = useState({});
  const [mode, setmode] = useState({
    mode: 'all',
    old: null,
    spaceship_id: ''
  });

  const [moduleOption, setModuleOption] = useState([]);
  const [tempValue, setTempValue] = useState({
    user_id: sessionStorage.getItem('user_id')
  });

  const [MenuDetail, setMenuDetail] = useState({
    module_id: '',
    menu_name: '',
  })

  const [userprofile, setuserprofile] = useState({
    spaceshipcount: 0,

  })

  const [mailBox, setMailBox] = useState(false);
  const toggle_modal_mailBox = () => setMailBox(!mailBox);
  const [mailList, setMailList] = useState([
    /*  {
       header: 'Header',
       body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, doloribus!',
       sendBy: 'Firstname Lastname',
       sendTime: new Date(),
       type: 'invite'
     },
     {
       header: 'Header',
       body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, doloribus!',
       sendBy: 'Firstname Lastname',
       sendTime: new Date(),
       type: '???'
     },
     {
       header: 'Header',
       body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, doloribus!',
       sendBy: 'Firstname Lastname',
       sendTime: new Date(),
       type: 'invite'
     },
     {
       header: 'Header',
       body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, doloribus!',
       sendBy: 'Firstname Lastname',
       sendTime: new Date(),
       type: '???'
     }, */
  ])

  const [errorMessage, setErrorMessage] = useState({ status: '', message: '' });
  const [alertKick, setAlertKick] = useState(false);
  const [alertKickall, setAlertKickall] = useState({ data: [] });
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);
  const [checkStatusRiskManage, setcheckStatusRiskManage] = useState(false);
  const [checkMailRead, setCheckMailRead] = useState({
    mailNo: ''
  })
  const [mailTab, setMailTab] = useState({
    is: 'UNREAD'
  })

  const [chatIndexNo, setChatIndexNo] = useState();

  const [freshSelectProjectByFed, setFreshSelectProjectByFed] = useState(false);
  const toggle_freshSelectProjectByFed = () => { setFreshSelectProjectByFed(!freshSelectProjectByFed) };

  const [stageProject, setStageProject] = useState('');

  function onMailRead(no) {
    if (checkMailRead.mailNo !== '') {
      document.getElementById(checkMailRead.mailNo).style["border"] = "2px solid #85BFFF";
      setCheckMailRead({ mailNo: no })
      checkMailRead.mailNo = no
      document.getElementById(no).style["border"] = "2px solid red";
    } else {
      setCheckMailRead({ mailNo: no })
      checkMailRead.mailNo = no
      document.getElementById(no).style["border"] = "2px solid red";
    }

  }



  function setProjectID(projectID) {
    setModuleAndMenu({ ...moduleAndMenuKey, project_id: projectID });
  }

  // console.log('testlog', mode.mode);
  function chcekusermode(id) {
    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/project/chcekusermode/${id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      /* data: temp, */
    })
      .then(async function (response) {
        // console.log(response.data);
        sessionStorage.setItem("getUserMode", response.data.mode);


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getProjectDetail(id) {
    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/project/GetProjectDetail/${id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      /* data: temp, */
    })
      .then(async function (response) {
        // setmode({ ...mode, mode: 'detait' });
        // mode.mode = 'detait';
        setProjectDetail(response.data)





      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function banUserbyproject() {
    socketRef.current.emit('join', "BanMember" + textModalComfrim.ban_user_id);
    var temp = {
      project_id: projectActive.pf_project_id,
      user_ban_id: textModalComfrim.ban_user_id,
      user_leader_id: ''

    }
    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/project/banUserbyproject`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        toggle_modal_confrimModal()
        console.log('TES', socketRef.current);
        socketRef.current.emit('BanMember', { message: 'Fetch', room: "BanMember:" + textModalComfrim.ban_user_id });
        socketRef.current.emit('mailbox', { message: 'banmode', room: "mailBox:" + textModalComfrim.ban_user_id, reSapphir: false });
        GetAccesstokenToken((callback) => {
          if (callback) {
            checkTeamView('ban');
          }
        });

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function unBlockUserbyproject() {
    socketRef.current.emit('join', "BanMember" + textModalComfrim.ban_user_id);
    console.log('textModalComfrim.ban_user_id', textModalComfrim.ban_user_id);
    var temp = {
      project_id: projectActive.pf_project_id,
      user_ban_id: textModalComfrim.ban_user_id,
      user_leader_id: ''
    }
    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/project/unBlockUserbyproject`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        toggle_modal_confrimModal()
        /* socketRef.current.emit('BanMember', { message: 'Fetch', room: "BanMember:" +  textModalComfrim.ban_user_id }); */
        socketRef.current.emit('mailbox', { message: 'banmode', room: "mailBox:" + textModalComfrim.ban_user_id, reSapphir: false });
        GetAccesstokenToken((callback) => {
          if (callback) {
            checkTeamView('ban');
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  function AccpectMemberOnSpace(space_id, id, usr_create_id, noti_id, project_id_noti) {

    socketRef.current.emit('join', "KickOffProject" + project_id_noti);

    var temp = {
      user_id: id,
      space_id: space_id,
      usr_create_id: usr_create_id,
      noti_id: noti_id
    }
    console.log('space_idspace_id', usr_create_id);
    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/project/accpectMemberOnSpace`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {

        socketRef.current.emit('KickOff', { message: '', room: "KickOffProject:" + project_id_noti });
        socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + noti_id, reSapphir: true });
        socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + usr_create_id, reSapphir: true });

        getMailByUser();

      })
      .catch(function (error) {

        socketRef.current.emit('KickOff', { message: '', room: "KickOffProject:" + project_id_noti });
        socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + noti_id, reSapphir: true });
        socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + usr_create_id, reSapphir: true });

        toggle_teamViewPopUpEr2()
        /*   setTeamViewPopUpEr2(true) */
        console.log(error);
      });
  }


  function DeclupMemberOnSpace(space_id, id, usr_create_id, noti_id, project_id_noti) {
    /*   console.log('เข้าเเล้ว',project_id_noti); */
    socketRef.current.emit('join', "KickOffProject" + project_id_noti);

    var temp = {
      user_id: id,
      space_id: space_id,
      usr_create_id: usr_create_id,
      noti_id: noti_id
    }
    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/project/declupMemberOnSpace`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        /*    console.log('usr_create_id',usr_create_id,id); */

        socketRef.current.emit('KickOff', { message: '', room: "KickOffProject:" + project_id_noti });
        socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + noti_id });
        getMailByUser();

      })
      .catch(function (error) {
        console.log(error);
      });
  }



  function getModuleByProject(id) {

    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/moduleMenu/getModuleByProject/${id}`,
      headers: {
        /*     Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      /* data: temp, */
    })
      .then(async function (response) {

        let options = [];
        response.data.forEach((e) => {
          options.push({
            value: e.module_id,
            label: e.module_name

          });

        })
        setModuleOption(options);


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getcountSpaceship() {

    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Permission_Service}/api/userprofile/getcountSpaceship/${sessionStorage.getItem('user_id')}`,
      headers: {
        /*     Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      /* data: temp, */
    })
      .then(async function (response) {


        setuserprofile({
          ...userprofile,
          spaceshipcount: response.data

        })
        userprofile.spaceshipcount = response.data


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [sapphireData, setSapphireData] = useState(0);
  function formatNumberToK(value) {


    if (parseInt(value) > 999999) {
      return parseFloat(value / 1000000).toFixed(1) + 'M';
    } else if (parseInt(value) > 99999) {
      return parseFloat(value / 1000).toFixed(1) + 'K';
    } else {
      return value;
    }

  }
  function getAmountSapphire() {

    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/sapphire/getAmountSapphire/${sessionStorage.getItem('user_id')}`,
      headers: {
        /*     Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      /* data: temp, */
    })
      .then(async function (response) {
        setSapphireData(response.data || 0)

        /*  setuserprofile({
           ...userprofile,
           spaceshipcount: response.data
 
         })
         userprofile.spaceshipcount = response.data */



      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const [defconData, setDefconData] = useState([]);

  function getDefconAll() {

    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/defcon/getDefconAll`,
      headers: {
        /*     Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },

    })
      .then(async function (response) {
        setDefconData(response.data)

        for (let item of response.data) {
          item.defcon_file_path_box = null
          await getFileBoxByIdImage(item.fd_img_id, (result) => {
            if (result) {
              item.defcon_file_path_box = result.fileImg;
            }
          });
        }
        setDefconData(response.data)
        settest({ ...test, test: '' });
        test.test = '';
      })
      .catch(function (error) {
        console.log(error);
      });
  }




  function callBackModalSrs(value) {
    setModalSrs({ ...modalSrs, isOpen: value.isOpen, onMenu: value.onMenu, nameMenu: value.nameMenu })
    modalSrs.isOpen = value.isOpen
    modalSrs.onMenu = value.onMenu
    modalSrs.nameMenu = value.nameMenu
  }

  function setValueAndOpenView(value) {
    setTempValue({ user_id: value });
    tempValue.user_id = value;
    setPersonalInViewfoCheck({ isOpen: true });
    personalInfoViewCheck.isOpen = true;
    /*  toggle_view() */
    // console.log('testlog value', value);
    /*        setTest({test:''});
           test.test = ''; */


  }


  function personalInfo(mode) {
    // let isTrue = !personalInfoCheck.isOpen

    // console.log('mode', mode);
    setPersonalInfoCheck({
      ...personalInfoCheck,
      isOpen: true,
      submode: mode !== undefined ? mode : null
    })
    personalInfoCheck.isOpen = true
    personalInfoCheck.submode = mode !== undefined ? mode : null
  }

  const [DatareportMapingUser, setDatareportMapingUser] = useState({
    columns: [],
    rows: [],
    color: {}
  });




  async function returncolordefcon(color, isuse, defcon_id) {
    let temp = {
      project_id: projectActive.pf_project_id,
      defcon_id: defcon_id,

    }
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/updateProjectDefcon`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp

    })
      .then(async function (response) {
        setmodeldefcon({ ...modeldefcon, isOpen: false })
        modeldefcon.isOpen = false
        setcolordefcon({
          ...colordefcon,
          color: color,
          isuse: isuse
        })
        colordefcon.color = color;
        colordefcon.isuse = isuse;
        socketRef.current.emit('defcon', { message: 'change Defcon', room: "PROJECT-DEFCON:" + projectActive.pf_project_id, project_id: projectActive.pf_project_id, defcon_color: color, defcon_is_use: isuse });
      })
      .catch(function (error) {

      });



  }

  async function reportMapingUser(id, mode, summaryofchange, check) {
    let aslist = {
      id: id
    }


    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/reportMapingUser`,
      headers: {
        /*  Authorization: getToken(), */
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: aslist

    })
      .then(async function (response) {
        // console.log(response.data);



        let headTable = response.data.headTable, color = response.data.color, loop = response.data.loop, name = response.data.name

        // console.log(color);

        const excelData = [
          [{ v: `User Role Management`, t: "s", s: { font: { sz: 20, bold: true }, alignment: { horizontal: 'center' }, fill: { fgColor: { rgb: `${color.header}` } } } }],
          [{ v: ``, t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center' } } }],
          [{ v: `เลือกให้ User เห็นแค่เมนูเฉพาะที่เลือกเท่านั้น`, t: "s", s: { font: { sz: 13 }, alignment: { horizontal: 'left' }, fill: { fgColor: { rgb: `${color.header}` } } } }],
          [{ v: `1. เลือก ✔ เฉพาะเมนูที่สามารถใช้งานได้`, t: "s", s: { font: { sz: 13 }, alignment: { horizontal: 'left' }, fill: { fgColor: { rgb: `${color.header}` } } } }],
          [{ v: `2. เลือก ✘ เฉพาะเมนูที่ไม่สามารถใช้งานได้`, t: "s", s: { font: { sz: 13 }, alignment: { horizontal: 'left' }, fill: { fgColor: { rgb: `${color.header}` } } } }],
          [{ v: ``, t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center' } } }],

        ];
        const wscols = [
        ];
        let header = [], count = 0

        const maxTextWidth = headTable.reduce((maxWidth, row) => {
          const cellText = String(row);
          const cellWidth = cellText.length;
          return Math.max(maxWidth, cellWidth);
        }, 0);
        console.log(maxTextWidth);


        for (let h of headTable) {
          let cellLength = String(h.label).length * 1.6;

          if (!wscols[count] || cellLength > wscols[count].width) {
            if (count > 0) {
              if (16 > cellLength) {
                wscols[count] = { width: 16 };
              } else wscols[count] = { width: cellLength };

            } else wscols[count] = { width: 9 };

          }


          header.push({
            v: h.label, t: "s",
            s: { font: { sz: 13, bold: true }, alignment: { horizontal: 'center' }, fill: { fgColor: { rgb: `${color.header}` } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } }
          }
          )
          count += 1

        }
        excelData.push(header)

        const checkIfContainsDot = (str) => {
          return str.includes('.');
        };

        function lightenHexColor(hex, percent) {
          // Ensure the hex code is 6 characters long
          if (hex.length === 3) {
            hex = hex.split('').map(char => char + char).join('');
          }

          // Convert hex to RGB
          let r = parseInt(hex.slice(0, 2), 16);
          let g = parseInt(hex.slice(2, 4), 16);
          let b = parseInt(hex.slice(4, 6), 16);

          // Increase each component by the specified percentage
          r = Math.min(255, Math.floor(r + (255 - r) * percent / 100));
          g = Math.min(255, Math.floor(g + (255 - g) * percent / 100));
          b = Math.min(255, Math.floor(b + (255 - b) * percent / 100));

          // Convert RGB back to hex
          const newHex = [r, g, b].map(component => {
            const hexComponent = component.toString(16);
            return hexComponent.length === 1 ? '0' + hexComponent : hexComponent;
          }).join('');

          return newHex;
        }
        // console.log(color.header);

        const lightColor = lightenHexColor(color.header, 65);
        count = 0;
        for (let c of loop) {
          let col = []
          let countcuting = c.no.toString().split('.')
          const haveDot = checkIfContainsDot(c.no.toString());
          headTable.map((head, i) => {
            console.log(c);
            if (head.field === "no" || head.field === "menu" || head.field === "alluser") {
              let text = c[head.field]
              let cellLength = String(text).length * 1.116;

              if (!wscols[i] || cellLength > wscols[i].width) {
                wscols[i] = { width: cellLength };
              }

              if (!haveDot) {
                col.push({
                  v: text || '', t: "s",
                  s: { font: { sz: 13/* , bold: true */ }, alignment: { horizontal: `${countcuting.length > 1 && head.field === "no" ? 'center' : 'left'}` }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } }, fill: { fgColor: { rgb: `${lightColor}` } } }
                })
              } else {
                col.push({
                  v: text || '', t: "s",
                  s: { font: { sz: 13/* , bold: true */ }, alignment: { horizontal: `${countcuting.length > 1 && head.field === "no" ? 'center' : 'left'}` }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } }
                })
              }
            } else {
              if (head.field === c[head.field]) {
                if (!haveDot) {
                  col.push({
                    v: '✔', t: "s",
                    s: { font: { sz: 13 }, alignment: { horizontal: 'center' }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } }, fill: { fgColor: { rgb: `${lightColor}` } } }
                  }
                  )
                } else {
                  col.push({
                    v: '✔', t: "s",
                    s: { font: { sz: 13 }, alignment: { horizontal: 'center' }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } }, }
                  }
                  )
                }
              } else {
                if (!haveDot) {
                  col.push({
                    v: '', t: "s",
                    s: { font: { sz: 13 }, alignment: { horizontal: 'center' }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } }, fill: { fgColor: { rgb: `${lightColor}` } } }
                  }
                  )
                } else {
                  col.push({
                    v: '', t: "s",
                    s: { font: { sz: 13 }, alignment: { horizontal: 'center' }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } }
                  }
                  )
                }
              }

            }

          })
          count += 1;
          // console.log(`${count}/ col-->:`, col);
          excelData.push(col)
        }

        const merges = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: (headTable.length) - 1 } },
          { s: { r: 2, c: 0 }, e: { r: 2, c: (headTable.length) - 1 } },
          { s: { r: 3, c: 0 }, e: { r: 3, c: (headTable.length) - 1 } },
          { s: { r: 4, c: 0 }, e: { r: 4, c: (headTable.length) - 1 } },
        ];

        const ws = XLSX.utils.aoa_to_sheet(excelData);
        const wb = XLSX.utils.book_new();

        ws['!cols'] = wscols;

        merges.forEach(merge => {
          ws['!merges'] = ws['!merges'] || [];
          ws['!merges'].push(merge);
        });

        XLSX.utils.book_append_sheet(wb, ws, 'Project Data');

        if (mode === 'version') {
          const base = XLSX.write(wb, { type: 'base64' });
          const binaryData = atob(base);
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([uint8Array], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          const file = new File([blob], `User Mapping ${name} ${moment(new Date()).add('year', 543).format('DD_MM_YY')}.xlsx`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/report/saveversiondoc`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              'X-TTT': `${Configs.API_TTT}`,
              'Content-Type': 'application/json',
            },
            data: {
              project_id: id,
              /*  path_file: response.data.data[0].path, */
              file_name: file.name,
              template_type: 'user_map',
              dv_summary_of_change: summaryofchange || null

            }

          })
            .then(function (response) {
              if (response.data) {
                CheckAndCreateFolderBox(response.data.data, response.data.olddata, file, id)

              }


            })
            .catch(function (error) {
              console.log(error);

            });














          /*  const dataImg = new FormData();
           dataImg.append('Profile', file);
           dataImg.append('typeFile', 'document_version');
           dataImg.append('projectKey', "tttspaceship");
           await axios({
             method: 'post',
             url: `${Configs.TTT_Game_Service}/api/upload/document2`,
             headers: {
              
               'X-TTT': `${Configs.API_TTT}`,
               'Content-Type': 'application/octet-stream',
             },
             data: dataImg,
           })
             .then(async function (response) {
             
             })
             .catch(function (error) {
               console.log(error);
             });
  */



        } else {
          XLSX.writeFile(wb, `User Mapping ${name} ${moment(new Date()).format("DD-MM-YYYY")}.xlsx`);
          if (check === undefined) {
            toggle_modal_alertKick()
            setAlertKick(false)
          } else {
            let data = alertKickall.data
            data.push('')
            setAlertKickall({ ...alertKickall, data: data })
            alertKickall.data = data
            if (alertKickall.data.length === 3) {
              toggle_modal_alertKick()
              setAlertKick(false)

            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        /*  Swal.fire({
           icon: "success",
           title: error,
           showConfirmButton: false,
           timer: 1500,
       }) */
      });




  }


  async function CheckAndCreateFolderBox(data, olddata, file, id) {
    let temp = {
      folderLis: ["PROJECT", `PROJECT_${id}`, "PROJECT DOCUMENTS", "USER MAPING"]
    }
    /*  temp.folderLis.push(folder_name) */
    await axios({
      method: 'post',
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(async function (response) {
        if (response.data) {

          data.files = file
          await UploadFiles(data, response.data.folder_id, olddata)

        }
      })
      .catch(function (error) {

        console.log(error);
      });
  }



  async function UploadFiles(item, folder_id, olddata) {
    console.log(item);
    const dataImg = new FormData();
    dataImg.append('File', item.files);
    dataImg.append('folder_id', folder_id);
    dataImg.append('key', "USER MAPING");
    dataImg.append('image_old_list', JSON.stringify(olddata));
    dataImg.append('ref_id', item.id);

    await axios({
      method: 'post',
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "multipart/form-data",
      },
      data: dataImg
    })
      .then(async function (response) {
        if (response.data) {
          if (response.data.image_id_list.length > 0) {
            setErrorMessage({ ...errorMessage, message: 'Success', status: false });
            afterSaveVersion()
          }

        }
      })
      .catch(function (error) {

        console.log(error);
      });
  }












  const [eventChange, setEventChange] = useState(false)
  const afterSaveVersion = () => {
    setEventChange(true)
    setTimeout(() => {
      setEventChange(false)
    }, 500);
  }


  const [pathProfile, setPathProfile] = useState(null);
  function setImageProfile(path) {
    setPathProfile(path)
  }

  async function reportProjectTimeline(id, mode, summaryofchange, check) {
    let aslist = {
      id: id
    }

    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/reportProjectTimeline`,
      headers: {
        /*  Authorization: getToken(), */
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: aslist

    })
      .then(async function (response) {

        console.log("response.data555", response.data);
        let callback = await ProjectTimelinereport(response.data, id, mode, summaryofchange)



        if (mode === 'version') {
          console.log(callback);
          setErrorMessage({ ...errorMessage, message: 'Success', status: false });
          afterSaveVersion()
        } else {
          if (check === undefined) {
            toggle_modal_alertKick()
            setAlertKick(false)
          } else {
            let data = alertKickall.data
            data.push('')
            setAlertKickall({ ...alertKickall, data: data })
            alertKickall.data = data
            if (alertKickall.data.length === 3) {
              toggle_modal_alertKick()
              setAlertKick(false)

            }
          }

        }
      })
      .catch(function (error) {
        console.log(error);
        /*  Swal.fire({
           icon: "success",
           title: error,
           showConfirmButton: false,
           timer: 1500,
       }) */
      });




  }


  async function reporttest(id, mode, summaryofchange, check) {

    let aslist = {
      id: id
    }

    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/srsreport`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: aslist

    })
      .then(async function (response) {


        let callback = await testreport(response.data, id, mode, summaryofchange)



        if (mode === 'version') {
          console.log(callback);
          setErrorMessage({ ...errorMessage, message: 'Success', status: false });
          afterSaveVersion()
        } else {
          if (check === undefined) {
            toggle_modal_alertKick()
            setAlertKick(false)
          } else {
            let data = alertKickall.data
            data.push('')
            setAlertKickall({ ...alertKickall, data: data })
            alertKickall.data = data
            if (alertKickall.data.length === 3) {
              toggle_modal_alertKick()
              setAlertKick(false)

            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        /*  Swal.fire({
           icon: "success",
           title: error,
           showConfirmButton: false,
           timer: 1500,
       }) */
      });




  }

  async function reportallseting(id, mode, summaryofchange, mr, check) {
    let aslist = {
      id: id,
      modereport: mr
    }


    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/reportallseting`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: aslist

    })
      .then(async function (response) {
        // console.log(response.data);
        let seting = {
          pagesarabar: true,
          insertsarabar: 2,
          pageversion: true,
          insertversion: 1,
          header: true,
          footer: true,
          mode: mr

        }
        let callback = await reportallsetingfunc(seting, response.data, id, mode, summaryofchange)
        console.log(callback);
        if (mode === 'version') {
          setErrorMessage({ ...errorMessage, message: 'Success', status: false });
          afterSaveVersion()

        } else {
          if (check === undefined) {
            toggle_modal_alertKick()
            setAlertKick(false)
          } else {
            let data = alertKickall.data
            data.push('')
            setAlertKickall({ ...alertKickall, data: data })
            alertKickall.data = data
            if (alertKickall.data.length === 3) {
              toggle_modal_alertKick()
              setAlertKick(false)

            }

          }
        }
      })
      .catch(function (error) {
        console.log(error);
        /*  Swal.fire({
           icon: "success",
           title: error,
           showConfirmButton: false,
           timer: 1500,
       }) */
      });




  }




  /*  window.addEventListener('mousemove', (event) => {
     console.log(event.clientX,event.clientY)
   mousePos = { x: event.clientX, y: event.clientY };
   mousePosText.textContent = `(${mousePos.x}, ${mousePos.y})`;
 }); */
  const [riskIssueTab, setRiskIssueTab] = useState({
    is: 'ALL'
  })
  const [riskIssueGetData, setRiskIssueGetData] = useState([])
  const [checkMenu, setCheckMenu] = useState()
  const [riskIssueOpti, setRiskIssueOpti] = useState({
    firstRisk: true,
    firstIssue: true
  })

  function setRiskIssueTabFunc(value, menu) {
    if (value != undefined && value != null) {
      setRiskIssueTab({ is: value })
      riskIssueTab.is = value
    }

    getRiskIssueData(menu, 'SEND',)
  }

  async function getRiskIssueData(onAMenu, onSend, project_id, tab) {
    // console.log('testlog', tab);
    if (onAMenu === 'RISK' && riskIssueOpti.firstRisk) {
      let temp = {
        pri_project_id: project_id ? project_id : projectActive.pf_project_id || null,
        onAMenu: onAMenu,
        current_page: 1,
        page_size: 99999,
        tab: tab || riskIssueTab.is,
        filelist: []
      }



      await axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/rickissue/getData`,
        headers: {
          /*  Authorization: getToken(), */
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
        data: temp
      })
        .then(async function (response) {
          setCheckMenu(onAMenu)
          setRiskIssueGetData(response.data)
          setRiskIssueOpti({ ...riskIssueOpti, firstRisk: false })
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (onAMenu === 'ISSUE' && riskIssueOpti.firstIssue) {
      let temp = {
        pri_project_id: project_id ? project_id : projectActive.pf_project_id || null,
        onAMenu: onAMenu,
        current_page: 1,
        page_size: 99999,
        tab: tab || riskIssueTab.is,
        filelist: []
      }
      await axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/rickissue/getData`,
        headers: {
          /*  Authorization: getToken(), */
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
        data: temp
      })
        .then(async function (response) {
          setCheckMenu(onAMenu)
          setRiskIssueGetData(response.data)
          setRiskIssueOpti({ ...riskIssueOpti, firstIssue: false })
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (onSend === 'SEND') {
      // console.log('testlog s', riskIssueTab.is);
      let temp = {
        pri_project_id: project_id ? project_id : projectActive.pf_project_id || null,
        onAMenu: onAMenu,
        current_page: 1,
        page_size: 99999,
        tab: tab || riskIssueTab.is,
        filelist: []
      }
      await axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/rickissue/getData`,
        headers: {
          /*  Authorization: getToken(), */
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
        data: temp
      })
        .then(async function (response) {
          setCheckMenu(onAMenu)
          setRiskIssueGetData(response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }


  const [riskIssueType, setRiskIssueType] = useState({
    type: 'RISK',
  })

  function setFetchStatus(value) {
    setRiskIssueType({ ...riskIssueType, type: value })
    riskIssueType.type = value
  }



  function openModals(value) {
    if (projectActive.pf_project_id != '' && projectActive.pf_project_id != null && projectActive.pf_project_id != undefined) {
      switch (value) {
        case 'close':
          socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'ProjectInfomation'});
          setOpenModal('open');
          if (param.setMenuValue != undefined) {
            param.setMenuValue(true)
          }
          break;
        default:
          socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'ProjectInfomation'});
          setOpenModal('close');
          if (param.setMenuValue != undefined) {
            param.setMenuValue(false)
          }
          break;
      }
    } else {
      Swal.fire(
        'warning!',
        'Please select a project.',
        'warning'
      )
      /*    Swal.fire({
           title: 'Are you sure?',
           text: "You won't be able to revert this!",
           icon: 'warning',
           showCancelButton: true,
           confirmButtonColor: '#3085d6'
         }) */
      setOpenModal('close');
    }


  }
  async function GetAccesstokenToken(callback) {
    await axios({
      method: "post",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
      headers: {
        /* Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        var temp = {
          tokenBox: response.data.tokenBox
        }
        if (callback) {
          callback(response.data.tokenBox);
        }
        sessionStorage.setItem('tokenBox', response.data.tokenBox)
        /*  console.log('ViewToken', response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  async function callBackFucEditModal(value) {
    GetAccesstokenToken(async (result_) => {
      /*  console.log('ตัวที่กลับมา', value); */
      /*  if (value.on === 'version') { */
      setErrorMessage({ ...errorMessage, message: '', status: true });
      toggle_modal_alertKick();
      /*   } */
      if (value.mode === 'project_plan') {
        reportProjectTimeline(value.id, value.on, value.dv_summary_of_change || null)
      }
      if (value.mode === 'user_map') {
        reportMapingUser(value.id, value.on, value.dv_summary_of_change || null)
      }

      if (value.mode === 'srs') {
        reporttest(value.id, value.on, value.dv_summary_of_change || null)
      }

      if (value.mode === 'TEST RESULT') {
        reportallseting(value.id, value.on, value.dv_summary_of_change || null, value.mode)
      }
      if (value.mode === 'UAT') {
        reportallseting(value.id, value.on, value.dv_summary_of_change || null, value.mode)
      }

      if (value.mode === 'FINAL SUMMARY') {
        reportallseting(value.id, value.on, value.dv_summary_of_change || null, value.mode)
      }






      if (value.mode === 'all') {
        await reportProjectTimeline(value.id, value.on, value.dv_summary_of_change || null, 'all')
        await reportMapingUser(value.id, value.on, value.dv_summary_of_change || null, 'all')
        await reportallseting(value.id, value.on, value.dv_summary_of_change || null, 'TEST RESULT', 'all')
        /* reportallseting(value.id, value.on, value.dv_summary_of_change || null, 'UAT') */
        await reportallseting(value.id, value.on, value.dv_summary_of_change || null, 'FINAL SUMMARY', 'all')

      }
    });
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }

  const [chating, setChating] = useState({
    isOpen: false
  });

  function openChat() {
    // console.log('testlog',chating);
    setChating({ ...chating, isOpen: true })
    chating.isOpen = true
    // console.log('testlog',chating);
  }

  /*   const [commentReport, setCommentReport] = useState({
      isOpen: false
    });
   */
  const [commentReport, setCommentReport] = useState(false);
  const toggle_modal_commentReport = () => setCommentReport(!commentReport);
  const [ModalTopUp, setModalTopUp] = useState(false);
  const toggle_modal_ModalTopUp = () => setModalTopUp(!ModalTopUp);
  const [templateComment, setTemplateComment] = useState({
    tp_id: '',
    tc_id: '',
  })
  function openCommentReport() {
    toggle_modal_commentReport()
  }


  const [mailUnReadCount, setMailUnReadCount] = useState(0);

  async function GetAccesstokenToken(callback) {
    axios({
      method: "post",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
      headers: {
        /* Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        var temp = {
          tokenBox: response.data.tokenBox
        }
        if (callback) {
          callback(response.data.tokenBox);
        }
        sessionStorage.setItem('tokenBox', response.data.tokenBox)
        /*  console.log('ViewToken', response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  async function getFileBoxByIdImage(image_id, callback) {

    await axios({
      method: "get",
      url: `https://api.box.com/2.0/files/${image_id}/content`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      },
      responseType: 'blob',

    })
      .then(async function (response) {

        if (callback) {
          let tmp = {
            fileImg: URL.createObjectURL(response.data),
            file_id: image_id,

          }
          callback(tmp);
        }

      }).catch((err) => {

        if (callback) {
          let tmp = {
            fileImg: null,
            file_id: null,

          }
          callback(tmp);
        }
      })

  }


  const [stockImage, setStockImage] = useState([]);

  async function getMailByUser() {

    await axios({
      method: 'GET',
      url: `${Configs.TTT_Game_Notification_Service}/api/notification/getNotification/${sessionStorage.getItem('user_id')}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

    })
      .then(async function (response) {

        // console.log('testlog res', response.data);
        let arr = [];
        for (let item of response.data) {
          let temp = {
            noti_id: item.noti_id,
            header: item.noti_header,
            body: item.noti_body,
            sendBy: item.noti_user_name,
            sendById: item.noti_user_id,
            sendTime: item.noti_created_date,
            type: item.noti_menu_name,
            noti_spaceship_id: item.noti_spaceship_id,
            noti_project_id: item.noti_project_id,
            noti_link_to: item.noti_link_to,
            noti_is_read: item.noti_is_read,
            projectName: item.project_name,
            userProfile: item.ul_file_path ? item.ul_file_path : null,
            sendSpaceshipName: item.spaceship_name,
            spaceshipProfile: item.spaceshipProfile,
            spaceshipId: item.spaceship_id,
            createDate: item.noti_created_date,
            noti_user_id: item.noti_user_id,
            img_usr_profile_id: item.usr_profile_id || null,
            img_sps_profile_id: item.sps_profile_id || null,
            img_sps_profile_model_id: item.sps_profile_model_id || null,
          };
          arr.push(temp)
        }

        let checkRead = arr.filter((e) => { return e.noti_is_read == false || e.noti_is_read == null });
        setMailUnReadCount(checkRead.length);
        setMailList(arr);

        await GetAccesstokenToken(async (result_) => {
          let arr_stock_img = stockImage || [];
          let index = 0;
          for await (let item2 of arr) {
            /*      console.log('NNN index',index) */
            item2.index = index;
            /*    console.log('img_usr_profile_id',item2.img_usr_profile_id) */
            let ref_id = item2.img_usr_profile_id;
            if (ref_id) {
              let check = arr_stock_img.filter((e) => { return e.img_id == ref_id });
              if (check.length > 0) {
                console.log('NNN มีรูปนี้แล้ว', index);
                item2.usr_profile = check[0].img_file;

              } else {
                /*         console.log('NNN ไม่มีรูปนี้',index); */

                console.log('NNN start person', item2.index)
                await getFileBoxByIdImage(ref_id, (result) => {
                  if (result) {
                    let stockImg = {
                      img_id: ref_id,
                      img_file: result.fileImg
                    }
                    arr_stock_img.push(stockImg);
                    item2.usr_profile = result.fileImg;
                    /*            let checkRead = arr.filter((e) => { return e.noti_is_read == false || e.noti_is_read == null });
                               setMailUnReadCount(checkRead.length); */
                    console.log('NNN finish person', item2.index, result.fileImg)

                  }
                });

              }



            }

            let ref_id2 = item2.img_sps_profile_id ? item2.img_sps_profile_id : item2.img_sps_profile_model_id;
            if (ref_id2) {
              let check = arr_stock_img.filter((e) => { return e.img_id == ref_id2 });
              if (check.length > 0) {
                console.log('NNN ยานมีรูปนี้แล้ว', index);
                item2.sps_profile = check[0].img_file;

              } else {
                console.log('NNN start sps', item2.index)

                await getFileBoxByIdImage(ref_id2, (result) => {
                  if (result) {
                    let stockImg = {
                      img_id: ref_id2,
                      img_file: result.fileImg
                    }
                    arr_stock_img.push(stockImg);
                    item2.sps_profile = result.fileImg;

                    console.log('NNN finish sps', item2.index, result.fileImg)
                    /*    let checkRead = arr.filter((e) => { return e.noti_is_read == false || e.noti_is_read == null });
                       setMailUnReadCount(checkRead.length);
                       setMailList(arr); */
                  }
                });
              }
            }
            if (index + 1 == arr.length) {
              console.log('NNN ALL FINISH')
              setMailList(arr);
              setStockImage(arr_stock_img);
              settest({ ...test, test: '' });
              test.test = '';
            }
            index++;


          }

        })





        /*         setCheckMenu(onAMenu)
                setRiskIssueGetData(response.data) */
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function updateReadMail(noti_id) {
    await axios({
      method: 'GET',
      url: `${Configs.TTT_Game_Notification_Service}/api/notification/ReadNotification/${noti_id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

    })
      .then(async function (response) {

        getMailByUser();

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const [spaceShipFed, setSpaceShipFed] = useState([]);
  async function getOnlineSapceshipInFed(fed_id) {
    /*     ${sessionStorage.getItem('fed_id')} */
    // dev 4311c904-b72d-44c3-918d-c8466bf9afdd

    // prod b9a184f3-fb8e-44c6-a4db-f0b90571ef43
    /*   fed_id = 'b9a184f3-fb8e-44c6-a4db-f0b90571ef43'; */
    await axios({
      method: 'GET',
      url: `${Configs.TTT_Game_Service}/api/federation/getOnlineSapceship/${sessionStorage.getItem('fed_id')}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

    })
      .then(async function (response) {

        setSpaceShipFed(response.data);

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [toCardStage, setToCardStage] = useState(null);
  const socketRef = useRef(null);
  useEffect(() => {

    if (socketRef.current == null) {
      socketRef.current = io(Configs.TTT_Game_Socket_Service, {
        //path: '/Socket-service/socket.io'
        port: Configs.TTT_Game_Socket_Service_Port
      });
    }
  }, [

  ])

  useEffect(() => {
    setClearClassname(false)
    if (key && (key === 'spaceshipdetail' || key == 'detait')) {
      setmode({ ...mode, mode: 'detait' });
      mode.mode = 'detait';
    } else if (key && (key === 'myproject' || key == 'package')) {
      setmode({ ...mode, mode: 'package' });
      mode.mode = 'package';
      toggle_modal_Package()
    } else if (key && key == 'fedSpaceShip') {
      setmode({ ...mode, mode: 'fedSpaceShip' });
      mode.mode = 'fedSpaceShip';
      setFedSpaceShipId({ ...fedSpaceShipId, id: fedSpsId });
    } else if (key && key == 'fedSpaceShipList') {
      setmode({ ...mode, mode: 'fedSpaceShipList' });
      mode.mode = 'fedSpaceShipList';
      setFedSpaceShipId({ ...fedSpaceShipId, id: fedSpsId });
    } else if (key && key == 'projcet') {
      setmode({ ...mode, mode: 'projcet' });
      mode.mode = 'projcet';
      setFedSpaceShipId({ ...fedSpaceShipId, id: fedSpsId });
    } else if (key && key == 'all' && spaceship_id != '') {
      setmode({ ...mode, mode: 'all' });
      mode.mode = 'all';
      setStageProject(spaceship_id);
    } else {
      socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'StageMonitoring'});
      setmode({ ...mode, mode: 'all' });
      mode.mode = 'all';
    }

    if (projectKey && menuKey && processKey) {
      let getUser_id = sessionStorage.getItem('user_id');
      localStorage.setItem("projectAct", JSON.stringify({ project_id: projectKey, user_on_project: getUser_id }));
      reloadSlectProject();
      setToCardStage({
        projectKey: projectKey,
        menuKey: menuKey,
        processKey: processKey,
        indexKey: indexKey
      });


    }


    getMailByUser();
  
   /*  getElementByLocationAndUser('StageMonitoring',null) */
    if (param.display === 'package') {
      toggle_modal_Package()
      if (modalDetail === true) {
        toggle_modal_Detail()
      }
    }
    /*  callBack() */
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };




  }, [])



 

  /*   useEffect(() => {
        console.log('onRoom', "PROJECT-CHAT:" + getProjectId.pf_project_id);
        if (isOpen === true) {
  
            socketRef.current.emit('leave', "PROJECT-CHAT:" + getProjectId.pf_project_id)
            socketRef.current.emit('join', "PROJECT-CHAT:" + getProjectId.pf_project_id)
  
  
        } else {
            socketRef.current.emit('leave', 'PROJECT-CHAT:' + getProjectId.pf_project_id)
            socketRef.current.emit('leave', 'PROJECT-CHAT:' + getProjectId.pf_project_id)
        }
    }, [isOpen]) */

  const [federationDetail, setFederationDetail] = useState({
    fed_id: '',
    fed_img_path: null,
    fed_name: '',
    fed_spaceship_count: 0
  });

  const [federationList, setFederationList] = useState([]);
  const [isOpenFederationList, setIsOpenFederationList] = useState(false);
  const toggle_open_fed_list = () => { setIsOpenFederationList(!isOpenFederationList) };





  async function getFederationDetailByOwner() {
    socketRef.current.emit('leaveOne', "Federation:" + federationDetail.fed_id);
    // console.log('เข้าวววววววววว');
    await axios({
      method: 'GET',
      url: `${Configs.TTT_Game_Service}/api/federation/getFederationByOwner/${sessionStorage.getItem('user_id')}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

    })
      .then(async function (response) {
        if (response.data.length > 0) {
          let fed_select_id = sessionStorage.getItem('fed_id');
          let check = response.data.filter((e) => { return e.federation_id == fed_select_id });
          setFederationList(response.data);
          setFederationDetail({
            ...federationDetail,
            fed_id: check.length > 0 ? check[0].federation_id : response.data[0].federation_id,
            fed_img_path: check.length > 0 ? check[0].image_path || null : response.data[0].image_path ? response.data[0].image_path : null,
            fed_name: check.length > 0 ? check[0].federation_name : response.data[0].federation_name,
            fed_spaceship_count: check.length > 0 ? check[0].spaceShip_list.length : response.data[0].spaceShip_list.length,
          });
          sessionStorage.setItem('fed_id', check.length > 0 ? check[0].federation_id : response.data[0].federation_id);
          federationDetail.fed_id = check.length > 0 ? check[0].federation_id : response.data[0].federation_id;
          federationDetail.fed_img_path = check.length > 0 ? check[0].image_path || null : response.data[0].image_path ? response.data[0].image_path : null;
          federationDetail.fed_name = check.length > 0 ? check[0].federation_name : response.data[0].federation_name;
          federationDetail.fed_spaceship_count = check.length > 0 ? check[0].spaceShip_list.length : response.data[0].spaceShip_list.length;


          socketRef.current.emit('join', "Federation:" + check.length > 0 ? check[0].federation_id : response.data[0].federation_id);
          socketRef.current.emit('Federation', { message: 'Fetch', room: "Federation:" + check.length > 0 ? check[0].federation_id : response.data[0].federation_id });
          toggle_freshSelectProjectByFed();


          await GetAccesstokenToken(async (result_) => {
            let index = 0;
            for (let item of response.data) {
              await getFileBoxByIdImage(item.image_profile_id, (result) => {
                if (result) {
                  if (fed_select_id == item.federation_id || index == 0) {
                    setFederationDetail({
                      ...federationDetail,
                      fed_img_file: result.fileImg
                    });
                    federationDetail.fed_img_file = result.fileImg;
                  }
                  item.img_profile = result.fileImg;

                }
              })
              index++;
            }
            setFederationList(response.data);


          })


        }
        /*         setSpaceShipFed(response.data); */

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [soltLocationData,setSoltLocationData] = useState({
    key:''
  });

  const [getKeyItem, setGetKeyItem] = useState({
    key: '',
    id: '',
    user: sessionStorage.getItem('user_id'),
    status:false
})
const [slotElementList, setSlotElementList] = useState([])

  useEffect(() => {
    sessionStorage.setItem('pageNow','StageMonitoring')
    setClearClassname(false)
    getFederationDetailByOwner();
    getcountSpaceship();
    getAmountSapphire();
    getDefconAll();
    /*     if(getProjectId !== '' ){ */




    const { current: socket } = socketRef;
    const socFnc = (data) => {
      /*  console.log('onRoom', data) */
      if (data.reSapphir !== undefined && data.reSapphir == true) {
        getAmountSapphire()
        setIsRefsSkill(true);
      } else {
        setIsRefsSkill(true);
        getMailByUser();
      }


      //getChatData(data.project_id);
      /*     socket.emit('leave',data.room) */
    }
    const socFncBan = (data) => {
      console.log('onRoomBan', data)
      setIsRefsSkill(true)
    }
    const socFncBan2 = (data) => {
      setSoltLocationData({...soltLocationData,
        key:''
      })
      console.log('key Room', data)
    }
    const socFederation = (data) => {
      console.log("fetch federation", data);
      getOnlineSapceshipInFed();
      setIsRefsSkill(true)
    }

    const socDefconFnc = (data) => {
      /* console.log('defconDATA', data) */
      setOpenModalSelectProject(!openModalSelectProject);
      setcolordefcon({
        ...colordefcon,
        color: data.defcon_color,
        isuse: data.defcon_is_use
      })
      colordefcon.color = data.defcon_color;
      colordefcon.isuse = data.defcon_is_use;

      /* getMailByUser(); */
      //getChatData(data.project_id);
      /*     socket.emit('leave',data.room) */
    }
    const slotElFnc = async (data) => {
      setGetKeyItem({...getKeyItem,
          key: data.message
      });
      getKeyItem.key = data.message;
    /*  await getElementByLocationAndUser(data.message, data.id || null); */
  }

    try {
      socket.open();
      //console.log('onMenu', 'เข้าEffectแล้ว', activeRoom.room, 'RISK93bf93f2-eb48-4bf7-b1bc-1305cdc13fad')
      /* socket.emit('join','RISK93bf93f2-eb48-4bf7-b1bc-1305cdc13fad') */

      socket.emit('online', sessionStorage.getItem('user_id'));
      socket.emit('join', "BanMember" + sessionStorage.getItem('user_id'));
      /* socket.emit('join', "locationElement" + sessionStorage.getItem('user_id'));
      socket.on('locationElement', slotElFnc) */
      socket.on('mailbox', socFnc)
      socket.on('BanMember', socFncBan)
      socket.on('defcon', socDefconFnc)
      socket.on('Federation', socFederation)

     

      /*     socket.on('online_update', socFnc) */

      /*  setActiveRoom({...activeRoom,room:'93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'});
          activeRoom.room = '93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'; */
    } catch (error) {
      console.log(error);
    }
    // Return a callback to be run before unmount-ing.

    return () => {
      /*   console.log('onRoom','out') */
      socket.off("chat", socFnc);

      socket.close();
      /*     setActiveRoom({ ...activeRoom, room: '' });
          activeRoom.room = ''; */
    };
    /* } */



  }, []);
  /* 
    useEffect(()=>{
      console.log('INNNN',federationDetail.fed_id)
      if (federationDetail.fed_id) {
       
  
        socketRef.current.emit('leaveOne', "Federation:" + federationDetail.fed_id );
        socketRef.current.emit('join', "Federation:" + federationDetail.fed_id );
  
  
    }
    },[federationDetail.fed_id]) */

  const [riskIssueCount, setRiskIssueCount] = useState({
    riskCount: 0,
    issueCount: 0
  })
  async function getCountRiskIssue(project_id) {
    await axios({
      method: 'GET',
      url: `${Configs.TTT_Game_Service}/api/rickissue/getRiskIssueCount/${project_id}`,
      headers: {
        //Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

    })
      .then(async function (response) {

        setRiskIssueCount({ ...riskIssueCount, riskCount: response.data.riskcount, issueCount: response.data.issuecount });

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function callBack(value) {
    getCountRiskIssue(value.project_id);
    /*  console.log('ตัวที่กลับมาเเล้ว', value); */
    ///socketRef.current.emit('leaveOne', "PROJECT-DEFCON:" + projectActive.pf_project_id)
    // console.log('testlog value', value);
    
    setProjectActive({
      ...projectActive,
      pf_project_id: value.project_id,
      project_name: value.project_name,
      project_progress: value.project_progress === 'NaN' ? 0 : value.project_progress,
      spaceship_id: value.spaceship_id,
      spaceship_size: value.spaceship_size,
      spaceship_name: value.spaceship_name,
      pp_start_date: value.pp_start_date,
      pp_finish_date: value.pp_finish_date,
      federation_name: value.federation_name,
      pp_team_member: value.pp_team_member,
      ul_file_name: value.ul_file_name,
      ul_file_path: (value.ul_file_path != '' && value.ul_file_path != null) ? `${Configs.TTT_Game_Service_IMG}${value.ul_file_path}` : null,
      isLeader: value.isLeader,
      url: value.url
    })
    /*   console.log('onRoom', "PROJECT-DEFCON:" + value.project_id); */

    socketRef.current.emit('join', "PROJECT-DEFCON:" + value.project_id)

    setOpenModal('close')
    /*  console.log('color',value.defcon_level) */
    setcolordefcon({
      ...colordefcon,
      color: value.defcon_color,
      isuse: value.defcon_level == 4 || value.defcon_level == 5 ? false : true
    })
    colordefcon.color = value.defcon_color;
    colordefcon.isuse = value.defcon_level == 4 || value.defcon_level == 5 ? false : true;

    setRiskIssueOpti({ ...riskIssueOpti, firstRisk: true, firstIssue: true })
    /*  settest({test,test:''})
     test.test = '' */
  }

  function CallbackImg(value, name) {
    console.log('กลับเเล้ว', value, name);
    setFederationDetail({
      ...federationDetail,
      fed_img_path: value,
      fed_name: name
    })
    federationDetail.fed_img_path = value
    federationDetail.fed_name = name
    settest({ test: '' });
    test.test = '';
  }
  function callBackModalOp(value) {
    setOpenModal(value);
    setOpenModalSelectProject(!openModalSelectProject)
    settest({ test, test: '' })
    test.test = ''
  }

  const [isanimetion, setIsanimetion] = useState(true)
  // console.log('isanimetion', isanimetion);
  function setdelayswit() {
    const intervalClose = setInterval(() => {
      toggle_modal_Package()
      if (mode.mode === 'all') {
        /*    toggle_modal_Detail() */
      }
      if (mode.mode === 'detait') {

        setModalDetail(true)
      } else setModalDetail(false)
      if (mode.mode === 'package') {
        setModalPackage(true)

      } else setModalPackage(false)
      setIsanimetion(true)
      clearInterval(intervalClose);
    }, 500);
  }

  const [memberSize, setMemberSize] = useState({
    max: 6
  })
  const [memberInSpaceShip, setMemberInSpaceShip] = useState([])
  async function callBackMemberInSpaceShip(value) {
    let temp = [];
    for (let item of value) {
      temp.push(item.usr_id)
    }
    setMemberInSpaceShip(temp)
  }

  const [spacedata, setspacedata] = useState({
    id: null
  })


  async function callBackspace(id, model) {
    setspacedata({
      ...spacedata,
      id: id
    })
    spacedata.id = id

    setModalDetail(model)


  }

  const [historyDetail, setHistoryDetail] = useState([])
  function callbackHistory(value) {
    // console.log('testlog value', value);
    setHistoryDetail(value)
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }





  async function acceptToSpaceship(space_id, user_id) {
    /*  socketRef.current.emit('leave', "SPACESHIP:" + space_id) */

    socketRef.current.emit('join', "SPACESHIP:" + space_id)


    /* socketRef.current.emit('fecthMemberSpaceship', "SPACESHIP:" + space_id) */
    socketRef.current.emit('fecthMemberSpaceship', { message: 'new message', room: "SPACESHIP:" + space_id, spaceship_id: space_id });
    let temp = {
      space_id: space_id,
      user_id: user_id
    }
    await axios({
      method: 'POST',
      url: `${Configs.TTT_Game_Service}/api/spaceship/acceptInviteToSpaceship`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp
    })
      .then(async function (response) {

        socketRef.current.emit('leaveOne', "SPACESHIP:" + space_id)
        getMailByUser();

      })
      .catch(function (error) {
        console.log('ลงเเท');

        /* toggle_teamViewPopUpEr2(); */
        console.log('ลงเเท', error);
      });

  }
  async function deniedToSpaceship(space_id, user_id) {
    /*  socketRef.current.emit('leave', "SPACESHIP:" + space_id) */
    socketRef.current.emit('join', "SPACESHIP:" + space_id)


    /* socketRef.current.emit('fecthMemberSpaceship', "SPACESHIP:" + space_id) */
    socketRef.current.emit('fecthMemberSpaceship', { message: 'new message', room: "SPACESHIP:" + space_id, spaceship_id: space_id });
    let temp = {
      space_id: space_id,
      user_id: user_id
    }
    await axios({
      method: 'POST',
      url: `${Configs.TTT_Game_Service}/api/spaceship/deniedInviteToSpaceship`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp
    })
      .then(async function (response) {

        socketRef.current.emit('leaveOne', "SPACESHIP:" + space_id)
        getMailByUser();

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  async function acceptToProject(project_id, user_id, space_id, tempData) {
    /*  socketRef.current.emit('leave', "SPACESHIP:" + space_id) */
    socketRef.current.emit('join', "PROJECT_:" + project_id)

    console.log(project_id, user_id, space_id)


    let temp = {
      project_id: project_id || '',
      user_id: user_id || '',
      space_id: space_id || '',

    }
    await axios({
      method: 'POST',
      url: `${Configs.TTT_Game_Service}/api/project/acceptOfferToProject`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp
    })
      .then(async function (response) {
        addNotification(
          tempData.is_accept,
          tempData.user_id,
          tempData.user_name,
          tempData.spaceshipId,
          tempData.mode,
          tempData.project_id
        )
        /*   socketRef.current.emit('fecthMemberSpaceship', { message: 'new message', room: "SPACESHIP:" + space_id, spaceship_id: space_id }); */
        socketRef.current.emit('leaveOne', "PROJECT_:" + project_id)
        getMailByUser();

      })
      .catch(function (error) {
        console.log(error);
        /*     addNotification(
              tempData.is_accept,
              tempData.user_id,
              tempData.user_name,
              tempData.spaceshipId,
              tempData.mode,
              tempData.project_id
            )  */

        socketRef.current.emit('leaveOne', "PROJECT_:" + project_id)

        getMailByUser();
      });

  }
  async function deniedToProject(project_id, user_id, space_id, tempData) {
    socketRef.current.emit('join', "PROJECT_:" + project_id)

    console.log(project_id, user_id, space_id)


    let temp = {
      project_id: project_id || '',
      user_id: user_id || '',
      space_id: space_id || '',

    }

    await axios({
      method: 'POST',
      url: `${Configs.TTT_Game_Service}/api/project/deniedToProject`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp
    })
      .then(async function (response) {
        addNotification(
          tempData.is_accept,
          tempData.user_id,
          tempData.user_name,
          tempData.spaceshipId,
          tempData.mode,
          tempData.project_id
        )

        socketRef.current.emit('leaveOne', "PROJECT_:" + project_id)
        getMailByUser();

      })
      .catch(function (error) {
        console.log(error);
        socketRef.current.emit('leaveOne', "PROJECT_:" + project_id)
        getMailByUser();
      });
  }

  async function addNotification(is_accept, user_id, user_name, spaceshipId, mode, project_id) {

    let tempNoti = {
      header: `${is_accept == true ? "ACCEPT" : "DENIED"} ${mode == 'offer_project' ? "YOUR OFFER PROJECT TO " : "YOUR INVITE TO"} `,
      // body: `คุณ ${sessionStorage.getItem('user')} ${is_accept == true ? "ยอมรับ" : "ปฏิเสธ"}เข้าร่วมยาน`,
      noti_menu_name: "",
      user_to_id: user_id,
      user_to_name: user_name,
      link_to: "",
      space_ship_id: spaceshipId,
      project_id: project_id
    }
    await axios({
      method: 'POST',
      url: `${Configs.TTT_Game_Notification_Service}/api/notification/addNotification`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: tempNoti,
    })
      .then(async function (response) {
        socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + user_id });
        // setSendData([])
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function SetDayAg(date) {
    let dateCount = moment(new Date()).diff(moment(date), 'days')
    if (dateCount === 0) {
      let minute = moment(new Date()).diff(moment(date), 'minutes')
      if (parseInt(minute) >= 60) {
        return `${Math.ceil(parseInt(minute) / 60)} HOURS AGO`
      } else if (parseInt(minute) <= 1) {
        return `JUST NOW`
      } else {
        return `${Math.ceil(parseInt(minute))} MINUTES AGO`
      }
    } else {
      if (parseInt(dateCount) >= 365) {
        return `${Math.ceil(parseInt(dateCount) / 365)} YEARS AGO`
      } else if (parseInt(dateCount) >= 30) {
        return `${Math.ceil(parseInt(dateCount) / 30)} MONTHS AGO`
      } else {
        return `${Math.ceil(parseInt(dateCount))} DAYS AGO`
      }
    }
  }


  // console.log('mode',mode.mode)


  function chcekusermodeSTR(id) {

    let Views = localStorage.getItem('projectAct')
    let prantJson = JSON.parse(Views)

    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/project/chcekusermode/${prantJson.project_id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        let setItemInSess = sessionStorage.setItem('getUserMode', response.data.mode)


      })

      .catch(function (error) {

        console.log(error);

      });

  }

  function reloadSlectProject() {
    chcekusermodeSTR();
    setOpenModalSelectProject(!openModalSelectProject);
  }


  useEffect(() => {
    sessionStorage.setItem("timeline", 0);
    //setCurrentPath(location.pathname);

    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keydown",
    ];
    var fiveMinutes = 60 * 31;

    var myInterval = setInterval(myTimer, 1000);
    //countdown(fiveMinutes);
    var timer = fiveMinutes,
      minutes,
      seconds;
    function myTimer() {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      // console.log(timer)
      if (timer === 60) {

        toggle_message_modal();

      }
      if (--timer < 0) {
        sessionStorage.clear();
        window.location.reload();

      } else {
        //console.log( minutes + ":" + seconds)

        sessionStorage.setItem("setupTime", minutes + ":" + seconds);
      }
    }
    for (var e = 0; e < events.length; e++) {
      //console.log(events);
      document.addEventListener(
        events[e],
        (event) => {
          clearInterval(myInterval);
          timer = 60 * 31;
          myInterval = setInterval(myTimer, 1000);
        },
        false
      );
    }
  }, []);

  // team detail || team view

  const [teamViewPopUp, setTeamViewPopUp] = useState(false);
  const toggle_teamViewPopUp = () => { setTeamViewPopUp(!teamViewPopUp) };
  const [teamViewPopUpEr, setTeamViewPopUpEr] = useState(false);
  const toggle_teamViewPopUpEr = () => { setTeamViewPopUpEr(!teamViewPopUpEr) };

  const [teamViewPopUpEr2, setTeamViewPopUpEr2] = useState(false);
  const toggle_teamViewPopUpEr2 = () => { setTeamViewPopUpEr2(!teamViewPopUpEr2) };

  const [teamViewData, setTeamViewData] = useState([])
  const [leadrUserId, setLeadUserId] = useState({
    leader_id: ''
  })

  async function GetAccesstokenToken(callback, model) {
    axios({
      method: "post",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {

        if (callback) {
          callback(response.data.tokenBox);
        }
        sessionStorage.setItem('tokenBox', response.data.tokenBox)
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  async function checkTeamView(model) {
    await axios({
      method: 'GET',
      url: `${Configs.TTT_Game_Service}/api/project/GetTeamView/${projectActive.pf_project_id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      // data: temp,
    })
      .then(async function (response) {
        if (model && model == 'ban') {
          setTeamViewData(response.data)
        } else {
          if (response.data.check === true && teamViewPopUp === false) {
            setTeamViewData(response.data)
            toggle_teamViewPopUp()
          } else if (response.data.check === false) {
            toggle_teamViewPopUpEr()
          }
        }
        if (response.data.ul_file_path_spaceship) {
          const response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.ul_file_path_spaceship}/content`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
          });

          response.data.ul_file_path = URL.createObjectURL(response2.data);
          setTeamViewData(response.data);
          settest({ ...test, test: '' });
          test.test = '';
        } else {
          const response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.ul_file_path_model}/content`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
          });

          response.data.ul_file_path = URL.createObjectURL(response2.data);
          setTeamViewData(response.data);
          settest({ ...test, test: '' });
          test.test = '';
        }

        let getLeader = response.data.leader.pf_usr_id || '';
        setLeadUserId({
          ...leadrUserId,
          leader_id: getLeader
        });
        leadrUserId.leader_id = getLeader;
        let checkLeader = response.data.leader;

        if (checkLeader.fd_img_id) {
          const response2 = await axios.get(`https://api.box.com/2.0/files/${checkLeader.fd_img_id}/content`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
          });

          checkLeader.ul_file_path = URL.createObjectURL(response2.data);

          response.data.leader = checkLeader;
          setTeamViewData(response.data);
          settest({ ...test, test: '' });
          test.test = '';
        }



        let checkUsers = response.data.users;
        if (checkUsers.length > 0) {
          for (let item of checkUsers) {
            if (item.fd_img_id) {
              const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                },
                responseType: 'blob',
              });

              item.ul_file_path = URL.createObjectURL(response2.data);
            }

            response.data.users = checkUsers;
            setTeamViewData(response.data);
            settest({ ...test, test: '' });
            test.test = '';
          }
        }
        // if (model && model == 'ban') {

        //   setTeamViewData(response.data)

        // } else {
        //   if (response.data.check === true && teamViewPopUp === false) {
        //     setTeamViewData(response.data)
        //     toggle_teamViewPopUp()
        //   } else if (response.data.check === false) {
        //     toggle_teamViewPopUpEr()
        //   }
        // }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // function setFixedHeight() {
  //   const element = document.getElementById('fixedHeight');
  //   const viewportHeight = window.innerHeight;
  //   element.style.height = (viewportHeight * 0.549) + 'px';
  // }

  // window.onload = setFixedHeight;

  // window.onresize = setFixedHeight;


  async function getElementByLocationAndUser(key, valueId) {
  /*   console.log('key', key);
    
    var temp = {
      key: key,
      id: valueId,
      user: sessionStorage.getItem('user_id')
    };
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/slotLocationElement/slotLocationElement`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      }, data: temp
    }).then(async function (response) { */

      setGetKeyItem({...getKeyItem,
        key: key,
        id:valueId,
        status: true
      });
      getKeyItem.key = key;
      getKeyItem.id = valueId;
      getKeyItem.status = true
    /*   setSlotElementList(response.data.data || []); */
      settest({...test,test:''});
      test.test = '';


 /*    }).catch(function (error) {
      console.log('key Error',error);
      
    }); */
  }



  const [onElement,setOnElement] = useState(null);
  function openElement(elm_id){
    console.log('openElement',elm_id);
    setOnElement(elm_id);
    sessionStorage.setItem('onElement',elm_id);
  }

  function closeElement(){
 
    setOnElement(null);
   
  }


  return (
    <>

      {/*       <canvas id='3dTower'></canvas> */}
      <div className="bg" style={{ zIndex: '-1' }} />
      {mode.mode === 'detait' ?
        (<div style={{ animation: `${isanimetion === true ? 'openRiskIssue 2s' : 'closeRiskIssue 2s'}` }}>
          <AddMember
            isOpen={addMemberModal}
            reload={addMemberReload}
            toggle_modal_addMemberReload={toggle_modal_addMemberReload}
            toggle_modal_addMember={toggle_modal_addMember}
            checkData={memberInSpaceShip}
            spaceShipId={'d0db48a0-e9b7-4f24-beeb-4c7614be141f'}
            memberSize={memberSize}
            spaceShipName={'RAIZEROS'}
          />
          <div className='projectHistoryBox'>
            <div className='projectHistoryHeader'>PROJECT HISTORY</div>
            <div className='projectHistory'>
              {historyDetail.length > 0 ?
                historyDetail.map((e, index) => {
                  return (<>
                    <div className='row projectHistoryList'>
                      <div className='col-6 projectHistoryListName'><img src={iconName} style={{ width: '12.5px', marginTop: '-5px' }}></img> {e.projectName}</div>
                      <div className='col-6 projectHistoryListStatus'>PROGRESS : <span className='projectHistoryListProgress'>{e.projectProgress}%</span> STATUS : <span className='projectHistoryListProgress'>-</span></div>
                      <div className='col-6 projectHistoryListPeriod'>PERIOD : {moment(e.projectStart).format("DD MMM yyyy")} - {moment(e.projectEnd).format("DD MMM yyyy")}</div>
                      <div className='col-6 projectHistoryListBudget'>BUDGET : {numberWithCommas(parseFloat(e.projectBudget || 0).toFixed(2))} BAHT</div>
                    </div>
                  </>)
                })
                : (<>NO DATA</>)}
            </div>
          </div>
          <div className='detailBox'>
            <ModalDetail
              isOpen={modalDetail}
              mode={'2'}
              reload={addMemberReload}
              toggle_modal_addMemberReload={toggle_modal_addMemberReload}
              spaceShipId={'d0db48a0-e9b7-4f24-beeb-4c7614be141f'}
              toggle_modal_addMember={toggle_modal_addMember}
              callBack={callBackMemberInSpaceShip}
              setMemberSize={setMemberSize}
              callBackspace={callBackspace}
              callbackHistory={callbackHistory}
            />
          </div>
        </div>)
        :
        (<>

        </>)}

      {mode.mode === 'package' ?
        (<>
          <div style={{ display: `${modalPackage === true ? '' : 'none'}`, animation: `${isanimetion === true ? 'openRiskIssue 1s' : 'closeRiskIssue 1s'}` }} >
            <ModalPackgage />
          </div>

        </>)
        :
        (<>

        </>)}

      {mode.mode === 'all' && param.display != 'kickoff' ?
        (<>
          <div className={allMonitoring == false ? 'MonitoringCardName' : 'cardstagemonitoring'}
            id={allMonitoring == false ? 'fixedHeight' : 'divTEST'}
            style={{
              display: `${modalPackage === false && param.display != 'tower' ? '' : 'none'}`,
              animation: `${isanimetion === true ? 'openRiskIssue 1s' : 'closeRiskIssue 1s'}`,
              top: `${clearClasname === true ? '2%' : '17%'}`,
              height: `${clearClasname === true ? 'fit-content' : '64%'}`,
              position: `${clearClasname === true ? 'fixed' : 'absolute'}`,
              left: `${clearClasname === true ? '5%' : '5%'}`,
              width: `${clearClasname === true ? '90%' : '90%'}`,
              /*  `${clearClasname === true ? {top:'2%',width:'100%',left:'0',height:'100%',position:'relative'} : ''}` */
            }} >
            <div className={param.display == undefined && allMonitoring == false ? 'table-header-border' : 'none'} style={{ display: `${param.display == undefined && allMonitoring == false ? '' : 'none'}` }} >
              <div className={param.display == undefined && allMonitoring == false ? 'table-header' : 'none'} style={{ display: `${param.display == undefined && allMonitoring == false ? '' : 'none'}` }}></div>
            </div>
            <div className={param.display == undefined && allMonitoring == false ? 'table-right-border' : 'none'} style={{ display: `${param.display == undefined && allMonitoring == false ? '' : 'none'}` }}>
              <div className={param.display == undefined && allMonitoring == false ? 'table-right' : 'none'} style={{ display: `${param.display == undefined && allMonitoring == false ? '' : 'none'}` }}></div>
            </div>
            <div className={param.display == undefined && allMonitoring == false ? 'table-left-border' : 'none'} style={{ display: `${param.display == undefined && allMonitoring == false ? '' : 'none'}` }}>
              <div className={param.display == undefined && allMonitoring == false ? 'table-left' : 'none'} style={{ display: `${param.display == undefined && allMonitoring == false ? '' : 'none'}` }}></div>
            </div>
            <div className={param.display == undefined && allMonitoring == false ? 'rhombus' : 'none'} style={{ display: `${param.display == undefined && allMonitoring == false ? '' : 'none'}`, zIndex: '-1' }}></div>
            <div className={param.display == undefined && allMonitoring == false ? 'rhombus-left' : 'none'} style={{ display: `${param.display == undefined && allMonitoring == false ? '' : 'none'}` }}></div>
            <div className={param.display == undefined && allMonitoring == false ? 'rhombus-right' : 'none'} style={{ display: `${param.display == undefined && allMonitoring == false ? '' : 'none'}` }}></div>
            <div className={param.display == undefined && allMonitoring == false ? 'card1' : 'none'} style={{ display: `${param.display == undefined && allMonitoring == false ? '' : 'none'}`, zIndex: '-1' }}></div>
            <div className={param.display == undefined && allMonitoring == false ? 'card2' : 'none'} style={{ display: `${param.display == undefined && allMonitoring == false ? '' : 'none'}`, zIndex: '-1' }}></div>
            <Cardstage
              toggle_modal_add_module={toggle_modal_add_module}
              setProject={setProjectID}
              toggle_modal_add_menu={toggle_modal_add_menu}
              toggle_modal_import={toggle_modal_import}
              projectDetail={projectDetail}
              mode={mode.mode}
              getModuleByProject={getModuleByProject}
              callBackModalSrs={callBackModalSrs}
              reloadSlectProject={() => reloadSlectProject()}
              toCardStage={toCardStage}
              setToCardStage={setToCardStage}
              allStageMonitoring={allStageMonitoring}
              fed_id={federationDetail.fed_id}
              toggle_clear_classname={toggle_clear_classname}
              clearClasname={clearClasname}
              setmode={setmode}
              amode={mode}
              stageProject={stageProject}
            />

          </div>
        </>)
        :
        (<>

        </>)}

      {mode.mode === 'projcet' ?
        (<>

          <PublicProjeclrit

          />
        </>)
        :
        (<>

        </>)}

      {mode.mode === 'fedSpaceShipList' ?
        (<>

          <CardFedSpaceShipList
            mode={mode}
            setmode={setmode}
            fedSpaceShipId={fedSpaceShipId}
            setFedSpaceShipId={setFedSpaceShipId}
          />

        </>)
        :
        (<>

        </>)}

      {mode.mode === 'fedSpaceShip' ?
        (<>

          <CardFedSpaceShip
            mode={mode}
            setmode={setmode}
            fedSpaceShipId={fedSpaceShipId}
          />

        </>)
        :
        (<>

        </>)}


      {clearClasname === false ? <>
        <div className='page-content'></div>

        <div className="frame-top" style={{ zIndex: '99' }}>

          <hr className='line-top-main' style={{ borderColor: colordefcon.color, zIndex: '99' }} />
          {colordefcon.isuse === true ?
            (<>  <div className='line-top-main-filter' style={{ boxShadow: `30px 60px 60px 50px ${colordefcon.color}`, zIndex: '2' }} /></>)
            :
            (<></>)}

          <div className='top-line-set'>
            <hr className='line-top-left-sec' />
            <hr className='line-top-left-rotate' />
            <hr className='line-top-right-sec' />
            <hr className='line-top-right-rotate' />
          </div>
          <div className='top-center-bg' style={{ zIndex: '99' }}></div>
          <div className='top-center-main' style={{ zIndex: '99' }}></div>
          <div className='top-center-left' style={{ zIndex: '99' }}></div>
          <div className='top-center-left-rotate' style={{ zIndex: '99' }}></div>
          <div className='top-center-right' style={{ zIndex: '99' }}></div>
          <div className='top-center-right-rotate' style={{ zIndex: '99' }}></div>
          <div className='logo-ss' style={{ zIndex: '99' }}><img src={logo_ss} style={{ width: "300px" }}></img></div>
        </div>

        <div className='frame-bottom' style={{ zIndex: '95' }}>
          <div className='bottom-fix'>
            <svg height="145px" width="300px">
              <line x1="50" y1="33" x2="78" y2="33" style={{ stroke: "rgba(94, 155, 225, 1)", strokeWidth: "2" }} />
              <line x1="50" y1="33" x2="5" y2="75" style={{ stroke: "rgba(94, 155, 225, 1)", strokeWidth: "2" }} />
              <line x1="5" y1="75" x2="81" y2="145" style={{ stroke: "rgba(94, 155, 225, 1)", strokeWidth: "2" }} />
              <line x1="250" y1="33" x2="222" y2="33" style={{ stroke: "rgba(94, 155, 225, 1)", strokeWidth: "2" }} />
              <line x1="250" y1="33" x2="295" y2="75" style={{ stroke: "rgba(94, 155, 225, 1)", strokeWidth: "2" }} />
              <line x1="295" y1="75" x2="219" y2="145" style={{ stroke: "rgba(94, 155, 225, 1)", strokeWidth: "2" }} />
            </svg>
            <div className='bottom-bg'>
              <div className='bottom-main'>
                <div className='text-project'>PROJECT PROGRESS</div>
                <div className='text-project-num'>{projectActive.project_progress}<span className='text-project-percent'>%</span></div>
              </div>
            </div>

          </div>
        </div>

        <div className="frame1" style={{ zIndex: '99' }}>
          <div className="box-top-left"></div>
          <div className="box-top-left-main-bg">
            <div className="box-top-left-main">
              <div className='row' style={{ margin: 0, padding: 0, width: '100%' }}>
                <div className='col-2' style={{ margin: 0, padding: 0 }}></div>
                <div className="col-6 text-name">
                  <div className='text-name-in'>{sessionStorage.getItem('ttt_id') + ' ' + sessionStorage.getItem('user')}</div>

                  <button className="botton-click" onClick={() => {
                    personalInfo()
                    if (param.setMenuValue != undefined) {
                      param.setMenuValue(true)
                    }
                  }}>
                    <img src={icon_pen} style={{ width: "15px", height: "15px", marginLeft: "5px" }}></img>
                  </button>
                </div>
                <div className='col-3' style={{ margin: '7px 0', padding: '0 25px', display: 'flex', gap: '10px' }}>
                  <div className='mailBoxButtonOpen' style={{ backgroundColor: `${mailBox ? '#0047FF' : '#5E9BE1'}` }} onClick={() => {
                    toggle_modal_mailBox()
                    if (param.setMenuValue != undefined) {
                      param.setMenuValue(true)
                    }
                  }}>
                    <img src={icon_mail} style={{ width: "25px", height: "25px", cursor: 'pointer' }}></img>
                    <div className='frame1mailBoxNoti' >{mailUnReadCount < 100 ? mailUnReadCount : '99+'}</div>
                  </div>
                  <div className='rocketButtonOpen' style={{ backgroundColor: `${personalInfoCheck.isOpen ? '#0047FF' : '#5E9BE1'}` }} onClick={() => {
                    personalInfo('SPACESHIP')
                    if (param.setMenuValue != undefined) {
                      param.setMenuValue(true)
                    }
                  }}>
                    <img src={icon_rocket} style={{ width: "14.5px", height: "19px" }}></img>
                    <div className="text-rocket">{userprofile.spaceshipcount}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-top-left-main-in1"></div>
          <div className="box-top-left-main-in2"></div>
          <div className="box-img-character"><a><img className="img-character" src={pathProfile || DefaultUser}></img></a></div>
          <div className="line-top-left1"></div>
          <div className="line-top-left2"></div>
          <div className="line-top-left-box"></div>
          <div className="line-top-left-box1"></div>
          <div className="line-top-left-box2"></div>
          <div className="box-status">
            <div className="box-status-in">
              <div className="text-level">LEVEL</div>
              <div className="text-level-num">999</div>
            </div>
            <div className="box-status-in"></div>
          </div>
          <div className="box-arc">
            <div className="box-arc-in"></div>
            <div className="box-arc-in"></div>
            <div className="box-arc-in"></div>
            <div className="box-arc-in"></div>
            <div className="box-arc-in"></div>
          </div>
        </div>

        <div className="frame2" style={{ zIndex: '99' }}>
          <div className="box-top-right-main-in2"></div>
          <div className="box-top-right-main-in1"></div>

          <div className="box-top-right-main-bg">
            <div className="box-top-right-main">
              <button className="topup-main" style={{ marginLeft: '45px', marginRight: '0px', overflow: 'hidden', cursor: 'pointer' }} onClick={() => { window.location.href = '/landingPageGame' }}>
                <div className="topup-text">
                  <img src={Icon_Universal} style={{ width: "100%", height: "100%", marginTop: '-5px' }}></img>

                </div>
                <div className="line-top-right-menu"></div>
                <div className="line-top-right-menu2"></div>
              </button>
              <button className="topup-main" style={{ marginLeft: '-28px', marginRight: '0px' }}>
                <div className="topup-text" style={{ cursor: 'pointer' }} onClick={() => { toggle_modal_ModalTopUp() }}>
                  <img src={icon_plus} style={{ width: "10px", height: "10px", marginTop: "0px", marginLeft: "-35px" }}></img>
                  <span className="text-topup" >TOP UP</span>
                </div>
                <div className="line-top-right-menu"></div>
                <div className="line-top-right-menu2"></div>
              </button>


              <button className="transfer-main" onClick={() => {
                if (modalPackage === false) {
                  setmode({ ...mode, mode: 'package' });
                  mode.mode = 'package';
                  setIsanimetion(false)
                  setdelayswit()
                  param.setCheckOnModal({ isOpen: true })
                }
                if (param.display === 'kickoff') {
                  socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'MyProject'});
                  window.location.href = '/StageMonitoring?key=myproject'
                }

              }}>
                <div className="transfer-text" style={{ cursor: 'pointer' }} >
                  <img src={icon_fresh} style={{ width: "10px", height: "10px", marginTop: "0px", marginLeft: "-95px" }}></img>
                  <span className="text-topup">MY PROJECT</span>
                </div>
                <div className="line-top-right-menu"></div>
              </button>

              <div className="sapphire-main">
                <img src={icon_sapphire} style={{ width: "15px", height: "20px", marginLeft: "20px" }}></img>
                <span className="text-sapphire">{numberWithCommas(formatNumberToK(sapphireData))}</span>
              </div>

              <button className="logout-main" onClick={() => { sessionStorage.clear(); window.location.href = '/' }} style={{ cursor: 'pointer' }}>LOGOUT</button>
            </div>
          </div>



          <div className="box-top-right">



          </div>
          <div className="line-top-right1"></div>
          <div className="line-top-right2"></div>
          <div className="line-top-right-box1"></div>
          <div className="line-top-right-box2"></div>
        </div>
      </> : <></>}


      {/* <i class="fa fa-square" aria-hidden="true" style={{color:"transparent",border:'1px solid white',borderRadius:'3px',height:'12px',width:'12px'}}></i> */}

      {/*   <LineModal dataTower = {jsonArea} isRender = {stateRender} checkCategory = {onCategory} historySetData = {historySetData} resetDataTower = {resetDataTower}></LineModal> */}


      <PersonalInfo
        isOpen={personalInfoCheck.isOpen}
        fncSetProfile={setImageProfile}
        setPersonalInfoCheck={setPersonalInfoCheck}
        submode={personalInfoCheck.submode}
        close={param.setMenuValue}
        sapphireData={sapphireData}
        getAmountSapphire={() => { getAmountSapphire() }}
      ></PersonalInfo>

      <Carddefcon
        isOpen={modeldefcon.isOpen}
        isLeader={projectActive.isLeader}
        returncolordefcon={returncolordefcon}
        dataDefcon={defconData}
      />

      {clearClasname === false ? <>
        <hr className="line-left" style={{ zIndex: '100', borderColor: colordefcon.color }} />
        <hr className="line-right" style={{ zIndex: '100', borderColor: colordefcon.color }} />


        <div className="frame3" style={{ zIndex: '99' }}>
          <img

            className='mr-1'
            style={{ width: '30px', top: '0%', left: '19.2%', zIndex: '99', position: 'absolute' }}
            src={img_sm} />
          <div className="rect-left-bottom" style={{ zIndex: '99', cursor: 'pointer' }}
            onClick={() => {

              if (federationDetail.fed_id != '') {
                window.open(Configs.TTT_Dashboard_Url + federationDetail.fed_id);
              }
            }} ></div>
          <div className="rect-left-bottom-line" style={{ zIndex: '99' }}></div>


          {isOpenFederationList && (<>
            <div className='showSelectFed'>
              <div className='showFedContainer'>
                {federationList.map((e) => {
                  return (<>
                    <div className='fedList'
                      onClick={() => {
                        socketRef.current.emit('leaveOne', "Federation:" + federationDetail.fed_id);
                        setFederationDetail({
                          ...federationDetail,
                          fed_id: e.federation_id,
                          fed_img_path: e.image_path ? e.image_path : null,
                          fed_name: e.federation_name,
                          fed_spaceship_count: e.spaceShip_list.length,
                          fed_img_file: e.img_profile,
                        });
                        sessionStorage.setItem('fed_id', e.federation_id);

                        federationDetail.fed_id = e.federation_id;
                        federationDetail.fed_img_path = e.image_path ? e.image_path : null;
                        federationDetail.fed_img_file = e.img_profile;
                        federationDetail.fed_name = e.federation_name;
                        federationDetail.fed_spaceship_count = e.spaceShip_list.length;

                        socketRef.current.emit('join', "Federation:" + e.federation_id);
                        socketRef.current.emit('Federation', { message: 'Fetch', room: "Federation:" + e.federation_id });
                        toggle_open_fed_list();
                        toggle_freshSelectProjectByFed();
                      }}
                    >
                      <img

                        style={{ width: '25px', height: '25px', }}
                        src={e.img_profile ? e.img_profile : logo}

                      />
                      <div className='fedTxt'>{e.federation_name}</div>
                    </div>
                  </>)
                })}




              </div>

            </div>
          </>)}


          <div className="box-middle-top" style={{ zIndex: '99', cursor: `${sessionStorage.getItem('fed_id') ? 'pointer' : 'no-drop'}` }} >
            {/* {console.log('จบ', federationDetail)} */}
            <div
              style={{ width: '190px', display: 'flex', justifyContent: 'center', marginTop: '20px' }}
              onClick={() => {
                if (sessionStorage.getItem('fed_id')) {
                  toggle_modal_fedMgtConfig()
                }
              }}
            >
              <img
                className='mr-2'
                style={{ width: '25px', height: '25px'/* , marginTop: '-6px' */ }}
                src={federationDetail.fed_img_file ? federationDetail.fed_img_file : logo} />
              <p style={{
                fontSize: '16px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>{federationDetail.fed_name || 'No Data'}</p>
            </div>

            <img
              className={isOpenFederationList ? '' : 'imgDown'}
              style={{ width: '25px', height: '25px' }}
              src={iconArrow}
              onClick={() => {
                toggle_open_fed_list();
              }}

            />

          </div>


          <button className="box-middle-top2" style={{ zIndex: '99', cursor: 'no-drop' }}>
            <img

              className='mr-2 '
              style={{ width: '13px', height: '13px', marginTop: '-3px' }}
              src={icon_plus2} />


            <span >CREATE FEDERATION</span>
          </button>
          <button className="box-middle-top3" style={{ zIndex: '99', cursor: 'no-drop' }}>
            <img

              className='mr-1'
              style={{ width: '15px', marginTop: '-2px', zIndex: '99' }}
              src={icon_mail} />INBOX
          </button>
          <button className={federationDetail.fed_id != (null || '') ? "box-middle-top4" : "box-middle-top5"} style={{ fontSize: '10px', zIndex: '99' }} onClick={() => {
            setmode({ ...mode, mode: 'fedSpaceShipList' });
            mode.mode = 'fedSpaceShipList';
          }}
            disabled={federationDetail.fed_id != (null || '') ? false : true}
          >
            <img
              className='mr-1'
              style={{ width: '8px', marginTop: '-2px', zIndex: '-1' }}
              src={icon_rocket}
            />
            <span>{federationDetail.fed_spaceship_count}</span>
          </button>
          <div className="rect-left-bottom2 " style={{ zIndex: '99', cursor: 'no-drop' }}> <img src={gostLogo} style={{ width: '42%', marginLeft: '37px', transform: 'rotate(180deg)' }} /></div>
          <div className="rect-left-bottom-line2" style={{ zIndex: '99' }}></div>
          <div className="rect-left-box-left" style={{ zIndex: '99' }}></div>
          <div className="rect-left-box-left-line" style={{ zIndex: '99' }}></div>



          <div className="triangle-left-border" style={{ zIndex: '99' }}>
            <div className="triangle-left"></div>
          </div>
          <div className="box-left-border" style={{ zIndex: '99' }}>
            <button className="box-left" style={{ textAlign: 'left', paddingBottom: '45px', paddingLeft: '5px' }}>
              FEDERATION<br />
              MANAGEMENT
            </button>
          </div>


          <button className="box-middle" style={{ zIndex: '99', cursor: `${spaceShipFed[0] ? 'pointer' : 'no-drop'}` }} onClick={() => {
            // toggle_modal_Detail()
            /* setModalDetail(true)
            if (param.setCheckOnModal != undefined) {
              param.setCheckOnModal({ isOpen: true })
            }
            setmode({ ...mode, mode: 'detait' });
            mode.mode = 'detait';
            if (param.display === 'kickoff') {
              window.location.href = '/StageMonitoring?key=spaceshipdetail'
            } */
            if (spaceShipFed[0]) {
              setmode({ ...mode, mode: 'fedSpaceShip' });
              mode.mode = 'fedSpaceShip';
              setFedSpaceShipId({ ...fedSpaceShipId, id: spaceShipFed[0].fs_sps_id });
            }
          }}>
            {spaceShipFed[0] && <span className={`${spaceShipFed[0] && spaceShipFed[0].max_is_online == 1 ? 'dot' : 'dot_'} mr-1`}></span>}{spaceShipFed[0] && spaceShipFed[0].spaceship_name}
          </button>
          <button className="box-middle" style={{ zIndex: '99', cursor: `${spaceShipFed[1] ? 'pointer' : 'no-drop'}` }} onClick={() => {
            if (spaceShipFed[1]) {
              setmode({ ...mode, mode: 'fedSpaceShip' });
              mode.mode = 'fedSpaceShip';
              setFedSpaceShipId({ ...fedSpaceShipId, id: spaceShipFed[1].fs_sps_id })
            }
          }}>
            {spaceShipFed[1] && <span className={`${spaceShipFed[1] && spaceShipFed[1].max_is_online == 1 ? 'dot' : 'dot_'} mr-1`}></span>}{spaceShipFed[1] && spaceShipFed[1].spaceship_name}
          </button>
          <button className="box-middle" style={{ zIndex: '99', cursor: `${spaceShipFed[2] ? 'pointer' : 'no-drop'}` }} onClick={() => {
            if (spaceShipFed[2]) {
              setmode({ ...mode, mode: 'fedSpaceShip' });
              mode.mode = 'fedSpaceShip';
              setFedSpaceShipId({ ...fedSpaceShipId, id: spaceShipFed[2].fs_sps_id })
            }
          }}>
            {spaceShipFed[2] && <span className={`${spaceShipFed[2] && spaceShipFed[2].max_is_online == 1 ? 'dot' : 'dot_'} mr-1`}></span>}{spaceShipFed[2] && spaceShipFed[2].spaceship_name}
          </button>
          <div className="box-right-border" style={{ zIndex: '99', cursor: `${spaceShipFed[3] ? 'pointer' : 'no-drop'}` }} onClick={() => {
            if (spaceShipFed[3]) {
              setmode({ ...mode, mode: 'fedSpaceShip' });
              mode.mode = 'fedSpaceShip';
              setFedSpaceShipId({ ...fedSpaceShipId, id: spaceShipFed[3].fs_sps_id })
            }
          }}>
            <button className="box-right" style={{ zIndex: '99', cursor: 'pointer' }}>
              {spaceShipFed[3] && <span className={`${spaceShipFed[3] && spaceShipFed[3].max_is_online == 1 ? 'dot' : 'dot_'} mr-1`}></span>}{spaceShipFed[3] && spaceShipFed[3].spaceship_name}
            </button>
          </div>
          <div className="box-defcon-border" style={{ zIndex: '99', cursor: 'pointer' }}
            onClick={() => {

socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'TeamDetail'});
              setmodeldefcon({
                ...modeldefcon,
                isOpen: !modeldefcon.isOpen
              })
              modeldefcon.isOpen = !modeldefcon.isOpen


              if (param.setMenuValue != undefined) {
                if (modeldefcon.isOpen) {
                  param.setMenuValue(true)
                } else {
                  param.setMenuValue(false)
                }
              }

            }}>

            <div className="box-defcon">DEFCON
              <div className="box-defcon-alert-border">
                <div className="box-defcon-alert">
                  <div className="box-defcon-value" style={{ backgroundColor: colordefcon.color }}></div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-defcon-line-right" style={{ zIndex: '99', width: `${(windowSize.innerWidth / 1920 * 823 - 808)}px`, borderTop: `1px solid #5E9BE1` }}></div>
          <div className="box-defcon-line-right" style={{ zIndex: '99', width: `${(windowSize.innerWidth / 1920 * 823 - 808)}px`, borderBottom: `1px solid #5E9BE1`, marginLeft: '832px' }}></div>
          <div className="rect-defcon-line-right2" style={{ zIndex: '99', marginLeft: `${(windowSize.innerWidth / 1920 * 823)}px` }}></div>
        </div>
        <hr className='line-bottom' style={{ zIndex: '99', borderColor: colordefcon.color }} />


        <div className="frame4" style={{ zIndex: '99' }}>

          <div className="box-defcon-mini-border-right-line1"></div>
          <div className="box-defcon-mini-border-right">
            <div className="row" style={{ marginTop: '3px', display: 'flex', justifyContent: 'center', width: '160px' }}>
              <img

                className='mr-2'
                style={{ width: '10px', height: '10px', marginTop: '2px' }}
                src={/* (projectActive.ul_file_path != '' && projectActive.ul_file_path != null) ? projectActive.ul_file_path : */ logo} />
              <span style={{ fontSize: '10px' }} >{projectActive.federation_name || 'No Data'}</span>
            </div>
          </div>
          <div className="box-defcon-mini-border-right-line2"></div>

          <div className="box-defcon-mini-border-right2-line1"></div>
          <div className="box-defcon-mini-border-right2">
            <div className="row" style={{ marginTop: '2px', display: 'flex', justifyContent: 'center', width: '177px' }}>
              <span style={{ fontSize: '10px'/* , marginLeft: '34px' */ }} >{projectActive.spaceship_name}</span>
            </div>
          </div>
          <div className="box-defcon-mini-border-right2-line2"></div>

          <div className="box-defcon-mini-border-right3-line1"></div>
          <div className="box-defcon-mini-border-right3">
            <img
              className='mr-2'
              style={{ width: '160px', height: '43px', objectFit: 'cover', cursor: 'pointer' }}
              src={projectActive.url ? projectActive.url : img_banner}
              onClick={()=>{
                window.location = `/SpaceshipConsole2.0?spid=${projectActive.spaceship_id}&mode=${mode.mode}${mode.mode == 'fedSpaceShip' ? `&fedSpsId=${fedSpaceShipId.id}` : ''}${mode.spaceship_id != '' && mode.mode == 'all' ? `&spaceship_id=${mode.spaceship_id}` : ''}`
              }}
            />
          </div>


          <div className="box-defcon-mini-border-right3-line2"></div>


          <div className="rect-right-top-bottom-line1"></div>
          <div className="rect-right-top-bottom" style={{ textAlign: 'center', paddingTop: '2px', cursor: 'pointer' }} onClick={() => {
            setProjectState({ isOpen: true })
            if (param.setMenuValue != undefined) {
              param.setMenuValue(true)
            }
          }}>
            <p className="ml-4" style={{ color: '#fff', fontSize: '12px' }}>PROJECT STATE</p>
          </div>
          <div className="box-center-top-border-right" style={{ textAlign: 'center', paddingTop: '5px', fontSize: '12px', cursor: 'pointer' }} onClick={() => {
            if (modalPackage === true) {
              setmode({ ...mode, mode: 'all' });
              mode.mode = 'all';
              setIsanimetion(false)
              allStageMonitoring(false)
              setdelayswit()
              window.location.href = '/StageMonitoring'
            } else {
              window.location.href = '/StageMonitoring'
            }

          }}>
            PROJECT STAGE MONITORING

          </div>



          <div className="rect-right-bottom-line1" ></div>
          <div className="rect-right-bottom" style={{ cursor: 'pointer' }}
            onClick={() => {
              socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'SearchProject'});
              setmode({ ...mode, mode: 'projcet' });
              mode.mode = 'projcet';
              setIsanimetion(false)
              setdelayswit()

            }} >
            <img

              style={{ width: '35px', marginTop: '5px', marginLeft: '35px', marginTop: '10px' }}
              src={iconviewproject}
            />
          </div>


          <div className="rect-center-bottom" style={{ textAlign: "center" }}>
            <img
              className='rect-center-bottom-img'
              style={{ width: '35px', marginTop: '5px' }}
              src={logo_twitch}
              onClick={() => {
                openChat()
                if (param.setMenuValue != undefined) {
                  param.setMenuValue(true)
                }
                /* ต้องเปิด*/
                /*  openCommentReport() */
                /*  toggle_modal_commentReport() */
              }} />
          </div>
          <div className="rect-center-bottom2" /* style={{ textAlign: 'left', fontSize: '20px', paddingTop: '4px',paddingLeft:'4px' ,borderColor:"red" }} */>

            <span>
              <SelectProject
                callBack={callBack}
                chcekusermode={chcekusermode}
                checng={openModalSelectProject}
                toggleSelectProject={() => { setOpenModalSelectProject(!openModalSelectProject) }}
                projectActive={projectActive}
                setMenuValue={param.setMenuValue}
                freshSelectProjectByFed={freshSelectProjectByFed}
              /></span>
            {/*  <span>PROJECT MONITORING</span> */}
            {/*   <img

            style={{ width: '35px', marginLeft: '60px' }}
            src={logo_pointer} /> */}

          </div>


          <div className="rect-right-bottom2" onClick={() => {
           
            openModals(openModal)
          }}>
            <img className='imgSteat'/* style={{}} */ src={ImgEditModal} />
          </div>



          <div className="rect-right-bottom2-line1"></div>



          <div className="triangle-right-border"></div>
          <div className="triangle-right"></div>



          <div className="box-defcon-line-left" style={{ marginLeft: `-${(windowSize.innerWidth / 1920 * 823 - 802)}px`, width: `${(windowSize.innerWidth / 1920 * 823 - 723)}px`, borderTop: '1px solid #5E9BE1' }}></div>
          <div className="box-defcon-line-left" style={{ marginLeft: `-${(windowSize.innerWidth / 1920 * 823 - 754)}px`, width: `${(windowSize.innerWidth / 1920 * 823 - 723)}px`, borderBottom: '1px solid #5E9BE1' }}></div>
          <div className="rect-defcon-line-left" style={{ marginLeft: `-${(windowSize.innerWidth / 1920 * 823 - 778)}px` }}></div>
          <div className="box-defcon-border-right">
            <button className="box-defcon-right" onClick={() => { /* console.log('testlog') */

              GetAccesstokenToken((callback) => {
                if (callback) {
                  checkTeamView();
                }
              });
            }}>
              <img

                className='mr-2'
                style={{ width: '25px', height: '25px', marginTop: '-6px', marginLeft: '-50px' }}
                src={logo_user} />
              <span >{`${projectActive.pp_team_member || 0}/${projectActive.spaceship_size || 0}`}</span>
            </button>

          </div>
          <div className="box-frame4-right-border">
            <button className="box-frame4-right" style={{ paddingLeft: '124px', fontSize: '12px' }}>
              {projectActive.pp_start_date} -   {projectActive.pp_finish_date}
              {/*  21 MAY 2023 - 30 JULY 2023 */}
            </button>
          </div>
          <button className="ml-1 box-risk" style={{ paddingRight: '20px', fontSize: '18px' }}
            onClick={() => {

              if ((riskIssue.isOpen != true && riskIssue.onMenu != 'RISK') || (riskIssue.isOpen === true && riskIssue.onMenu != 'RISK')) {
                setRiskIssueTabFunc('WAITING', 'RISK')
                // getRiskIssueData('RISK')
                setRiskIssue({ isOpen: true, onMenu: 'RISK' })
                riskIssue.onMenu = 'RISK'
                setFetchStatus('RISK');
                // console.log('testlog', riskIssue.isOpen);
                if (param.setMenuValue != undefined) {
                  param.setMenuValue(true)
                }
              }
            }}>
            <b>RISK {riskIssueCount.riskCount > 0 ? `(${riskIssueCount.riskCount})` : ''}</b>
          </button>
          <button className="mr-1 box-issue" style={{ paddingLeft: '20px', fontSize: '18px' }}
            onClick={() => {
              /*  setRiskIssue({ isOpen: false, onMenu: 'ISSUE' })
               riskIssue.onMenu = 'ISSUE'; */
              if ((riskIssue.isOpen != true && riskIssue.onMenu != 'ISSUE') || (riskIssue.isOpen === true && riskIssue.onMenu != 'ISSUE')) {
                setRiskIssueTabFunc('WAITING', 'ISSUE')
                // getRiskIssueData('ISSUE')
                setRiskIssue({ isOpen: true, onMenu: 'ISSUE' })
                riskIssue.onMenu = 'ISSUE';
                setFetchStatus('ISSUE');
                // console.log('testlog', riskIssue.isOpen);
                if (param.setMenuValue != undefined) {
                  param.setMenuValue(true)
                }
              }
            }}>
            <b>ISSUE {riskIssueCount.issueCount > 0 ? `(${riskIssueCount.issueCount})` : ''}</b>
          </button>
          <div className="box-risk-line"></div>
          <div className="box-issue-line"></div>



          <div className="box-frame4-right-right-border">
            <button className="box-frame4-right-right" style={{ textAlign: 'right', paddingRight: '5px', paddingBottom: '45px' }}>
              PROJECT <br />
              MANAGEMENT

            </button>

          </div>
          <div className="rect-right-box-right-line"></div>
          <div className="rect-right-box-right"
          ></div>


        </div>
        <hr className='line-bottom2' style={{ zIndex: '99', borderColor: colordefcon.color }} />







        {/* ------------------------------------------------------------ */}



        <div className="container-fluid">
          <div className="mb-2 row">
            <div className="table-responsive" style={{ display: "none" }}>
              <table
                id="table-to-xls-reportusermaping"
                className="table table-head-fixed"
                border="1"
                style={{ border: 'none' }}
              >
                <thead >

                  <tr >
                    <th style={{ height: '30px', border: 'none', backgroundColor: DatareportMapingUser.color.header, color: '#ffffff', textDecorationLine: 'underline' }} colspan={DatareportMapingUser.columns.length}> User Role Management</th>
                  </tr>
                  <tr style={{ border: 'none' }} />
                  <tr >
                    <th style={{ height: '22px', border: 'none', backgroundColor: DatareportMapingUser.color.loop, textAlign: 'left', fontSize: '11px', paddingTop: '4px' }} colspan={DatareportMapingUser.columns.length}> เลือกให้ User เห็นแค่เมนูเฉพาะที่เลือกเท่านั้น</th>
                  </tr>
                  <tr >
                    <th style={{ height: '22px', border: 'none', backgroundColor: DatareportMapingUser.color.loop, textAlign: 'left', fontSize: '11px' }} colspan={DatareportMapingUser.columns.length}> 1. เลือก &#10004; เฉพาะเมนูที่สามารถใช้งานได้</th>
                  </tr>
                  <tr >
                    <th style={{ height: '22px', border: 'none', backgroundColor: DatareportMapingUser.color.loop, textAlign: 'left', fontSize: '11px', paddingBottom: '4px' }} colspan={DatareportMapingUser.columns.length}>2. เลือก &#x2718; เฉพาะเมนูที่ไม่สามารถใช้งานได้</th>
                  </tr>
                  <tr style={{ border: 'none' }} />

                  {DatareportMapingUser.columns.map((head) => {
                    return (
                      <>
                        <th style={{ backgroundColor: DatareportMapingUser.color.loop }}>{head.label}</th>
                      </>
                    );
                  })}
                  <tbody>
                    {DatareportMapingUser.rows.map((data_) => {

                      return (
                        <>
                          <tr>
                            {DatareportMapingUser.columns.map((head, i) => {
                              console.log(i);
                              if (i > 1) {
                                return (
                                  <>
                                    <td style={{ textAlign: 'center', width: '83px' }}>{data_[head.field] != undefined ? (<>&#10004;</>) : ''}</td>
                                  </>
                                );
                              } else {
                                if (i === 0) {
                                  return (
                                    <>
                                      <td style={{ textAlign: `${data_.issup === '0' ? 'left' : 'center'}` }} >{data_[head.field]}</td>
                                    </>
                                  )
                                } else {
                                  return (
                                    <>
                                      <td style={{ textAlign: 'left', paddingLeft: `${data_.issup === '1' ? '15px' : '0px'}` }} >{data_[head.field]}</td>
                                    </>
                                  )
                                }

                              }





                            })}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>


                </thead>
                <tbody>
                </tbody>
              </table>


            </div>
          </div>
        </div>

        <div
          className="col "
          style={{ textAlign: "right", display: "none" }}
        >
          <div className="form-group " >
            <ReactHTMLTableToExcel
              id="reportusermaping"
              className="btn btn-block btn-primary"
              table="table-to-xls-reportusermaping"
              filename={
                "Report Mapping User" +
                moment(new Date()).format("DD-MM-yyyy")
              }
              sheet="tablexls"
              buttonText="Download as XLS"
            />
          </div>
        </div>




        <hr className='line-bottom2' style={{ zIndex: '99', borderColor: colordefcon.color }} />
      </> : <></>}

      <RiskIssue
        isOpen={riskIssue.isOpen}
        setRiskIssue={setRiskIssue}
        getCountRiskIssue={getCountRiskIssue}
        onMenu={riskIssue.onMenu}
        getData={riskIssueGetData}
        checkMenu={checkMenu}
        getRiskIssueData={getRiskIssueData}
        getProjectId={projectActive.pf_project_id}
        setMenuValue={param.setMenuValue}
        riskIssueTab={riskIssueTab}
        setRiskIssueTabFunc={setRiskIssueTabFunc}
        checkStatusRiskManage={checkStatusRiskManage}
      ></RiskIssue>

      <ProjectState
        isOpen={projectState.isOpen}
        setProjectState={setProjectState}
        close={param.setMenuValue}
        projectId={projectActive.pf_project_id}
      ></ProjectState>

      <ModalProject
        openModal={openModal}
        callBackModalOp={callBackModalOp}
        callBackFucEditModal={callBackFucEditModal}
        projectTemp={projectActive}
        isSaves={isSaves}
        setMenuValue={param.setMenuValue}
        eventChangeSaveVersion={eventChange}

      />



      <ModalAddModule isOpen={modalAddModule} toggle_modal_add_module={toggle_modal_add_module} project_id={moduleAndMenuKey.project_id} getProjectDetail={getProjectDetail} />
      <ModalAddMenu isOpen={modalAddMenu} toggle_modal_add_menu={toggle_modal_add_menu} project_id={moduleAndMenuKey.project_id} moduleOption={moduleOption} getProjectDetail={getProjectDetail} />

      <ModalImportStage isOpen={modalImportStage} toggle_modal_import={toggle_modal_import} project_id={moduleAndMenuKey.project_id} moduleOption={[]} getProjectDetail={getProjectDetail} />

      <Chating
        isOpen={chating.isOpen}
        setChating={setChating}
        getProjectId={projectActiveTag ? projectActiveTag : projectActive}
        close={param.setMenuValue}
        setProjectActiveTag={setProjectActiveTag}
        chatIndexNo={chatIndexNo}
        setChatIndexNo={setChatIndexNo}
      ></Chating>

      <CommentReport
        isOpen={commentReport}
        toggle_modal_commentReport={toggle_modal_commentReport}
        ModuleDetails={templateComment}
      />

      <ModelAlertMassge
        isOpen={modalMessageOpen}
        toggle_alertKick={() => { toggle_message_modal() }}
        message={{
          message: 'If no further transaction is performed, the automatic system will exit within 1 minute.', isError: null, menuTitle: "You haven't done anything for 30 minutes."
        }}
      />


      <TopUpModal
        isOpen={ModalTopUp}
        toggle_modal_ModalTopUp={() => toggle_modal_ModalTopUp()}
        getAmountSapphireFnc={getAmountSapphire}
      />


      <ModalSRS
        isOpen={modalSrs.isOpen}
        setModalSrs={setModalSrs}
        onMenu={modalSrs.onMenu}
        nameMenu={modalSrs.nameMenu}
      />
      <div className='listMailBoxBg' style={{ display: `${mailBox ? '' : 'none'}` }} onClick={() => {
        toggle_modal_mailBox()
        if (param.setMenuValue != undefined) {
          param.setMenuValue(false)
        }

      }}></div>
      <div className='listMailBox' style={{ display: `${mailBox ? '' : 'none'}` }}>
        <div className='listMailBoxHead'>NOTIFICATIONS</div>
        <div className='listMailBoxTabList'>
          <div className={mailTab.is == 'ALL' ? 'listMailBoxTabActive' : 'listMailBoxTab'} onClick={() => {
            if (mailTab.is != 'ALL') {
              setMailTab({ is: 'ALL' })
              mailTab.is = 'ALL'
            }
          }}>ALL</div>
          <div className={mailTab.is == 'UNREAD' ? 'listMailBoxTabActive' : 'listMailBoxTab'} onClick={() => {
            if (mailTab.is != 'UNREAD') {
              setMailTab({ is: 'UNREAD' })
              mailTab.is = 'UNREAD'
            }
          }}><div className='unreadTabDot' style={{ display: `${mailUnReadCount > 0 && mailTab.is != 'UNREAD' ? '' : 'none'}` }}></div>UNREAD</div>
        </div>
        <div className='mt-2 listMailBoxLine'></div>
        <div className='mt-2 listMailBoxList'>

          {mailList.map((e, index) => {
            /* console.log('testlogProject', e); */
            if (mailTab.is == 'ALL') {
              return (
                <>
                  <div className='row mailBoxList' style={{ cursor: 'pointer' }} onClick={() => {
                    updateReadMail(e.noti_id);
                  }}>
                    <div className='col-2 setColMail'>
                      <img className='mailBoxUserProfile'
                        src={`${e.usr_profile ? e.usr_profile /* e.userProfile !== null ? `${Configs.TTT_Game_Service_IMG}${e.userProfile}` */ : userProfileDefualt}`}
                      >
                      </img>
                    </div>
                    <div className='col-8 mailBox'>
                      <div>{e.sendBy} <span className='mailBoxTextWeigth'> {e.header} </span> {e.noti_project_id ? e.projectName : e.sendSpaceshipName}</div>
                      <div className='mailBoxTextWeigth'>{e.sendSpaceshipName} • {SetDayAg(e.createDate)}</div>
                      <div className='mailBoxButton' style={{ display: `${e.type == 'tag_project' || e.type == 'tag_chat' ? '' : 'none'}` }}>
                        <div className='ButtonAccept' style={{ border: '2px solid blue' }} onClick={() => {
                          let temp = JSON.parse(e.noti_link_to);
                          if (e.type == 'tag_project') {
                            window.location.href = `/StageMonitoring?projectKey=${temp.project_id}&menuKey=${temp.menu_id}&processKey=${temp.process_id}&indexKey=${temp.index}`;
                          } else if (e.type == 'tag_chat') {
                            setChatIndexNo(temp.index);
                            setChating({ ...chating, isOpen: true });
                            chating.isOpen = true
                            setProjectActiveTag(temp);
                            if (param.setMenuValue != undefined) {
                              param.setMenuValue(true)
                            }
                            mailBox && toggle_modal_mailBox();
                          }
                        }}>GO TO</div>
                      </div>
                      <div className='mailBoxButton' style={{ display: `${e.type == 'invite_spaceship' ? '' : 'none'}` }}>
                        <div className='ButtonAccept' onClick={() => {
                          acceptToSpaceship(e.noti_spaceship_id, sessionStorage.getItem('user_id'));
                          addNotification(true, e.sendById, e.sendBy, e.spaceshipId, "invite_spaceship")
                        }}>ACCEPT</div>
                        <div className='ButtonDenied' onClick={() => {
                          deniedToSpaceship(e.noti_spaceship_id, sessionStorage.getItem('user_id'));
                          addNotification(false, e.sendById, e.sendBy, e.spaceshipId, 'invite_spaceship')
                        }}>DENIED</div>
                      </div>

                      <div className='mailBoxButton' style={{ display: `${e.type == 'invite_on_space_' ? '' : 'none'}` }}>
                        <div className='ButtonAccept' onClick={() => {
                          AccpectMemberOnSpace(e.noti_spaceship_id, sessionStorage.getItem('user_id'), e.noti_user_id, e.noti_id, e.noti_project_id);
                          /*    addNotification(true, e.sendById, e.sendBy, e.spaceshipId, "invite_on_space_") */
                        }}>ACCEPT</div>
                        <div className='ButtonDenied' onClick={() => {
                          DeclupMemberOnSpace(e.noti_spaceship_id, sessionStorage.getItem('user_id'), e.noti_user_id, e.noti_id, e.noti_project_id);
                          /*    addNotification(false, e.sendById, e.sendBy, e.spaceshipId, 'invite_on_space_') */
                        }}>DENIED</div>
                      </div>

                      <div className='mailBoxButton' style={{ display: `${e.type == 'invite_on_space__' ? '' : 'none'}` }}>
                        <div className='listMailDate'>You have successfully responded.</div>
                      </div>
                      {/*  <div className='mailBoxButton' style={{ display: `${e.type == 'invite_on_space_succ_' ? '' : 'none'}` }}>
                        <div className='listMailDate'>You have successfully responded.</div>
                      </div> */}
                      <div className='mailBoxButton' style={{ display: `${e.type == 'invite_spaceship_' ? '' : 'none'}` }}>
                        <div className='listMailDate'>You have successfully responded.</div>
                      </div>

                      <div className='mailBoxButton' style={{ display: `${e.type == 'offer_project' ? '' : 'none'}` }}>
                        <div className='ButtonAccept' onClick={() => {
                          let temp = {
                            is_accept: true,
                            user_id: e.sendById,
                            user_name: e.sendBy,
                            spaceshipId: e.spaceshipId,
                            mode: "offer_project",
                            project_id: e.noti_project_id
                          }
                          acceptToProject(e.noti_project_id, sessionStorage.getItem('user_id'), e.noti_spaceship_id, temp);
                          /*     addNotification(true, e.sendById, e.sendBy, e.spaceshipId,'offer_project',e.noti_project_id) */
                        }}>ACCEPT</div>
                        <div className='ButtonDenied' onClick={() => {
                          let temp = {
                            is_accept: false,
                            user_id: e.sendById,
                            user_name: e.sendBy,
                            spaceshipId: e.spaceshipId,
                            mode: "offer_project",
                            project_id: e.noti_project_id
                          }
                          deniedToProject(e.noti_project_id, sessionStorage.getItem('user_id'), e.noti_spaceship_id, temp);
                          /*      addNotification(false, e.sendById, e.sendBy, e.spaceshipId,'offer_project',e.noti_project_id) */
                        }}>DENIED</div>
                      </div>
                      <div className='mailBoxButton' style={{ display: `${e.type == 'offer_project_' ? '' : 'none'}` }}>
                        <div className='listMailDate'>You have successfully responded.</div>
                      </div>
                      <div className='mailBoxButton' style={{ display: `${e.type == 'offer_project_aleady' ? '' : 'none'}` }}>
                        <div className='listMailDate'>You have Join Project Fail.</div>
                      </div>

                    </div>
                    {e.sps_profile && (<div className='col-2 setColMail'>
                      <img className='mailBoxSpaceshipProfile '
                        src={`${e.sps_profile ? e.sps_profile /* e.spaceshipProfile !== null ? `${Configs.TTT_Game_Service_IMG}${e.spaceshipProfile}` */ : ''}`}
                      >
                      </img>
                    </div>)}

                  </div>
                </>
              )
            } else if (mailTab.is == 'UNREAD') {
              if (e.noti_is_read == false) {
                return (
                  <>
                    <div className='row mailBoxList' style={{ cursor: 'pointer' }} onClick={() => {
                      updateReadMail(e.noti_id);
                    }}>
                      <div className='col-2 setColMail'>
                        <img className='mailBoxUserProfile'
                          src={`${e.usr_profile ? e.usr_profile /* :e.userProfile !== null ? `${Configs.TTT_Game_Service_IMG}${e.userProfile}` */ : userProfileDefualt}`}
                        >
                        </img>
                      </div>
                      <div className='col-8 mailBox'>
                        {/*            <div>{e.sendBy} <span className='mailBoxTextWeigth'> {e.header} </span> {e.sendSpaceshipName}</div> */}
                        <div>{e.sendBy} <span className='mailBoxTextWeigth'> {e.header} </span> {e.noti_project_id ? e.projectName : e.sendSpaceshipName}</div>
                        <div className='mailBoxTextWeigth'>{e.sendSpaceshipName} • {SetDayAg(e.createDate)}</div>
                        <div className='mailBoxButton' style={{ display: `${e.type == 'tag_project' || e.type == 'tag_chat' ? '' : 'none'}` }}>
                          <div className='ButtonAccept' style={{ border: '2px solid blue' }} onClick={() => {
                            let temp = JSON.parse(e.noti_link_to);
                            if (e.type == 'tag_project') {
                              window.location.href = `/StageMonitoring?projectKey=${temp.project_id}&menuKey=${temp.menu_id}&processKey=${temp.process_id}&indexKey=${temp.index}`;
                            } else if (e.type == 'tag_chat') {
                              setChatIndexNo(temp.index);
                              setChating({ ...chating, isOpen: true });
                              chating.isOpen = true
                              setProjectActiveTag(temp);
                              if (param.setMenuValue != undefined) {
                                param.setMenuValue(true)
                              }
                              mailBox && toggle_modal_mailBox();
                            }
                          }}>GO TO</div>
                        </div>
                        <div className='mailBoxButton' style={{ display: `${e.type == 'invite_spaceship' ? '' : 'none'}` }}>
                          <div className='ButtonAccept' onClick={() => {
                            acceptToSpaceship(e.noti_spaceship_id, sessionStorage.getItem('user_id'));
                            addNotification(true, e.sendById, e.sendBy, e.spaceshipId)
                          }}>ACCEPT</div>
                          <div className='ButtonDenied' onClick={() => {
                            deniedToSpaceship(e.noti_spaceship_id, sessionStorage.getItem('user_id'));
                            addNotification(false, e.sendById, e.sendBy, e.spaceshipId)
                          }}>DENIED</div>
                        </div>

                        <div className='mailBoxButton' style={{ display: `${e.type == 'invite_on_space_' ? '' : 'none'}` }}>
                          <div className='ButtonAccept' onClick={() => {
                            AccpectMemberOnSpace(e.noti_spaceship_id, sessionStorage.getItem('user_id'), e.noti_user_id, e.noti_id, e.noti_project_id);
                            /*    addNotification(true, e.sendById, e.sendBy, e.spaceshipId, "invite_on_space_") */
                          }}>ACCEPT</div>
                          <div className='ButtonDenied' onClick={() => {
                            DeclupMemberOnSpace(e.noti_spaceship_id, sessionStorage.getItem('user_id'), e.noti_user_id, e.noti_id, e.noti_project_id);
                            /*    addNotification(false, e.sendById, e.sendBy, e.spaceshipId, 'invite_on_space_') */
                          }}>DENIED</div>
                        </div>

                        <div className='mailBoxButton' style={{ display: `${e.type == 'invite_on_space__' ? '' : 'none'}` }}>
                          <div className='listMailDate'>You have successfully responded.</div>
                        </div>

                        <div className='mailBoxButton' style={{ display: `${e.type == 'invite_spaceship_' ? '' : 'none'}` }}>
                          <div className='listMailDate'>You have successfully responded.</div>
                        </div>
                        <div className='mailBoxButton' style={{ display: `${e.type == 'offer_project' ? '' : 'none'}` }}>
                          <div className='ButtonAccept' onClick={() => {
                            let temp = {
                              is_accept: true,
                              user_id: e.sendById,
                              user_name: e.sendBy,
                              spaceshipId: e.spaceshipId,
                              mode: "offer_project",
                              project_id: e.noti_project_id
                            }
                            acceptToProject(e.noti_project_id, sessionStorage.getItem('user_id'), e.noti_spaceship_id, temp);
                            /*      addNotification(true, e.sendById, e.sendBy, e.spaceshipId, 'offer_project', e.noti_project_id) */
                          }}>ACCEPT</div>
                          <div className='ButtonDenied' onClick={() => {
                            let temp = {
                              is_accept: false,
                              user_id: e.sendById,
                              user_name: e.sendBy,
                              spaceshipId: e.spaceshipId,
                              mode: "offer_project",
                              project_id: e.noti_project_id
                            }
                            deniedToProject(e.noti_project_id, sessionStorage.getItem('user_id'), e.noti_spaceship_id, temp);
                            /*     addNotification(false, e.sendById, e.sendBy, e.spaceshipId, 'offer_project', e.noti_project_id) */
                          }}>DENIED</div>
                        </div>
                        <div className='mailBoxButton' style={{ display: `${e.type == 'offer_project_' ? '' : 'none'}` }}>
                          <div className='listMailDate'>You have successfully responded.</div>
                        </div>
                        <div className='mailBoxButton' style={{ display: `${e.type == 'offer_project_aleady' ? '' : 'none'}` }}>
                          <div className='listMailDate'>You have Join Project Fail.</div>
                        </div>
                      </div>

                      {e.sps_profile && (
                        <div className='col-2 setColMail'>
                          <img className='mailBoxSpaceshipProfile '
                            src={`${e.sps_profile ? e.sps_profile /* :  e.spaceshipProfile !== null ? `${Configs.TTT_Game_Service_IMG}${e.spaceshipProfile}`  */ : ''}`}
                          >
                          </img>
                        </div>)}
                    </div>
                  </>
                )
              }

            }

          })}
        </div>


      </div>

      {/* team view popup */}
      <div className='teamViewBg' style={{ display: `${teamViewPopUp ? '' : 'none'}` }}>
        <div className='teamViewBoxShadow'>
          <div className='teamViewBox'>

            <div className='teamViewHeaderBox'>
              <div className='teamViewHeaderShadow'>
                <div className='teamViewHeader'>TEAM DETAIL</div>
              </div>
              <div className="teamViewClose" style={{ padding: '0px', margin: '0px' }} onClick={() => {
                toggle_teamViewPopUp()
              }}>
                <i class="fa fa-times" aria-hidden="true" style={{ fontSize: "30px" }}></i>
              </div>
            </div>



            <div className='teamViewSpaceBox' style={{ backgroundImage: `url(${teamViewData.ul_file_path/* config.TTT_Game_Service_IMG+teamViewData.ul_file_path */})` }}>
              <div className='teamViewSpaceBoxGradiant'>
                <div className='teamViewSpaceName'>{teamViewData.spaceship_name}</div>
                <div className='teamViewSpaceDesc'>{teamViewData.spaceship_description}</div>
              </div>
            </div>

            <div className='teamViewUserBox'>
              <div className='teamViewLeaderBox'>
                <div className='teamViewLeader' onClick={() => {
                  setValueAndOpenView(teamViewData.leader.pf_usr_id)
                }}>
                  <svg height="100%" width="100%">
                    <line x1="16" y1="55" x2="16" y2="6" style={{ stroke: "#06F", strokeWidth: "2" }} />
                    <line x1="16" y1="6" x2="50" y2="6" style={{ stroke: "#06F", strokeWidth: "2" }} />

                    <line x1="135" y1="6" x2="170" y2="6" style={{ stroke: "#06F", strokeWidth: "2" }} />
                    <line x1="170" y1="6" x2="170" y2="55" style={{ stroke: "#06F", strokeWidth: "2" }} />

                    <line x1="170" y1="130" x2="170" y2="175" style={{ stroke: "#06F", strokeWidth: "2" }} />
                    <line x1="170" y1="175" x2="135" y2="175" style={{ stroke: "#06F", strokeWidth: "2" }} />

                    <line x1="55" y1="175" x2="16" y2="175" style={{ stroke: "#06F", strokeWidth: "2" }} />
                    <line x1="16" y1="175" x2="16" y2="129" style={{ stroke: "#06F", strokeWidth: "2" }} />
                  </svg>

                  <div className='teamViewLeaderImg' style={{ backgroundImage: `url(${teamViewData.leader && (teamViewData.leader.ul_file_path !== undefined && teamViewData.leader.ul_file_path !== null && teamViewData.leader.ul_file_path !== '') ? `${teamViewData.leader.ul_file_path/* Configs.TTT_Game_Service_IMG}${teamViewData.leader.ul_file_path */}` : defaultUserLogo})` }}></div>

                  <div className='teamViewLeaderDetail'>
                    <div className='teamViewLeaderHeader'>
                      <div className='teamViewLeaderHeaderIn'>LEADER</div>
                    </div>

                    <div className='teamViewLeaderName'>{teamViewData.leader && teamViewData.leader.usr_ttt_id} {teamViewData.leader && teamViewData.leader.usr_name} {teamViewData.leader && teamViewData.leader.usr_lastname}</div>
                    <div className='teamViewLeaderRole'>{teamViewData.leader && teamViewData.leader.usr_role_name}</div>

                    <div className='teamViewLeaderLeft1'></div>
                    <div className='teamViewLeaderLeft2'></div>

                    <div className='teamViewLeaderRight1'></div>
                    <div className='teamViewLeaderRight2'></div>

                    <div className='teamViewLeaderBottom1'></div>
                    <div className='teamViewLeaderBottom2'></div>

                    <div className='teamViewLeaderLeftTop'>
                      <div className='teamViewLeaderLeftTopIn'></div>
                      <div className='teamViewLeaderLeftTopIn'></div>
                      <div className='teamViewLeaderLeftTopIn'></div>
                      <div className='teamViewLeaderLeftTopIn'></div>
                      <div className='teamViewLeaderLeftTopIn'></div>
                      <div className='teamViewLeaderLeftTopIn'></div>
                    </div>

                    <div className='teamViewLeaderRightBottom'>
                      <div className='teamViewLeaderLeftTopIn'></div>
                      <div className='teamViewLeaderLeftTopIn'></div>
                      <div className='teamViewLeaderLeftTopIn'></div>
                      <div className='teamViewLeaderLeftTopIn'></div>
                      <div className='teamViewLeaderLeftTopIn'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='teamViewUserBody'>
                {teamViewData.users && teamViewData.users.map((e, index) => {
                  /*  console.log('listUser', e); */
                  const tooltip = `${e.usr_ttt_id} ${e.usr_name} ${e.usr_lastname}`;
                  if (e.su_is_leader !== true) {
                    return (
                      <>
                        <div className='teamViewUser'>
                          <div className={`teamViewUserImg  ${e.pf_is_block == true ? 'banGay' : ''}`} style={{ backgroundImage: `url(${e.ul_file_path !== undefined && e.ul_file_path !== null ? `${e.ul_file_path/* Configs.TTT_Game_Service_IMG + e.ul_file_path */}` : defaultUserLogo})` }} onClick={() => {
                            setValueAndOpenView(e.pf_usr_id)
                          }}></div>

                          <div className='teamViewUserDetail'>
                            {leadrUserId.leader_id === sessionStorage.getItem('user_id') ? (<>
                              {e.pf_is_block == true ? <div className='unblockUser notban' onClick={() => {
                                setTextModalComfrim({
                                  ...textModalComfrim,
                                  text_1: '! Unblock This Member !',
                                  text_2: 'Are you sure to unblock this member ?',
                                  is_ver: 2,
                                  ban_user_id: e.pf_usr_id
                                });
                                textModalComfrim.text_1 = '! Unblock This Member !';
                                textModalComfrim.text_2 = 'Are you sure to unblock this member ?';
                                textModalComfrim.is_ver = 2;
                                textModalComfrim.ban_user_id = e.pf_usr_id;
                                toggle_modal_confrimModal()
                              }}>
                                <img src={unlockImg} style={{ width: '26px', height: '26px' }} />
                              </div> : <div className='blockUser notban' onClick={() => {
                                setTextModalComfrim({
                                  ...textModalComfrim,
                                  text_1: '! Block This Member !',
                                  text_2: 'Are you sure to block this member ?',
                                  is_ver: 1,
                                  ban_user_id: e.pf_usr_id
                                });
                                textModalComfrim.text_1 = '! Block This Member !';
                                textModalComfrim.text_2 = 'Are you sure to block this member ?';
                                textModalComfrim.is_ver = 1;
                                textModalComfrim.ban_user_id = e.pf_usr_id;
                                toggle_modal_confrimModal()
                              }} >
                                <StopOutlined />

                              </div>}
                            </>) : <></>}


                            <div className={`teamViewUserDetailIn ${e.pf_is_block == true ? 'banGay' : ''}`}>

                              <Tooltip title={tooltip} color='blue'><div className='teamViewUserName' onClick={() => {
                                setValueAndOpenView(e.pf_usr_id)
                              }} >{e.usr_ttt_id} {e.usr_name} {e.usr_lastname}</div></Tooltip>
                              <Tooltip title={e.usr_role_name} color='blue'><div className='teamViewUserName' onClick={() => {
                                setValueAndOpenView(e.pf_usr_id)
                              }}>{e.usr_role_name}</div></Tooltip>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                })}
              </div>

            </div>

          </div>
        </div>
      </div>

      <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
        <div className='alertKickBoxShadow' >
          <div className='alertKickBox' style={{ height: 'fit-content' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `${errorMessage.status ? 'ffffff' : 'green'}` }}>
                  {errorMessage.status ? 'Loading' : 'SUCCESS'}
                </div>
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning' style={{ border: 'none' }}>
                  {errorMessage.status ? <><div class="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div></> : <></>}
                </div>
              </div>
              {errorMessage.status ? <></> : <>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    {errorMessage.message}

                  </div>
                </div>


                <div className='row alertKickBoxButton'>
                  <button className='alertKickButton' onClick={() => {
                    toggle_modal_alertKick()
                  }}>OK</button>

                </div>
              </>}


            </div>
          </div>
        </div>
      </div>

      <div className='alertKick' style={{ display: `${confrimModal ? '' : 'none'}` }}>
        <div className='alertKickBoxShadow'>
          <div className='alertKickBox'>
            <div className='alertKickBoxIn'>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: 'red' }}>
                  ! WARNING !
                </div>
              </div>
              <div className='row' style={{ height: '20px', width: '70%', borderBottom: '2px solid #FFFF', display: 'flex', justifyContent: 'center', margin: '0 auto' }}> </div>
              <div className='row' style={{ height: '12.5%' }}>
                <div className='alertKickText' style={{ fontSize: '20px', padding: '12px' }}>
                  {textModalComfrim.text_1}
                </div>
              </div>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickTextSec'>
                  {textModalComfrim.text_2}
                </div>
              </div>
              <div className='row alertKickBoxButton' >
                <button className='mr-2 alertKickConfirm' onClick={() => {
                  if (textModalComfrim.is_ver == 1) {
                    banUserbyproject();
                  } else {
                    unBlockUserbyproject();
                  }
                }}>YES</button>

                <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                  toggle_modal_confrimModal()
                }}>NO</button>


              </div>

            </div>
          </div>
        </div>
      </div>



      <ModelAlertMassge
        isOpen={teamViewPopUpEr}
        toggle_alertKick={() => { toggle_teamViewPopUpEr() }}
        message={{
          message: 'This project has not been kicked off yet.', isError: null, menuTitle: "This project has not been kicked off yet."
        }}
      />

      <ModelAlertMassge
        isOpen={teamViewPopUpEr2}
        toggle_alertKick={() => { toggle_teamViewPopUpEr2() }}
        message={{
          message: `YOU CAN'T GET ON THE SPACESHIP. THE SPACESHIP LEADER DOESN'T HAVE ENOUGH SAPPHIRE`, isError: null, menuTitle: "! Everything comes with a price !"
        }}
      />

      <DashBordFederation
        isOpen={dashFedModal}
        toggle_dashFedModal_modal={() => { toggle_dashFedModal_modal() }}

      />

      <ViewUserProfile
        isOpen={personalInfoViewCheck.isOpen}
        fncSetProfile={pathProfile}
        submode={personalInfoViewCheck}
        setPersonalInfoCheck={setPersonalInViewfoCheck}
        close={null}
        tempValue={tempValue}
      />

      <FederationMgt
        isOpen={fedMgtConfig}
        toggle_modal_fedMgtConfig={toggle_modal_fedMgtConfig}
        getFederationDetailByOwner={getFederationDetailByOwner}
        CallbackImg={CallbackImg}
        onElement={onElement}
        closeElement={closeElement}
      />
      {clearClasname === false ? <CompleteYourSkill
        isOpen={openModalCompleteSkill}
        toggle_openModalCompleteSkill_modal={toggle_openModalCompleteSkill_modal}
        isRefsSkill={isRefsSkill}
        setIsRefsSkill={setIsRefsSkill}
        personalInfoCheck={personalInfoCheck.isOpen}
      /> : <></>}

      {/* <SlotLocationCompon
        isOpen={true}
       getKeyItem={getKeyItem} 
       openElement={openElement}
     
       onElement={onElement}
      /> */}


    </>
  )
}

export default LandingPagestage
