import React, { useEffect, useState, useRef } from 'react'
import '../risk&Issue/riskIssue.css'
import axios from "axios";
import Configs from "../../../config";
import moment from "moment";
import { func } from 'prop-types';
import { io } from 'socket.io-client';
import IconRiskManagement from "../../../assets/img/icon/Riskmanagement.png";
/* const socket = io("http://150.95.24.71:7776"); */

const RiskIssue = ({
    isOpen,
    setRiskIssue,
    onMenu,
    getData,
    checkMenu,
    getRiskIssueData,
    getProjectId,
    setMenuValue,
    riskIssueTab,
    setRiskIssueTabFunc,
    getCountRiskIssue,
}) => {
    const [riskData, setRiskData] = useState([])
    const [userismode, setuserismode] = useState(true)
    const [issueData, setIssueData] = useState([])
    const [sendText, setSendText] = useState({
        text: ''
    })
    const [timeSend, setTimeSend] = useState(true)
    const [firstAnimetion, setFirstAnimetion] = useState(true)
    const [isAnimetion, setIsAnimetion] = useState()
    const [test, setTest] = useState({ test: '' })
    const [idSpaceshipAndFederation, setidSpaceshipAndFederation] = useState({
        spaceship_id:"",
        federation_id:"",
        project_name:""
    });
    
    async function sendTextData() {
       
        // let temp = getData
        // let addData = {
        //     pri_comment: sendText.text
        // }
        // temp.push(addData)
        // setRiskIssueData(temp)
        // // console.log('testlog',riskIssueData);
        // setTest({...test,test:''});
        // test.test = '';
        // console.log('testlog',onMenu);
        let temp = {
            pri_project_id: getProjectId,
            pri_type: onMenu,
            pri_comment: sendText.text,
            pri_created_by: sessionStorage.getItem('user_id'),
            pri_created_name: sessionStorage.getItem('user'),
            filelist: []
        }
   
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/rickissue/sendData`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                socketRef.current.emit('chat', { message: sendText.text, room: onMenu + getProjectId, onMenu: onMenu, project_id: getProjectId, tab: riskIssueTab.is });
          
                let temp2 = {
                    SaveRiskManagement:{
                        rmr_pri_id:response.data,
                        rmr_rc_id: '',
                        rmr_descriptions: sendText.text,
                        rmr_probability: 0,
                        rmr_impact: 0,
                        rmr_exposure: '',
                        rmr_rms_id_1: '',
                        rmr_mitigation_plan: '',
                        rmr_contingency_plan: '',
                        rmr_target_date: moment(new Date()).toISOString(),
                        rmr_rms_id_2: '81ba9127-d22a-4bc5-9f77-f51ca1ede2ff',
                        rmr_closed_date: null,
                        rmr_remark: null,
                        rmr_project_id: getProjectId,
                        rmr_federation_id: idSpaceshipAndFederation.federation_id,
                        rmr_spaceship_id: idSpaceshipAndFederation.spaceship_id,
                        rmr_is_use: true,
                        rmr_created_date:  moment(new Date()).toISOString(),
                        rmr_created_by: sessionStorage.getItem('user_id'),
                        rmr_updated_date:  moment(new Date()).toISOString(),
                        rmr_updated_by: sessionStorage.getItem('user_id'),
                    }}
                await axios({
                    method: 'post',
                    url: `${Configs.TTT_Game_Service}/api/riskManagement/saveChatRecordRiskmanagement`,
                    headers: {
                        'Authorization': sessionStorage.getItem('access_token'),
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'application/json',
                    },
                    data: temp2
                })
                    .then(async function (response) {   

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                /*         socketRef.current.emit("chat message",{onMenu:onMenu,project_id:getProjectId}); */
                /*     getRiskIssueData(onMenu, 'SEND') */
                setSendText({ ...sendText, text: '' })
                sendText.text = ''
                setTest({ ...test, test: '' });
                test.test = '';

                document.getElementById("sendtext").style.height = "40px";
                document.getElementById("sendtext").style.top = "0px";

                getCountRiskIssue(getProjectId);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    
    async function getIdSpaceshipAndFederation() {

        let temp = {
            project_id:getProjectId
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/getIdSpaceshipAndFederation`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                // console.log(response.data);  
                
                setidSpaceshipAndFederation({...idSpaceshipAndFederation,
                    spaceship_id:response.data.spaceship_id,
                    federation_id:response.data.federation_id,
                    project_name:response.data.project_name
                 });
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function setTimeClose() {
        const intervalClose = setInterval(() => {
            setRiskIssue({ isOpen: false })
            setIsAnimetion(true)
            clearInterval(intervalClose);
        }, 900);
    }

    function setTimeToSend() {
        if (timeSend) {
            getRiskIssueData(onMenu, 'SEND')
            setTimeSend(false)
        }

        const intervalClose = setInterval(() => {
            setTimeSend(true)
            clearInterval(intervalClose);
        }, 5000);
    }

    const [editData, setEditData] = useState({
        edit_open: false,
        edit_text: '',
        pri_comment: '',
        pri_created_name: '',
        pri_created_date: '',
        pri_id: '',
        pri_updated_date: ''
    })

    async function editRiskIssue(pri_comment, pri_created_name, pri_created_date, pri_id, pri_updated_date) {
        setEditData({ ...editData, edit_open: true, edit_text: pri_comment, pri_comment: pri_comment, pri_created_name: pri_created_name, pri_created_date: pri_created_date, pri_id: pri_id, pri_updated_date: pri_updated_date })
        editData.edit_open = true
    }

    async function editClose() {
        setEditData({ ...editData, edit_open: false })
        editData.edit_open = false
        // if (editData.edit_text != editData.pri_comment) {

        // } else {
        //     setEditData({...editData, edit_open: false})
        //     editData.edit_open = false
        // }
    }

    async function editRefresh() {
        setEditData({ ...editData, edit_text: editData.pri_comment })
        editData.edit_text = editData.pri_comment
    }

    async function editSubmit() {
        // console.log('testlog',editData.pri_id, editData.edit_text);

        let temp = {
            pri_id: editData.pri_id,
            pri_comment: editData.edit_text,
            pri_update_by: sessionStorage.getItem('user_id'),
            pri_update_name: sessionStorage.getItem('user'),
            filelist: []
        }
        let temp2 = {
            rmr_pri_id:editData.pri_id,
            rmr_descriptions:editData.edit_text,
            rmr_updated_by: sessionStorage.getItem('user_id'),
            rmr_updated_date:  moment(new Date()).toISOString(),
        }

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/rickissue/updateData`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                socketRef.current.emit('chat', { message: sendText.text, room: onMenu + getProjectId, onMenu: onMenu, project_id: getProjectId });

                await axios({
                    method: 'post',
                    url: `${Configs.TTT_Game_Service}/api/riskManagement/editChatRecordRiskmanagement`,
                    headers: {
                        'Authorization': sessionStorage.getItem('access_token'),
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'application/json',
                    },
                    data: temp2
                })
                    .then(async function (response) {  

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                /*    socketRef.current.emit("chat message",{onMenu:onMenu,project_id:getProjectId}); */
                /*  getRiskIssueData(onMenu, 'SEND') */
                setEditData({ ...editData, edit_open: false })
                editData.edit_open = false
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [confirmMode, setConfirmMode] = useState({
        mode: ''
    });
    const [confirmBox, setConfirmBox] = useState(false);
    const toggle_modal_confirmBox = () => setConfirmBox(!confirmBox);
    const [confirmData, setConfirmData] = useState({

    })

    async function updateToIssue() {
        let temp = {
            pri_id: confirmData.pri_id,
            pri_project_id: confirmData.pri_project_id,
            pri_type: 'ISSUE',
            pri_comment: confirmData.pri_comment,
            pri_created_by: confirmData.pri_created_by,
            pri_created_name: confirmData.pri_created_name,
            filelist: []
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/rickissue/updateToIssue`,
            headers: {
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                socketRef.current.emit('chat', { message: sendText.text, room: onMenu + getProjectId, onMenu: onMenu, project_id: getProjectId, tab: riskIssueTab.is });
                setTest({ ...test, test: '' });
                test.test = '';

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function updateAchieve(id, is) {
        let temp = {
            pri_id: id,
            is: is,
            filelist: []
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/rickissue/updateAchieve`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                socketRef.current.emit('chat', { message: sendText.text, room: onMenu + getProjectId, onMenu: onMenu, project_id: getProjectId, tab: riskIssueTab.is });
                // setRiskIssueTabFunc(null,onMenu)
                getCountRiskIssue(getProjectId);
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function auto_area() {
        let sendtext = document.getElementById("edittext")

        sendtext.style.height = "50px";
        sendtext.style.height = (sendtext.scrollHeight) + "px";

    }

    async function auto_grow() {
        let sendtext = document.getElementById("sendtext")
        let sendtextint = 0
        // console.log('testlog',  (sendText.text.length == 0 ? 44 : sendtext.scrollHeight) + "px");

        sendtext.style.height = "40px";
        sendtext.style.height = (sendText.text.length == 0 ? 40 : sendtext.scrollHeight) + "px";

        if (parseInt(sendtext.style.height) >= 150) {
            sendtextint = 110
        } else if (parseInt(sendtext.style.height) <= 40 || sendText.text.length <= 1) {
            sendtextint = 0
        } else {
            sendtextint = (parseInt(sendtext.style.height)) - 40
        }

        sendtext.style.top = "-" + sendtextint + "px";
    }

    useEffect(() => {
    }, [getData, onMenu])
    useEffect(() => {
       
      
        // console.log('testlog', getData);
        if (firstAnimetion) {
            setFirstAnimetion(false)
            // firstAnimetion = false
            setIsAnimetion(true)
            // isAnimetion = true



        }

        setuserismode(sessionStorage.getItem('getUserMode'))
        // console.log(userismode);
        if (checkMenu == 'RISK') {
            setRiskData(getData)
        } else if (checkMenu == 'ISSUE') {
            setIssueData(getData)
        }
        /*       setActiveType(onMenu) */
        const interval = setInterval(() => {
            document.getElementById('autoScroll').scrollTop += (getData.length * 1000)
            // console.log('testlog');
            clearInterval(interval);
        }, 150);

        setActiveRoom({ ...activeRoom, room: getProjectId });
        activeRoom.room = getProjectId;


    }, [getData, onMenu])

    //console.log("socket",socket)

    /*     const [setActiveType,setActiveType] = useState(onMenu); */
    const [firstState, setFirstState] = useState({
        risk: true,
        issue: true,
    })

    /*          const [activeType,setActiveType] = useState() */


    /* const socketRef = useRef(null);
    
            useEffect(()=>{
    
                if (socketRef.current == null) {
              socketRef.current = io("http://150.95.24.71:7776");
            }
            },[
    
            ])
    
                    useEffect(()=>{ 
                        if(isOpen === true){
                            socketRef.current.emit('join',onMenu+getProjectId)
                        }else{
                             socketRef.current.emit('leave',onMenu+getProjectId)
                        }
    
                       
            },[isOpen]) */

    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                //path: '/Socket-service/socket.io'
                port:Configs.TTT_Game_Socket_Service_Port
              });
        }
    }, [

    ])
    const [checkStatusRiskManage, setcheckStatusRiskManage] = useState(false);
async function checkStatusRisk(){
    let temp2= {
        project_id:getProjectId || null,
        user_id: sessionStorage.getItem('user_id')
      }

       await axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/rickissue/checkPormissionRiskManagement`,
        headers: {
          /*  Authorization: getToken(), */
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
        data: temp2
      })
        .then(async function (response) {
          setcheckStatusRiskManage(response.data.status_project);
          console.log();
          
        })
        .catch(function (error) {
          console.log(error);
        });
}
    useEffect(() => {
        setuserismode(sessionStorage.getItem('getUserMode'))
        console.log("checkStatusRiskManage",checkStatusRiskManage);
        getIdSpaceshipAndFederation();
        // console.log(userismode);
        // console.log('testlog', onMenu);
        if (isOpen === true && (onMenu !== undefined || onMenu !== '' || onMenu !== null)) {
            checkStatusRisk();
            if (onMenu === "RISK") {
                socketRef.current.emit('leaveOne', "ISSUE" + getProjectId)
                socketRef.current.emit('join', "RISK" + getProjectId)

            } else if (onMenu === "ISSUE") {
                socketRef.current.emit('leaveOne', "RISK" + getProjectId)
                socketRef.current.emit('join', "ISSUE" + getProjectId)

            } else {
                socketRef.current.emit('leaveOne', "RISK" + getProjectId)
                socketRef.current.emit('leaveOne', "ISSUE" + getProjectId)
            }
        } else {
            socketRef.current.emit('leaveOne', 'RISK' + getProjectId)
            socketRef.current.emit('leaveOne', 'ISSUE' + getProjectId)
        }
    }, [onMenu, isOpen])
    /* if (socketRef.current) {
    
    
    } */
    /* socket.emit('join','93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'); */

    /* console.log('onRoom',getProjectId) */
    const [activeRoom, setActiveRoom] = useState({ room: getProjectId });

    useEffect(() => {


        /*     if(getProjectId !== '' ){ */



        const { current: socket } = socketRef;
        const socFnc = (data) => {
            // console.log('onRoom', data)
            getRiskIssueData(data.onMenu, 'SEND', data.project_id, data.tab);

            /*     socket.emit('leave',data.room) */
        }
        try {
            socket.open();
            // console.log('onMenu', 'เข้าEffectแล้ว', activeRoom.room, 'RISK93bf93f2-eb48-4bf7-b1bc-1305cdc13fad')
            /* socket.emit('join','RISK93bf93f2-eb48-4bf7-b1bc-1305cdc13fad') */


            socket.on('chat', socFnc)



            /*  setActiveRoom({...activeRoom,room:'93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'});
                activeRoom.room = '93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'; */
        } catch (error) {
            console.log(error);
        }
        // Return a callback to be run before unmount-ing.

        return () => {
            /*   console.log('onRoom','out') */
            socket.off("chat", socFnc);

            socket.close();
            setActiveRoom({ ...activeRoom, room: '' });
            activeRoom.room = '';
        };
        /* } */



    }, []);
    /*    useEffect(()=>{
           console.log("onMenu",onMenu)
           socketRef.on('chat message',(status)=>{
              
               if(status == true && onMenu !=''){ 
                   
                   console.log('onMenu','เข้าแล้ว')
                   getRiskIssueData(onMenu, 'SEND')}
               
                
              })
   
              return () => {
          
               socket.off('chat message', 'END');
                    socket.disconnect();
             } 
   
       },[getData]) */

    async function  delRiskIssue() {
        let temp = {
            pri_id: confirmData.pri_id
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/delChaRecordRiskmanagement`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'), 
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) { 
                await axios({
                    method: 'post',
                    url: `${Configs.TTT_Game_Service}/api/rickissue/delRiskIssue`,
                    headers: {
                        /*  Authorization: getToken(), */
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'application/json',
                    },
                    data: temp
                })
                    .then(async function (response) {
                        if (response.data) {
                            socketRef.current.emit('chat', { message: sendText.text, room: onMenu + getProjectId, onMenu: onMenu, project_id: getProjectId, tab: riskIssueTab.is });
                            getCountRiskIssue(getProjectId);
        
                     
        
                            setTest({ ...test, test: '' });
                            test.test = '';
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
             })
            .catch(function (error) {
                console.log(error);
            });
    
    }

// console.log(userismode);
    return (
        <>
            <div className='riskIssueBox' style={{ display: `${isOpen ? '' : 'none'}`, animation: `${isAnimetion ? 'openRiskIssue 1s' : 'closeRiskIssue 1s'}` }}>
                {/* <div className='riskIssueBoxTop' style={{ backgroundColor: `${onMenu == 'RISK' ? 'yellow' : 'red'}` }}>
                    <div className='riskIssueBoxTopIn'>
                        <div className='riskIssueBoxTopHead'>
                            {onMenu == 'RISK' ? 'RISK' : 'ISSUE'}
                        </div>
                        <div className='riskIssueBoxTopText' style={{ display: 'none' }}>
                            View All
                        </div>
                       
                    </div>
                </div>
                <div className='riskIssueBoxBottom' style={{ backgroundColor: `${onMenu == 'RISK' ? 'yellow' : 'red'}` }}>
                    <div className='riskIssueBoxBottomIn'></div>
                </div>
                 */}
                <svg height="675" width="600" style={{ position: 'absolute'}}>
                    <line x1="40" y1="8" x2="482.5" y2="8" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="482.5" y1="8" x2="561" y2="74" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="561" y1="74" x2="561" y2="470" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="561" y1="470" x2="534" y2="497" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="536" y1="493" x2="536" y2="665" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="536" y1="666" x2="404" y2="666" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="404" y1="666" x2="376" y2="632" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="376" y1="633" x2="70" y2="633" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="70" y1="633" x2="39" y2="607" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="39" y1="607" x2="39" y2="7" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />

                    <line x1="1" y1="565" x2="1" y2="1" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="1" y1="1" x2="100" y2="1" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />

                    <line x1="499" y1="1" x2="597" y2="1" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="597" y1="1" x2="597" y2="460" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />

                    <line x1="560" y1="200" x2="580" y2="220" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="580" y1="220" x2="580" y2="672" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="580" y1="672" x2="450" y2="672" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />

                    <line x1="150" y1="640" x2="170" y2="660" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                    <line x1="170" y1="660" x2="400" y2="660" style={{ stroke: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}`, strokeWidth: '2' }} />
                </svg>

                <div className='riskIssueBoxClose' style={{ backgroundColor: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}` }}>
                    <div className="riskIssueBoxTopClose" onClick={() => {
                        if (setMenuValue !== undefined) {
                            setMenuValue(false)
                        }
                        setTimeClose()
                        setIsAnimetion(false)
                    }}><i class="fa fa-times" aria-hidden="true"></i></div>
                    <div className="riskIssueBoxTopRefresh" onClick={() => { setTimeToSend() }} style={{ pointerEvents: `${timeSend ? '' : 'none'}`, opacity: `${timeSend ? '1' : '0.5'}`, animation: `${timeSend ? '' : 'spin 3s infinite'}` }}><i className="fa fa-spinner" /></div>
                </div>

                <div className='riskIssueBoxDivLeft' style={{ backgroundColor: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}` }}></div>

                <div className='riskIssueBoxDivRightTop' style={{ backgroundColor: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}` }}></div>
                <div className='riskIssueBoxDivRightBottom' style={{ backgroundColor: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}` }}></div>

                <div className='riskIssueBoxDivBottom1' style={{ backgroundColor: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}` }}></div>
                <div className='riskIssueBoxDivBottom2' style={{ backgroundColor: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}` }}></div>
                <div className='riskIssueBoxDivBottom3' style={{ backgroundColor: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}` }}></div>

                <div className='riskIssueBoxTop' /* style={{ backgroundColor: `${onMenu == 'RISK' ? 'yellow' : 'red'}` }} */>
                    {/* <div className='riskIssueBoxTopIn'> */}
                    <div style={{display:"flex", justifyContent:"flex-start", gap:"30px"}}>
                    <div className='riskIssueBoxTopHead' style={{ color: `${onMenu == 'RISK' ? 'YELLOW' : 'RED'}` }}>
                        {onMenu == 'RISK' ? 'RISK' : 'ISSUE'}
                    </div>

                    {checkStatusRiskManage ? 
                    onMenu == 'RISK' ? 
                    <div style={{height:"100%", width:"100%"}}>

                    <div style={{ 
                        fontFamily:"Oxanium", 
                        border: "2px solid yellow", 
                        height:"50px", 
                        width:"18%",
                        marginTop:"15px", 
                        textAlign:"center", 
                        paddingTop:"12px", 
                        fontSize:"16px", 
                        borderRadius:"5px", 
                        cursor:"pointer",
                        backgroundColor: "rgba(0, 71, 255, 0.2)"
                    }}
                        onClick={() => {
                        window.location.href = `/RiskManagement?id=${getProjectId}`
                        }}
                        ><img 
                        style={{width:"40px", height:"40px", marginTop:"-15%"}}
                        src={IconRiskManagement}/>
                        </div>
                        </div> : <></>
                    : <></>}
                

                        </div>
                    {/* <div className="riskIssueBoxTopClose" onClick={() => {
                            if (setMenuValue !== undefined) {
                                setMenuValue(false)
                            }
                            setTimeClose()
                            setIsAnimetion(false)
                        }}><i class="fa fa-times" aria-hidden="true"></i></div> */}
                    <div className="riskIssueBoxTopRefresh" onClick={() => { setTimeToSend() }} style={{ pointerEvents: `${timeSend ? '' : 'none'}`, opacity: `${timeSend ? '1' : '0.5'}`, animation: `${timeSend ? '' : 'spin 3s infinite'}` }}><i className="fa fa-spinner" /></div>
                    <div className='riskIssueTab'>
                        <div className='riskIssueTabText'
                            style={{ color: `${riskIssueTab.is === 'ALL' ? (onMenu == 'RISK' ? 'YELLOW' : 'RED') : '#FFF'}`, textDecoration: `${riskIssueTab.is === 'ALL' ? 'underline' : 'none'}` }}
                            onClick={() => {
                                if (riskIssueTab.is != 'ALL') {
                                    setRiskIssueTabFunc('ALL', onMenu)
                                }
                            }}>ALL</div>
                        <div className='riskIssueTabText'
                            style={{ color: `${riskIssueTab.is === 'WAITING' ? (onMenu == 'RISK' ? 'YELLOW' : 'RED') : '#FFF'}`, textDecoration: `${riskIssueTab.is === 'WAITING' ? 'underline' : 'none'}` }}
                            onClick={() => {
                                if (riskIssueTab.is != 'WAITING') {
                                    setRiskIssueTabFunc('WAITING', onMenu)
                                }
                            }}>WAITING</div>
                        <div className='riskIssueTabText'
                            style={{ color: `${riskIssueTab.is === 'ACHIEVE' ? (onMenu == 'RISK' ? 'YELLOW' : 'RED') : '#FFF'}`, textDecoration: `${riskIssueTab.is === 'ACHIEVE' ? 'underline' : 'none'}` }}
                            onClick={() => {
                                if (riskIssueTab.is != 'ACHIEVE') {
                                    setRiskIssueTabFunc('ACHIEVE', onMenu)
                                }
                            }}>ACHIEVE</div>

                    </div>
                    <div className='riskIssueChat' id='autoScroll'>

                        {onMenu == 'RISK' ?
                            riskData.map((e, index) => {
                                const commentLines = e.pri_comment.split('\n');
                                // console.log('testlog',e);

                                return (<>
                                    <div className='row riskIssueSetRow' style={{ border: `1px solid ${onMenu == 'RISK' ? 'yellow' : 'red'}` }}>
                                        <div className="col-12 riskIssueChatText">
                                            {commentLines.map((line, i) => (
                                                <span key={i}>
                                                    {line}
                                                    {i < commentLines.length - 1 && <br />}
                                                </span>
                                            ))}
                                            <div class="tooltiptext">Create by : {e.pri_created_name} <br /> Create date : {moment(new Date(e.pri_created_date)).format("DD-MM-yyyy HH:mm:ss")}</div>
                                        </div>
                                        <div className="col-12" style={{ padding: '2.5px 5px', display: `${e.pri_created_by == sessionStorage.getItem('user_id') ? 'flex' : 'none'}`, justifyContent: 'flex-end', gap: '5px', alignItems: 'center', fontSize: '10px' }}>
                                            {e.pri_updated_date === null || e.pri_updated_date === '' || e.pri_updated_date === undefined ? "" : "EDIT"}
                                            <i class="fa fa-edit"
                                                onClick={() => {
                                                    editRiskIssue(e.pri_comment, e.pri_created_name, e.pri_created_date, e.pri_id, e.pri_updated_date)
                                                }}
                                                style={{ display: `${e.pri_created_by == sessionStorage.getItem('user_id') ? '' : 'none'}`, fontSize: '15px', fontWeight: 100, cursor: 'pointer' }}></i>
                                            <div className='riskIssueRedDot'
                                                onClick={() => {
                                                    setConfirmData(e);
                                                    setConfirmMode({...confirmMode, mode: 'send'});
                                                    confirmMode.mode = 'send';
                                                    toggle_modal_confirmBox()
                                                }}
                                                style={{ display: `${e.pri_created_by == sessionStorage.getItem('user_id') || e.pri_achieve == true ? '' : 'none'}`, cursor: 'pointer' }}>
                                            </div>
                                            <div className='riskIssueAchieve'
                                                onClick={() => {
                                                    if (e.pri_achieve) {
                                                        updateAchieve(e.pri_id, 'false')
                                                    } else {
                                                        updateAchieve(e.pri_id, 'true')
                                                    }
                                                }}
                                                style={{ display: `${e.pri_created_by == sessionStorage.getItem('user_id') || e.pri_achieve == true ? '' : 'none'}`, color: `${e.pri_achieve ? 'white' : 'gray'}`, cursor: 'pointer' }}>
                                                A</div>
                                            <div className='riskIssueDel'
                                                onClick={() => {
                                                    setConfirmData(e)
                                                    setConfirmMode({...confirmMode, mode: 'del'});
                                                    confirmMode.mode = 'del';
                                                    toggle_modal_confirmBox()
                                                    // delRiskIssue(e.pri_id)
                                                }}>
                                                <i class="fa fa-trash" aria-hidden="true"></i></div>
                                        </div>
                                    </div>
                                </>)
                            }) :
                            issueData.map((e, index) => {
                                const commentLines2 = e.pri_comment.split('\n');

                                return (
                                    <div className='row riskIssueSetRow' style={{ border: `1px solid ${onMenu == 'RISK' ? 'yellow' : 'red'}` }}>
                                        <div className="col-12 riskIssueChatText">
                                            {commentLines2.map((line, i) => (
                                                <span key={i}>
                                                    {line}
                                                    {i < commentLines2.length - 1 && <br />}
                                                </span>
                                            ))}
                                            <div class="tooltiptext">Create by : {e.pri_created_name} <br /> Create date : {moment(new Date(e.pri_created_date)).format("DD-MM-yyyy HH:mm:ss")}</div>
                                        </div>
                                        <div className="col-12" style={{ padding: '2.5px 5px', display: `${e.pri_created_by == sessionStorage.getItem('user_id') ? 'flex' : 'none'}`, justifyContent: 'flex-end', gap: '5px', alignItems: 'center', fontSize: '10px' }}>
                                            {e.pri_updated_date === null || e.pri_updated_date === '' || e.pri_updated_date === undefined ? "" : "EDIT"}
                                            <i class="fa fa-edit"
                                                onClick={() => {
                                                    editRiskIssue(e.pri_comment, e.pri_created_name, e.pri_created_date, e.pri_id, e.pri_updated_date)
                                                }}
                                                style={{ display: `${e.pri_created_by == sessionStorage.getItem('user_id') ? '' : 'none'}`, cursor: 'pointer' }}></i>
                                            <div className='riskIssueAchieve'
                                                onClick={() => {
                                                    if (e.pri_achieve) {
                                                        updateAchieve(e.pri_id, 'false')
                                                    } else {
                                                        updateAchieve(e.pri_id, 'true')
                                                    }
                                                }}
                                                style={{ display: `${e.pri_created_by == sessionStorage.getItem('user_id') || e.pri_achieve == true ? '' : 'none'}`, color: `${e.pri_achieve ? 'white' : 'gray'}`, cursor: 'pointer' }}>
                                                A</div>
                                            <div className='riskIssueDel'
                                                onClick={() => {
                                                    setConfirmData(e)
                                                    setConfirmMode({...confirmMode, mode: 'del'});
                                                    confirmMode.mode = 'del';
                                                    toggle_modal_confirmBox()
                                                    // delRiskIssue(e.pri_id)
                                                }}>
                                                <i class="fa fa-trash" aria-hidden="true"></i></div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                    <div className='row riskIssueChatSend'>
                      {/*   {userismode === true ? <>
                        
                        </> :
                        <>
                        
                        
                        </>} */}
                        <textarea type='text'
                            id='sendtext'
                            onKeyDown={(e) => {
                                // console.log('keyDow', e);
                                if (e.shiftKey && e.keyCode === 13) {
                                } else {
                                    if (e.keyCode == 13 || e.keyCode == '13') {
                                        e.preventDefault();
                                        if (sendText.text != null && sendText.text != undefined && sendText.text != '' && sendText.text.trim() != '') {
                                            sendTextData()
                                        }
                                    }
                                }

                            }}
                            className='col-10 riskIssueChatTextBox'
                            style={{ border: `1px solid ${onMenu == 'RISK' ? 'yellow' : 'red'}` }}
                            placeholder="Input text...."
                            value={sendText.text}
                            disabled={userismode === 'false' ? false : true}
                            onChange={(e) => {
                                setSendText({ ...sendText, text: e.target.value })
                                sendText.text = e.target.value
                                auto_grow()
                            }}>
                        </textarea>
                        {/* {console.log(userismode)} */}
                        <div className='col-2' style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'flex-end' }}>
                            <input
                                type='button'
                                value='SEND'
                                className='riskIssueChatSendBox'
                                disabled={userismode === 'false' ? false : true}
                                style={{ border: `1px solid ${onMenu == 'RISK' ? 'yellow' : 'red'}` }}
                                onClick={() => {

                                    if (sendText.text != null && sendText.text != undefined && sendText.text != '' && sendText.text.trim() != '') {
                                        sendTextData()
                                    }
                                }}></input>
                        </div>
                    </div>
                    {/* </div> */}
                </div>

            </div>
            <div className='riskIssueEditText' style={{ display: `${editData.edit_open ? '' : 'none'}` }}>
                <div className='riskIssueEditBox'>
                    <div className='riskIssueEditCreateName'>Create by : {editData.pri_created_name}</div>
                    <div className='riskIssueEditCreateDate'>Create date : {moment(new Date(editData.pri_created_date)).format("DD-MM-yyyy HH:mm:ss")}</div>
                    <div className='riskIssueEditCreateDate'>Last update : {editData.pri_updated_date === null || editData.pri_updated_date === undefined || editData.pri_updated_date === '' ? '-' : `${moment(new Date(editData.pri_updated_date)).format("DD-MM-yyyy HH:mm:ss")}`}</div>
                    <textarea type='text'
                        id='edittext'
                        className='riskIssueEditComment'
                        // placeholder={editData.pri_comment}
                        value={editData.edit_text}
                        onChange={(e) => {
                            setEditData({ ...editData, edit_text: e.target.value })
                            editData.edit_text = e.target.value
                            auto_area()
                        }}
                    >

                    </textarea>
                    <div className='row riskIssueEditMenu'>
                        <div className='col-6' style={{ margin: '0', padding: '0' }}>
                            <div className="riskIssueEditRefresh" onClick={() => {
                                editRefresh()
                            }}>refresh</div>
                        </div>
                        <div className='col-3' style={{ margin: '0', padding: '0' }}>
                            <div className="riskIssueEditSubmit" onClick={() => {
                                editSubmit()
                            }}>SUBMIT</div>
                        </div>
                        <div className='col-3' style={{ margin: '0', padding: '0' }}>
                            <div className="riskIssueEditSubmit" onClick={() => {
                                editClose()
                            }}>CLOSE</div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='confirmBg' style={{ display: `${confirmBox ? '' : 'none'}`, zIndex: '999' }}>
                <div className='confirmBox'>
                    <div className='row confirmBoxHead'>CONFIRM?</div>
                    <div className='row confirmBoxText'>{confirmMode.mode == 'send' ? 'Send this RISK to ISSUE?' : 'Are you sure to delete?'}</div>
                    <div className='row confirmBoxButton'>
                        <button className='confirmButton' onClick={() => {
                            if (confirmMode.mode == 'send') {
                                updateToIssue()

                                toggle_modal_confirmBox()
                            } else {
                                delRiskIssue()

                                toggle_modal_confirmBox()
                            }
                            
                        }}>CONFIRM</button>
                        <button className='cancleButton' onClick={() => {
                            toggle_modal_confirmBox()
                        }}>CANCLE</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RiskIssue;
