import React, { useEffect, useState } from 'react'
import { useParams } from "react-router";
import axios from 'axios';
import {
    LoginOutlined,
    FileFilled,
    LoadingOutlined 
  } from '@ant-design/icons';
import './style.css';
import Configs from "../../config";




function Loadfilebox() {
    
    const {id} = useParams();
    const [statusConfirm,setStatusConfirm] = useState({
        text:'',
        file:''
       
    });
    const [Loading,setLoading] = useState(true);
    function setLoadfilebox(){
       
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/project/Loadfilebox/${id}`,
            headers: {
                /*     Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
               'Content-Type': 'multipart/form-data',
            },
            
        }).then(async (res)=>{
            console.log(res);
            
            const response2 = await axios.get(`https://api.box.com/2.0/files/${res.data.id}/content`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                },
                responseType: 'blob',
            });
            setStatusConfirm({...statusConfirm,
                text:res.data.namefile,
               file:URL.createObjectURL(response2.data)
            });
            statusConfirm.text = res.data.namefile
            statusConfirm.file = URL.createObjectURL(response2.data)
            const link = document.getElementById('downloadlink');
            if (link) {
              link.click(); 
              setLoading(false)
            }



        }).catch((error)=>{
            
           /*  if(error.response.data.error[0].errorDis == 'คุณได้ส่งผลการยืนยันของเอกสารนี้ไปแล้ว'){
                setStatusConfirm({...statusConfirm,
                    color:'red',
                    text1:'การยืนยันนี้ได้ดำเนินการไปแล้ว',
                    text2:'ขออภัย, การยืนยันของคุณไม่สำเร็จ',
                    text3:'เอกสาร Software Requirements Specification (SRS) ของท่านได้รับการยืนยันก่อนหน้านี้แล้ว'
                });
            }else{
                setStatusConfirm({...statusConfirm,
                    color:'red',
                    text1:'การยืนยันนี้ไม่สำเร็จ',
                    text2:'ขออภัย, การยืนยันของคุณไม่สำเร็จ',
                    text3:'เอกสาร Software Requirements Specification (SRS) ของท่านยืนยันไม่สำเร็จ'
                });
            } */

        
       
        })
    }




    useEffect(()=>{
        GetAccesstokenToken((result) => {
            if (result) {
                setLoadfilebox();
            }
          })
    
    },[])
    
    async function GetAccesstokenToken(callback) {
        await axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                /* Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
                var temp = {
                    tokenBox: response.data.tokenBox
                }
                if (callback) {
                    callback(response.data.tokenBox);
                }
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
                /*  console.log('ViewToken', response.data); */
            })
            .catch(function (error) {
                console.log(error);
            });
    
    }
  return (
    <>
    <div className = 'BgCon'>
        <div className='containerContent'>
        <a href={statusConfirm.file} download={statusConfirm.text} className='divloadfile' id={'downloadlink'}>
    {Loading === true ? <>
        <div style={{color:'#f2f2f2',fontSize:'80px', width:'100%',display:'flex',justifyContent:'center',marginBottom:'25px'}}>
    <LoadingOutlined   color/>
    </div></> 
    : 
    <>
    <div style={{color:'#f2f2f2',fontSize:'50px', width:'100%',display:'flex',justifyContent:'center',marginBottom:'25px'}}>
 <FileFilled  color/>
 </div>

 <div className='fontHead3'>{statusConfirm.text}</div>
</>}
       </a>        
            <br/>
            <div className='btnBackHome'
            onClick={()=>{
                window.location.href = Configs.TTTClient;
                
            }}
            >
            <LoginOutlined rotate={180}  color/> Back to Spaceship
            </div>
        </div>
    </div>

    </>
  )
}

export default Loadfilebox
