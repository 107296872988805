import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import moment from "moment";
import HomeTwo from "./pages/homes/HomeTwo";
import HomeOne from "./pages/homes/HomeOne";
import HomeThree from "./pages/homes/HomeThree";
import Shop from "./pages/store/Shop";
import Contact from "./pages/contact/Contact";
import Community from "./pages/community/Community";
import Overview from "./pages/overview/Overview";
import LeaderBoard from "./pages/leaderBoard/LeaderBoard";
import FederationBoard from "./pages/leaderBoard/FederationBoard";
import Contactus from "./pages/overview/ContactusT";
import AboutUs from "./pages/aboutpage/aboutuspage/AboutUs";
import Upcoming from "./pages/aboutpage/upcoming/Upcoming";
import GameSingle from "./pages/aboutpage/singlegame/GameSingle";
import Blogs from "./pages/blogs/Blogs";
import BlogDetails from "./pages/blogdetails/BlogDetails";
import ScrollToTopRoute from "./components/scrolltotop/ScrollToTopRoute";
import HomeFour from "./pages/homes/HomeFour";
import HomeFive from "./pages/homes/HomeFive";
import HomeSix from "./pages/homes/HomeSix";
import HomeSeven from "./pages/homes/HomeSeven";
import HomeTTT from "./view/homes/HomeTTT";
import HomeTTTMobile from "./view/homes/HomeTTTMobile";
import Information from "./view/information/Information";
import Info from "./view/information/Info";
import Infodetail from "./view/information/detailInfo";
import ViewNews from "./view/information/viewNews";
import ShopTTT from "./view/shop/ShopTTT";
import Shopdetail from "./view/shop/Shopdetail";
import Login from "./view/login";
import IndexAbout from "./view/abouts/index-about";
import Guide from "./view/guide";
import HowToPlay from "./view/tutorial/howToPlay";
import Tribe from "./view/tutorial/tribe";
import Achievement from "./view/tutorial/achievement";
import DetailClass from "./view/detailClass";
import ComponentTest from "./view/component/componentTest";
import VoiceRecord from "../src/components/voiceRecord";
import TTTgame from "./components/TTTGame";

import ConfirmSrs from "./view/confirmSRS";

import Register from "./view/register/";
import LandingPageGame from "./view/LandingPageGame/";
import News from "./view/news/new";
import LineModal from "./view/3dModalTEST/line_modal";
import LandingPagestage from "./view/LandingPagestage";
import SpaceShipConsole from "./view/SpaceshipConsole/consoledetail"
import SpaceshipConsole2 from "./view/SpaceshipConsole2/Spaceshipconsole"
import Loadfilebox from "./view/loadfilebox"
// import Loader from "react-js-loader";
import PrivateRoute from './routes/PrivateRoute'


import Langingpage from "./template/index";
moment.locale('en');
function App() {
  if (process.env.NODE_ENV == "development") {
   /*   console.log = () => {}; */
    console.debug = () => { };
    console.info = () => { };
    console.warn = () => { }; 
     console.error = () => {};
  }

  if (process.env.NODE_ENV == "production") {
   /*  console.log = () => { }; */
    console.debug = () => { };
    console.info = () => { };
    console.warn = () => { };
    console.error = () => {};
  }
  //  const [loading,setLoading] = useState(false);

  //  useEffect(()=>{
  //   setLoading(true)
  //   setTimeout(()=>{
  //     setLoading(false)
  //   },8000)
  //  },[])
  //  {
  //   loading ?(
  //     <MoonLoader color={color} loading={loading} css={override} size={150} />
  //   )

  // }
  const [modeMobile, setModeMobile] = useState(false);

  useEffect(() => {

    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      setModeMobile(true);
    }
    else {
      setModeMobile(false);
    }

  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>

          <ScrollToTopRoute exact={true} path="/">
            {modeMobile ? <HomeTTTMobile />:<HomeTTT />}
            {/* <HomeTwo/> */}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/index-2">
            <HomeOne />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/index-3">
            <HomeThree />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/shop">
            <Shop />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/contact">
            <Contact />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/community">
            <Community />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/overview">
            <Overview />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/UniversalBoard">
            <LeaderBoard />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/FederationBoard">
            <FederationBoard />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/Contactus">
            <Contactus />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/about-us">
            <AboutUs />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/upcoming-games">
            <Upcoming />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/game-single">
            <GameSingle />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/index-about">
            <IndexAbout />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/blogs">
            <Blogs />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/blog-details">
            <BlogDetails />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/index-4">
            <HomeFour />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/index-5">
            <HomeFive />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/index-6">
            <HomeSix />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/index-7">
            <HomeSeven />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/Information">
            <Information />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/Info">
            <Info />
          </ScrollToTopRoute>
          <Route exact={true} path="/Infodetail"
            component={() => <Infodetail />}
          />

          <Route exact={true} path="/viewNews"
            component={() => <ViewNews mode='view' />}
          />

          {/*  <Infodetail  newsid="asd" /> */}
          {/*     </Route> */}

          <ScrollToTopRoute exact={true} path="/login">
            <Login />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/tutorial">
            <Guide />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/howToPlay">
            <HowToPlay />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/talentGroup">
            <Tribe />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/ShopTTT">
            <ShopTTT />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/Shopdetail">
            <Shopdetail />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/detailClass">
            <DetailClass />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/TTTgame">
            <TTTgame />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/Register">
            <Register />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/test">
            <ComponentTest />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/News">
            <News />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/Achievement">
            <Achievement />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/ConfirmSRS/:id/:value">
            <ConfirmSrs />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/VoiceRecord">
            <VoiceRecord />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/SpaceshipConsole">
            <SpaceShipConsole/>
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/SpaceshipConsole2.0">
            <SpaceshipConsole2/>
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/Loadfilebox/:id">
            <Loadfilebox />
          </ScrollToTopRoute>
          

          {/* <ScrollToTopRoute path={`${process.env.PUBLIC_URL}/`} component={Langingpage} /> */}
          <PrivateRoute path={`${process.env.PUBLIC_URL}/`} component={Langingpage} />

        </Switch>
      </Router>

    </div>
  );
}

export default App;
