import React, { useEffect, useState, useRef } from 'react'
import '../Package/packagestyle.css'
import axios from "axios";
import Configs from "../../config";
import moment from "moment";
import { func } from 'prop-types';
import Title from "../../components/customElement/headertitle"
import checkicon from "../../assets/img/bg/imgmian.jpg"
import poin from '../../assets/img/bg/imgbdsport.jpg';
import Switch from '../../components/customElement/Switch ';
import iconedit from '../../assets/img/icon/edit-3.png';
import iconsave from '../../assets/img/icon/Icon Save SS.png';
import iconlike from '../../assets/img/icon/Icon Like SS.png';
import spaceimg from '../../assets/img/iconSpaceShip/Space_Size-S2.png'
import iconcomment from '../../assets/img/icon/Icon Comment SS.png';
import iconlikein from '../../assets/img/icon/Icon Like SS_Blue.png';
import viewimg from '../../assets/img/icon/Cover_Default Doc.png';
import Select from 'react-select'
import { Input, Space, Tag, theme, Tooltip, ConfigProvider, DatePicker, Modal, Cascader, Upload } from 'antd';
import iconlock from '../../assets/img/icon/padlock2.png'
import padiconlock from '../../assets/img/icon/padlock.png'
import iconlockglow from '../../assets/img/icon/padlockglow.png'
import key1 from '../../assets/img/icon/key1.png'
import key2 from '../../assets/img/icon/key2.png'
import eyepassword from '../../assets/img/icon/eye2.png'
import eyeicon from '../../assets/img/icon/eyeicon.png'
import CommentReport from '../../components/commentReport/ModelComment.jsx';
import iconvclen from '../../assets/img/icon/Screenshot 2023-12-28 113625.png'
import InfiniteScroll from 'react-infinite-scroll-component';
import FileViewer from 'react-file-viewer';
import icondoc from '../../assets/img/icon/document.png'
import iconexcel from '../../assets/img/icon/excel.png'
import Searchicon from '../../assets/img/icon/magnify icons.png';
import styled, { keyframes } from 'styled-components';
import projectimg from '../../assets/img/bg/2.png'
import Viewspaceship from '../../view/viewspaceship/'
import docicon from '../../assets/img/icon/doc icon.png';
import imageicon from '../../assets/img/icon/image icon.png';
import pdficon from '../../assets/img/icon/pdf icon.png';
import ppticon from '../../assets/img/icon/ppt icon.png';
import xlsxicon from '../../assets/img/icon/xlsx icon.png';
import uploadicon from '../../assets/img/icon/upload.png';
import { io } from 'socket.io-client';
const { TextArea } = Input;
const { Dragger } = Upload;

function Viewpublicprojec({ }) {
  const [loadingVideo,setLoadingVideo] = useState('loading...')
  const [isVideoLoading,setIsVideoLoading] = useState(false);
  const [isLoading2,setIsLoading2] = useState({
    step1:false,
    step2:false,
  });
  const [test, settest] = useState({
    test: ''
  })
  const [templateListshow, setTemplateListshow] = useState([])
  // console.log('templateListshow', templateListshow)
  const [dropdowncatListshow, setdropdowncatListshoww] = useState([])
  const [projcetView, setprojcetView] = useState(false);
  const toggle_modal_projcetView = () => setprojcetView(!projcetView);
  const [projcetViewspaceship, setprojcetViewspaceship] = useState(false);
  const toggle_modal_projcetViewspaceship = () => setprojcetViewspaceship(!projcetViewspaceship);
  const [passwordprojcet, setpasswordprojcet] = useState(false);
  const toggle_modal_password = () => setpasswordprojcet(!passwordprojcet);
  const [confrimModal, setConfrimModal] = useState(false);
  const toggle_modal_confrimModal = () => setConfrimModal(!confrimModal);
  const [dataconfim, setdataconfim] = useState({
    project_id: '',
    spaceship_id: '',
    spaceship_ss_id: '',
    text: ''



  });


  const [isLoading, setIsLoading] = useState(false);
  const [loading, setloading] = useState(false);

  const [dataonprojcet, setdataonprojcett] = useState({
    project_id: '',
    islock: false,
    passroom: '',
    passroomdis: '',
    isshowpass: false,
    project_password: '',
    project_name: ''



  });
  const [alertKick, setAlertKick] = useState(false);
  const [boxImg, setBoxImg] = useState();
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);
  const [filter, setFilter] = useState({
    keySearch: '',
    cat: ''
  });
  const [onSearch, setOnSearch] = useState({
    show: 30,
    current: 1,
  });
  const [hasMore, setHasMore] = useState(true);

  const [detailprojcet, setdetailprojcet] = useState({
    project_name: '',
    project_description: '',
    project_budget: '',
    project_created_date: '',
    project_id: '',
    spaceshiparr: [],
    ul_type: '',
    imgpath: '',
    ps: '',
    pc: '',
    rgb: { r: '0', g: '0', b: '0' },
    project_owner: '',
    project_owner_name: ''

  })

  const [windowSize, setWindowSize] = useState({
    size: 0,
    height: 0
  });

  const [mySpaceshipList,setMySapceshipList] = useState([]);
  const [templateList, setTemplateList] = useState({ data: [] })
  // console.log('templateList', templateList)

  const handleWindowResize = () => {


    // console.log("templateList.data", templateList.data)
    setloopcard(templateList.data)

  };

  const [projectInterestingData, setProjectInterestingData] = useState([]);
  const [projectInterestingDetail, setProjectInterestingDetail] = useState({
    isOpen: false,
    projectid: '',
    spaceid: '',
    spaceName: ''
  });

  const [sendDetailApplyPopup, setSendDetailApplyPopup] = useState(false);
  const toggle_modal_sendDetailApplyPopup = () => { setSendDetailApplyPopup(!sendDetailApplyPopup) };
  const [sendDetailApply, setSendDetailApply] = useState({
    text: ''
  });

  const [fileImportList,setFileImportList] = useState([]);
  const [fileDoc,setFileDoc] = useState([])
  const [fileImport,setFileImport] = useState({
    file:[],
    remark:null,
    remarktwo:null,
    newfile:null
  });

  const [pitchingReviewData, setPitchingReviewData] = useState({});

  useEffect(() => {



    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [templateList.data]);

  function getprojcetonuser(page, enties, onsearch) {
    // setloading(true);
    let aslist = {
      page: page || 1,
      enties: enties || 15,
      keySearch: (filter.keySearch || '').trim(),
      cat: filter.cat || null
    };

    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/getprojcetonuser`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

      data: aslist
    })
    .then(async function (response) {
      let datalist = response.data;

      let processedData = datalist.data.map(item => {
        item.img_project = viewimg; 
        return item;
      });
      setOnSearch({
        ...onSearch,
        current: page || 1,
        show: enties || 15,
      });
    
      setHasMore(datalist.isstop);

    if (onsearch === undefined) {

        let newlist = templateList.data
        for (let i of datalist.data) {
          newlist.push(i)
        }

        setTemplateList({
          ...templateList,
          data: newlist
        })

        setloopcard(newlist)

      }else {

        templateList.data = datalist.data
        setloopcard(datalist.data);

      }

      // console.log("arr_temp: ",arr_temp);

      for(let item of processedData){
        item.img_project = viewimg;
        if(item.filePathTor){
          if(item.fileType  === 'doc' || item.fileType === 'docx'){
            item.project_display_doc_path = icondoc;
       
          }else if(item.fileType  === 'csv' || item.fileType === 'xlsx' || item.fileType === 'xls'){
            item.project_display_doc_path = iconexcel;
      
          }else{
            let response2 = await axios.get(`https://api.box.com/2.0/files/${item.filePathTor}/content`, {
              headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
              },
              responseType: 'blob',
          });
        
          item.project_display_doc_path = URL.createObjectURL(response2.data);
          }
         
        }
        if(item.imgpath){
          let response2 = await axios.get(`https://api.box.com/2.0/files/${item.imgpath}/content`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
        });

        item.img_project = URL.createObjectURL(response2.data);
        }else if(!item.imgpath && item.imgCate){
          let response3 = await axios.get(`https://api.box.com/2.0/files/${item.imgCate}/content`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
        });
        item.img_project = URL.createObjectURL(response3.data);
        }else{
          item.img_project = viewimg;
        }
      }

 
      setOnSearch({
        ...onSearch,
        current: page || 1,
        show: enties || 15,
      });

      setHasMore(datalist.isstop)

      if (onsearch === undefined) {
        // console.log("เข้านะจ๊ะะะะ")

        let newlist = templateList.data
        for (let i of datalist.data) {
          newlist.push(i)
        }

        setTemplateList({
          ...templateList,
          data: newlist
        })

        setloopcard(newlist)

      }else {

        templateList.data = datalist.data
        // console.log('ddd', datalist.data);
        setloopcard(datalist.data);

      }
      // setloading(false);
      // settest('');
      

      // console.log('datalistgunnnn', datalist)

    
      // setloading(false);

    })
    .catch(function (error) {
      // setloading(false);
      console.log(error);

    });
    
  };

  function getprojcetpassword() {
    let aslist = {
      /*  user_id: sessionStorage.getItem('user_id'), */
      project_id: dataonprojcet.project_id,
      pass: dataonprojcet.passroom
    };

    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/getprojcetpassword`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

      data: aslist
  })
    .then(function (response) {
      getprojcetonuser(1, onSearch.show, '');
      toggle_modal_password()
      saveuserprojcetview()
      getprojcetview(dataonprojcet.project_id)
      getProjectInteresting(dataonprojcet.project_id);
      setdataonprojcett({
        ...dataonprojcet,
        project_id: '',
        islock: false,
        passroom: '',
        passroomdis: '',
        isshowpass: false,
        project_password: '',
        project_name: ''
      })
      dataonprojcet.project_id = ''
      dataonprojcet.islock = false
      dataonprojcet.passroom = ''
      dataonprojcet.passroomdis = ''
      dataonprojcet.isshowpass = false
      dataonprojcet.project_password = ''
      dataonprojcet.project_name = ''


    })
    .catch(function (error) {
      /*  console.log(error); */
      toggle_modal_alertKick()
    });
  };

  function getprojcetview(project_id) {
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/getprojcetview`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: {
        project_id: project_id
      }

    })
    .then(function (response) {
      let project = response.data

      getspaceshipprojcetview(project)

    })
    .catch(function (error) {
      console.log(error);

    });
  };

  function getProjectInteresting(project_id) {
    axios({
      method: 'GET',
      url: `${Configs.TTT_Game_Service}/api/project/getProjectInteresting/${project_id}`,
      headers: {
        // Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      // data: {
      //   project_id: project_id
      // }

    })
      .then(async function (response) {
        let temp = response.data || []
        setProjectInterestingData(response.data)
              await GetAccesstokenToken(async (result_)=>{
                let index = 0;
                for(let item of temp){
              
                    item.spaceship_profile_img = null;
                    if(item.img_spaceship_id || item.img_spaceship_model_id){
                        let ref_id = item.img_spaceship_id ? item.img_spaceship_id : item.img_spaceship_model_id;
                         await getFileBoxByIdImage(ref_id,async (result)=>{
                            if(result){
                                item.spaceship_profile_img = result.fileImg;
          
                      /*           await setMySapceshipList(temp); */
                                setProjectInterestingData(response.data)
                        
                                        settest({...test,test:''});
                                        test.test = '';
              
                          
                                  } 
                        
                    
                            
                          })
                    }
                  
                }
            
           
              })

      })
      .catch(function (error) {
        console.log(error);
      });
  };

// console.log("detailprojcet: ",detailprojcet);
  function getspaceshipprojcetview(project, onview) {
    // console.log("project",project);
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/getspaceshipprojcetview`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: {
        project_id: project.project_id
      }

    })
      .then(async function (response) {
       
        let pathicon = null

        let pathUrl = null
        if(project.getpath){
          let getpath = await axios.get(`https://api.box.com/2.0/files/${project.getpath}/content`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
        });
  
         pathUrl = URL.createObjectURL(getpath.data);
        }

        // console.log("project: ",project);

        // if (project.fileType === 'doc' || project.fileType === 'docx') {
        //   pathUrl = icondoc
        // }
        // if (project.fileType === 'xls' || project.fileType === 'xlsx' || project.fileType === 'csv') {
        //   pathUrl = iconexcel
        // }
        // console.log("pathUrl: ",pathUrl);
        // if (project.ul_type === 'image') {

        //   pathicon = `${Configs.TTT_Game_Service_IMG}${project.ul_file_path}`
        //   //  pathicon = setImginBox()
        // }

        if (project.ul_file_path !== undefined) {
          setMySapceshipList(response.data);
          setdetailprojcet({
            ...detailprojcet,
            project_name: project.project_name || '',
            project_description: project.project_description || '',
            project_budget: project.project_budget || '',
            project_created_date: project.project_created_date || '',
            project_id: project.project_id || '',
            spaceshiparr: response.data,
            fileType: project.getTypeFile,
             imgpath: pathUrl || null,
            // imgpath: project.ul_type == 'pdf' ? `${Configs.TTT_Game_Service_IMG}${project.ul_file_path}` : icondoc,
            pc: project.pc_name,
            ps: project.pros_name,
            rgb: project.rgb,
            project_owner: project.project_created_by,
            project_owner_name : project.project_created_name
          })
          // console.log(detailprojcet);
          detailprojcet.project_name = project.project_name || '';
          detailprojcet.project_description = project.project_description || '';
          detailprojcet.project_budget = project.project_budget || '';
          detailprojcet.project_created_date = project.project_created_date || '';
          detailprojcet.project_id = project.project_id || '';
          detailprojcet.spaceshiparr = response.data;
          detailprojcet.fileType = project.getTypeFile;
           detailprojcet.imgpath = pathUrl || null;
          // detailprojcet.imgpath = project.ul_type == 'pdf' ? `${Configs.TTT_Game_Service_IMG}${project.ul_file_path}` : pathicon
          detailprojcet.pc = project.pc_name
          detailprojcet.ps = project.pros_name
          detailprojcet.ps = project.rgb
          detailprojcet.project_owner = project.project_created_by;
          detailprojcet.project_owner_name = project.project_created_name;
        } else {
          setMySapceshipList(response.data);
          setdetailprojcet({
            ...detailprojcet,
            spaceshiparr: response.data,
          })
          // console.log(detailprojcet);

          detailprojcet.spaceshiparr = response.data;
        }

        if (onview === undefined) {
          toggle_modal_projcetView();
        }


      })
      .catch(function (error) {
        console.log(error);

      });


  }

  async function GetAccesstokenToken(callback) {
    axios({
        method: "post",
        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
        headers: {
            /* Authorization: getToken(), */
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
        },
    })
        .then(async function (response) {
          var temp = {
            tokenBox: response.data.tokenBox
          }
          if(callback){
            callback(response.data.tokenBox);
          }
          sessionStorage.setItem('tokenBox',response.data.tokenBox)
           /*  console.log('ViewToken', response.data); */
        })
        .catch(function (error) {
            console.log(error);
        });

}
  
  async function getFileBoxByIdImage(image_id,callback){
    axios({
      method: "get",
      url: `https://api.box.com/2.0/files/${image_id}/content`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
    },
    responseType: 'blob',

    })
      .then(async function (response) {

        if(callback){
          let tmp ={
            fileImg: URL.createObjectURL(response.data),
            file_id:image_id,
    
          }
          callback(tmp);
        }

      }).catch((err)=>{
        if(callback){
            let tmp ={
              fileImg:null,
              file_id:null,
      
            }
            callback(tmp);
          }
      })

  }

  function saveuserprojcetview() {
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/saveuserprojcetview`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: {
        project_id: dataonprojcet.project_id
      }

    })
      .then(function (response) {

        /*  toggle_modal_projcetView() */

      })
      .catch(function (error) {
        console.log(error);

      });


  }

  const socketRef = useRef(null);
  useEffect(() => {

      if (socketRef.current == null) {
          socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                //path: '/Socket-service/socket.io'
                port:Configs.TTT_Game_Socket_Service_Port
              });
      }
  }, [

  ])

      useEffect(() => {
        if (projectInterestingDetail.isOpen === true) {

            socketRef.current.emit('leaveOne', "SPACESHIP:" + projectInterestingDetail.spaceid)
            socketRef.current.emit('join', "SPACESHIP:" + projectInterestingDetail.spaceid)


        } else {
            socketRef.current.emit('leaveOne', 'SPACESHIP:' + projectInterestingDetail.spaceid)
            socketRef.current.emit('leaveOne', 'SPACESHIP:' + projectInterestingDetail.spaceid)
        }
    }, [projectInterestingDetail.isOpen])
    useEffect(() => {


        /*     if(getProjectId !== '' ){ */



        const { current: socket } = socketRef;
        const socFnc = (data) => {
            // console.log('onRoom', data)
            // getDataSpaceShip();
            //getChatData(data.project_id);
            /*     socket.emit('leave',data.room) */
        }
        try {
            socket.open();
            //console.log('onMenu', 'เข้าEffectแล้ว', activeRoom.room, 'RISK93bf93f2-eb48-4bf7-b1bc-1305cdc13fad')
            /* socket.emit('join','RISK93bf93f2-eb48-4bf7-b1bc-1305cdc13fad') */


            socket.on('fecthMemberSpaceship', socFnc)
            /*     socket.on('online_update', socFnc) */



            /*  setActiveRoom({...activeRoom,room:'93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'});
                activeRoom.room = '93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'; */
        } catch (error) {
            console.log(error);
        }
        // Return a callback to be run before unmount-ing.

        return () => {
            /*   console.log('onRoom','out') */
            socket.off("chat", socFnc);

            socket.close();
            /*     setActiveRoom({ ...activeRoom, room: '' });
                activeRoom.room = ''; */
        };
        /* } */



    }, []);

  async function getprojcetfocus(idprojcet, spaceship_id, spaceship_ss_id) {
    setIsLoading2({...isLoading2,
      step1:true,
      step2:false
    })
    isLoading2.step1 = true
    isLoading2.step2 = false
    let fileList = fileDoc || []
    console.log('LEK_FILE_LIST',fileList);
    let file = fileList.length > 0 ? fileList[0].originFileObj : null;
    console.log('LEK_FILE',file);
    let ole_file = []

 /*    if (fileList.length > 0) {
        ole_file = fileList.filter((e) => e.byid !== undefined && e.byid == true)
        let newFile = fileList.filter((e) => e.byid == undefined || e.byid == false)

        if (newFile.length > 0) {
            const dataImg = new FormData();
            for (let i = 0; i < newFile.length; i++) {
                dataImg.append('Profile', newFile[i].originFileObj);
            }
            dataImg.append('typeFile', "projectPitching");
            dataImg.append('projectKey', "video");

            await axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                headers: {
                 
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'multipart/form-data',
                },
                data: dataImg,
            })
                .then(async function (response) {
                    if (response.data.status == true) {
                        response.data.data.forEach((e) => {
                            const tmp = {
                                name: e.orgin_name,
                                path: e.path,
                                is_save: true,
                                ul_is_use: true,
                                ul_file_size: e.size,
                                url: e.path,
                                ul_type: e.mimetype || 'vde',
                                ul_group_name: "projectPitching",
                                ul_file_name: e.orgin_name,
                                ul_file_path: e.path,
                                ul_menu_name: "projectPitching",
                                ul_is_profile: false,
                                status: 'done'

                            }
                            ole_file.push(tmp);
                        })

                        
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    } */

    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/getprojcetfocus`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

      data: {
        pi_spaceship_id: spaceship_id,
        pi_project_id: idprojcet,
        spaceship_ss_id: spaceship_ss_id,
        desc: sendDetailApply.text,
        ole_file: ole_file || []
      }
    })
      .then(async function (response) {

        if (response.data.id) {

          let tempNoti = {
            header: `(${projectInterestingDetail.spaceName}) INTERESTED IN YOUR PROJECT (${detailprojcet.project_name})`,
            // header:"คุณได้รับคำเชิญเข้าร่วมยาน",
            // body:"คุณได้รับคำเชิญจาก " + sessionStorage.getItem('user') + " เพื่อเข้าร่วมยาน " + spaceShipName + " คุณจะยอมรับคำเชิญหรือไม่ ?",
            noti_menu_name: null,
            user_to_id: detailprojcet.project_owner,
            user_to_name: detailprojcet.project_owner_name,
            link_to: "",
            space_ship_id: projectInterestingDetail.spaceid,
            project_id: projectInterestingDetail.projectid
          }
          await axios({
            method: 'POST',
            url: `${Configs.TTT_Game_Notification_Service}/api/notification/addNotification`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              'X-TTT': `${Configs.API_TTT}`,
              'Content-Type': 'application/json',
            },
            data: tempNoti,
          })
          .then(async function (response) {
              socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + detailprojcet.project_owner});
              // setSendData([])
              // fncAlert('SEND OFFER SUCCESS.', false);
          })
            .catch(function (error) {
              console.log(error);
            });


          await CheckAndCreateFolderBox(idprojcet,"PROJECT PITCHING",async (result)=>{
            if(result){
              await UploadFiles(result.folder_id,result.folder_name,result.ref_id,file,async (result2)=>{
                if(result2 == true){
                  setIsLoading2({...isLoading2,
                    step1:true,
                    step2:true
                  })
                  isLoading2.step1 = true
                  isLoading2.step2 = true
                }
              });
            }
          },response.data.id);










     

  
          getProjectInteresting(idprojcet);
          getspaceshipprojcetview(detailprojcet, '')
          setdataconfim({
            project_id: '',
            spaceship_id: '',
            spaceship_ss_id: '',
            text: ''



          })

        /*   toggle_modal_confrimModal()
          toggle_modal_projcetViewspaceship()
          toggle_modal_sendDetailApplyPopup(); */
        }

      })
      .catch(function (error) {
        console.log(error);

      });

      

  }


  async function CheckAndCreateFolderBox(project_id,folder_name,callback,ref_id){
    let temp = {
      folderLis:["PROJECT",`PROJECT_${project_id}`,'PROJECT VIDEO',folder_name]
    }
    await axios({
      method: "POST",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
      headers: {
          Authorization: sessionStorage.getItem('access_token'),
          "X-TTT": `${Configs.API_TTT}`,
          "Content-Type": "application/json",
      },data:temp,
  })
      .then(async function (response) {
        if(response.data){
          let  folder_id = response.data.folder_id;       
          if(callback){
            let res = {
              ref_id:ref_id,
              folder_id:folder_id,
              folder_name:folder_name,
            }
            callback(res)
          }
     

        }

      })
      .catch(function (error) {
          console.log(error);
      });
  }



  async function UploadFiles(folder_id,key,ref_id,file,callback){
/*     let image_id_list = fileUploadList || []; */
    let data_ = new FormData();
    data_.append('File',file);
    data_.append('folder_id',folder_id);
    data_.append('key',key);
    data_.append('image_old_list',JSON.stringify([]));
    data_.append('ref_id',ref_id);

    await axios({
      method: "POST",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
      headers: {
          Authorization: sessionStorage.getItem('access_token'),
          "X-TTT": `${Configs.API_TTT}`,
          "Content-Type": "multipart/form-data",
      },data:data_,
  })
      .then(async function (response) {
/*         let  temp = fileUploadList || [];
        for(let item of response.data.image_id_list){
          temp.push(item);
        }
        setFileUploadList(temp); */
        settest({...test,test:''});
        test.test = '';
        if(callback){
         
          callback(true)
        }
    

      })
      .catch(function (error) {
          console.log(error);
      });
  }

  // console.log('re re re', templateList);

  useEffect(() => {
    // console.log('re อีกละนะ')
    getallcattegoryindropdown()
    getprojcetonuser(1, onSearch.show, undefined);
  }, [])

  // console.log('templateList', templateList.data);
async function setloopcard(data) {
    // console.log('data_Loop', data);
    let arr_temp = [];

    const indexMap = arr_temp.map((map, item, index) => {
      map[item.project_id] = index;
      return map;
    }, {});
    

    data.forEach(newItem => {
      if (indexMap.hasOwnProperty(newItem.project_id)) {
    
        arr_temp[indexMap[newItem.project_id]] = newItem;
      } else {
    
        arr_temp.push(newItem);
      
        indexMap[newItem.project_id] = arr_temp.length - 1;
      }
    });

    // console.log("arr_temp: ",arr_temp);
    setTemplateList({
      ...templateList,
      data: arr_temp
    })
      // console.log("data55555",data);
    let looptemplate = arr_temp.map((e, index) => {


      const Cardsetcolorin = styled.div`
      position: absolute;
      width: 320px;
      `;

      return (<>

        <Cardsetcolorin className='tagproject'>

          <div style={{ width: 'auto', height: "250px" }}>

          </div>

          <div style={{ padding: '20px' }}>
            <div style={{ color: '#ffffff', fontSize: '21px' }}>{e.project_name}</div>
            <div >{e.project_description}  <a style={{ marginLeft: '3px', color: '#0047FF', cursor: 'pointer', color: '#ffffff', textDecoration: 'underline' }}

            >Read more </a></div>
            <div style={{ marginTop: '20px', fontFamily: 'Oxanium' }}>PROJECT CATEGORY  : {e.pc_name || 'none'}</div>
          </div>
        </Cardsetcolorin>

      </>)

    })

    setTemplateListshow(looptemplate)


    let allhigthproject = document.getElementsByClassName("tagproject")
    let maxhightdiv = []
    for (let index = 0; index < allhigthproject.length; index++) {

      maxhightdiv.push(allhigthproject[index].offsetHeight)
      document.getElementsByClassName('tagproject')[index].style.display = 'none';
    }

    let widthwindthdiv = document.getElementById("scrollableindiv").offsetWidth
    let maxwindow = widthwindthdiv
    let x = 10;
    let y = 10;
    let maxlength = 0
    let arrmax = []

   

    // console.log("data69",data);


    let setproion = arr_temp.map((e, index) => {

      if (y > 10) {
        let minindex = arrmax.findIndex(obj => obj.count === Math.min(...arrmax.map(m => m.count)));
        x = ((320 + 10) * arrmax[minindex].id) + 10
        y = arrmax[minindex].count + 10
        arrmax[minindex].count += maxhightdiv[index] + 10
      }
      if (maxwindow <= (x + 320 + 10) && y === 10) {
        let minindex = arrmax.findIndex(obj => obj.count === Math.min(...arrmax.map(m => m.count)));
        x = ((320 + 10) * arrmax[minindex].id) + 10
        y = arrmax[minindex].count + 10
        arrmax[minindex].count += maxhightdiv[index] + 10
      }
      let r = '0', g = '0', b = '0'
      if (e.pc_color !== null) {
        let hexWithoutHash = e.pc_color.replace('#', '');
        r = parseInt(hexWithoutHash.substring(0, 2), 16);
        g = parseInt(hexWithoutHash.substring(2, 4), 16);
        b = parseInt(hexWithoutHash.substring(4, 6), 16);
      }

      const Cardsetcolorin = styled.div`
        position: absolute;
        left:${x}px;
        top:${y}px;
        width: 320px;
        background-image: linear-gradient(to bottom right, rgba(${r}, ${g}, ${b}, 1), rgba(${r - 2}, ${g + 8}, ${b + 83}, 1));
        border-radius: 20px;

      `;

      const marquee = keyframes`
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
      `;

      const MarqueeText = styled.div`
      width: 100%;
      overflow: hidden;
      `;
      const Text = styled.div`
        display: inline-block;
        white-space: nowrap;
        font-size: 21px;
        animation: ${marquee} 10s linear infinite;
      `;


      // let pathicon = null;
      //  if (e.imgtype === 'doc') {
      //    pathicon = `${Configs.TTT_Game_Service_IMG}${e.project_cover_path}` || viewimg
      //  }
      //  if (e.imgtype === 'xls') {
      //    pathicon = `${Configs.TTT_Game_Service_IMG}${e.project_cover_path}`|| viewimg
      //  }
      //  if (e.imgtype === 'image') {
      //    pathicon = `${Configs.TTT_Game_Service_IMG}${e.project_cover_path}` || viewimg
      //  }

     
      // pathicon = e.project_cover_path !== null ? `${Configs.TTT_Game_Service_IMG}${e.project_cover_path}` : viewimg;
      /* if(!e.project_cover_path){ */
      // if(e.imgpath){
      //   //  console.log("1");
      //   let temp1 = null;
      //   temp1 =  setImginBox(e.imgpath);
      //   temp1.then((e)=> { setBoxImg(e)});
      //   pathicon = boxImg;
      //   setBoxImg(null);
      // }else if(e.imgpath && e.imgCate){
      //   // console.log("2");
      //   let temp1 = null;
      //   temp1 =  setImginBox(e.imgpath);
      //   temp1.then((e)=> {setBoxImg(e)});
      //   pathicon = boxImg;

      //   setBoxImg(null);
      // }else{
      //   // console.log("3");
      //   if(e.imgCate){
      //     let temp2 = null;
      //     temp2 =  setImginBox(e.imgCate);
      //     temp2.then((e)=> {setBoxImg(e)});
      //     pathicon = boxImg;
      //     setBoxImg(null);
      //   }else{
      //     pathicon = viewimg;
      //   }
      // }
      // console.log("pathicon ", pathicon);
       /* } */

        // console.log("e: ", e);


      if (y === 10) {
        arrmax.push({
          id: maxlength,
          count: maxhightdiv[index] + 10
        })
        maxlength += 1
        x += 320 + 10
      };

      let checktext =  e.project_name ? e.project_name.replace(/[ัี่้๊๋็]/g, '') : ''
    
      return (<>
        {/* {!data.some(proj => proj.project_id === e.project_id) ? <></> :
        <> */}
        <Cardsetcolorin className='overlinediv'>
          <div className='postLockIn' style={{ display: `${e.islock ? '' : 'none'}` }} onClick={(x) => {
            setdataonprojcett({
              ...dataonprojcet, project_id: e.project_id,
              project_password: e.project_password,
              project_name: e.project_name
            })
            dataonprojcet.project_id = e.project_id
            dataonprojcet.project_password = e.project_password
            dataonprojcet.project_name = e.project_name
            toggle_modal_password()

          }}>
            <div className="image-container" style={{ position: 'relative', width: '100%', height: '300px', marginTop: '' , cursor : 'pointer' }}>
              <img src={key1} className="main-image" style={{ width: "100%", height: "200px", objectFit: 'contain', marginTop: "" }} />
              <img src={key2} className="hover-image" style={{ width: "100%", height: "200px", objectFit: 'contain', marginTop: "" }} />
              <div style={{ paddingTop : '220px'}}>
                <div className='lockNameText' style={{ display: 'block', clear: 'both' , textAlign: 'center', fontSize: '20px' }}>
                  {checktext}
                </div>
                <div className = 'lockedText' style={{ display: 'block', clear: 'both' , textAlign: 'center' , fontSize: '35px' }}>
                  PRIVATE PROJECT
                </div>
                <div className = 'lockedSecondText' style={{ display: 'block', clear: 'both' , textAlign: 'center' , fontSize: '20px' , color : '#FF4848'}}>
                  REQUIRES PASSWORD
                </div>
              </div>
            </div>
          </div>

          {e.fileType === 'pdf' ?
            <>
         {/*      <a href={`${Configs.TTT_Game_Service_IMG}${e.imgpath}`} > */}
              <a href={e.img_project} >
                <div className='imgtopborder' style={{ width: 'auto', height: "250px", backgroundColor: 'rgba(0,0,0,0.8)' }}>
                  {/*  <FileViewer
                      fileType={e.imgtype}
                      filePath={`${Configs.TTT_Game_Service_IMG}${e.imgpath}`}

                    /> */}
                  <img src={e.img_project} style={{ width: '100%', height: '250px', objectFit: 'contain', border: '0px', backgroundColor: 'rgba(0,0,0,0.8)' }} className='imgtopborder' />

                </div>
              </a>
            </>
            :
            <>
              {e.fileType !== 'pdf' ?
          /*       <a href={`${Configs.TTT_Game_Service_IMG}${e.imgpath}`} > */
                   <a href={e.img_project} >
                  <img src={e.img_project} style={{ width: '100%', height: '250px', objectFit: 'contain', border: '0px', backgroundColor: 'rgba(0,0,0,0.8)' }} className='imgtopborder' />
               </a>
                :
                <>
                  <img src={e.img_project} style={{ width: '100%', height: '250px', objectFit: 'cover', border: '0px', backgroundColor: 'rgba(0,0,0,0.8)' }} className='imgtopborder' />
                </>}


            </>
          }


          <div style={{ padding: '20px' }}>
          <div style={{ color: '#ffffff', fontSize: '21px',textOverflow:'ellipsis',overflow:'hidden',width:'100%' }}> {e.project_name}</div>
         {/*  <MarqueeText>
            {checktext.length > 20 ? <Text >{e.project_name}</Text> : <div style={{ color: '#ffffff', fontSize: '21px' }}> {e.project_name}</div>}
      
    </MarqueeText> */}

          {/*   <div style={{ color: '#ffffff', fontSize: '21px' }}> {e.project_name}</div> */}
            <div /* ref={el => console.log("test", el.offsetHeight)} */ style={{ color: '#ffffff' }}>{e.project_description}  <a style={{ marginLeft: '3px', color: '#0047FF', cursor: 'pointer', color: '#ffffff', textDecoration: 'underline' }}
              onClick={() => {


                getprojcetview(e.project_id)
                getProjectInteresting(e.project_id);

              }}

            >Read more </a></div>
            <div style={{ marginTop: '20px', fontFamily: 'Oxanium', color: '#ffffff' }}>PROJECT CATEGORY  : {e.pc_name || 'none'}</div>
          </div>
        </Cardsetcolorin>
        {/* </> 
    } */}
      </>)
      

    })


    setWindowSize({
      ...windowSize,
      size: ((maxwindow - (320 + 10) * maxlength) / 2),
      height: Math.max(...arrmax.map(m => m.count)) + 10
    })




    setTemplateListshow(setproion)


  }

  const [selectedValue, setSelectedValue] = useState([]);
  const handleCascaderClick = (value, selectedOptions) => {
    console.log('Clicked value:', value);
    setSelectedValue(value);
    setFilter({ ...filter, cat: value[value.length - 1] })
    filter.cat = value[value.length - 1]
  };





  function getallcattegoryindropdown() {

    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/category/getallcattegoryindropdown`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },


    })
      .then(function (response) {
        setdropdowncatListshoww(response.data.data)

        // console.log(response.data.data);

      })
      .catch(function (error) {
        /*  console.log(error); */
        toggle_modal_alertKick()
      });


  }

  function callBackClosePopup() {
    setPitchingReviewData({});
    setProjectInterestingDetail({ ...projectInterestingDetail, isOpen: false });
  }

  const props = {
   
    maxCount: 1,
    name: 'file',
    accept:".mp4",
    multiple: false,
    method:'get',
    headers: {
      'X-TTT': `${Configs.API_TTT}`,
      'Content-Type': 'application/json',
  },
  
    action: Configs.TTT_Game_Service+ '/api/upload/fackFile',
    onChange(info) {
    /*   setFileDoc([]);
      settest({...test,test:''}) */
     /*  test.test = ''; */
      console.log('status',info)
      
      const { status } = info.file;
      const { file } = info;
    /*   info.status  ='done'; */
    console.log(' TESTTTT',status, file);
      if (status !== 'uploading') {
  
      }
      if (status === "uploading") {  
        setIsLoading(true);
        setTimeout(() => {   setIsLoading(false);}, 5000); 
        console.log('infFile',file);
        let ole_file = [];
        let count = ole_file.length + 1;
       /*  if(file.length > 0){ */
        /*   for (let item of file) { */
  
            var list = {
                uid: count,
                ul_file_path: file,
                ul_file_name: file.name,
                name: file.name,
                path: URL.createObjectURL(file.originFileObj ),
                url:  URL.createObjectURL(file.originFileObj ),
                is_save: true,
                ul_is_use: true,
                ul_file_size: 0,
                ul_type: file.type,
                ul_group_name: "projectPitching",
                ul_menu_name: "projectPitching",
                ul_is_profile: false,
                status: 'done',
                image_file: file,
                is_img_upload: true,
                originFileObj: file.originFileObj
            }
            ole_file.push(list)
            
            console.log('EEDS',ole_file);
  
            
          /*   setIsVideoLoading(false); */
            setFileDoc(ole_file)
            // setIsLoading(false);
          /*   setIsLoading(false); */
            settest({test:''});
            test.test = '';
    /*         setFileImport({
              ...fileImport,file:ole_file,
              newfile:true
     
           }) */
           /*  count++ */
  
     /*    } */
    /*     } */
  
  
  
  
       /*  setFileImport(info.file); */
    
        /* message.success(`${info.file.name} file uploaded successfully.`); */
      } else if (status === 'error') {
        /* message.error(`${info.file.name} file upload failed.`); */
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const onRemoveFile = (info, index) => {
    console.log("onRemove info = " + info);
    setFileImportList([])
  
    setFileImport({
        ...fileImport,file:[]
     })
  };
  // console.log("detailprojcet",detailprojcet);
  async function getPitchingProjectDetailById(spaceShipId) {
    var temp = {
      spaceShipId: spaceShipId,
      projectId: detailprojcet.project_id
    }
  //  console.log(detailprojcet.imgpath);
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/getPitchingProjectDetailById`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

      data: temp
    })
      .then(async function (response) {
        if (response.data.length > 0) {
          

          setPitchingReviewData({...pitchingReviewData,
            pi_vdo_path: response.data[0].pi_vdo_path,
            pi_vdo_name: response.data[0].pi_vdo_name,
            pi_description: response.data[0].pi_description,
            video_pitching_id: response.data[0].video_pitching_id
          });
          pitchingReviewData.pi_vdo_path = response.data[0].pi_vdo_path;
          pitchingReviewData.pi_vdo_name = response.data[0].video_pitching_name;
          pitchingReviewData.pi_description = response.data[0].pi_description;
          pitchingReviewData.video_pitching_id = response.data[0].video_pitching_id;
   
          console.log('testlog pitchingReviewData',pitchingReviewData);
          let video_file = null;
         /*a wait GetAccesstokenToken(async(result_)=>{
           await getFileBoxByIdImage(response.data[0].video_pitching_id,async (result)=>{
              if(result){
                console.log('result_pitching',result)
                video_file = result.fileImg;
                let fileUploadImg = [];
                if (response.data[0].video_pitching_id !== null) {
                        var temps = {
                            uid: fileDoc.length || 1,
                            video_pitching:video_file,
                            video_id : response.data[0].video_pitching_id,
                            ul_file_path: response.data[0].pi_vdo_path,
                            ul_file_name: response.data[0].pi_vdo_name,
                            name: response.data[0].video_pitching_name,
                            path: response.data[0].pi_vdo_path,
                            url: `${Configs.TTT_Game_Service_IMG}/${response.data[0].pi_vdo_path}`,
                            is_save: false,
                            ul_is_use: true,
                            ul_file_size: null,
                            ul_type: null,
                            ul_group_name: "projectPitching",
                            ul_menu_name: "video",
                            ul_is_profile: false,
                            status: 'done',
                            byid: true
                        }
                        fileUploadImg.push(temps);
                        
                }
                setFileDoc(fileUploadImg);
              }
            })
          }) */

          setSendDetailApply({ ...sendDetailApply, text: response.data[0].pi_description });
          let fileUploadImg = [];
          if (response.data[0].video_pitching_id !== null) {
                  var temps = {
                      uid: fileDoc.length || 1,
                      video_pitching:video_file,
                      video_id : response.data[0].video_pitching_id,
                      ul_file_path: response.data[0].pi_vdo_path,
                      ul_file_name: response.data[0].pi_vdo_name,
                      name: response.data[0].video_pitching_name,
                      path: response.data[0].pi_vdo_path,
                      url: `${Configs.TTT_Game_Service_IMG}/${response.data[0].pi_vdo_path}`,
                      is_save: false,
                      ul_is_use: true,
                      ul_file_size: null,
                      ul_type: null,
                      ul_group_name: "projectPitching",
                      ul_menu_name: "video",
                      ul_is_profile: false,
                      status: 'done',
                      byid: true
                  }
                  fileUploadImg.push(temps);
                  
          }
          setFileDoc(fileUploadImg);
          settest({...test,test: ''});
          test.test = '';
        }
      })
      .catch(function (error) {
        console.log(error);

      });
  }  

  return (
    <>
      {projcetView === true ?
        <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: '999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ width: '70%', height: '80%', backgroundImage: `linear-gradient(to bottom right, rgba(${detailprojcet.rgb.r}, ${detailprojcet.rgb.g}, ${detailprojcet.rgb.b}, 1), rgba(${detailprojcet.rgb.r - 2}, ${detailprojcet.rgb.g + 8}, ${detailprojcet.rgb.b + 83}, 1))`, borderRadius: '20px' }}>


            <div className='row' style={{ padding: 0, margin: 0, height: '100%' }}>
              <div className='col-4' style={{ padding: '0px', margin: 0, backgroundColor: 'rgba(0,0,0,0.8)' }}>
                {/* <div style={{ height: '700px', padding: '0', overflow: 'auto' }}>
                  {detailprojcet.spaceshiparr.map((e) => {
                    return (
                      <div className='row' style={{ border: '1px solid gray', height: '75px', margin: '0 0 10px 0', padding: 0 }}>
                        <div className='col-3' style={{ margin: 0, padding: 0 }}>

                          <img src={e.imgpath ? `${Configs.TTT_Game_Service_IMG}${e.imgpath}` : viewimg} style={{ width: "100%", height: "73px", objectFit: 'contain', margin: 0, padding: 0 }} />

                        </div>
                        <div className='col-9' style={{ margin: 0, padding: '10px 0' }}>
                          <div style={{ padding: '0 5px', marginBottom: '5px', display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '5px' }}>
                            <div style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: `${e.spaceship_ss_id === '29d1c602-1858-40f9-8289-8ef8a9ab234d' || e.spaceship_ss_id === '6d93d367-508b-4d98-955a-10f1ee44ae52' ? e.isfocus ? 'yellow' : 'green' : 'red'}` }}></div>
                            <div>{e.spaceship_name}</div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 10px', alignItems: 'center' }}>
                            <div style={{ display: `${e.spaceship_ss_id === '29d1c602-1858-40f9-8289-8ef8a9ab234d' || e.spaceship_ss_id === '6d93d367-508b-4d98-955a-10f1ee44ae52' ? '' : 'none'}`, border: '1.5px solid blue', width: 'fit-content', padding: '0px 2.5px', color: 'blue', cursor: 'pointer' }} onClick={() => {
                              if (e.spaceship_ss_id === '29d1c602-1858-40f9-8289-8ef8a9ab234d' || e.spaceship_ss_id === '6d93d367-508b-4d98-955a-10f1ee44ae52') {
                                getprojcetfocus(detailprojcet.project_id, e.su_sps_id, e.spaceship_ss_id)
                              }
                            }}>FOCUS</div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div> */}

                <a href={detailprojcet.imgpath} >
                  {detailprojcet.imgpath != null?
                    <>
                      <div style={{ width: 'auto', height: "100%" }} className='divcanvas'>
                        
          {detailprojcet.fileType === "docx" || detailprojcet.fileType === "docx" ?
            <>
            
                      <FileViewer
                          fileType={'png'}
                          filePath={icondoc}
                        /> 
      
            </>
            :
            <>
              {detailprojcet.fileType === "xls" || detailprojcet.fileType === "xlsx" || detailprojcet.fileType === "csv" ?
                <>
                      <FileViewer
                          fileType={'png'}
                          filePath={iconexcel}
                        /> 
                </>    
                :
                <>
                     <FileViewer
                          fileType={'pdf'}
                          filePath={detailprojcet.imgpath}
                        /> 
                </>}
            </>
          }
                      {/* {detailprojcet.getTypeFile === "docx" || detailprojcet.getTypeFile === "docx" ? 
                      (<>
                       <img src={icondoc}  style={{width:'900px'}}/> 
                      </>) :
                      <>{detailprojcet.getTypeFile === "xls" || detailprojcet.getTypeFile === "xlsx" || detailprojcet.getTypeFile === "csv" ? 
                        (<>
                        </>):(<> */}
                          {/* <img src={icondoc}  style={{width:'900px'}}/> */}
                        {/*    <FileViewer
                          fileType={'pdf'}
                          filePath={detailprojcet.imgpath}
                        />  */}
                        {/* </>)
                      }</>
                      } */}
                      

                      </div>
                    </>
                    :
                    <>
                      {/* {detailprojcet.ul_type !== null ? */}
                        {/* <img src={detailprojcet.imgpath} style={{ width: '100%', height: '100%', objectFit: 'contain', border: '0px' }} />
                        : */}
                        {/* <div style={{ height: '250px', width: '100%' }}>

                        <img src={icondoc}  style={{width:'900px'}}/>
                        </div> */}
                        {/* } */}

                    </>
                  }
                </a>
              </div>
              <div className='col-8' stype={{ padding: 0, margin: 0 }}>
                <div style={{ width: '100%', height: '15%', padding: '2.5% 0 0 0' }}>
                  <div className="projectViewPopupName">{detailprojcet.project_name}  {/*  {moment(detailprojcet.project_created_date).format('DD/MM/YYYY')} */}</div>
                </div>
                <div style={{ width: '100%', height: '75%', overflowY: 'auto',color:'#ffffff' }}>
                  <div style={{ wordWrap: 'break-word', width: '100%', fontFamily: 'Oxanium', overflowY: 'auto', height: '40%' }}>{detailprojcet.project_description}</div>
                  <div style={{ fontSize: '16px', marginBottom: '10px', fontFamily: 'Oxanium', fontWeight: '700' }}>{`Project Create  `}<span style={{ fontSize: '14px', fontFamily: 'Oxanium', marginTop: '2px', fontWeight: '400' }}> {` : ${moment(detailprojcet.project_created_date).format('DD MMMM YYYY')}`}</span></div>
                  <div style={{ fontSize: '16px', marginBottom: '10px', fontFamily: 'Oxanium', fontWeight: '700' }}>Budget <span style={{ fontSize: '14px', fontFamily: 'Oxanium', marginTop: '2px', fontWeight: '400' }}> {` :  ${detailprojcet.project_budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span></div>
                  <div style={{ fontSize: '16px', marginBottom: '10px', fontFamily: 'Oxanium', fontWeight: '700' }}>Category Project <span style={{ fontSize: '14px', fontFamily: 'Oxanium', marginTop: '2px', fontWeight: '400' }}> {` :  ${detailprojcet.pc || ''}`}</span></div>
                  <div style={{ fontSize: '16px', marginBottom: '10px', fontFamily: 'Oxanium', fontWeight: '700' }}>Project Status <span style={{ fontSize: '14px', fontFamily: 'Oxanium', marginTop: '2px', fontWeight: '400' }}> {` :  ${detailprojcet.ps || ''}`}</span></div>
                  <div style={{ display: `${detailprojcet.project_owner == sessionStorage.getItem('user_id') ? '' : 'none'}`, fontSize: '45px', fontFamily: 'Oxanium', fontWeight: '700', color: '#FFF' }}>SPACESHIP PITCHING</div>
                  <div style={{ display: `${detailprojcet.project_owner == sessionStorage.getItem('user_id') ? '' : 'none'}`, height: '300px', overflowX: 'auto', overflowY: 'hidden' }}>
                    <div style={{ display: 'flex', gap: '1.5%', width: 'fit-content', height: '100%' }}>
                      {projectInterestingData.map((e, index) => {
                    /*     console.log('testlog eee',e); */
                        return (
                          <div style={{ width: '210px', height: '100%', backgroundImage: `url(${e.spaceship_profile_img})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', clipPath: 'polygon(0 0, 85% 0, 100% 10%, 100% 100%, 0 100%)', padding: '5px 10px', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', cursor: 'pointer' }} onClick={() => {
                            setProjectInterestingDetail({ ...projectInterestingDetail, isOpen: true, projectid: detailprojcet.project_id, spaceid: e.pi_spaceship_id, spaceName: e.spaceship_name,spaceship_profile:e.spaceship_profile_img});
                            getPitchingProjectDetailById(e.pi_spaceship_id);
                          }}>
                            <div style={{ color: '#FFF', fontFamily: 'Oxanium', fontSize: '30px', fontWeight: '700', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e.spaceship_name}</div>
                            <div style={{ color: '#FFF', fontFamily: 'Oxanium', fontSize: '10px', fontWeight: '400', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textDecorationLine: 'underline' }}>VIEW CONTENT</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div style={{ width: '100%', height: '10%' }}></div>
                {/* <div style={{ height: '700px' }}>
                  <div className="row" style={{ margin: 0,marginTop:'50px'  }}>
                    <div className="projectViewPopupName">{detailprojcet.project_name}{  {moment(detailprojcet.project_created_date).format('DD/MM/YYYY')} }</div>
                  </div>
                  <div className="row" style={{ margin: 0,marginTop:'55px' ,color:'#ffffff'  }}>
                    <div style={{ wordWrap: 'break-word', width: '100%', fontFamily: 'Oxanium',overflowY:'auto',height:'180px' }}>{detailprojcet.project_description}   </div>
                  </div>
                  <div className="row" style={{ margin: 0,marginTop:'50px',color:'#ffffff'  }}>
                    <div style={{ fontSize: '16px', marginBottom: '10px', fontFamily: 'Oxanium' ,fontWeight:'700' }}>{`Project Create  `}{ : {detailprojcet.project_budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Baht }</div><div style={{fontSize: '14px', fontFamily: 'Oxanium',marginTop:'2px'}}> {` : ${moment(detailprojcet.project_created_date).format('DD MMMM YYYY')}`}</div>
                  </div>

                  <div className="row" style={{ margin: 0,color:'#ffffff'  }}>
                    <div style={{ fontSize: '16px', marginBottom: '10px', fontFamily: 'Oxanium',fontWeight:'700' }}>Budget </div><div style={{fontSize: '14px', fontFamily: 'Oxanium',marginTop:'2px'}}> {` :  ${detailprojcet.project_budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</div>
                  </div>
                  <div className="row" style={{ margin: 0,color:'#ffffff'   }}>
                    <div style={{ fontSize: '16px', marginBottom: '10px', fontFamily: 'Oxanium',fontWeight:'700'  }}>Category Project </div><div style={{fontSize: '14px', fontFamily: 'Oxanium',marginTop:'2px'}}> {` :  ${detailprojcet.pc || ''}`}</div>
                  </div>
                  <div className="row" style={{ margin: 0,color:'#ffffff'  }}>
                    <div style={{ fontSize: '16px', marginBottom: '10px', fontFamily: 'Oxanium',fontWeight:'700'  }}>Project Status </div><div style={{fontSize: '14px', fontFamily: 'Oxanium',marginTop:'2px'}}> {` :  ${detailprojcet.ps || ''}`}</div>
                  </div>
                  <div className="row" style={{ margin: 0,color:'#ffffff'  }}>
                    <div style={{ fontSize: '16px', marginBottom: '10px', fontFamily: 'Oxanium',fontWeight:'700'  }}>Project Status </div><div style={{fontSize: '14px', fontFamily: 'Oxanium',marginTop:'2px'}}> {` :  ${detailprojcet.ps || ''}`}</div>
                  </div>
                </div> */}


              </div>

            </div>
            <div style={{ backgroundColor: 'red', color: '#ffffff', height: '45px', width: '110px', textAlign: 'center', padding: '10px', position: 'absolute', right: '23.5%', top: '83.5%', borderRadius: '5px', cursor: 'pointer' }} onClick={() => {
              setdetailprojcet({
                detailprojcet,
                project_name: '',
                project_description: '',
                project_budget: '',
                project_created_date: ``,
                project_id: '',
                spaceshiparr: [],
                ul_type: '',
                imgpath: '',
                pc: '',
                ps: '',
                project_owner: '',
                project_owner_name: '',
                
              })

              detailprojcet.project_name = ''
              detailprojcet.project_description = ''
              detailprojcet.project_budget = ''
              detailprojcet.project_created_date = ``
              detailprojcet.project_id = ''
              detailprojcet.spaceshiparr = []
              detailprojcet.ul_type = ``
              detailprojcet.imgpath = ''
              detailprojcet.pc = ''
              detailprojcet.ps = ''
              detailprojcet.project_owner = ''
              detailprojcet.project_owner_name = ''

              toggle_modal_projcetView()
            }}>CLOSE</div>
            <div style={{ backgroundColor: '#0047FF', color: '#ffffff', height: '45px', width: '110px', textAlign: 'center', padding: '10px', position: 'absolute', right: '17%', top: '83.5%', borderRadius: '5px', cursor: 'pointer' }} onClick={async() => {
                 toggle_modal_projcetViewspaceship()
       /*        let temp = detailprojcet.spaceshiparr || []; */
              let temp = mySpaceshipList || []
              await GetAccesstokenToken(async (result_)=>{
                let index = 0;
                for(let item of temp){
              
                    item.profile_img = null;
                    if(item.img_profile_id || item.img_model_id){
                        let ref_id = item.img_profile_id ? item.img_profile_id : item.img_model_id;
                         await getFileBoxByIdImage(ref_id,async (result)=>{
                            if(result){
                                item.profile_img = result.fileImg;
                    /*             setdataonprojcett({...detailprojcet,spaceshiparr:temp}); */
                           /*      detailprojcet.spaceshiparr = temp; */
                                await setMySapceshipList(temp);
                        
                                       /*  setSpaceData(datalist.data); */
                                        settest({...test,test:''});
                                        test.test = '';
                                
                                 /*    console.log('response.data.spaceShip_list',index) */
                          
                                  } 
                        
                             
                  /*             setAllFileDataDetail({
                                ...allFileDataDetail,image_profile:result.fileImg,image_profile_id:result.file_id});
                              allFileDataDetail.image_profile = result.fileImg;
                              allFileDataDetail.image_profile_id = result.file_id; */
                            
                          })
                    }
                  
                }
            
           
              })
/*  --- */
           
            }}>APPLY</div>
          </div>



        </div>
        :
        <></>
      }

      {projcetViewspaceship === true ?
        <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: '999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ width: '70%', height: '80%', backgroundColor: '#000F2B', border: '1px solid #82D9FF', paddingLeft: '20px', paddingRight: '20px' }}>
            <div className='row' style={{ margin: 0, padding: '0 5px', height: '5%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', zIndex: '10' }}>
              <div className="pi-closePersonalInfo" onClick={() => {

                toggle_modal_projcetViewspaceship()
              }}>
                <i class="fa fa-times" aria-hidden="true" style={{ fontSize: "30px" }}></i>
              </div>
            </div>
            <div style={{ position: 'absolute', fontSize: '48px', fontFamily: 'Oxanium', color: '#ffffff', marginTop: '-20px' }}>SELECT YOUR SPACESHIP</div>
            <div style={{ position: 'absolute', fontSize: '21px', fontFamily: 'Oxanium', color: '#ffffff', marginTop: '40px' }}>{`FOR ${detailprojcet.project_name}`}</div>

            <div className='spaceNewBoxList' style={{ marginTop: '100px' }}>
              <div className='spaceNewBox'>
                { mySpaceshipList.map((e, index) => {

                  return (
                    <div className='spaceNewBoxIn' >
                      <div className='spaceNewBoxIn' style={{ backgroundColor: `${e.spaceship_ss_id === '29d1c602-1858-40f9-8289-8ef8a9ab234d' || e.spaceship_ss_id === '6d93d367-508b-4d98-955a-10f1ee44ae52' ? (e.isfocus ? 'yellow' : 'green') : 'red'}` }}>
                        <div className='spaceNewBoxBgBorder'></div>
                        <img className='spaceNewImg' style={{ objectFit: 'contain' }} src={`${e.profile_img ? e.profile_img : spaceimg}`} ></img>
                        <div className='spaceNewBoxBg' style={{ backgroundColor: `${e.spaceship_ss_id === '29d1c602-1858-40f9-8289-8ef8a9ab234d' || e.spaceship_ss_id === '6d93d367-508b-4d98-955a-10f1ee44ae52' ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.75)'}`, cursor: 'pointer' }} onClick={() => {
                          if (e.spaceship_ss_id === '29d1c602-1858-40f9-8289-8ef8a9ab234d' || e.spaceship_ss_id === '6d93d367-508b-4d98-955a-10f1ee44ae52') {
                            let textcon = ''

                            if (e.isfocus === true) {
                              textcon = 'you want to cancel Project or not?'
                            } else textcon = 'You want to join Project or not?'
                            setdataconfim({
                              project_id: detailprojcet.project_id,
                              spaceship_id: e.spaceship_id,
                              spaceship_ss_id: e.spaceship_ss_id,
                              text: textcon
                            })
                            // toggle_modal_confrimModal()
                                  setProjectInterestingDetail({ ...projectInterestingDetail, spaceName: e.spaceship_name})

                            getPitchingProjectDetailById(e.spaceship_id);
                            toggle_modal_sendDetailApplyPopup();
                          }
                        }}>
                          <div className='spaceNewBoxText' style={{ fontSize: '20px' }}>RANK : {e.spaceship_rank}</div>
                          <div className='spaceNewBoxText' style={{ fontSize: '25px', marginTop: '330px' }}>SPACESHIP :</div>
                          <div className='spaceNewBoxText' style={{ fontSize: '40px' }}>{e.spaceship_name}</div>
                          <div className='spaceNewBoxText' style={{ fontSize: '18px' }}>CREATE : {moment(e.spaceship_created_date).format("DD MMM YYYY")}</div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        :
        <></>
      }



      {passwordprojcet === true ?

        <div>
          
          <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: '999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className='productprojectborder' style ={{position: 'absolute', width: '100%', height: '100%' , zIndex: '1000', display: 'flex' , alignItems: 'center' , filter: 'drop-shadow(0px 0px 15px #0047FF) drop-shadow(0px 0px 15px #0047FF)' , width : '100%' , justifyContent: 'center' , height : '100%'}}>
            <div style={{ width: '30%', height: '35%', backgroundColor: '#000F2B' , border: '2px solid #0094FF' , clipPath: 'polygon(14% 0px, 100% 0px, 100% 76%, 85% 100%, 0px 100%, 0px 25%)'}}> 
            <svg height="calc(100% + 2.5px)" width="calc(100% + 2.5px)" style={{ position: 'absolute', top: '-1px', left: '-1px' , zIndex : '1000'}}>
                <line x1="calc(34.4% + 10px)" y1="calc(41.5% + 0px)" x2="calc(39.5% + 0px)" y2="calc(32% + 0px)"  style={{ stroke: '#0094FF', strokeWidth: '2' }}></line>
                <line x1="calc(64.4% + 10px)" y1="calc(58% + 10px)" x2="calc(59.25% + 10px)" y2="calc(67.5% + 10px)" style={{ stroke: '#0094FF', strokeWidth: '2' }} />
              </svg>
              <div className='projectNameHeader' style={{ display : 'absolute' , fontSize : '35px' , justifyContent : 'center' , textAlign : 'center' , paddingTop : '25px'}}>PRIVATE PROJECT</div>

              <div className='row' style={{ height: '25%', margin: 0, padding: 0, display: 'absolute', justifyContent: 'flex-start', padding: '20px 40px' }}>
                <div className='projectNameText'>PROJECT : {dataonprojcet.project_name}</div>
              </div>

              <div className='row' style={{ height: '25%', margin: 0, padding: 0, display: 'absolute', justifyContent: 'flex-start', padding: '20px 0 0 40px' , zIndex : '' }}>
                <div className='projectPasswordText'>PASSWORD : </div>
                  <div className='packageInputOnLock' style={{ border: 'none', margin: '-9.5px 0 0 10px', width: '250px', display: 'flex', fontSize: '20px', zIndex : '1001' }}>
                    <div className="ds-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px  '#5D9BE1'`, marginBottom: '20px' }}>
                      <div className="ds-infoBoxText">
                        <div className="ds-infoBoxTextCenPrivate" style ={{background: '#0047FF' , zIndex : '1001'}}>
                          <div style={{ position: 'relative', width: 'fit-content' }}>
                            <input
                              className='ds-infoBoxTextInPrivate'
                              type={dataonprojcet.isshowpass === false ? "password" : "text"}
                              value={dataonprojcet.passroom}
                              onBlur={() => {
                                if (dataonprojcet.passroom.length === 0) {
                                  setdataonprojcett({ ...dataonprojcet, islock: false, isshowpass: false });
                                }
                              }}
                              onChange={(e) => {
                                let psssdis = '';
                                if (e.target.value.length > 0) {
                                  for (let p of e.target.value) {
                                    psssdis += '*';
                                  }
                                }
                                setdataonprojcett({ ...dataonprojcet, passroom: e.target.value, passroomdis: psssdis });
                              }}
                              autoFocus
                            />
                            <div 
                              className='packageInputOnLockSee'   
                              onClick={(e) => { 
                                setdataonprojcett({ ...dataonprojcet, isshowpass: !dataonprojcet.isshowpass }); 
                              }} 
                              style={{ position: 'absolute', right: '10px', top: '34%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                            >
                            <img src={eyeicon} style={{ height: '20px', width: '20px' , zIndex : 1001}} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
            </div>

            <div className='row projectButtonBox' style={{ display : 'flex' , position : 'relative' , zIndex : '1001'}}>
              
              
              <div className='projectButton' style={{ border: '2px solid #0047FF' , borderRadius: '5px' , color: 'white' , backgroundColor : '#0047FF' , fontSize : '25px' }} onClick={() => {
                getprojcetpassword()
                /*    if (dataonprojcet.project_password === dataonprojcet.passroom) {
                   
                 }else toggle_modal_alertKick() */
              }}>SUBMIT</div>
              <div className='projectButton' style={{ border: '2px solid red', borderRadius: '5px' , color: 'white' , backgroundColor : 'red' , fontSize : '25px' , display : 'absolute' }} onClick={() => {
                setdataonprojcett({
                  ...dataonprojcet,
                  project_id: '',
                  islock: false,
                  passroom: '',
                  passroomdis: '',
                  isshowpass: false,
                  project_password: '',
                  project_name: ''
                })
                dataonprojcet.project_id = ''
                dataonprojcet.islock = false
                dataonprojcet.passroom = ''
                dataonprojcet.passroomdis = ''
                dataonprojcet.isshowpass = false
                dataonprojcet.project_password = ''
                dataonprojcet.project_name = ''
                toggle_modal_password()
              }}>CANCEL</div>
            </div>
            </div>
          </div>
        </div>
        </div>
        :
        <></>
      }


      <div className='packageReport'>
        <svg className='packageReportLine' height="105" width="703">

        </svg>
        <div style={{ position: 'absolute', top: '0', left: '0', width: '700px' }}>
          <div className='packageReportText' style={{ margin: 0 }} >ProjectS     </div>

          <ConfigProvider
            theme={{
              token: {


                colorInfo: '#85BFFF',
                colorBorder: '#85BFFF',
                colorText: '#FFF',

                colorTextDescription: '#000',
                colorBgContainer: '#000',

                zIndexPopupBase: 999999,
                colorPrimaryBg: 'blue',//สีตอน Active ใน Select
                colorBgBase: '#778899',//พื้นหลัง select ตอนกด down ลงมา

                colorTextQuaternary: '#FFFFFF',// สีตัวหนังสือของ placeholder


                colorFillTertiary: '#000',// สีตอน hover ใน Select


                colorTextBase: 'blue', // ลูกศรใน datepicker & rangepicker




                colorPrimaryBg: '#85BFFF',
                /*        
                       colorBgLayout:'red',
                       colorFillQuaternary:'red', */


              }
            }}
          >
            <div style={{ position: 'absolute', left: '55%', top: '45%' }}>
              <Cascader
                options={dropdowncatListshow}
                value={selectedValue}
                onChange={handleCascaderClick}
                placeholder="Please select"
                showSearch={{ filter: (inputValue, path) => path.some(option => option.label.toLowerCase().includes(inputValue.toLowerCase())) }}
                style={{ width: '350px' }}
                changeOnSelect />
            </div>
          </ConfigProvider>
        </div>



        {/* <div style={{ position: 'absolute', top: '40px', right: '130px', cursor: 'pointer' }} >

          <div className="ds-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px  '#5D9BE1'`, marginBottom: '20px' }}>
            <div className="ds-infoBoxText">
              <div className="ds-infoBoxTextCen">
                <input className="ds-infoBoxTextIn"
                  type='text'
                  value={filter.keySearch}
                  onChange={(e) => { setFilter({ ...filter, keySearch: e.target.value }); }}

                  placeholder='search...'
                >


                </input>
             

              </div>
            </div>
          </div>

        </div> */}
        <div style={{ position: 'absolute', marginTop: '-66px', right: '0.75%', width: '375px', height: '100px' }}>
          <div className="ds-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px  '#5D9BE1'`, marginBottom: '20px' }}>
            <div className="ds-infoBoxText">
              <div className="ds-infoBoxTextCen">
                <input className="ds-infoBoxTextIn"
                  type='text'
                  value={filter.keySearch}
                  onChange={(e) => { setFilter({ ...filter, keySearch: e.target.value }); }}

                  placeholder='search...'
                >


                </input>


              </div>
            </div>
          </div>

          <div style={{ position: 'absolute', marginTop: '-66px', right: '15%', top: '65%', cursor: 'pointer' }}
            onClick={() => {
              getprojcetonuser(1, onSearch.show, filter.keySearch);
            }}
          >
            <img src={Searchicon} style={{ width: "50px", height: "46px", }}></img>
          </div>

          <div style={{ position: 'absolute', marginTop: '66px', right: '0%', top: '-67%', cursor: 'pointer' }}
            onClick={() => {
              setFilter({ ...filter, keySearch: '', cat: '' })
              filter.keySearch = ''
              filter.cat = ''
              setSelectedValue([])

              getprojcetonuser(1, onSearch.show, filter.keySearch);
            }}
          >

            <img src={iconvclen} style={{ width: "50px", height: "50px", }}></img>              </div>

        </div>
      </div>



      <div className='cardpackagebgnone' style={{ marginLeft: `${windowSize.size}px` }}  >

        <div id="scrollableindiv" style={{ height: '100%', overflow: "auto" }}>


          <InfiniteScroll
            dataLength={templateListshow.length}
            next={() => {
              getprojcetonuser(onSearch.current + 1, onSearch.show);
            }}


            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={<p></p>}
            scrollThreshold={0.8}
            scrollableTarget="scrollableindiv"

          >

            {/*  <div className='pt-4 px-2 columns-2 gap-x-5 sm:columns-3 md:columns-2 lg:columns-3 xl:columns-5 xl:px-8'>
              {templateListshow}
            </div> */}
            <div style={{ position: 'relative', height: `${windowSize.height}px`, }}>
              {templateListshow}
            </div>

          </InfiniteScroll>
        </div>
      </div>


      <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, zIndex: '999' }}>
        <div className='alertKickBoxShadow'>
          <div className='alertKickBox'>
            <div className='alertKickBoxIn'>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead'>
                  ! ERROR !
                </div>
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning'>
                  WARNING
                </div>
              </div>
              <div className='row' style={{ height: '12.5%' }}>
                <div className='alertKickText'>
                  Passwords don't match
                </div>
              </div>
              <div className='row' style={{ height: '25%' }}>
                {/*  <div className='alertKickTextSec'>
                                    YOU CAN NOT GET OFF FROM THIS SPACESHIP
                                </div> */}
              </div>
              <div className='row alertKickBoxButton'>
                <button className='alertKickButton' onClick={() => {
                  toggle_modal_alertKick()
                }}>OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='alertKick' style={{ display: `${confrimModal ? '' : 'none'}` }}>
        <div className='alertKickBoxShadow'>
          <div className='alertKickBox'>
            <div className='alertKickBoxIn'>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: /* 'orange' */`${isLoading2.step1 === true ? isLoading2.step2 === true ? 'green' : 'ffffff': 'orange'}` }}>
                  { `${isLoading2.step1 === true ? isLoading2.step2 === true ? 'SUCCESS' : 'Loading': '! warning !'}`}
                </div>
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning' style={{ border: 'none',borderBottom: `${isLoading2.step1 === true ?  isLoading2.step2 === true ?'1px solid #fff ' :'none':' 1px solid #fff '}` }}>
                { `${isLoading2.step1 === true ? isLoading2.step2 === true ? 'SUCCESS' : '': 'warning'}`}
                {isLoading2.step2 === false && isLoading2.step1 === true ?
                <> 
                <div class="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                </>:<></>}
                </div>
                
              </div>
         
              <div className='row' style={{ height: '12.5%' }}>
                <div className='alertKickText'>

                </div>
              </div>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickTextSec'>
                  {isLoading2.step1 === true ?  isLoading2.step2 === true ?<>SUCCESS</>:<></>: <>{dataconfim.text}</>}
                
                </div>
              </div>
              {isLoading2.step1 === true ? <>
                {isLoading2.step2 === true ?
                <> 
                <div className='row alertKickBoxButton'>
                  <button className='alertKickButton' onClick={() => {
            /*         toggle_modal_alertKick() */
            setIsLoading2({...isLoading2,
              step1:false,
              step2:false
            })
            isLoading2.step1 = false
            isLoading2.step2 = false
            toggle_modal_confrimModal()
            toggle_modal_projcetViewspaceship()
            toggle_modal_sendDetailApplyPopup();
                  }}>OK</button>

                </div>
                </>
:

<>
{/* <div class="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div> */}
</>}
              </>
              : 
              <>
                <div className='row alertKickBoxButton' >
                <button className='alertKickConfirm mr-2' onClick={() => {
                  // console.log('testlog Save', projectInterestingDetail);
                  getprojcetfocus(dataconfim.project_id, dataconfim.spaceship_id, dataconfim.spaceship_ss_id)

                }}>YES</button>

                <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                  toggle_modal_confrimModal()
                  // setdataconfim({
                  //   project_id: '',
                  //   spaceship_id: '',
                  //   spaceship_ss_id: '',
                  //   text: ''



                  // })
                }}>NO</button>


              </div>
              </>}
            

            </div>
          </div>
        </div>
      </div>

      <Viewspaceship
        isOpen={projectInterestingDetail.isOpen}
        projectid={projectInterestingDetail.projectid}
        spaceid={projectInterestingDetail.spaceid}
        spaceship_profile ={projectInterestingDetail.spaceship_profile}
        mode={'1'}
        callBack={callBackClosePopup}
        pitchingReviewData={pitchingReviewData}
        owner_by={detailprojcet.project_owner}
        owner_name={detailprojcet.project_owner_name}
        projectInterestingData={projectInterestingData}
      />

      <div className='sendDetailApplyBg' style={{ display: `${sendDetailApplyPopup ? '' : 'none'}` }}>
        <div className='sendDetailApplyBox'>
          <div className='sendDetailApplyHeader'>Upload Video Pitching Project</div>
          <div className='sendDetailApplyBody'>
     


            <div className='sendDetailApplyVideo' onClick={()=>{
              
              setIsVideoLoading(false);setLoadingVideo('loading...');}}>
              <Dragger {...props}
                onRemove={e => onRemoveFile(e)}
                fileList={fileDoc}
                showUploadList={false}
                style={{ width: '100%' }}
                // disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                beforeUpload={(file, fileList) => {
                  console.log(file, fileList)
                }}
              /*  beforeUpload={(file, fileList) => { */

              /*      let ole_file = fileImport.file;
                   let count = ole_file.length + 1;
                   if(file.length > 0){
                 
             
                     for (let item of file) {
             
                       var list = {
                           uid: count,
                           ul_file_path: item,
                           ul_file_name: item.name,
                           name: item.name,
                           path: URL.createObjectURL(item),
                           url: URL.createObjectURL(item),
                           is_save: true,
                           ul_is_use: true,
                           ul_file_size: 0,
                           ul_type: file.type,
                           ul_group_name: "kickoff",
                           ul_menu_name: "kickoff",
                           ul_is_profile: false,
                           status: 'done',
                           image_file: item,
                           is_img_upload: true,
                           originFileObj: item
                       }
                       ole_file.push(list)
                       count++
             
                   }
                   } */

              /*       setFileImportList([
                        {uid: "rc-upload-1705392044222-3",
                        lastModified: 1705387231365,
                        lastModifiedDate:new Date(),
                        name:file.name,
                        size:4918014,
                        type:'video/mp4',
                        webkitRelativePath:''}
                        ]) */

              /*           let datalist = fileImport.file.filter((e) => e.byid !== undefined && e.byid == true)
                        datalist.push({
                        ul_file_path: URL.createObjectURL(file),
                        ul_file_name: file.name,
                        name: file.name,
                        path: URL.createObjectURL(file),
                        url: URL.createObjectURL(file),
                        is_save: true,
                        ul_is_use: true,
                        ul_file_size: 0,
                        ul_type: file.type,
                        ul_group_name: "kickoff",
                        ul_menu_name: "kickoff",
                        ul_is_profile: true,
                        status: 'done',
                        image_file: file,
                        is_img_upload: true
                      })
               */

              /*      console.log('ole_file',ole_file); */
              /*   setFileImport({
                   ...fileImport,file:ole_file,
                   newfile:true
          
                }) */

              /*   setFileImport(file); */

              // Prevent upload
              /*  return false; */
              /*   }} */

              >
                <p className="ant-upload-drag-icon"/*  style= {{height:'15%'}} */>
                  {/*  <InboxOutlined /> */}
                  <img src={uploadicon} style={{ width: '10%' }} />
                </p>
                <p className="ant-upload-text" style={{
                  color: '#FFF',
                  fontFamily: 'Oxanium',
                  fontSize: '30px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 'normal',
                }} >DRAG & DROP FILES HERE</p>
                <p className="ant-upload-text" style={{
                  color: '#FFF',
                  fontFamily: 'Oxanium',
                  fontSize: '30px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 'normal',
                }} >OR</p>
                <p className="ant-upload-text" style={{
                  color: '#FFF',
                  fontFamily: 'Oxanium',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  fill: 'linear-gradient(90deg, #051F6C 0%, #16285C 78.79%, #222E51 162.66%)',
                  strokeWidth: '2px',
                  stroke: '#7BD7FF',
                  filter: 'drop-shadow(0px 0px 20px #7BD7FF)',
                }} >BROWSE FILES</p>

              </Dragger>
            </div>
            <div className='sendDetailApplyVideoList'>
              {fileDoc.map((e, index) => {
                console.log('pitching_project',e)
                return (
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
           
                    <a target="_blank" /* href={e.video_pitching}  */
                    onClick = {async()=>{
                      setIsLoading2({...isLoading2,
                        step1:true,
                        step2:false
                      })
                      isLoading2.step1 = true
                      isLoading2.step2 = false
                      setConfrimModal(true);
                      if(e.ObjFile){
                        setConfrimModal(false);
                        setIsLoading2({...isLoading2,
                          step1:false,
                          step2:false
                        })
                        isLoading2.step1 = false
                        isLoading2.step2 = false
                      
                        window.open(e.ObjFile)
                      }else{
                        await GetAccesstokenToken(async(result_)=>{
                          await getFileBoxByIdImage(e.video_id,async (result)=>{
                             if(result){
                              setConfrimModal(false);
                              setIsLoading2({...isLoading2,
                                step1:false,
                                step2:false
                              })
                              isLoading2.step1 = false
                              isLoading2.step2 = false
                              e.ObjFile = result.fileImg;
                              window.open(e.ObjFile)
                             }
                            })
                          })
                      }
                   
                        
                    }}
                    style={{ color: '#ffffff', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',cursor:'pointer' }} >{isLoading == true ? 'Loading...': e.name}</a>
                    {!isLoading && <i class="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }}  onClick={()=> {setFileDoc([]);
                    settest({...test,test:''})
                    test.test = '';
  
                    }}></i>}
                  </div>
                )
              })}
            </div>
            <div className='sendDetailApplyDesc'>
              <div>Description :</div>
              <TextArea
                className='sendDetailApplyDescInput'
                value={sendDetailApply.text}
                onChange={(e) => {
                  setSendDetailApply({ ...sendDetailApply, text: e.target.value });
                  sendDetailApply.text = e.target.value;
                }}
                placeholder="Input your description..."
                autoSize={{ minRows: 1 }}
              />
            </div>
          </div>
          <div className='sendDetailApplyFooter'>
            <div className='sendDetailApplyButton' style={{ color: '#0047FF', border: '1px solid #0047FF' }} onClick={() => {
              // let textcon = ''

              // if (e.isfocus === true) {
              //   textcon = 'you want to cancel Project or not?'
              // } else textcon = 'You want to join Project or not?'
              // setdataconfim({
              //   project_id: detailprojcet.project_id,
              //   spaceship_id: e.spaceship_id,
              //   spaceship_ss_id: e.spaceship_ss_id,
              //   text: textcon
              // })
              toggle_modal_confrimModal()

            }}>SUBMIT</div>
            <div className='sendDetailApplyButton' style={{ color: 'red', border: '1px solid red' }} onClick={() => {
              setFileDoc([]);
              setSendDetailApply({});
              toggle_modal_sendDetailApplyPopup();
            }}>Cancel</div>
          </div>


          


        </div>
      </div>
      {loading && (
                    <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                        <div className='alertKickBoxShadow'>
                            <div className='alertKickBox' style={{ height: 'fit-content' }}>
                                <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                    <div className='row' style={{ height: '25%' }}>
                                        <div className='alertKickHead' style={{ color: 'orange' }}>
                                            Loading
                                        </div>
                                    </div>
                                    <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                        <div className='alertKickWarning' style={{ border: 'none' }}>
                                            <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
    </>
  )
}







export default Viewpublicprojec;

