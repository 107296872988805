import ExcelJS from 'exceljs';
import filePath from './img/effort.xlsx'
import { saveAs } from 'file-saver';
import moment from "moment";
import 'moment/locale/th';
import axios from "axios";
import Configs from "../../../config"

async function effortreport(selectedData,data,callback) {
    moment.locale('th');
  /*   let temp = {
        project_id:getProjectId,
        selectedValue:selectedData
    } */
  /*   await axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/riskManagement/Riskexcell`,
        headers: {
            'Authorization': sessionStorage.getItem('access_token'),
            'X-TTT': `${Configs.API_TTT}`,
            'Content-Type': 'application/json',
        },
        data: temp
    })
        .then(async function (response) { */
         
           let value = selectedData
            const arrayBuffer = await fetchExcelFile(filePath);

            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(arrayBuffer);
        
            const worksheet = workbook.worksheets[0];
        
            const newWorkbook = new ExcelJS.Workbook();
            const newWorksheet = newWorkbook.addWorksheet('Risk Management');
            let count = 0,sumd= 0 ,sumh = 0,summ = 0,strat = 2
            worksheet.eachRow((row, rowNumber) => {
                
                console.log(row,rowNumber);
                    
                       row.eachCell((cell, colNumber) => {
                          /*  console.log(row,colNumber,cell.value); */
                         const newCell = newWorksheet.getCell(rowNumber+count, colNumber);
                       
                         newCell.value = cell.value;
                      
  
                         let s = cell.style;
                         s.fill.bgColor = { argb: /* 'FF' + */ value.fed }
                         s.fill.fgColor = { argb: /* 'FF' + */ value.fed }
                         newCell.style = s;
                         console.log(cell.style);
                         newCell.font = { name: 'TH SarabunPSK', family: 4, size: 16, bold: true}
                         const cellLength = cell.value ? cell.value.toString().length : 0;
                         const adjustedWidth = cellLength + 2; 
                         if (colNumber !== 1) {
                          newWorksheet.getColumn(colNumber).width = Math.max(newWorksheet.getColumn(colNumber).width || 0, adjustedWidth);
                         }
                        
                         newCell.border = {
                           top: { style: 'thin', color: { argb: 'FF000000' } },
                           left: { style: 'thin', color: { argb: 'FF000000' } },
                           bottom: { style: 'thin', color: { argb: 'FF000000' } },
                           right: { style: 'thin', color: { argb: 'FF000000' } }
                         };

                         if (rowNumber === 1) {
                            newCell.value += `${moment(selectedData.month).add('year',543).format('MMMM yyyy')}`;
                         }

                       });
                       if (rowNumber === 2) {
                        value.dataTable.forEach((item, rowIndex) => {
                            sumd+= item.day || 0
                            let hours = Math.floor(item.sum);  // ส่วนจำนวนเต็มเป็นชั่วโมง
                            let minutes = Math.round((item.sum % 1) * 100);  // ส่วนทศนิยมเป็นนาที
                          
                            sumh += hours;
                            summ += minutes;
                           /*  sumh+= item.sum || 0 */
                            value.headTable.forEach((header, colIndex) => {
                              const cell = newWorksheet.getCell(rowIndex + rowNumber+1, colIndex + 1);
                           
                              let values = item[header.field];
                              const cellLength = values ? values.toString().length : 0;
                              const adjustedWidth = cellLength + 2; 
                              if (colIndex + 1 !== 1) {
                              newWorksheet.getColumn(colIndex + 1).width = Math.max(newWorksheet.getColumn(colIndex + 1).width || 0, adjustedWidth);
                              }
                              cell.font = { name: 'TH SarabunPSK', family: 4, size: 16, bold:  rowNumber === 1 ? true : false }
                          



                               
                              cell.value = values;
                              if (header.field === 'day' || header.field === 'h' || header.field === 'no') {
                                cell.alignment = { vertical: 'top', horizontal: 'center' };
                              }
                             
                             /*  if (header.field === 'rc_no'|| header.field === 'rmr_probability' || header.field === 'rmr_impact' || header.field === 'rmr_exposure' || header.field === 'rmr_mode' || header.field =='rms_name') {
                            
                                cell.alignment = { vertical: 'top', horizontal: 'center' };
                            } else if (typeof cell.value === 'string') {
                          
                                cell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
                            } */
                              cell.border = {
                                top: { style: 'thin', color: { argb: 'FF000000' } },
                                left: { style: 'thin', color: { argb: 'FF000000' } },
                                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                                right: { style: 'thin', color: { argb: 'FF000000' } }
                              };
                    
      
                            });
                            count+=1
                          });
                     }
                           
                     });
                  
                    /*  console.log(sumh,summ,summ % 60); */
                     
                     sumh += Math.floor(summ / 60);
                     summ = summ % 60; 
                     Object.keys(worksheet._merges).forEach((key) => {
                       const merge = worksheet._merges[key];
                       const model = merge.model;
                       
                       const topLeft = newWorksheet.getCell(model.top, model.left).address;
                       const bottomRight = newWorksheet.getCell(model.bottom, model.right).address;
                 
                       try {
                         console.log(`${topLeft}:${bottomRight}`);
                         
                         newWorksheet.mergeCells(`${topLeft}:${bottomRight}`);
                       } catch (error) {
                         console.error(`Error merging cells ${topLeft}:${bottomRight}:`, error);
                       }
                     });
                     let cellsum  = newWorksheet.getCell(strat+count +1, 1);
                     newWorksheet.mergeCells(`A${strat+count +1}:E${strat+count +1}`);
                     cellsum.value = 'Total';
                     cellsum.fill = {
                         type: 'pattern',
                         pattern: 'solid',
                         fgColor: { argb: value.fed  }, 
                     };
                     cellsum.alignment = { vertical: 'top', horizontal: 'center' };
                     cellsum.border = {
                         top: { style: 'thin', color: { argb: 'FF000000' } },
                         left: { style: 'thin', color: { argb: 'FF000000' } },
                         bottom: { style: 'thin', color: { argb: 'FF000000' } },
                         right: { style: 'thin', color: { argb: 'FF000000' } }
                       };
                       cellsum.font ={ name: 'TH SarabunPSK', family: 4, size: 16, bold: true}

                     cellsum  = newWorksheet.getCell(strat+count +1, 6);
                     cellsum.value = sumd;
                     cellsum.fill = {
                         type: 'pattern',
                         pattern: 'solid',
                         fgColor: { argb: value.fed  }, 
                     };
                     cellsum.alignment = { vertical: 'top', horizontal: 'center' , bold: true};
                     cellsum.border = {
                         top: { style: 'thin', color: { argb: 'FF000000' } },
                         left: { style: 'thin', color: { argb: 'FF000000' } },
                         bottom: { style: 'thin', color: { argb: 'FF000000' } },
                         right: { style: 'thin', color: { argb: 'FF000000' } }
                       };
                       cellsum.font ={ name: 'TH SarabunPSK', family: 4, size: 16}
                       cellsum  = newWorksheet.getCell(strat+count +1, 7);
                       cellsum.value = `${sumh} ชั่วโมง ${summ} นาที`;
                       cellsum.fill = {
                           type: 'pattern',
                           pattern: 'solid',
                           fgColor: { argb: value.fed  }, 
                       };
                       cellsum.alignment = { vertical: 'top', horizontal: 'center' , bold: true};
                       cellsum.border = {
                           top: { style: 'thin', color: { argb: 'FF000000' } },
                           left: { style: 'thin', color: { argb: 'FF000000' } },
                           bottom: { style: 'thin', color: { argb: 'FF000000' } },
                           right: { style: 'thin', color: { argb: 'FF000000' } }
                         };
                         cellsum.font ={ name: 'TH SarabunPSK', family: 4, size: 16}

                           cellsum  = newWorksheet.getCell(strat+count +1, 8);
                           cellsum.value = /* (sumh / 60) */'';
                           cellsum.fill = {
                               type: 'pattern',
                               pattern: 'solid',
                               fgColor: { argb: value.fed  }, 
                           };
                           cellsum.alignment = { vertical: 'top', horizontal: 'center' , bold: true};
                           cellsum.border = {
                               top: { style: 'thin', color: { argb: 'FF000000' } },
                               left: { style: 'thin', color: { argb: 'FF000000' } },
                               bottom: { style: 'thin', color: { argb: 'FF000000' } },
                               right: { style: 'thin', color: { argb: 'FF000000' } }
                             };
                             cellsum.font ={ name: 'TH SarabunPSK', family: 4, size: 16}

            const buffer = await newWorkbook.xlsx.writeBuffer();
        
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const file = new File([blob], `Federation Summary Effort Report ${moment(new Date()).add('year', 543).format('DD_MM_YY')}.xlsx`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  console.log(data.ref_id);
  
            const ref_id = data.ref_id;
            CheckAndCreateFolderBox({id:ref_id},'',file)
  
             /* saveAs(blob, `Risk Management${moment(new Date()).format('DD/MM/yyyy')}.xlsx`); */
       /*  })
        .catch(function (error) {
            console.log(error);
        }); */


            async function CheckAndCreateFolderBox(datalist,olddata,file) {
                let temp = {
                    folderLis: ["FEDERATION",`FEDERATION_${data.fed_id}`,"FEDERATION SUMMARY EFFPRT REPORT"]
                }
               /*  temp.folderLis.push(folder_name) */
                await axios({
                    method: 'post',
                    url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
                    headers: {
                      Authorization: sessionStorage.getItem('access_token'),
                      "X-TTT": `${Configs.API_TTT}`,
                      "Content-Type": "application/json",
                    },
                    data: temp
                })
                    .then(async function (response) {
                        if (response.data) {
                            datalist.files = file
                            datalist.file_name =file.name
                            await UploadFiles(datalist, response.data.folder_id,olddata)
                     
                        }
                    })
                    .catch(function (error) {
                       
                        console.log(error);
                    });
            }
            
            
            
            async function UploadFiles(item, folder_id,olddata) {
              console.log(item);
              const dataImg = new FormData();
              dataImg.append('File', item.files);
              dataImg.append('folder_id', folder_id);
              dataImg.append('key', "FEDERATION SUMMARY EFFPRT REPORT");
              dataImg.append('image_old_list', JSON.stringify(olddata));
              dataImg.append('ref_id', item.id);
            
              await axios({
                  method: 'post',
                  url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFileschecking`,
                  headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    "X-TTT": `${Configs.API_TTT}`,
                    "Content-Type": "multipart/form-data",
                  },
                  data: dataImg
              })
                  .then(async function (response) {
                      if (response.data) {
                        if (response.data.image_id_list.length > 0) {
                           /*  let as = {
                                ref_id: item.id,
                                pathimg: response.data.image_id_list[0],
                                file_name: item.file_name
        
                            } */
                            callback('')
                        
                        }
                       
                      }
                  })
                  .catch(function (error) {
                    
                      console.log(error);
                  });
            }
         
   
  }
const fetchExcelFile = async (url) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
  };

  export default effortreport;