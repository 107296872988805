
import React, { useEffect, useState,useRef } from 'react';
import { DatePicker, Tabs, Tooltip, Input, Table } from 'antd';
import { CalendarOutlined, SearchOutlined, EyeOutlined, EditOutlined, DownloadOutlined, DeleteOutlined, PlusCircleOutlined, UserOutlined, PlusOutlined, CloseOutlined, UserAddOutlined } from '@ant-design/icons';
import axios from "axios";
import { io } from 'socket.io-client';
import Configs from "../../../../config";
import dayjs, { isDayjs } from 'dayjs';
import styled from 'styled-components';
import './tabMOM.css'
import { isArray, isFunction, isPlainObject, times } from 'lodash';
import AlertConfrimDelete from '../../../../components/AlertMassge/AlertConfrimDelete';
import momreport from '../../Report/momreport'
import Swal from 'sweetalert2';
import ModelAlertConfrim from "../../../../components/AlertMassge/AlertConfrim";
import ModelAlertMassge from "../../../../components/AlertMassge/AlertMassge";
import moment from 'moment';
import TabMomAttendance from './TabMomAttendance';
import momTabUserImg from './momTabUserImg.jpg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

function TabCompanentMOM({ callbackMomSpecificField, momSpecificField, srsSpecificField, userismode, project_id, getMOM, callbackMomDelSpecificField }) {
  const [test, setTest] = useState({ test: '' });
  const [textChange, setTextChange] = useState({ text: '' });

  const [specificField, setSpecificField] = useState([]);

  const [alertKick, setAlertKick] = useState(false);
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);

  const [alertKickNot, setAlertKickNot] = useState(false);
  const toggle_modal_alertKickNot = () => setAlertKickNot(!alertKickNot);

  const [alertKickSuccess, setAlertKickSuccess] = useState(false);
  const toggle_modal_alertKickSuccess = () => setAlertKickSuccess(!alertKickSuccess);

  const [loading, setLoading] = useState(false);

  const [momTabTableSearch, setMomTabTableSearch] = useState('');
  const [momTabMode, setMomTabMode] = useState('table');
  const [momTabAddUserIsOpen, setMomTabAddUserIsOpen] = useState(false);
  const toggleMomTabAddUserIsOpen = () => { setMomTabAddUserIsOpen(!momTabAddUserIsOpen) };
  const [momTabUserIsEdit, setMomTabUserIsEdit] = useState(false);
  const toggleMomTabUserIsEdit = () => { setMomTabUserIsEdit(!momTabUserIsEdit) };
  const [momTabAddUserMode, setMomTabAddUserMode] = useState('all');
  const [momTabAddUserModeOld, setMomTabAddUserModeOld] = useState('all');

  const [momTabCompanyTabSelect, setMomTabCompanyTabSelect] = useState(null);
  const [momTabCompanyTabSelectId, setMomTabCompanyTabSelectId] = useState('');

  const [momTabAddData, setMomTabAddData] = useState({
    mom_tab: [],
    mom_link: []
  });
  
  const socketRef = useRef(null);
  useEffect(() => {

      if (socketRef.current == null) {
          socketRef.current = io(Configs.TTT_Game_Socket_Service, {
              port:Configs.TTT_Game_Socket_Service_Port
            });
      }
  }, [

  ])


  useEffect(() => {
    const { current: socket } = socketRef;
    const socFnc = (data) => {}
      try {
        socket.open();
        socket.on('locationElement', socFnc)
      } catch (error) {
        console.log(error);
      }
      
    }, []);


  const [momTabEditData, setMomTabEditData] = useState({});

  const [momTabUserSelect, setMomTabUserSelect] = useState([]);

  const [momTabUserSearch, setMomTabUserSearch] = useState('');

  const [momTabUserEditData, setMomTabUserEditData] = useState({});

  const [momData, setMomData] = useState([]);

  const [alertKickMassge, setAlertKickMassge] = useState(false);
  const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);

  const [messageError, setMessageError] = useState({
    message: 'Please fill in all information completely.',
    isError: true,
    menuTitle: ''
  })

  const [alertKickConfirm, setAlertKickConfirm] = useState(false);
  const toggle_modal_alertKickConfirm = () => { setAlertKickConfirm(!alertKickConfirm) };
  const [alertKickConfirmId, setAlertKickConfirmId] = useState('');
  const [alertKickConfirmMessage, setAlertKickConfirmMessage] = useState({
    messageMain: 'Do you want to delete data?',
    messageSec: `You won't be able to revert this!`,
    menuTitle: null
  })

  function parseEmployees(input) {
    let employees = input.split(',');

    let result = employees.map(employee => {
      let [name, position] = employee.split('-');
      return {
        name: name.trim(),
        position: position ? position.trim() : null
      };
    });

    return result;
  }

  function parseLinks(input) {
    let regex = /<<link>>(.*?)<<link>>/g;
    let matches = input.match(regex);

    if (!matches) return [];

    let result = matches.map(match => {
      match = match.replace(/<<link>>/g, '');

      let [name, path] = match.split(',');

      return {
        name: name.trim(),
        link: path.trim()
      };
    });

    return result;
  }

  const getDataForMom = async (srs_value, callback) => {
    try {
      const temp_mom_data = [];
      const id = "738fc3d7-b0ee-4bf3-b9fa-b0f3958f77f8";
      const res_doc = await axios({
        method: "GET",
        url: `${Configs.TTT_Game_Service}/api/documentTemplate/getDocumentTemplateDown/${id}`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          "X-TTT": `${Configs.API_TTT}`,
          "Content-Type": "application/json",
        },
      })
      const document_specifig = res_doc.data;
      if (document_specifig) {
        for (let item of document_specifig) {
          for (let item2 of item.dt_input_data.data) {
            for (let item3 of item2.value_list) {
              for (let item_ of srs_value) {
                for (let item2_ of item_.dt_input_data.data) {
                  for (let item3_ of item2_.value_list) {
                    if (item3.value_id == item3_.value_id) {
                      if (item3_.value_input) {
                        item3.value_input = item3_.value_input || '';
                      }
                      if (item3_.data_list) {
                        item3.data_list = item3_.data_list || [];
                      }
                      if (item3_.value_input_header) {
                        item3.value_input_header = item3_.value_input_header || '';
                      }

                    }
                  }
                }
              }
            }
          }
        }
        for (let item of document_specifig) {
          for (let item2 of item.dt_input_data.data) {
            for (let item3 of item2.value_list) {
              if (item3.value_type == 'table_column') {
                let temp_in = {
                  value_input_table: [],
                }
                for (let item of item3.table_column_list) {
                  temp_in.value_input_table.push('');
                }
                if (!item3.data_list) {
                  item3.data_list = [];
                }
                if (item3.data_list && item3.data_list.length && item3.table_column_list.length != item3.data_list[0].value_input_table.length) {
                  item3.data_list = [];
                }
                if (item3.data_list.length == 0 && item3.is_multiple == false) {
                  item3.data_list.push(temp_in);
                }

              }

              if (item3.value_id == "7f6d4901-319b-4aed-af5b-f0cbea20a942" || item3.value_id == "8ca1ace7-b475-4c19-9cd4-94453d39b3f7") { // ครั้งที่เก็บความต้องการ

                item3.data_list.forEach((item3Value, _index5) => {
                  const objEmployees = item3Value.value_input_table[2] && item3Value.value_input_table[2] !== "" ? parseEmployees(item3Value.value_input_table[2]) : null;
                  const objParseLink = item3Value.value_input_table[5] && item3Value.value_input_table[5] !== "" ? parseLinks(item3Value.value_input_table[5]) : null;
                  item3Value.value_participants = objEmployees ? objEmployees : [];
                  item3Value.value_link = objParseLink ? objParseLink : [];

                  const date = (item3Value.value_input_table[4] && item3Value.value_input_table[4] != "") ?
                    item3Value.value_input_table[4].split(' - ').map(date => dayjs(date, "DD/MM/YYYY HH:mm:ss")) :
                    [null, null];
                  const model = {
                    index: item3Value.value_input_table[0],
                    tag: "srs",
                    mom_id: null,
                    mom_no: item3Value.value_input_table[0],
                    mom_location: item3Value.value_input_table[3],
                    mom_header: item3Value.value_input_table[1],
                    mom_start_date: isDayjs(date?.[0]) ? date[0].$d : undefined,
                    mom_end_date: isDayjs(date?.[1]) ? date[1].$d : undefined,
                    mom_project_id: project_id,
                    mom_created_date: new Date(),
                    mom_link: item3Value.value_link,
                    mom_participants: item3Value.value_participants,
                    mom_group: []
                  }
                  temp_mom_data.push(model);
                });
              }
            }
          }
        }
      }

      const _momSpecificField = await getMOM();
      const mom_not_null = _momSpecificField.filter(e => e.tag == "mom" && e.mom_start_date && e.mom_id);
      const mom = _momSpecificField.filter(e => e.tag == "mom");

      mom_not_null.forEach(e => temp_mom_data.push(e));
      temp_mom_data.sort((a, b) => {
        return new Date(b.mom_start_date) - new Date(a.mom_start_date);
      });

      temp_mom_data.sort((a, b) => {
        return new Date(b.mom_start_date) - new Date(a.mom_start_date);
      });

      genNumberMOMNumber(temp_mom_data, (async (call2) => {
        if (call2) {

          callback(call2);
        }
      }))
    } catch (error) {
      console.log('error :>> ', error);
    }
  }

  async function GetAccesstokenToken(callback) {
    axios({
      method: "post",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        sessionStorage.setItem('tokenBox', response.data.tokenBox)
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  useEffect(async () => {
    GetAccesstokenToken();
    await getDataForMom(srsSpecificField, (async (calback) => {
      if (calback) {
        await getMomData(calback);
      }
    }));
  }, []);

  const handleLinkInputChange = (key, value, index) => {
    let temp = momTabAddData;
    temp.mom_link[index][key] = value;

    setMomTabAddData(temp);
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  };

  const handleLinkInputChangeEdit = (key, value, index) => {
    let temp = momTabEditData;
    temp.mom_link[index][key] = value;

    setMomTabEditData(temp);
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  };

  const genNumberMOMNumber = (data, calback) => {
    data.sort((a, b) => {
      return new Date(a.mom_start_date) - new Date(b.mom_start_date);
    });

    let index = 1;
    data.forEach(e => {
      if (e.tag == "mom") {
        e.index = index;
        index++
      }
    })
    data.sort((a, b) => {
      return new Date(b.mom_start_date) - new Date(a.mom_start_date);
    });

    setSpecificField(data);
    callbackMomSpecificField(data);
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
    if (calback) {
      calback(data)
    }
  }

  const changeValueInJson = (key, value) => {
    let temp = momTabAddData;

    if (key == "mom_date") {
      temp.mom_start_date = value[0].$d;
      temp.mom_end_date = value[1].$d;
    } else {
      temp[key] = value;
    }
    setMomTabAddData(temp);
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  }

  const changeValueInJsonEdit = (key, value) => {
    let temp = momTabEditData;

    if (key == "mom_date") {
      temp.mom_start_date = value[0].$d;
      temp.mom_end_date = value[1].$d;
    } else {
      temp[key] = value;
    }
    setMomTabEditData(temp);
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  }

  async function generateMomReport(mom_id) {
    socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL'});
    setLoading(true);
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/getMOMdataForReport`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: {
        mom_id_list: [mom_id],
        project_id: project_id

      }
    })
      .then(function (response) {
        momreport(response.data.minute_of_meeting[0], null, 'version');
      }).finally((res) => {
        setTimeout(function () {
          setLoading(false);
          toggle_modal_alertKickSuccess()
        }, 10000);

        setTest({ ...test, test: "" });
        test.test = "";
      }).catch((error) => {
        setLoading(false);
        console.log(error);
      });
    toggle_modal_alertKick();
  }

  async function downloadMOM(mom_id, type) {
    if (type != 'all') {
      socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL'});
      setLoading(true);
    }
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/downLoadMom`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: {
        mom_id_list: [mom_id],
        project_id: project_id
      }
    })
      .then(function (response) {
        let temp_all_data_mom = response.data.minute_of_meeting[0].mom_pdf;
        if (temp_all_data_mom.length > 0) {
          let find_lastversion = temp_all_data_mom.sort((a, b) => Number(b.momt_version) - Number(a.momt_version))[0]
          downloadFile(find_lastversion, "");
          if (type != 'all') {
            setLoading(false);
          }
        } else {
          toggle_modal_alertKickNot()
          if (type != 'all') {
            setLoading(false)
          }
        }
        socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL SUCCESS'});
        Swal.close();
      }).catch((error) => {
        console.log(error);
      });
  }

  async function generateMomReportAll(params) {
    for (let item of momData) {
      if (item.mom_tag == 'mom') {
        await generateMomReport(item.mom_id);
      };
    };

    // let temp_mom_id = specificField.filter((el) => {
    //   return el.tag == 'mom' && el.mom_id !== null && el.mom_id !== undefined
    // });
    // if (temp_mom_id.length > 0) {
    //   let reArangeData = temp_mom_id.map((el) => {
    //     return el.mom_id
    //   })
    //   console.log("reArangeData", reArangeData);
    //   for (const item of reArangeData) {
    //     generateMomReport(item);
    //   }
    // } else {
    //   toggle_modal_alertKickNot();
    // }
  }
  async function downloadMOMAll(params) {
    socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL'});
    setLoading(true);
    for (let item of momData) {
      if (item.mom_tag == 'mom' && item.momt_created_date) {
        await downloadMOM(item.mom_id, 'all');
      };
    };
    setLoading(false);
    socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL SUCCESS'});
    // let temp_mom_id = specificField.filter((el) => {
    //   return el.tag == 'mom' && el.mom_id !== null && el.mom_id !== undefined
    // });
    // if (temp_mom_id.length > 0) {
    //   let reArangeData = temp_mom_id.map((el) => {
    //     return el.mom_id
    //   })

    //   axios({
    //     method: 'post',
    //     url: `${Configs.TTT_Game_Service}/api/report/downLoadMom`,
    //     headers: {
    //       Authorization: sessionStorage.getItem('access_token'),
    //       'X-TTT': `${Configs.API_TTT}`,
    //       'Content-Type': 'application/json',
    //     },
    //     data: {
    //       mom_id_list: reArangeData,
    //       project_id: project_id
    //     }
    //   })
    //     .then(async function (response) {

    //       console.log("response.data.minute_of_meeting", response.data);
    //       if (response.data.minute_of_meeting.length > 0) {

    //         response.data.minute_of_meeting.forEach((url) => {
    //           if (url.mom_pdf.length > 0) {
    //             let find_lastversion = url.mom_pdf.sort((a, b) => Number(b.momt_version) - Number(a.momt_version))[0]
    //             let tempArrLoad = [];
    //             tempArrLoad.push();
    //             downloadFile(find_lastversion, 'downloadAll');

    //           }
    //         });
    //       }
    //     }).finally((res) => {
    //       setLoading(false);
    //       Swal.close();
    //     }).catch((error) => {
    //       console.log(error);
    //       setLoading(false);
    //     });
    // } else {
    //   setLoading(false);
    //   toggle_modal_alertKickNot();
    // }
  }
  const downloadFile = async (fileUrl, fileName) => {
    try {
      if (fileName === 'downloadAll') {
        let response3 = { data: '' }
        response3 = await axios.get(`https://api.box.com/2.0/files/${fileUrl.fd_img_id}/content`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
          },
          responseType: 'blob',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(response3.data);

        link.download = fileUrl.momt_filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


      } else {
        let response2 = { data: '' }
        response2 = await axios.get(`https://api.box.com/2.0/files/${fileUrl.fd_img_id}/content`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
          },
          responseType: 'blob',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(response2.data);

        link.download = fileUrl.momt_filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error(`Error downloading ${fileName}:`, error);
    }
  };

  async function addMomData() {
    let temp = {
      project_id: project_id,
      mom_location: momTabAddData.mom_location,
      mom_header: momTabAddData.mom_header,
      mom_start_date: momTabAddData.mom_start_date,
      mom_end_date: momTabAddData.mom_end_date,
      mom_record_by: momTabAddData.mom_record_by,
      mom_description: momTabAddData.mom_description,
      user_list: momTabUserSelect,
      mom_link: momTabAddData.mom_link
    }

    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/documentTemplate/addMomData`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp
    })
      .then(function (response) {
        if (response.data) {
          setMomTabAddData({
            mom_tab: [],
            mom_link: []
          });
          setMomTabUserSelect([]);
          getDataForMom(srsSpecificField, (async (calback) => {
            if (calback) {
              await getMomData(calback);
            }
          }));
          setMomTabMode('table');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function editMomData() {
    let temp = {
      project_id: project_id,
      mom_id: momTabEditData.mom_id,
      mom_location: momTabEditData.mom_location,
      mom_header: momTabEditData.mom_header,
      mom_start_date: momTabEditData.mom_start_date,
      mom_end_date: momTabEditData.mom_end_date,
      mom_record_by: momTabEditData.mom_record_by,
      mom_description: momTabEditData.mom_description,
      user_list: momTabUserSelect,
      mom_link: momTabEditData.mom_link
    }

    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/documentTemplate/editMomData`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp
    })
      .then(function (response) {
        if (response.data) {
          setMomTabAddData({
            mom_tab: [],
            mom_link: []
          });
          setMomTabUserSelect([]);
          getDataForMom(srsSpecificField, (async (calback) => {
            if (calback) {
              await getMomData(calback);
            }
          }));
          setMomTabMode('table');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function delMomDataById(id) {
    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/documentTemplate/delMomDataById/${id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      }
    })
      .then(function (response) {
        if (response.data) {
          getDataForMom(srsSpecificField, (async (calback) => {
            if (calback) {
              await getMomData(calback);
              setTest({ ...test, test: '' });
              test.test = '';
            }
          }));
          socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL SUCCESS'});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function checkMomCount() {
    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/documentTemplate/checkMomCount/${project_id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      }
    })
      .then(function (response) {
        if (response.data) {
          setMomTabAddData({ ...momTabAddData, no: parseInt(response.data.count) + 1 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getMomData(tempData) {
    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/documentTemplate/getMomData/${project_id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      }
    })
      .then(function (response) {
        if (response.data) {
          let getSrs = tempData.filter((e) => { return e.tag === 'srs' });

          for (let item of getSrs) {
            var setFa = {
              mom_id: null,
              mom_no: null,
              mom_location: item.mom_location,
              mom_header: item.mom_header,
              mom_start_date: item.mom_start_date,
              mom_end_date: item.mom_end_date,
              mom_project_id: item.mom_project_id,
              mom_is_use: true,
              mom_created_date: item.mom_created_date,
              mom_updated_date: null,
              mom_created_by: null,
              mom_updated_by: null,
              mom_record_by: null,
              mom_description: null,
              mom_code: null,
              user_list: [],
              mom_link: item.mom_link.map((map) => {
                return {
                  md_name: map.name,
                  md_link: map.link
                }
              }),
              mom_tag: "srs"
            }
            response.data.data.push(setFa);
          }
          const sortedData = response.data.data.sort((a, b) => {
            return new Date(b.mom_start_date) - new Date(a.mom_start_date);
          });
          setMomData(sortedData);
          console.log('testlog response.data.data', sortedData);

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleOnDragTab = (result) => {
    if (!result.destination) return;

    for (let item of momTabUserSelect) {
      if (result.source.index > result.destination.index) {
        if (item.mmp_sort_company >= result.destination.index && item.mmp_sort_company < result.source.index) {
          item.mmp_sort_company++;
        }
        if (item.mp_company == result.draggableId) {
          item.mmp_sort_company = result.destination.index;
        }
      } else {
        if (item.mmp_sort_company > result.source.index && item.mmp_sort_company <= result.destination.index) {
          item.mmp_sort_company = item.mmp_sort_company - 1;
        }
        if (item.mp_company == result.draggableId) {
          item.mmp_sort_company = result.destination.index;
        }
      }
    }

    setTest({ ...test, test: '' });
    test.test = '';
  };

  const handleOnDragPersonal = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) return;

    let filteredList = momTabUserSelect.filter(fil =>
      momTabUserSearch !== ''
        ? fil.mp_name.toString().toLocaleLowerCase().includes(momTabUserSearch.toString().toLocaleLowerCase()) && fil.mp_company === momTabCompanyTabSelectId
        : fil.mp_company === momTabCompanyTabSelectId
    ).sort((a, b) => a.mmp_sort_personal - b.mmp_sort_personal);

    let draggedItem = filteredList[source.index];
    let draggedOverItem = filteredList[destination.index];

    let draggedItemId = draggedItem?.mp_id;
    let draggedItemSort = draggedItem?.mmp_sort_personal;
    let draggedOverItemSort = draggedOverItem?.mmp_sort_personal || null;

    for (let item of momTabUserSelect) {
      if (draggedItemSort > draggedOverItemSort) {
        if (item.mmp_sort_personal >= draggedOverItemSort && item.mmp_sort_personal < draggedItemSort) {
          item.mmp_sort_personal++;
        }
        if (item.mp_id == draggedItemId) {
          item.mmp_sort_personal = draggedOverItemSort;
        }
      } else {
        if (item.mmp_sort_personal > draggedItemSort && item.mmp_sort_personal <= draggedOverItemSort) {
          item.mmp_sort_personal = item.mmp_sort_personal - 1;
        }
        if (item.mp_id == draggedItemId) {
          item.mmp_sort_personal = draggedOverItemSort;
        }
      }
    }

    setTest({ ...test, test: '' });
    test.test = '';
  };

  return (
    <>
      {momTabMode == 'table' && <div className='momTabBox'>
        <div style={{ margin: 0, padding: '0 0 5px 0', borderBottom: '2px solid #0047FF' }}>
          <div className='momTabBoxHeader'>MINUTE OF MEETING LIST</div>
        </div>

        <div style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '25%' }}>
            <Input
              className='momTabSearch'
              placeholder='Search'
              prefix={<SearchOutlined />}
              onChange={(e) => {
                setMomTabTableSearch(e.target.value)
              }}
            />
          </div>
          <div style={{ display: 'flex' }}>
            {!userismode ?
              <>
                <button
                  className="btnAddSpecific ml-2"
                  style={{
                    padding: '10px 20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0',
                    backgroundColor: '#0047FF',
                    borderRadius: '4px'
                  }}
                  onClick={() => {
                    checkMomCount();
                    setMomTabEditData({
                      mom_tab: [],
                      mom_link: []
                    });
                    setMomTabUserSelect([]);
                    setMomTabMode('add');
                  }}
                >
                  <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                    <PlusCircleOutlined />
                    <span>
                      NEW MOM
                    </span>
                  </div>
                </button>
              </> : null}

            <button
              className="btnAddSpecific ml-2"
              disabled={userismode}
              style={{
                cursor: !userismode ? "pointer" : "no-drop",
                padding: '10px 20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0',
                backgroundColor: '#0047FF',
                borderRadius: '4px'
              }}
              onClick={() => {
                if (!userismode) {
                  downloadMOMAll();
                }
              }}
            >
              <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 7.5H16L10 13.5L4 7.5H9V0.5H11V7.5ZM2 16.5H18V9.5H20V17.5C20 18.0523 19.5523 18.5 19 18.5H1C0.44772 18.5 0 18.0523 0 17.5V9.5H2V16.5Z" fill="white" />
                </svg>
                <span>
                  Download All
                </span>
              </div>

            </button>
            <button
              className="btnAddSpecific ml-2"
              disabled={userismode}
              style={{
                cursor: !userismode ? "pointer" : "no-drop",
                padding: '10px 20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0',
                backgroundColor: '#0047FF',
                borderRadius: '4px'
              }}
              onClick={() => {
                if (!userismode) {
                  socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL'});
                  generateMomReportAll();
                }
              }}
            >
              <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 5.75C16.7949 5.75 18.25 4.29493 18.25 2.5H19.75C19.75 4.29493 21.2051 5.75 23 5.75V7.25C21.2051 7.25 19.75 8.70507 19.75 10.5H18.25C18.25 8.70507 16.7949 7.25 15 7.25V5.75ZM4 7.5C4 6.39543 4.89543 5.5 6 5.5H13V3.5H6C3.79086 3.5 2 5.29086 2 7.5V17.5C2 19.7091 3.79086 21.5 6 21.5H18C20.2091 21.5 22 19.7091 22 17.5V12.5H20V17.5C20 18.6046 19.1046 19.5 18 19.5H6C4.89543 19.5 4 18.6046 4 17.5V7.5Z" fill="#FFF414" />
                </svg>

                <span>
                  GENERATE ALL
                </span>
              </div>
            </button>
          </div>
        </div>

        <div style={{ flexGrow: '1', height: '100%', overflow: 'auto' }}>
          <table>
            <thead className='momTabTableHeader'>
              <td className='momTabTableHeaderText'>No.</td>
              <td className='momTabTableHeaderText'>ประเภท</td>
              <td className='momTabTableHeaderText'>MOM Code</td>
              <td className='momTabTableHeaderText' style={{ width: '30%' }}>หัวข้อการประชุม</td>
              <td className='momTabTableHeaderText'>วันที่เริ่มต้น - สิ้นสุด</td>
              <td className='momTabTableHeaderText'>ผู้เข้าร่วมการประชุม</td>
              <td className='momTabTableHeaderText'>Management</td>
            </thead>
            <tbody className='momTabTableBody'>
              {momData?.filter((filter) => {
                return momTabTableSearch != '' ? (filter.mom_header || '').toString().toLocaleLowerCase().includes(momTabTableSearch.toString().toLocaleLowerCase()) || (filter.mom_code || '').toString().toLocaleLowerCase().includes(momTabTableSearch.toString().toLocaleLowerCase()) : filter
              }).map((e, i) => {
                return <>
                  <tr>
                    <td className='momTabTableBodyText'>{i + 1}</td>
                    <td className='momTabTableBodyText' style={{ color: `${e.mom_tag != 'srs' ? '#0094FF' : '#26E607'}` }}>{e.mom_tag || 'MOM'}</td>
                    <td className='momTabTableBodyText'>{e.mom_tag != 'srs' ? e.mom_code || '-' : '-'}</td>
                    <td className='momTabTableBodyText' style={{ textAlign: 'start' }}>{e.mom_header}</td>
                    <td className='momTabTableBodyText'>{moment(e.mom_start_date).format('DD/MM/YYYY HH.mm')}<br />{moment(e.mom_end_date).format('DD/MM/YYYY HH.mm')}</td>
                    <td className='momTabTableBodyText'><UserOutlined /> {e.user_list.length || 0}</td>
                    <td className='momTabTableBodyText' style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                      <Tooltip placement="top" title={'View'} overlayClassName="momTabCustomTooltip">
                        <EyeOutlined style={{ cursor: 'pointer' }} onClick={() => {
                          setMomTabUserSelect(e.user_list);
                          setMomTabCompanyTabSelectId(null);
                          setMomTabCompanyTabSelect(null);
                          setMomTabEditData(e);
                          setMomTabMode('view');
                        }} />
                      </Tooltip>
                      {e.mom_tag != 'srs' && <>
                        <Tooltip placement="top" title={'Edit'} overlayClassName="momTabCustomTooltip">
                          <EditOutlined style={{ cursor: 'pointer' }} onClick={() => {
                            setMomTabUserSelect(e.user_list);
                            setMomTabCompanyTabSelectId(null);
                            setMomTabCompanyTabSelect(null);
                            setMomTabEditData(e);
                            setMomTabMode('edit');
                          }} />
                        </Tooltip>
                        <Tooltip placement="top" title={'Download'} overlayClassName="momTabCustomTooltip">
                          <DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => {
                          
                            downloadMOM(e.mom_id);
                            toggle_modal_alertKick();
                          }} />
                        </Tooltip>

                        <Tooltip placement="top" title={'Generate'} overlayClassName="momTabCustomTooltip">
                          <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} onClick={() => {
                             socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL'});
                            generateMomReport(e.mom_id);
                            e.momt_created_date = new Date();
                            toggle_modal_alertKick();
                          }}>
                            <path d="M15 5.75C16.7949 5.75 18.25 4.29493 18.25 2.5H19.75C19.75 4.29493 21.2051 5.75 23 5.75V7.25C21.2051 7.25 19.75 8.70507 19.75 10.5H18.25C18.25 8.70507 16.7949 7.25 15 7.25V5.75ZM4 7.5C4 6.39543 4.89543 5.5 6 5.5H13V3.5H6C3.79086 3.5 2 5.29086 2 7.5V17.5C2 19.7091 3.79086 21.5 6 21.5H18C20.2091 21.5 22 19.7091 22 17.5V12.5H20V17.5C20 18.6046 19.1046 19.5 18 19.5H6C4.89543 19.5 4 18.6046 4 17.5V7.5Z" fill="#FFF" />
                          </svg>
                        </Tooltip>
                        <Tooltip placement="top" title={'Delete'} overlayClassName="momTabCustomTooltip">
                          <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => {
                          /*  socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL'}); */
                          setAlertKickConfirmId(e.mom_id);
                            setTest({ ...test, test: '' });
                            test.test = '';
                            toggle_modal_alertKickConfirm();
                          }} />
                        </Tooltip>
                      </>}
                    </td>
                  </tr>
                </>
              })}
            </tbody>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>}

      {momTabMode == 'add' && <div className='momTabBox'>
        <div style={{ margin: 0, padding: '0 0 5px 0', display: 'flex', justifyContent: 'space-between' }}>
          <div className='momTabBoxHeader'>MINUTE OF MEETING ADD</div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div className='momTabAddButton' onClick={async () => {
              let check = true;
              if (momTabAddData.mom_start_date
                && momTabAddData.mom_end_date
                && momTabAddData.mom_record_by?.trim()?.length > 0
                && momTabAddData.mom_header?.trim()?.length > 0
                && momTabAddData.mom_description?.trim()?.length > 0
                && momTabAddData.mom_location?.trim()?.length > 0) {
                if (momTabAddData.mom_link?.length > 0) {
                  for (let item of momTabAddData.mom_link) {
                    if (!item.name || item.name?.trim()?.length < 1 || !item.link || item.link?.trim()?.length < 1) {
                      check = false
                    }
                  }
                }
              } else {
                check = false
              }

              if (check) {
                addMomData();
              } else {
                toggle_modal_alertKickMassge();
              }
            }}>SAVE</div>
            <div className='momTabAddButton' onClick={() => {
              setMomTabAddData({
                mom_tab: [],
                mom_link: []
              });
              setMomTabUserSelect([]);
              setMomTabMode('table');
            }}>CANCEL</div>
          </div>
        </div>

        <div style={{ overflow: 'auto' }}>
          <div className='momTabAddBox'>
            <div style={{ margin: 0, padding: '0 0 5px 0', borderBottom: '2px solid #0047FF', width: '100%', display: 'flex', gap: '10px', alignItems: 'center', height: 'fit-content' }}>
              <div className='momTabBoxHeader'>บันทึกการประชุม : ครั้งที่</div>
              <div className='momTabAddNum'>{momTabAddData.no}</div>
            </div>

            <div className='momTabAddBody'>
              <div className='momTabAddBodyLeft'>
                <div>
                  <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabAddData.mom_start_date && momTabAddData.mom_end_date ? 'none' : ''}` }}>*</span>เวลา :</div>
                  <RangePicker
                    className='rangePickerMomTab'
                    showTime
                    format={"DD/MM/YYYY HH:mm:ss"}
                    style={{ borderRadius: '0px', width: '380px' }}
                    allowClear={false}
                    onChange={(_, v) => {
                      changeValueInJson(`mom_date`, _);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabAddData.mom_record_by?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ผู้บันทึกการประชุม :</div>
                  <TextArea
                    className='textAreaMomTab'
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    onChange={(v) => {
                      changeValueInJson('mom_record_by', v.target.value);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabAddData.mom_header?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>หัวข้อการประชุม :</div>
                  <TextArea
                    className='textAreaMomTab'
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    onChange={(v) => {
                      changeValueInJson('mom_header', v.target.value);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabAddData.mom_description?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>รายละเอียดการประชุม :</div>
                  <TextArea
                    className='textAreaMomTab'
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    onChange={(v) => {
                      changeValueInJson('mom_description', v.target.value);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabAddData.mom_location?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>สถานที่ :</div>
                  <TextArea
                    className='textAreaMomTab'
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    onChange={(v) => {
                      changeValueInJson('mom_location', v.target.value);
                    }}
                  />
                </div>

                <div className='momTabAddLickDocument'>
                  <div>LINK DOCUMENT</div>
                  <div style={{ border: '1px solid #0047FF', backgroundColor: '#0A2268', padding: '5px', cursor: 'pointer', position: 'sticky', right: 0, display: 'flex', alignItems: 'center' }} onClick={() => {
                    momTabAddData.mom_link = momTabAddData.mom_link || [];
                    momTabAddData.mom_link?.push({
                      name: '',
                      link: ''
                    });

                    setTest({ ...test, test: '' });
                    test.test = '';
                  }}>
                    <PlusOutlined style={{ fontSize: '12px' }} />
                  </div>
                </div>
                {momTabAddData.mom_link?.map((el, il) => {
                  return <>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className='momTabAddLinkIndex'>{il + 1}</div>
                        <div style={{ border: '1px solid #FF0000', backgroundColor: '#920000', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                          momTabAddData.mom_link?.splice(il, 1);

                          setTest({ ...test, test: '' });
                          test.test = '';
                        }}>
                          <DeleteOutlined />
                        </div>
                      </div>

                      <div style={{ border: '2px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <div style={{ minWidth: '10%', textAlign: 'end' }}><span style={{ color: 'red', display: `${el.name?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ชื่อ :</div>
                          <TextArea
                            className='textAreaMomTab'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={el.name}
                            onChange={(e) => handleLinkInputChange('name', e.target.value, il)}
                          />
                        </div>

                        <div style={{ display: 'flex', gap: '10px' }}>
                          <div style={{ minWidth: '10%', textAlign: 'end' }}><span style={{ color: 'red', display: `${el.link?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>Link :</div>
                          <TextArea
                            className='textAreaMomTab'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={el.link}
                            onChange={(e) => handleLinkInputChange('link', e.target.value, il)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                })}
              </div>

              <div className='momTabAddBodyRight'>
                <div style={{ overflow: 'auto', width: '650px' }}>
                  <DragDropContext onDragEnd={handleOnDragTab}>
                    <Droppable droppableId="companies" direction="horizontal">
                      {(provided) => (
                        <div
                          style={{ width: '100%', display: 'flex' }}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {momTabUserSelect?.filter((item, index, self) =>
                            index === self.findIndex(t => t.mp_company === item.mp_company)
                          )?.sort((a, b) => {
                            return (a.mmp_sort_company || 0) - (b.mmp_sort_company || 0)
                          }
                          )?.map((et, it) => (
                            <Draggable key={et.mp_company} draggableId={et.mp_company} index={it}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    background: `${momTabCompanyTabSelectId == et.mp_company ? '#002B99' : 'linear-gradient(to Left, rgba(0,43,153,0.5) 0%, rgba(0,14,51,0.5) 100%)'}`,
                                    padding: '0 7.5px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px',
                                    cursor: 'pointer',
                                    ...provided.draggableProps.style,
                                  }}
                                  onClick={() => {
                                    setMomTabCompanyTabSelectId(et.mp_company);
                                    setMomTabCompanyTabSelect(it);
                                    setTest({ ...test, test: '' });
                                    test.test = '';
                                  }}
                                >
                                  <div style={{ width: 'fit-content', padding: '5px 10px', whiteSpace: 'nowrap' }}>
                                    {et.mp_company}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div style={{ border: '2px solid #0047FF', padding: '10px', display: 'flex', alignItems: 'flex-start', gap: '10px', flexDirection: 'column', flexGrow: '1' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <div>ผู้เข้าร่วมประชุม</div>
                      <div style={{ border: '1px solid #0047FF', backgroundColor: '#0A2268', padding: '5px', cursor: 'pointer', position: 'sticky', right: 0, display: 'flex', alignItems: 'center' }} onClick={() => {
                        toggleMomTabAddUserIsOpen();
                      }}>
                        <PlusOutlined style={{ fontSize: '12px' }} />
                      </div>
                      <Input
                        className='momTabAddSearch'
                        value={momTabUserSearch}
                        onChange={(e) => {
                          setMomTabUserSearch(e.target.value);
                        }}
                      />
                    </div>

                    <div>
                      <UserOutlined /> {momTabUserSelect?.filter((fil) => {
                        return fil.mp_company == momTabCompanyTabSelectId
                      })?.length || 0}
                    </div>
                  </div>

                  <div style={{ display: 'flex', gap: '10px', width: '100%', flexDirection: 'column' }}>
                    {!momTabUserIsEdit ?
                      <>
                        <DragDropContext onDragEnd={handleOnDragPersonal}>
                          <Droppable droppableId="momTabUserSelect">
                            {(provided) => (
                              <div {...provided.droppableProps} ref={provided.innerRef} style={{ display: 'flex', gap: '10px', width: '100%', flexDirection: 'column', position: 'relative' }}>
                                {momTabUserSelect?.filter((fil) => {
                                  return momTabUserSearch !== ''
                                    ? fil.mp_name.toString().toLocaleLowerCase().includes(momTabUserSearch.toString().toLocaleLowerCase()) && fil.mp_company === momTabCompanyTabSelectId
                                    : fil.mp_company === momTabCompanyTabSelectId;
                                })?.sort((a, b) => {
                                  return (a.mmp_sort_personal || 0) - (b.mmp_sort_personal || 0)
                                }
                                )?.map((e, i) => (
                                  <Draggable key={e.mp_id} draggableId={e.mp_id.toString()} index={i}>
                                    {(provided, snapshot) => {
                                      const isDragging = snapshot.isDragging;
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                            display: 'flex',
                                            gap: '10px',
                                            width: '100%',
                                            flexDirection: 'row',
                                            transition: 'none',
                                            boxShadow: isDragging ? '0 2px 10px rgba(0, 0, 0, 0.2)' : 'none',
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <div className='momTabAddLinkIndex'>{i + 1}</div>
                                            <div style={{ border: '1px solid #E4DA15', backgroundColor: '#9D9602', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                                              setMomTabUserEditData(e);
                                              toggleMomTabUserIsEdit();
                                              setTest({ ...test, test: '' });
                                              test.test = '';
                                            }} >
                                              <EditOutlined />
                                            </div>
                                            <div style={{ border: '1px solid #FF0000', backgroundColor: '#920000', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                                              let findIndexUser = momTabUserSelect.findIndex((find) => find.mp_id == e.mp_id);
                                              if (findIndexUser > -1) {
                                                momTabUserSelect.splice(findIndexUser, 1);
                                              }
                                              let check = momTabUserSelect?.filter((fil) => fil.mp_company === momTabCompanyTabSelectId);

                                              if (check?.length === 0) {
                                                let findIndex = momTabEditData.mom_tab?.findIndex((find) => find.mp_company === e.mp_company);
                                                if (findIndex > -1) {
                                                  momTabEditData.mom_tab.splice(findIndex, 1);
                                                }
                                                setMomTabCompanyTabSelect(null);
                                                setMomTabCompanyTabSelectId('');
                                              }
                                              setTest({ ...test, test: '' });
                                              test.test = '';
                                            }}>
                                              <DeleteOutlined />
                                            </div>
                                          </div>

                                          <div style={{ border: '1px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px', borderRadius: '10px' }}>
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                              <div style={{ minWidth: '20%', height: '100%', background: 'rgba(76, 76, 76, 0.1)', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
                                                <div style={{ width: '50%', height: '50%', border: '1px solid #0047FF', borderRadius: '50%', backgroundImage: `url(${momTabUserImg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                                                <div style={{ fontSize: '12px' }}>{e.mom_code || '-'}</div>
                                              </div>
                                              <div>
                                                <div style={{ color: '#0094FF' }}>{e.mp_name || '-'}</div>
                                                <div>{e.mp_position || '-'}</div>
                                                <div>{e.mp_email || '-'}</div>
                                                <div>{e.mp_tel || '-'}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </> : <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px' }}>
                        <div style={{ border: '1px solid #0047FF', padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                          <div>ชื่อ-นามสกุล :</div>
                          <div style={{ color: '#0094FF' }}>{momTabUserEditData.mp_name}</div>

                          <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_position?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ตำแหน่ง :</div>
                          <TextArea
                            className='textAreaMomTabAddUser'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={momTabUserEditData.mp_position}
                            onChange={(e) => {
                              setMomTabUserEditData({ ...momTabUserEditData, mp_position: e.target.value });
                            }}
                          />

                          <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserEditData.mp_email?.trim()) || momTabUserEditData.mp_email == null ? 'none' : ''}` }}>*</span>Email :</div>
                          <TextArea
                            className='textAreaMomTabAddUser'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={momTabUserEditData.mp_email}
                            onChange={(e) => {
                              setMomTabUserEditData({ ...momTabUserEditData, mp_email: e.target.value });
                            }}
                          />

                          <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_tel?.trim()?.length > 9 || momTabUserEditData.mp_tel?.trim()?.length < 1 || momTabUserEditData.mp_tel == null ? 'none' : ''}` }}>*</span>เบอร์โทร :</div>
                          <TextArea
                            className='textAreaMomTabAddUser'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={momTabUserEditData.mp_tel}
                            onChange={(e) => {
                              let value = e.target.value.replace(/\D/g, '');

                              if (value.length > 3) {
                                value = `${value.slice(0, 3)}-${value.slice(3)}`;
                              }

                              setMomTabUserEditData({ ...momTabUserEditData, mp_tel: value });
                            }}
                          />
                        </div>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                          <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                            if (momTabUserEditData.mp_position?.trim()?.length > 0 &&
                              (momTabUserEditData.mp_email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserEditData.mp_email?.trim()) || momTabUserEditData.mp_email == null) &&
                              (momTabUserEditData.mp_tel?.trim()?.length > 9 || momTabUserEditData.mp_tel?.trim()?.length < 1 || momTabUserEditData.mp_tel == null)
                            ) {
                              let findIndex = momTabUserSelect.findIndex((find) => find.mp_id == momTabUserEditData.mp_id);
                              if (findIndex > -1) {
                                momTabUserSelect[findIndex] = momTabUserEditData;
                              };
                              toggleMomTabUserIsEdit();
                            } else {
                              toggle_modal_alertKickMassge();
                            }
                          }}>SAVE</div>
                          <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                            toggleMomTabUserIsEdit();
                          }}>CANCEL</div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}

      {momTabMode == 'edit' && <div className='momTabBox'>
        <div style={{ margin: 0, padding: '0 0 5px 0', display: 'flex', justifyContent: 'space-between' }}>
          <div className='momTabBoxHeader'>MINUTE OF MEETING EDIT</div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div className='momTabAddButton' onClick={async () => {
              let check = true;
              if (momTabEditData.mom_start_date
                && momTabEditData.mom_end_date
                && momTabEditData.mom_record_by?.trim()?.length > 0
                && momTabEditData.mom_header?.trim()?.length > 0
                && momTabEditData.mom_description?.trim()?.length > 0
                && momTabEditData.mom_location?.trim()?.length > 0) {
                if (momTabEditData.mom_link?.length > 0) {
                  for (let item of momTabEditData.mom_link) {
                    if (!item.md_name || item.md_name?.trim()?.length < 1 || !item.md_link || item.md_link?.trim()?.length < 1) {
                      check = false
                    }
                  }
                }
              } else {
                check = false
              }

              if (check) {
                editMomData();
              } else {
                toggle_modal_alertKickMassge();
              }
            }}>SAVE</div>
            <div className='momTabAddButton' onClick={() => {
              setMomTabAddData({
                mom_tab: [],
                mom_link: []
              });
              setMomTabUserSelect([]);
              setMomTabMode('table');
              getDataForMom(srsSpecificField, (async (calback) => {
                if (calback) {
                  await getMomData(calback);
                }
              }));
            }}>CANCEL</div>
          </div>
        </div>

        <div style={{ overflow: 'auto' }}>
          <div className='momTabAddBox'>
            <div style={{ margin: 0, padding: '0 0 5px 0', borderBottom: '2px solid #0047FF', width: '100%', display: 'flex', gap: '10px', alignItems: 'center', height: 'fit-content' }}>
              <div className='momTabBoxHeader'>บันทึกการประชุม : ครั้งที่</div>
              <div className='momTabAddNum'>{momTabEditData.mom_no}</div>
            </div>

            <div className='momTabAddBody'>
              <div className='momTabAddBodyLeft'>
                <div>
                  <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabEditData.mom_start_date && momTabEditData.mom_end_date ? 'none' : ''}` }}>*</span>เวลา :</div>
                  <RangePicker
                    className='rangePickerMomTab'
                    showTime
                    format={"DD/MM/YYYY HH:mm:ss"}
                    style={{ borderRadius: '0px', width: '380px' }}
                    allowClear={false}
                    value={
                      momTabEditData.mom_start_date && momTabEditData.mom_end_date
                        ? [dayjs(momTabEditData.mom_start_date), dayjs(momTabEditData.mom_end_date)]
                        : [null, null]
                    }
                    onChange={(_, v) => {
                      changeValueInJsonEdit(`mom_date`, _);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabEditData.mom_record_by?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ผู้บันทึกการประชุม :</div>
                  <TextArea
                    className='textAreaMomTab'
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    value={momTabEditData.mom_record_by}
                    onChange={(v) => {
                      changeValueInJsonEdit('mom_record_by', v.target.value);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabEditData.mom_header?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>หัวข้อการประชุม :</div>
                  <TextArea
                    className='textAreaMomTab'
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    value={momTabEditData.mom_header}
                    onChange={(v) => {
                      changeValueInJsonEdit('mom_header', v.target.value);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabEditData.mom_description?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>รายละเอียดการประชุม :</div>
                  <TextArea
                    className='textAreaMomTab'
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    value={momTabEditData.mom_description}
                    onChange={(v) => {
                      changeValueInJsonEdit('mom_description', v.target.value);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabEditData.mom_location?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>สถานที่ :</div>
                  <TextArea
                    className='textAreaMomTab'
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    value={momTabEditData.mom_location}
                    onChange={(v) => {
                      changeValueInJsonEdit('mom_location', v.target.value);
                    }}
                  />
                </div>

                <div className='momTabAddLickDocument'>
                  <div>LINK DOCUMENT</div>
                  <div style={{ border: '1px solid #0047FF', backgroundColor: '#0A2268', padding: '5px', cursor: 'pointer', position: 'sticky', right: 0, display: 'flex', alignItems: 'center' }} onClick={() => {
                    momTabEditData.mom_link = momTabEditData.mom_link || [];
                    momTabEditData.mom_link?.push({
                      name: '',
                      link: ''
                    });

                    setTest({ ...test, test: '' });
                    test.test = '';
                  }}>
                    <PlusOutlined style={{ fontSize: '12px' }} />
                  </div>
                </div>
                {momTabEditData.mom_link?.map((el, il) => {
                  return <>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className='momTabAddLinkIndex'>{il + 1}</div>
                        <div style={{ border: '1px solid #FF0000', backgroundColor: '#920000', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                          momTabEditData.mom_link?.splice(il, 1);

                          setTest({ ...test, test: '' });
                          test.test = '';
                        }}>
                          <DeleteOutlined />
                        </div>
                      </div>

                      <div style={{ border: '2px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <div style={{ minWidth: '10%', textAlign: 'end' }}><span style={{ color: 'red', display: `${el.md_name?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ชื่อ :</div>
                          <TextArea
                            className='textAreaMomTab'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={el.md_name}
                            onChange={(e) => handleLinkInputChangeEdit('md_name', e.target.value, il)}
                          />
                        </div>

                        <div style={{ display: 'flex', gap: '10px' }}>
                          <div style={{ minWidth: '10%', textAlign: 'end' }}><span style={{ color: 'red', display: `${el.md_link?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>Link :</div>
                          <TextArea
                            className='textAreaMomTab'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={el.md_link}
                            onChange={(e) => handleLinkInputChangeEdit('md_link', e.target.value, il)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                })}
              </div>

              <div className='momTabAddBodyRight'>
                <div style={{ overflow: 'auto', width: '650px' }}>
                  <DragDropContext onDragEnd={handleOnDragTab}>
                    <Droppable droppableId="companies" direction="horizontal">
                      {(provided) => (
                        <div
                          style={{ width: '100%', display: 'flex' }}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {momTabUserSelect?.filter((item, index, self) =>
                            index === self.findIndex(t => t.mp_company === item.mp_company)
                          )?.sort((a, b) => {
                            return (a.mmp_sort_company || 0) - (b.mmp_sort_company || 0)
                          }
                          )?.map((et, it) => (
                            <Draggable key={et.mp_company} draggableId={et.mp_company} index={it}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    background: `${momTabCompanyTabSelectId == et.mp_company ? '#002B99' : 'linear-gradient(to Left, rgba(0,43,153,0.5) 0%, rgba(0,14,51,0.5) 100%)'}`,
                                    padding: '0 7.5px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px',
                                    cursor: 'pointer',
                                    ...provided.draggableProps.style,
                                  }}
                                  onClick={() => {
                                    setMomTabCompanyTabSelectId(et.mp_company);
                                    setMomTabCompanyTabSelect(it);
                                    setTest({ ...test, test: '' });
                                    test.test = '';
                                  }}
                                >
                                  <div style={{ width: 'fit-content', padding: '5px 10px', whiteSpace: 'nowrap' }}>
                                    {et.mp_company}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div style={{ border: '2px solid #0047FF', padding: '10px', display: 'flex', alignItems: 'flex-start', gap: '10px', flexDirection: 'column', flexGrow: '1' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <div>ผู้เข้าร่วมประชุม</div>
                      <div style={{ border: '1px solid #0047FF', backgroundColor: '#0A2268', padding: '5px', cursor: 'pointer', position: 'sticky', right: 0, display: 'flex', alignItems: 'center' }} onClick={() => {
                        toggleMomTabAddUserIsOpen();
                      }}>
                        <PlusOutlined style={{ fontSize: '12px' }} />
                      </div>
                      <Input
                        className='momTabAddSearch'
                        value={momTabUserSearch}
                        onChange={(e) => {
                          setMomTabUserSearch(e.target.value);
                        }}
                      />
                    </div>

                    <div>
                      <UserOutlined /> {momTabUserSelect?.filter((fil) => {
                        return fil.mp_company == momTabCompanyTabSelectId
                      })?.length || 0}
                    </div>
                  </div>

                  <div style={{ width: '100%' }}>
                    {!momTabUserIsEdit ?
                      <>
                        <DragDropContext onDragEnd={handleOnDragPersonal}>
                          <Droppable droppableId="momTabUserSelect">
                            {(provided) => (
                              <div {...provided.droppableProps} ref={provided.innerRef} style={{ display: 'flex', gap: '10px', width: '100%', flexDirection: 'column', position: 'relative' }}>
                                {momTabUserSelect?.filter((fil) => {
                                  return momTabUserSearch !== ''
                                    ? fil.mp_name.toString().toLocaleLowerCase().includes(momTabUserSearch.toString().toLocaleLowerCase()) && fil.mp_company === momTabCompanyTabSelectId
                                    : fil.mp_company === momTabCompanyTabSelectId;
                                })?.sort((a, b) => {
                                  return (a.mmp_sort_personal || 0) - (b.mmp_sort_personal || 0)
                                }
                                )?.map((e, i) => (
                                  <Draggable key={e.mp_id} draggableId={e.mp_id.toString()} index={i}>
                                    {(provided, snapshot) => {
                                      const isDragging = snapshot.isDragging;
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                            display: 'flex',
                                            gap: '10px',
                                            width: '100%',
                                            flexDirection: 'row',
                                            transition: 'none',
                                            boxShadow: isDragging ? '0 2px 10px rgba(0, 0, 0, 0.2)' : 'none',
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <div className='momTabAddLinkIndex'>{i + 1}</div>
                                            <div style={{ border: '1px solid #E4DA15', backgroundColor: '#9D9602', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                                              setMomTabUserEditData(e);
                                              toggleMomTabUserIsEdit();
                                              setTest({ ...test, test: '' });
                                              test.test = '';
                                            }} >
                                              <EditOutlined />
                                            </div>
                                            <div style={{ border: '1px solid #FF0000', backgroundColor: '#920000', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                                              let findIndexUser = momTabUserSelect.findIndex((find) => find.mp_id == e.mp_id);
                                              if (findIndexUser > -1) {
                                                momTabUserSelect.splice(findIndexUser, 1);
                                              }
                                              let check = momTabUserSelect?.filter((fil) => fil.mp_company === momTabCompanyTabSelectId);

                                              if (check?.length === 0) {
                                                let findIndex = momTabEditData.mom_tab?.findIndex((find) => find.mp_company === e.mp_company);
                                                if (findIndex > -1) {
                                                  momTabEditData.mom_tab.splice(findIndex, 1);
                                                }
                                                setMomTabCompanyTabSelect(null);
                                                setMomTabCompanyTabSelectId('');
                                              }
                                              setTest({ ...test, test: '' });
                                              test.test = '';
                                            }}>
                                              <DeleteOutlined />
                                            </div>
                                          </div>

                                          <div style={{ border: '1px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px', borderRadius: '10px' }}>
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                              <div style={{ minWidth: '20%', height: '100%', background: 'rgba(76, 76, 76, 0.1)', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
                                                <div style={{ width: '50%', height: '50%', border: '1px solid #0047FF', borderRadius: '50%', backgroundImage: `url(${momTabUserImg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                                                <div style={{ fontSize: '12px' }}>{e.mom_code || '-'}</div>
                                              </div>
                                              <div>
                                                <div style={{ color: '#0094FF' }}>{e.mp_name || '-'}</div>
                                                <div>{e.mp_position || '-'}</div>
                                                <div>{e.mp_email || '-'}</div>
                                                <div>{e.mp_tel || '-'}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>

                      </> : <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px' }}>
                        <div style={{ border: '1px solid #0047FF', padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                          <div>ชื่อ-นามสกุล :</div>
                          <div style={{ color: '#0094FF' }}>{momTabUserEditData.mp_name}</div>

                          <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_position?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ตำแหน่ง :</div>
                          <TextArea
                            className='textAreaMomTabAddUser'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={momTabUserEditData.mp_position}
                            onChange={(e) => {
                              setMomTabUserEditData({ ...momTabUserEditData, mp_position: e.target.value });
                            }}
                          />

                          <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserEditData.mp_email?.trim()) || momTabUserEditData.mp_email == null ? 'none' : ''}` }}>*</span>Email :</div>
                          <TextArea
                            className='textAreaMomTabAddUser'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={momTabUserEditData.mp_email}
                            onChange={(e) => {
                              setMomTabUserEditData({ ...momTabUserEditData, mp_email: e.target.value });
                            }}
                          />

                          <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_tel?.trim()?.length > 9 || momTabUserEditData.mp_tel?.trim()?.length < 1 || momTabUserEditData.mp_tel == null ? 'none' : ''}` }}>*</span>เบอร์โทร :</div>
                          <TextArea
                            className='textAreaMomTabAddUser'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={momTabUserEditData.mp_tel}
                            onChange={(e) => {
                              let value = e.target.value.replace(/\D/g, '');

                              if (value.length > 3) {
                                value = `${value.slice(0, 3)}-${value.slice(3)}`;
                              }

                              setMomTabUserEditData({ ...momTabUserEditData, mp_tel: value });
                            }}
                          />
                        </div>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                          <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                            if (momTabUserEditData.mp_position?.trim()?.length > 0 &&
                              (momTabUserEditData.mp_email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserEditData.mp_email?.trim()) || momTabUserEditData.mp_email == null) &&
                              (momTabUserEditData.mp_tel?.trim()?.length > 9 || momTabUserEditData.mp_tel?.trim()?.length < 1 || momTabUserEditData.mp_tel == null)
                            ) {
                              let findIndex = momTabUserSelect.findIndex((find) => find.mp_id == momTabUserEditData.mp_id);
                              if (findIndex > -1) {
                                momTabUserSelect[findIndex] = momTabUserEditData;
                              };
                              toggleMomTabUserIsEdit();
                            } else {
                              toggle_modal_alertKickMassge();
                            }
                          }}>SAVE</div>
                          <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                            toggleMomTabUserIsEdit();
                          }}>CANCEL</div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}

      {momTabMode == 'view' && <div className='momTabBox'>
        <div style={{ margin: 0, padding: '0 0 5px 0', display: 'flex', justifyContent: 'space-between' }}>
          <div className='momTabBoxHeader'>MINUTE OF MEETING VIEW</div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div className='momTabAddButton' onClick={() => {
              setMomTabEditData({
                mom_tab: [],
                mom_link: []
              });
              setMomTabUserSelect([]);
              setMomTabMode('table');
            }}>CANCEL</div>
          </div>
        </div>

        <div style={{ overflow: 'auto' }}>
          <div className='momTabAddBox'>
            <div style={{ margin: 0, padding: '0 0 5px 0', borderBottom: '2px solid #0047FF', width: '100%', display: 'flex', gap: '10px', alignItems: 'center', height: 'fit-content' }}>
              <div className='momTabBoxHeader'>บันทึกการประชุม : ครั้งที่</div>
              <div className='momTabAddNum'>{momTabEditData.mom_no}</div>
            </div>

            <div className='momTabAddBody'>
              <div className='momTabAddBodyLeft'>
                <div>
                  <div className='momTabAddText'>เวลา :</div>
                  <RangePicker
                    className='rangePickerMomTab'
                    showTime
                    disabled={true}
                    format={"DD/MM/YYYY HH:mm:ss"}
                    style={{ borderRadius: '0px', width: '380px' }}
                    allowClear={true}
                    value={
                      momTabEditData.mom_start_date && momTabEditData.mom_end_date
                        ? [dayjs(momTabEditData.mom_start_date), dayjs(momTabEditData.mom_end_date)]
                        : [null, null]
                    }
                    onChange={(_, v) => {
                      changeValueInJsonEdit(`mom_date`, _);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'>ผู้บันทึกการประชุม :</div>
                  <TextArea
                    className='textAreaMomTab'
                    disabled={true}
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    value={momTabEditData.mom_record_by || '-'}
                    onChange={(v) => {
                      changeValueInJsonEdit('mom_record_by', v.target.value);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'>หัวข้อการประชุม :</div>
                  <TextArea
                    className='textAreaMomTab'
                    disabled={true}
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    value={momTabEditData.mom_header || '-'}
                    onChange={(v) => {
                      changeValueInJsonEdit('mom_header', v.target.value);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'>รายละเอียดการประชุม :</div>
                  <TextArea
                    className='textAreaMomTab'
                    disabled={true}
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    value={momTabEditData.mom_description || '-'}
                    onChange={(v) => {
                      changeValueInJsonEdit('mom_description', v.target.value);
                    }}
                  />
                </div>

                <div>
                  <div className='momTabAddText'>สถานที่ :</div>
                  <TextArea
                    className='textAreaMomTab'
                    disabled={true}
                    autoSize={{
                      minRows: 1
                    }}
                    type='text'
                    value={momTabEditData.mom_location || '-'}
                    onChange={(v) => {
                      changeValueInJsonEdit('mom_location', v.target.value);
                    }}
                  />
                </div>

                <div className='momTabAddLickDocument'>
                  <div>LINK DOCUMENT</div>
                </div>
                {momTabEditData.mom_link?.map((el, il) => {
                  return <>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className='momTabAddLinkIndex' style={{ width: '30px' }}>{il + 1}</div>
                      </div>

                      <div style={{ border: '2px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <div style={{ minWidth: '7.5%', textAlign: 'end' }}>ชื่อ :</div>
                          <TextArea
                            className='textAreaMomTab'
                            disabled={true}
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={el.md_name || '-'}
                            onChange={(e) => handleLinkInputChangeEdit('name', e.target.value, il)}
                          />
                        </div>

                        <div style={{ display: 'flex', gap: '10px' }}>
                          <div style={{ minWidth: '7.5%', textAlign: 'end' }}>Link :</div>
                          <TextArea
                            className='textAreaMomTab'
                            disabled={true}
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={el.md_link || '-'}
                            onChange={(e) => handleLinkInputChangeEdit('link', e.target.value, il)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                })}
              </div>

              <div className='momTabAddBodyRight'>
                <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', width: '100%' }}>
                  <div style={{ fontSize: '12px', color: 'grey' }}>Generate Date {momTabEditData.momt_created_date ? moment(momTabEditData.momt_created_date).format('DD/MM/YYYY') : '-'}</div>
                  {momTabMode == 'view' && <div style={{ display: 'flex' }}>
                    <button
                      className="btnAddSpecific ml-2"
                      disabled={userismode}
                      style={{
                        cursor: !userismode ? "pointer" : "no-drop",
                        padding: '10px 20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        backgroundImage: 'linear-gradient(75deg, rgba(0,71,255,1) 0%, rgba(2,30,111,1) 100%)',
                        borderRadius: '4px'
                      }}
                      onClick={() => {
                        if (!userismode) {
                          downloadMOM(momTabEditData.mom_id);
                        }
                      }}
                    >
                      <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 7.5H16L10 13.5L4 7.5H9V0.5H11V7.5ZM2 16.5H18V9.5H20V17.5C20 18.0523 19.5523 18.5 19 18.5H1C0.44772 18.5 0 18.0523 0 17.5V9.5H2V16.5Z" fill="white" />
                        </svg>
                        <span>
                          Download
                        </span>
                      </div>

                    </button>
                    <button
                      className="btnAddSpecific ml-2"
                      disabled={userismode}
                      style={{
                        cursor: !userismode ? "pointer" : "no-drop",
                        padding: '10px 20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        backgroundImage: 'linear-gradient(75deg, rgba(0,71,255,1) 0%, rgba(2,30,111,1) 100%)',
                        borderRadius: '4px'
                      }}
                      onClick={() => {
                        socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL'});
                        generateMomReport(momTabEditData.mom_id);
                        momTabEditData.momt_created_date = new Date();
                      }}
                    >
                      <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15 5.75C16.7949 5.75 18.25 4.29493 18.25 2.5H19.75C19.75 4.29493 21.2051 5.75 23 5.75V7.25C21.2051 7.25 19.75 8.70507 19.75 10.5H18.25C18.25 8.70507 16.7949 7.25 15 7.25V5.75ZM4 7.5C4 6.39543 4.89543 5.5 6 5.5H13V3.5H6C3.79086 3.5 2 5.29086 2 7.5V17.5C2 19.7091 3.79086 21.5 6 21.5H18C20.2091 21.5 22 19.7091 22 17.5V12.5H20V17.5C20 18.6046 19.1046 19.5 18 19.5H6C4.89543 19.5 4 18.6046 4 17.5V7.5Z" fill="#FFF414" />
                        </svg>

                        <span>
                          GENERATE
                        </span>
                      </div>
                    </button>
                  </div>}
                </div>

                <div style={{ overflow: 'auto', width: '650px', marginTop: '20px ' }}>
                  <div style={{ width: '100%', display: 'flex' }}>
                    {momTabUserSelect?.filter((item, index, self) =>
                      index === self.findIndex(t => t.mp_company === item.mp_company)
                    )?.sort((a, b) => {
                      return (a.mmp_sort_company || 0) - (b.mmp_sort_company || 0)
                    }
                    )?.map((et, it) => {
                      return (
                        <div
                          style={{
                            background: `${momTabCompanyTabSelect == it ? '#002B99' : 'linear-gradient(to Left, rgba(0,43,153,0.5) 0%, rgba(0,14,51,0.5) 100%)'}`,
                            padding: '0 7.5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setMomTabCompanyTabSelectId(et.mp_company);
                            setMomTabCompanyTabSelect(it);
                            setTest({ ...test, test: '' });
                            test.test = '';
                          }}
                        >
                          <div style={{ width: 'fit-content', padding: '5px 10px', whiteSpace: 'nowrap' }}>
                            {et.mp_company}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div style={{ border: '2px solid #0047FF', padding: '10px', display: 'flex', alignItems: 'flex-start', gap: '10px', flexDirection: 'column', flexGrow: '1' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <div>ผู้เข้าร่วมประชุม</div>
                      <Input
                        className='momTabAddSearch'
                        value={momTabUserSearch}
                        onChange={(e) => {
                          setMomTabUserSearch(e.target.value);
                        }}
                      />
                    </div>

                    <div>
                      <UserOutlined /> {momTabUserSelect?.filter((fil) => {
                        return fil.mpg_id == momTabCompanyTabSelectId
                      })?.length || 0}
                    </div>
                  </div>

                  <div style={{ display: 'flex', gap: '10px', width: '100%', flexDirection: 'column' }}>
                    {!momTabUserIsEdit ?
                      <>
                        {momTabUserSelect?.filter((fil) => {
                          return momTabUserSearch != '' ? fil.mp_name.toString().toLocaleLowerCase().includes(momTabUserSearch.toString().toLocaleLowerCase()) && fil.mp_company == momTabCompanyTabSelectId : fil.mp_company == momTabCompanyTabSelectId
                        })?.sort((a, b) => {
                          return (a.mmp_sort_personal || 0) - (b.mmp_sort_personal || 0)
                        }
                        )?.map((e, i) => {
                          return <div style={{ display: 'flex', gap: '10px', width: '100%', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                              <div className='momTabAddLinkIndex' style={{ width: '30px' }}>{i + 1}</div>
                            </div>

                            <div style={{ border: '1px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px', borderRadius: '10px' }}>
                              <div style={{ display: 'flex', gap: '10px' }}>
                                <div style={{ minWidth: '20%', height: '100%', background: 'rgba(76, 76, 76, 0.1)', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
                                  <div style={{ width: '50%', height: '50%', border: '1px solid #0047FF', borderRadius: '50%', backgroundImage: `url(${momTabUserImg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                                  <div style={{ fontSize: '12px' }}>{e.mom_code || '-'}</div>
                                </div>
                                <div>
                                  <div style={{ color: '#0094FF' }}>{e.mp_name || '-'}</div>
                                  <div>{e.mp_position || '-'}</div>
                                  <div>{e.mp_email || '-'}</div>
                                  <div>{e.mp_tel || '-'}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        })}
                      </> : <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px' }}>
                        <div style={{ border: '1px solid #0047FF', padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                          <div>ชื่อ-นามสกุล :</div>
                          <div style={{ color: '#0094FF' }}>{momTabUserEditData.mp_name}</div>

                          <div>ตำแหน่ง :</div>
                          <TextArea
                            className='textAreaMomTabAddUser'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={momTabUserEditData.mp_position}
                            onChange={(e) => {
                              setMomTabUserEditData({ ...momTabUserEditData, mp_position: e.target.value });
                            }}
                          />

                          <div>Email :</div>
                          <TextArea
                            className='textAreaMomTabAddUser'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={momTabUserEditData.mp_email}
                            onChange={(e) => {
                              setMomTabUserEditData({ ...momTabUserEditData, mp_email: e.target.value });
                            }}
                          />

                          <div>เบอร์โทร :</div>
                          <TextArea
                            className='textAreaMomTabAddUser'
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            value={momTabUserEditData.mp_phone}
                            onChange={(e) => {
                              setMomTabUserEditData({ ...momTabUserEditData, mp_phone: e.target.value });
                            }}
                          />
                        </div>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                          <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                            let findIndex = momTabUserSelect.findIndex((find) => find.mp_id == momTabUserEditData.mp_id);
                            if (findIndex > -1) {
                              momTabUserSelect[findIndex] = momTabUserEditData;
                            };
                            toggleMomTabUserIsEdit();
                          }}>ADD</div>
                          <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                            toggleMomTabUserIsEdit();
                          }}>CANCEL</div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}

      <TabMomAttendance
        isOpen={momTabAddUserIsOpen}
        toggle={toggleMomTabAddUserIsOpen}
        setMomTabAddUserMode={setMomTabAddUserMode}
        momTabAddUserMode={momTabAddUserMode}
        setMomTabAddUserModeOld={setMomTabAddUserModeOld}
        momTabAddUserModeOld={momTabAddUserModeOld}
        momTabUserSelect={momTabUserSelect}
        setMomTabUserSelect={setMomTabUserSelect}
        test={test}
        setTest={setTest}
        mom_tab={momTabAddData.mom_tab}
        mom_tab_edit={momTabEditData.mom_group}
        momTabCompanyTabSelectId={momTabCompanyTabSelectId}
        project_id={project_id}
        momTabMode={momTabMode}
      />
      <ModelAlertMassge
        isOpen={alertKickMassge}
        toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
        message={messageError}
      />
      <ModelAlertConfrim
        isOpen={alertKickConfirm}
        toggle_alertKick={toggle_modal_alertKickConfirm}
        mainFuc={() => delMomDataById(alertKickConfirmId)}
        message={alertKickConfirmMessage}
      />

      <div className='alertKick' style={{ display: `${alertKickSuccess ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
        <div className='alertKickBoxShadow' >
          <div className='alertKickBox' style={{ height: 'fit-content' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `#339900` }}>
                  SUCCESS
                </div>
              </div>
              <div className='row' style={{ height: '12.5%' }}>
                <div className='alertKickText'>
                  Current version saved
                </div>
              </div>
              <div className='row alertKickBoxButton' >
                <button className='alertKickButton' onClick={() => {
                  socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL SUCCESS'});
                  toggle_modal_alertKickSuccess()
                }}>OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='alertKick' style={{ display: `${alertKickNot ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
        <div className='alertKickBoxShadow' >
          <div className='alertKickBox' style={{ height: 'fit-content' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `orange` }}>
                  !WARNING!
                </div>
              </div>
              <div className='row' style={{ height: '12.5%' }}>
                <div className='alertKickText'>
                  Data not found!!
                </div>
              </div>
              <div className='row alertKickBoxButton' >
                <button className='alertKickConfirm mr-2' onClick={() => {
                   socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL SUCCESS'});
                  toggle_modal_alertKickNot()
                }}>OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox' style={{ height: 'fit-content' }}>
              <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: 'orange' }}>
                    Loading
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning' style={{ border: 'none' }}>
                    <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TabCompanentMOM;