import React, { useEffect,useState,useRef } from 'react'
import { Input, Space, Tag, theme, Tooltip, ConfigProvider,DatePicker,Modal,Select,Button } from 'antd';


import axios from 'axios';
import moment from 'moment';
import dayjs from "dayjs";

import {
  EditOutlined
} from '@ant-design/icons';
import Configs from "../../../config";
import './modalSpaceShip.css'
import ModalConfirm from './ModalConfirm.jsx'
import { generateUUID } from 'three/src/math/MathUtils.js';
import SRSConfirmreport from '../../component/Report/srsconfirmreport ';
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Search } = Input;
const { TextArea } = Input;


function ModalStakeholder({
  isOpen,
  toggle_modal_stakeholder,
  project_id,
  getProjectDetail,
  ModuleDetails,
  updateStakeholder,
  Tags,
  QDC,
  menu_id,
  module_id,
  switModeShowDisplay
  
}){


  const [modalConfirm,setModalConfirm] = useState(false);
  const toggle_modal_confirm = () => setModalConfirm(!modalConfirm);
  const [menuIndex,setMenuIndex] = useState(null);

  const [isOpenAddConfirm,setIsOpenAddConfirm] = useState(false);
  const toggle_add_confirm = () =>{ setIsOpenAddConfirm(!isOpenAddConfirm)}

  
  const [isOpenEditConfirm,setIsOpenEditConfirm] = useState(false);
  const toggle_edit_confirm = () =>{ setIsOpenEditConfirm(!isOpenEditConfirm)}
  
  const [ModuleDetail,setModuleDetail] = useState({})
  
  const [fileUpload,setFileUpload] = useState([]);

  const [editConfirm,setEditConfirm] = useState({
    id:'',
    description:'',
    file_upload_name:'',
    file_upload_path:''

  })

  const [errorMessage,setErrorMessage] = useState('');
  const [alertKick, setAlertKick] = useState(false);
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);
  const [errorMessageload, setErrorMessageload] = useState({ status: '', message: '' });
  const [addConfirm,setAddConfirm] = useState({
    name:'',
    email:'',
    cc:[]
  })

  const [oldFileList, setOldFileList] = useState([]);
  const [otherFileList, setOtherFileList] = useState([]);
  const [loading, setLoading] = useState(false);
/*   function saveConfigModule(){

    console.log(ModuleDetail)
    let temp = ModuleDetail;


    temp.removeMenuList = removeMenuList || [];
    let countErr = 0;


    if(temp.module_name.trim() == ''){
      countErr++;
      setErrorMessage('Please Enter Your Module Name !!!')
    }


   

  for( var i=0; i<temp.menuList.length; i++ ) {
     if(temp.menuList[i].errorMessage  || temp.menuList[i].errorMessageDate){
      countErr++;
     }

     if(temp.menuList[i].menu_name.trim() == ''){
      countErr++;
      temp.menuList[i].errorMessage = 'Please Enter Your Menu Name !!!';
    }
    }

    setModuleDetail({...ModuleDetail,menuList:temp.menuList});
    ModuleDetail.menuList = temp.menuList;
   if(countErr == 0){
      //console.log("HERERE")
      axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/moduleMenu/saveConfigModule`,
        headers: {
           //Authorization: getToken(),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
        data: temp
  
      })
        .then( function (response) {
          if(response.data){
            setErrorMessage('');
            setModuleDetail({});

            toggle_modal_stakeholder();
            getProjectDetail(project_id)
          }
  
      
  
        })
        .catch(function (error) {
          console.log(error);
          setErrorMessage(error.response.data.error[0].errorDis)
       
        });

    }
  } */

 

  useEffect(()=>{
     setErrorMessage('');
      
        /*     console.log('ModuleDetails',ModuleDetails)
            const temp =ModuleDetails || {}
            setModuleDetail(temp); */
      if(isOpen == true) {
        getUserInProject(project_id);
        let tempTag = [];
        for(let item of Tags){
          let temp = {
            tag_id:generateUUID(),
            tag:item,
            isEdit:false,
          }
          tempTag.push(temp);
        }
        if(QDC){
          setQdcDetail({...qdcDetail,quality:QDC.quality,delivery:QDC.delivery,cost:QDC.cost});
        }

        setStakeholderList(tempTag)
      }
      
         
  },[isOpen])


  const [userInProjectOption,setUserInProjectOption] = useState([]);

  function getUserInProject(project_id_){
    axios({
      method: 'GET',
      url: `${Configs.TTT_Game_Service}/api/userprofile/getUserInProject/${project_id_}`,
      headers: {
         //Authorization: getToken(),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },


    })
      .then( function (response) {
        

        if(response.data){
          let option = [];
          for(let item of response.data){
            let temp = {
              label:item.userTTT.usr_name + ' ' + item.userTTT.usr_lastname,
              value:item.userTTT.usr_name + ' ' + item.userTTT.usr_lastname
            }
            option.push(temp)
          }

          setUserInProjectOption(option);
         
        }
    

      })
      .catch(function (error) {
        console.log(error);
        setErrorMessage(error.response.data.error[0].errorDis)
     
      });
  }




  function addMenu(){
    let tempData = ModuleDetail.menuList || [];
    let temp = {
      
        menu_id: generateUUID(),
        menu_module_id: null,
        menu_name: "",
        menu_created_by: null,
        menu_updated_by: null,
        menu_created_name: null,
        menu_updated_name: null,
        menu_sort:tempData.length +1,
        menu_start_date: null,
        menu_end_date: null,
        menu_actual_date: null,
        menu_remark: null,
        is_new:true
    
    }
    tempData.push(temp)

    setModuleDetail({...ModuleDetail,menuList:tempData});
    ModuleDetail.menuList = tempData;
  }

/*   useEffect(()=>{


 },[]) */
 const [textChange,setTextChange] = useState({textvalue :''});
  /* function changeMenuValue(key,value,menu_id){
    let tempData = ModuleDetail;
    let index = tempData.menuList.findIndex((e)=>e.menu_id === menu_id);
    if(index > -1){
      if(key == 'menu_start_stop_date'){
        console.log('HERE')
        let start = parseInt(moment(tempData.module_start_date).format("YYYYMMDD") || 0 );
        let end = parseInt(moment(tempData.module_end_date).format("YYYYMMDD") || 0);
      setTextChange({...textChange,textvalue:new Date(value[0])});
      textChange.textvalue = new Date(value[0]);
      tempData.menuList[index]['menu_start_date'] = textChange.textvalue;
      setTextChange({...textChange,textvalue:new Date(value[1])});
      textChange.textvalue = new Date(value[1]);
      tempData.menuList[index]['menu_end_date'] = textChange.textvalue;

      for( var i=0; i<tempData.menuList.length; i++ ) {
        console.log((parseInt(moment(tempData.menuList[i].menu_start_date).format('YYYYMMDD') || 0), start))
      if(parseInt(moment(tempData.menuList[i].menu_start_date).format('YYYYMMDD') || 0)   < start ||  parseInt(moment(tempData.menuList[i].menu_end_date).format('YYYYMMDD') || 0) > end){
        tempData.menuList[i].errorMessageDate = 'Date Must In Range Module Period !!';
        console.log("error DAte")
      }else{
        tempData.menuList[i].errorMessageDate = '';
      }
    }
  
      }else{

      setTextChange({...textChange,textvalue:value});
      textChange.textvalue = value;
      tempData.menuList[index][key] = textChange.textvalue;





        
        let b = [];
        let c = [];
        for( var i=0; i<tempData.menuList.length; i++ ) {
        
          if ( b.indexOf((tempData.menuList[i].menu_name).toString().toLowerCase().trim() ) < 0 ) {
              b.push( tempData.menuList[i].menu_name.toString().toLowerCase().trim() );
          } else {
              c.push( tempData.menuList[i].menu_name.toString().toLowerCase().trim() );
          }
  
        
      }

      if(c.length > 0) {
        for( let name of c){
          let check = tempData.menuList.filter((e)=>{return (e.menu_name).toString().toLowerCase().trim() == name.toString().toLowerCase()});
          for(let item of check){
            let index1 = tempData.menuList.findIndex((e)=>e.menu_id == item.menu_id);
            if(index1 > -1){
              tempData.menuList[index1].errorMessage = "Menu Name has Aleady !!"
            }
          }
         
          let indexB = b.findIndex((bb)=>bb.toString().toLowerCase() == name.toString().toLowerCase());
          if(indexB > -1){
            b.splice(indexB,1);
          }
        }

      }

      if(b.length > 0) {
        for( let name of b){
          let check = tempData.menuList.filter((e)=>{return (e.menu_name).toString().toLowerCase().trim() == name.toString().toLowerCase()});
          for(let item of check){
            let index1 = tempData.menuList.findIndex((e)=>e.menu_id == item.menu_id);
            if(index1 > -1){
              tempData.menuList[index1].errorMessage = ""
            }
          }
         
  
        }



      }

      //console.log(b,c)



    }
    setModuleDetail(tempData);
    console.log("ADADASDS")
    }
  }



  function sortUpMenu(index){
    let tempData = ModuleDetail;
    tempData.menuList[index].menu_sort = parseInt(tempData.menuList[index].menu_sort || 1) - 1
    tempData.menuList[index-1].menu_sort = parseInt(tempData.menuList[index].menu_sort || 1) + 1
    let temp_sort = tempData.menuList.sort((a,b)=>a.menu_sort - b.menu_sort)
  
    setModuleDetail({...ModuleDetail,menuList:temp_sort});
    ModuleDetail.menuList = temp_sort || [];
    console.log(temp_sort)
  }

  function sortDownMenu(index){
    let tempData = ModuleDetail;
    tempData.menuList[index].menu_sort = parseInt(tempData.menuList[index].menu_sort || 1) + 1
    tempData.menuList[index+1].menu_sort = parseInt(tempData.menuList[index].menu_sort || 1) - 1
    let temp_sort = tempData.menuList.sort((a,b)=>a.menu_sort - b.menu_sort)
  
    setModuleDetail({...ModuleDetail,menuList:temp_sort});
    ModuleDetail.menuList = temp_sort || [];
    console.log(temp_sort)
  }




const [removeMenuList,setRemoveMenuList] = useState([]);
function removeMenu(index){
  if(index > -1){
    let tempMenu = ModuleDetail.menuList || [];
    let tempRm = removeMenuList || [];
    tempRm.push(tempMenu[index]);
    tempMenu.splice(index,1);

    let count = 1;
    for(let  item of tempMenu){
      item.menu_sort = count;
      count++;
    }
    setModuleDetail({...ModuleDetail,menuList:tempMenu});
    ModuleDetail.menuList = tempMenu;
    setRemoveMenuList(tempRm);
  }
 
  console.log('index',index)


  toggle_modal_confirm();
}  */
  async function GetAccesstokenToken(callback) {
    await axios({
        method: "post",
        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
        headers: {
            /* Authorization: getToken(), */
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
        },
    })
        .then(async function (response) {
            var temp = {
                tokenBox: response.data.tokenBox
            }
            if (callback) {
                callback(response.data.tokenBox);
            }
            sessionStorage.setItem('tokenBox', response.data.tokenBox)
            /*  console.log('ViewToken', response.data); */
        })
        .catch(function (error) {
            console.log(error);
        });

}

const [stakeholderIndex,setStakeholderIndex] = useState(null);
const [stakeholderList,setStakeholderList] = useState([]);
const [qdcDetail,setQdcDetail] = useState({
  quality:'',
  delivery:'',
  cost:'',
});
const [test,settest] = useState({test:''});
const [tagStakeholder,setTagStakeholder] = useState({
  tag:''
});
function addStakeholder(value){
  console.log('INN',value)
  let temp = stakeholderList || []; //
  let check = temp.filter((e)=> {return e.tag.toString().trim().toLowerCase() ===  value.toString().trim().toLowerCase()});
  if(check.length > 0){
    // ซ้ำ
   /*  setErrorMessage('Tag has already !!'); */
   setTagStakeholder({...tagStakeholder,tag:''});
   tagStakeholder.tag = '';
  }else{
    //ไม่ซ้ำ
    
    temp.push({tag_id:generateUUID(),tag:(value || '').toString().trim()})
    setTagStakeholder({...tagStakeholder,tag:''});
    tagStakeholder.tag = '';
  }

  setStakeholderList(temp);

  settest({...test,test:''});
  test.test = '';
  
}

function removeStakeholder(){
  if(stakeholderIndex > -1){
    let temp = stakeholderList || []; //
    temp.splice(stakeholderIndex,1);
    let b = [];
    let c = [];
    for( var i=0; i<temp.length; i++ ) {
    
      if ( b.indexOf((temp[i].tag).toString().toLowerCase().trim() ) < 0 ) {
          b.push( temp[i].tag.toString().toLowerCase().trim() );
      } else {
          c.push( temp[i].tag.toString().toLowerCase().trim() );
      }

    
  }

  console.log(b,c)

  if(c.length > 0) {
    for( let name of c){
      let check = temp.filter((e)=>{return (e.tag).toString().toLowerCase().trim() == name.toString().toLowerCase()});
      for(let item of check){
        let index1 = temp.findIndex((e)=>e.tag == item.tag);
        if(index1 > -1){
          temp[index1].errorMessage = "Tag has Aleady !!"
        }
      }
     
      let indexB = b.findIndex((bb)=>bb.toString().toLowerCase() == name.toString().toLowerCase());
      if(indexB > -1){
        b.splice(indexB,1);
      }
    }

  }

  if(b.length > 0) {
    for( let name of b){
      let check = temp.filter((e)=>{return (e.tag).toString().toLowerCase().trim() == name.toString().toLowerCase()});
      for(let item of check){
        let index1 = temp.findIndex((e)=>e.tag == item.tag);
        if(index1 > -1){
          temp[index1].errorMessage = ""
        }
      }
     

    }


   /*  setStakeholderList(temp); */
  }

    setStakeholderList(temp);
    settest({...test,test:''});
    test.test = '';
  }
 
  console.log('index',stakeholderIndex)


  toggle_modal_confirm();
} 


function setIsEdit(index){
  let temp = stakeholderList || [];
  if(index > -1){
    let i = 0;
     for(let item of temp){
      if(i !== index){
        item.isEdit = false;
   
      } 
      
      i++;
     }
    temp[index].isEdit = !temp[index].isEdit;
    for(let item of temp){
  
        item.tag = item.tag.trim();
   
      }
      
   
     
    setStakeholderList(temp)
    settest({...test,test:''});
    test.test = '';

   
  }

}


function changeTag(index,key,value){
  let temp = stakeholderList || [];
  if(index > -1){
    setTextChange({...textChange,textvalue:value});
    textChange.textvalue = value;
    temp[index][key] = textChange.textvalue;
 
   
    let b = [];
    let c = [];
    for( var i=0; i<temp.length; i++ ) {
      
      if ( b.indexOf((temp[i].tag).toString().toLowerCase().trim()) < 0 ) {
          b.push(temp[i].tag.toString().toLowerCase().trim());
      } else {
          c.push(temp[i].tag.toString().toLowerCase().trim());
      }

    
  }

  console.log(b,c)

  if(c.length > 0) {
    for( let name of c){
      let check = temp.filter((e)=>{return (e.tag).toString().toLowerCase().trim() == name.toString().toLowerCase()});
      console.log(check)
      for(let item of check){
        let index1 = temp.findIndex((e)=>e.tag_id == item.tag_id);
        console.log(index1)
        if(index1 > -1){
          temp[index1].errorMessage = "Tag has Aleady !!"
        }
      }
     
      let indexB = b.findIndex((bb)=>bb.toString().toLowerCase() == name.toString().toLowerCase());
      if(indexB > -1){
        b.splice(indexB,1);
      }
    }

  }

  if(b.length > 0) {
    for( let name of b){
      let check = temp.filter((e)=>{return (e.tag).toString().toLowerCase().trim() == name.toString().toLowerCase()});
      for(let item of check){
        let index1 = temp.findIndex((e)=>e.tag_id == item.tag_id);
        if(index1 > -1){
          temp[index1].errorMessage = ""
        }
      }
     

    }


   
  }
  if(textChange.textvalue.trim() == ''){
    temp[index].errorMessage = "Tag has empty !!";
  }
  setStakeholderList(temp);
    settest({...test,test:''});
    test.test = '';
    
  }
  /* setTextChange({...textChange,textvalue:value});
  textChange.textvalue = value;
  tempData.menuList[index][key] = textChange.textvalue; */
}



async function addConfirmData(/* pathFile, */ref_id){
  console.log(/* pathFile, */ref_id);
  let  temp = {
    name:addConfirm.name.trim(),
    email:addConfirm.email.trim(),
    cc:addConfirm.cc,
    menu_id:menu_id,
    module_id:module_id,
    project_id:project_id,
  /*   path_confirm:pathFile, */
    ref_id:ref_id
  }


 await axios({
    method: "POST",
    url: `${Configs.TTT_Game_Service}/api/project/addConfirmReq`,
    headers: {
      Authorization: sessionStorage.getItem('access_token'),
      'X-TTT': `${Configs.API_TTT}`,
      'Content-Type': 'application/json',
    },
    data: temp,
  })
    .then(async function (response) {
      setErrorMessageload({ ...errorMessageload, message: 'Success', status: false });
      toggle_add_confirm();
      getConfirmList();

    }).catch(async function (err){

    })

 
}


const [confirmList,setConfirmList] = useState([]);


useEffect(()=>{
  if(isOpen == true){
    GetAccesstokenToken();
    getConfirmList();
  }

},[isOpen]);

function getConfirmList(){
  axios({
    method: "GET",
    url: `${Configs.TTT_Game_Service}/api/project/getConfirmReqByMenu/${menu_id}`,
    headers: {
      Authorization: sessionStorage.getItem('access_token'),
      'X-TTT': `${Configs.API_TTT}`,
      'Content-Type': 'application/json',
    },
   
  })
    .then(async function (response) {
      setConfirmList(response.data);
      if (response.data) {
        for (let item of response.data) {
          if (item.fd_img_id) {
            let response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
              },
              responseType: 'blob',
            });

            item.cre_path_upload = URL.createObjectURL(response2.data);

            setConfirmList(response.data);
            settest({...test, test: ''});
            test.test = '';
          }
        }
      }
    }).catch(async function (err) {

    })
}

async function getSRSConfirmreport() {

  await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/SRSConfirmreport`,
      headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data:{
         /*  user_id:sessionStorage.getItem('user_id'),
          startdate: new Date(),
          enddate: new Date(), */
          project_id: project_id,
          menu_id: menu_id
      }
  })
      .then(async function (response) {
       

         SRSConfirmreport(response.data,null,'version',async (result) => {
 

          /* const response2 = await axios.get(`https://api.box.com/2.0/files/${result.pathimg}/content`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
        }); */
       
          addConfirmData(/* response2.request.responseURL, */result.ref_id);
       
          
      });
       /*  let resPath = await SRSConfirmreport(response.data,null,'version');
        console.log('resPath',resPath); */
       /*  addConfirmData(data[0]path); */
     
      })
      .catch(function (error) {
          console.log(error);
      });
};


async function updateConfirm(){
  setLoading(true);
  let temp = {
    id:editConfirm.id,
    description:editConfirm.description.trim(),
    // file_upload_path:editConfirm.file_upload_path || '',
    file_upload_name:editConfirm.file_upload_name || '',
    is_new:false
  }

  let checknew = fileUpload.filter((e) => { return e.isNew == true })

  if (checknew.length > 0) {
    temp.is_new = true;
  }
  if (checknew.length > 0 || fileUpload.length < 1) {  
    temp.file_upload_name = fileUpload[0]?.edit_ul_file_name;
    
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/updateConfirm`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp,
    })
      .then(async function (response) {
        CheckAndCreateFolderBox(project_id, response.data);
      }).catch((err) => {
  
      })
  } else {
    setLoading(false)
  }


  // if(checknew.length > 0){
    
      // const dataFile = new FormData();
  // for (let i = 0; i < fileUpload.length; i++) {
  //   if(fileUpload[i].isNew == true){
  //     dataFile.append('Profile', fileUpload[i].file);
  //   }
    
  // }
  // dataFile.append('typeFile', "Attachment");
  // dataFile.append('projectKey', "confirmSRS");

  //   await axios({
  //     method: 'post',
  //     url: `${Configs.TTT_Game_Service}/api/upload/document2`,
  //     headers: {
  //         Authorization: sessionStorage.getItem('access_token'),
  //         'X-TTT': `${Configs.API_TTT}`,
  //         'Content-Type': 'application/octet-stream',
  //     },
  //     data: dataFile,
  // })
  //     .then(async function (response) {
  //         if (response.data.status == true) {
  //           temp.file_upload_path= response.data.data[0].path;
  //           temp.file_upload_name= response.data.data[0].orgin_name;
  //           temp.is_new = true;
  //         }
  //       }).catch((err)=>{

  //       })
  // }
  

      //   await axios({
      //     method: 'post',
      //     url: `${Configs.TTT_Game_Service}/api/project/updateConfirm`,
      //     headers: {
      //         Authorization: sessionStorage.getItem('access_token'),
      //         'X-TTT': `${Configs.API_TTT}`,
      //         'Content-Type': 'application/json',
      //     },
      //     data: temp,
      // })
      //     .then(async function (response) {
      //         toggle_edit_confirm();
      //         getConfirmList();
      //       }).catch((err)=>{
    
      //       })
}

async function getOtherFilesByMenuId(callback) {
  await axios({
    method: 'get',
    url: `${Configs.TTT_Game_Service}/api/project/getOtherFilesByMenuId/${menu_id}`,
    headers: {
      Authorization: sessionStorage.getItem('access_token'),
      'X-TTT': `${Configs.API_TTT}`,
    }
  })
    .then(async function (response) {
      if (response.data) {
        setOtherFileList(response.data);
        callback();
      }
    })
    .catch(function (error) {
      // Swal.fire("Error", error.response.data.error[0].errorDis, "error");
      console.log(error);
    });
}

async function CheckAndCreateFolderBox(project_id, ref_id) {
  let temp = {
    folderLis: ["PROJECT", "PROJECT_" + project_id, 'PROJECT DOCUMENTS', 'CONFIRM REQUIREMENT MINI']
  }
  await axios({
    method: 'post',
    url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
    headers: {
      Authorization: sessionStorage.getItem('access_token'),
      'X-TTT': `${Configs.API_TTT}`,
    },
    data: temp
  })
    .then(async function (response) {
      if (response.data) {
        getOtherFilesByMenuId(() => {
            UploadFiles(ref_id, response.data.folder_id);
        });

      }
    })
    .catch(function (error) {
      // Swal.fire("Error", error.response.data.error[0].errorDis, "error");
      console.log(error);
    });
}

  async function UploadFiles(id, folder_id) {
    let checknew = fileUpload.filter((e) => { return e.isNew == true })
    let oldFileList_ = oldFileList.filter((e) => { return e != null });
    let setOldFile = [...oldFileList_, ...otherFileList];
    const dataImg = new FormData();

    if (checknew.length > 0 || fileUpload.length < 1) {
      for (let item of fileUpload) {
        dataImg.append('File', item.file);
      }
      dataImg.append('folder_id', folder_id);
      dataImg.append('key', 'ATTACHMENT');
      dataImg.append('image_old_list', JSON.stringify(setOldFile));
      dataImg.append('ref_id', id);

      await axios({
        method: 'post',
        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'multipart/form-data'
        },
        data: dataImg
      })
        .then(async function (response) {
          if (response.data) {
            // setErrorMessage({ ...errorMessage, message: 'Success', status: false });
            // toggle_modal_alertKick();

            // temp.file_upload_path= URL.createObjectURL(response2.data);
            toggle_edit_confirm();
            getConfirmList();
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    } else {
      setLoading(false);
    }


  };

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };


  const handleTagClose = (removedTag) => {
    setAddConfirm(prevState => ({
      ...prevState,
      cc: prevState.cc.filter(tag => tag !== removedTag)
    }));
  };
return (

    <>
    
{/*     {isOpen && (
    <> */}
     <ConfigProvider
            theme={{
              token: {
              
            /*     colorPrimary: '#85BFFF', */ 
                colorInfo: '#85BFFF',
                colorBorder: '#85BFFF',
                colorText: '#FFF',
                
                colorTextDescription:'#ffff',
                colorBgContainer: '#000',
            
                zIndexPopupBase:999999,
                colorPrimaryBg:'blue',//สีตอน Active ใน Select
                colorBgBase:'#000',//พื้นหลัง select ตอนกด down ลงมา
        
                colorTextQuaternary:'#FFFFFF',// สีตัวหนังสือของ placeholder

              
                colorFillTertiary:'blue',// สีตอน hover ใน Select
                

                colorTextBase:'blue', // ลูกศรใน datepicker & rangepicker
               


             
                colorPrimaryBg:'#85BFFF',
         /*       
                colorBgLayout:'red',
                colorFillQuaternary:'red', */
               
                
              }
            }}
          >
         <div className={`backdropModuleMenu ${isOpen ? 'opened' : 'closed'}`} onClick={()=>{toggle_modal_stakeholder();}}>      </div>  
    <div className={`detailBoxModuleMenu ${isOpen ? 'opened':'closed'}`}   style= {{/* top:`10%`, */
/*     left:`50%`,
    position:'fixed',
    transform: 'translate(-50%, -22%)',
    width:'80%',
    height:'80%' */
    left: `${switModeShowDisplay === true ? '0%' : '50%'}`,
    position: `${switModeShowDisplay === true ? 'absolute':'fixed'}`, 
    transform: `${switModeShowDisplay === true ? '':'translate(-50%, -14%)'}`,
    width: `${switModeShowDisplay === true ? '100%':'80%'}` , 
    height: `${switModeShowDisplay === true ? '83vh':'80%'}`,
    top: `${switModeShowDisplay === true ? '4%':''}`,
  }}
        
  
    >
     

        
         <div className = 'stakeholderHeader'>
              <div className='fontHeader'>STAKEHOLDER</div>
              <div style={{display:'flex',gap:'20px'}}>
              <div style={{display:'flex',flexDirection:'column',gap:'10px',width:'30%'}}>

              <div style={{display:'flex'}}>
                <i class="fa fa-plus mr-1 mb-1" style= {{border:'2px solid #fff',padding:'3px',borderRadius:'5px',fontSize:'10px'}} aria-hidden="true"></i>
            NEW TAG<font color ="red">*</font> <font className='ml-4' color ="red">{errorMessage}</font>
    
            

</div>

<Input style= {{backgroundColor:'#000'}} type ='text' placeholder='PLEASE INPUT YOUR TAG...'
              value = { tagStakeholder.tag || ''}
               onChange={(e)=>{
                setErrorMessage('');
             
                
                
              setTagStakeholder({...tagStakeholder,tag:e.target.value});
           /*      setModuleDetail({...ModuleDetail,module_name:e.target.value}) */
               }}
               onKeyDown={(e) => {

                if (e.keyCode === 13 && tagStakeholder.tag.trim() !== '') {
                 /*  addStakeholder(tagStakeholder.tag); */
                 let cuttext = tagStakeholder.tag.split(',')
                 for (let i of cuttext) {
                  if (i.trim() !== '') {
                    addStakeholder(i);
                  }
                 
                  
                 }
                }
              }}
              
              />


</div>



<div style={{display:'flex',flexDirection:'column',gap:'10px',width:'15%'}}>
SEARCH
            
            <Select
         
            showSearch
   
            style={{
              width: '100%',
              fontWeight:'normal'
              }}
            placeholder="SEARCH TAG..."
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().trim().includes((input ?? '').toLowerCase().trim())}
            filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            value ={userInProjectOption.filter((e)=>{return e.value === tagStakeholder.tag})} 
            options={userInProjectOption}
            onChange={(value)=>{
              setTagStakeholder({...tagStakeholder,tag:value});
              addStakeholder(value)
              
            }}
  />
  </div>

  </div>
       
              <div style= {{borderBottom:'2px solid #5E9BE1'}}/>
         </div>


         <div className='stakeholderBody'>
          <div className='stakePerson'>
          <table style={{border:'none'}}>

            <tr style={{position:'sticky',top:0, background:'linear-gradient(to right, rgba(0, 40, 156, 1) 0%, rgba(0, 71, 255, 1) 50%, rgba(0, 40, 156, 1) 100%)',border:'none'}}>
              <td style={{fontSize:'16px',height:'50px',textAlign:'center',width:'15%',border:'none'}}>ลำดับ</td>
              <td style={{fontSize:'16px',height:'50px',border:'none'}}>ชื่อ</td>
              <td style={{fontSize:'16px',height:'50px',textAlign:'center',width:'15%',border:'none'}}>แก้ไข</td>
              <td style={{fontSize:'16px',height:'50px',textAlign:'center',width:'15%',border:'none'}}>ลบ</td>
            </tr>
      
    
            {stakeholderList.map((e,index)=>{
              return (<>
                  <tr style={{borderBottom:'2px solid #5E9BE1'}}>
              <td style={{fontSize:'16px',height:'50px',textAlign:'center'}}>{index+1}</td>
              <td style={{fontSize:'16px',height:'50px',textTransform: 'none'}}>{e.isEdit ? (<>
                  {e.errorMessage && e.errorMessage !== '' ? (<><font size = {1} color = "red">{e.errorMessage}</font></>):(<></>)}
             
                <Input id = {index} style= {{backgroundColor:'#000'}} type ='text' placeholder='PLEASE INPUT YOUR TAG...'
              value = { e.tag || ''}
               onChange={(e)=>{
                
                changeTag(index,'tag',e.target.value)
                
                  
           
               }}
               onKeyDown={(e) => {
          
                if (e.keyCode === 13) {
                  setIsEdit(index)
                }
              }}
        
              
              />
               </>) : (<>{e.errorMessage && e.errorMessage !== '' ? (<><font color = "red" size ={1}>{e.errorMessage}</font><br/></>) : (<></>)}{e.tag}</>)}</td>
              <td style={{fontSize:'16px',height:'50px',textAlign:'center'}}><i class="far fa-edit" style= {{cursor:'pointer'}} onClick={()=>{setIsEdit(index);  }}></i></td>
              <td style={{fontSize:'16px',height:'50px',textAlign:'center'}}><i class="far fa-trash-alt" style= {{cursor:'pointer'}} onClick = {()=>{toggle_modal_confirm();setStakeholderIndex(index)}} aria-hidden="true"></i></td>
            </tr>
              </>)
            })}
      
          </table>
          </div>
            
          <div className='stakeQDC'>
          Benefit Guideline QDC
          <div className='stakeQDC_'>
                  <div style={{display:'flex'}}><div style={{width:'200px'}}>Quality (Q) :  </div> <TextArea value = {qdcDetail.quality}
                  onChange={(e)=>{
                    setQdcDetail({...qdcDetail,quality:e.target.value});
                  }}
                  autoSize /></div>
                  <div style={{display:'flex'}}><div style={{width:'200px'}}>Delivery (D) :  </div> <TextArea value = {qdcDetail.delivery}
                  onChange={(e)=>{
                    setQdcDetail({...qdcDetail,delivery:e.target.value});
                  }} autoSize /></div>
                  <div style={{display:'flex'}}><div style={{width:'200px'}}>Cost (C) :  </div> <TextArea value = {qdcDetail.cost}
                  onChange={(e)=>{
                    setQdcDetail({...qdcDetail,cost:e.target.value});
                  }} autoSize /></div>
                  </div> 
          </div>  
          <br/>
         </div>


         <div className='stakeConfirm'>
          <div className='' style={{display:'flex',gap:'15px',alignItems:'center'}}>

          Partial Confirm Requirement (history) <div className='btnAddConfirm'
          onClick = {()=>{
        
            setAddConfirm({...addConfirm,name:'',email:'',cc:[]});
            setInputValue('')
            toggle_add_confirm();
          /*   console.log('isopneAdd',isOpenAddConfirm) */
          }}
          > <i class="fa fa-plus mr-1 mb-1" style= {{border:'2px solid #fff',padding:'3px',borderRadius:'5px',fontSize:'10px'}} aria-hidden="true"></i>Add confirm</div>
          </div>
        
         
         <div className='stakeConfirm_'>
         <table>
                <tr style={{height:'30px',position:'sticky',top:0,background:'linear-gradient(to right, rgba(0, 40, 156, 1) 0%, rgba(0, 71, 255, 1) 50%, rgba(0, 40, 156, 1) 100%)',height:'50px'}}>
                  <td style={{textAlign:'center',fontSize:'14px'}}>No.</td>
                  <td style={{textAlign:'center',fontSize:'14px'}}>Name</td>
                  <td style={{textAlign:'center',fontSize:'14px'}}>Email</td>
                  <td style={{textAlign:'center',fontSize:'14px'}}>Date sent</td>
                  <td style={{textAlign:'center',fontSize:'14px'}}>Status</td>
                  <td style={{textAlign:'center',fontSize:'14px'}}>Date return</td>
                  <td style={{textAlign:'center',fontSize:'14px'}}>Attachment</td>
                  <td style={{textAlign:'center',fontSize:'14px'}}>Mgt</td>
                </tr>
                {confirmList.map((e,index)=>{
                  let statsu_color = 'white';
                  let color = 'rgba(0, 0, 0, 0.1)';
                  if(index % 2 == 0){
                    color = 'rgba(133, 191, 255, 0.1)';
                  }
                  if(e.cs_id == '5009926c-ea0d-4801-9fb3-5501812ff00e'){
                    // FIX แดง
                    statsu_color  = 'red';
                  }
                  if(e.cs_id == '8ede22cf-62a1-4e35-ae1a-9b8ec7e98ca7'){
                    // Confirm เขียว
                    statsu_color = 'green';
                  }

                  return (<>
                  <tr style={{height:'30px',backgroundColor:`${color}`}}>
                  <td style={{textAlign:'center',fontSize:'14px'}}>{index+1}</td>
                  <td style={{textAlign:'center',fontSize:'14px',textTransform: 'none'}}>{e.cre_name}</td>
                  <td style={{textAlign:'center',fontSize:'14px',textTransform: 'none'}}>{e.cre_email}</td>
                  <td style={{textAlign:'center',fontSize:'14px'}}>{moment(e.cre_date_sent).format('DD/MM/YYYY HH:mm น.')}</td>
                  <td style={{textAlign:'center',fontSize:'14px',textTransform: 'none',color:statsu_color}}>{e.cs_name}</td>
                  <td style={{textAlign:'center',fontSize:'14px'}}>{e.cre_date_return ? moment(e.cre_date_return).format('DD/MM/YYYY HH:mm น.') : '-'}</td>
                  <td style={{textAlign:'center',fontSize:'14px'}}>{e.cre_path_upload ? <a href = {`${e.cre_path_upload}`} target='_blank'>{e.cre_name_upload}</a> : '-'}</td>
                  <td style={{textAlign:'center',fontSize:'14px'}}><EditOutlined style={{cursor:'pointer'}} onClick={()=>{
                    setFileUpload([]);
                    let temp = [];
                    if(e.cre_path_upload){
                      temp.push({
                        type: 'IMAGE',
                        file_name: e.cre_name_upload,
                        file: null,
                        path: e.cre_path_upload,
                        url: e.cre_path_upload,
                        edit_ul_file_name: e.cre_name_upload,
                        edit_ul_file_path: e.cre_path_upload,
                        isNew: false
                      })
                    }
                    setFileUpload(temp);
                    setEditConfirm({...editConfirm,description:e.cre_remark || '',id:e.cre_id
                    ,file_upload_name:e.cre_name_upload,file_upload_path:e.cre_path_upload

                    });

                    toggle_edit_confirm();
                  }}/></td>
                </tr>
                  </>)
                })}
            


              </table>
              </div>
         </div>


         <div className='stakeFooter'>
         <button className=' btn-primary mr-2' style ={{padding:'5px',fontSize:'20px',height:'45px'}} onClick={()=>{
              let checkError = stakeholderList.filter((e)=>{return e.errorMessage && e.errorMessage.trim() !== ''});
              if(checkError.length == 0 ){
                updateStakeholder(stakeholderList,qdcDetail);
              }
     
              
              }}> SAVE </button> <button className=' btn-danger' style ={{padding:'5px',fontSize:'20px',height:'45px'}} onClick={()=>{toggle_modal_stakeholder();}}> CANCEL </button>
         </div>



         <div className={`boxAddConfirmBackDrop ${isOpenAddConfirm ? 'opened' : 'closed'}`}  onClick={()=>{toggle_add_confirm();}}></div>
         <div className={`boxAddConfirm ${isOpenAddConfirm ? 'opened' : 'closed'}`}>
          <div className='boxAddConfirmBody'>
          <div className='fontHeader'>ADD CONFIRM</div>
            <div>Name<font color = 'red'> *</font></div>
            <Input value = {addConfirm.name} onChange={(e)=>{setAddConfirm({...addConfirm,name:e.target.value})}}/>
            <div>Email<font color = 'red'> *</font></div>
            <Input value={addConfirm.email} onChange={(e)=>{setAddConfirm({...addConfirm,email:e.target.value})}}/>
            <div>Carbon Copy (Cc)</div>
            <div>
      <Input
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && inputValue) {
            if (!addConfirm.cc.includes(inputValue)) {
              setAddConfirm(prevState => ({
                ...prevState,
                cc: [...prevState.cc, inputValue]
              }));
            }
            setInputValue('');
          }
        }}
        onBlur={(e) => {
          if (inputValue.trim()) { 
            if (!addConfirm.cc.includes(inputValue)) {
              setAddConfirm(prevState => ({
                ...prevState,
                cc: [...prevState.cc, inputValue]
              }));
            }
            setInputValue('');
          }
        }} 
        placeholder="Type and press enter"
      />
     <div style={{ marginTop: 10 }}>
        {addConfirm.cc.map(tag => (
          <Tooltip key={tag} title={tag}>
            <Tag
              closable
              onClose={() => handleTagClose(tag)}
            >
              {tag}
            </Tag>
          </Tooltip>
        ))}
      </div>
    </div>
          </div>

          
          <div className='boxAddConfirmFooter'>
          <button className=' btn-outline-primary mr-2' style ={{padding:'5px',fontSize:'16px',height:'40px',cursor:'pointer'}} 
          onClick={()=>   {
            
            if(addConfirm.name.trim() != '' && addConfirm.email.trim() != ''){
              setErrorMessageload({ ...errorMessageload, message: '', status: true });
              toggle_modal_alertKick();
              GetAccesstokenToken((result) => {
                if (result) {
                  setInputValue('')
              getSRSConfirmreport();
            }
          })

            }
          }}
          > Save </button> <button className=' btn-outline-danger' style ={{padding:'5px',fontSize:'16px',height:'40px',cursor:'pointer'}} 
          onClick={()=>{toggle_add_confirm();}}
          > Cancel </button>
          </div>
    
         </div>

         <div className={`boxAddConfirmBackDrop ${isOpenEditConfirm ? 'opened' : 'closed'}`}  onClick={()=>{toggle_edit_confirm();}}></div>
         <div className={`boxAddConfirm ${isOpenEditConfirm ? 'opened' : 'closed'}`}>
          <div className='boxAddConfirmBody' style={{maxHeight:'500px'}}>
          <div className='fontHeader'>EDIT CONFIRM</div>
          <div style={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid rgba(133, 191, 255, 1)',paddingBottom:'5px'}}>
          <div>ATTACHMENT</div>
          <label className='btnAddConfirm'
          style={{margin:0,color:'#fff'}}
          for="uploadConfirm"
          onClick = {()=>{
      /*       toggle_add_confirm(); */
          /*   console.log('isopneAdd',isOpenAddConfirm) */
          }}
          > <i class="fa fa-plus mr-1 mb-1" style= {{border:'2px solid #fff',padding:'3px',borderRadius:'5px',fontSize:'10px'}} aria-hidden="true"></i>UPLOAD</label>
      
      <input
                            id='uploadConfirm'
                            style={{ display: 'none' }}
                            type='file'
                            accept={'image/*'}
                       /*      multiple */
                            onChange={(e) => {

                                if (e.target.files.length > 0) {
                            
                                   let temp = [];
                                    for (let item of e.target.files) {
                                        let file = {
                                         
                                            type: 'IMAGE',
                                            file_name: item.name,
                                            file: item,
                                            path: URL.createObjectURL(item),
                                            url: URL.createObjectURL(item),
                                            edit_ul_file_name: item.name,
                                            edit_ul_file_path: URL.createObjectURL(item),
                                            isNew: true
                                        }
                                        temp.push(file);
                                  
                                    }
                                    setFileUpload(temp);
                                    
                                    settest({ ...test, test: '' });
                                    test.test = '';
                                }
                            }}
                        />
          </div>
      
          {fileUpload.map((e,index) =>{
            return (<>
                 <div style= {{display:'flex',justifyContent:'space-between'}}>
                  <div>{e.file_name}</div>
                  <div style={{cursor:'pointer'}}
                  onClick={()=>{
                    setFileUpload([]);
                    setEditConfirm({...editConfirm,file_upload_name:'',file_upload_path:''
  
                      });
                  }}
                  >X</div>
                 </div>
              </>)
          })
            
          }
         

       
            <div>DESCRIPTION{/* <font color = 'red'> *</font> */}</div>
            <TextArea value = {editConfirm.description}
                  onChange={(e)=>{
                    setEditConfirm({...editConfirm,description:e.target.value});
                  }}
                  
                  autoSize />
          </div>

          <div className='boxAddConfirmFooter'>
          <button className=' btn-outline-primary mr-2' style ={{padding:'5px',fontSize:'16px',height:'40px',cursor:'pointer'}} 
          onClick={()=>   {updateConfirm();}}
          > Save </button> <button className=' btn-outline-danger' style ={{padding:'5px',fontSize:'16px',height:'40px',cursor:'pointer'}} 
          onClick={()=>{toggle_edit_confirm();}}
          > Cancel </button>
          </div>
    
         </div>

       
    </div>


     
    </ConfigProvider>
              
     
    <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999 }}>
        <div className='alertKickBoxShadow' >
          <div className='alertKickBox' style={{ height: 'fit-content' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `${errorMessageload.status ? 'ffffff' : 'green'}` }}>
                  {errorMessageload.status ? 'Loading' : 'SUCCESS'}
                </div>
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning' style={{ border: 'none' }}>
                  {errorMessageload.status ? <><div class="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div></> : <></>}
                </div>
              </div>
              {errorMessageload.status ? <></> : <>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    {errorMessageload.message}

                  </div>
                </div>


                <div className='row alertKickBoxButton'>
                  <button className='alertKickButton' onClick={() => {
                    toggle_modal_alertKick()
                  }}>OK</button>

                </div>
              </>}


            </div>
          </div>
        </div>
      </div>


    <ModalConfirm isOpen={modalConfirm} toggle_modal_confirm = {toggle_modal_confirm} removeMenu = {removeStakeholder} index = {stakeholderIndex}/>
{/*     </>
    
  )} */}
              {loading && (
                    <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                        <div className='alertKickBoxShadow'>
                            <div className='alertKickBox' style={{ height: 'fit-content' }}>
                                <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                    <div className='row' style={{ height: '25%' }}>
                                        <div className='alertKickHead' style={{ color: 'green' }}>
                                            Loading
                                        </div>
                                    </div>
                                    <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                        <div className='alertKickWarning' style={{ border: 'none' }}>
                                            <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
    </>
)


}
export default ModalStakeholder;


